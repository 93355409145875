<template>
  <toggle-button
    :isset="finished"
    color="green"
    issetText="Mark Finished"
    issetIcon="done_outline"
    reverseText="Finished"
    reverseIcon="verified"
    @toggle="toggleArchive"
  />
</template>

<script>
export default {
  computed: {
    bookingId() {
      return this.$store.getters["FinanceReportStore/booking_id"];
    },
    finished() {
      return this.$store.getters["FinanceReportStore/report_finished"];
    },
  },
  methods: {
    toggleArchive(val) {
      this.$root.$loading.open("Updating...");
      this.$store
        .dispatch("FinanceReportStore/toggleFinished", {
          booking_id: this.bookingId,
          finished: val,
        })
        .then(() => {
          this.$root.$loading.end();
          this.$root.$success("Updated Report");
        })
        .catch((err) => this.$root.$error(err));
    },
  },
};
</script>
