<template>
  <a-card>
    <div>Primary Contact</div>

    <v-row>
      <v-col class="py-0">
        <a-field
          :config="config.name"
          v-uppercase="!showTranslation"
          :value="value.name"
          @input="(v) => feedUp('name', v)"
        />
      </v-col>
      <v-col class="py-0">
        <a-field :config="config.title" v-uppercase :value="value.title" @input="(v) => feedUp('title', v)" />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="py-0">
        <elwrapper icon="phone_in_talk" preicon="person">
          <FPhone :country="country" :value="value.phone_number" @input="(v) => feedUp('phone_number', v)" />
        </elwrapper>
        <!-- <a-field :config="config.phone_number" 
        :country="country"
        :value="value.phone_number"
        @input="v => feedUp('phone_number', v)" /> -->
      </v-col>
      <v-col class="py-0">
        <a-field
          :config="config.email"
          v-uppercase="!showTranslation"
          :value="value.email"
          @input="(v) => feedUp('email', v)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <elwrapper icon="phone_in_talk" preicon="smartphone" midtext>
          <FPhone :country="country" :value="value.cell_number" @input="(v) => feedUp('cell_number', v)" />
        </elwrapper>

        <!--  <a-field :config="config.cell_number" 
        :country="country" /> -->
      </v-col>
    </v-row>
  </a-card>
</template>

<script>
import contactConfig from "../config/contactConfig";

export default {
  props: {
    value: Object,
    country: String,
  },
  data() {
    return {
      config: contactConfig,
    };
  },
  computed: {
    showTranslation() {
      return this.$store.getters["AccountStore/environment"] !== "USA";
    },
  },
  methods: {
    feedUp(key, value) {
      let copy = { ...this.value };
      this.$emit(
        "input",
        Object.assign(copy, {
          [key]: value,
        })
      );
    },
  },
};
</script>
