<template>
  <v-container fill-height>
    <v-col align="center" justify="center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
      <div class="headline ma-3 white--text">{{ text || "Loading" }}...</div>
    </v-col>
  </v-container>
</template>

<script>
export default {
  props: {
    text: String,
  },
};
</script>
