<template>
  <div>
    <div v-for="(data, index) in dataCells" :key="index">
      <SupplierMassAddRow
        :index="index"
        :saved="data.saved"
        :error="data.error"
        :value="data.supplier"
        @input="(v) => (data.supplier[v.cell] = v.value)"
      />
      <SupplierMetaMassAddRow
        v-if="metaCells"
        :saved="data.saved"
        :error="data.error"
        :refCells="metaCells"
        :value="data.meta"
        @input="(v) => (data.meta[v.cell] = v.value)"
      />
      <SupplierContactMassAddRow
        :saved="data.saved"
        :error="data.error"
        :value="data.primary_contact"
        @input="(v) => (data.primary_contact[v.cell] = v.value)"
      />
    </div>
    <v-row justify="end">
      <g-button
        icon="add"
        label="Add Row"
        class="ma-1"
        @onclick="$emit('add', { supplier: {}, primary_contact: {}, meta: {} })"
      />
      <g-button icon="remove" label="Remove Row" class="ma-1" @onclick="$emit('remove')" />
    </v-row>
  </div>
</template>

<script>
import SupplierMassAddRow from "./_SupplierMassAddRow";
import SupplierMetaMassAddRow from "./_SupplierMetaMassAddRow";
import SupplierContactMassAddRow from "./_SupplierContactMassAddRow";

export default {
  props: {
    dataCells: Array,
    supplierType: String,
  },
  components: {
    SupplierMassAddRow,
    SupplierMetaMassAddRow,
    SupplierContactMassAddRow,
  },
  computed: {
    metaCells() {
      const HOTEL = [
        {
          label: "Hotel Class",
          key: "hotel_class",
        },
        {
          label: "Tax",
          key: "tax",
        },
        {
          label: "Breakfast",
          key: "breakfast",
        },
        {
          label: "Car Parking",
          key: "car_parking",
        },
        {
          label: "Bus Parking",
          key: "bus_parking",
        },
        {
          label: "Commissionable",
          key: "commisionable",
        },
        {
          label: "Porterage",
          key: "porterage",
        },
        {
          label: "FOC",
          key: "foc",
        },
        {
          label: "Comments",
          key: "comments",
        },
      ];

      const RESTAURANT = [
        {
          label: "Style",
          key: "style",
        },
        {
          label: "Tax",
          key: "tax",
        },
        {
          label: "Inclusive",
          key: "inclusive",
        },
        {
          label: "FOC",
          key: "foc",
        },
        {
          label: "Comments",
          key: "comments",
        },
      ];

      const EXCURSION = [
        {
          label: "Tax",
          key: "tax",
        },
        {
          label: "Inclusive",
          key: "inclusive",
        },
        {
          label: "FOC",
          key: "foc",
        },
        {
          label: "Comments",
          key: "comments",
        },
      ];

      return (
        {
          HOTEL,
          RESTAURANT,
          EXCURSION,
        }[this.supplierType] || [
          {
            label: "Comments",
            key: "comments",
          },
        ]
      );
    },
  },
};
</script>
