<template>
  <div class="d-flex align-center mx-3">
    <input type="checkbox" v-model="check" :checked="check" @change="$emit('input', check)" />
    <span class="caption px-3">{{ label }}</span>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
    label: String,
  },
  data() {
    return {
      check: this.value,
    };
  },
  watch: {
    value(v) {
      this.check = this.value;
    },
  },
};
</script>
