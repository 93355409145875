<template>
  <v-text-field
    outlined
    hide-details
    dense
    type="number"
    min="0"
    max="100"
    suffix="%"
    :value="value"
    @input="(v) => $emit('input', v)"
  />
</template>

<script>
export default {
  props: {
    value: [Number, String],
  },
  methods: {},
};
</script>
