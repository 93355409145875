<template>
  <v-col>
    <v-row justify="space-between">
      <delete-button :headline="deleteHeadline" :text="deleteText" :ondelete="confirmDelete" />

      <div>
        <g-button label="Return" @onclick="$router.push({ name: 'suppliers_contacts' })" />
        <save-button :onsave="updateContact" />
      </div>
    </v-row>

    <v-divider class="my-5" />

    <ContactEditForm
      v-model="contactForm"
      :country="country"
      :isprimary="isPrimary"
      @setPrimary="(v) => (isPrimary = v)"
    />
  </v-col>
</template>

<script>
import { ContactEditForm } from "@/modules/contacts";

export default {
  components: {
    ContactEditForm,
  },
  data() {
    var vm = this;
    return {
      contactId: "",
      isPrimary: null,
      contactFormOriginal: "",
      contactForm: {
        name: "",
        title: "",
        phone_number: "",
        cell_number: "",
        email: "",
        notes: "",
        meta: {},
      },
    };
  },
  computed: {
    supplierId() {
      return this.$store.getters["SupplierStore/id"];
    },
    country() {
      return this.$store.getters["SupplierStore/country"];
    },
    deleteHeadline() {
      return `Delete: ${this.contactForm.name} ?`;
    },
    deleteText() {
      return this.isPrimary
        ? `This is a PRIMARY CONTACT. If you remove this, there will be no primary contact anymore and it must be reset.`
        : null;
    },
  },
  methods: {
    setPrimary(clearPrimary) {
      if (this.isPrimary) {
        return this.$store
          .dispatch("SupplierContactStore/setPrimaryContact", {
            supplier_id: this.supplierId,
            contact_id: clearPrimary ? null : this.contactId,
            old_primary: this.cachedPrimaryContactId,
          })
          .then((v) => {
            this.$store.dispatch("SupplierStore/changePrimary", clearPrimary ? null : this.contactId);
            return new Promise((resolve, rej) => resolve(true));
          })
          .catch((err) => {
            throw err;
          });
      }

      return new Promise((resolve, rej) => resolve(false));
    },
    updateContact(skipRedirect) {
      let updateSupplier = null;
      return this.$store
        .dispatch("SupplierContactStore/updateContact", {
          id: this.contactId,
          contact: this.contactForm,
          original: this.contactFormOriginal,
        })
        .then((data) => {
          updateSupplier = data;
          return this.setPrimary(false);
        })
        .then((v) => {
          if (updateSupplier) this.$store.dispatch("SupplierStore/updateContact", this.contactForm);
          this.$root.$success("Updated contact: " + this.contactForm.name);
        })
        .then((v) => {
          if (!skipRedirect) this.$router.push({ name: "suppliers_contacts" });
        })
        .catch((err) => this.$root.$error);
    },

    confirmDelete() {
      return (
        this.$store
          .dispatch("SupplierContactStore/deleteContactList", [this.contactId])
          // If primary contact, want to remove that too
          .then((v) => this.setPrimary(true))
          .then((v) => {
            this.deleteGroup = [];
            this.$store.dispatch("SupplierStore/removeContacts", [this.contactId]);
            this.$root.$success("Deleted contacts");
            this.$router.push({ name: "suppliers_contacts" });
          })
          .catch((err) => this.$store.$error(err))
      );
    },
  },
  mounted() {
    this.contactId = this.$route.params.contact_id;

    // Get current primary contact for supplier
    var primary = this.$store.getters["SupplierStore/primary_contact"];
    this.cachedPrimaryContactId = primary.id;
    this.isPrimary = this.contactId === primary.id;

    // Get contact (copy to not mutate original)
    var contactList = this.$store.getters["SupplierStore/all_contacts"];
    this.contactForm = { ...contactList.find((x) => this.contactId === x.id) };
    if (!this.contactForm.meta) this.contactForm.meta = {};
    this.contactFormOriginal = JSON.stringify(this.contactForm);
  },
  beforeDestroy() {
    // Save on tab change
    if (this.contactFormOriginal !== JSON.stringify(this.contactForm)) {
      this.updateContact(true);
    }
  },
};
</script>
