export default [
  {
    path: "/admin_textconfig",
    name: "admin_textconfig",
    component: () => import("./TextConfigurationView"),
    meta: {
      tabAccess: "ADMIN",
    },
  },
  {
    path: "/admin_textconfig/:groupkey/:refkey",
    name: "admin_textconfig_edit",
    component: () => import("./EditTextConfiguration"),
    meta: {
      tabAccess: "ADMIN",
    },
  },
];
