<template>
  <div class="black--text px-3">
    <div class="mx-5">
      <v-row class="subtitle-2 grey--text my-2"> Extra Options </v-row>

      <TemplateTextOption
        :type="type"
        :supplier="supplier"
        :products="products"
        :bookingSupplierMeta="metaconfig"
        @set-bsmeta="setBookingSupplierMeta"
      />
      <v-row v-if="typeHotel" class="pa-0">
        <v-col class="pa-0">
          <CustomResortFee :bsmeta="metaconfig" @set="setCustomResortFee" />
        </v-col>
        <v-col class="pa-0">
          <CustomCommission :bsmeta="metaconfig" @set="setCustomCommission" />
        </v-col>
      </v-row>

      <PoterageOption
        v-if="typeHotel"
        init
        :supplier="supplier"
        :bsmeta="metaconfig"
        :pax="pax_num"
        @set="setPorterage"
      />
      <ParkingOption v-if="typeHotel" :supplier="supplier" :bsmeta="metaconfig" @set="setParking" />

      <div v-if="typeHotel" class="d-flex flex-row">
        <pcheck
          label="Custom Room List"
          :value="!!metaconfig.custom_room_num"
          @input="(v) => setBookingSupplierMeta({ key: 'custom_room_num', value: v ? {} : false })"
        />

        <RoomNumberField
          v-if="metaconfig.custom_room_num"
          :value="metaconfig.custom_room_num"
          :pax="pax_num"
          style="max-width: 130px"
          @input="(v) => setBookingSupplierMeta({ key: 'custom_room_num', value: v })"
          offmain
        />
      </div>
    </div>

    <v-row v-if="contacted" class="button-row" justify="space-between">
      <g-button label="Return" icon="reply" @onclick="$emit('close')" />
      <v-btn text disabled small>
        <span>contracted, cannot edit</span>
      </v-btn>
    </v-row>
    <div v-else>
      <v-row class="button-row py-3" justify="space-between">
        <g-button label="Cancel" icon="clear" @onclick="$emit('cancel')" />

        <div>
          <g-button label="Back" icon="keyboard_arrow_left" @onclick="$emit('revert')" />
          <g-button type="primary" label="Finished" @onclick="$emit('finish')" />
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import format from "@/services/format";

import TemplateTextOption from "./_TemplateTextOption";
import CustomCommission from "./_CustomCommission";
import CustomResortFee from "./_CustomResortFee";
import ParkingOption from "./_ParkingOption";
import PoterageOption from "./_PorterageOption";

import { RoomNumberField, BookingRoomService } from "@/modules/bookings.rooms";

export default {
  props: {
    contacted: Boolean,
    init: Boolean, // When FIRST adding a booking supplier

    type: String,
    supplier: Object,
    products: Object,
    metaconfig: Object,
  },
  components: {
    TemplateTextOption,
    CustomCommission,
    CustomResortFee,
    ParkingOption,
    PoterageOption,

    RoomNumberField,
  },
  data() {
    return {
      customRoom: false,
    };
  },
  computed: {
    pax_num() {
      return this.$store.getters["BookingStore/pax_num"] || this.$store.getters["BookingStore/data"]["est_pax_num"];
    },
    typeHotel() {
      return this.supplier.type_as === "HOTEL" || this.supplier.supplier_type === "HOTEL";
    },
  },
  methods: {
    // OPTIONS
    setBookingSupplierMeta({ key, value }) {
      Vue.set(this.metaconfig, key, value);
    },
    setCustomResortFee(v) {
      if (v === "ADD") {
        Vue.set(this.metaconfig, "has_custom_resortfee", true);
        Vue.set(this.metaconfig, "custom_resortfee_value", {});
      } else if (v === "DELETE") {
        Vue.delete(this.metaconfig, "has_custom_resortfee");
      } else {
        Vue.set(this.metaconfig, "custom_resortfee_value", v);
      }
    },
    setCustomCommission(v) {
      if (v === "ADD") {
        Vue.set(this.metaconfig, "has_custom_commission", true);
        Vue.set(this.metaconfig, "custom_commission_value", "");
      } else if (v === "DELETE") {
        Vue.delete(this.metaconfig, "has_custom_commission");
      } else {
        Vue.set(this.metaconfig, "custom_commission_value", v);
      }
    },
    setPorterage(v) {
      if (v) {
        Vue.set(this.metaconfig, "porterage", true);
      } else {
        Vue.delete(this.metaconfig, "porterage");
      }
    },
    setParking(v) {
      Vue.set(this.metaconfig, "parking", v);
    },

    // EJECT

    updatePricing() {
      // Data is saved to main (metaconfig) object thingy
      // xxxx Extract the results into reduced object for saving
      this.$emit("finish", false);
    },
  },
};
</script>

<style lang="scss">
.custom-price-row {
  border-bottom: 1px solid lightgrey;

  &:focus-within {
    color: #1976d2;
  }
}
</style>
