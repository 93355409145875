<template>
  <v-col class="pa-2 bookings">
    <v-row justify="space-between" class="pa-2 bookings-header">
      <div>
        <g-button
          :label="showSearch ? 'hide' : 'show search'"
          :icon="showSearch ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
          @onclick="showSearch = !showSearch"
        />
        <v-dialog v-model="exportDialog" max-width="400" v-if="this.searchQuery.view === 'SUPPLIER'">
          <template v-slot:activator="{ on }">
            <g-button class="mx-2" label="Export" icon="get_app" :loading="loading" @onclick="exportDialog = true" />
          </template>

          <v-card>
            <v-card-title class="d-flex justify-space-between">
              <span class="subtitle-2">Export Reporting by Supplier</span>
            </v-card-title>

            <v-card-text>
              <input id="include_header" type="checkbox" :checked="isIncludeHeader" v-model="isIncludeHeader" />
              <label class="mx-2" for="include_header">Include header</label>
            </v-card-text>

            <v-card-actions>
              <g-button
                label="Cancel"
                @onclick="
                  exportDialog = false;
                  isIncludeHeader = false;
                "
              />
              <div class="flex-grow-1"></div>
              <g-button class="mx-2" label="Export" icon="get_app" :loading="loading" @onclick="exportData" />
            </v-card-actions>
          </v-card>
        </v-dialog>

        <g-button v-else class="mx-2" label="Export" icon="get_app" :loading="loading" @onclick="exportData" />
      </div>

      <d-header label="Reporting" style="text-align: center; display: flex; flex-grow: 1" />
    </v-row>

    <FinanceSearch
      v-show="showSearch"
      v-model="searchQuery"
      :showRecon="showRecon"
      :showArchive="showArchive"
      @showTotal="(v) => (showTotal = v)"
      @splitTotal="(v) => (splitTotal = v)"
      @updateRecShow="(v) => (showRecon = v)"
      @updateArchive="(v) => (showArchive = v)"
    />

    <FinanceTable
      class="mx-2"
      :query="searchQuery.query"
      :view="searchQuery.view"
      :showTotal="showTotal"
      :splitTotal="splitTotal"
      :showRecon="showRecon"
      :showArchive="showArchive"
    />
  </v-col>
</template>

<script>
import { FinanceSearch, FinanceTable } from "@/modules/finances";
import { HEADER_COLS, ExportService } from "@/modules/exports";
import ReportService from "@/modules/finances.report/service";
import money from "@/services/money";

export default {
  components: {
    FinanceSearch,
    FinanceTable,
  },
  data() {
    return {
      showSearch: true,
      showTotal: true,
      splitTotal: false,
      exportDialog: false,
      isIncludeHeader: false,
      searchQuery: {
        query: {},
        view: "GENERAL",
      },
      showRecon: ["REC_NONE", "REC_PART", "REC_ALL"],
      showArchive: ["ACTIVE"],
      loading: false,
    };
  },
  methods: {
    exportData() {
      let rows = [];
      let config = [];

      const date = new Date().getTime();
      let fileName = `report-${date}`;

      const view = this.searchQuery.view;
      const data = this.$store.getters["FinanceStore/data"];

      if (view === "GENERAL") {
        let tableHeader = this.$store.getters["FinanceStore/tableHeader"];
        tableHeader = tableHeader.map((header) => ({ ...header, width: header.width ? header.width : 36 }));

        const headers = tableHeader.map((header) => header.text);
        const title = ["List of Reporting"];

        const formattedData = data.map((financeData) => {
          const booking = financeData.booking;
          const totalInvoice = _formatCost(financeData.total_invoice);
          const totalBilled = _formatCost(financeData.total_billed.COMBINED);

          const margin = financeData.margin;

          const marginDiff = _formatCost(margin ? margin.diff : null);
          const marginPer = margin && margin.percentage ? `(${Number(margin.percentage).toFixed(2)} %)` : "";
          const totalRecognition = `${Number((financeData.reconciliation || 0) * 100).toFixed(0)} %`;
          const invoicePaidDisplay = _formatCost(financeData.invoice_paid);

          return [
            booking.client_name,
            booking.group_name,
            totalInvoice,
            totalBilled,
            marginDiff,
            marginPer,
            totalRecognition,
            invoicePaidDisplay,
          ];
        });

        rows = [title, [], headers, ...formattedData];
        config.push({ row: 1, options: { height: 40, font: { name: "Calibri", size: 20 } } });

        headers.forEach((_, i) => {
          config.push({ row: 3, column: i + 1, options: { width: 20 } });
          config.push({
            row: 3,
            cell: i + 1,
            options: HEADER_COLS.DEFAULT_CONFIG,
          });
        });
        config.push({ row: 3, column: 1, options: { width: 35 } });
        config.push({ row: 3, column: 2, options: { width: 35 } });

        formattedData.forEach((_, i) => {
          headers.forEach((__, j) => {
            config.push({
              row: 4 + i,
              cell: 1 + j,
              options: { border: HEADER_COLS.DEFAULT_CONFIG.border },
            });
          });
          config.push({
            row: 4 + i,
            options: {
              font: { name: "Calibri" },
              alignment: HEADER_COLS.DEFAULT_CONFIG.alignment,
            },
          });
        });
      }

      if (view === "SUPPLIER") {
        fileName = `report_by_supplier-${date}`;
        const title = ["List of Reporting by Supplier"];
        rows.push(title, []);
        config.push({ row: 1, options: { height: 40, font: { name: "Calibri", size: 20 } } });

        const bookings = data.map((data) => data.booking);

        const headers = bookings.map((booking) => [
          booking.booking_id,
          booking.client_name,
          booking.group_name,
          booking.from,
          booking.to,
          `${booking.where_start} to ${booking.where_end}`,
        ]);

        const formattedData = {};

        data.forEach((data) => {
          data.displayRows.forEach((supplier) => {
            const strippedExpense = supplier.products.filter(
              (item) => item.id !== "PARKING_BUS" && item.id !== "PARKING_VEHICLE" && item.id !== "PORTERAGE"
            );
            const total = _formatCost(ReportService.getProductListTotal(strippedExpense, true, this.$store.getters));

            const invoice = _formatCost(data.supplier_invoices[supplier.key]?.total_cost);
            const delta = _formatCost(data.deltas[supplier.key]);
            const reconciled = data.supplier_invoices[supplier.key]?.reconciled ? "RECONCILED" : "NOT RECONCILED";

            if (data.booking_id in formattedData)
              formattedData[data.booking_id].push([supplier.name, total, invoice, delta, reconciled]);
            else formattedData[data.booking_id] = [[supplier.name, total, invoice, delta, reconciled]];
          });
        });

        let currentBookingHeaderPosition = 3;
        const headerRow = this.isIncludeHeader ? 1 : 0;

        headers.forEach((header, i) => {
          if (this.isIncludeHeader) {
            const bookingHeader = header.slice(1);
            rows.push(bookingHeader);

            bookingHeader.forEach((_, j) => {
              config.push({ row: currentBookingHeaderPosition, cell: j + 1, options: HEADER_COLS.DEFAULT_CONFIG });
            });
          }

          // header[0] is booking_id
          const bookingData = formattedData[header[0]];

          bookingData.forEach((data, j) => {
            const currentRow = j + headerRow + currentBookingHeaderPosition;
            rows.push(data);
            config.push({
              row: currentRow,
              options: {
                font: { name: "Calibri" },
                alignment: HEADER_COLS.DEFAULT_CONFIG.alignment,
              },
            });

            data.forEach((_, k) =>
              config.push({ row: currentRow, cell: k + 1, options: { border: HEADER_COLS.DEFAULT_CONFIG.border } })
            );
          });
          currentBookingHeaderPosition += bookingData.length + headerRow;
        });

        // 5 columns
        for (let i = 3; i <= 5; i++) {
          config.push({ row: 0, column: i, options: { width: 20 } });
        }
        config.push({ row: 0, column: 1, options: { width: 35 } });
        config.push({ row: 0, column: 2, options: { width: 35 } });

        this.exportDialog = false;
        this.isIncludeHeader = false;
      }

      ExportService({ fileName, rows, config, includeIcon: false });
    },
  },
  mounted() {
    const today = new Date();
    const todayYear = today.getUTCFullYear();
    const cutoffDate = new Date(Date.UTC(todayYear, 2, 1)); // March 1st of this year in UTC
    const year = today > cutoffDate ? todayYear : todayYear - 1;
    const defaultDate = new Date(Date.UTC(year, 0, 1));
    const from = defaultDate.toISOString().slice(0, 10);

    this.searchQuery.query = { from: from, clientPaid: null, recondStatus: null };
  },
};

function _formatCost(v) {
  return v ? +Number(v).toFixed(2) : "";
}
</script>

<style lang="scss"></style>
