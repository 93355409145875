<template>
  <div>
    <v-row justify="space-between">
      <a-card nopad>
        <g-button :disabled="emailView === 0" label="1 - Select" class="mx-1" @onclick="emailView = 0" />
        <g-button :disabled="emailView === 1" label="2 - Compose" @onclick="emailView = 1" />
        <g-button :disabled="emailView === 2" label="3 - Preview" @onclick="emailView = 2" />
      </a-card>

      <!-- <v-alert type="warning" dense text class="caption" v-if="!roomingListMatches">
			Rooming List does not match Supplier
		</v-alert> -->

      <a-card nopad v-if="emailView !== 0">
        <EmailSendButton
          :disabled="!canSend"
          :subject="formData.subject"
          :attachments="formData.attachments"
          :compileMailFn="compileMailFn"
          :postSend="_updateBookingSupplierStatuses"
          :onFinishFn="returnTo"
        />
      </a-card>
    </v-row>

    <v-row v-show="emailView === 0">
      <SelectSuppliersToContact
        style="flex: 1"
        @setType="(v) => (supplierType = v)"
        :roomBreakdownData="roomBreakdownData"
        :value="selectedSuppliers"
        @input="(v) => onSelectionUpdated(v)"
      />
    </v-row>

    <v-row v-show="emailView === 1">
      <a-card style="flex: 1">
        <EmailWrapper
          hideButtons
          :groupName="groupName"
          mailType="SINGLE"
          :config="emailConfig"
          multiDate
          :selectedDate="selectedDate"
          v-model="formData"
        />
      </a-card>
    </v-row>

    <v-row v-if="emailView === 2">
      <a-card style="flex: 1">
        <PreviewEmailWrapper
          :subject="formData.subject"
          :attachments="formData.attachments"
          :compileMailFn="compileMailFn"
        />
      </a-card>
    </v-row>
  </div>
</template>

<script>
import { PreviewEmailWrapper, EmailTemplateService } from "@/modules/admin.email_template";
import { ProductService } from "@/modules/products";
import { EmailService, EmailSendButton } from "@/modules/email";
import { SelectSuppliersToContact, EmailWrapper, BookingEmailService } from "@/modules/bookings.email";
import { BookingRoomService } from "@/modules/bookings.rooms";
export default {
  components: {
    PreviewEmailWrapper,
    SelectSuppliersToContact,
    EmailWrapper,
    EmailSendButton,
  },
  data() {
    return {
      formData: {
        attachments: [],
      },
      supplierType: "",
      selectedSuppliers: {
        contacts: {},
        ignoreProducts: {}, // Keys include booking_supplier_id + product_id
        groupBy: {}, // [supplier_id]: groupping type (SPLIT, GROUP)
        admins: {},
      },

      emailView: 0,
    };
  },
  computed: {
    bookingId() {
      return this.$store.getters["BookingStore/booking_id"];
    },
    bookingName() {
      return this.$store.getters["BookingStore/booking_name"]();
    },
    bookingSuppliers() {
      return this.$store.getters["BookingDayStore/suppliers"];
    },
    groupName() {
      return this.$store.getters["BookingStore/active"].group_name;
    },
    canSend() {
      return Object.keys(this.selectedSuppliers.contacts).length > 0;
    },

    selectedDate() {
      const supplierIdList = Object.keys(
        Object.values(this.selectedSuppliers.contacts).reduce((map, i) => {
          map[i.supplier_id] = 1;
          return map;
        }, {})
      );
      const bookingDays = this.$store.getters["BookingDayStore/days"];

      return BookingEmailService.getFirstDayHotelBySupplierId(supplierIdList, bookingDays, this.bookingSuppliers);
    },

    emailConfig() {
      return {
        group_name: this.groupName,
        pax: this.$store.getters["BookingStore/pax_num"],
        // BookingRoomService.getPaxCount(this.$store.getters['BookingStore/data'].room_num,
        //       			this.$store.getters['BookingRoomStore/rooms'])
      };
    },

    roomBreakdownData() {
      return BookingRoomService.getRoomingListBreakdown({
        bookingMetaData: this.$store.getters["BookingStore/data"],
        bookingRoomingList: this.$store.getters["BookingRoomStore/rooms"],
        bookingDays: this.$store.getters["BookingDayStore/days"],
        supplierRoomList: this.$store.getters["BookingSupplierRoomStore/supplier_rooms"],
      });
    },
  },
  methods: {
    returnTo() {
      // this.$router.push({
      //   name: "bookings_days",
      //   params: { booking_id: this.bookingId },
      // });
    },

    compileMailFn() {
      const ss = this.selectedSuppliers;
      const language = this.formData.language;
      const cc = Object.values(ss.admins).map((e) => e.email);
      // Group contact emails + insert their names
      let groupedSupplierContacts = EmailService.groupSupplierContacts(ss.contacts, "supplier_id", cc);
      const message = this.formData.message || "";
      groupedSupplierContacts.forEach((selected) => {
        selected.message = EmailTemplateService.replaceContact(selected.contacts.nameList, message, language);
        selected.message = EmailTemplateService.replaceSupplier(selected.message, selected);
      });

      // Handle email/product group splitting
      // And assign relevant booking supplier/supplier and product for emails
      const bookingDays = this.$store.getters["BookingDayStore/days"];
      let groupedEmailInfo = EmailService.groupSupplierProducts(
        groupedSupplierContacts,
        this.supplierType.value,
        bookingDays,
        this.bookingSuppliers,
        ss.groupBy || {},
        ss.ignoreProducts,
        this.$store.getters["BookingStore/oldDates"]
      );

      // Fill in product + room information
      let finalEmailGroups = groupedEmailInfo.map((item) => {
        return EmailTemplateService.fillProductsBySupplierAndProductGroup(
          item,
          this.roomBreakdownData,
          this.formData.subject
        );
      });

      // Add in attachments
      finalEmailGroups.forEach((group) => {
        group.attachments = this.formData.attachments.filter((a) => {
          if (!a.supplier_id) return true;
          return a.supplier_id === group.supplier_id;
        });
      });

      return Promise.resolve(finalEmailGroups);
    },

    _updateBookingSupplierStatuses(mail) {
      if (this.formData.status === undefined || this.formData.status === null) return Promise.resolve();

      // const ss = this.selectedSuppliers;
      // const bookingSupplierIdList = EmailService.getBookingSupplierIdToUpdate(
      //   ss.contacts,
      //   this.bookingSuppliers,
      //   ss.ignoreProducts
      // );
      const bookingSupplierIdList = Object.values(this.bookingSuppliers)
        .filter((supplier) => supplier.supplier_id === mail.supplier_id)
        .map((supplier) => supplier.id);

      return BookingEmailService.updateBookingSupplierStatus({
        booking_id: this.bookingId,
        bookingSupplierIdList,
        status: this.formData.status,
        lockedPriceMapping: this.getLockedPriceMapping(bookingSupplierIdList),
      }).catch((err) => {
        throw err;
      });
    },

    getLockedPriceMapping(bookingSupplierIdList) {
      let endMap = {};
      bookingSupplierIdList.forEach((id) => {
        endMap[id] = BookingEmailService.getOneLockedPricing(this.bookingSuppliers[id], this.$store.getters);
      });

      return endMap;
    },
    onSelectionUpdated(selectedSuppliers) {
      this.selectedSuppliers = selectedSuppliers;
    },
  },
  mounted() {
    this.$store.dispatch("EmailTemplateStore/getTemplates", true).catch((err) => this.$root.$error(err));
  },
};
</script>
