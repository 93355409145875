import Admin from "./Admin";
import AccountManage from "./AccountManage";
import AccountManageNew from "./AccountManageNew";
import AccountManageEdit from "./AccountManageEdit";

import { UploadForm } from "@/modules/uploadDb";
import defaults from "./defaults/_routes";
import emailTemplateRoutes from "./emailTemplates/_routes";
import history from "./history/_routes";
import optionList from "./option_lists/_routes";
import textConfig from "./text_configs/_routes";

export default [
  {
    path: "/admin",
    component: Admin,
    children: [
      {
        path: "",
        name: "admin",
        component: AccountManage,
        meta: {
          tabAccess: "ADMIN",
        },
      },
      {
        path: "/account_new",
        name: "admin_add_account",
        component: AccountManageNew,
        meta: {
          tabAccess: "ADMIN",
        },
      },
      {
        path: "/account/:account_id",
        name: "admin_account_edit",
        component: AccountManageEdit,
        meta: {
          tabAccess: "ADMIN",
        },
      },
      {
        path: "/upload",
        name: "admin_upload",
        component: UploadForm,
        meta: {
          tabAccess: "ADMIN",
        },
      },
      ...emailTemplateRoutes,
      ...defaults,
      ...history,
      ...optionList,
      ...textConfig,
    ],
  },
];
