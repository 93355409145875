<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-chip v-show="reminders.overdue" v-on="on" small color="red" class="ml-3">{{ reminders.overdue }}</v-chip>
      </template>
      <span>{{ reminders.overdue }} overdue reminders</span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-chip v-show="reminders.pending" v-on="on" small color="yellow" class="ml-3">{{ reminders.pending }}</v-chip>
      </template>
      <span>{{ reminders.pending }}</span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-chip v-show="reminders.resolved" v-on="on" small color="green" class="ml-3">{{ reminders.resolved }}</v-chip>
      </template>
      <span>{{ reminders.resolved }} resolved</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    reminders: Object,
  },
};
</script>
