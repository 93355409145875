<template>
  <div>
    <g-button :disabled="disabled" :loading="loading" @onclick="sendMail" type="primary" icon="send" label="Send" />

    <BulkEmailDialog v-model="bulkdialog" :emailList="emailList" @send-one-mail="sendOneMail" :loading="loading" />
  </div>
</template>

<script>
import BulkEmailDialog from "./_BulkEmailDialog";
import { EmailTemplateService } from "@/modules/admin.email_template";
import { EmailService } from "@/modules/email";
export default {
  props: {
    disabled: Boolean,
    subject: String,
    attachments: Array, // Defaults

    compileMailFn: Function, // RETURN Promise
    postSend: Function, // RETURN Promise
    onFinishFn: Function, // RETURN Promise
  },
  components: {
    BulkEmailDialog,
  },
  data() {
    return {
      loading: false,
      bulkdialog: false,
      emailList: [],
    };
  },
  methods: {
    sendMail() {
      const accountData = this.$store.getters["AccountStore/emailCreds"];
      if (!(accountData && accountData.source && accountData.email)) {
        this.$root.$error("Email not configured to send");
        return;
      }

      const vm = this;

      this.$root.$loading.open("Compiling email...");
      this.loading = true;

      this.compileMailFn()
        .then((mailGroups) => {
          mailGroups.forEach((item) => {
            // ATTACH SIGNATURE
            item.message += this.$store.getters["AccountStore/signature"];
          });

          mailGroups.sort((a, b) => (a.supplier_name < b.supplier_name ? -1 : 1));

          this.bulkdialog = true;
          this.emailList = mailGroups;

          // TODO: Add delay for temporariness; will enhance this later
          const promises = mailGroups.map(async (mail, index) => {
            await new Promise((resolve) => setTimeout(resolve, index * 2000));
            return this.sendEmail(accountData, mail, vm);
          });
          // return EmailService.sendBulkEmail({
          //   accountData: accountData,
          //   subject: this.subject,
          //   attachments: this.attachments,

          //   messageData: mailGroups,

          //   updateMessageFn: function (message) {
          //     vm.$root.$loading.update(message);
          //   },
          // });
          return Promise.all(promises);
        })
        .then(() => {
          // if (this.postSend) return this.postSend();
        })
        .then(() => {
          // DONE!
          // this.bulkdialog = false;
          this.loading = false;
          this.$root.$loading.end();
          // this.$root.$success("Email sent");
          this.onFinishFn();
        })
        .catch((err) => {
          this.loading = false;
          this.$root.$loading.end();
          if (err && err.command === "AUTH LOGIN") {
            this.$root.$error("Email credentials are incorrect");
          } else {
            this.$root.$error(err);
          }
        });
    },
    sendEmail(accountData, mail, vm) {
      return EmailService._sendOneMail({
        accountData: accountData,
        subject: this.subject,
        attachments: this.attachments,

        messageBlob: mail,

        updateMessageFn: function (message) {
          vm.$root.$loading.update(message);
        },
      })
        .then(() => {
          if (this.postSend) return this.postSend(mail);
        })
        .then(() => {
          // DONE!
          this.onFinishFn();
        })
        .catch((err) => {
          throw err;
          // if (err && err.command === "AUTH LOGIN") {
          //   this.$root.$error("Email credentials are incorrect");
          // } else {
          //   this.$root.$error(err);
          // }
        });
    },
    sendOneMail(index) {
      const accountData = this.$store.getters["AccountStore/emailCreds"];
      if (!(accountData && accountData.source && accountData.email)) {
        this.$root.$error("Email not configured to send");
        return;
      }

      const vm = this;
      this.emailList[index].promise = null;

      return EmailService._sendOneMail({
        accountData: accountData,
        subject: this.subject,
        attachments: this.attachments,
        messageBlob: this.emailList[index],
        updateMessageFn: function (message) {
          vm.$root.$loading.update(message);
        },
      })
        .then((mail) => {
          // if (this.postSend) return this.postSend(mail);
        })
        .then(() => {
          // DONE!
          this.loading = false;
          this.$root.$loading.end();
          // this.$root.$success("Email sent");
          this.onFinishFn();
        })
        .catch((err) => {
          this.loading = false;
          this.$root.$loading.end();
          if (err && err.command === "AUTH LOGIN") {
            this.$root.$error("Email credentials are incorrect");
          } else {
            this.$root.$error(err);
          }
        });
      // return mailGroups[index];
    },
  },
  mounted() {
    // Get Emails
    this.$store.dispatch("AccountStore/getPersonalEmailList");
  },
};
</script>
