import ProductsView from "./ProductsView";
import ProductNew from "./ProductNew";

export default [
  {
    path: "products",
    name: "suppliers_products",
    component: ProductsView,
    meta: {
      toolbar: "supplier",
      showProductYear: true,
      tabAccess: "DATABASE",
    },
  },
  {
    path: "products_new",
    name: "suppliers_products_new",
    component: ProductNew,
    meta: {
      toolbar: "supplier",
      showProductYear: true,
      tabAccess: "DATABASE",
    },
  },
];
