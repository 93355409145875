import Vue from "vue";
import api from "./api";

const SET_ACTIVE_CLIENT = "SET_ACTIVE_CLIENT";
const TRANSFER_CLIENT = "TRANSFER_CLIENT";
const UPDATE_CLIENT = "UPDATE_CLIENT";
const UPDATE_CLIENT_ADDRESS = "UPDATE_CLIENT_ADDRESS";

const UPDATE_PRIMARY = "UPDATE_PRIMARY";
const ADD_CONTACT = "ADD_CONTACT";
const UPDATE_CONTACT = "UPDATE_CONTACT";
const DELETE_CONTACT_LIST = "DELETE_CONTACT_LIST";

const namespaced = true;

const state = {
  active: _getClient(),
  cache: _getClient(),

  contacts: _getContacts(),
};

function _getClient() {
  return JSON.parse(localStorage.getItem("client") || "{}");
}

function _getContacts() {
  return JSON.parse(localStorage.getItem("client_contacts") || "{}");
}

const mutations = {
  SET_ACTIVE_CLIENT(state, data) {
    state.active = data.client || {};
    state.cache = { ...data.client };
    state.contacts = data.contacts || {};

    localStorage.setItem("client", JSON.stringify(state.active));
    localStorage.setItem("client_contacts", JSON.stringify(state.contacts));
  },
  TRANSFER_CLIENT(state) {
    // Transfer active client => cache
    // Save update local storage
    state.cache = { ...state.active };
    localStorage.setItem("client", JSON.stringify(state.cache));
  },
  UPDATE_CLIENT(state, { key, val }) {
    Vue.set(state.active, key, val);
  },
  UPDATE_CLIENT_ADDRESS(state, { key, val }) {
    if (!state.active.address) state.active.address = {};
    Vue.set(state.active.address, key, val);
  },

  // Contacts
  UPDATE_PRIMARY(state, nuId) {
    var newPrimary = state.contacts.all_contacts.find((x) => x.id === nuId);
    Vue.set(state.contacts, "primary_contact", newPrimary);
    localStorage.setItem("client_contacts", JSON.stringify(state.contacts));
  },
  ADD_CONTACT(state, contact) {
    state.contacts.all_contacts.push(contact);
    localStorage.setItem("client_contacts", JSON.stringify(state.contacts));
  },
  UPDATE_CONTACT(state, { id, contact }) {
    var index = state.contacts.all_contacts.findIndex((x) => x.id === id);
    state.contacts.all_contacts[index] = contact;

    // In case seperate object
    if (id === state.contacts.primary_contact.id) {
      state.contacts.primary_contact = contact;
    }
    localStorage.setItem("client_contacts", JSON.stringify(state.contacts));
  },
  DELETE_CONTACT_LIST(state, idList) {
    state.contacts.all_contacts = state.contacts.all_contacts.filter(function (item) {
      return idList.indexOf(item.id) === -1;
    });
    localStorage.setItem("client_contacts", JSON.stringify(state.contacts));
  },
};

const actions = {
  setClient({ commit }, data) {
    commit(SET_ACTIVE_CLIENT, data);
  },
  setUpdate({ commit }, { key, val }) {
    commit(UPDATE_CLIENT, { key, val });
  },
  setUpdateAddress({ commit }, { key, val }) {
    commit(UPDATE_CLIENT_ADDRESS, { key, val });
  },

  // Contact Controls
  insertContact({ commit }, contactData) {
    commit(ADD_CONTACT, contactData);
  },
  updateContact({ commit }, contact) {
    commit(UPDATE_CONTACT, {
      id: contact.id,
      contact: contact,
    });
  },
  removeContacts({ commit }, contactIdList) {
    commit(DELETE_CONTACT_LIST, contactIdList);
  },
  changePrimary({ commit }, new_primary_id) {
    commit(UPDATE_PRIMARY, new_primary_id);
  },

  // Clients
  getClientList({ commit }, data) {
    return api
      .getClientList(data)
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  getClientCount({ commit }, data) {
    return api
      .getClientCount(data)
      .then((data) => Object.values(data.data)[0])
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  getOneClient({ commit }, client_id) {
    return api
      .getOneClient(client_id)
      .then((data) => {
        commit(SET_ACTIVE_CLIENT, data.data);
        return data.data;
      })
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  addClient({ commit }, data) {
    return api
      .addClient(data)
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  updateClient({ commit, state }, data) {
    var id = data ? data.client_id : state.active.id;
    var update = data ? data.data : state.active;

    return api
      .updateClient(id, update, state.cache)
      .then((v) => {
        commit(TRANSFER_CLIENT);
      })
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  deleteClient({ commit, state }, client_id) {
    var delete_id = client_id || state.active.id;
    return api
      .deleteClient(delete_id)
      .then((v) => commit(SET_ACTIVE_CLIENT, {}))
      .catch((err) => {
        if (err) throw err.data;
      });
  },
};

const getters = {
  client_id: (state) => (state.active ? state.active.id : ""),
  country: (state) => (state.active ? state.active.country : ""),
  address: (state) => (state.active ? state.active.address || {} : {}),
  active: (state) => state.active || {},
  cache: (state) => state.cache || {},

  all_contacts: (state) => state.contacts.all_contacts || [],
  primary_contact: (state) => state.contacts.primary_contact || {},

  hasChanges: (state) => {
    let current = JSON.stringify(state.current);
    let cached = JSON.stringify(state.cached);
    if (current === cached) return false;
    return true;
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
