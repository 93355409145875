<template>
  <tr class="database-row">
    <td>
      <FavoriteStar :id="rows.id" :selected="rows.favorite" type="quote" @update="(v) => (rows.favorite = v)" />
    </td>
    <td>{{ rows.client_name }}</td>
    <td>{{ rows.reference }}</td>
    <td>{{ rows.city_start }}</td>
    <td>{{ rows.city_end }}</td>
    <td>{{ rows.version }}</td>
    <td>{{ this.formatDate(rows.start_date) }}</td>
    <td>{{ this.formatDate(rows.end_date) }}</td>
    <td>{{ rows.number_of_nights }}</td>
  </tr>
</template>

<script>
import FavoriteStar from "@/modules/database/components/tables/subcontent/_FavoriteStar";

export default {
  props: {
    item: Object,
  },
  components: {
    FavoriteStar,
  },
  computed: {
    rows: {
      get() {
        return this.item;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return "-";

      const { year, month, day } = date;

      if (year) {
        const options = { year: "numeric" };
        if (month) options.month = "long";
        if (day) options.day = "numeric";

        const dateObject = new Date(year, (month || 1) - 1, day || 1);
        return dateObject.toLocaleDateString("en-US", options);
      }

      return "-";
    },
  },
};
</script>
