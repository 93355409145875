import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// import VueFriendlyIframe from 'vue-friendly-iframe';
import VueOffline from "vue-offline";
import VueVirtualScroller from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import VueRouterBackButton from "vue-router-back-button";
import vuetify from "./plugins/vuetify";
import VueCurrencyInput from "vue-currency-input";
import VueExcelEditor from "vue-excel-editor";
import "./plugins/tiptap";

import "@/directives";
import "@/components";

// EVENT BUS
import "@/modules/bookings/eventbus";
import "@/modules/bookings.rooms/eventbus";

Vue.use(VueCurrencyInput);
Vue.use(VueOffline);
Vue.use(VueVirtualScroller);
Vue.use(VueRouterBackButton, { router });
Vue.use(VueExcelEditor);
// Vue.use(VueFriendlyIframe);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
