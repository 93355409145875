<template>
  <div>
    <v-menu bottom right open-on-hover>
      <template v-slot:activator="{ on }">
        <div v-on="on" v-show="!loading">
          <v-btn v-if="value === 2" color="warning" small depressed>{{ halfLabel }}</v-btn>
          <v-btn v-else-if="value" color="green" small depressed>{{ onLabel }}</v-btn>
          <v-btn v-else text small>{{ offLabel }}</v-btn>
        </div>
        <v-progress-circular indeterminate color="primary" v-show="loading"></v-progress-circular>
      </template>

      <div class="d-flex flex-column white">
        <v-btn @click="switchValue(0)" small text>{{ offLabel }}</v-btn>
        <v-btn v-if="halfLabel" @click="switchValue(2)" small color="warning" outlined>{{ halfLabel }}</v-btn>
        <v-btn @click="switchValue(1)" small color="green" outlined>{{ onLabel }}</v-btn>
      </div>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    value: [Boolean, Number],
    onLabel: String,
    halfLabel: String,
    offLabel: String,

    onchange: Function, // Must Return PROMISE
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    switchValue(v) {
      this.loading = true;
      this.onchange(v).then(() => {
        this.loading = false;
      });
    },
  },
};
</script>
