<template>
  <div>
    <TemplateSelect :limitTemplate="limitTemplate" @input="fillTemplate" />

    <v-divider class="my-3" />

    <SendToForm
      v-model="sendToContactList"
      v-if="!hideButtons"
      :supplierId="supplierId"
      @input="(v) => $emit('setcontacts', v)"
    />

    <EmailForm :value="value" @input="(v) => $emit('input', v)" :buttons="mailType === 'SINGLE' ? buttons : []" />

    <PrintWrapper v-model="printDialog" :css="printStyle">
      <template v-slot:header>
        <div class="white--text caption" v-if="multiDate">
          <span class="mx-1">Printing</span>
          <span>{{ carouselIndex + 1 }} / {{ selectedDate.length }}</span>
        </div>
      </template>

      <RoomingListPrint id="printWrapper" :date="displayDate" :specialList="specialRoomList" />
    </PrintWrapper>

    <TemplateDialog
      v-model="subjectDialog"
      :title="selectedTemplateSubjectTitle"
      :floatingText="floatingSubject"
      @set="(text) => update('subject', text)"
    />

    <TemplateDialog
      v-model="dialog"
      :title="selectedTemplateTitle"
      :floatingText="floatingText"
      @set="(text) => update('message', text)"
    />
  </div>
</template>

<script>
import Vue from "vue";
// import { ipcRenderer } from 'electron'
import { TemplateSelect, TemplateDialog, EmailTemplateService } from "@/modules/admin.email_template";
import { EmailForm, SendToForm, EmailService } from "@/modules/email";
import { RoomingListCss, RoomPrintStyle, RoomingListPrint, BookingRoomService } from "@/modules/bookings.rooms";
import { PrintWrapper, PrintService } from "@/modules/media.print";

const TIMER = 5000;

export default {
  name: "EmailWrapper",
  props: {
    value: Object,
    supplierId: String,
    hideButtons: Boolean,
    groupName: String,
    limitTemplate: Number,
    multiDate: Boolean,
    selectedDate: [String, Array],

    // CONFIG STUFF
    mailType: {
      required: true,
      type: String, // mailType => MASS, SINGLE
    },
    config: Object,
  },
  components: {
    EmailForm,
    SendToForm,
    TemplateSelect,
    TemplateDialog,

    PrintWrapper,
    RoomingListPrint,
  },
  data() {
    return {
      // Template fill stuff
      subjectDialog: false,
      dialog: false,
      selectedTemplateSubjectTitle: "",
      selectedTemplateTitle: "",
      floatingSubject: "",
      floatingText: "",
      sendToContactList: [],

      specialRoomList: [],

      printDialog: false,
      carouselIndex: 0,
      buttons: [
        {
          text: "Attach Rooming List",
          onclick: this.attachRoomingList,
        },
      ],
    };
  },
  computed: {
    printStyle() {
      return RoomingListCss;
    },
    displayDate() {
      if (!this.selectedDate || this.selectedDate === "ALL" || !this.selectedDate.length) return "ALL";
      if (!this.multiDate) return this.selectedDate;
      return this.selectedDate[this.carouselIndex].dateList;
    },
  },
  methods: {
    update(key, value) {
      Vue.set(this.value, key, value);
      this.$emit("input", this.value);
    },
    fillTemplate(found) {
      this.selectedTemplateSubjectTitle = "SUBJECT: " + found.name;
      this.selectedTemplateTitle = "MESSAGE: " + found.name;

      this.floatingSubject =
        this.mailType === "SINGLE" && found.subject.includes("#")
          ? found.subject.replace(/#/g, `# ${this.groupName}`)
          : found.subject;

      this.update("status", found.status_set);
      this.update("language", found.language);

      // Insert basic booking stuff
      // Supplier specific rooms will be added in the backend
      if (this.mailType === "SINGLE") {
        this.floatingText = EmailTemplateService.bookingBasicFill(found.text, {
          group_name: this.config.group_name,
          pax: this.config.pax,
        });
        this.floatingSubject = EmailTemplateService.bookingBasicFill(
          this.floatingSubject,
          {
            group_name: this.config.group_name,
            pax: this.config.pax,
          },
          true
        );
      } else {
        this.floatingText = found.text;
      }

      this.dialog = EmailTemplateService.hasReplaceContent(this.floatingText);
      if (!this.dialog) this.update("message", this.floatingText);

      this.subjectDialog = EmailTemplateService.hasReplaceContent(this.floatingSubject);
      if (!this.subjectDialog) this.update("subject", this.floatingSubject);
    },
    attachRoomingList() {
      this.printDialog = true;
      this.$root.$success("Printing Rooming List...");
      this.carouselIndex = 0;

      if (!this.multiDate) {
        setTimeout(() => {
          this._extractPDF(`${this.groupName}_RoomingList`);
        }, TIMER);
        return;
      }

      this._getDateLoop();
    },
    _getDateLoop() {
      setTimeout(() => {
        const selected = this.selectedDate[this.carouselIndex];
        const title = `${this.groupName}_RoomingList (${selected.date} ${selected.supplier_name})`;

        this._extractPDF(title, selected.supplier_id);
      }, TIMER);
    },
    _extractPDF(title, supplier_id) {
      const domElement = document.getElementById("printWrapper");
      const styles = RoomPrintStyle;

      // Get and inject images here
      Promise.all([PrintService.getImageUri("inspiritLogo")]).then((imgData) => {
        var sourceHTML = domElement.outerHTML;

        // Replace Inspirit Logo with base64 (if present)
        if (imgData[0]) {
          const nuImg = `<img src="${imgData[0]}" class="logo"/>`;
          sourceHTML = sourceHTML.replace(/(?<=<span id="inspiritLogo">)(.*?)(?=<\/span>)/gm, nuImg);
        }

        PrintService.extractWordDocumentContent(title, sourceHTML, styles, {
          width: 850,
          postOpen: false,
          supplier_id,
          config: {
            landscape: false,
            right: 25,
            left: 25,
          },
        });
      });
    },
  },
  mounted() {
    this.specialRoomList = BookingRoomService.getExtraRoomList(
      this.$store.getters["BookingDayStore/days"],
      this.$store.getters["BookingSupplierRoomStore/supplier_rooms"]
    );

    // console.log('TO DO')
    // ipcRenderer.on('print-success', (event, {filename, filepath, supplier_id}) => {
    // 	// Append to attachments
    // 	let temp = {...this.value}
    // 	temp.attachments.push({
    // 		filename,
    // 		supplier_id,
    // 		path: filepath
    // 	})
    // 	this.$emit('input', temp)

    // 	// Handle case of multiple booking lists
    // 	if(this.multiDate && this.carouselIndex+1 < this.selectedDate.length){
    // 		this.carouselIndex += 1
    // 		this._getDateLoop()
    // 		return;
    // 	}

    // 	this.printDialog = false;
    // 	this.$root.$success('Finished Printing')
    //    })
  },
};
</script>
