<template>
  <v-col cols="2" class="subtitle-2">
    <f-text :value="displayName" @input="(v) => setCustomName(v)" />
  </v-col>
</template>

<script>
import Vue from "vue";
export default {
  props: {
    product: Object,
    bookingSupplierMeta: Object,
  },
  computed: {
    productId() {
      return this.product.product_id || this.product.id;
    },
    displayName() {
      if (!this.bookingSupplierMeta.custom_names) return this.product.name;
      return this.bookingSupplierMeta.custom_names[this.productId] || this.product.name;
    },
  },
  methods: {
    setCustomName(value) {
      if (!this.bookingSupplierMeta.custom_names) Vue.set(this.bookingSupplierMeta, "custom_names", {});
      Vue.set(this.bookingSupplierMeta.custom_names, this.productId, value);
    },
  },
};
</script>
