<template>
  <v-dialog :value="value" width="100%">
    <v-card class="pa-3">
      <v-card-title>Update Confirmed Suppliers?</v-card-title>
      <v-card-text class="black--text">
        <!-- HEADER -->
        <v-row class="subtitle-2">
          <v-col cols="1">
            <!-- SELECT ALL -->
            <input type="checkbox" :checked="updateAll" @input="toggleSelectAll(!updateAll)" />
          </v-col>
          <v-col cols="3">Supplier</v-col>
          <v-col style="max-width: 200px; min-width: 200px">Dates</v-col>
          <v-col class="pa-0">
            <v-row class="pa-0">
              <v-col>Products</v-col>
              <v-col style="max-width: 155px; min-width: 155px" class="text-center">Rooms</v-col>
              <v-col style="max-width: 155px; min-width: 155px" class="text-center">PAX</v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-divider />

        <!-- MAIN -->
        <v-row v-for="confirmed in confirmedBookingSuppliers" :key="confirmed.bsid">
          <v-col cols="1">
            <!-- CHECKBOX -->
            <input type="checkbox" v-model="selected[confirmed.bsid]" :checked="selected[confirmed.bsid]" />
          </v-col>
          <v-col cols="3" class="subtitle-2">{{ confirmed.name }}</v-col>
          <v-col style="max-width: 200px; min-width: 200px"><d-date-list :list="confirmed.whatDays" /></v-col>

          <v-col class="pa-0">
            <v-row v-for="(prod, index) in confirmed.products" :key="confirmed.id + prod.id" class="pa-0">
              <v-col>
                {{ prod.productName }}
              </v-col>
              <v-col style="max-width: 310px; min-width: 310px">
                <v-row v-for="(room, dindex) in prod.locked" :key="confirmed.id + prod.id + dindex">
                  <v-col style="max-width: 150px; min-width: 150px" class="text-center">
                    <div v-if="confirmed.type_as === 'HOTEL'">
                      <d-combo-count :total="room.room_total" :extraCount="room.extraTotal" />
                      <v-icon>arrow_right_alt</v-icon>
                      <d-combo-count
                        class="primary--text font-weight-bold"
                        :total="room.updated.room_total"
                        :extraCount="room.updated.extraTotal"
                      />
                    </div>
                  </v-col>
                  <v-col style="max-width: 150px; min-width: 150px" class="text-center blue-grey lighten-5">
                    <d-combo-count :total="room.pax_total" :extraCount="room.extraTotal" />
                    <v-icon>arrow_right_alt</v-icon>
                    <d-combo-count
                      class="primary--text font-weight-bold"
                      :total="room.updated.pax_total"
                      :extraCount="room.updated.extraTotal"
                    />
                  </v-col>
                </v-row>
                <div v-if="prod.locked.length > 1" class="caption red--text text-center">
                  (Sync to remove discrepency)
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <div class="caption text-right">Note: + suppliers are estimates and may vary by day.</div>
      </v-card-text>
      <v-card-actions>
        <g-button label="Cancel" @onclick="$emit('input', false)" />
        <div class="flex-grow-1"></div>
        <g-button type="primary" label="Update" :loading="updateInProgress" @onclick="updateSupplierList" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BookingDayService from "@/modules/bookings.days/service";
import BookingRoomService from "../../service";
export default {
  props: {
    value: Boolean,
    specialList: Object,
  },
  data() {
    return {
      selected: {},
      updateAll: false,
      updateInProgress: false,
    };
  },
  watch: {
    value(v) {
      this.toggleSelectAll(true);
    },
  },
  computed: {
    confirmedBookingSuppliers() {
      const suppliers = this.$store.getters["BookingDayStore/suppliers"];
      const roomBreakdownData = BookingRoomService.getRoomingListBreakdown({
        bookingMetaData: this.$store.getters["BookingStore/data"],
        bookingRoomingList: this.$store.getters["BookingRoomStore/rooms"],
        bookingDays: this.$store.getters["BookingDayStore/days"],
        supplierRoomList: this.$store.getters["BookingSupplierRoomStore/supplier_rooms"],
      });

      const paxNum = this.$store.getters["BookingStore/pax_num"];
      const paxFromRoom = BookingRoomService.getPaxCount(
        this.$store.getters["BookingStore/data"].room_num,
        this.$store.getters["BookingRoomStore/rooms"]
      );
      const supplierRoomList = BookingRoomService.getExtraRoomList(
        this.$store.getters["BookingDayStore/days"],
        this.$store.getters["BookingSupplierRoomStore/supplier_rooms"]
      );

      return Object.values(suppliers)
        .filter((item) => {
          return item.status > 3 && ["HOTEL", "RESTAURANT", "EXCURSION"].includes(item.type_as);
        })
        .map((item) => {
          return {
            bsid: item.id,
            type_as: item.type_as,
            name: item.name,
            whatDays: this.$store.getters["BookingDayStore/whatDays"](item.type_as, item.id),
            products: Object.values(item.products).map((p) => {
              return {
                productName: p.name,
                locked:
                  item.type_as === "HOTEL"
                    ? _extractRoomlock(item.type_as, item.meta, roomBreakdownData)
                    : _extractPaxLock(item.type_as, p.id, item.meta, paxNum, paxFromRoom, supplierRoomList),
              };
            }),
          };
        });
    },
  },
  methods: {
    toggleSelectAll(toggle) {
      // Select or deselect all
      this.updateAll = toggle;
      Object.values(this.confirmedBookingSuppliers || {}).forEach((item) => (this.selected[item.bsid] = toggle));
    },
    updateSupplierList() {
      let queryBlob = {};
      const suppliers = this.$store.getters["BookingDayStore/suppliers"];
      Object.keys(this.selected).forEach((bsid) => {
        // If checked, then sync the ROOM & PAX count
        if (this.selected[bsid]) {
          queryBlob[bsid] = suppliers[bsid];
        }
      });

      this.loading = true;
      BookingDayService.syncConfirmedSuppliersInsideBooking(queryBlob, this.$store.getters, this.$store.dispatch)
        .then((v) => {
          this.loading = false;
          this.$roomingPing.$emit("sync");
          this.$emit("input", false);
        })
        .catch((err) => {
          this.loading = false;
          this.$root.$error(err);
        });
    },
  },
};

function _extractRoomlock(typeAs, bsMeta, roomBreakdownData) {
  // Extract & merge locked dates and their respective rooms
  let blob = {};
  let confirm = bsMeta.confirmed_roomcount || {};
  Object.keys(confirm)
    .map((date) => {
      return {
        date, // Used for calculating updated lock
        room_total: confirm[date].room_total,
        pax_total: confirm[date].pax_total,
        extraTotal: confirm[date].extraTotal,
      };
    })
    .forEach((item) => {
      // Cleaning out junk where sometimes rooms/pax are updated
      // Or don't sync correctly, so needs to be fixed here
      let key = `${item.room_total}-${item.pax_total}`;
      if (!blob[key]) {
        blob[key] = {
          room_total: item.room_total,
          pax_total: item.pax_total,
          extraTotal: item.extraTotal,
          updated: BookingRoomService.flattenAndGetRoomingInfo({
            confirmed: true,
            dateList: [item.date],
            ...roomBreakdownData,
            supplierCustomRoom: bsMeta && bsMeta.custom_room_num,
          }),
        };
      }
    });

  return Object.values(blob);
}

function _extractPaxLock(typeAs, productId, bsMeta, paxNum, paxFromRoom, supplierRoomList) {
  // These need to have their PAX updated
  // Will have different suppliers
  let confirm = (bsMeta.confirmed_count_total || {})[productId];
  if (confirm) {
    // Tour Guides, Drivers, etc.
    return [
      {
        pax_total: confirm.count || 1,
        extraTotal: (Object.values(confirm.tour_guides)[0] || 0) + (Object.values(confirm.transport)[0] || 0),
        updated: {
          pax_total: paxNum || paxFromRoom,
          extraTotal: supplierRoomList.ALL.length,
        },
      },
    ];
  }

  // No confirmed count (likely no rooming list)
  // Need to use PAX
  let alt = (bsMeta.count || {})[productId];

  if (alt || alt === 0) {
    // This was hard-coded by user, probably showing unsyned in booking suppleir
    return [
      {
        pax_total: Number(alt) || 1, // Zero count will usually be one
        extraTotal: supplierRoomList.ALL.length,
        updated: {
          pax_total: paxNum || paxFromRoom,
          extraTotal: supplierRoomList.ALL.length,
        },
      },
    ];
  }

  return [];
}
</script>
