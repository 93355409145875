<template>
  <div>
    <HistoryRow
      v-for="h in rows"
      :key="h.id"
      :userName="h.user_name"
      :timestamp="h.time_stamp"
      :env="h.env"
      :history="h"
      :db="h.db"
      :action="h.action"
      :dataChange="h.changes"
      :customMessage="h.message"
    />

    <infinite-loading
      slot="append"
      spinner="waveDots"
      :identifier="identifyScrollRef"
      @infinite="infiniteHandler"
      force-use-infinite-wrapper=".v-data-table__wrapper"
    >
      <div slot="no-more">
        <div class="overline ma-2">(End)</div>
      </div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>

<script>
import HistoryRow from "./_HistoryRow";
import InfiniteLoading from "vue-infinite-loading";
export default {
  props: {
    query: Object,
  },
  components: {
    HistoryRow,
    InfiniteLoading,
  },
  data() {
    return {
      rows: [],
      loading: true,
      page: 0,

      firstRender: false, // Infinite component does first query
      identifyScrollRef: new Date().getTime(),
      completed: false,
    };
  },
  watch: {
    query(searchQuery) {
      if (this.queryActive) return;

      if (this.firstRender) {
        this.resetScrolling();
        this.$emit("setComplete", false);
        this.identifyScrollRef = new Date().getTime();
      }
    },
  },
  methods: {
    resetScrolling() {
      this.page = 0;
      this.rows = [];
      this.queryActive = true;
    },
    queryDatabase(searchQuery) {
      this.loading = true;

      return this.$store
        .dispatch("AdminHistoryStore/getHistory", { page: this.page, query: searchQuery })
        .then((rows) => {
          this.loading = false;

          // First set of data
          if (!this.rows.length && rows.length) {
            this.rows = rows;
            return { rows: [], continue: true };
          }
          return { rows, continue: false };
        })
        .catch((err) => {
          this.loading = false;
          this.$root.$error(err);
        });
    },
    infiniteHandler($state) {
      this.queryDatabase(this.query)
        .then((data) => {
          this.firstRender = true;
          this.queryActive = false;

          if (data.rows.length || data.continue) {
            this.page += 1;
            this.rows.push(...data.rows);
            $state.loaded();

            // If less than 50, assume complete
            if (data.rows.length === 50 || data.continue) return;
          }

          this.completed = true;
          this.$emit("setComplete", true);
          $state.complete();
        })
        .catch((err) => {
          $state.error();
        });
    },
  },
  mounted() {},
};
</script>
