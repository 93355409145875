<template>
  <div :class="color" class="lighten-4">
    <v-row class="subtitle-2">
      <v-col style="max-width: 140px">
        {{ displayTimestamp }}
      </v-col>
      <v-col style="max-width: 90px">
        {{ displayAction }}
      </v-col>
      <v-col>
        <v-row>
          <span>{{ dbDisplayName }}</span>
          <v-icon>arrow_right_alt</v-icon>
          <a-truncate :text="displayItemName" :hoverText="displayItemName" />
        </v-row>
      </v-col>

      <v-col>
        {{ dateChangeMessage }}
      </v-col>

      <v-col class="d-flex justify-end">
        <span>{{ userName }}</span>
        <span class="ml-1">({{ env }})</span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import COLORS from "../config/EDIT_COLORS";
import DB_DISPLAYNAME from "../config/DB_DISPLAYNAME";
import moment from "moment";
import service from "../service";
export default {
  props: {
    history: Object, // Master object

    userName: String,
    timestamp: String,
    env: String,

    db: String,
    action: String,
    dataChange: Object,
    customMessage: String,
  },
  computed: {
    color() {
      return COLORS[this.action];
    },
    displayAction() {
      return (
        {
          MASS_DELETE: "DELETE",
        }[this.action] || this.action
      );
    },
    dbDisplayName() {
      return DB_DISPLAYNAME[this.db] || this.db;
    },
    displayTimestamp() {
      return moment(Number(this.timestamp)).format("MMM DD, YY hh:mm A");
    },
    displayItemName() {
      if (this.action === "DELETE") return `${this.customMessage}`;
      // BOOKING
      if (this.db.includes("booking") || this.db === "reminders") return this.history.group_name;

      // CLIENTS
      if (this.db === "client_payment_history") return `${this.history.client_name} / ${this.history.group_name}`;
      if (this.db.includes("client")) return this.history.client_name;

      // REPORTING
      if (this.db === "finance_documents") return this.history.group_name;
      if (this.db === "received_commisions") return `${this.history.group_name} / ${this.history.supplier_name}`;

      // PRODUCTS
      if (this.db === "products")
        return this.action === "UPDATE" ? this.history.product_name : this.history.supplier_name;

      // SUPPLIERS
      if (this.db.includes("supplier")) return this.history.supplier_name;

      return "NULL";
    },
    dateChangeMessage() {
      if (this.customMessage) return this.customMessage;

      if (this.action === "UPDATE") {
        if (!this.dataChange) return "";
        let before = this.dataChange.before;
        let after = this.dataChange.after;
        let list = Object.keys(before).reduce((arr, key) => {
          return [...arr, ...service.extractText(key, before, after)];
        }, []);

        return `Changed ${list.join(", ")}`;
      }

      return "";
    },
  },
};
</script>
