import moment from "moment";

const DEFAULT_CONFIG = {
  border: {
    top: { style: "thin" },
    left: { style: "thin" },
    bottom: { style: "thin" },
    right: { style: "thin" },
  },
  font: {
    name: "Calibri",
  },
  alignment: {
    wrapText: true,
    vertical: "middle",
    horizontal: "center",
  },
};

export default {
  DEFAULT_CONFIG,
  DATE: (day) => {
    return {
      value: moment(new Date(day.date)).utc().format("D MMM YYYY"),
    };
  },
  PAX: (pax) => {
    return {
      value: pax,
    };
  },
  DEFAULT_STRING: (value) => {
    return {
      value,
    };
  },
};
