<template>
  <v-btn icon small class="report-btn" @click="setEmail">
    <v-icon small color="blue">send</v-icon>
  </v-btn>
</template>

<script>
import { EmailService } from "@/modules/email";
export default {
  props: {
    clientData: Object,
  },
  methods: {
    setEmail() {
      // Set email form content
      this.$store.dispatch("EmailStore/pendMail", {
        //contact: this.contact,
        returnTo: this.$route.path,
        config: {
          title: this.clientData.name,
          clientId: this.clientData.id,
          client: true,
        },
      });
      // Switch to new page
      this.$router.push({
        name: "email",
      });
    },
  },
};
</script>
