<template>
  <v-row class="mb-5 top-row" align="center">
    <v-col cols="2" align-self="start" class="subtitle-2">Default Technical Text</v-col>
    <v-col>
      <f-textarea :value="displayTechnicalText" rows="2" @input="setTechnicalText" />
    </v-col>
  </v-row>
</template>

<script>
import { TechnicalService } from "@/modules/bookings.technical";
export default {
  props: {
    type: String,
    supplier: Object,
    products: Object,
    bookingSupplierMeta: Object,
  },
  computed: {
    displayTechnicalText() {
      return TechnicalService.getTechnicalDisplayName(
        this.type,
        this.supplier,
        this.products,
        this.bookingSupplierMeta
      );
    },
  },
  methods: {
    setTechnicalText(value) {
      this.$emit("set-bsmeta", { key: "technical_text", value });
    },
  },
};
</script>
