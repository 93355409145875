<template>
  <v-menu open-on-hover top offset-y>
    <template v-slot:activator="{ on }">
      <v-btn small icon v-on="on">
        <v-icon small color="grey lighten-1">note</v-icon>
      </v-btn>
    </template>

    <div class="hover-menu">
      <div v-html="cleanedNotes"></div>
    </div>
  </v-menu>
</template>

<script>
export default {
  props: {
    notes: String,
  },
  computed: {
    cleanedNotes() {
      return this.notes.replace(/\n/gm, "<br/>");
    },
  },
};
</script>
