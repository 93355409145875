<template>
  <v-row class="caption font-weight-bold" :class="colorcss" justify="center" align="center" style="height: 100%">
    <span v-if="hasSetCost">{{ formatedDiff }}</span>
    <span v-else>.</span>
  </v-row>
</template>

<script>
import money from "@/services/money";
export default {
  props: {
    hasSetCost: Boolean,
    delta: Number,
    positive: Boolean,
  },
  computed: {
    colorcss() {
      if (this.delta === 0) return "";
      if (this.delta && this.positive) return "red--text";
      if (this.delta && !this.positive) return "green--text";
      return "";
    },
    formatedDiff() {
      return `$ ${money.format(this.delta, { precision: 2 })}`;
    },
  },
};
</script>
