<template>
  <div class="pa-1">
    <div class="subtitle-2">History</div>
    <span v-if="!displayList.length" class="mx-2">- (None)</span>
    <v-row v-for="item in displayList" :key="item.id">
      <v-col cols="8">
        <span>
          <v-icon small left>event</v-icon>
          {{ item.date }}
        </span>
        <span v-if="item.isfull" class="green white--text pa-1">(Full)</span>
        <span v-if="item.ispartial" class="warning white--text pa-1">(Partial)</span>
      </v-col>
      <v-col class="d-flex justify-end">
        <span>{{ item.amount }}</span>
      </v-col>
      <v-col cols="1">
        <v-btn icon x-small @click="removeCommission(item.id)">
          <v-icon small>clear</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import money from "@/services/money";
import format from "@/services/format";
export default {
  props: {
    external: Boolean,
    history: Array,
  },
  computed: {
    displayList() {
      const v = JSON.parse(JSON.stringify(this.history));

      if (!v) return [];
      return v
        .sort((a, b) => {
          if (a.set_date < b.set_date) return 1;
          if (a.set_date > b.set_date) return -1;
          return 0;
        })
        .map((item) => {
          return {
            id: item.id,
            date: format.formatDate(item.set_date),
            amount: `$ ${money.format(item.total_received, { precision: 2 })}`,
            ispartial: item.received_type === "PARTIAL",
            isfull: item.received_type === "FULL",
          };
        });
    },
  },
  methods: {
    removeCommission(received_commission_id) {
      this.$store
        .dispatch("FinanceReportStore/removeCommissionReceived", {
          received_commission_id,
          skipCommit: this.external,
        })
        .then((v) => {
          this.$emit("pop-commission", received_commission_id);
        });
    },
  },
};
</script>
