<template>
  <div>
    <v-row
      class="subtitle-2"
      justify="space-between"
      align="center"
      style="height: 100%"
      v-for="env in totalEnvList"
      :key="env.label"
    >
      <span class="caption font-weight-bold">{{ env.label }}</span>
      <span class="caption font-weight-bold">{{ env.formatedTotal }}</span>
    </v-row>
  </div>
</template>

<script>
import money from "@/services/money";
export default {
  props: {
    totalBlob: Object,
  },
  computed: {
    totalEnvList() {
      return Object.keys(this.totalBlob)
        .filter((env) => env !== "COMBINED")
        .map((env) => {
          const source = money.getCurrencySource(env);
          return {
            label: env,
            formatedTotal:
              this.totalBlob && this.totalBlob[env] ? `$ ${money.format(this.totalBlob[env], source.money)}` : "$ --",
          };
        });
    },
  },
};
</script>
