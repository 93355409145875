<template>
  <div>
    <div class="ma-1 font-italic">
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">{{ config.icon }}</v-icon>
        </template>
        <span>{{ config.title }}</span>
      </v-tooltip>

      <span v-if="!hasData" class="caption pl-2 text--disabled">- (None)</span>
    </div>

    <div class="ring mx-2" v-if="hasData">
      <SupplierBlockRowWrapper
        v-for="(data, j) in displayData"
        :key="j"
        class="color-alt pl-1"
        top
        status
        showHover
        :d="data"
        :type="config.type"
        :daynum="dayNum"
        :date="date"
        :dbkey="config.dbkey"
        :cancelled="isCancelled"
        :roomBreakdownData="roomBreakdownData"
        @syncmeta="syncmeta"
        @altstatus="(v) => $emit('altstatus', v)"
        @editRoomName="(v) => $emit('editRoomName', v)"
        @editConfirm="(v) => $emit('editConfirm', v)"
      >
        <template v-slot:default="{ nestedSuppliers }">
          <SupplierBlockRowWrapper
            v-for="(subd, subindex) in nestedSuppliers"
            :key="dayNum + subd.id"
            @click.native="$emit('edit', { data: subd, type: type })"
            status
            smstatus
            showHover
            :d="subd"
            :type="config.type"
            :daynum="dayNum"
            :date="date"
            :dbkey="config.dbkey"
            :cancelled="isCancelled"
            :roomBreakdownData="roomBreakdownData"
            @syncmeta="syncmeta"
            @altstatus="(v) => $emit('altstatus', v)"
            @editRoomName="(v) => $emit('editRoomName', v)"
            @editConfirm="(v) => $emit('editConfirm', v)"
          />
        </template>
      </SupplierBlockRowWrapper>
    </div>
  </div>
</template>

<script>
import { SupplierBlockRowWrapper } from "@/modules/bookings.days";
import { BookingRoomService } from "@/modules/bookings.rooms";
import { BookingDayService } from "@/modules/bookings.days";
import moneyService from "@/services/money";

export default {
  props: {
    dayNum: Number,
    config: Object,
    dayData: Array,
    date: String,
    showCancelled: Boolean,
  },
  components: {
    SupplierBlockRowWrapper,
  },
  data() {
    return {
      roomBreakdownData: {},
    };
  },
  computed: {
    hasData() {
      return this.dayData ? this.dayData.length > 0 : false;
    },

    displayData() {
      return [...this.dayData]
        .map((supplierId) => {
          const convertedSupplier = this.suppliers[supplierId];
          const workingEnv = this.$store.getters["AccountStore/environment"];

          if (workingEnv !== convertedSupplier.supplier_env) {
            for (const key in convertedSupplier.products) {
              if (convertedSupplier.products[key].rate.converted && convertedSupplier.products[key].rate.value != 0) {
                continue;
              }
              let rate = moneyService.stripFormatting(convertedSupplier.products[key].rate.value);

              if (workingEnv == "USA") {
                rate = moneyService.getConvertedValue(rate, convertedSupplier.supplier_env, this.$store.getters);
              } else {
                rate = moneyService.getConvertToForeign(rate, workingEnv, this.$store.getters);
              }

              // currency rates aren't loaded yet
              if (rate == 0) {
                continue;
              }

              const currencySource = moneyService.getCurrencySource(workingEnv);

              convertedSupplier.products[key].rate.value = moneyService.format(rate, currencySource);
              convertedSupplier.products[key].rate.cur = currencySource.currency;
              convertedSupplier.products[key].rate.converted = true;
              convertedSupplier.name = `*${convertedSupplier.name}`;
            }
          }
          return convertedSupplier;
        })
        .filter((v) => {
          if (v.nest_type === "ALT") return false;
          if (this.showCancelled) return v;
          return v.status !== -1;
        })
        .sort((a, b) => {
          if (a.time > b.time) return 1;
          if (a.time < b.time) return -1;
          return 0;
        });
    },
    suppliers() {
      return this.$store.getters["BookingDayStore/suppliers"];
    },
    isCancelled() {
      return this.$store.getters["BookingStore/isCancelled"];
    },
  },
  methods: {
    _syncRooming() {
      this.roomBreakdownData = BookingRoomService.getRoomingListBreakdown({
        bookingMetaData: this.$store.getters["BookingStore/data"],
        bookingRoomingList: this.$store.getters["BookingRoomStore/rooms"],
        bookingDays: this.$store.getters["BookingDayStore/days"],
        supplierRoomList: this.$store.getters["BookingSupplierRoomStore/supplier_rooms"],
      });
    },
    syncmeta(v) {
      this._syncRooming();
      const dayinfo = this.$store.getters["BookingDayStore/daySupplierFlat"]("ALL");

      const paxNum = this.$store.getters["BookingStore/pax_num"];

      // STEP ONE -- UPDATE Count
      this._presync(v, paxNum)
        .then((s) => {
          const overwrite = true;
          const vm = this;

          return this.$store.dispatch("BookingDayStore/updateOneSupplierStatus", {
            bookingSupplierId: v.data.id,
            bookingSuppliers: this.suppliers,
            bookingId: this.$store.getters["BookingStore/booking_id"],
            supplierId: v.data.supplier_id,
            applyAll: v.data.meta && v.data.meta.bound,

            data: {
              status: v.data.status, // Keep status same
              meta: v.data.meta,
            },
            bookingPaxCount: paxNum,

            getLockedPricesFn(item) {
              return BookingDayService.setLockedPrices({
                item,
                status: v.data.status,
                storeGetters: vm.$store.getters,
                useDates: null, // SKIP, don't need locked price
                overwrite,
              });
            },
            getConfirmedRoomDataFn(item, existingMeta) {
              return BookingDayService.setConfirmedRoomData({
                item,
                status: v.data.status,
                existingMeta,
                roomBreakdownData: vm.roomBreakdownData,
                dayinfo,
                overwrite,
              });
            },
            getConfirmedCountFn(item, existingMeta) {
              return BookingDayService.setConfirmedCount({
                env: vm.$store.getters["AccountStore/environment"],
                item,
                status: v.data.status,
                existingMeta,
                days: vm.$store.getters["BookingDayStore/days"],
                bookingSuppliers: vm.$store.getters["BookingDayStore/suppliers"],
                // bookingSupplierRoomList: vm.$store.getters['BookingSupplierRoomStore/supplier_rooms'],
                dayinfo,
                overwrite,
              });
            },
          });
        })
        .then(() => {
          this.$root.$loading.end();
          this.$root.$success("Synced supplier");
          this.$roomingPing.$emit("sync");
        })
        .catch((err) => {
          this.$root.$error(err);
        });
    },
    _presync(tosync, paxNum) {
      let metaref = tosync.data.meta || {};

      if (tosync.key === "confirmed_count_total") {
        // Update the product count to reflect PAX (supplier count not needed here)
        return this.$store.dispatch("BookingDayStore/updateBookingSupplier", {
          bookingSupplierId: tosync.data.id,
          bookingId: this.$store.getters["BookingStore/booking_id"],
          data: {
            content: {
              meta: {
                ...metaref,
                count: Object.keys(tosync.data.products).reduce((map, key) => {
                  map[key] = paxNum;
                  return map;
                }, {}),
              },
            },
          },
        });
      }

      if (tosync.key === "confirmed_offcount") {
        return this.$store.dispatch("BookingDayStore/updateBookingSupplier", {
          bookingSupplierId: tosync.data.id,
          bookingId: this.$store.getters["BookingStore/booking_id"],
          data: {
            content: {
              meta: {
                ...metaref,
                confirmed_offcount: tosync.value,
              },
            },
          },
        });
      }

      return Promise.resolve();
    },
  },
  mounted() {
    this._syncRooming();
    this.$roomingPing.$on("sync", this._syncRooming);
  },
  beforeDestroy() {
    this.$roomingPing.$off("sync", this._syncRooming);
  },
};
</script>
