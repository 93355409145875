import _ from "lodash";
import { SuppliersMenu } from "@/config/menus";
import { BookingRoomService } from "@/modules/bookings.rooms";
import { ProductService } from "@/modules/products";
import DateFormat from "@/services/format";

export default {
  getSupplierDisplayName,
  flattenBookingDays,
  whatDays,
  whatDaysBySupplierId,
  whatProductMapping,

  setLockedPrices,
  setConfirmedCount,
  setConfirmedRoomData,
  getIncludedTourGuideTransportMealCount,

  syncConfirmedSuppliersInsideBooking,

  getBookingSupplierProductList,
};

function getSupplierDisplayName(supplier) {
  return (supplier.supplier_meta && supplier.supplier_meta.en_name) || supplier.name;
}

function flattenBookingDays(days, mod) {
  if (!days) return [];
  if (mod === "ALL") {
    return days.map((item) => {
      return {
        date: item.date,
        suppliers: SuppliersMenu.FULL.reduce((arr, x) => {
          if (x.alt === "ALL") return arr;
          if (!item[x.alt]) return arr;
          return [...arr, ...(item[x.alt] || [])];
        }, []),
      };
    });
  }
  return days.map((item) => {
    return {
      date: item.date,
      suppliers: item[mod],
    };
  });
}

function whatDays(typeUse, bookingSupplierId, days, full) {
  let flattened = days[0].suppliers;

  let dateList = [];
  // What days supplier appears
  if (flattened) {
    // Flattened already
    days.forEach((item) => {
      if (item.suppliers.includes(bookingSupplierId)) dateList.push(full ? item : item.date);
    });
  } else {
    // Sorted in modules
    // Needs typeUse to be defined
    const mod = SuppliersMenu.MOD_DBKEY[typeUse] || typeUse;
    days.forEach((item) => {
      if (item[mod].includes(bookingSupplierId)) dateList.push(full ? item : item.date);
    });
  }

  return dateList;
}

function whatDaysBySupplierId(typeUse, supplierId, days, bookingSuppliers) {
  const mod = SuppliersMenu.MOD_DBKEY[typeUse] || typeUse;
  return days
    .filter((day) => {
      return (day[mod] || []).filter((bsid) => bookingSuppliers[bsid].supplier_id === supplierId).length;
    })
    .map((day) => day.date);
}

function whatProductMapping(typeUse, days, suppliers, supplier_id) {
  const dbkey = SuppliersMenu.MOD_DBKEY[typeUse] || typeUse;

  // const days = this.$store.getters['BookingDayStore/days']
  const bsref = Object.values(suppliers)
    .filter((v) => v.supplier_id === supplier_id)
    .map((v) => v.id);

  const savedDates = days
    .filter((day) => {
      return _.intersection(day[dbkey] || [], bsref).length;
    })
    .map((day) => {
      return {
        date: day.date,
        bsid: (day[dbkey] || []).find((id) => bsref.includes(id)),
      };
    })
    .reduce((obj, item) => {
      obj[item.date] = item.bsid;
      return obj;
    }, {});

  return savedDates;
}

function setLockedPrices({ item, status, storeGetters, useDates, overwrite, errorPopup }) {
  if (overwrite) return null;
  if (status < 3 || item.locked_prices) return null;

  const products = item.products;

  return ProductService.lockPrices(products, item, storeGetters, useDates, errorPopup);
}

function setConfirmedRoomData({ item, status, existingMeta, roomBreakdownData, dayinfo, overwrite }) {
  if (item.type_as !== "HOTEL" || status < 4) return { confirmed_roomcount: null };

  const bookingSupplierId = item.id;

  // Don't overwrite confirmed room count
  if (existingMeta && existingMeta.confirmed_roomcount && !overwrite) return null;

  const dateList = whatDays(item.type_as, bookingSupplierId, dayinfo);
  const flattenedBreakdown = dateList.reduce((obj, date) => {
    let temp = BookingRoomService.flattenAndGetRoomingInfo({
      confirmed: status >= 4,
      dateList: [date],
      ...roomBreakdownData,
      supplierCustomRoom: existingMeta && existingMeta.custom_room_num,
    });

    obj[date] = temp;
    return obj;
  }, {});

  return { confirmed_roomcount: flattenedBreakdown };
}

function setConfirmedCount({ env, item, status, existingMeta, bookingSuppliers, days, dayinfo, overwrite }) {
  // Erase if settin back to lower status
  // !['RESTAURANT', 'EXCURSION'].includes(item.type_as) -- NOT SURE WHAT THIS DID
  if (status < 4) return { confirmed_count_total: null };
  // Don't overwrite confirmed
  if (existingMeta && existingMeta.confirmed_count_total && !overwrite) return null;

  const bsmeta = item.meta || {};
  let mapping = Object.keys(bsmeta.count || {}).reduce((m, item) => {
    // product_id => day => total count (including suppliers)
    m[item] = {};
    return m;
  }, {});

  if (item.type_as === "RESTAURANT" || (item.type_as === "EXCURSION" && env === "CDA")) {
    // Restaurants have tour guides + bus drivers added to meal sometimes
    // Add them here on what days they are present
    const daysOn = whatDays(item.type_as, item.id, days, true);
    let supplierCountMap = { tour_guides: {}, transport: {} };
    daysOn.forEach((day) => {
      let tourTransportCount = getIncludedTourGuideTransportMealCount(
        day,
        bookingSuppliers,
        bsmeta && bsmeta.exclude_suppliers && bsmeta.exclude_suppliers[day.date],
        { split: true }
      );

      supplierCountMap.tour_guides[day.date] = tourTransportCount.tour_guides;
      supplierCountMap.transport[day.date] = tourTransportCount.transport;
    });

    Object.keys(mapping).forEach((productId) => {
      mapping[productId] = {
        count: Number(item.meta.count[productId]),
        ...supplierCountMap,
      };
    });
  } else {
    Object.keys(mapping).forEach((productId) => {
      mapping[productId] = {
        count: Number(item.meta.count[productId]),
      };
    });
  }

  return {
    confirmed_count_total: mapping,
  };
}

function getIncludedTourGuideTransportMealCount(bookingDay, suppliers, excludeMapping, config) {
  // Extracts the meal count breakdown for a restaraunt
  const split = config && config.split;
  const notransport = config && config.notransport;
  if (!suppliers) return split ? { tour_guides: 0, transport: 0 } : 0;

  const tour_guides = (bookingDay.tour_guides || []).map((bsid) => suppliers[bsid]);
  const transport = notransport ? [] : (bookingDay.transport || []).map((bsid) => suppliers[bsid]);

  if (!split) {
    return _extractExcludeSupplierBlob([...tour_guides, ...transport], excludeMapping);
  }

  return {
    tour_guides: _extractExcludeSupplierBlob(tour_guides, excludeMapping),
    transport: _extractExcludeSupplierBlob(transport, excludeMapping),
  };
}

function _extractExcludeSupplierBlob(list, excludeMapping) {
  return (list || [])
    .filter((bs) => bs) // TEMP FIX -- If delete supplier, this will break
    .reduce((total, bs) => {
      if (excludeMapping && excludeMapping[bs.id]) return total;
      total +=
        Object.values((bs.meta && bs.meta.count) || {}).reduce(
          (all, productCount) => (all += Number(productCount || 1)),
          0
        ) || 1;
      return total;
    }, 0);
}

function removeOneBookingSupplier(state, date, maptype, bsid) {
  // REMOVE MAIN
  const dbkey = SuppliersMenu.MOD_DBKEY[maptype];
  const dayIndex = state.days.findIndex((v) => v.date === date);
  const day = state.days.find((v) => v.date === date);
  day[dbkey] = day[dbkey].filter((v) => v !== bsid);

  // HANDLE NESTED SUPPLIERS
  const alldbkey = SuppliersMenu.DBKEY;
  const nestedSuppliers = state.suppliers
    .filter((supplier) => {
      return supplier.parent_bsid === this.supplierInfo.supplierData.id;
    })
    .map((supplier) => supplier.id);
  day[dbkey] = day[dbkey].filter((v) => nestedSuppliers.includes[v]);

  // Mark to save
  state.daySaveQueue.days[dayIndex] = true;

  // Check if supplier doesn't exists in others
  var clearFromDB = true;
  state.days.forEach((d) => {
    if (d[dbkey].includes(bsid)) clearFromDB = false;
  });
}

/*********
 * THE GREAT CONFIRM SUPPLIER/SYNC MONSTER
 ********/
function syncConfirmedSuppliersInsideBooking(bookingSuppliers, storeGetters, storeDispatch) {
  // PARAMS
  //	bookingSupplierObject 	What will be synced
  // 	storeGetters			Vue's store
  // RETURN 	@{Promise}
  const bookingData = storeGetters["BookingStore/data"];
  const roomingList = storeGetters["BookingRoomStore/rooms"];
  const suppliers = storeGetters["BookingDayStore/suppliers"];
  const roomBreakdownData = BookingRoomService.getRoomingListBreakdown({
    bookingMetaData: storeGetters["BookingStore/data"],
    bookingRoomingList: storeGetters["BookingRoomStore/rooms"],
    bookingDays: storeGetters["BookingDayStore/days"],
    supplierRoomList: storeGetters["BookingSupplierRoomStore/supplier_rooms"],
  });
  const dayinfo = storeGetters["BookingDayStore/daySupplierFlat"]("ALL");

  const paxNum = storeGetters["BookingStore/pax_num"];
  const paxFromRoom = BookingRoomService.getPaxCount(
    storeGetters["BookingStore/data"].room_num,
    storeGetters["BookingRoomStore/rooms"]
  );
  const supplierRoomList = BookingRoomService.getExtraRoomList(
    storeGetters["BookingDayStore/days"],
    storeGetters["BookingSupplierRoomStore/supplier_rooms"]
  );

  let preArray = [],
    postArray = [];
  Object.values(bookingSuppliers).forEach((bs) => {
    preArray.push(_presyncConfirmedSupplier(bs.type_as, bs, paxNum, paxFromRoom, storeGetters, storeDispatch));
  });

  // Lineral to avoid scrambled data
  return preArray
    .reduce(function (prev, curr) {
      return prev.then(curr);
    }, Promise.resolve())
    .then((_) => {
      Object.values(bookingSuppliers).forEach((bs) => {
        postArray.push(
          _postsyncConfirmedSupplier(
            bs,
            bookingSuppliers,
            roomBreakdownData,
            dayinfo,
            paxNum,
            storeGetters,
            storeDispatch
          )
        );
      });
      return postArray.reduce(function (prev, curr) {
        return prev.then(curr);
      }, Promise.resolve());
    });
}

function _presyncConfirmedSupplier(typeAs, data, paxNum, paxFromRoom, storeGetters, storeDispatch) {
  if (["RESTAURANT", "EXCURSION"].includes(typeAs)) {
    let metaref = data.meta || {};
    if (metaref.confirmed_offcount) return Promise.resolve();

    // Update the product count to reflect PAX (supplier count not needed here)
    return storeDispatch("BookingDayStore/updateBookingSupplier", {
      bookingSupplierId: data.id,
      bookingId: storeGetters["BookingStore/booking_id"],
      data: {
        content: {
          meta: {
            ...metaref,
            count: Object.keys(data.products).reduce((map, key) => {
              if (!(metaref.count_lock && metaref.count_lock[key])) {
                map[key] = paxNum || paxFromRoom;
              }
              return map;
            }, {}),
          },
        },
      },
    });
  }

  return Promise.resolve();
}

function _postsyncConfirmedSupplier(data, suppliers, roomBreakdownData, dayinfo, paxNum, storeGetters, storeDispatch) {
  // Don't need to reset confirmed count + rooms, etc if still pending confirmation
  if (data.status < 4) return Promise.resolve();

  const overwrite = true;
  const vm = this;

  return storeDispatch("BookingDayStore/updateOneSupplierStatus", {
    bookingSupplierId: data.id,
    bookingSuppliers: suppliers,
    bookingId: storeGetters["BookingStore/booking_id"],
    supplierId: data.supplier_id,
    applyAll: data.meta && data.meta.bound,

    data: {
      status: data.status, // Keep status same
      meta: data.meta,
    },
    getLockedPricesFn(item) {
      return setLockedPrices({
        item,
        status: data.status,
        storeGetters,
        useDates: null, // SKIP, don't need locked price
        overwrite,
      });
    },
    getConfirmedRoomDataFn(item, existingMeta) {
      return setConfirmedRoomData({
        item,
        status: data.status,
        existingMeta,
        roomBreakdownData,
        dayinfo,
        overwrite,
      });
    },
    getConfirmedCountFn(item, existingMeta) {
      return setConfirmedCount({
        env: storeGetters["AccountStore/environment"],
        item,
        status: data.status,
        existingMeta,
        days: storeGetters["BookingDayStore/days"],
        bookingSuppliers: storeGetters["BookingDayStore/suppliers"],
        // bookingSupplierRoomList: vm.$store.getters['BookingSupplierRoomStore/supplier_rooms'],
        dayinfo,
        overwrite,
      });
    },
  });
}

/*********
 * COMPILE PRODUCT RATE
 ********/
function getBookingSupplierProductList(bookingData, bs, date, storeGetters) {
  var t = [];
  if (bs.products) {
    let name, price, refkey, count, groupFit, product, quotePrice;
    const vm = bs;
    Object.keys(bs.products).forEach((key) => {
      product = bs.products[key];
      price = _extractRateContent(bs, bs.products[key], date, storeGetters);
      count = bs.meta && bs.meta.count && bs.meta.count[key];
      name = bs.meta && bs.meta.custom_names && bs.meta.custom_names[key];
      groupFit = bs.products[key].group_fit;
      quotePrice = bs.meta && bs.meta.quote_price && bs.meta.quote_price[key];

      t.push({
        id: product.id,
        name: `${name || bs.products[key].name}`,
        hoverName: `${name || bs.products[key].name} (${groupFit})`,
        rate: price.rate,
        convert: price.convert,
        count: count || 1,
        original: price.original,

        tax_inclusive: product.tax_inclusive,
        tax: (product.meta && product.meta.custom_tax) || (bs.supplier_meta && bs.supplier_meta.tax),
        meta: product.meta,
        quotePrice: quotePrice != null ? ProductService.formatPrice(quotePrice) : null,
      });
    });
  }
  return t;
}

function _extractRateContent(bs, product, date, storeGetters) {
  let locked = bs.locked_prices && bs.locked_prices[product.id];
  const useDates = bs.meta && bs.meta.use_dates;
  const altPrices = !bs.meta ? {} : { ...bs.meta.custom_prices } || {};
  let dateDefault = {
    from_month: DateFormat.dateToObj(date).month,
    from_day: DateFormat.dateToObj(date).day,
  };
  const price = ProductService.getPrice(
    product,
    bs.supplier_meta,
    bs.country,
    altPrices,
    storeGetters,
    true,
    useDates || dateDefault,
    bs.meta
  );

  if (!product.seasonal) {
    return {
      rate: locked ? ProductService.formatPrice(locked.totalPrice) : price.totalPrice_print,
      convert:
        locked && locked.totalConvert ? ProductService.formatConvert(locked.totalConvert) : price.totalConvert_print,
      original: locked && locked.originalPrice ? ProductService.formatPrice(locked.originalPrice) : price.price_print,
    };
  }

  let result = "",
    convert = "",
    original = "",
    temp;
  price.forEach((p, index) => {
    locked = bs.locked_prices && bs.locked_prices[p.id] && bs.locked_prices[p.id][index];
    temp = {
      rate: locked ? ProductService.formatPrice(locked.totalPrice) : p.totalPrice_print,
      convert: locked && locked.totalConvert ? ProductService.formatConvert(locked.totalConvert) : p.totalConvert_print,
      original: locked && locked.originalPrice ? ProductService.formatPrice(locked.originalPrice) : p.price_print,
    };

    convert += `${index >= 1 ? ";<br/>" : ""}${temp.convert}`;
    result += `${index >= 1 ? ";<br/>" : ""}${temp.rate}`;
    original += `${index >= 1 ? ";<br/>" : ""}${temp.original}`;
  });
  return { rate: result, convert, original };
}
