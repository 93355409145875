import moment from "moment";

const SET_YEAR = "SET_YEAR";

getDefaultYear();

const state = {
  workingYear: localStorage.getItem("workingYear") || `${getDefaultYear()}`,
};

const mutations = {
  SET_YEAR(state, year) {
    state.workingYear = year;
    localStorage.setItem("workingYear", year);
  },
};

const actions = {
  setDefaultProductYear({ commit }, year) {
    commit(SET_YEAR, `${year}`);
  },
};

const getters = {
  workingYear: (state) => state.workingYear,
};

function getDefaultYear() {
  // Before June first, current year
  // After June first, next year
  const CURRENT_YEAR = moment().year();
  return moment().isBefore(moment(CURRENT_YEAR + "-06-01")) ? CURRENT_YEAR : CURRENT_YEAR + 1;
}

export default {
  // NOT NAMESPACED,
  state,
  mutations,
  actions,
  getters,
};
