<template>
  <v-row class="caption">
    <v-col cols="3"> Parking ({{ types }}) </v-col>
    <v-col style="min-width: 200px; max-width: 200px">
      {{ parking.rate }}
    </v-col>
    <v-col>
      <span>x {{ parking.totalCountOfItems }}</span>
    </v-col>
    <v-col style="min-width: 90px; max-width: 90px">
      <span>x {{ parking.dayCount || 0 }} Nights</span>
    </v-col>
    <v-col class="d-flex justify-center" style="min-width: 140px; max-width: 140px; border: 1px solid lightgrey">
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <span v-on="on">{{ compiledTotal }}</span>
        </template>
        <div>
          <div>{{ displayTotalBreakdown }}</div>
        </div>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
import money from "@/services/money";
export default {
  props: {
    types: String,
    parking: Object,
  },
  computed: {
    displayTotalBreakdown() {
      let total = this.parking.totalCountOfItems;
      return `= ${total} * ${this.parking.rate}`;
    },
    compiledTotal() {
      if (!this.parking.compiledTotal) return "$ --";
      return `$ ${money.format(this.parking.compiledTotal, { precision: 2 })}`;
    },
  },
};
</script>
