<template>
  <v-data-table
    disable-pagination
    hide-default-footer
    :headers="headers"
    :items="rows"
    :height="height"
    :loading="loading"
    loading-text="Fetching..."
    :custom-sort="customSort"
    class="database-table"
  >
    <template v-slot:body="{ items }">
      <tbody>
        <tr v-for="item in items" :key="item.id" class="database-row" @click="goToPage(item)">
          <td>
            <span>{{ item.client_name }}</span>
          </td>
          <td>
            <span>{{ getGroupName(item) }}</span>
          </td>
          <td>
            <span>{{ getDate(item.from) }}</span>

            <v-tooltip top v-if="item.need_sup_update">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="mx-1" small color="warning">error_outline</v-icon>
              </template>
              <span>Date changed. Suppliers must be notified.</span>
            </v-tooltip>
          </td>
          <td>{{ getDate(item.to) }}</td>
          <td>
            <div class="d-flex justify-space-between">
              <span>{{ getNightCount(item.from, item.to) }}</span>
              <RoomingListIndicator :roomingList="item.rooming_data" />
            </div>
          </td>
          <td>{{ item.where_start }}</td>
          <td>{{ item.where_end }}</td>
          <td>
            <ConfirmedChip :confirmed="item.confirmed" :totalSuppliers="item.totalSuppliers" />
          </td>
          <td>
            <ReminderList @click.native="(e) => goToReminderPage(e, item)" :reminders="item.reminders" />
          </td>
        </tr>
      </tbody>

      <infinite-loading
        slot="append"
        spinner="waveDots"
        :identifier="identifyScrollRef"
        @infinite="infiniteHandler"
        force-use-infinite-wrapper=".v-data-table__wrapper"
      >
        <div slot="no-more">
          <div class="overline ma-2">(End)</div>
        </div>
        <div slot="no-results"></div>
      </infinite-loading>
    </template>
  </v-data-table>
</template>

<script>
import format from "@/services/format";
import InfiniteLoading from "vue-infinite-loading";
import ConfirmedChip from "./_ConfirmedChip";
import ReminderList from "./_ReminderList";
import RoomingListIndicator from "./_RoomingListIndicator";
export default {
  props: {
    query: Object,
    height: [Number, String],
  },
  components: {
    InfiniteLoading,
    ConfirmedChip,
    ReminderList,
    RoomingListIndicator,
  },
  data() {
    return {
      headers: [
        {
          text: "Client",
          value: "client_name",
        },
        {
          text: "Group Name",
          value: "group_name",
        },
        {
          text: "Date From",
          value: "from",
        },
        {
          text: "Date To",
          value: "to",
        },
        {
          text: "Nights",
          value: "nights", // None (calculated)
          width: 75,
        },
        {
          text: "City Start",
          value: "where_start",
        },
        {
          text: "City End",
          value: "where_end",
        },
        {
          text: "Confirmed",
          value: "confirmed",
        },
        {
          text: "Reminders",
          value: "reminders",
        },
      ],

      rows: [],
      loading: true,
      page: 0,

      firstRender: false, // Infinite component does first query
      identifyScrollRef: new Date().getTime(),
      completed: false,
    };
  },
  watch: {
    query(searchQuery) {
      if (this.queryActive) return;

      if (this.firstRender) {
        this.resetScrolling();
        this.$emit("setComplete", false);
        this.identifyScrollRef = new Date().getTime();
      }
    },
  },
  methods: {
    getGroupName(item) {
      const name = item.group_name;
      if (item.pending_state && item.status === 1) return `${name} (NEW DATES TO ADD)`;
      if (item.pending_state && item.status === 0) return `${name} (OLD DATES TO REMOVE)`;
      return name;
    },
    getDate(date) {
      return format.formatDate(date);
    },
    getNightCount(from, to) {
      return format.getNumberOfDays(from, to);
    },
    goToPage(item) {
      this.$router.push({
        name: "bookings_view",
        params: {
          booking_id: item.id,
        },
      });
    },
    goToReminderPage(e, item) {
      e.stopPropagation();
      e.preventDefault();
      this.$router.push({
        name: "booking_reminder",
        params: {
          booking_id: item.id,
        },
      });
    },

    getPercentage(a) {
      if (!a.totalSuppliers) return 0;
      return a.confirmed / a.totalSuppliers;
    },
    customSort(items, sortBy, isDesc) {
      items.sort((a, b) => {
        let aval = a[sortBy],
          bval = b[sortBy];
        if (sortBy[0] === "confirmed") {
          if (aval) aval = this.getPercentage(a);
          if (bval) bval = this.getPercentage(b);
        }

        if (!isDesc[0]) {
          return aval < bval ? -1 : 1;
        } else {
          return bval < aval ? -1 : 1;
        }
      });
      return items;
    },

    resetScrolling() {
      this.page = 0;
      this.rows = [];
      this.queryActive = true;
    },
    queryDatabase(searchQuery) {
      this.loading = true;

      return this.$store
        .dispatch("BookingStore/getBookings", {
          page: this.page,
          query: searchQuery,
          extra: {
            attachRooms: true,
          },
        })
        .then((rows) => {
          this.loading = false;

          // First set of data
          if (!this.rows.length && rows.length) {
            this.rows = rows;
            return { rows: [], continue: true };
          }
          return { rows, continue: false };
        })
        .catch((err) => {
          this.loading = false;
          this.$root.$error(err);
        });
    },

    infiniteHandler($state) {
      this.queryDatabase(this.query)
        .then((data) => {
          this.firstRender = true;
          this.queryActive = false;

          if (data.rows.length || data.continue) {
            this.page += 1;
            this.rows.push(...data.rows);
            $state.loaded();

            // If less than 10, assume complete
            if (data.rows.length === 10 || data.continue) return;
          }

          this.completed = true;
          this.$emit("setComplete", true);
          $state.complete();
        })
        .catch((err) => {
          $state.error();
        });
    },
  },
};
</script>
