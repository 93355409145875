<template>
  <a-card>
    <a-field :config="config.type" v-model="type" />
  </a-card>
</template>

<script>
import config from "../config/clientConfig";

export default {
  data() {
    return {
      config: config,
    };
  },
  computed: {
    type: {
      get() {
        return this.get("type");
      },
      set(newValue) {
        return this.set("type", newValue);
      },
    },
  },
  methods: {
    get(val) {
      return this.$store.getters["ClientStore/active"][val];
    },
    set(key, val) {
      return this.$store.dispatch("ClientStore/setUpdate", { key, val });
    },
  },
};
</script>
