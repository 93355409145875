<template>
  <div>
    <v-row justify="space-between" class="ma-2 top-row">
      <g-button
        :label="showSearch ? 'hide' : 'show search'"
        :icon="showSearch ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
        @onclick="toggleSearch"
      />
    </v-row>

    <DatabaseSearch v-show="showSearch" type="suppliers" :mod="module" @search="updateQuery" />

    <DatabaseTable
      select-only
      select-into
      class="table"
      dbConfig="suppliers"
      :dbSource="module"
      :query="dbQuery"
      ref="supDBwrapper"
      :height="dbHeight"
      @gointo="selectSupplier"
    />

    <v-row class="mx-0 pt-3 button-row" justify="start">
      <g-button label="Close" type="black" @onclick="$emit('close')" />
    </v-row>
  </div>
</template>

<script>
import { DatabaseTable, DatabaseSearch } from "@/modules/database";
export default {
  props: {
    module: String,
  },
  components: {
    DatabaseTable,
    DatabaseSearch,
  },
  data() {
    return {
      dbHeight: "0px",
      showSearch: true,

      // Supplier
      dbQuery: {},
      selectedSupplier: false,
    };
  },
  methods: {
    updateQuery(query) {
      this.dbQuery = { ...query };
    },
    selectSupplier(supplier) {
      let filter = {
        multiple: true,
        list: [
          {
            value: supplier.id,
            columns: ["supplier_id"],
          },
        ],
      };

      this.$emit("update", {
        supplier: supplier,
        productFilter: filter,
      });
    },

    toggleSearch() {
      this.showSearch = !this.showSearch;
      this.$nextTick().then(function (res) {
        let OFFSET = 50;
        if (res.showSearch) OFFSET += 175;
        if (!res.$refs.supDBwrapper) return;
        res.dbHeight = res.$refs.supDBwrapper.$el.clientHeight - OFFSET + "px";
      });
    },
  },
  mounted() {
    this.$nextTick().then(function (res) {
      let OFFSET = 50;
      if (res.showSearch) OFFSET += 175;
      if (!res.$refs.supDBwrapper) return;
      res.dbHeight = res.$refs.supDBwrapper.$el.clientHeight - OFFSET + "px";
    });
  },
};
</script>
