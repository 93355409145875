<template>
  <v-row nowrap>
    <v-col class="pa-0">
      <FNumber :value="num" @input="(v) => ejectValid('num', v)" />
    </v-col>
    <v-col cols="1" class="px-2">/</v-col>
    <v-col class="pa-0">
      <FNumber :value="denom" @input="(v) => ejectValid('denom', v)" />
    </v-col>
  </v-row>
</template>

<script>
import FNumber from "../items/FNumber";
export default {
  props: {
    value: Object, // Fraction like this: 1/2
  },
  components: {
    FNumber,
  },
  computed: {
    num() {
      return this.value ? this.value.value : 1;
    },
    denom() {
      return this.value ? this.value.per : 1;
    },
  },
  methods: {
    ejectValid(type, v) {
      this.$emit("input", {
        type: "RATE",
        value: type === "num" ? v : this.num,
        per: type === "denom" ? v : this.denom,
      });
    },
  },
};
</script>
