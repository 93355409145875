export default {
  tax: {
    cols: 4,
    label: "Tax",
    inputType: "PERCENT",
  },
  parking: {
    cols: 4,
    label: "Parking",
    inputType: "CYOA",
    choosePath: {
      free: "TEXT",
      $: "COST",
      "not offered": "TEXT",
    },
    restrictEnv: ["JPN"],
  },
  commission: {
    cols: 4,
    label: "Commission",
    inputType: "CYOA",
    choosePath: {
      yes: "PERCENT",
      no: "TEXT",
    },
  },
  payment_type: {
    cols: 4,
    label: "Payment",
    inputType: "CYOA",
    choosePath: {
      wire: "TEXT",
      "credit card": "TEXT",
      cash: "TEXT",
      check: "TEXT",
    },
  },
  foc: {
    cols: 4,
    label: "FOC",
    inputType: "CYOA",
    choosePath: {
      yes: "RATE",
      no: "TEXT",
    },
  },
};
