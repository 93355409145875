<template>
  <div class="d-flex flex-row align-center">
    <a-group icon="event" class="px-2 py-2">
      <div class="d-flex flex-row align-center">
        <span>From</span>
        <year-picker class="ml-2" style="width: 105px" v-model="data.period.startYear" @input="updateStartDate" />
        <month-picker class="ml-2" style="width: 135px" v-model="data.period.startMonth" @input="updateStartDate" />
        <day-picker
          class="ml-2"
          style="width: 90px"
          :month="data.period.startMonth"
          v-model="data.period.startDay"
          @input="updateStartDate"
        />
      </div>
    </a-group>

    <a-group class="px-2 py-2 ml-2">
      <div class="d-flex flex-row align-center">
        <span class="caption">Nb of nights</span>
        <f-number class="ml-2" v-model="data.nights" @input="updateNumberOfNights" />
      </div>
    </a-group>

    <a-group icon="event2" class="px-2 py-2 ml-2">
      <div class="d-flex flex-row align-center">
        <span>To</span>
        <year-picker class="ml-2" style="width: 105px" v-model="data.period.endYear" @input="updateEndDate" />
        <month-picker class="ml-2" style="width: 135px" v-model="data.period.endMonth" @input="updateEndDate" />
        <day-picker
          class="ml-2"
          style="width: 90px"
          :month="data.period.endMonth"
          v-model="data.period.endDay"
          @input="updateEndDate"
        />
      </div>
    </a-group>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    value: Object,
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    updateStartDate() {
      if (!this.id) {
        // Set end year to match the start year for editing quote
        this.data.period.endYear = this.data.period.startYear;
      }
      if (!this.data.period.startMonth) {
        // Clear start day if start month is null
        this.data.period.startDay = null;
      }
      this.autocompletePeriod({ isStartDateUpdated: true });
      this.$emit("input", this.data);
    },

    updateNumberOfNights() {
      this.autocompletePeriod({ isNumberOfNightsUpdated: true });
      this.$emit("input", this.data);
    },

    updateEndDate() {
      this.autocompletePeriod({ isEndDateUpdated: true });
      this.$emit("input", this.data);
    },

    autocompletePeriod({ isStartDateUpdated = false, isEndDateUpdated = false, isNumberOfNightsUpdated = false }) {
      const { period, nights } = this.data;
      const { startYear, startMonth, startDay, endYear, endMonth, endDay } = period;

      if (!isNumberOfNightsUpdated && startYear && startMonth && startDay && endYear && endMonth && endDay) {
        // Calculate and set number of nights
        const startDate = new Date(`${startYear}-${startMonth}-${startDay}`);
        const endDate = new Date(`${endYear}-${endMonth}-${endDay}`);
        const calculatedNights = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
        this.data.nights = calculatedNights >= 0 ? calculatedNights : "";
      } else if (!isEndDateUpdated && startYear && startMonth && startDay && nights) {
        // Calculate and set end date
        const startDate = new Date(`${startYear}-${startMonth}-${startDay}`);
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + Number(nights));

        Object.assign(this.data.period, {
          endYear: endDate.getFullYear().toString(),
          endMonth: endDate.getMonth() + 1,
          endDay: endDate.getDate(),
        });
      } else if (!isStartDateUpdated && endYear && endMonth && endDay && nights) {
        // Calculate and set start date
        const endDate = new Date(`${endYear}-${endMonth}-${endDay}`);
        const startDate = new Date(endDate);
        startDate.setDate(endDate.getDate() - nights);

        Object.assign(this.data.period, {
          startYear: startDate.getFullYear().toString(),
          startMonth: startDate.getMonth() + 1,
          startDay: startDate.getDate(),
        });
      } else {
        // Do something
      }
    },
  },
};
</script>
