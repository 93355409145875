<template>
  <a-card>
    <v-col>
      <h3>Total Costs</h3>
      <v-row>
        <table v-if="data.peopleRanges.length > 0">
          <thead>
            <tr>
              <th>No</th>
              <th>Min</th>
              <th>Max</th>
              <th></th>
              <th class="px-2">Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(peopleRange, index) in data.peopleRanges" :key="index">
              <td>{{ index + 1 }}</td>
              <td>
                <f-number
                  :value="peopleRange.min"
                  @input="(v) => updatePeopleRangeByIndex(v, index)"
                  @keydown="(e) => onKeyDown(e)"
                />
              </td>
              <td>
                <f-number v-model="peopleRange.max" @keydown="(e) => onKeyDown(e)" />
              </td>
              <td>
                <QuotePax
                  :key="index"
                  :value="Number(getTotalCostByPax()[index]).toFixed(2)"
                  :disabled="true"
                  :unit="'per PAX'"
                />
              </td>
              <td align="center">
                <v-btn icon small @click="removePeopleRange(index)">
                  <v-icon>clear</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </table>
      </v-row>
      <v-row v-if="data.peopleRanges.length < 10" align="center">
        <v-btn small text color="primary" @click="addPeopleRange">+ Add new line</v-btn>
      </v-row>
    </v-col>
  </a-card>
</template>

<script>
import QuotePax from "./QuotePax";
import quoteUtils from "@/modules/quotes/components/utils";
export default {
  components: {
    QuotePax,
  },
  props: {
    value: Object,
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    getTotalCostByPax() {
      return quoteUtils.getTotalCostByPax(this.data.variableCosts, this.data.fixedCosts, this.data.peopleRanges);
    },
    addPeopleRange() {
      this.data.peopleRanges.push({ min: 0, max: 0 });
      this.data.margin.percentDynamic.push(0);
      this.data.margin.perPax.push(0);
      this.data.margin.perGroup.push(0);
    },
    removePeopleRange(index) {
      this.data.peopleRanges.splice(index, 1);
      this.data.margin.percentDynamic.splice(index, 1);
      this.data.margin.perPax.splice(index, 1);
      this.data.margin.perGroup.splice(index, 1);
    },
    updatePeopleRangeByIndex(v, index) {
      this.data.peopleRanges[index].min = v;
    },
    onKeyDown(e) {
      if (e.key === "." || e.key === "-" || e.key === "+") e.preventDefault();
    },
  },
};
</script>
