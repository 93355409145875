<template>
  <elwrapper :label="label">
    <v-row>
      <v-col style="max-width: 150px">
        <f-date :disabled="disabled" :datewall="startWall" :value="value" @input="updateDate" />
      </v-col>
      <v-col class="d-flex">
        <span class="caption px-2">or</span>
        <f-number :disabled="disabled" v-model="dayCount" @input="syncDayAndDate" />
        <span class="caption px-2">days before</span>
      </v-col>
    </v-row>
  </elwrapper>
</template>

<script>
import moment from "moment";
export default {
  props: {
    value: String,
    label: String,
    datewall: String,
    startDay: String,
    autoBeforeDays: [String, Number],
    disabled: Boolean,
  },
  data() {
    return {
      dayCount: Number(this.autoBeforeDays) || 0,
    };
  },
  watch: {
    startDay(value) {
      this.syncDayAndDate(value);
    },
  },
  computed: {
    startWall() {
      return moment().format("YYYY-MM-DD");
    },
  },
  methods: {
    updateDate(date) {
      this.dayCount = moment(this.startDay).diff(date, "days");
      this.$emit("input", date);
    },
    syncDayAndDate() {
      // Booking start date minus day count
      let bookingStart = moment(this.startDay).subtract(Number(this.dayCount || "0"), "days");

      // If reminder date is set in pass, then set date to current date
      let adjust = moment().diff(bookingStart, "days");

      let use = adjust > 0 ? this.startWall : bookingStart.format("YYYY-MM-DD");

      this.$emit("input", use);
    },
  },
};
</script>
