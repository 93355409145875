<template>
  <v-card class="technical-wrapper">
    <v-row align="center" justify="space-between" class="mx-0 px-2 grey darken-3 pdf-controls">
      <div class="d-flex">
        <span v-if="isCancelled" class="white--text">Edit disabled</span>
        <pcheck v-else label="Edit Mode" v-model="editMode" class="white--text" />

        <v-dialog v-model="dialog" width="300">
          <template v-slot:activator="{ on }">
            <g-button :disabled="!editMode" label="Reset" icon="autorenew" @onclick="dialog = true" />
          </template>

          <v-card class="pa-3">
            <v-card-title>Are you sure?</v-card-title>
            <v-card-text class="black--text">
              <p>
                This will completely erase all custom changes and reset the technical to its default/original view.
                <span class="red--text">You cannot undo a reset once saved.</span>
              </p>
              <p>If you want to keep some, but remove others, please use "Clean Merge"</p>
            </v-card-text>
            <v-row class="justify-space-between">
              <g-button label="No, Keep" icon="clear" @onclick="dialog = false" />
              <g-button label="Yes, Reset" type="error" icon="autorenew" @onclick="resetTechnical" />
            </v-row>
          </v-card>
        </v-dialog>

        <CompareCleanDialog
          :editMode="editMode"
          :days="days"
          :compiledDays="compiledDays"
          :savedTechnical="savedTechnical"
          @overwrite="overwriteTechnical"
        />
      </div>

      <div class="d-flex">
        <g-button label="Undo" icon="undo" :disabled="!editMode" @onclick="toggleUndo = !toggleUndo" />
        <save-button :disabled="!editMode" :onsave="updateTechnical" class="mr-3" />
        <g-button label="Print" icon="print" :disabled="editMode" @onclick="printDocument" />
      </div>
    </v-row>

    <div class="technical-scroll grey darken-2">
      <div id="printWrapper" class="pdf">
        <TechnicalWrapper
          :editMode="editMode"
          :contactMap="contactMap"
          :days="days"
          :savedTechnical="savedTechnical"
          :compiledDays="compiledDays"
          :toggleUndo="toggleUndo"
          @update="setTeachnicalUpdate"
        />
      </div>
    </div>
  </v-card>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import { PrintService } from "@/modules/media.print";
import {
  TechnicalWrapper,
  TechnicalService,
  TechnicalPrintCss,
  CompareCleanDialog,
} from "@/modules/bookings.technical";
export default {
  components: {
    TechnicalWrapper,
    CompareCleanDialog,
  },
  data() {
    return {
      dialog: false,
      editMode: false,

      compiledDays: {},
      contactMap: {},
      savedTechnical: {
        days: {},
      },

      toggleUndo: false,
    };
  },
  computed: {
    days() {
      return this.$store.getters["BookingDayStore/days"]
        .map((x) => {
          return {
            date: x.date,
            city: x.city,
          };
        })
        .sort((a, b) => {
          if (a.date > b.date) return 1;
          if (a.date < b.date) return -1;
          return 0;
        });
    },
    groupName() {
      return this.$store.getters["BookingStore/active"].group_name;
    },
    isCancelled() {
      return this.$store.getters["BookingStore/isCancelled"];
    },
  },
  methods: {
    resetTechnical() {
      this.savedTechnical = {
        header: null,
        transport_notes: {},
        days: JSON.parse(JSON.stringify(this.compiledDays)),
      };
      this.dialog = false;
    },
    setTeachnicalUpdate(v) {
      Vue.set(this.savedTechnical, v.nest ? v.label[v.nest] : v.label, v.value);
    },
    overwriteTechnical(obj) {
      this.savedTechnical = obj;
    },
    printDocument() {
      const title = `${this.groupName}_Technical`;
      const domElement = document.getElementById("printWrapper");
      const styles = TechnicalPrintCss;

      var sourceHTML = domElement.outerHTML;

      PrintService.extractWordDocumentContent(title, sourceHTML, styles, {
        width: 750,
        postOpen: true,
        config: {
          landscape: false,
        },
      });
    },

    updateTechnical() {
      const bookingId = this.$store.getters["BookingStore/booking_id"];

      return this.$store
        .dispatch("BookingTechnicalStore/saveTechnical", {
          booking_id: bookingId,
          blob: this.savedTechnical,
        })
        .then((v) => {
          this.$root.$success("Saved Technical");
        })
        .catch((err) => this.$root.$error(err));
    },

    mergeCompiledAndSaved(saved, compiledDays, invalid) {
      if (!saved) {
        this.savedTechnical.days = compiledDays;
      }
      this.savedTechnical = {
        ..._.omit(saved, ["days"]),
        days: TechnicalService.mergeTechnical(saved.days, compiledDays, invalid),
      };
    },
  },
  mounted() {
    // Get default headers, footers, etc.
    this.$store.dispatch(
      "TextConfigStore/getTextDefaultConfiguration",
      this.$store.getters["AccountStore/environment"]
    );

    // Compile Current
    const suppliers = this.$store.getters["BookingDayStore/suppliers"];
    const days = this.$store.getters["BookingDayStore/days"];
    const flights = this.$store.getters["BookingFlightStore/flattenFlights"];
    const compiled = TechnicalService.compileTechnical(suppliers, days, flights);

    const compiledDays = compiled.itinerary;
    const ignoreSuppliers = compiled.invalid;

    this.compiledDays = JSON.parse(JSON.stringify(compiled.itinerary));

    // Get existing
    const bookingId = this.$store.getters["BookingStore/booking_id"];
    this.$store
      .dispatch("BookingTechnicalStore/getTechnical", bookingId)
      .then((data) => {
        // Merge results
        this.mergeCompiledAndSaved(data, compiledDays, ignoreSuppliers);
      })
      .catch((err) => this.$root.$error(err));

    // Get addresses and phone number contacts
    // Of Restuatnst, excursions, hotel
    const contactNeededQuery = {};
    Object.values(suppliers).forEach((item) => (contactNeededQuery[item.supplier_id] = 1));

    this.$store
      .dispatch("BookingTechnicalStore/getSupplierContactInformation", Object.keys(contactNeededQuery))
      .then((contacts) => (this.contactMap = contacts || {}))
      .catch((err) => this.$root.$error(err));
  },
};
</script>

<style lang="scss">
.technical-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  .pdf-controls {
    position: absolute;
    top: 0;
    width: 100%;
    height: 35px;
    z-index: 100;
  }

  .technical-scroll {
    overflow-y: scroll;
    height: 100%;
  }

  .pdf {
    display: flex;
    flex-direction: column;
    width: 780px;
    background: white;
    margin: 0 auto;
    padding: 60px;

    .underline {
      text-decoration: underline;
    }

    .caps {
      text-transform: uppercase;
    }
  }
}
</style>
