<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)" width="300px" persistent>
    <v-card>
      <v-card-title>
        <div class="subtitle-2">Remove: {{ supplierName }}</div>
      </v-card-title>

      <v-card-text>
        <div><span class="subtitle-2">Products:</span> {{ productNames }}</div>
        <div class="d-flex flex-column">
          <div v-if="deleteFromBooking"><span class="subtitle-2">Days:</span> {{ bookingDayFormat }}</div>
          <g-button label="Current day only" type="warning" class="my-2" @onclick="removeOne" />
          <g-button v-if="deleteFromBooking" label="Entire booking" type="error" class="my-2" @onclick="removeAll" />
        </div>
      </v-card-text>

      <v-card-actions>
        <g-button label="Cancel" icon="clear" @onclick="$emit('input', false)" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import format from "@/services/format";
import _ from "lodash";
export default {
  props: {
    value: Boolean,
    bookingId: String,
    supplierInfo: Object,
  },
  data() {
    return {};
  },
  computed: {
    supplierName() {
      return this.supplierInfo.supplierData && this.supplierInfo.supplierData.name;
    },
    productNames() {
      if (!this.supplierInfo.supplierData) return "";
      const products = this.supplierInfo.supplierData.products;
      return Object.values(products)
        .map((v) => v.name)
        .join(", ");
    },

    bookingDays() {
      if (!this.supplierInfo.supplierData) return [];
      const bookingSupplierId = this.supplierInfo.supplierData.id;
      const typeAs = this.supplierInfo.supplierData.type_as;
      const days = this.$store.getters["BookingDayStore/whatDays"](typeAs, bookingSupplierId);

      return days;
    },

    bookingDayFormat() {
      const typeAs = this.supplierInfo.typeAs;
      const dataString = format.compileDateListReadable(this.bookingDays);
      const count = this.bookingDays.length;
      return typeAs === "HOTEL" ? `(${count} Nights) ${dataString}` : dataString;
    },
    deleteFromBooking() {
      return this.bookingDays.length > 1;
    },
  },
  methods: {
    removeOne() {
      let supplierIdList = [];
      let promiseChain = [];
      let nestedSuppliers = Object.values(this.$store.getters["BookingDayStore/suppliers"]).filter((supplier) => {
        return supplier.parent_bsid === this.supplierInfo.supplierData.id;
      });

      // Main supplier being removed
      promiseChain.push(
        this._popOneBookingSupplier({
          dayIndex: this.supplierInfo.daynum,
          dbkey: this.supplierInfo.dbkey,
          supplierId: this.supplierInfo.supplierData.supplier_id,
          contentIndex: this.supplierInfo.contentIndex,
        })
      );

      // Handle nested case
      nestedSuppliers.forEach((bs) => {
        let dayIndex = this.supplierInfo.daynum;
        if (bs.nest_type === "BREAKFAST") dayIndex += 1;

        // MEALS
        let mealContentIndex = this.$store.getters["BookingDayStore/dayIncludes"](dayIndex, "restaurants", bs.id);
        if (mealContentIndex !== -1) {
          // Has a meal bound to this. Remove that too
          promiseChain.push(
            this._popOneBookingSupplier({
              dayIndex: dayIndex,
              dbkey: "restaurants",
              supplierId: bs.supplier_id,
              contentIndex: mealContentIndex,
            })
          );
          return;
        }

        // ALTERNATE SUPPLIER (Same type only)
        let hotelContentIndex = this.$store.getters["BookingDayStore/dayIncludes"](
          dayIndex,
          this.supplierInfo.dbkey,
          bs.id
        );
        if (hotelContentIndex !== -1) {
          // Remove the alternative hotel
          promiseChain.push(
            this._popOneBookingSupplier({
              dayIndex: dayIndex,
              dbkey: this.supplierInfo.dbkey,
              supplierId: bs.supplier_id,
              contentIndex: hotelContentIndex,
            })
          );
        }
      });

      this._launchPromiseChain(promiseChain)
        .then(() => this._filterAndPurgeBookingSupplierRooms())
        .then(() => {
          this.$roomingPing.$emit("sync");
          this.$root.$loading.end();
        })
        .catch((err) => {
          this.$root.$error(err);
          this.$root.$loading.end();
        });
    },
    _popOneBookingSupplier(info) {
      return this.$store.dispatch("BookingDayStore/removeBookingSupplier", {
        supplier_rooms: this.$store.getters["BookingSupplierRoomStore/supplier_rooms"],
        data: {
          dayIndex: info.dayIndex,
          dbkey: info.dbkey,
          bookingId: this.bookingId,
          supplierId: info.supplierId,
          contentIndex: info.contentIndex,
        },
      });
    },
    removeAll() {
      let promiseChain = [];
      let nestedSuppliers = Object.values(this.$store.getters["BookingDayStore/suppliers"]).filter((supplier) => {
        return supplier.parent_bsid === this.supplierInfo.supplierData.id;
      });

      // Main supplier being removed
      promiseChain.push(
        this._purgeAllBookingSupplier({
          bookingSupplierId: this.supplierInfo.supplierData.id,
          supplierId: this.supplierInfo.supplierData.supplier_id,
          dbkey: this.supplierInfo.dbkey,
        })
      );

      // Handle nested case
      let dbkeyRef = {
        BREAKFAST: "restaurants",
        DINNER: "restaurants",
        ALT_HOTEL: this.supplierInfo.dbkey,
      };
      nestedSuppliers.forEach((bs) => {
        promiseChain.push(
          this._purgeAllBookingSupplier({
            bookingSupplierId: bs.id,
            supplierId: bs.supplier_id,
            dbkey: dbkeyRef[bs.nest_type],
          })
        );
      });

      this._launchPromiseChain(promiseChain)
        .then(() => this._filterAndPurgeBookingSupplierRooms())
        .then(() => {
          this.$roomingPing.$emit("sync");
          this.$root.$loading.end();
        })
        .catch((err) => {
          this.$root.$error(err);
          this.$root.$loading.end();
        });
    },
    _purgeAllBookingSupplier(info) {
      return this.$store.dispatch("BookingDayStore/removeAllOfBookingSupplier", {
        supplier_rooms: this.$store.getters["BookingSupplierRoomStore/supplier_rooms"],
        data: {
          bookingSupplierId: info.bookingSupplierId,
          bookingId: this.bookingId,
          supplierId: info.supplierId,
          dbkey: info.dbkey,
        },
      });
    },

    _launchPromiseChain(promiseChain) {
      this.$emit("input", false);
      this.$root.$loading.open("Removing");
      // This needs to be lineral to avoid conflicting data
      return promiseChain.reduce(function (prev, curr) {
        return prev.then(curr);
      }, Promise.resolve());
    },

    _filterAndPurgeBookingSupplierRooms() {
      const typeAs = this.supplierInfo.typeAs;
      if (!["TRANSPORT", "TOUR_GUIDE"].includes(typeAs)) return Promise.resolve();

      const updatedSupplierRoomList = this.$store.getters["BookingDayStore/days"].reduce((arr, item) => {
        return _.uniq([...arr, ...item.supplier_rooms]);
      }, []);
      const supplierRoomIdList = this.$store.getters["BookingSupplierRoomStore/supplier_rooms"].map((v) => v.id);

      // Remove any supplier rooms no longer relevant to a day
      return Promise.all(
        _.difference(supplierRoomIdList, updatedSupplierRoomList).map((sroomid) => {
          return this.$store.dispatch("BookingSupplierRoomStore/removeSupplierRoom", sroomid);
        })
      );
    },
  },
};
</script>
