<template>
  <v-col>
    <v-row justify="space-between">
      <ClientHistorySearch v-model="emailFilter" @search="searchEmails" />
    </v-row>

    <v-divider class="my-5" />

    <div v-if="view === 'SHOW'">
      <v-expansion-panels popout focusable hover flat>
        <v-expansion-panel v-for="h in historyContent" :key="h.id">
          <v-expansion-panel-header>
            <HistoryCardHeader
              :type="h.type"
              :date="h.date"
              :content="h"
              :contactMap="contactMapping"
              :accountMap="accountEmailMapping"
            />
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <HistoryCard :type="h.type" :content="h" :contactMap="contactMapping" :accountMap="accountEmailMapping" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <d-no-display v-else-if="view === 'NOVAL'" label="(No history to show)" />
    <v-progress-linear v-else-if="view === 'LOADING'" color="blue" indeterminate rounded></v-progress-linear>
  </v-col>
</template>

<script>
import { ClientHistorySearch } from "@/modules/clients.history";
import format from "@/services/format";
import { EmailService } from "@/modules/email";
import { HistoryCard, HistoryCardHeader, SupplierHistoryService } from "@/modules/suppliers.history";
export default {
  components: {
    ClientHistorySearch,
    HistoryCard,
    HistoryCardHeader,
  },
  data() {
    return {
      historyList: [],
      accountEmailMapping: {},

      filter: {},
      emailFilter: {
        user: "",
        contact: "",
        dates: {
          // THREE DAYS AGO
          from: format.convertDate(new Date(Date.now() - 3 * 24 * 60 * 60 * 1000)),
          to: format.convertDate(new Date()),
          range: true,
        },
      },
    };
  },
  computed: {
    clientId() {
      return this.$store.getters["ClientStore/client_id"];
    },
    contactMapping() {
      const contacts = this.$store.getters["ClientStore/all_contacts"];
      let obj = {};
      contacts.forEach((item) => {
        if (item.email) obj[item.email] = item.name;
      });
      return obj;
    },

    // EMAIL LIST
    gotData() {
      // Hide data if querying new supplier
      // Otherwise show cache until new history is found
      const storedSupplierId = this.$store.getters["ClientStore/savedClientId"];
      if (!storedSupplierId) return false;
      if (storedSupplierId !== this.supplierId) return false;
      return true;
    },
    emailList() {
      if (!this.gotData) return [];
      return this.$store.getters["ClientStore/emailList"];
    },

    view() {
      if (!this.gotData) return "LOADING";
      if (!this.historyContent.length) return "NOVAL";
      return "SHOW";
    },
    historyContent() {
      // EMAIL
      return this.emailList;
    },
  },
  methods: {
    replyMail(content) {
      this.$store.dispatch("openEmailReplyTab", {
        clientId: this.clientId,
        isclient: true,
        originalContent: content,
      });
    },

    // Search Blobs
    searchEmails(filter) {
      // Get emails
      this.emailFilter = filter;

      this.loadingEmail = true;
      this.$store
        .dispatch("ClientHistoryStore/getClientEmailHistory", {
          clientId: this.supplierId,
          query: SupplierHistoryService.createEmailQuery(
            this.emailFilter,
            this.$store.getters["ClientStore/all_contacts"],
            this.$store.getters["ClientHistoryStore/account_ref"]
          ),
        })
        .then((v) => {
          this.loadingEmail = false;
        })
        .catch((err) => {
          this.$root.$error(err);
        });
    },
  },
  mounted() {
    this.searchEmails(this.emailFilter);

    // Get email reference
    this.$store
      .dispatch("EmailStore/getAccountEmails")
      .then((results) => {
        // Add accounts to history store so search can reference
        this.$store.dispatch("ClientHistoryStore/storeAccounts", results);
      })
      .catch((err) => this.$root.$error(err));
  },
};
</script>
