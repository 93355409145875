<template>
  <div>
    <div>
      <!-- <currency-input :value="total" :options="source.money" class="currency-input" /> -->
      <span class="currency-input">{{ getCurrency }}{{ total }}</span>
      <span style="color: red" class="font-weight-bold" v-if="conversionPostfix"> ({{ conversionPostfix }}) </span>
      <span v-if="type">/ {{ type }}</span>
    </div>
  </div>
</template>

<script>
import ProductService from "../service";
import moneyService from "@/services/money";
export default {
  props: {
    env: String,
    rate: Object,
    tax: [Number, Object, String],
    taxInclusive: [Number, Boolean],
    occupancy: [Number, Object],
    resortFee: [Number, Object],
    extraList: [Array], // => Objects
  },
  computed: {
    // Conversion stuff
    source() {
      return moneyService.getCurrencySource(this.workingEnv);
    },
    supplierEnv() {
      return this.$store.getters["SupplierStore/env"];
    },
    workingEnv() {
      return this.$store.getters["AccountStore/environment"];
    },
    rateInfo() {
      return this.rate;
    },
    total() {
      const total = ProductService.calcTotal({
        rateInfo: this.rateInfo,
        tax: this.tax,
        taxInclusive: this.taxInclusive,
        occupancy: this.occupancy,
        resort_fee: this.resortFee,
        extraList: this.extraList,
      });
      return Number(total).toFixed(2);
    },
    formatedTotal() {
      if (this.rateInfo && this.rateInfo.value === 0) return "$ --";
      if (!this.total) return "n/a";
      return this.total;
    },
    type() {
      if (!(this.rateInfo && this.total)) return null;

      let type = ProductService.getRateType(this.rateInfo.type);
      return type;
    },
    conversionPostfix() {
      if (this.workingEnv != this.supplierEnv) {
        return moneyService.getCurrencySource(this.supplierEnv).currency;
      }
      return null;
    },
    getCurrency() {
      return this.workingEnv == "CDA" ? "CAD" : "$";
    },
  },
  methods: {},
};
</script>

<style lang="scss">
.currency-input {
  text-align: right;
  max-width: 70px;
  pointer-events: none;
}
</style>
