<template>
  <v-toolbar id="supheader" dense flat>
    <v-toolbar-title>
      <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
    </v-toolbar-title>

    <div class="flex-grow-1"></div>

    <template v-slot:extension>
      <SupplierTabs />
    </template>
  </v-toolbar>
</template>

<script>
import SupplierTabs from "./_SupplierTabs";

export default {
  components: {
    SupplierTabs,
  },
  props: {
    supplierId: String,
    supplierType: String,
    name: String,
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: "Database",
          to: { name: "database" },
        },
        {
          text: `Suppliers (${this.supplierType})`,
          to: { name: "database_view", params: { view: "suppliers", sub: this.supplierType || "ALL" } },
        },
        {
          text: this.name,
        },
      ];
    },
  },
};
</script>
