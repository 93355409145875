<template>
  <div class="d-flex align-start caption justify-space-between" style="width: 100%">
    <div class="d-flex align-start px-2">
      <div>
        <div v-for="days in hotelDays" :key="days.date">
          <v-icon small>{{ days.icon }}</v-icon>
          <span class="ml-2">{{ days.date }}</span>
        </div>
      </div>
    </div>
    <RoomNumberHover
      style="min-width: 90px"
      :confirmed="true"
      :dateList="dateList"
      :roomBreakdownData="roomBreakdownData"
      :supplierCustomRoom="supplierCustomRoom"
      :bsmeta="bsmeta"
      offsync
    />
  </div>
</template>

<script>
// import RoomNumberHover from '@/modules/bookings.rooms/components/roomNumberHover/RoomNumberHover'
import { RoomNumberHover } from "@/modules/bookings.rooms";
import format from "@/services/format";
export default {
  props: {
    bsid: Array,
    bookingSuppliers: Object,
    bookingDays: Array,
    roomBreakdownData: Object,

    statusDisplay: Array,
  },
  components: {
    RoomNumberHover,
  },
  computed: {
    relevantBookingSupplier() {
      return Object.keys(this.bookingSuppliers).reduce((obj, key) => {
        if (this.bsid.includes(key) && ["HOTEL", "RESTAURANT"].includes(this.bookingSuppliers[key].type_as)) {
          obj[key] = this.bookingSuppliers[key];
        }
        return obj;
      }, {});
    },
    status() {
      // Right now, assuming only 1 booking supplier
      if (!Object.values(this.relevantBookingSupplier)[0]) return 0;
      return Object.values(this.relevantBookingSupplier)[0].status;
    },
    bsmeta() {
      // Right now, assuming only 1 booking supplier
      if (!Object.values(this.relevantBookingSupplier)[0]) return 0;
      return Object.values(this.relevantBookingSupplier)[0].meta;
    },

    dateExtraction() {
      // Extract Dates
      return this.bookingDays.reduce((obj, day) => {
        this.bsid.forEach((bsid) => {
          if (day.hotels.includes(bsid)) {
            if (!obj[bsid]) obj[bsid] = [];
            obj[bsid].push(day.date);
          }
        });
        return obj;
      }, {});
    },
    dateList() {
      const d = Object.values(this.dateExtraction).reduce((dump, days) => {
        return [...dump, ...days];
      }, []);
      return d.filter((c, index) => d.indexOf(c) === index);
    },
    hotelDays() {
      // Extract (HOTEL) Dates
      let bsidDateMapping = { ...this.dateExtraction };

      // Format dates
      Object.keys(bsidDateMapping).forEach((key) => {
        bsidDateMapping[key] = format.compileDateListReadable(bsidDateMapping[key], true);
      });

      // Stack based on blip order
      return this.statusDisplay.map((item) => {
        return {
          date: bsidDateMapping[item.bsid] || `${format.formatDate(item.firstDay)} - ${item.product_names}`,
          icon: bsidDateMapping[item.bsid] ? "event" : "restaurant_menu",
        };
      });
    },
    supplierCustomRoom() {
      // Right now, assuming only 1 booking supplier
      if (!Object.values(this.relevantBookingSupplier).length) return null;
      const first = Object.values(this.relevantBookingSupplier)[0];
      return first.meta && first.meta.custom_room_num;
    },
  },
};
</script>
