<template>
  <div class="d-flex align-start justify-space-between caption" style="width: 100%">
    <div class="d-flex align-start px-2">
      <div class="ml-2">
        <div v-for="product in displayProductNames" :key="product.bsid">{{ product.names }}</div>
      </div>
    </div>

    <div class="d-flex align-center caption">
      <div class="px-2" style="min-width: 80px; max-width: 80px">
        <v-icon small left>person</v-icon>
        <span>{{ pax }}</span>
      </div>
      <div style="display: flex; min-width: 90px; max-width: 90px">
        <v-icon v-if="displayProductNames.length" small left>timer</v-icon>
        <div>
          <div v-for="(time, index) in displayProductNames" :key="'excur' + index">{{ time.time }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import format from "@/services/format";
export default {
  props: {
    bsid: Array,
    bookingSuppliers: Object,
    statusDisplay: Array,
    pax: [String, Number],
  },
  computed: {
    relevantBookingSupplier() {
      return Object.keys(this.bookingSuppliers).reduce((obj, key) => {
        if (this.bsid.includes(key) && this.bookingSuppliers[key].type_as === "EXCURSION") {
          obj[key] = this.bookingSuppliers[key];
        }
        return obj;
      }, {});
    },
    displayProductNames() {
      return this.statusDisplay.map((item) => {
        return {
          bsid: item.bsid,
          names: item.product_names,
          time: item.time ? format.formatTime(item.time) : "",
        };
      });
    },
  },
};
</script>
