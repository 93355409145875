<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)" width="400px" persistent>
    <v-card>
      <v-toolbar depressed flat>
        <span class="title"># of Rooms</span>
        <div class="flex-grow-1"></div>
      </v-toolbar>

      <v-card-text class="black--text">
        <v-row v-for="room in BOOKING_ROOMS" :key="room.value" align="center" class="mx-0">
          <f-number v-model="roomBreakdown[room.value]" />
          <span class="px-3">{{ room.label }}</span>
        </v-row>

        <v-divider class="my-2" />

        <div class="d-flex">
          <div style="width: 80px" class="px-2">{{ total }}</div>
          <div>TOTAL</div>
        </div>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <g-button label="Cancel" @onclick="$emit('input', false)" />
        <g-button type="primary" label="Update" @onclick="eject" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BOOKING_ROOMS from "@/config/BOOKING_ROOMS";
import BookingRoomService from "../../service";
export default {
  props: {
    value: Boolean,
    current: Object,
    extraTotal: Number,
  },
  data() {
    return {
      BOOKING_ROOMS,
      roomBreakdown: {},
    };
  },
  watch: {
    current(v) {
      this.roomBreakdown = { ...v };
    },
  },
  computed: {
    total() {
      var total = 0;
      let extra = 0;
      Object.values(this.roomBreakdown).forEach((value) => {
        if (value) total += Number(value);
      });

      if (this.roomBreakdown["SUPPLIER.TOUR_GUIDE"]) {
        total -= Number(this.roomBreakdown["SUPPLIER.TOUR_GUIDE"]);
        extra += Number(this.roomBreakdown["SUPPLIER.TOUR_GUIDE"]);
      }

      if (this.roomBreakdown["SUPPLIER.TRANSPORT"]) {
        total -= Number(this.roomBreakdown["SUPPLIER.TRANSPORT"]);
        extra += Number(this.roomBreakdown["SUPPLIER.TRANSPORT"]);
      }

      return extra ? `${total}+${extra}` : total;
    },
  },
  methods: {
    eject() {
      this.$emit("update", this.roomBreakdown);
      this.$emit("input", false);
    },
  },
  mounted() {
    this.roomBreakdown = { ...this.current } || {};
  },
};
</script>
