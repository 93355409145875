import { ProductTypes } from "@/config/fields/products";
import MONTHS from "@/config/MONTHS";
import moneyService from "@/services/money";
import format from "@/services/format";
import CURRENCY_SYMBOLS from "@/config/CURRENCY_SYMBOLS";
import EXTRA_OPTIONS from "@/config/products/EXTRA_OPTIONS";

export default {
  // GET
  getDate,
  getRateType,
  getExtraList,
  getSeasonalRange,
  // FORMAT
  formatPrice,
  formatConvert,
  // CALCULATION
  calcTax,
  calcExtra,
  calcTotal,
  // Package Calculations
  fullCalc,
  packagePrice,
  // Price extraction
  getPrice,
  getPriceFromBookingSupplier,
  lockPrices,
};

// **********
// * GET
// **********
function getDate(m, d) {
  if (!m) return "";
  return `${MONTHS[m - 1].short}${d ? "/" + d : ""}`;
}

function getRateType(type) {
  const source = ProductTypes[type];
  return source ? source.label : "";
}

function getExtraList(product) {
  const meta = product.meta;
  if (!meta) return [];

  return Object.keys(meta)
    .filter((v) => v.includes("extra_"))
    .map((key) => {
      return {
        key,
        ...meta[key],
      };
    });
}

function getSeasonalRange(seasonalList, customDate) {
  // customDate => month, day
  // use custom date, or default to current date
  const baseDate = new Date();

  // START INDEX
  const start_month = customDate ? customDate.from_month : baseDate.getMonth() + 1;
  const start_day = customDate ? customDate.from_day || 0 : baseDate.getDate();

  // END INDEX
  let end_month = start_month;
  let end_day = start_day || 32; // End of month if undefined
  if (customDate && customDate.to_month) {
    end_month = customDate.to_month;
    end_day = customDate.to_day || end_day;
  }

  return (seasonalList || []).filter((item) => {
    return _inDateRange(item, start_month, start_day, end_month, end_day);
  });
}

// **********
// * FORMAT
// **********
function formatPrice(rate, seasonalInfo, skipDates) {
  const type = getRateType(rate.type);
  const value = rate.value ? moneyService.stripFormatting(rate.value) : null;
  let base = `${value ? parseFloat(value).toFixed(2) : "--"} ${type ? "/ " + type : ""}`;

  // Currency adjustment (if not in value)
  if (base.indexOf(CURRENCY_SYMBOLS[rate.cur] || rate.cur || "$") === -1) {
    base = `${CURRENCY_SYMBOLS[rate.cur] || rate.cur || "$"} ${base}`;
  }

  // Free => $ --, otherwise display value
  return (
    (Number(value) === 0 ? "$ --" : base) +
    (seasonalInfo && !skipDates ? ` (${seasonalInfo.dateStart} to ${seasonalInfo.dateEnd})` : "")
  );
}

function formatConvert(value) {
  return `($${value} USD)`;
}

// **********
// * CALCULATION BASIC
// **********
function calcTax(rateInfo, taxInclusive, tax) {
  if (taxInclusive) return 0;

  const cost = moneyService.stripFormatting(`${rateInfo.value}`);
  const calc = moneyService._num((cost * +tax) / 100);

  return cost && tax ? calc : 0;
}

function calcExtra(extraList, rateInfo, taxInclusive, tax) {
  if (!extraList) return { total: 0, totalTax: 0 };

  let total = 0;
  let totalTax = 0;
  let itemValue, cost, includeTax;
  extraList.forEach((item) => {
    if (!item.value) return;

    includeTax = "beforeTax" in item ? item.beforeTax : EXTRA_OPTIONS[item.key].beforeTax;
    itemValue = typeof item.value === "object" ? item.value.value : item.value;

    if (item.astype === "PERCENT") {
      // Percentage of cost, so need to get that here
      cost = (moneyService.stripFormatting(rateInfo.value) * moneyService.stripFormatting(itemValue)) / 100;
    } else {
      // Regular price not relative to input cost
      cost = moneyService.stripFormatting(itemValue);
    }

    if (includeTax) {
      // Add in tax
      totalTax += calcTax({ value: cost }, taxInclusive, tax);
    }

    total += cost;
  });

  return {
    total,
    totalTax,
  };
}

function calcTotal({
  rateInfo,
  tax,
  taxInclusive,

  extraList, // @{Array}
  occupancy, // @{Object}
  resort_fee, // @{Object}
}) {
  let cost = moneyService.stripFormatting(rateInfo.value);
  let total = cost;

  if (total === 0) return 0;
  if (!total) return null;

  const totalTax = calcTax(rateInfo, taxInclusive, tax);
  const resortFee = resort_fee ? moneyService.stripFormatting(resort_fee.value) : 0;
  const resortFeeTax = resort_fee ? moneyService._num(calcTax(resort_fee, taxInclusive, tax)) : 0;
  const totalExtra = calcExtra(extraList, rateInfo, taxInclusive, tax);

  // ADD TAX
  total += totalTax;

  // EXTRA STUFF
  total += resortFee + resortFeeTax + totalExtra.total + totalExtra.totalTax;

  if (!taxInclusive) {
    // NOT tax inclusive
    if (occupancy) total += moneyService.stripFormatting(occupancy.value);
  }

  return total;
}

// **********
// * PACKAGE CALCULATIONS
// **********
function fullCalc(product, meta, country, bookingSupplierMeta) {
  // CHECK
  const countryConfig = moneyService.getCurrencySource(country);

  const supplierMeta = meta || {};
  const isSeasonal = product.seasonal;

  const tax = supplierMeta.tax;
  const taxInclusive = product.tax_inclusive || product.taxInclusive;
  const customTax = product.meta ? product.meta.custom_tax : 0;

  const occupancy =
    product.product_type === "ROOM"
      ? (product.meta && product.meta.custom_occupancy) || supplierMeta.occupancy_rate
      : 0;
  const cusresort =
    bookingSupplierMeta && bookingSupplierMeta.has_custom_resortfee && bookingSupplierMeta.custom_resortfee_value.value
      ? bookingSupplierMeta.custom_resortfee_value
      : null;
  const resort_fee = product.product_type === "ROOM" ? cusresort || supplierMeta.resort_fee : 0;
  const extraList = getExtraList(product);

  // Regular rate
  const rate = product.rate ? (typeof product.rate === "string" ? JSON.parse(product.rate) : product.rate) : {};

  const total = calcTotal({
    rateInfo: rate,
    tax: customTax || tax || 0,
    taxInclusive,

    extraList,
    occupancy,
    resort_fee,

    calcOnly: true,
  });

  return {
    tax: customTax || tax || 0,
    taxInclusive,

    cur: rate.cur,
    value: moneyService.format(`${total}`, countryConfig.money),
    raw_value: moneyService._num(total),
    type: rate.type,
  };
}

function packagePrice(
  product,
  rateMerge,
  supplier_meta,
  country,
  seasonal,
  storeGetter,
  skipDates,
  bookingSupplierMeta
) {
  // Gets print of product rate
  // Total, conversion
  const item = seasonal
    ? {
        product_type: product.product_type,
        seasonal: product.seasonal,
        taxInclusive: product.tax_inclusive || product.taxInclusive,
        meta: product.meta,
        ...rateMerge,
      }
    : {
        ...product,
        ...(rateMerge && { rate: rateMerge }),
      };

  const rateInfo = item.rate || {};

  const priceConvert = moneyService.getConvertedValue(rateInfo.value || "", country, storeGetter);
  const totalPrice = fullCalc(item, supplier_meta, country, bookingSupplierMeta);
  const totalConvert = moneyService.getConvertedValue(totalPrice.raw_value, country, storeGetter);

  const dateStart = getDate(item.mstart, item.dstart);
  const dateEnd = getDate(item.mend, item.dend);

  const seasonalInfo = seasonal
    ? {
        dateStart: getDate(item.mstart, item.dstart),
        dateEnd: getDate(item.mend, item.dend),
      }
    : null;

  return {
    defaultType: rateInfo.type,

    price: rateInfo,
    price_print: formatPrice(rateInfo, seasonalInfo, skipDates),
    priceConvert,
    priceConvert_print: formatConvert(priceConvert),

    totalPrice,
    totalPrice_print: formatPrice(totalPrice, seasonalInfo, skipDates),
    totalConvert,
    totalConvert_print: formatConvert(priceConvert),

    // Seasonal specific
    ...seasonalInfo,
  };
}

// *************
// * PRICE EXTRACTION
// *************
function getPrice(
  p,
  supplierMeta,
  country,
  altPrices,
  storeGetter,
  skipDates,
  customSeasonalDate,
  bookingSupplierMeta
) {
  //	customSeasonalDate	@{Object}
  // 		=> from_month
  // 		=> from_day
  const custom = altPrices ? altPrices[p.id] : null;
  // if (!p.rate && custom) return '';

  if (!p.seasonal) {
    let item = custom ? { ...p } : p;
    if (custom) item.rate = custom;
    return packagePrice(item, custom, supplierMeta, country, false, storeGetter, skipDates, bookingSupplierMeta);
  }

  // Seasonal pricing mess
  let parse = p.rate || [];
  let pricesInRange = getSeasonalRange(parse, customSeasonalDate);

  return pricesInRange.map((item, index) => {
    let temp = custom && custom[index] ? { ...item } : item;
    if (custom && custom[index]) {
      if (custom[index].rate) temp.rate = custom[index].rate;
      else temp.rate = custom[index];
    }
    return packagePrice(p, temp, supplierMeta, country, true, storeGetter, skipDates, bookingSupplierMeta);
  });
}

function getPriceFromBookingSupplier(bookingSupplier, product, altPrices, storeGetter, seasonalBreak, customDate) {
  // Accounts for locked pricing
  const data = bookingSupplier;

  let locked = data.locked_prices && data.locked_prices[product.id];
  const useDates = (data.meta && data.meta.use_dates) || {
    from_month: format.dateToObj(customDate).month,
    from_day: format.dateToObj(customDate).day,
  };
  const price = getPrice(product, data.supplier_meta, data.country, altPrices, storeGetter, true, useDates, data.meta);

  if (!product.seasonal) {
    return {
      rate: locked ? formatPrice(locked.totalPrice) : price.totalPrice_print,
      convert: locked && locked.totalConvert ? formatConvert(locked.totalConvert) : price.totalConvert_print,
      original: locked && locked.originalPrice ? formatPrice(locked.originalPrice) : price.price_print,
    };
  }

  let result = "",
    convert = "",
    original = "",
    temp;
  price.forEach((p, index) => {
    locked = data.locked_prices && data.locked_prices[p.id] && data.locked_prices[p.id][index];
    temp = {
      rate: locked ? formatPrice(locked.totalPrice) : p.totalPrice_print,
      convert: locked && locked.totalConvert ? formatConvert(locked.totalConvert) : p.totalConvert_print,
      original: locked && locked.originalPrice ? formatPrice(locked.originalPrice) : p.price_print,
    };

    convert += `${index >= 1 ? seasonalBreak : ""}${temp.convert}`;
    result += `${index >= 1 ? seasonalBreak : ""}${temp.rate}`;
    original += `${index >= 1 ? seasonalBreak : ""}${temp.original}`;
  });
  return { rate: result, convert, original };
}

function lockPrices(products, supplier, storeGetter, useDate, errorPopup) {
  // 	useDate 	@{String}
  let lockedList = {},
    pricePackage;
  const customPrices = supplier.meta ? { ...supplier.meta.custom_prices } || {} : {};
  const commission = supplier.supplier_meta ? supplier.supplier_meta.commission : null;
  const workingEnv = storeGetter["AccountStore/environment"];
  const convert = (price) => {
    if (supplier.supplier_env !== workingEnv) {
      if (workingEnv === "USA") {
        price = moneyService.getConvertedValue(price, supplier.supplier_env, storeGetter);
      } else {
        if (supplier.supplier_env === "USA") {
          price = moneyService.getConvertToForeign(price, workingEnv, storeGetter);
        } else {
          console.log(`Behavior not handled for ${supplier.supplier_env}`);
        }
      }
    }

    return price;
  };

  Object.values(products).forEach((product) => {
    pricePackage = getPrice(
      product,
      supplier.supplier_meta,
      supplier.country,
      customPrices,
      storeGetter,
      false,
      {
        from_month: format.dateToObj(useDate).month,
        from_day: format.dateToObj(useDate).day,
      },
      supplier.meta
    );

    if (!product.seasonal) {
      // ERROR Catcher
      // If this fails, the whole reporting crashes
      if (!pricePackage.totalPrice) {
        if (errorPopup) errorPopup("No Total Price", product, pricePackage);
        return;
      }

      pricePackage.totalPrice.raw_value = convert(pricePackage.totalPrice.value);
      pricePackage.totalPrice.value = pricePackage.totalPrice.raw_value.toString();
      lockedList[product.id] = {
        totalPrice: pricePackage.totalPrice,
        originalPrice: pricePackage.price,
        commission,
        ...(pricePackage.totalConvert && { totalConvert: pricePackage.totalConvert }),
      };
      return;
    }

    // Seasonal
    lockedList[product.id] = {};
    pricePackage.forEach((seasonalRate, index) => {
      // ERROR Catcher
      // If this fails, the whole reporting crashes
      if (!seasonalRate.totalPrice) {
        if (errorPopup) errorPopup("No Total Price", product, pricePackage);
        return;
      }
      seasonalRate.totalPrice.raw_value = convert(seasonalRate.totalPrice.value);
      seasonalRate.totalPrice.value = seasonalRate.totalPrice.raw_value.toString();
      lockedList[product.id][index] = {
        totalPrice: seasonalRate.totalPrice,
        originalPrice: seasonalRate.price,
        commission,
        ...(seasonalRate.totalConvert && { totalConvert: seasonalRate.totalConvert }),
      };
    });
  });

  return lockedList;
}

// *************
// * HELEPER
// *************
function _inDateRange(item, start_month, start_day, end_month, end_day) {
  if (!(item.mstart && item.mend)) return true;
  // User entered date
  let start_user = parseInt(`${start_month}${_getDayString(start_day, true, start_month)}`);
  let end_user = parseInt(`${end_month}${_getDayString(end_day, false, end_month)}`);

  // Product seasonal rate
  let item_start = parseInt(`${item.mstart}${_getDayString(item.dstart, true, item.mstart)}`);
  let item_end = parseInt(`${item.mend}${_getDayString(item.dend, false, item.mend)}`);

  if (item_start > item_end) {
    // Special case, end of year overlaps into next (example October to April)
    if (item_start <= start_user || item_start <= end_user) return true;
    if (item_end >= start_user || item_end >= end_user) return true;
    return false;
  }

  if (start_user <= item_start && item_start <= end_user) return true; // b starts in a
  if (start_user <= item_end && item_end <= end_user) return true; // b ends in a
  if (item_start <= start_user && end_user <= item_end) return true; // a in b
  if (item_start > item_end && start_user <= item_start && start_user <= item_end) return true;
  return false;
}

function _getDayString(day, start, month) {
  // Day not defined, get first or last day
  if (!day) return start ? "01" : MONTHS[month - 1].days;
  if (day < 10) return "0" + day;
  return day;
}

// function matchDateToSeasonIndex(seasonalList, month, day) {
//     return seasonalList.findIndex(item => {
//         let pass = true;
//         if (item.mstart <= item.mend) {
//             // start of year to mid
//             if (month < item.mstart) pass = false;	// not before start
//             if (month > item.mend) pass = false;	// not after end
//         } else if (item.mstart > item.mend) {
//             // End of year into next
//             // months between two months invalid
//             if (month < item.mstart && month > item.mend) pass = false;
//         }
//         if (item.dstart && day < item.dstart) pass = false;	// not before day (if specified)
//         if (item.dend && day > item.dend) pass = false;	// not after
//         return pass;
//     })
// }
