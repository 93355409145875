<template>
  <div class="caption">
    <ol>
      <li>Go back to <a href="https://service.supernature.travel/cpanel">cpanel</a></li>
      <li>Go to (or search): Forwarders</li>
      <li>Select "Add Forwarder"</li>
      <li>Add "Address to Forward." This is the email created in Step #1.</li>
      <li>Ensure "Forward to Email Address" is selected. (Default)</li>
      <li>Forward to: <span class="font-italic font-weight-bold">archives-system@service.supernature.travel</span></li>
    </ol>
    <v-row justify="end" class="mx-0">
      <g-button type="primary" label="Done" @onclick="$emit('next')" />
    </v-row>
  </div>
</template>

<script>
export default {
  methods: {},
};
</script>
