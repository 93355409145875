<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)" persistent>
    <v-card>
      <v-toolbar depressed flat>
        <span class="headline">Seasonal Pricing</span>
        <div class="flex-grow-1"></div>
      </v-toolbar>

      <v-card-text>
        <div v-for="(p, index) in priceList" :key="index" class="d-flex justify-center align-start">
          <v-icon small class="mr-3" style="min-height: 34px">event</v-icon>
          <month-picker v-model="p.mstart" />
          <day-picker :month="p.mstart" v-model="p.dstart" />

          <div class="subtitle-2 px-3 d-flex align-center" style="min-height: 34px">to</div>

          <v-icon small class="mr-3" style="min-height: 34px">event</v-icon>
          <month-picker v-model="p.mend" />
          <day-picker :month="p.mend" v-model="p.dend" />

          <div class="subtitle-2 px-3 d-flex align-center" style="min-height: 34px">Rate</div>
          <cur-per-val :env="env" :default="defaultPer" :dropdown="source" v-model="p.rate" />

          <div class="subtitle-2 px-3 d-flex align-center" style="min-height: 34px"></div>

          <v-autocomplete
            :disabled="p.rate.type != 'PROOM'"
            dense
            outlined
            hide-details
            item-text="label"
            item-value="value"
            :items="seasonPerRoomTypeSource"
            v-model="p.roomType"
          ></v-autocomplete>

          <v-btn small icon @click="deleteRow(index)">
            <v-icon small>clear</v-icon>
          </v-btn>
        </div>
        <add-button @click="addRow" />
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <g-button @onclick="$emit('input', false)" label="Cancel" />
        <g-button type="primary" @onclick="eject" label="Update" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ProductFields, SeasonProductPerRoomTypes } from "@/config/fields/products";
export default {
  props: {
    value: Boolean,
    priceList: Array,
    env: String,
  },
  computed: {
    source() {
      var productType = this.get("type");
      var supplierType = this.get("supplier_type");
      return ProductFields.rate_type.getSource(productType, supplierType);
    },
    defaultPer() {
      var productType = this.get("type");
      var supplierType = this.get("supplier_type");

      if (productType === "ROOM") return "PROOM";
      if (["RESTAURANT", "EXCURSION"].includes(supplierType)) return "PP";
      if (supplierType === "TRANSPORT") return "PG";
      return "";
    },
    seasonPerRoomTypeSource() {
      return SeasonProductPerRoomTypes;
    },
  },
  methods: {
    get(val) {
      return this.$store.getters["ProductStore/" + val];
    },
    addRow() {
      // Add empty row; rate is minimum requirement
      let temp = [...this.priceList];
      temp.push({
        rate: {
          ...(this.defaultPer && { type: this.defaultPer }),
        },
        roomType: "DEFAULT",
      });
      this.$emit("update", temp);
    },
    deleteRow(index) {
      let temp = [...this.priceList];
      temp.splice(index, 1);
      this.$emit("update", temp);
    },
    eject() {
      this.$emit("update", this.priceList);
      this.$emit("input", false);
    },
  },
};
</script>
