export default `
body {
	font-family:"Arial",sans-serif;
	padding: 10px
}

.text-center {
	text-align:center;
}

.title {
    font-size: 1.25rem!important;
    letter-spacing: .009375rem!important;
}

.subtitle-1 {
	line-height:115%
}

.font-weight-bold {
    font-weight: 700!important;
}

.subtitle-2, .title {
    font-weight: 500;
    line-height: normal;
}

.title {
    font-weight: 500;
    line-height: normal;
    font-family: Roboto,sans-serif!important;
}

.red--text {
    color:red;
}
.blue--text {
    color: blue
}

.attention {
	line-height: 1.5;
	background: lightgrey;
	border: 3px solid red;
	margin: 10px auto;
    -webkit-print-color-adjust: exact; 
}

.underline {
    text-decoration: underline;
}

.caption {
    font-size: .75rem!important;
    letter-spacing: .025rem!important;
    font-weight: 400;
    line-height: normal;
    font-family: Roboto,sans-serif!important;
}

.caps {
	text-transform: uppercase;
}

.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    margin: 0px;
}

.col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    padding: 5px;
}

.col-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}

.px-5 {
    padding-right: 20px!important;
    padding-left: 20px!important;
}

.py-2 {
    padding-top: 8px!important;
    padding-bottom: 8px!important;
}

.pa-0 {
    padding: 0!important;
}

.my-5 {
    margin-top: 20px!important;
    margin-bottom: 20px!important;
}

.d-flex {
    display: -webkit-box!important;
    display: -ms-flexbox!important;
    display: flex!important;
}

.flex-column {
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: normal!important;
    -ms-flex-direction: column!important;
    flex-direction: column!important;
}

.day {
	margin: 10px auto;
	width: 100%;
}

.day table {
	width: 100%;
}

.day table .t-ctrls {
	opacity: 0;
}

.day table tr {
	margin-top: 10px;
}

.day table td.time {
	width: 120px;
}

.day table td {
	vertical-align: top;
	padding-top: 10px;
}

.t-footer {
	padding: 10px 0;
	margin: 10px 0;
	border-top: 3px solid black;
	border-bottom: 3px solid black;
}

`;
