import Vue from "vue";
import api from "./api";
import moment from "moment";

const SET_ACTIVE_BOOKING = "SET_ACTIVE_BOOKING";
const TRANSFER_BOOKING = "TRANSFER_BOOKING";
const UPDATE_BOOKING = "UPDATE_BOOKING";
const UPDATE_CLIENT = "UPDATE_CLIENT";

const SET_CONTRACT = "SET_CONTRACT";
const SET_INVOICE = "SET_INVOICE";

const namespaced = true;

const state = {
  active: _getBooking(),
  cache: _getBooking(),

  contracts: [],
  invoices: [],
};

function _getBooking() {
  return JSON.parse(localStorage.getItem("booking") || "{}");
}

const mutations = {
  SET_ACTIVE_BOOKING(state, booking) {
    state.active = booking.booking || {};
    state.cache = { ...state.active };
    localStorage.setItem("booking", JSON.stringify(state.active));
  },
  TRANSFER_BOOKING(state) {
    // Transfer active booking => cache
    // Save update local storage
    localStorage.setItem("booking", JSON.stringify(state.active));
  },
  UPDATE_BOOKING(state, { deep, key, val }) {
    // Default
    if (!deep) return Vue.set(state.active, key, val);

    if (!state.active.data) Vue.set(state.active, "data", {});
    Vue.set(state.active.data, key, val);
  },
  UPDATE_CLIENT(state, data) {
    Vue.set(state.active, "client_name", data.name);
    Vue.set(state.active, "client_id", data.id);
  },

  // Booking Contracts
  SET_CONTRACT(state, contracts) {
    state.contracts = contracts;
  },
  SET_INVOICE(state, invoices) {
    state.invoices = invoices;
  },
};

const actions = {
  setBooking({ commit }, data) {
    commit(SET_ACTIVE_BOOKING, { booking: data });
  },
  update({ commit }, { deep, key, val }) {
    commit(UPDATE_BOOKING, { deep, key, val });
  },
  getBookings({ commit }, { page, query, extra }) {
    return api
      .getBookings(page, query, extra)
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  getBookingGroupList() {
    return api
      .getBookingGroupList()
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  getSupplierListForBookings({ commit }, options) {
    return api
      .getSupplierListForBookings(options)
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  getOneBooking({ commit }, booking_id) {
    if (!booking_id) return Promise.resolve({});

    return api
      .getOneBooking(booking_id)
      .then((data) => {
        commit(SET_ACTIVE_BOOKING, data.data);
        return data.data;
      })
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  confirmCanAddBooking({ commit }, data) {
    return api
      .confirmCanAddBooking(data)
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  createBooking({ commit }, data) {
    return api
      .addBooking(data)
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  copyBooking({ commit }, { booking_id, dates }) {
    // PARAMS
    //	booking_id	@{String} Booking to copy
    //	dates 		@{Object} New dates of copied booking
    // RETURN @{Promise} => @{String} new booking id

    return api
      .copyBooking(booking_id, dates)
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  duplicateBooking({ commit }, { booking_id, dates }) {
    // PARAMS
    //	booking_id	@{String} Booking to copy
    //	dates 		@{Object} New dates of copied booking
    // RETURN @{Promise} => @{String} new booking id

    return api
      .duplicateBooking(booking_id, dates)
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  updateBooking({ commit, state }) {
    var booking_id = state.active.id;
    var data = state.active;

    if (!booking_id) return Promise.resolve();

    return api
      .updateBooking(booking_id, {
        booking: data,
        cache: state.cache,
      })
      .then((v) => {
        // Update new booking
        commit(TRANSFER_BOOKING);
      })
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  updateBookingDates({ commit, state }, dateInfo) {
    var booking_id = state.active.id;
    var data = state.active;
    var cached = state.cache;

    return api
      .updateBookingDates(booking_id, {
        nu_start: dateInfo.nuFrom,
        nu_end: dateInfo.nuTo,
        keepType: dateInfo.keepType,
        old_start: cached.from,
        old_end: cached.to,
      })
      .then((v) => {
        commit(UPDATE_BOOKING, { key: "from", val: dateInfo.nuFrom });
        commit(UPDATE_BOOKING, { key: "to", val: dateInfo.nuTo });
        commit(UPDATE_BOOKING, { key: "need_sup_update", val: 1 });
        return api.updateBooking(booking_id, {
          booking: state.active,
          cache: state.cache,
          updateDays: false,
        });
      })
      .then((v) => {
        commit(TRANSFER_BOOKING);
      })
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  checkRestorePoint({ commit }) {
    var booking_id = state.active.id;

    return api
      .checkHasBookingDates(booking_id)
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  revertBookingDates({ commit }) {
    var booking_id = state.active.id;

    return api
      .revertBookingDates(booking_id)
      .then((dateInfo) => {
        commit(UPDATE_BOOKING, { key: "from", val: dateInfo.data.prev_from });
        commit(UPDATE_BOOKING, { key: "to", val: dateInfo.data.prev_to });
        return api.updateBooking(booking_id, {
          booking: state.active,
          cache: state.cache,
          updateDays: false,
        });
      })
      .then((v) => {
        commit(TRANSFER_BOOKING);
        return {
          nuFrom: state.active.from,
          nuTo: state.active.to,
        };
      })
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  updateBookingMajor({ commit, state }, data) {
    if (data.key === "client") {
      commit(UPDATE_CLIENT, data.val);
    } else {
      commit(UPDATE_BOOKING, data);
    }

    var booking_id = state.active.id;
    var booking = state.active;

    return api
      .updateBooking(booking_id, {
        booking: booking,
        cache: state.cache,
        updateDays: false,
      })
      .then((v) => {
        // Update new booking
        commit(TRANSFER_BOOKING);
      })
      .catch((err) => {
        if (err) throw err.data;
      });
  },

  deleteBooking({ commit, state }) {
    var booking_id = state.active.id;
    return api
      .deleteBooking(booking_id)
      .then((v) => commit(SET_ACTIVE_BOOKING, {}))
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  cancelBooking({ commit, state }, cancelData) {
    var booking_id = state.active.id;
    return api
      .cancelBooking(booking_id, cancelData)
      .then((v) => commit(SET_ACTIVE_BOOKING, {}))
      .catch((err) => {
        if (err) throw err.data;
      });
  },

  setContract({ commit }, contracts) {
    commit(SET_CONTRACT, contracts);
  },
  setInvoice({ commit }, invoices) {
    commit(SET_INVOICE, invoices);
  },
};

const getters = {
  booking_id: (state) => state.active.id,
  booking_name: (state) => (customBooking) => {
    if (!state.active && !customBooking) return "";
    const a = customBooking || state.active;
    return `${a.client_name || "???"}/${a.group_name}: ${a.where_start} to ${a.where_end}`;
  },
  suppliersNeedUpdate: (state) => {
    return state.active.need_sup_update;
  },
  isPendingPostCopyUpdate: (state) => {
    return state.active.pending_state && state.active.status === 1;
  },
  isPendingPostCopyCancel: (state) => {
    return state.active.pending_state && state.active.status === 0;
  },
  active: (state) => state.active || {},
  data: (state) => (state.active ? state.active.data || {} : {}),
  pax_num: (state) =>
    state.active && state.active.data && state.active.data.pax_num ? Number(state.active.data.pax_num || 0) : 0,
  isCancelled: (state) => {
    if (!state.active) return false;
    return state.active.status === -1;
  },

  isArchived: (state) => {
    if (!state.active) return false;
    return state.active.archived === 1 || moment(state.active.to).isBefore(moment(), "day");
  },
  isArchiveLock: (state) => {
    if (!state.active) return false;
    return moment(state.active.to).isBefore(moment(), "day");
  },

  hasChanges: (state) => {
    const orig = {
      data: state.cache.data,
      notes: state.cache.notes,
      invoice_notes: state.cache.invoice_notes,
    };
    const active = {
      data: state.active.data,
      notes: state.active.notes,
      invoice_notes: state.active.invoice_notes,
    };
    return JSON.stringify(active) !== JSON.stringify(orig);
  },

  /* OLD DAYS */
  oldDates: (state) => {
    let sa = state.active;

    return {
      old_start: sa.prev_from,
      old_end: sa.prev_to,
      old_days: sa.revert_status_blob ? sa.revert_status_blob.oldDayRows || [] : [],
      old_suppliers: sa.revert_status_blob ? sa.revert_status_blob.suppliers || {} : {},
    };
  },

  contracts: (state) => state.contracts || [],
  invoices: (state) => state.invoices || [],
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
