<template>
  <v-row class="subtitle-2" justify="center" align="center" style="height: 100%">
    <span class="caption font-weight-bold">{{ formatedTotal }}</span>
  </v-row>
</template>

<script>
import money from "@/services/money";
export default {
  props: {
    country: String,
    total: Number,
  },
  computed: {
    formatedTotal() {
      const source = money.getCurrencySource(this.country);
      return this.total ? `$ ${money.format(this.total, source.money)}` : "$ --";
    },
  },
};
</script>
