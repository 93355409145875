<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)" width="400" persistent>
    <v-card>
      <v-card-title> Client Payment </v-card-title>
      <v-card-text class="black--text">
        <v-row v-if="remainingTotal === 0" class="green pa-1 mb-1" justify="center">
          <span class="subtitle-2">PAID</span>
        </v-row>

        <v-row justify="center">
          <v-btn-toggle v-model="paymentType" mandatory>
            <v-btn value="FULL" small> Full </v-btn>
            <v-btn value="PARTIAL" small> Partial </v-btn>
            <v-btn value="DEPOSIT" small> Deposit </v-btn>
          </v-btn-toggle>
        </v-row>

        <TotalPaidUnpaidRow :totalInvoice="totalInvoice" :remainingTotal="remainingTotal" :paidAmount="paidAmount" />

        <v-divider />

        <Payment
          v-if="paymentType === 'PARTIAL'"
          v-model="invoiceValue"
          :totalInvoice="totalInvoice"
          :remainingTotal="remainingTotal"
          :paidAmount="paidAmount"
        />

        <Payment
          v-if="paymentType === 'FULL'"
          v-model="fullInvoice"
          altlabel="Amount Paid"
          :totalInvoice="totalInvoice"
          :remainingTotal="remainingTotal"
          :paidAmount="paidAmount"
        />

        <Deposit v-if="paymentType === 'DEPOSIT'" v-model="invoiceValue" :defaultValue="defaultDeposit" />

        <PaymentDateRow v-model="invoiceDate" />
      </v-card-text>

      <v-card-actions>
        <g-button @onclick="clear" label="Cancel" />
        <div class="flex-grow-1"></div>
        <g-button type="primary" :loading="loading" @onclick="update" label="Update" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import money from "@/services/money";
import format from "@/services/format";

import Deposit from "@/modules/finances.report/components/paymentTypes/Deposit";
import Payment from "@/modules/finances.report/components/paymentTypes/Payment";
import TotalPaidUnpaidRow from "@/modules/finances.report/components/paymentTypes/_TotalPaidUnpaidRow";
import PaymentDateRow from "@/modules/finances.report/components/paymentTypes/_PaymentDateRow";
export default {
  props: {
    bookingId: String,

    value: Boolean,
    paidAmount: [Number, String],
    totalInvoice: [Number, String],
  },
  components: {
    Deposit,
    Payment,
    TotalPaidUnpaidRow,
    PaymentDateRow,
  },
  data() {
    return {
      paymentType: "INVOICE",
      invoiceDate: format.convertDate(new Date()),
      invoiceValue: null,
      fullInvoice: this.totalInvoice - this.paidAmount,
      loading: false,
    };
  },
  computed: {
    defaultDeposit() {
      const invoice = this.$store.getters["FinanceReportStore/client_invoice"];

      return invoice.total_invoice;
    },

    remainingTotal() {
      return this.paidAmount && this.totalInvoice ? this.totalInvoice - this.paidAmount : this.totalInvoice || 0;
    },
  },
  methods: {
    update() {
      this.loading = true;
      this.$store
        .dispatch("FinanceReportStore/addClientPayment", {
          booking_id: this.bookingId,
          data: {
            paid_amount: this.paymentType === "FULL" ? Number(this.fullInvoice) : Number(this.invoiceValue),
            paid_date: this.invoiceDate,
            isdeposit: this.paymentType === "DEPOSIT" ? 1 : 0,
            //payment_type: this.paymentType,
          },
        })
        .then((v) => {
          this.clear();
          this.loading = false;
        });
    },
    clear() {
      this.paymentType = "FULL";
      this.invoiceValue = null;
      this.fullInvoice = Math.max(this.totalInvoice - this.paidAmount, 0);
      this.$emit("input", false);
    },
  },
};
</script>
