<template>
  <v-navigation-drawer permanent :width="width" style="min-width: 150px">
    <v-row nowrap class="pa-3">
      <v-btn icon small :to="{ name: 'database' }">
        <v-icon small>arrow_back</v-icon>
      </v-btn>
      <v-breadcrumbs class="pa-0 ml-3 text-capitalize" :items="breadcrumbs" divider=">"></v-breadcrumbs>
    </v-row>

    <v-divider />

    <v-list dense nav>
      <v-list-item
        v-for="item in menuItems"
        :key="item.title"
        class="py-2"
        color="success"
        :class="{ 'grey lighten-2': item.module === dbSource }"
        @click="nav(item)"
      >
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { SuppliersMenu, ProductMenu } from "@/config/menus";

var MENUS = {
  products: ProductMenu,
  suppliers: SuppliersMenu.FULL,
  clients: [
    {
      title: "All",
      module: "ALL",
    },
  ],
};

export default {
  props: {
    dbConfig: String,
    dbSource: String,
  },
  computed: {
    menuItems() {
      return MENUS[this.dbConfig] || [];
    },
    breadcrumbs() {
      return [
        {
          text: "Database",
          to: { name: "database" },
        },
        {
          text: this.dbConfig,
        },
      ];
    },
    width() {
      if (this.dbConfig === "products") return 190;
      return 220;
    },
  },
  methods: {
    nav(item) {
      this.$router.push({
        name: "database_view",
        params: {
          view: this.dbConfig,
          sub: item.module,
        },
      });
    },
  },
};
</script>

<style></style>
