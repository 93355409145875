<template>
  <div>
    <a-hover-menu>
      <template v-slot:activator>
        <span>{{ booking.group_name }}</span>
        <v-icon v-if="booking.report_finished" color="green" small right>verified</v-icon>
        <span v-if="booking.report_archived" class="grey lighten-2 font-weight-regular mx-1 px-1 caption"
          >(Archived)</span
        >
      </template>

      <div class="caption" style="min-width: 200px">
        <v-row>
          <v-col>{{ fromDate }}</v-col>
          <v-col>{{ toDate }}</v-col>
        </v-row>
        <v-row>
          <v-col>{{ booking.where_start }}</v-col>
          <v-col>{{ booking.where_end }}</v-col>
        </v-row>
      </div>
    </a-hover-menu>
  </div>
</template>

<script>
import format from "@/services/format";
export default {
  props: {
    booking: Object,
  },
  computed: {
    fromDate() {
      return format.formatDate(this.booking.from, true);
    },
    toDate() {
      return format.formatDate(this.booking.to);
    },
  },
};
</script>
