<template>
  <div>
    <div class="text-center">
      <v-row class="mx-0" v-if="editMode || !hideLogo">
        <v-col cols="1" v-if="editMode">
          <v-btn icon small @click="$emit('update', { label: 'hide_logo', value: !hideLogo })">
            <v-icon>{{ hideLogo ? "visibility_off" : "visibility" }}</v-icon>
          </v-btn>
        </v-col>
        <v-col>
          <img src="@/assets/img/Logo.png" style="height: 120px" />
        </v-col>
      </v-row>

      <div style="margin: 20px auto">
        <div class="title">
          <b>Group {{ groupName }} ({{ pax }} PAX)</b>
        </div>
        <div class="title caps">
          <b>{{ date }}</b>
        </div>
        <div class="title underline"><b>Technical Notes</b></div>
      </div>

      <v-row class="mx-0 my-5" v-if="editMode || !hideEstimate">
        <v-col cols="1" v-if="editMode">
          <v-btn icon small @click="$emit('update', { label: 'hide_estimate', value: !hideEstimate })">
            <v-icon>{{ hideEstimate ? "visibility_off" : "visibility" }}</v-icon>
          </v-btn>
        </v-col>
        <v-col>
          <div class="subtitle-1 red--text" v-html="headerWarningText"></div>
        </v-col>
      </v-row>
    </div>

    <v-row class="my-5" v-if="confirmedCoaches.length && (editMode || !hideBuses)">
      <v-col cols="1" v-if="editMode">
        <v-btn icon small @click="$emit('update', { label: 'hide_buses', value: !hideBuses })">
          <v-icon>{{ hideBuses ? "visibility_off" : "visibility" }}</v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <v-row class="mx-3 font-weight-bold caption">
          <v-col class="pa-0">Coach Company</v-col>
          <v-col cols="3" class="pa-0">Contact</v-col>
          <v-col cols="5" class="pa-0">Notes</v-col>
        </v-row>
        <CoachesGroup
          v-for="company in confirmedCoaches"
          :key="company.id"
          :editMode="editMode"
          :company="company"
          :contactMap="contactMap"
          :notes="transport_notes"
          @update="(v) => $emit('update', { label: 'transport_notes', value: v })"
        />
      </v-col>
    </v-row>

    <v-row class="my-5" v-if="confirmedTourGuide.length && (editMode || !hideTourGuide)">
      <v-col cols="1" v-if="editMode">
        <v-btn icon small @click="$emit('update', { label: 'hide_tour_guides', value: !hideTourGuide })">
          <v-icon>{{ hideTourGuide ? "visibility_off" : "visibility" }}</v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <v-row class="mx-3 font-weight-bold caption">
          <v-col class="pa-0">Tour Guides</v-col>
          <v-col cols="3" class="pa-0">Contact</v-col>
          <v-col cols="5" class="pa-0">Notes</v-col>
        </v-row>
        <CoachesGroup
          v-for="company in confirmedTourGuide"
          :key="company.id"
          :editMode="editMode"
          :company="company"
          :contactMap="contactMap"
          :notes="tour_guide_notes"
          @update="(v) => $emit('update', { label: 'tour_guide_notes', value: v })"
        />
      </v-col>
    </v-row>

    <v-row class="mb-5 mx-0" v-if="editMode || !hideAttention">
      <v-col cols="1" v-if="editMode">
        <v-btn icon small @click="$emit('update', { label: 'hide_attention', value: !hideAttention })">
          <v-icon>{{ hideAttention ? "visibility_off" : "visibility" }}</v-icon>
        </v-btn>
      </v-col>
      <v-col class="attention">
        <div class="font-weight-bold underline caps">Attention:</div>

        <div v-if="editMode">
          <RichTextEditor
            maxheight="short"
            :value="attentionShow"
            @input="(v) => $emit('update', { label: 'header', value: v })"
          />
        </div>
        <div v-else v-html="attentionShow" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CoachesGroup from "./_CoachesGroup";
import format from "@/services/format";
import { BookingRoomService } from "@/modules/bookings.rooms";

export default {
  props: {
    hideLogo: Boolean,
    editMode: Boolean,
    header: String,
    transport_notes: Object,
    tour_guide_notes: Object,
    hideAttention: Boolean,
    hideEstimate: Boolean,
    hideBuses: Boolean,
    hideTourGuide: Boolean,

    contactMap: Object,
  },
  components: {
    CoachesGroup,
  },
  computed: {
    headerWarningText() {
      return this.$store.getters["TextConfigStore/TECHNICAL_WARN_HEADER"];
    },
    groupName() {
      return this.$store.getters["BookingStore/active"].group_name;
    },
    pax() {
      const paxFromRoom = BookingRoomService.getPaxCount(
        this.$store.getters["BookingStore/data"].room_num,
        this.$store.getters["BookingRoomStore/rooms"]
      );
      const supplierRoomList = BookingRoomService.getExtraRoomList(
        this.$store.getters["BookingDayStore/days"],
        this.$store.getters["BookingSupplierRoomStore/supplier_rooms"]
      );

      return supplierRoomList ? `${paxFromRoom}+${supplierRoomList.ALL.length}` : paxFromRoom;
    },
    date() {
      const firstDay = this.$store.getters["BookingDayStore/firstDay"].date;
      const lastDay = this.$store.getters["BookingDayStore/lastDay"].date;
      const ignoreYear = format.dateToObj(firstDay).year === format.dateToObj(lastDay).year;
      return `${format.formatDate(firstDay, ignoreYear)} - ${format.formatDate(lastDay)}`;
    },

    confirmedCoaches() {
      let supplierRef = {};
      let first, last;
      this.$store.getters["BookingDayStore/confirmedSuppliers"]("TRANSPORT").forEach((item) => {
        let dates = this.$store.getters["BookingDayStore/whatDays"]("TRANSPORT", item.id);

        if (item.supplier_meta && item.supplier_meta.trans_type && item.supplier_meta.trans_type === "TRAIN") {
          return;
        }

        _extractAndSort(supplierRef, item, dates);
      });

      return _formatCoach(supplierRef);
    },
    confirmedTourGuide() {
      let supplierRef = {};
      let first, last;
      this.$store.getters["BookingDayStore/confirmedSuppliers"]("TOUR_GUIDE").forEach((item) => {
        let dates = this.$store.getters["BookingDayStore/whatDays"]("TOUR_GUIDE", item.id);

        _extractAndSort(supplierRef, item, dates);
      });

      return _formatCoach(supplierRef);
    },

    // ATTENTION BLOCK
    attentionShow() {
      return this.header || this.$store.getters["TextConfigStore/TECHNICAL_ATTENTION"];
    },
  },
};

function _extractAndSort(supplierRef, item, dates) {
  // MODIFIER FUNCTION
  // Same supplier, different products (bound = true)
  if (!supplierRef[item.supplier_id]) {
    supplierRef[item.supplier_id] = {
      supplier_id: item.supplier_id,
      name: item.name,
      confirm_number: item.meta.confirm_number,
      dates: dates,
    };
    return;
  }

  supplierRef[item.supplier_id].dates = supplierRef[item.supplier_id].dates.concat(dates).sort((a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  });
}

function _formatCoach(group) {
  return Object.values(group)
    .map((item) => {
      return {
        supplier_id: item.supplier_id,
        name: item.name,
        confirm_number: item.confirm_number,
        daySort: item.dates[0],
        dates: item.dates.map((day) => format.formatDate(day)).join(", "),
      };
    })
    .sort((a, b) => {
      if (a.daySort < b.daySort) return -1;
      if (a.daySort > b.daySort) return 1;
      return 0;
    });
}
</script>

<style lang="scss">
.technical-wrapper {
  .attention {
    background: lightgrey;
    border: 3px solid red;
    padding: 10px;
    margin: 10px auto;
  }
}
</style>
