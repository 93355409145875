<template>
  <v-hover v-slot="{ hover }">
    <v-row align="center" class="ma-0 pa-0 flex-nowrap">
      <v-icon v-if="hover" x-small>edit</v-icon>
      <span v-if="supplier.time" class="font-weight-bold" style="font-size: 10px; margin-right: 5px"
        >{{ supplier.time }} -</span
      >
      <HotelPriceHover
        v-if="showPriceBreakdown"
        multi
        :text="supplier.name"
        :supplierMeta="bookingData.supplier_meta"
        :bookingSupplierMeta="bookingData.meta"
        :original="productList"
        :showConvert="showConverstion"
      />
      <OtherPriceHover v-else multi :text="supplier.name" :original="productList" />
    </v-row>
  </v-hover>
</template>

<script>
import { HotelPriceHover, BookingDayService, OtherPriceHover } from "@/modules/bookings.days";
export default {
  props: {
    supplier: Object,
    date: String,
    typeAs: String,
  },
  components: {
    HotelPriceHover,
    OtherPriceHover,
  },
  computed: {
    bookingData() {
      return this.$store.getters["BookingStore/data"];
    },
    bookingSupplier() {
      return this.$store.getters["BookingDayStore/suppliers"][this.supplier.id];
    },
    productList() {
      return BookingDayService.getBookingSupplierProductList(
        this.bookingData,
        this.bookingSupplier,
        this.date,
        this.$store.getters
      );
    },
    showConverstion() {
      return this.$store.getters["AccountStore/environment"] !== "USA";
    },
    showPriceBreakdown() {
      return this.typeAs === "HOTEL";
    },
  },
};
</script>
