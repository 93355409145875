<template>
  <div>
    <v-row justify="space-between">
      <h3 class="title white--text">Lists</h3>
    </v-row>
    <a-card
      v-for="item in lists"
      :key="item.pathname"
      class="hover"
      @click.native="$router.push({ name: 'admin_edit_list', params: { list: item.listname } })"
    >
      {{ item.label }}
    </a-card>
  </div>
</template>

<script>
import OPTION_LIST_CONFIG from "@/config/OPTION_LIST_CONFIG";
export default {
  data() {
    return {
      lists: Object.keys(OPTION_LIST_CONFIG).reduce((arr, key) => {
        arr.push({
          label: OPTION_LIST_CONFIG[key].label,
          listname: key,
        });
        return arr;
      }, []),
    };
  },
};
</script>
