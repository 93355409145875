export default {
  "Room Type": {
    prop: "room_type",
    type: String,
    required: true,
  },
  "Room Number": {
    prop: "room_number",
    type: Number,
    required: true,
  },
  "First Name": {
    prop: "first_name",
    type: String,
  },
  "Last Name": {
    prop: "last_name",
    type: String,
  },
  "Adult / Child": {
    prop: "adult_or_child",
    type: String,
  },
  "Age of Child": {
    prop: "child_age",
    type: Number,
  },
  Requests: {
    prop: "requests",
    type: String,
  },
};
