import api from "./api";
import ReportService from "./service";
import ReportCompileService from "./compile.service";
import Vue from "vue";
import { BookingRoomService } from "@/modules/bookings.rooms";

const SET_COMPILED = "SET_COMPILED";

const UPDATE_ONE_SUPPLIER = "UPDATE_ONE_SUPPLIER";
const REMOVE_INVOICE = "REMOVE_INVOICE";
const ADD_EXTRA_EXPENSE = "ADD_EXTRA_EXPENSE";
const SET_CUSTOM_COMMISSION = "SET_CUSTOM_COMMISSION";
const REMOVE_CUSTOM_COMMISSION = "REMOVE_CUSTOM_COMMISSION";
const UPDATE_EXTRA_EXPENSE = "UPDATE_EXTRA_EXPENSE";
const REMOVE_EXTRA_EXPENSE = "REMOVE_EXTRA_EXPENSE";

const namespaced = true;

const state = {
  compiledProducts: {},
  supplierBreakdown: {},
  delta: {},
  supplier_invoices: {},
  custom_commissions: {},
  extra_expenses: [],
};

const mutations = {
  SET_COMPILED(state, data) {
    state.compiledProducts = data.compiledProducts;
    state.supplierBreakdown = data.supplierBreakdown;
    state.extra_expenses = data.extra_expenses;
    state.supplier_invoices = data.supplier_invoices;
    state.custom_commissions = data.custom_commissions;

    // COMPILE DELTA
    state.delta = ReportService.extractDeltas(state.supplierBreakdown, state.supplier_invoices);
  },

  UPDATE_ONE_SUPPLIER(state, { supplier_id, type_as, data }) {
    Vue.set(state.supplier_invoices, supplier_id + type_as, data);
    // COMPILE DELTA
    state.delta = ReportService.extractDeltas(state.supplierBreakdown, state.supplier_invoices);
  },
  REMOVE_INVOICE(state, { supplier_id, type_as }) {
    Vue.delete(state.supplier_invoices, supplier_id + type_as);
    // COMPILE DELTA
    state.delta = ReportService.extractDeltas(state.supplierBreakdown, state.supplier_invoices);
  },

  SET_CUSTOM_COMMISSION(state, { customCommissionId, data }) {
    if (!customCommissionId) {
      // CREATE
      state.custom_commissions.push(data);
    } else {
      // ALREADY EXIST
      let focus = state.custom_commissions.find((v) => v.id === customCommissionId);
      focus.custom_amount = data.custom_amount;
    }
  },
  REMOVE_CUSTOM_COMMISSION(state, custom_commission_id) {
    state.custom_commissions = state.custom_commissions.filter((p) => p.id !== custom_commission_id);
    // COMPILE DELTA
    state.delta = ReportService.extractDeltas(state.supplierBreakdown, state.supplier_invoices);
  },

  ADD_EXTRA_EXPENSE(state, data) {
    state.extra_expenses.push(data);
    // stash(state)
  },
  UPDATE_EXTRA_EXPENSE(state, { expense_id, data }) {
    const extra = state.extra_expenses.find((p) => p.id === expense_id);
    Vue.set(extra, "reconciled", data.reconciled);
  },
  REMOVE_EXTRA_EXPENSE(state, expense_id) {
    state.extra_expenses = state.extra_expenses.filter((p) => p.id !== expense_id);
    // stash(state)
  },
};

const actions = {
  setReportingCalculations({ commit }, { data, storeGetter }) {
    const bd = data.booking.data;
    const compiledProducts = _compileProducts({
      pax: bd && (bd.pax_num || bd.est_pax_num),
      booking_suppliers: data.booking_suppliers,
      booking_days: data.days,
      bookingMetaData: bd,
      bookingRoomingList: data.rooms.rooms,
      supplierRoomList: data.supplier_rooms,
      storeGetter,
    });
    const supplierBreakdown = ReportCompileService.compileSupplierBreakdown(data.booking_suppliers, compiledProducts);

    commit(SET_COMPILED, {
      supplier_invoices: data.supplier_invoices.reduce((temp, item) => {
        temp[item.supplier_id + item.type_as] = item;
        return temp;
      }, {}),
      extra_expenses: data.extra_expenses,
      custom_commissions: data.custom_commissions,
      compiledProducts,
      supplierBreakdown,
    });
  },

  updateSupplierInvoice({ commit }, { booking_id, supplier_id, type_as, data, tocreate, cache, skipCommit }) {
    return api
      .setSupplierReconcilation(booking_id, supplier_id, type_as, data, tocreate, cache)
      .then((res) => {
        if (!skipCommit) commit(UPDATE_ONE_SUPPLIER, { supplier_id, type_as, data: res.data || data });
        return Promise.resolve({ supplier_id, type_as, data: res.data || data });
      })
      .catch((err) => err.data);
  },
  removeSupplierInvoice({ commit, state }, { supplier_invoice_id, supplier_id, type_as, skipCommit }) {
    // const supplier_invoice_id = state.supplier_invoices[supplier_id + type_as].id

    return api
      .removeSupplierInvoice(supplier_invoice_id)
      .then((res) => {
        if (!skipCommit) commit(REMOVE_INVOICE, { supplier_id, type_as });
        return supplier_invoice_id;
      })
      .catch((err) => err.data);
  },

  // CUSTOM COMMISSION
  setCustomCommission({ commit }, { booking_id, supplier_id, type_as, data, customCommissionId, cache, skipCommit }) {
    return api
      .setCustomCommission(booking_id, supplier_id, type_as, data, !!customCommissionId, cache)
      .then((res) => {
        if (!skipCommit) commit(SET_CUSTOM_COMMISSION, { customCommissionId, data: res.data });
        return res.data;
      })
      .catch((err) => err.data);
  },
  removeCustomCommission({ commit, state }, { custom_commission_id, skipCommit }) {
    return api
      .removeCustomCommission(custom_commission_id)
      .then((res) => {
        if (!skipCommit) commit(REMOVE_CUSTOM_COMMISSION, custom_commission_id);
        return custom_commission_id;
      })
      .catch((err) => err.data);
  },

  // EXTRA EXPENSES
  addExtraExpenses({ commit }, { booking_id, data }) {
    return api
      .addExtraExpenses(booking_id, data)
      .then((data) => commit(ADD_EXTRA_EXPENSE, data.data))
      .catch((err) => err.data);
  },
  updateExtraExpense({ commit }, { expense_id, data }) {
    return api
      .updateExtraExpense(expense_id, data)
      .then((v) => commit(UPDATE_EXTRA_EXPENSE, { expense_id, data }))
      .catch((err) => err.data);
  },
  removeExtraExpenses({ commit }, expense_id) {
    return api
      .removeExtraExpenses(expense_id)
      .then((data) => commit(REMOVE_EXTRA_EXPENSE, expense_id))
      .catch((err) => err.data);
  },
};

const getters = {
  compiledProducts: (state) => state.compiledProducts || null,
  supplierBreakdown: (state) => state.supplierBreakdown || null,
  compiledCostTotal: (state) => {
    const invoiceTotal = Object.values(state.supplier_invoices || {}).reduce((total, item) => {
      return total + Number(item.total_cost || 0);
    }, 0);
    const extraTotal = ReportService.getExtraTotal(
      state.extra_expenses.filter((v) => {
        if (v.expense_type !== "DEPOSIT") return true;
        // Only include deposit in calucation if no total
        let hasTotal = Object.values(state.supplier_invoices || {}).find((a) => a.supplier_id === v.supplier_id);
        return !hasTotal;
      })
    );

    return invoiceTotal + extraTotal;
  },
  compiledOneTypeCostTotal: (state) => (type_as) => {
    const invoiceTotal = Object.values(state.supplier_invoices || {}).reduce((total, item) => {
      if (type_as !== item.type_as) return total;
      return total + Number(item.total_cost || 0);
    }, 0);
    const extraList = state.extra_expenses.filter((v) => {
      if (v.expense_type !== "DEPOSIT" && type_as === v.type_as) return true;
      if (type_as !== v.type_as) return false;
      // Only include deposit in calucation if no total
      let hasTotal = Object.values(state.supplier_invoices || {}).find((a) => a.supplier_id === v.supplier_id);
      return !hasTotal;
    });
    const extraTotal = ReportService.getExtraTotal(extraList);

    return invoiceTotal + extraTotal;
  },
  compiledDelta: (state) => {
    const deltaTotal = Object.values(state.delta).reduce((total, d) => {
      return total + (d || 0);
    }, 0);
    const extraTotal = ReportService.getExtraTotal(state.extra_expenses, true);

    return deltaTotal + extraTotal;
  },
  supplierDelta: (state) => (supplier_id, type_as) => {
    return state.delta[supplier_id + type_as];
  },

  supplier_invoices: (state) => state.supplier_invoices,
  custom_commissions: (state) => state.custom_commissions,
  extra_expenses: (state) => state.extra_expenses || [],
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};

function _compileProducts({
  pax,
  booking_suppliers,
  booking_days,

  bookingMetaData,
  bookingRoomingList,
  supplierRoomList,

  storeGetter,
}) {
  return ReportCompileService.compileProductPrices({
    pax,
    booking_suppliers,
    booking_days,
    roomBreakdown: BookingRoomService.getRoomingListBreakdown({
      bookingMetaData,
      bookingRoomingList,
      bookingDays: booking_days,
      supplierRoomList,
    }),
    storeGetter,
  });
}
