<template>
  <div class="d-flex align-center">
    <div class="d-flex align-center">
      <span>+</span>
      <v-text-field
        outlined
        hide-details
        dense
        style="max-width: 50px"
        :disabled="disabled"
        :value="phoneDisplay.code"
        @input="(v) => eject('code', v)"
      />
    </div>
    <v-text-field
      outlined
      hide-details
      dense
      v-mask="mask || '###-###-####'"
      masked
      :disabled="disabled"
      :value="phoneDisplay.phone"
      @input="(v) => eject('phone', v)"
    />

    <div class="d-flex align-center pl-2">
      <span class="caption">ext.</span>
      <v-text-field
        outlined
        hide-details
        dense
        style="max-width: 100px"
        :disabled="disabled"
        :value="phoneDisplay.ext"
        @input="(v) => eject('ext', v)"
      />
    </div>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import PHONE_MASK from "@/config/PHONE_MASK";
import service from "./phoneService";

export default {
  props: {
    value: [String, Object],
    country: String,
    disabled: Boolean,
  },
  directives: {
    mask,
  },
  computed: {
    mask() {
      return PHONE_MASK(this.country || "US") || PHONE_MASK("US");
    },
    phoneDisplay() {
      return service.phoneDisplay(this.value, this.mask[0], this.country);
    },
  },
  methods: {
    eject(source, v) {
      let orig = { ...this.phoneDisplay };
      orig[source] = v;
      this.$emit("input", orig);
    },
  },
};
</script>
