import api from "./api";
import moment from "moment";
import { SuppliersMenu } from "@/config/menus";
import { EmailTemplateService } from "@/modules/admin.email_template";
import { EmailService } from "@/modules/email";
import { ProductService } from "@/modules/products";

const EMAIL_LIMIT = 10;

export default {
  getBookingContacts,

  _pickFoundSuppliers,
  _mergeContactsWithSuppliers,
  _attachFirstDay,

  getFirstDayHotelBySupplierId,
  getRestaurantSupplierCount,

  compileOneBookingEmail,

  getLockedPriceMapping,
  getOneLockedPricing,

  updateBookingSupplierStatus,
};

function getBookingContacts(suppliers, maptype) {
  // Returns promise, which returns object
  const foundSupplierRef = _pickFoundSuppliers(suppliers, maptype);

  // Get list of contact ids
  const supplierIdList = Object.values(foundSupplierRef)
    .map((item) => item.supplier_id)
    .reduce((unique, item) => {
      // strip duplicates
      return unique.includes(item) ? unique : [...unique, item];
    }, []);

  if (!supplierIdList.length) {
    return Promise.resolve({});
  }

  return api
    .getBookingContacts(supplierIdList)
    .then((data) =>
      _adjustSupplierDisplayForExceptions(_mergeContactsWithSuppliers(foundSupplierRef, data.data), suppliers, maptype)
    );
}

function _pickFoundSuppliers(suppliers, maptype) {
  let picked = {},
    item;
  Object.keys(suppliers)
    .filter((key) => (maptype ? suppliers[key].type_as === maptype : true))
    .forEach((filteredkey) => {
      item = suppliers[filteredkey];
      // Not already picked, then add here
      if (!(item.supplier_id in picked)) {
        picked[item.supplier_id] = {
          supplier_id: item.supplier_id,
          name: item.name,
          supplier_meta: item.supplier_meta,
          compile: "GROUP",
          bsid: [],
        };
      }

      // Whether to show the rooming list + dates
      if (item.type_as === "HOTEL") {
        picked[item.supplier_id].hotelType = true;
      } else if (item.type_as === "RESTAURANT") {
        picked[item.supplier_id].restaurantType = true;
      } else if (item.type_as === "EXCURSION") {
        picked[item.supplier_id].excursionType = true;
      }

      // Add booking supplier id
      // This will be used to figure out products, dates, etc
      picked[item.supplier_id].bsid.push(filteredkey);
    });

  return picked;
}

function _mergeContactsWithSuppliers(foundSupplierRef, contactList) {
  // Add contact object thingy
  Object.values(foundSupplierRef).forEach((item) => {
    // Add contacts array
    if (!foundSupplierRef[item.supplier_id].contacts) {
      foundSupplierRef[item.supplier_id].contacts = [];
      foundSupplierRef[item.supplier_id].hidden = 0;
    }
  });

  // Want to create tree of suppliers with nested contacts
  contactList.forEach((item) => {
    // Only add contact if email exists
    if (!item.contact_email) {
      foundSupplierRef[item.supplier_id].hidden += 1;
      return;
    }

    // Add contact here
    foundSupplierRef[item.supplier_id].contacts.push({
      id: item.contact_id,
      name: item.contact_name,
      email: item.contact_email,
      title: item.title,
      supplier_id: item.supplier_id,
      supplier_name: item.supplier_name,
      supplier_city: item.supplier_city,
      supplier_meta: item.supplier_meta,
    });
  });

  return foundSupplierRef;
}

function _attachFirstDay(foundSupplierRef, bookingDays, bookingSuppliers) {
  // Takes suppliers, gets their FIRST day, and then sorts by first day + time + type
  // 	foundSupplierRef	@{Object}
  //	bookingDays 		@{Array}
  // 	bookingSuppliers 	@{Object}
  const DBFIELDS = SuppliersMenu.DBKEY;

  Object.values(foundSupplierRef).forEach((item) => {
    let foundBsid;
    // Get FIRST day it appears
    bookingDays.find((day) => {
      // Get FIRST match
      const match = DBFIELDS.find((mod) => {
        // Check if this module has a bsid saved to its day
        let overLapping = day[mod].filter((value) => item.bsid.includes(value));

        if (!overLapping.length) return false;
        // Overlap exists
        foundBsid = overLapping[0];
        return overLapping.length;
      });

      if (!match) return;
      // Add type
      item.dateTypeAs = match;
      item.dateBegin = day.date;
      return true;
    });

    // Extract time if exists
    item.time = bookingSuppliers[foundBsid].time;
  });

  return Object.values(foundSupplierRef).sort((a, b) => {
    // Sort first by date
    if (a.dateBegin < b.dateBegin) return -1;
    if (a.dateBegin > b.dateBegin) return 1;
    // Then by time (if time exists)
    if (a.time < b.time) return -1;
    if (a.time > b.time) return 1;
    // Sort then by type
    const aIndex = DBFIELDS.indexOf(a.dateTypeAs);
    const bIndex = DBFIELDS.indexOf(b.dateTypeAs);
    if (aIndex < bIndex) return -1;
    if (aIndex > bIndex) return 1;
    return 0;
  });
}

function _adjustSupplierDisplayForExceptions(supplierEmailData, bookingSuppliers, maptype) {
  if (maptype === "HOTEL") {
    // Merge in products associated with these hotels
    Object.values(supplierEmailData).forEach((item) => {
      item.bsid = Object.values(bookingSuppliers)
        .filter((bs) => {
          if (bs.supplier_id === item.supplier_id) return true;
          return false;
        })
        .map((bs) => bs.id);
    });
  }

  // if (maptype === "RESTAURANT") {
  //   // Remove Hotels from displaying as Restaurant
  //   return Object.values(supplierEmailData).filter((item) => {
  //     const match = Object.values(bookingSuppliers).find((i) => item.supplier_id === i.supplier_id);
  //     return match.supplier_type !== "HOTEL";
  //   });
  // }

  return Object.values(supplierEmailData);
}

function getFirstDayHotelBySupplierId(supplierIdList, bookingDays, bookingSuppliers) {
  // Used to get HOTEL for rooming list

  let foundBlockList = [];

  // Extract first day of each supplier "block"
  supplierIdList.forEach((id) => {
    let name;
    // Filter and extract non-sequence dates
    let temp = bookingDays
      .filter((day) => {
        return SuppliersMenu.FULL.find((item) => {
          if (!day[item.alt]) return;
          return day[item.alt].find((bsid) => {
            // This will update until match is found
            name = bookingSuppliers[bsid].name;
            return bookingSuppliers[bsid].supplier_id === id;
          });
        });
      })
      .map((day) => day.date)
      .sort((a, b) => {
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      })
      .reduce((arr, date) => {
        // Create array of arrays
        if (!arr.length) return [[date]];
        let prev = arr[arr.length - 1];
        // Append if day after
        if (moment(date).diff(prev[prev.length - 1], "days") === 1) {
          prev.push(date);
          return arr;
        }

        // Else start new sub-group
        return [...arr, [date]];
      }, []);

    temp.forEach((dateList) => {
      foundBlockList.push({
        supplier_id: id,
        supplier_name: name,
        date: dateList[0],
        dateList,
      });
    });
  });

  return foundBlockList;
}

function getRestaurantSupplierCount(bookingDays, bookingSuppliers, bsidList, exclude) {
  return bookingDays.reduce((mapping, day) => {
    const date = day.date;
    bsidList.forEach((bsid) => {
      if (day.restaurants.includes(bsid)) {
        mapping[date] =
          day.tour_guides.reduce((total, item) => {
            if (exclude && date in exclude && item in exclude[date]) return total;
            return total + 1;
          }, 0) +
          day.transport.reduce((total, item) => {
            if (exclude && date in exclude && item in exclude[date]) return total;
            let count = (bookingSuppliers[item].meta && bookingSuppliers[item].meta.count) || {};
            return total + (Object.values(count).reduce((t, c) => t + Number(c), 0) || 1);
          }, 0);
      }
    });
    return mapping;
  }, {});
}

function compileOneBookingEmail({
  contacts,
  subject,
  message,
  bookingDays,
  supplierType,
  bookingSuppliers,
  groupBy,
  ignoreProducts,
  bookingRoomData,
  oldDates,
  language = "EN",
  cc,
}) {
  // Group contact emails + insert their names
  let groupedSupplierContacts = EmailService.groupSupplierContacts(contacts, "supplier_id", cc);
  groupedSupplierContacts.forEach((selected) => {
    selected.message = EmailTemplateService.replaceContact(selected.contacts.nameList, message, language);
    selected.message = EmailTemplateService.replaceSupplier(selected.message, selected);
  });

  // Handle email/product group splitting
  // And assign relevant booking supplier/supplier and product for emails
  let groupedEmailInfo = EmailService.groupSupplierProducts(
    groupedSupplierContacts,
    supplierType,
    bookingDays,
    bookingSuppliers,
    groupBy || {},
    ignoreProducts,
    oldDates
  );

  // Fill in product information
  let bshotel;
  let finalEmailGroups = groupedEmailInfo.map((item) => {
    return EmailTemplateService.fillProductsBySupplierAndProductGroup(item, bookingRoomData, subject);
  });

  return finalEmailGroups;
}

function getLockedPriceMapping(selectedSuppliers) {
  let endMap = {};
  Object.values(selectedSuppliers).forEach((item) => {
    endMap[item.id] = getOneLockedPricing(item);
  });

  return endMap;
}

function getOneLockedPricing(supplier, storeGetters) {
  const products = supplier.products;
  const customPrices = supplier.meta ? { ...supplier.meta.custom_prices } || {} : {};

  let lockedList = {},
    pricePackage;
  Object.values(products).forEach((product) => {
    pricePackage = ProductService.getPrice(
      product,
      supplier.supplier_meta,
      supplier.country,
      customPrices,
      storeGetters,
      false,
      supplier.meta
    );

    if (!product.seasonal) {
      lockedList[product.id] = {
        totalPrice: pricePackage.totalPrice,
        ...(pricePackage.totalConvert && { totalConvert: pricePackage.totalConvert }),
      };
      return;
    }

    // Seasonal
    lockedList[product.id] = {};
    pricePackage.forEach((seasonalRate, index) => {
      lockedList[product.id][index] = {
        totalPrice: seasonalRate.totalPrice,
        ...(seasonalRate.totalConvert && { totalConvert: seasonalRate.totalConvert }),
      };
    });
  });

  return lockedList;
}

function updateBookingSupplierStatus({ booking_id, bookingSupplierIdList, status, lockedPriceMapping }) {
  return api.updateBookingSupplierStatus({ booking_id, bookingSupplierIdList, status, lockedPriceMapping });
}
