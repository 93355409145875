<template>
  <btndrop :value="isReconciled" onLabel="Reconciled" offLabel="Not Reconciled" :onchange="updateReconciled" />
</template>

<script>
export default {
  props: {
    external: Boolean,
    bookingId: String,
    supplierId: String,
    typeAs: String,
    supplierInvoice: Object,
  },
  computed: {
    isReconciled() {
      return this.supplierInvoice && this.supplierInvoice.reconciled;
    },
  },
  methods: {
    updateReconciled(v) {
      const tocreate = this.supplierInvoice;

      return this.$store
        .dispatch("FinanceCompileStore/updateSupplierInvoice", {
          skipCommit: this.external,
          booking_id: this.bookingId,
          supplier_id: this.supplierId,
          type_as: this.typeAs,
          tocreate: !tocreate,
          data: {
            total_cost: this.supplierInvoice && this.supplierInvoice.total_cost,
            reconciled: v,
          },
          ...(tocreate && {
            cache: {
              total_cost: tocreate.total_cost,
              reconciled: tocreate.reconciled,
            },
          }),
        })
        .then((data) => {
          this.$emit("update", data);
        })
        .catch((err) => {
          this.$root.$error("Failed to save supplier");
        });
    },
  },
};
</script>
