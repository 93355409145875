// Corresponds to Supplier types
const HOTEL = "HOTEL";
const RESTAURANT = "RESTAURANT";
const TRANSPORT = "TRANSPORT";
const EXCURSION = "EXCURSION";
const PARK = "PARK";
const TOUR_GUIDE = "TOUR_GUIDE";

export default [
  {
    title: "Accommodations",
    module: "ROOM",
    restrictTo: [HOTEL],
  },
  {
    title: "Meals",
    module: "MEAL",
    restrictTo: [HOTEL, RESTAURANT],
  },
  {
    title: "Excursions",
    module: "EXCURSION",
    restrictTo: [HOTEL, EXCURSION, PARK],
  },
  {
    title: "Tour Guide Services",
    module: "TOUR",
    restrictTo: [TOUR_GUIDE],
  },
  {
    title: "Transportation",
    module: "TRANS",
    restrictTo: [TRANSPORT, TOUR_GUIDE],
  },
  {
    title: "Expense",
    module: "EXPENSE",
  },
];
// }, {
// 	title: 'Other',
// 	module: 'OTHER'
// }]
