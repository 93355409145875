<template>
  <v-dialog v-model="dialog" width="800">
    <template v-slot:activator="{ on }">
      <v-btn depressed small outlined color="primary" style="background: white" v-on="on">
        <v-icon small left>visibility</v-icon>
        <span>Preview</span>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-icon small left>visibility</v-icon>
        <span class="subtitle-2">Preview Email</span>
        <span class="caption mx-3">(This is what will be sent)</span>
      </v-card-title>

      <v-divider />

      <v-card-text class="black--text mt-3">
        <v-row>
          <v-col cols="1" @click="back" :class="{ hover: canGoBack }">
            <v-icon v-show="canGoBack">arrow_back_ios</v-icon>
          </v-col>

          <v-col cols="10">
            <div class="subtitle-2">
              <span>Sending to: </span>
              <v-chip v-for="(email, index) in focusEmailContacts" :key="index">{{ email }}</v-chip>
            </div>
            <div>
              <span class="subtitle-2">Subject: </span>
              <span>{{ focusSubject }}</span>
            </div>
            <v-divider class="my-2" />
            <div v-html="focusEmailMessage"></div>
          </v-col>

          <v-col cols="1" @click="forward" :class="{ hover: canGoForward }">
            <v-icon v-show="canGoForward">arrow_forward_ios</v-icon>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="d-flex justify-end">
        <g-button label="Okay" @onclick="dialog = false" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import service from "../../service";
export default {
  props: {
    subject: String,
    compileMailFn: Function, // MUST RETURN PROMISE
  },
  data() {
    return {
      dialog: false,
      loading: false,

      messageDataList: [],
      focusEmailIndex: 0,
    };
  },
  watch: {
    dialog(v) {
      if (v) {
        this.compiledTemplate();
      } else {
        this.focusEmailIndex = 0;
      }
    },
  },
  computed: {
    focusSubject() {
      if (!this.messageDataList.length) return "";
      return this.messageDataList[this.focusEmailIndex].customSubject || this.subject;
    },
    focusEmailContacts() {
      if (!this.messageDataList.length) return "";
      return this.messageDataList[this.focusEmailIndex].contacts.emailList;
    },
    focusEmailMessage() {
      if (!this.messageDataList.length) return "(No selected contacts)";
      return this.messageDataList[this.focusEmailIndex].message;
    },

    canGoBack() {
      return this.focusEmailIndex > 0;
    },
    canGoForward() {
      return this.focusEmailIndex < this.messageDataList.length - 1;
    },
  },
  methods: {
    back() {
      if (this.focusEmailIndex === 0) return;
      this.focusEmailIndex -= 1;
    },
    forward() {
      if (this.focusEmailIndex === this.messageDataList.length - 1) return;
      this.focusEmailIndex += 1;
    },
    compiledTemplate() {
      this.loading = true;

      this.compileMailFn().then((messageData) => {
        messageData.forEach((item) => {
          // ATTACH SIGNATURE
          item.message += this.$store.getters["AccountStore/signature"];
        });
        this.loading = false;
        this.messageDataList = messageData;
      });
    },
  },
};
</script>
