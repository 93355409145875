<template>
  <v-col>
    <v-row justify="space-between">
      <div class="title white--text">
        <span class="caption">{{ title.day }}:</span>
        <span class="mx-2">{{ title.date }}</span>

        <span v-if="title.city" class="caption pa-1 blue-grey darken-2">{{ title.city }}</span>
        <span v-else class="caption">(None)</span>
        <v-btn icon @click="openCityDialog">
          <v-icon small>edit</v-icon>
        </v-btn>
      </div>

      <span v-if="isCancelled" class="white--text">CANCELLED -- Messaging and Buttons disabled</span>
    </v-row>

    <FlightDayBlock :date="dateInfo.date" />

    <DayFieldWrapper
      v-for="(day, index) in dayConfig"
      :key="index"
      :daynum="dayIndex"
      :type="day.type"
      :title="day.title"
      :icon="day.icon"
      :dbkey="day.dbkey"
      :cancelled="isCancelled"
      :date="dateInfo.date"
      @edit="editSupplier"
      @dialog="openDialog"
      @remove="openRemoveDialog"
      @editRoomName="openEditRoomDialog"
      @editConfirm="openConfirmDialog"
    />

    <SelectSupplierDialog
      v-model="dialog"
      mode="BOOKING"
      :startDay="dateInfo.date"
      :module="module"
      @addalt="openAlternate"
    />

    <SelectSupplierDialog
      v-model="editDialog"
      edit
      mode="BOOKING"
      :startDay="dateInfo.date"
      :module="editType"
      :editBookingSupplier="editSupplierInfo"
      @replace="openReplaceDialog"
      @addalt="openAlternate"
    />

    <SelectAltSupplierDialog
      v-model="altdialog"
      :supplierInfo="editSupplierInfo"
      :editType="module"
      :dayIndex="dayIndex"
      :lastDay="lastDay"
      :bookingDayYear="bookingDayYear"
    />

    <ReplaceSupplierDialog
      v-model="replaceDialog"
      mode="BOOKING"
      :module="replaceType"
      :supplierToReplace="supplierToReplace"
    />

    <RemoveSupplierDialog v-model="removeDialog" :bookingId="bookingId" :supplierInfo="removeSupplierInfo" />

    <EditSupplierRoomNameDialog v-model="nameDialog" :clickedSupplier="clickedSupplier" :updateFn="updatePart" />

    <EditConfirmNumberDialog v-model="confirmDialog" :clickedSupplier="clickedSupplier" :updateFn="updatePart" />

    <CityDialog v-model="cityDialog" :editCityDay="editCityDay" />

    <NotReconciledDialog
      v-model="notReconciledDialog"
      :bookingId="notReconciledDialogBookingId"
      :hasReconciledPayment="notReconciledDialoghasReconciledPayment"
      :isLastProduct="notReconciledDialogIsLastProduct"
    />
  </v-col>
</template>

<script>
import format from "@/services/format";
import SUPMENU from "@/config/menus/suppliersMenu";
import { CityDialog } from "@/modules/bookings.overview";
import {
  DayFieldWrapper,
  RemoveSupplierDialog,
  EditSupplierRoomNameDialog,
  EditConfirmNumberDialog,
} from "@/modules/bookings.days";
import {
  SelectSupplierDialog,
  SelectAltSupplierDialog,
  ReplaceSupplierDialog,
  NotReconciledDialog,
} from "@/modules/bookings.select_dialog";
import { FlightDayBlock } from "@/modules/bookings.flights";
export default {
  components: {
    CityDialog,
    DayFieldWrapper,
    SelectSupplierDialog,
    SelectAltSupplierDialog,
    ReplaceSupplierDialog,
    RemoveSupplierDialog,
    EditSupplierRoomNameDialog,
    EditConfirmNumberDialog,
    FlightDayBlock,
    NotReconciledDialog,
  },
  data() {
    return {
      dialog: false,
      editDialog: false,
      altdialog: false,
      notReconciledDialog: false,
      notReconciledDialogBookingId: null,
      notReconciledDialoghasReconciledPayment: null,
      notReconciledDialogIsLastProduct: null,
      module: "",
      dbkey: "",

      shiftDayOn: false,

      editSupplierInfo: {},
      editType: "",

      replaceDialog: false,
      replaceType: "",
      supplierToReplace: null,

      removeDialog: false,
      removeSupplierInfo: {},

      nameDialog: false,
      confirmDialog: false,
      clickedSupplier: null,

      cityDialog: false,
      editCityDay: {},
    };
  },
  computed: {
    bookingId() {
      return this.$store.getters["BookingStore/booking_id"];
    },
    dayIndex() {
      return this.shiftDayOn ? Number(this.$route.params.day) : this.$route.params.day - 1;
    },
    dayCount() {
      return this.$store.getters["BookingDayStore/dayCount"];
    },
    dayConfig() {
      return SUPMENU.FULL.map((item) => {
        return {
          type: item.module,
          title: item.title,
          dbkey: item.alt,
          icon: item.icon,
        };
      }).filter((item) => {
        if (this.dayIndex === this.dayCount - 1) {
          return !["ALL", "HOTEL"].includes(item.type);
        }
        return item.type !== "ALL";
      });
    },
    title() {
      return this.$store.getters["BookingDayStore/day_name"](this.dayIndex) || { day: "Day ?", date: "" };
    },
    dateInfo() {
      return this.$store.getters["BookingDayStore/day"](this.dayIndex);
    },
    lastDay() {
      return this.$store.getters["BookingDayStore/lastDay"].date;
    },
    isCancelled() {
      return this.$store.getters["BookingStore/isCancelled"];
    },

    bookingDayYear() {
      const date = this.dateInfo.date;
      return `${new Date(date).getFullYear()}`;
    },
  },
  methods: {
    openCityDialog() {
      this.cityDialog = true;
      this.editCityDay = { day: this.dayIndex + 1 };
    },

    openDialog(v) {
      if (this.isCancelled) return;
      this.dialog = true;
      this.module = v.mod;
      this.dbkey = v.dbkey;
    },

    openRemoveDialog(data) {
      if (this.isCancelled) return;

      const bookingId = this.bookingId;
      const supplierId = data.supplierData.supplier_id;

      this.$store.dispatch("FinanceReportStore/getOneReport", bookingId).then((report) => {
        const reconciledSupplier = report.supplier_invoices.filter(
          (item) => item.supplier_id == supplierId && item.reconciled == 1
        );

        const bookingSuppliersArray = Object.values(report.booking_suppliers);
        const matchingSuppliers = bookingSuppliersArray.filter((item) => item.supplier_id == supplierId);

        let hasReconciledPayment = reconciledSupplier.length != 0;
        let isLastProduct = matchingSuppliers.length == 1;

        if (hasReconciledPayment && isLastProduct) {
          this.notReconciledDialog = true;
          this.notReconciledDialogBookingId = bookingId;
          this.notReconciledDialoghasReconciledPayment = hasReconciledPayment;
          this.notReconciledDialogIsLastProduct = isLastProduct;
        } else {
          this.removeSupplierInfo = data;
          this.removeDialog = true;
        }
      });
    },

    editSupplier(supplierInfo) {
      if (this.isCancelled) return;

      this.editDialog = true;
      this.editSupplierInfo = supplierInfo.data;
      this.editType = supplierInfo.type;
    },

    updatePart(data) {
      const bookingSuppliers = this.$store.getters["BookingDayStore/suppliers"];
      let updateQuery = [];
      // Only update current one
      if (!data.bound) {
        updateQuery.push(
          this.$store.dispatch("BookingDayStore/updateBookingSupplier", {
            bookingSupplierId: data.bsid,
            bookingId: this.bookingId,
            data: {
              content: {
                meta: Object.assign(bookingSuppliers[data.bsid].meta, data.metaMerge),
              },
            },
          })
        );
      } else {
        // Multiple booking suppliers to update
        Object.values(bookingSuppliers)
          .filter((item) => item.supplier_id === data.supplier_id)
          .forEach((bs) => {
            updateQuery.push(
              this.$store.dispatch("BookingDayStore/updateBookingSupplier", {
                bookingSupplierId: bs.id,
                bookingId: this.bookingId,
                data: {
                  content: {
                    meta: Object.assign(bs.meta, data.metaMerge),
                  },
                },
              })
            );
          });
      }

      return Promise.all(updateQuery)
        .then((s) => {
          this.$roomingPing.$emit("sync");
        })
        .catch((err) => this.$root.$error(err));
    },

    _syncBookingRooms() {
      this.$roomingPing.$emit("sync");
    },

    openAlternate(bsid) {
      const bookingSupplier = this.$store.getters["BookingDayStore/oneSupplier"](bsid);

      this.editSupplierInfo = bookingSupplier;
      this.altdialog = true;
    },
    openReplaceDialog(supplierInfo) {
      this.supplierToReplace = supplierInfo;
      this.replaceType = supplierInfo.type_as;
      this.editDialog = false;
      this.replaceDialog = true;
    },

    openEditRoomDialog(bs) {
      this.clickedSupplier = bs;
      this.nameDialog = true;
    },
    openConfirmDialog(bs) {
      this.clickedSupplier = bs;
      this.confirmDialog = true;
    },
  },
  mounted() {
    // TO FIX -- this will break computed properties
    //this.$store.dispatch('BookingDayStore/focus', this.dayIndex)
  },
  beforeDestroy() {
    if (this.isCancelled) return;
  },
};
</script>

<style lang="scss">
.v-card.supplier-select-wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;

  .dialog-content.v-card__text {
    flex: 1;
    display: flex;
    width: 100%;

    > div {
      display: flex;
      flex-direction: column;
      align-content: space-around;
      width: 100%;
    }

    .top-row {
      max-height: 25px;
    }

    .table {
      flex: 1;
    }

    .button-row {
      max-height: 50px;
    }
  }
}
</style>
