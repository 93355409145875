<template>
  <v-row justify="space-between">
    <ApplyDay :startDay="startDay" :value="endDay" :type="module" @input="updateRange" />

    <a-group icon="event" label="Custom Dates">
      <div class="d-flex align-center">
        <v-btn small text color="primary" @click="customDayDialog = true" class="mx-3"> Select </v-btn>
      </div>
    </a-group>

    <SelectCustomDaysDialog v-model="customDayDialog" :type="module" pretitle="Select" @update="updateCustom" />
  </v-row>
</template>

<script>
import ApplyDay from "../options/_ApplyDay";
import SelectCustomDaysDialog from "../dialogs/_SelectCustomDaysDialog";
export default {
  props: {
    value: Array,

    module: String,
    startDay: [String, Date],
  },
  components: {
    ApplyDay,
    SelectCustomDaysDialog,
  },
  data() {
    return {
      customDayDialog: false,
      usingConfigType: null,
      endDay: null,
    };
  },
  methods: {
    updateRange(v) {
      this.usingConfigType = "RANGE";
      this.endDay = v;

      // Convert to individual days
      const dayList = this.$store.getters["BookingDayStore/dayLinkList"]();
      let dump = [];
      dayList.forEach((d) => {
        if (d.rawDate === this.startDay && !this.endDay) {
          dump.push(d.rawDate);
          return;
        }

        if (this.startDay <= d.rawDate && d.rawDate <= this.endDay) {
          dump.push(d.rawDate);
          return;
        }
      });

      this.$emit("input", dump);
    },
    updateCustom(v) {
      this.usingConfigType = "CUSTOM";
      this.$emit("input", v);
    },
  },
};
</script>
