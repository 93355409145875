import axios from "@/plugins/axios";

export default {
  // Quote
  getQuoteList(query) {
    return axios.get(`/quotes`, { params: query });
  },
  getOneQuote(quote_id) {
    return axios.get(`/quotes/${quote_id}`);
  },
  createQuote(data) {
    return axios.post("/quotes", data);
  },
  convertBookingToQuote(booking_id) {
    return axios.post(`/bookings/${booking_id}/quote`);
  },
  convertQuoteToBooking(quote_id) {
    return axios.post(`/quotes/${quote_id}/booking`);
  },
  updateQuote(quote_id, data) {
    return axios.put(`/quotes/${quote_id}`, data);
  },
  deleteQuote(quote_id) {
    return axios.delete(`/quotes/${quote_id}`);
  },
  // Documents
  getDocumentList(quote_id) {
    return axios.get(`/quotes/${quote_id}/documents`);
  },
  addDocument(quote_id, { name, file }) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("file", file);
    return axios.post(`/quotes/${quote_id}/documents`, formData, { "Content-Type": "multipart/form-data" });
  },
  updateDocument(id, { display_name, old_name, dependent_id }) {
    return axios.post(`/quotes_documents/${id}/`, {
      display_name,
      old_name,
      quote_id: dependent_id,
    });
  },
  deleteDocumentList(idList) {
    return axios.delete("/quotes_documents", { data: { document_list: idList } });
  },
};
