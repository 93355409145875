<template>
  <div>
    <TechnicalHeader
      :editMode="editMode"
      :header="savedTechnical.header"
      :transport_notes="savedTechnical.transport_notes"
      :tour_guide_notes="savedTechnical.tour_guide_notes"
      :hideLogo="savedTechnical.hide_logo"
      :hideAttention="savedTechnical.hide_attention"
      :hideEstimate="savedTechnical.hide_estimate"
      :hideBuses="savedTechnical.hide_buses"
      :hideTourGuide="savedTechnical.hide_tour_guides"
      :contactMap="contactMap"
      @update="(v) => $emit('update', v)"
      class="mb-5"
    />

    <TechnicalDay
      v-for="(date, index) in days"
      :key="date.date"
      :date="date.date"
      :city="date.city"
      :dayIndex="index"
      :editMode="editMode"
      :contactMap="contactMap"
      :compiledDayDefault="compiledDays[date.date]"
      :noBus="hasNoBusService(index)"
      :busService="hasTransferBusService(index)"
      :noGuide="hasNoTourGuide(index)"
      :value="savedTechnical.days[date.date]"
      @input="(v) => $emit('update', { label: 'days', nest: date.date, value: v })"
      class="my-5 py-2"
      @action="handleAdjust"
    />
    <TechnicalFooter
      :editMode="editMode"
      :footer="savedTechnical.footer"
      @updateFooter="(v) => $emit('update', { label: 'footer', value: v })"
    />
  </div>
</template>

<script>
import TechnicalHeader from "./TechnicalHeader";
import TechnicalDay from "./TechnicalDay";
import TechnicalFooter from "./TechnicalFooter";
export default {
  props: {
    hideLogo: Boolean,
    editMode: Boolean,
    toggleUndo: Boolean,

    contactMap: Object,
    days: Array,
    savedTechnical: Object,
    compiledDays: Object,
  },
  components: {
    TechnicalHeader,
    TechnicalDay,
    TechnicalFooter,
  },
  watch: {
    toggleUndo(val) {
      this.undoStep();
    },
  },
  data() {
    return {
      undoHistory: [],
    };
  },
  // computed: {
  // 	days(){
  // 		return this.$store.getters['BookingDayStore/days']
  // 				.map(x => {
  // 					return {
  // 						date: x.date,
  // 						city: x.city
  // 					}
  // 				})
  // 				.sort((a,b) => {
  // 					if(a.date > b.date) return 1;
  // 					if(a.date < b.date) return -1;
  // 					return 0;
  // 				});
  // 	}
  // },
  methods: {
    hasNoBusService(dayIndex) {
      // Ignore first and last days
      // if(dayIndex === 0) return false;
      // if(dayIndex === this.days.length-1) return false;

      return !this.$store.getters["BookingDayStore/daySupplier"](dayIndex, "transport").filter((item) => {
        if (!item.supplier_meta) return false;
        // Default assumes bus
        if (!item.supplier_meta.trans_type) return true;
        // Only true if bus
        return item.supplier_meta.trans_type === "BUS";
      }).length;
    },
    hasNoTourGuide(dayIndex) {
      return !this.$store.getters["BookingDayStore/daySupplier"](dayIndex, "tour_guides").length;
    },
    hasTransferBusService(dayIndex) {
      // Get transport for date, check if airport_transfer = true
      return !!this.$store.getters["BookingDayStore/daySupplier"](dayIndex, "transport")
        //.filter(item => item.status >= CONFIRM)
        .find((item) => {
          if (!item.meta) return false;
          return item.meta.airport_transfer;
        });
    },

    // CONTROLS
    handleAdjust({ command, date, index, item }, skipUndo) {
      let vm = this;
      let fn = {
        UP: function () {
          vm.moveUp(date, index, skipUndo);
        },
        DOWN: function () {
          vm.moveDown(date, index, skipUndo);
        },
        ADD: function () {
          vm.insert(date, index, item, skipUndo);
        },
        DELETE: function () {
          vm.remove(date, index, skipUndo);
        },
      };

      fn[command]();
    },
    moveUp(date, index, skipUndo) {
      const day = this.savedTechnical.days[date];
      day.splice(index - 1, 0, day.splice(index, 1)[0]);

      if (skipUndo) return;
      this.undoHistory.push({
        command: "DOWN",
        date,
        index: index - 1,
      });
    },
    moveDown(date, index, skipUndo) {
      const day = this.savedTechnical.days[date];
      day.splice(index + 1, 0, day.splice(index, 1)[0]);

      if (skipUndo) return;
      this.undoHistory.push({
        command: "UP",
        date,
        index: index + 1,
      });
    },
    insert(date, index, item, skipUndo) {
      const day = this.savedTechnical.days[date];
      day.splice(index + 1, 0, item);

      if (skipUndo) return;
      this.undoHistory.push({
        command: "DELETE",
        date,
        index: index + 1,
      });
    },
    remove(date, index, skipUndo) {
      const day = this.savedTechnical.days[date];
      const item = day[index];
      day.splice(index, 1);

      if (skipUndo) return;
      this.undoHistory.push({
        command: "ADD",
        date,
        item,
        index: index - 1,
      });
    },

    // UNDO FUNCTION
    undoStep() {
      const action = this.undoHistory.pop();
      this.handleAdjust(action, true);
    },
  },
};
</script>
