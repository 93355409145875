<template>
  <div>
    <a-hover-menu>
      <template v-slot:activator>
        <div class="text-truncate">{{ text }}</div>
      </template>

      <div class="caption">
        <div v-for="(product, pindex) in displayList" :key="'prod' + pindex">
          <div v-if="multi" :class="getProductNameClass()">
            {{ product.productName }}
          </div>
          <v-row v-for="(item, index) in product.data" :key="index" class="pa-0">
            <v-col :class="getLabelClass()" style="width: 150px">{{ item.label }}</v-col>
            <v-col :class="getValueClassByKey(item, product.data)">{{ item.displayValue }}</v-col>
          </v-row>
        </div>
      </div>
    </a-hover-menu>
  </div>
</template>

<script>
import moneyService from "@/services/money";
import EXTRA_OPTIONS from "@/config/products/EXTRA_OPTIONS";
export default {
  props: {
    text: String,
    original: [String, Array],
    multi: Boolean,
    showConvert: Boolean,
    convert: String,
    tax: [String, Number],
    taxInclusive: Number,
    supplierMeta: Object,
    meta: Object,
    bookingSupplierMeta: Object,
    quotePrice: String,
  },
  computed: {
    displayList() {
      if (this.multi) {
        return this.original.map((product) => {
          return {
            productName: product.hoverName,
            data: _compileProduct({
              original: product.original,
              text: product.rate,
              tax: product.tax,
              taxInclusive: product.tax_inclusive,
              meta: product.meta,
              supplierMeta: this.supplierMeta,
              convert: product.convert,
              showConvert: this.showConvert,
              extraList: _getExtraList(product.meta),
              quotePrice: product.quotePrice,
            }),
          };
        });
      }

      const data = _compileProduct({
        original: this.original,
        text: this.text,
        tax: this.tax,
        taxInclusive: this.taxInclusive,
        meta: this.meta,
        supplierMeta: this.supplierMeta,
        convert: this.convert,
        showConvert: this.showConvert,
        extraList: _getExtraList(this.meta),
        quotePrice: this.quotePrice,
      });

      return [{ data }];
    },
  },
  methods: {
    getProductNameClass() {
      return "text-decoration-underline";
    },
    getLabelClass() {
      return "d-flex flex-nowrap pa-0";
    },
    getValueClassByKey(item, data) {
      const { key } = item;

      if (key !== "total") return "d-flex flex-nowrap pa-0";

      const quote = data.find((value) => value.key === "quote");
      const total = data.find((value) => value.key === "total");

      if (!quote) return "d-flex flex-nowrap pa-0";

      const workingEnv = this.$store.getters["AccountStore/environment"];
      const formatQuote = moneyService.stripFormatting(quote.displayValue, workingEnv);
      const formatTotal = moneyService.stripFormatting(total.displayValue, workingEnv);

      const isTotalHigher = formatTotal > formatQuote;
      const textColorClass = isTotalHigher ? "lightred-text" : "lightgreen-text";

      return `d-flex flex-nowrap pa-0 font-weight-bold ${textColorClass}`;
    },
  },
};

function _compileProduct({
  original,
  text,
  tax,
  taxInclusive,
  meta,
  supplierMeta,
  convert,
  showConvert,
  extraList,
  quotePrice,
}) {
  let quotePriceRow =
    quotePrice != null
      ? [
          {
            key: "quote",
            label: "Quote price",
            displayValue: quotePrice,
          },
        ]
      : [];

  const price = {
    key: "price",
    label: `Price (${taxInclusive ? "Tax Inclusive" : "Before Tax"})`,
    displayValue: original,
  };
  const total = {
    key: "total",
    label: "Total",
    displayValue: showConvert ? convert : text,
  };
  let taxRow = !taxInclusive
    ? [
        {
          key: "tax",
          label: "Tax",
          displayValue: `${tax} %`,
        },
      ]
    : [];

  const o = (meta && meta.custom_occupancy) || (supplierMeta && supplierMeta.occupancy_rate);
  let occupancy =
    o && o.type === "COST" && o.value !== ""
      ? [
          {
            key: "occupancy",
            label: "Occupancy",
            displayValue: `${o.cur} ${o.value}`,
          },
        ]
      : [];

  const rf = supplierMeta && supplierMeta.resort_fee;
  let resort_fee =
    rf && rf.type === "COST"
      ? [
          {
            key: "resort",
            label: "Resort Fee",
            displayValue: `${rf.cur} ${rf.value}`,
          },
        ]
      : [];

  return [...quotePriceRow, price, ...taxRow, ...occupancy, ...resort_fee, ...extraList, total];
}

function _getExtraList(meta) {
  if (!meta) return [];
  return Object.keys(meta)
    .filter((key) => key.includes("extra_"))
    .map((key) => {
      return {
        label: EXTRA_OPTIONS[key].label,
        displayValue: _getDisplayValue(meta[key]),
      };
    });
}

function _getDisplayValue(item) {
  if (item.astype == "PERCENT") return `${item.value} %`;
  return `${item.value.cur} ${item.value.value}`;
}
</script>

<style>
.red-text {
  color: red;
}
.green-text {
  color: green;
}
.lightred-text {
  color: rgb(255, 102, 102);
}
.lightgreen-text {
  color: lightgreen;
}
</style>
