import axios from "@/plugins/axios";

export default {
  // QUERY
  querySuppliers(type, searchQuery) {
    return axios.get(`/suppliers/types/${type}/search`, {
      params: { ...searchQuery },
    });
  },
  queryProducts(type, searchQuery) {
    return axios.get(`/products/types/${type}/search`, {
      params: { ...searchQuery },
    });
  },

  // COUNT
  countSupplier(type, searchQuery) {
    return axios.get(`/suppliers/types/${type}/count`, {
      params: { ...searchQuery },
    });
  },
  countProducts(type, searchQuery) {
    return axios.get(`/products/types/${type}/count`, {
      params: { ...searchQuery },
    });
  },

  // NAME LIST
  getSupplierNamesOnly(type, searchQuery) {
    return axios.get(`/suppliers/types/${type}/names`, {
      params: { ...searchQuery },
    });
  },
  getClientNamesOnly(searchQuery) {
    return axios.get("/clients/types/names", {
      params: { ...searchQuery },
    });
  },

  // CREATE STUFF
  createSupplier(type, supplier, primary_contact) {
    return axios.post(`/suppliers/types/${type}`, { supplier, primary_contact });
  },
  createProduct(supplier_id, supplier_pid, product) {
    return axios.post(`/suppliers/${supplier_id}/products`, { supplier_pid, product });
  },

  // SEARCH DATA
  getSupplierItemList(env, column) {
    return axios.get(`/supplier_data/${env}/${column}`);
  },
  // DEPRICATED -- REMOVE FROM BACKEND?
  // getRestaurantStyle(){
  // 	return axios.get('/supplier_data/restaurant_styles')
  // },
  // getOperatingAreas(){
  // 	return axios.get('/supplier_data/op_areas')
  // }
};
