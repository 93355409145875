<template>
<v-row class="invoice-row">
  <v-col align-self="center" class="grey lighten-3 px-2" style="min-width:80px;max-width: 80px;height: 30px">  
    <div class="caption font-weight-bold" style="line-height: 16px">Est.</div>
  </v-col>
  <v-col align-self="center" class="grey lighten-3" style="min-width:120px;max-width: 120px;">
    <div>
      <v-icon small left>event</v-icon>
      {{displayDate}}
    </div>
  </v-col>
  <v-col align-self="center" class="grey lighten-3 d-flex justify-end align-center" style="min-width:120px;max-width: 120px;height: 30px;">
    <div style="line-height: 16px">{{ displayInvoice }}</div>
  </v-col>
  <v-col align-self="center" class="d-flex" style="max-width: 100px">
    <v-btn icon x-small @click="invoiceDialog = true" class="report-btn">
      <v-icon small>edit</v-icon>
    </v-btn>
  </v-col>

  <SetEstInvoiceDialog 
    v-model="invoiceDialog"
    :estInvoice="estInvoice.estimated_value"
    @update="saveEstimate"
    @remove="removeEstimate" />
</v-row>
</template>

<script>
  import money from '@/services/money'
  import format from '@/services/format'
  import SetEstInvoiceDialog from './dialogs/_SetEstInvoiceDialog'
export default {
  props: {
    bookingId: String
  },
  components: {
    SetEstInvoiceDialog
  },
  data() {
    return {
      invoiceDialog: false,
      estimatedInvoice: null,
    };
  },
  computed: {
    estInvoice() {
      return this.$store.getters["FinanceReportStore/est_invoice"];
    },
    displayDate(){
      const v = this.estInvoice.time_stamp;
      return v? format.formatDate(parseInt(v)) : ''
    },
    displayInvoice(){
      const v = this.estInvoice.estimate_amount
      return v? `$ ${money.format(v, { precision: 2 })}` : '$ --'
    }
  },
  methods: {
    saveEstimate(v) {
      this.$store.dispatch("FinanceReportStore/setEstimatedInvoice", {
        booking_id: this.bookingId,
        estimate_amount: v
      });
    },
    removeEstimate(v){
      if(!this.estInvoice) return;
      this.$store.dispatch("FinanceReportStore/removeEstInvoice", this.estInvoice.id);
    }
  },
}
</script>


<style scoped>
.invoice-row-label {
  max-width: 120;
  height: 40;
}
</style>
