<template>
  <a-card>
    <a-field :config="config.name" v-uppercase="!showTranslation" v-model="name" />
  </a-card>
</template>

<script>
import config from "../config/clientConfig";

export default {
  data() {
    return {
      config: config,
    };
  },
  computed: {
    name: {
      get() {
        return this.get("name");
      },
      set(newValue) {
        return this.set("name", newValue);
      },
    },
    showTranslation() {
      return this.$store.getters["AccountStore/environment"] !== "USA";
    },
  },
  methods: {
    get(val) {
      return this.$store.getters["ClientStore/active"][val];
    },
    set(key, val) {
      return this.$store.dispatch("ClientStore/setUpdate", { key, val });
    },
  },
};
</script>
