<template>
  <div class="d-flex align-start caption justify-start mx-5 px-3" style="width: 100%">
    <div class="d-flex align-start px-2">
      <div class="ml-2">
        <div v-for="product in displayProductNames" :key="product.bsid">{{ product.names }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import format from "@/services/format";
export default {
  props: {
    statusDisplay: Array,
  },
  computed: {
    displayProductNames() {
      return this.statusDisplay.map((item) => {
        return {
          bsid: item.bsid,
          names: item.product_names,
        };
      });
    },
  },
};
</script>
