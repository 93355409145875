<template>
  <v-col class="pa-0" style="border-bottom: 1px solid rgb(200, 200, 200)">
    <v-row class="pa-0 black--text" v-for="p in existingBs" :key="p.id">
      <v-btn
        text
        small
        @click="
          $emit('replace', {
            date: focusDate,
            replaceBsSupplierId: p.id,
            replaceProduct: p.products,
            replaceBsMeta: p.meta,
          })
        "
      >
        <v-icon x-small>swap</v-icon>
        <span>Replace</span>
      </v-btn>
      <!-- NEW -->
      <v-col class="pa-0" align-self="center">
        {{ getProductNames(p.products, p) }}
      </v-col>

      <!-- OLD -->
      <v-col v-for="sub in bssuppliers" :key="'OLD' + sub.id" class="py-0" align-self="center">
        {{ getProductNames(sub.products, sub) }}
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import format from "@/services/format";
export default {
  props: {
    mode: String,
    module: String,
    disabled: Boolean,

    focusDate: String,
    supplierId: String,
    originSupplierId: String,
  },
  data() {
    return {
      existingBs: [],
    };
  },
  watch: {
    originSupplierId(supid) {
      this.existingBs = this.$store.getters["BookingDayStore/allSuppliersOnDay"](
        this.focusDate,
        this.module,
        this.originSupplierId
      );
    },
  },
  computed: {
    bssuppliers() {
      return this.$store.getters["BookingDayStore/allSuppliersOnDay"](this.focusDate, this.module, this.supplierId);
    },
    showTime() {
      // Time option for everything except hotel
      return !["HOTEL"].includes(this.module) && this.time;
    },
    formatTime() {
      const time = this.time;
      if (!time) return "";
      return format.formatTime(time);
    },
  },
  methods: {
    getProductNames(products, bs) {
      return Object.values(products)
        .map((v) => {
          let name = bs.meta && bs.meta.custom_names && bs.meta.custom_names[v.id];
          return name || v.name;
        })
        .join(", ");
    },
    clearProduct(bsid) {
      this.$emit("clear", bsid);
    },
  },
  mounted() {
    this.existingBs = this.$store.getters["BookingDayStore/allSuppliersOnDay"](
      this.focusDate,
      this.module,
      this.originSupplierId
    );
  },
};
</script>
