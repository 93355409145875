<template>
  <div cols="1" align-self="center" style="text-align: end; display: flex">
    <v-dialog v-model="dialog" persistent max-width="350">
      <template v-slot:activator="{ on }">
        <a-hover-menu>
          <template v-slot:activator>
            <v-row class="pa-0" align="center">
              <v-icon v-if="outside" small color="error">report_problem</v-icon>
              <v-btn v-else-if="show" v-on="on" small icon text>
                <v-icon small color="error">report_problem</v-icon>
              </v-btn>
              <span v-else style="width: 24px; text-align: center">x</span>

              <slot></slot>
            </v-row>
          </template>

          <div class="caption">
            <div>
              <span class="caption font-weight-bold">Breakdown</span>
            </div>
            <v-row v-for="(item, index) in displayList" :key="index">
              <span class="caption">{{ item.displayValue }} - {{ item.label }}</span>
            </v-row>
            <v-row v-if="show">
              <v-icon small color="error" left>report_problem</v-icon>
              Count changed. Suppliers must be updated.
            </v-row>
            <v-row class="ma-0" v-if="show">
              <v-col>Before:</v-col>
              <v-col>{{ beforeTotal }}</v-col>
              <v-col>Current:</v-col>
              <v-col>{{ nowTotal }}</v-col>
            </v-row>
          </div>
        </a-hover-menu>
      </template>

      <v-card>
        <v-card-text class="pt-3" style="color: black">
          <div class="my-2">Do you want to sync product count to current PAX (and suppliers)?</div>
          <div>Suppliers will not be notified.</div>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn text depressed small @click="dialog = false">Cancel</v-btn>

          <v-btn small outlined color="green" @click="update">
            <v-icon small left>sync</v-icon>
            <span>Yes, Sync</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div>
      <a-hover-menu v-if="locked" class="locked-icon">
        <template v-slot:activator>
          <v-icon small right color="grey">lock</v-icon>
        </template>
        <span class="caption">Locked</span>
      </a-hover-menu>

      <a-hover-menu v-if="outside || show">
        <template v-slot:activator>
          <v-btn small icon text @click="confirm">
            <v-icon small color="green">check</v-icon>
          </v-btn>
        </template>

        <span class="caption">Confirm correct</span>
      </a-hover-menu>
    </div>
  </div>
</template>

<script>
import { BookingRoomService } from "@/modules/bookings.rooms";
export default {
  props: {
    typeAs: String,
    status: Number,
    date: String,
    bookingSupplierMeta: Object,
    outside: Boolean,

    productId: String,
    pax: Number,
    productCount: [Number, String],
    supcount: [Array, Object], // List of supplier meals for restaraunt
  },
  data() {
    return {
      dialog: false,
    };
  },
  watch: {
    show(v) {
      if (v) this.$syncCounts.$emit("show", v);
    },
  },
  computed: {
    showBreakdown() {
      return ["RESTAURANT"].includes(this.typeAs);
    },
    locked() {
      if (this.bookingSupplierMeta.confirmed_offcount) return true;
      if (this.bookingSupplierMeta.count_lock && this.bookingSupplierMeta.count_lock[this.productId]) return true;
      return false;
    },
    show() {
      if (this.locked) return false;

      if (this.outside) return true;
      if (!["RESTAURANT", "EXCURSION"].includes(this.typeAs)) return false;

      const saved =
        Number(this.savedCount.count) +
        Number(this.savedCount.tour_guides || 0) +
        Number(this.savedCount.transport || 0);
      const current =
        Number(this.pax || this.savedCount.count) +
        Number(this.supplierCount.tour_guides || 0) +
        Number(this.supplierCount.transport || 0);

      return saved !== current;
    },
    supplierCount() {
      if (!this.supcount && !["RESTAURANT"].includes(this.typeAs)) return { tour_guides: 0, transport: 0 };

      return {
        tour_guides: this.supcount.tour_guides,
        transport: this.supcount.transport,
      };
    },

    savedCount() {
      if (this.status >= 4) {
        // Saved to DB when confirmed
        const confirmedCount = (this.bookingSupplierMeta && this.bookingSupplierMeta.confirmed_count_total) || {
          [this.productId]: {},
        };
        const prod = confirmedCount[this.productId];

        return {
          count: Number(prod.count || 1),
          tour_guides: Number(prod.tour_guides ? prod.tour_guides[this.date] || 0 : 0),
          transport: Number(prod.transport ? prod.transport[this.date] || 0 : 0),
        };
      }

      const count = (this.bookingSupplierMeta && this.bookingSupplierMeta.count) || {};

      // PAX + Suppliers
      return {
        count: Number(count[this.productId] || 1),
        ...this.supplierCount,
      };
    },
    beforeTotal() {
      const count = this.savedCount.count || 1;
      const extra = (this.savedCount.tour_guides || 0) + (this.savedCount.transport || 0);
      return extra ? `${count}+${extra}` : count;
    },
    nowTotal() {
      const count = this.pax || this.savedCount.count; // If no PAX, used what product count is set to
      const extra = (this.supplierCount.tour_guides || 0) + (this.supplierCount.transport || 0);
      return extra ? `${count}+${extra}` : count;
    },

    displayList() {
      const PAX = {
        label: this.typeAs === "TRANSPORT" ? "Bus" : "PAX",
        displayValue: this.productCount,
      };

      let list = [PAX];

      if (this.typeAs !== "RESTAURANT") return list;

      const tours = {
        label: "Tour Guides",
        displayValue: (this.supcount && this.supcount.tour_guides) || 0,
      };
      const bus = {
        label: "Bus Drivers",
        displayValue: (this.supcount && this.supcount.transport) || 0,
      };

      if (tours.displayValue > 0) list.push(tours);
      if (bus.displayValue > 0) list.push(bus);

      return list;
    },
  },
  methods: {
    update() {
      this.$emit("synccount", { product_id: this.productId, date: this.date, confirmCount: this.savedCount });
      this.dialog = false;
    },
    confirm(e) {
      e.stopPropagation();

      this.$emit("confirm");
      this.dialog = false;
    },
    replyPing() {
      if (this.show) this.$syncCounts.$emit("show");
    },
  },
  mounted() {
    this.$syncCounts.$on("ping", this.replyPing);
    if (this.show) this.replyPing();
  },
  beforeDestroy() {
    this.$syncCounts.$off("ping", this.replyPing);
  },
};
</script>
