<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn depressed small v-bind="attrs" v-on="on">
        <v-icon left>add</v-icon>
        <span>Add</span>
      </v-btn>
    </template>

    <v-card>
      <v-card-title> Add Item </v-card-title>
      <v-card-text>
        <a-text-field label="Label" v-model="pendingAddItem" />
      </v-card-text>

      <v-divider></v-divider>

      <v-alert dense text type="error" v-if="showPendingError">This exists. Cannot add.</v-alert>

      <v-card-actions>
        <g-button label="Close" icon="clear" @onclick="close" />
        <v-spacer></v-spacer>
        <add-button :disabled="showPendingError" @click="add" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    existingKeys: Object,
  },
  data() {
    return {
      dialog: false,
      pendingAddItem: null,
    };
  },
  computed: {
    showPendingError() {
      return !!this.existingKeys[this.pendingAddItem];
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.pendingAddItem = null;
    },
    add() {
      this.$emit("add", this.pendingAddItem);
      this.close();
    },
  },
};
</script>
