<template>
  <div class="mx-2">
    <v-alert dense text :color="color" class="pa-0 my-1" v-if="isset">
      <div class="white--text d-flex align-center">
        <!-- <span class="mx-4 caption">Archived.</span> -->
        <g-button
          v-if="!locked"
          :label="reverseText"
          :type="color"
          class="mx-3"
          :icon="reverseIcon"
          @onclick="$emit('toggle', 0)"
        />
      </div>
    </v-alert>

    <div v-else>
      <v-hover v-slot:default="{ hover }">
        <v-btn v-if="!hover" color="grey" icon small>
          <v-icon small>{{ issetIcon }}</v-icon>
        </v-btn>

        <v-btn v-else :color="color + ' lighten-1'" @click="$emit('toggle', 1)" small>
          <v-icon left small>{{ issetIcon }}</v-icon>
          <span>{{ issetText }}</span>
        </v-btn>
      </v-hover>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isset: Boolean,
    locked: Boolean,
    color: String,
    issetText: String,
    issetIcon: String,
    reverseText: String,
    reverseIcon: String,
  },
};
</script>
