<template>
  <div id="envset">
    <div v-if="envList.length > 1">
      <v-select
        dense
        hide-details
        item-text="label"
        item-value="value"
        :items="envList"
        :value="environment"
        return-object
        @input="(v) => updateEnvironment(v)"
      />
    </div>

    <div v-else class="subtitle-1">
      {{ label }}
    </div>
  </div>
</template>

<script>
import ENV from "@/config/ENV";
export default {
  props: {
    switchEnv: Function,
  },
  data() {
    return {
      allEnvItems: ENV,
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters["isAdmin"];
    },
    envList() {
      const blob = this.$store.getters["AccountStore/custom_access"];
      return this.isAdmin ? ENV : ENV.filter((item) => blob[item.value]);
    },
    environment() {
      return this.$store.getters["AccountStore/environment"];
    },
    label() {
      return ENV.find((x) => this.environment === x.value).label;
    },
  },
  methods: {
    updateEnvironment(mod) {
      this.$store.dispatch("loading", true);
      this.$store
        .dispatch("AccountStore/switchEnvironment", mod.value)
        .then(() => this.$store.dispatch("autoLogin"))
        .then((account) => {
          this.switchEnv(account);
          this.$router.push({ name: "home" });
          this.$root.$success("Switched Environment to: " + mod.label);
          this.$store.dispatch("loading", false);

          // Clear Search saved
          this.$store.dispatch("DatabaseStore/resetSearch");
        })
        .catch((err) => {
          this.$store.dispatch("loading", false);
          this.$root.$error(err);
        });
    },
  },
};
</script>

<style lang="scss">
#envset {
  width: 100px;
  margin-left: 10px;
}
</style>
