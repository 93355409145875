import REGIONS from "./REGIONS";

import countries from "country-list-js";
import jpPrefecture from "jp-prefecture";
import countriesList from "countries-list";
import usastates from "usa-states";

const UsaStates = usastates.UsaStates;
const world = countriesList.countries;
let StateList = new UsaStates({ includeTerritories: true });
// let GroupedJapanRegions = jpPrefecture.getAllRegionInPref();

const USA = "USA";
const JAPAN = "JAPAN";

export default {
  COUNTRY_LIST: {
    // Using environment
    DEFAULT: {
      USA: [obj(USA, "US")],
      JPN: [obj(JAPAN, "JP")],
      CDA: [obj("CANADA", "CA")],
    },
    TOUR: {
      USA: [obj(USA, "US"), obj("CANADA", "CA")],
      CDA: [obj(USA, "US"), obj("CANADA", "CA")],
      JPN: [obj(JAPAN, "JP")],
    },
    WORLD: Object.keys(world).map((key) => {
      return { label: world[key].name, value: key };
    }),
  },
  SUBJUR: {
    US: (function () {
      let states = [];
      StateList.states.forEach((item) => {
        states.push({
          label: `${item.name} (${item.abbreviation})`,
          value: item.abbreviation,
        });
      });
      return states;
    })(),
    // JP: (function(){
    // 	let list = [];
    // 	GroupedJapanRegions.forEach(region => {
    // 		list.push({
    // 			label: makeJapanLabel(region.region, true),
    // 			value: region.region.en
    // 		})

    // 		region.prefectures.forEach(pref => {
    // 			list.push({
    // 				label: makeJapanLabel(pref, false),
    // 				value: `${region.region.en}.${pref.en}`
    // 			});
    // 		});
    // 	});
    // 	return list;
    // })(),
    ANY: function (countryISO) {
      var found = countries.findByIso2(countryISO);
      if (!found || !found.provinces) return null;

      // Extract provinces
      let list = [];
      found.provinces.forEach((item) => {
        list.push({
          label: item.name,
          value: item.short || item.name,
        });
      });
      return list;
    },
  },
  REGIONS: {
    US: REGIONS,
    CA: REGIONS,
    // JP: (function(){
    // 	let list = [{ label: 'National', value: 'NAT' }];
    // 	let map = {};
    // 	GroupedJapanRegions.forEach(region => {
    // 		list.push({
    // 			label: makeJapanLabel(region.region, true),
    // 			value: region.region.en
    // 		});
    // 		map[`${region.region.en}`] = region.region.en;

    // 		region.prefectures.forEach(pref => {
    // 			map[`${region.region.en}.${pref.en}`] = region.region.en;
    // 		});
    // 	});

    // 	return {
    // 		REGIONS: list,
    // 		STATE_TO_REGION_MAP: map
    // 	};
    // })()
  },
};

function obj(name, value) {
  return {
    label: name,
    value: value || name,
  };
}

function makeJapanLabel(data, isRegion) {
  const kanji = data.name;
  const english = isRegion ? data.en.toUpperCase() : data.en.charAt(0).toUpperCase() + data.en.slice(1);
  return `${!isRegion ? "--- " : ""}${kanji} / ${english}`;
}
