<template>
  <div :class="item.color" class="d-flex flex-nowrap align-center">
    <v-icon :class="item.color">brightness_1</v-icon>
    <span class="pl-2 text-truncate">{{ item.label }}</span>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
  },
};
</script>
