<template>
  <v-alert type="info" outlined dense v-show="showBlock" class="mx-5">
    <v-row class="pa-0">
      <v-col class="pa-0">
        <elwrapper label="Time" v-if="showTimeInput">
          <f-time style="max-width: 130px" :value="time" @input="(v) => $emit('set-time', v)" />
        </elwrapper>
      </v-col>
      <v-col align-self="center" class="pa-0">
        <pcheck v-if="showAirportTransfer" label="Airport Transfer Only" v-model="airportTransferOnly" />
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
import Vue from "vue";
export default {
  props: {
    disabled: Boolean,
    type: String,
    metaconfig: Object,
    time: String,
  },
  computed: {
    airportTransferOnly: {
      get() {
        return this.metaconfig && this.metaconfig.airport_transfer;
      },
      set(v) {
        Vue.set(this.metaconfig, "airport_transfer", v);
      },
    },
    showBlock() {
      return this.showTimeInput || this.showAirportTransfer;
    },
    showTimeInput() {
      const notAllowed = ["HOTEL", "TOUR_GUIDE"];
      return !notAllowed.includes(this.type);
    },
    showAirportTransfer() {
      return this.type === "TRANSPORT";
    },
  },
};
</script>
