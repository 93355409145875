<template>
  <div class="d-flex align-center">
    <span class="white--text subtitle-2 mx-2">Showing Year: </span>

    <v-menu bottom left open-on-hover>
      <template v-slot:activator="{ on }">
        <div v-on="on">
          <g-button :label="value"></g-button>
        </div>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, index) in displayYearOptions"
          :key="index"
          @click="$emit('input', item)"
          class="year-option"
          :class="{ 'primary--text blue lighten-4 font-weight-bold': isCurrentYear(item, value) }"
        >
          <v-list-item-title>
            <span class="caption">{{ item }}</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
let START_YEAR = 2020;
let ADVANCE = 2;
let LENGTH = new Date().getFullYear() - START_YEAR + ADVANCE;

export default {
  props: {
    value: String,
    yearOptions: Array,
    strict: Boolean,
  },
  computed: {
    displayYearOptions() {
      const currentYear = new Date().getFullYear();
      if (this.strict) return [...Array(LENGTH).keys()].map((i) => `${2020 + i}`).reverse();
      return this.yearOptions;
    },
  },
  methods: {
    isCurrentYear(a, b) {
      return `${a}` === `${b}`;
    },
  },
};
</script>

<style lang="scss">
.year-option {
  padding: 5px 15px;
  max-height: 30px;
  height: 30px;
  min-height: 30px;
}
</style>
