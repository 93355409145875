import Technical from "./Technical";

export default [
  {
    path: "technical",
    name: "booking_technical",
    component: Technical,
    meta: {
      toolbar: "booking",
      tabAccess: "BOOKING",
    },
  },
];
