<template>
  <nav-button icon="file_copy" label="Copy" @click="copySupplier" />
</template>

<script>
export default {
  props: {
    supplierId: String,
    name: String,
  },
  methods: {
    copySupplier() {
      const copyName = this.name;

      this.$root.$loading.open("Copying " + this.name);
      this.$store
        .dispatch("SupplierStore/copySupplier", this.supplierId)
        .then((new_id) => {
          // Updating supplier route
          this.$router.push({
            name: "suppliers_info",
            params: {
              id: new_id,
            },
          });
          return this.$store.dispatch("SupplierStore/getSupplier", new_id);
        })
        .then(() => {
          this.$root.$loading.end();
          this.$root.$success(`Copied: ${copyName} to: ${copyName} (Copy)`);
        })
        .catch(this.$root.$error);
    },
  },
};
</script>
