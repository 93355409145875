import Vue from "vue";

const EventBus = new Vue();

// Create event bus
Object.defineProperties(Vue.prototype, {
  $roomingPing: {
    get: function () {
      return EventBus;
    },
  },
});
