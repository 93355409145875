<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)" width="300px" persistent>
    <v-card>
      <v-card-text class="mt-5 pt-5 black--text">
        <div class="subtitle-2 py-2">Add Next Year</div>
        <v-divider />
        <v-row justify="space-between">
          <div class="pa-1 d-flex align-center">
            <span>{{ nextYear }}</span>
            <span v-if="alreadyAdded" class="mx-1 overline primary--text">* Added</span>
          </div>

          <g-button
            v-if="!alreadyAdded"
            type="primary"
            label="Add"
            :loading="loading"
            @onclick="setNextYear"
          ></g-button>
        </v-row>
        <v-divider />
      </v-card-text>

      <v-card-actions>
        <g-button @onclick="$emit('input', false)" icon="clear" label="Close" />
        <div class="flex-grow-1"></div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    addNextYear: Function,
    alreadyAdded: Boolean,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    activeYear() {
      return new Date().getFullYear();
    },
    nextYear() {
      return new Date().getFullYear() + 1;
    },
  },
  methods: {
    setNextYear() {
      this.loading = true;
      this.addNextYear(this.nextYear)
        .then((t) => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$root.$error(err);
        });
    },
  },
};
</script>
