import languageList from "language-list";
import ENV from "../../../ENV";

export default {
  language: {
    label: "Language",
    inputType: "DROPDOWN",
    multiple: true,
    source: languageList()
      .getData()
      .map((item) => {
        return {
          label: item.language,
          value: item.code,
        };
      }),
  },
  dob: {
    label: "DOB",
    inputType: "DATE",
  },
  payment_type: {
    label: "Payment",
    inputType: "CYOA",
    choosePath: {
      wire: "TEXT",
      "credit card": "TEXT",
      cash: "TEXT",
      check: "TEXT",
    },
  },
};
