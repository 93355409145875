import Vue from "vue";
import api from "./api";
import format from "@/services/format";
import { SuppliersMenu } from "@/config/menus";
import { BookingDayService } from "@/modules/bookings.days";

const SET_DAYS = "SET_DAYS";
const SET_SHIFTED_DAYS = "SET_SHIFTED_DAYS";
const UPDATE_DAY = "UPDATE_DAY";

const FOCUS_DAY = "FOCUS_DAY";
const TRANSFER_SAVE = "TRANSFER_SAVE";

const ADD_SUPPLIER = "ADD_SUPPLIER";
const INSERT_SUPPLIER_TO_DAYS = "INSERT_SUPPLIER_TO_DAYS";
const REMOVE_SUPPLIER = "REMOVE_SUPPLIER";
const REMOVE_ALL_SUPPLIER = "REMOVE_ALL_SUPPLIER";
const UPDATE_SUPPLIER = "UPDATE_SUPPLIER";
const UPDATE_SUPPLIER_DAYS = "UPDATE_SUPPLIER_DAYS";

const ADD_SUPPLIER_ROOM_TO_DATE = "ADD_SUPPLIER_ROOM_TO_DATE";
const REMOVE_SUPPLIER_ROOM_BY_DATE = "REMOVE_SUPPLIER_ROOM_BY_DATE";

const namespaced = true;

const state = {
  days: _getQuote(),
  suppliers: _getSupplierMapping(),

  daySaveQueue: _getClean(),
};

function _getQuote() {
  return JSON.parse(localStorage.getItem("quote_days") || "[]");
}

function _getSupplierMapping() {
  return JSON.parse(localStorage.getItem("quote_suppliers") || "{}");
}

function _getClean() {
  return {
    days: {},
    suppliers: {},
  };
}

const mutations = {
  SET_DAYS(state, data) {
    state.days = data.days || [];
    state.suppliers = data.suppliers || {};
    state.daySaveQueue = _getClean();

    localStorage.setItem("quote_days", JSON.stringify(state.days));
    localStorage.setItem("quote_suppliers", JSON.stringify(state.suppliers));
  },
  SET_SHIFTED_DAYS(state, days) {
    state.days = days || [];
    localStorage.setItem("quote_days", JSON.stringify(state.days));
  },
  UPDATE_DAY(state, { day_number, key, val }) {
    Vue.set(state.days[day_number], key, val);
  },

  // One day
  FOCUS_DAY(state, dayIndex) {
    // Use cached day as reference
    var day = _getQuote()[dayIndex];
    state.days[dayIndex] = day;
  },

  // SAVE
  TRANSFER_SAVE(state) {
    localStorage.setItem("quote_days", JSON.stringify(state.days));
    localStorage.setItem("quote_suppliers", JSON.stringify(state.suppliers));

    state.daySaveQueue = _getClean();
  },

  // Store management
  INSERT_SUPPLIER_TO_DAYS(state, { quoteSupplierId, dateList, type_as }) {
    const dbkey = SuppliersMenu.MOD_DBKEY[type_as];

    dateList.forEach((date) => {
      const day = state.days.find((day) => day.date === date);
      const dayIndex = state.days.findIndex((day) => day.date === date);
      if (!day[dbkey]) day[dbkey] = [];

      // Don't add same bs twice
      if (!day[dbkey].includes(quoteSupplierId)) day[dbkey].push(quoteSupplierId);

      // Mark what days to save
      state.daySaveQueue.days[dayIndex] = true;
    });
  },
  ADD_SUPPLIER(state, { quoteSupplierId, content }) {
    // Add new supplier
    // Supplier MUST be created before inserting into here
    content.id = quoteSupplierId;
    Vue.set(state.suppliers, quoteSupplierId, content);
  },
  UPDATE_SUPPLIER(state, { quoteSupplierId, content }) {
    state.suppliers[quoteSupplierId] = Object.assign(state.suppliers[quoteSupplierId], content);

    state.daySaveQueue.supplier = {
      key: quoteSupplierId,
      task: "UPDATE",
    };
  },
  UPDATE_SUPPLIER_DAYS(state, { quoteSupplierId, dayKeep, dbkey }) {
    let exists;
    state.days.forEach((item, index) => {
      exists = item[dbkey].includes(quoteSupplierId);
      if (dayKeep[index] && !exists) {
        // Not added to this day, add it here
        item[dbkey].push(quoteSupplierId);
        return;
      }

      if (!dayKeep[index] && exists) {
        // Remove from day
        item[dbkey] = item[dbkey].filter((id) => id !== quoteSupplierId);
      }
    });
  },
  REMOVE_SUPPLIER(state, { dayIndex, dbkey, contentIndex, supplier_rooms }) {
    const day = state.days[dayIndex];
    const removed = day[dbkey].splice(contentIndex, 1);
    const removedId = removed[0];

    // Mark to save
    state.daySaveQueue.days[dayIndex] = true;

    // Remove supplier rooms if supplier no longer on focused day
    if (["transport", "tour_guides"].includes(dbkey)) {
      const supplierId = state.suppliers[removedId].supplier_id;
      const remainingSuppliers = day[dbkey].filter((bsid) => state.suppliers[bsid].supplier_id === supplierId);
      if (!remainingSuppliers.length) {
        day.supplier_rooms = day.supplier_rooms.filter((sroomid) => {
          return supplier_rooms.find((v) => v.id === sroomid).supplier_id !== supplierId;
        });
      }
    }

    // Check if supplier doesn't exists in others
    var clearFromDB = true;
    state.days.forEach((d) => {
      if (d[dbkey].includes(removedId)) clearFromDB = false;
    });
    // Delete from database if removed from all days
    if (clearFromDB) {
      delete state.suppliers[removedId];
    }
    state.daySaveQueue.supplier = {
      key: removedId,
      task: clearFromDB ? "DELETE" : "KEEP",
    };
  },
  REMOVE_ALL_SUPPLIER(state, { quoteSupplierId, dbkey, supplier_rooms }) {
    const supplierId = state.suppliers[quoteSupplierId].supplier_id;
    state.days.forEach((d, index) => {
      d[dbkey] = d[dbkey].filter((id) => id !== quoteSupplierId);
      state.daySaveQueue.days[index] = true;

      // Remove supplier rooms if supplier no longer on focused day
      if (["transport", "tour_guides"].includes(dbkey)) {
        let remainingSuppliers = d[dbkey].filter((bsid) => state.suppliers[bsid].supplier_id === supplierId);
        if (!remainingSuppliers.length) {
          d.supplier_rooms = d.supplier_rooms.filter((sroomid) => {
            return supplier_rooms.find((v) => v.id === sroomid).supplier_id !== supplierId;
          });
        }
      }
    });
    delete state.suppliers[quoteSupplierId];
    state.daySaveQueue.supplier = {
      key: quoteSupplierId,
      task: "DELETE",
    };
  },

  /*************
   * SUPPLIER ROOM
   * *********/
  ADD_SUPPLIER_ROOM_TO_DATE(state, { date, quoteSupplierRoomId }) {
    const dayIndex = state.days.findIndex((v) => v.date === date);
    const day = state.days.find((v) => v.date === date);
    day.supplier_rooms.push(quoteSupplierRoomId);

    state.daySaveQueue.days[dayIndex] = true;
  },
  REMOVE_SUPPLIER_ROOM_BY_DATE(state, { date, quoteSupplierRoomId }) {
    const dayIndex = state.days.findIndex((v) => v.date === date);
    const day = state.days.find((v) => v.date === date);
    day.supplier_rooms = day.supplier_rooms.filter((v) => v !== quoteSupplierRoomId);

    state.daySaveQueue.days[dayIndex] = true;
  },
};

const actions = {
  setDays({ commit }, rooms) {
    commit(SET_DAYS, rooms);
  },
  update({ commit }, { day_number, key, val }) {
    commit(UPDATE_DAY, { day_number, key, val });
  },
  focus({ commit }, dayIndex) {
    commit(FOCUS_DAY, dayIndex);
  },

  getDays({ commit }, quote_id) {
    return api
      .getAllQuoteDays(quote_id)
      .then((results) => commit(SET_DAYS, results.data))
      .catch((err) => {
        if (err) throw err.data;
      });
  },

  /*****
   * ADD + REMOVE DAYS
   * ****/
  insertDay({ commit }, { quote_id, dayIndexToInsert }) {
    return api
      .insertDaysIntoQuote(quote_id, dayIndexToInsert)
      .then((results) => commit(SET_SHIFTED_DAYS, results.data))
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  removeDay({ commit }, { quote_id, dayIndexToRemove }) {
    return api
      .removeDaysFromQuote(quote_id, dayIndexToRemove)
      .then((results) => commit(SET_SHIFTED_DAYS, results.data))
      .catch((err) => {
        if (err) throw err.data;
      });
  },

  createQuoteSupplier({ commit, state }, { quote_id, type_as, dateList, content, product_year }) {
    let createdBSID;

    return api
      .createQuoteSupplier(quote_id, type_as, content, product_year)
      .then((v) => {
        createdBSID = v.data;

        commit(INSERT_SUPPLIER_TO_DAYS, {
          quoteSupplierId: v.data,
          type_as,
          dateList,
          content,
        });
        commit(ADD_SUPPLIER, {
          quoteSupplierId: v.data,
          content,
        });

        const daysToSave = Object.keys(state.daySaveQueue.days).map((key) => state.days[key]);
        return api.updateQuoteDays(quote_id, daysToSave);
      })
      .then((v) => commit(TRANSFER_SAVE))
      .then((v) => createdBSID)
      .catch((err) => {
        if (err) throw err.data;
      });
  },

  replaceQuoteSupplier({ commit, state }, { quote_id, replaceBsSupplierId, content }) {
    const quoteId = quote_id;
    const quoteSupplierId = replaceBsSupplierId;
    let cache = JSON.parse(JSON.stringify(state.suppliers[quoteSupplierId]));
    const supplierId = cache.supplier_id;
    commit(UPDATE_SUPPLIER, { content, quoteSupplierId });

    // Strip cache of keys not updatin
    Object.keys(cache).forEach((key) => {
      if (!(key in content)) delete cache[key];
    });

    return api
      .updateQuoteDaySupplier(quoteSupplierId, { data: content, cache, quoteId, supplierId })
      .then((v) => commit(TRANSFER_SAVE))
      .catch((err) => {
        if (err) throw err.data;
      });
  },

  /************
   * ASSSIGN ONE BOOKING DAY
   * *********/
  assignQuoteSupplierToDay({ commit }, { quote_id, quoteSupplierId, dateList, type_as }) {
    commit(INSERT_SUPPLIER_TO_DAYS, {
      quoteSupplierId,
      dateList,
      type_as,
    });

    const daysToSave = Object.keys(state.daySaveQueue.days).map((key) => state.days[key]);
    return api.updateQuoteDays(quote_id, daysToSave);
  },

  updateQuoteSupplier({ commit, state }, { quoteSupplierId, quoteId, data }) {
    let cache = JSON.parse(JSON.stringify(state.suppliers[quoteSupplierId]));
    const supplierId = cache.supplier_id;
    commit(UPDATE_SUPPLIER, { ...data, quoteSupplierId });

    // Strip cache of keys not updatin
    Object.keys(cache).forEach((key) => {
      if (!(key in data.content)) delete cache[key];
    });

    return api
      .updateQuoteDaySupplier(quoteSupplierId, { data: data.content, cache, quoteId, supplierId })
      .then((v) => commit(TRANSFER_SAVE))
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  updateQuoteSupplierDays({ commit, state }, { quote_id, quoteSupplierId, dayKeep, historyBlob, dbkey }) {
    commit(UPDATE_SUPPLIER_DAYS, { quoteSupplierId, dayKeep, dbkey });
    const daysToSave = state.days;

    return api
      .updateQuoteDays(quote_id, daysToSave, historyBlob)
      .then((v) => commit(TRANSFER_SAVE))
      .catch((err) => {
        if (err) throw err.data;
      });
  },

  /************
   * REMOVE SUPPLIER STUFF
   * *********/
  removeQuoteSupplier({ commit, state }, { data, supplier_rooms }) {
    commit(REMOVE_SUPPLIER, { ...data, supplier_rooms });

    const daysToSave = Object.keys(state.daySaveQueue.days).map((key) => state.days[key]);
    const supplierRef = state.daySaveQueue.supplier;

    return api
      .removeQuoteSupplier(supplierRef.key, {
        days: daysToSave,
        quoteId: data.quoteId,
        supplierId: data.supplierId,
        isdelete: supplierRef.task === "DELETE",
        removedDate: state.days[data.dayIndex].date,
      })
      .then((v) => commit(TRANSFER_SAVE))
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  removeAllOfQuoteSupplier({ commit, state }, { data, supplier_rooms }) {
    commit(REMOVE_ALL_SUPPLIER, { ...data, supplier_rooms });

    const daysToSave = Object.keys(state.daySaveQueue.days).map((key) => state.days[key]);
    const supplierRef = state.daySaveQueue.supplier;

    return api
      .removeQuoteSupplier(supplierRef.key, {
        days: daysToSave,
        quoteId: data.quoteId,
        supplierId: data.supplierId,
        isdelete: supplierRef.task === "DELETE",
      })
      .then((v) => commit(TRANSFER_SAVE))
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  updateOneSupplierReminder({ commit, state }, { quoteId, quoteSupplierId, supplierId, cache, data }) {
    return api.updateQuoteDaySupplier(quoteSupplierId, { quoteId, supplierId, cache, data });
  },

  /*************
   * SUPPLIER ROOM
   * *********/
  addSupplierRoomToDate({ commit }, { quote_id, supplierRoomIdList, dateList }) {
    supplierRoomIdList.forEach((id) => {
      dateList.forEach((date) => {
        commit(ADD_SUPPLIER_ROOM_TO_DATE, {
          quoteSupplierRoomId: id,
          date,
        });
      });
    });

    const daysToSave = Object.keys(state.daySaveQueue.days).map((key) => state.days[key]);
    return api.updateQuoteDays(quote_id, daysToSave);
  },
  removeSupplierRoomDate({ commit }, { quote_id, supplierRoomIdList, dateList }) {
    supplierRoomIdList.forEach((id) => {
      dateList.forEach((date) => {
        commit(REMOVE_SUPPLIER_ROOM_BY_DATE, {
          quoteSupplierRoomId: id,
          date,
        });
      });
    });

    const daysToSave = Object.keys(state.daySaveQueue.days).map((key) => state.days[key]);
    return api.updateQuoteDays(quote_id, daysToSave);
  },
};

const getters = {
  days: (state) => state.days,
  day: (state) => (dayIndex) => {
    return state.days[dayIndex] || {};
  },
  dayByDate: (state) => (date) => {
    return state.days.find((d) => d.date === date) || {};
  },
  daySupplier: (state) => (dayIndex, dbkey) => {
    return state.days[dayIndex][dbkey].map((quoteSupplierId) => {
      return state.suppliers[quoteSupplierId] || {};
    });
  },
  dayIncludes: (state) => (dayIndex, dbkey, quoteSupplierId) => {
    return state.days[dayIndex][dbkey].indexOf(quoteSupplierId);
  },
  day_name: (state) => (dayIndex) => {
    let day = state.days[dayIndex] || {};
    return {
      day: `Day ${dayIndex + 1}`,
      date: `${format.formatDate(day.date)}`,
    };
  },

  // Supplier stuff
  suppliers: (state) => state.suppliers,
  oneSupplier: (state) => (quoteSupplierId) => {
    return state.suppliers[quoteSupplierId] || {};
  },
  whatDays: (state) => (typeUse, quoteSupplierId) => {
    // What days supplier appears
    return BookingDayService.whatDays(typeUse, quoteSupplierId, state.days);
  },
  whatDaysBySupplierId: (state) => (typeUse, supplierId) => {
    return BookingDayService.whatDaysBySupplierId(typeUse, supplierId, state.days, state.suppliers);
  },
  firstDaySupplier: (state) => (mod, quoteSupplierId) => {
    // First day a supplier appears
    // Assuming days are sorted
    return state.days.find((x) => x[mod].includes(quoteSupplierId));
  },
  allDaysSupplier: (state) => (mod, quoteSupplierId) => {
    const dateList = BookingDayService.whatDays(mod, quoteSupplierId, state.days);
    return format.compileDateListReadable(dateList);
  },
  lastDaySupplier: (state) => (mod, quoteSupplierId) => {
    // Last day a supplier appears
    return state.days
      .slice()
      .reverse()
      .find((x) => x[mod].includes(quoteSupplierId));
  },

  allSuppliersOnDay: (state) => (date, maptype, supplierId) => {
    const mod = SuppliersMenu.MOD_DBKEY[maptype];
    const day = state.days.find((v) => v.date === date);
    if (!day[mod]) return [];
    return day[mod]
      .filter((bsid) => state.suppliers[bsid].supplier_id === supplierId)
      .map((bsid) => state.suppliers[bsid]);
  },
  daySupplierFlat: (state) => (mod) => {
    return BookingDayService.flattenBookingDays(state.days, mod);
  },
  dayCount: (state) => {
    return state.days ? state.days.length : 0;
  },
  dayLinkList: (state) => (quote_id) => {
    // NO DATES HERE
    return state.days.map((day, dayIndex) => ({
      dayIndex,
      day: `Day ${dayIndex + 1}`,
      to: { name: "quote_day", params: { quote_id: quote_id, day: dayIndex + 1 } },
    }));
  },

  firstDay: (state) => state.days[0],
  lastDay: (state) => {
    return state.days[state.days.length - 1];
  },

  daySupplierRoomRef: (state) => {
    return state.days.reduce((obj, item) => {
      obj[item.date] = item.supplier_rooms;
      return obj;
    }, {});
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
