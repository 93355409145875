<template>
  <v-container fill-height>
    <v-row justify="center" align="center">
      <div style="text-align: center">
        <img src="@/assets/img/Logo.png" class="logo" />
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.logo {
  height: 180px;
}
</style>
