import ReminderView from "./ReminderView";

export default [
  {
    path: "reminder",
    name: "booking_reminder",
    component: ReminderView,
    meta: {
      toolbar: "booking",
      tabAccess: "BOOKING",
    },
  },
];
