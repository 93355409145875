<template>
  <v-row align="center" style="height: 45px">
    <v-col cols="4" class="subtitle-2" align-self="center"> Custom Resort Fee </v-col>
    <v-col>
      <div v-if="bsmeta.has_custom_resortfee" style="width: 100px; display: flex; align-items: center">
        <enumber :value="bsmeta.custom_resortfee_value" @input="(v) => $emit('set', v)" />
        <v-btn icon x-small @click="$emit('set', 'DELETE')" class="clearbtn">
          <v-icon small>clear</v-icon>
        </v-btn>
      </div>
      <v-btn v-else x-small class="mx-1 cyoa-btn" color="grey lighten-1" @click="(v) => $emit('set', 'ADD')"
        >+ Add</v-btn
      >
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    bsmeta: Object,
  },
};
</script>
