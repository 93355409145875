<template>
  <elwrapper label="Total:" smlabel texttop midtext class="ring grey lighten-3 total-block">
    <div class="d-flex">
      <div v-if="isSeasonal">
        <SeasonalPriceRow
          v-for="(r, index) in rateInfo"
          :key="index"
          class="caption"
          :country="country"
          :rate="r"
          :tax="tax"
          :taxInclusive="taxInclusive"
          :occupancy="occupancy"
          :resortFee="resortFee"
          :extraList="extraList"
        />
      </div>

      <TotalCalc
        v-else
        class="caption"
        :country="country"
        :rate="rateInfo"
        :tax="tax"
        :taxInclusive="taxInclusive"
        :occupancy="occupancy"
        :resortFee="resortFee"
        :extraList="extraList"
      />

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon v-show="showconfim && rateInfo && !confirmed" small v-on="on" class="ml-3">report_problem</v-icon>
        </template>
        <span>Rate not confirmed for {{ focusYear }}</span>
      </v-tooltip>
    </div>
  </elwrapper>
</template>

<script>
import ProductService from "../service";
import SeasonalPriceRow from "./rate/_SeasonPriceRow";
import TotalCalc from "./_TotalCalc";
export default {
  props: {
    isSeasonal: Boolean,
    country: String,
    rateInfo: [Array, Object],
    tax: [Number, Object, String],
    taxInclusive: [Number, Boolean],

    occupancy: [Number, Object],
    resortFee: [Number, Object],
    extraList: [Array], // => Objects,

    showconfim: Boolean,
    confirmed: Number,
    focusYear: String,
  },
  components: {
    SeasonalPriceRow,
    TotalCalc,
  },
};
</script>

<style lang="scss">
.total-block {
  margin: -5px 0px -4px 0px !important;
}
</style>
