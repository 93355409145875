<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)" persistent fullscreen>
    <v-card class="supplier-select-wrapper">
      <v-card-title>
        <DialogHeader
          :type="module"
          :name="supplier.name"
          :supplierId="supplier.id"
          :step="step"
          :hasStepThree="hasStepThree"
          :edit="edit"
          @replace="$emit('replace', editBookingSupplier)"
          @setStep="(v) => (step = v)"
        />
      </v-card-title>

      <v-card-text class="dialog-content">
        <SupplierSelectView v-show="step === 1" :module="module" @update="selectSupplier" @close="close" />

        <ProductSelectWrapper
          v-if="step === 2"
          :mode="mode"
          :module="module"
          :startDay="startDay"
          :supplier="supplier"
          :productFilter="productFilter"
          :dateList="dateList"
          @setDateList="(v) => (dateList = v)"
          @update="selectProducts"
          @revert="step = 1"
          @close="close"
        />

        <template v-if="step === 3">
          <MealSelectWrapper
            v-if="module === 'HOTEL'"
            :mode="mode"
            :nested="nested"
            :supplier="supplier"
            :productFilter="productFilter"
            :dateList="dateList"
            :dateToBsidMapping="dateToBsidMapping"
            @next="finishMeal"
            @revert="step = 2"
            @close="close"
          />

          <SupplierMealWrapper
            v-else-if="module === 'RESTAURANT'"
            :mode="mode"
            :module="module"
            :supplierId="supplier.id"
            @next="step = 4"
            @revert="step = 2"
            @close="close"
          />

          <RoomSelectWrapper
            v-else-if="['TOUR_GUIDE', 'TRANSPORT'].includes(module)"
            :mode="mode"
            :module="module"
            :supplierId="supplier.id"
            @next="step = 4"
            @revert="step = 2"
            @close="close"
          />

          <!-- Canada Excursion -->
          <SupplierSelectWrapper
            v-else-if="showExtraExcursion"
            :mode="mode"
            :module="module"
            :supplierId="supplier.id"
            @next="step = 4"
            @revert="step = 2"
            @close="close"
          />

          <div v-else>
            <v-btn @click="close">Close</v-btn>
            <v-btn @click="step = 2">Back</v-btn>
            <v-btn @click="step = 4">Next</v-btn>
          </div>
        </template>

        <AlternateSelectWrapper
          v-if="step === 4 && !nested"
          mode="BOOKING"
          :module="module"
          :dateToBsidMapping="dateToBsidMapping"
          @revert="step = hasStepThree ? 3 : 2"
          @close="close"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import format from "@/services/format";
import { BookingDayService } from "@/modules/bookings.days";

import DialogHeader from "./_DialogHeader";
import SupplierSelectView from "./step1_suppliers/SupplierSelectView";
import ProductSelectWrapper from "./step2_products/ProductSelectWrapper";
// STEP 3
import MealSelectWrapper from "./step3_custom/MealSelectWrapper";
import RoomSelectWrapper from "./step3_custom/RoomSelectWrapper";
import SupplierMealWrapper from "./step3_custom/SupplierMealWrapper";
import SupplierSelectWrapper from "./step3_custom/SupplierSelectWrapper";
// STEP 4
import AlternateSelectWrapper from "./step4_alternate/AlternateSelectWrapper";

export default {
  name: "SelectSupplierDialog",
  components: {
    DialogHeader,
    SupplierSelectView,
    ProductSelectWrapper,
    MealSelectWrapper,
    RoomSelectWrapper,
    SupplierMealWrapper,
    SupplierSelectWrapper,
    AlternateSelectWrapper,
  },
  props: {
    value: Boolean,

    nested: Boolean,
    mode: String,

    module: String,
    startDay: [String, Date],

    edit: Boolean,
    editBookingSupplier: Object,
    editProductFilter: Object,
  },
  data() {
    return {
      step: 1,

      // SUPPLIER
      supplier: {},
      productFilter: {},
      // PRODUCT
      dateList: [this.startDay],
      dateToBsidMapping: {},
    };
  },
  watch: {
    toggleReset(v) {
      this.init();

      // Fix for editing
      if (this.edit) this.initEdit();
    },
  },
  computed: {
    toggleReset() {
      return `${this.value}|${this.module}`;
    },
    editInputCheck() {
      return `${this.editSupplier}|${this.editProductFilter}`;
    },

    showExtraExcursion() {
      const env = this.$store.getters["AccountStore/environment"];
      return env === "CDA" && this.module === "EXCURSION";
    },
    hasStepThree() {
      return this.showExtraExcursion || ["HOTEL", "RESTAURANT", "TOUR_GUIDE", "TRANSPORT"].includes(this.module);
    },

    // useDate(){
    //   // Use custom date
    //   if(this.specificConfig && this.specificConfig.use_dates) return this.specificConfig.use_dates;
    //   // Otherwise, default to whatever current day is
    //   var dateInfo = this.$store.getters['BookingDayStore/day'](this.dayIndex)
    //   var dateobj = format.dateToObj(dateInfo.date);
    //   return {
    //     from_month: dateobj.month,
    //     from_day: dateobj.day
    //   }
    // },
  },
  methods: {
    // ----------------
    // INIT
    // ----------------
    init() {
      Object.assign(this, {
        step: 1,
        supplier: {},
        productFilter: {},
        dateList: [this.startDay],
        dateToBsidMapping: {},
      });
    },
    initEdit() {
      const e = this.editBookingSupplier;
      const mockSupplier = {
        id: e.supplier_id,
        name: e.name,
        supplier_type: e.supplier_type,
        meta: e.supplier_meta,
      };

      // Set Supplier
      this.selectSupplier({
        supplier: mockSupplier,
        productFilter: {
          multiple: true,
          list: [
            {
              value: mockSupplier.id,
              columns: ["supplier_id"],
            },
          ],
        },
      });

      // Set Product for extra stuff
      const days = this.$store.getters["BookingDayStore/days"];
      const suppliers = this.$store.getters["BookingDayStore/suppliers"];
      this.dateToBsidMapping = BookingDayService.whatProductMapping(this.module, days, suppliers, mockSupplier.id);
    },

    // ----------------
    // SELECT SUPPLIER
    // ----------------
    selectSupplier(data) {
      this.supplier = data.supplier;
      this.productFilter = data.productFilter;
      this.step = 2;

      const existing = this.$store.getters["BookingDayStore/whatDaysBySupplierId"](this.module, data.supplier.id);
      this.dateList = [...existing, this.startDay].sort((a, b) => a - b);
    },

    // ----------------
    // SELECT PRODUCT
    // ----------------
    selectProducts(dateToBsidMapping) {
      // Product stuff
      this.dateToBsidMapping = dateToBsidMapping;
      this.step = this.hasStepThree ? 3 : 4;
    },

    // ----------------
    // SELECT EXTRA
    // ----------------
    finishMeal(extra) {
      this.step = 4;
    },

    // ----------------
    // SAVE / CANCEL
    // ----------------
    close() {
      this.$emit("input", false);
    },
    // _dumpNestedBooking(){
    //   let promiseQueue = []
    //   this.nestedBookingSupplierIds.forEach(bsid => {
    //     promiseQueue.push(this.$store.dispatch('BookingDayStore/removeAllOfBookingSupplier', {
    //       data: {
    //         bookingSupplierId: bsid,
    //         bookingId: this.$store.getters['BookingStore/booking_id'],
    //         supplierId: this.supplier.id,
    //         dbkey: 'restaurants'
    //       }
    //     }))
    //   })
    //   return Promise.all(promiseQueue);
    // },

    // eject(addAlternate){
    //   // DEPRICATED
    //   let createdBSID;

    //   this._updateSelected({
    //     dbkey: this.dbkey,
    //     type: this.module,

    //     config: {
    //       ...this.specificConfig,
    //       ...this.bookingSupplierMeta
    //     },
    //     supplier: this.supplier,
    //     products: {...this.products}
    //   })
    //   .then(bsid => {
    //     createdBSID = bsid;
    //     return this._assignNestedBookingSuppliers(bsid)
    //   })
    //   .then(v => {
    //     this.$root.$success('Added ' + this.supplier.name);

    //     this.revertTable();
    //     this.$emit('input', false);
    //     if(addAlternate) this.$emit('addalt', createdBSID)
    //   });
    // },
    // _updateSelected(v){
    //   const productYear = `${new Date(this.dateInfo.date).getFullYear()}`;

    //   const content = {
    //     supplier_id: v.supplier.id,
    //     country: v.supplier.country,
    //     name: v.supplier.name,
    //     city: v.supplier.city,
    //     subcity: v.supplier.subcity,
    //     type_as: v.type,
    //     supplier_meta: v.supplier.meta,

    //     ...this.nested && {
    //       nest_type: this.nestedType,
    //       // Add to existing (this will be null if new parent)
    //       ...this.parentBookingSupplierId && { parent_bsid: this.parentBookingSupplierId }
    //     },

    //     product_year: productYear,
    //     products: v.products,
    //     status: 0,
    //     time: v.config.time,
    //     meta: {
    //       ...v.config.meta,
    //       ...v.config.custom_prices && { custom_prices: v.config.custom_prices },
    //       ...v.config.custom_names && { custom_names: v.config.custom_names },
    //       ...v.config.count && { count: v.config.count },
    //       ...v.config.use_dates && { use_dates: v.config.use_dates },
    //       ...v.config.technical_text && { technical_text: v.config.technical_text },
    //       ...v.config.custom_room_num && { custom_room_num: v.config.room_num },

    //       ...v.config.porterage && { porterage: v.config.porterage },
    //       ...v.config.parking && { parking: v.config.parking },
    //       ...v.config.exclude_suppliers && { exclude_suppliers: v.config.exclude_suppliers }
    //     }
    //   }

    //   let dateRange = v.config.dateRange;

    //   let multipleDays = dateRange? (dateRange.from && dateRange.to && dateRange.from !== dateRange.to)
    //       : false;

    //   return this.$store.dispatch('BookingDayStore/createBookingSupplier', {
    //     booking_id: this.bookingId,
    //     type_as: v.type,
    //     data: {
    //       dayIndex: this.dayIndex,
    //       dbkey: v.dbkey,
    //       content: content,
    //       ...multipleDays && {
    //           // Days between (plus current day)
    //         count: format.getNumberOfDays(dateRange.from, dateRange.to) + 1
    //       }
    //     },
    //     product_year: productYear
    //   }).then(bsid => {
    //     this.$emit('postadd', bsid)
    //     this.$roomingPing.$emit('sync')
    //     return bsid;
    //   })
    // },
    // _assignNestedBookingSuppliers(parentBSID){
    //   let promiseQueue = []
    //   this.nestedBookingSupplierIds.forEach(bsid => {
    //     promiseQueue.push(this.$store.dispatch('BookingDayStore/updateBookingSupplier', {
    //       bookingSupplierId: bsid,
    //       bookingId: this.bookingId,
    //       data: {
    //         content: {
    //           parent_bsid: parentBSID
    //         }
    //       }
    //     }))
    //   })
    //   return Promise.all(promiseQueue);
    // }
  },
};
</script>
