import api from "./api";

const PEND_MAIL = "PEND_MAIL";

const namespaced = true;

const state = {
  contact: {},
  config: {},
  returnTo: "/",
};

const mutations = {
  PEND_MAIL(state, { contact, returnTo, config }) {
    Object.assign(state, { contact, returnTo, config });
  },
};

const actions = {
  pendMail({ commit }, { contact, returnTo, config }) {
    commit(PEND_MAIL, { contact, returnTo, config });
  },

  popNewWindow({ commit }, data) {},
  getWindowData(v, dataStampName) {
    const data = localStorage.getItem(dataStampName);
    return Promise.resolve(data ? JSON.parse(data) : {});
  },

  getAccountEmails({ commit }) {
    return api
      .getAccountEmails()
      .then((data) => data.data)
      .catch((err) => {
        throw err;
      });
  },
};

const getters = {
  title: (state) => state.config.title,
  isclient: (state) => state.config.client,
  clientId: (state) => state.config.clientId,
  supplierId: (state) => state.config.supplierId,
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
