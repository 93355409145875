<template>
  <v-navigation-drawer permanent width="220" style="min-width: 150px">
    <v-list dense nav>
      <v-list-item
        v-for="item in menuItems"
        :key="item.title"
        class="py-2"
        color="success"
        :class="{ 'grey lighten-2': item.module === view }"
        @click="nav(item)"
      >
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    view: String,
  },
  data() {
    return {
      menuItems: [
        {
          title: "To Search",
          module: "search",
        },
        {
          title: "Accounting",
          module: "accounting",
        },
        {
          title: "Margin",
          module: "margin",
        },
      ],
    };
  },
  methods: {
    nav() {},
  },
};
</script>

<style></style>
