<template>
  <v-btn
    depressed
    small
    :disabled="disabled"
    outlined
    color="primary"
    type="submit"
    class="search-btn"
    @click="$emit('onclick')"
  >
    <v-icon small left>search</v-icon>
    <span>Search</span>
  </v-btn>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
  },
};
</script>

<style lang="scss">
.search-btn {
  background: white;
}
</style>
