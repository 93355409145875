import axios from "@/plugins/axios";

export default {
  queryFinances(searchQuery) {
    return axios.get("/finances", {
      params: { ...searchQuery },
    });
  },

  getGroupListWithClients() {
    return axios.get("/booking_client_groups");
  },
};
