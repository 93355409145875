import axios from "@/plugins/axios";

export default {
  /**
   * rates - string array of currencies. e.g ['CAD', 'JPY']
   */
  fetch(rates) {
    return axios.get(`currency/rates?cur=${rates.join(",")}`);
  },
};
