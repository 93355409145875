import axios from "@/plugins/axios";

export default {
  getMassReminders(dateStart, dateEnd) {
    return axios.get("/reminders", { params: { dateStart, dateEnd } });
  },
  getOverdueReminders(data) {
    return axios.get("/overdue_reminders", { params: { ...data } });
  },

  getBookingReminders(bookingId) {
    return axios.get(`/bookings/${bookingId}/reminders`);
  },
  addReminder(bookingId, data) {
    return axios.post(`/bookings/${bookingId}/reminders`, data);
  },
  updateReminder(reminderId, data, cache, message) {
    return axios.post(`/reminders/${reminderId}`, { data, cache, message });
  },
  removeReminder(reminderId) {
    return axios.delete(`/reminders/${reminderId}`);
  },
};
