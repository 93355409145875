import axios from "@/plugins/axios";

export default {
  addSupplierRoom(booking_id, data) {
    return axios.post(`/bookings/${booking_id}/supplier_rooms`, data);
  },
  updateSupplierRoom(supplierRoomId, data) {
    return axios.post(`/supplier_rooms/${supplierRoomId}`, { data });
  },
  removeSupplierRoom(supplier_room_id) {
    return axios.delete(`/supplier_rooms/${supplier_room_id}`);
  },
};
