<template>
  <div class="px-5">
    <v-row v-if="showNotes">
      <v-col cols="1" v-if="editMode" class="pa-0">
        <v-btn icon x-small @click="$emit('input', !value)">
          <v-icon>{{ value ? "visibility_off" : "visibility" }}</v-icon>
        </v-btn>
      </v-col>

      <v-col v-if="!value || editMode" class="d-flex flex-column pa-0" :class="{ 'text--disabled': editMode && value }">
        <v-row>
          <v-col :cols="editMode ? 4 : 3" class="caption font-weight-bold mx-2 mx-2 pa-0" align-self="top"
            >Menu:
          </v-col>
          <v-col class="caption pa-0 text-capitalize" align-self="center" v-html="adjustNotes"></v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
    editMode: Boolean,
    menu: String,
  },
  computed: {
    showNotes() {
      return this.menu;
    },
    adjustNotes() {
      return (this.menu || "").replace(/\r?\n/g, "<br />");
    },
  },
};
</script>
