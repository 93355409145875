<template>
  <v-dialog :value="value" fullscreen>
    <v-card class="print-wrapper grey darken-2">
      <v-row align="center" justify="space-between" class="mx-0 px-2 grey darken-3 pdf-controls">
        <v-btn icon text @click="$emit('input', false)">
          <v-icon color="white">clear</v-icon>
        </v-btn>

        <slot name="header"></slot>
      </v-row>

      <div id="printWrapper" class="pdf">
        <slot></slot>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
  methods: {
    printDocument() {},
  },
};
</script>

<style lang="scss">
.print-wrapper {
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 35px);

  .pdf-controls {
    position: fixed;
    top: 0;
    width: 100%;
    height: 35px;
    z-index: 100;

    fieldset {
      background: white;
    }
  }

  .pdf {
    margin: 35px auto 0;
    height: 100%;
    width: 850px;
    overflow: auto;
    background: white;

    .doc {
      display: block;
      margin: 0 auto;
      padding: 5px;
    }
  }

  .title {
    text-decoration: underline;
  }
}
</style>
