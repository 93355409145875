<template>
  <v-app-bar app dense id="mainnav" class="elevation-1">
    <v-toolbar-title class="headline text-uppercase d-flex">
      <v-btn small text @click="goBack" class="mr-3">
        <v-icon small>keyboard_arrow_left</v-icon>
        <span>Back</span>
      </v-btn>
      <v-icon>public</v-icon>
      <Environment :switchEnv="switchEnv" />
      <NavItems />
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <SupplierToolbar v-if="toolbar === 'supplier'" />
    <ClientToolbar v-if="toolbar === 'client'" />
    <BookingToolbar v-if="toolbar === 'booking'" />
    <ProductYear v-if="showProductYearControl" />
    <DropMenu />

    <ReminderNotifications />
  </v-app-bar>
</template>

<script>
import Environment from "./_Environment";
import NavItems from "./_NavItems";
import DropMenu from "./_DropMenu";
import ReminderNotifications from "./_ReminderNotifications";

import SupplierToolbar from "./supplierSubs/SupplierToolbar";
import ClientToolbar from "./clientSubs/ClientToolbar";
import BookingToolbar from "./bookingSubs/BookingToolbar";

import ProductYear from "./_ProductYear";

export default {
  props: {
    switchEnv: Function,
  },
  components: {
    Environment,
    NavItems,
    DropMenu,
    SupplierToolbar,
    ClientToolbar,
    BookingToolbar,
    ReminderNotifications,
    ProductYear,
  },
  data() {
    return {};
  },
  computed: {
    toolbar() {
      return this.$route.meta && this.$route.meta.toolbar;
    },
    showProductYearControl() {
      return this.$route.meta && this.$route.meta.showProductYear;
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
#mainnav {
  height: 30px !important;

  .v-toolbar__content {
    height: 30px !important;
  }
}
</style>
