<template>
  <div>
    <h3 class="title white--text">Edit Account: {{ email }}</h3>
    <v-row justify="space-between" class="ma-3">
      <delete-button :ondelete="deleteAccount" />

      <div>
        <g-button label="Return" @onclick="$router.push({ name: 'admin' })" />
        <save-button :disabled="!canSave" :onsave="updateRole" />
      </div>
    </v-row>

    <a-card>
      <elwrapper widelabel label="Name">{{ name }}</elwrapper>
      <elwrapper widelabel label="Email">{{ email }}</elwrapper>

      <a-select
        widelabel
        :label="(role === 'ADMIN' ? '' : 'Start ') + 'Environment'"
        :items="environments"
        v-model="env"
      />
      <a-select widelabel label="Role" :items="roles" v-model="role" />
      <CustomRoleManager v-if="role === 'ACCESS'" v-model="custom_access" />

      <a-text-field widelabel v-if="showEmailPasswordEdit" label="Email Password" v-model="email_password" />
      <elwrapper widelabel v-else label="Email Password">
        <span>********</span>
        <v-btn small icon @click="showEdit = true"><v-icon small>edit</v-icon></v-btn>
      </elwrapper>

      <div class="d-flex ma-3">
        <g-button label="Generate New Password" @onclick="makeRandomPassword" />
        <div>
          {{ newGeneratedPassword }}
        </div>
      </div>
    </a-card>

    <d-header label="Linked Webmail" />

    <EmailAccountBlock :accountId="accountId" :linkedEmailList="linkedEmailList" @added="postEmailAdd" />
  </div>
</template>

<script>
import ENV from "@/config/ENV";
import { CustomRoleManager } from "@/modules/admin";
import { EmailAccountBlock } from "@/modules/account";
export default {
  components: {
    CustomRoleManager,
    EmailAccountBlock,
  },
  data() {
    var vm = this;
    return {
      roles: ["ACCESS", "ADMIN"],
      environments: ENV,

      accountId: "",
      name: "",
      email: "",
      role: "",
      env: "",
      email_password: "",
      custom_access: {},

      linkedEmailList: [],

      newGeneratedPassword: null,

      hasPassword: false,
      showEdit: false,
    };
  },
  computed: {
    canSave() {
      return this.role && this.env;
    },
    showEmailPasswordEdit() {
      return !this.hasPassword || this.showEdit;
    },
  },
  methods: {
    deleteAccount() {
      return this.$store
        .dispatch("AdminStore/deleteAccount", this.accountId)
        .then((v) => {
          this.$root.$success("Deleted account for " + this.email);
          this.$router.push({ name: "admin" });
        })
        .catch((err) => this.$root.$error(err));
    },
    updateRole() {
      return this.$store
        .dispatch("AdminStore/updateAccountRole", {
          account_id: this.accountId,
          role: this.role,
          env: this.env,
          ...(this.showEmailPasswordEdit && this.email_password && { email_password: this.email_password }),
          ...(this.role === "ACCESS" && { custom_access: this.custom_access }),
        })
        .then((v) => {
          this.$root.$success("Updated role for " + this.email);
          //this.$router.push({ name: 'admin' })
        })
        .catch((err) => this.$root.$error(err));
    },
    makeRandomPassword() {
      this.$store
        .dispatch("AdminStore/generatePassword", {
          accountId: this.accountId,
          email: this.email,
        })
        .then((password) => {
          this.$root.$success("Created new password for " + this.email);

          this.newGeneratedPassword = password;
          //this.$router.push({ name: 'admin' })
        })
        .catch((err) => this.$root.$error(err));
    },

    postEmailAdd(data) {
      if (!data.ismain) return this.linkedEmailList.push(data);

      const query = this.linkedEmailList.map((item) => {
        return this.$store.dispatch("AccountStore/updateCustomEmail", {
          emailaccount_id: item.id,
          data: {
            ismain: 0,
          },
        });
      });

      Promise.all(query)
        .then(() => {
          this.linkedEmailList.push(data);
        })
        .catch((err) => {
          this.$root.$error(err);
        });
    },
  },
  mounted() {
    // Get this account
    this.accountId = this.$route.params.account_id;
    this.$store
      .dispatch("AdminStore/getOneAccount", this.accountId)
      .then((account) => {
        this.name = account.name;
        this.email = account.email;
        this.role = account.role;
        this.env = account.env;
        this.hasPassword = account.email_password;
        this.custom_access = account.custom_access;
      })
      .catch((err) => this.$root.$error(err));

    this.$store
      .dispatch("AdminStore/getAccountEmailList", this.accountId)
      .then((list) => (this.linkedEmailList = list))
      .catch((err) => this.$root.$error(err));
  },
};
</script>
