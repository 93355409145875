import { render, staticRenderFns } from "./SupplierBlockRowWrapper.vue?vue&type=template&id=ca9255c4"
import script from "./SupplierBlockRowWrapper.vue?vue&type=script&lang=js"
export * from "./SupplierBlockRowWrapper.vue?vue&type=script&lang=js"
import style0 from "./SupplierBlockRowWrapper.vue?vue&type=style&index=0&id=ca9255c4&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports