import axios from "@/plugins/axios";

export default {
  getBookingRoomBreakdown(booking_id) {
    return axios.get(`/bookings/${booking_id}/rooms`);
  },
  updateRoom(booking_id, { rooms, cache }) {
    return axios.post(`/bookings/${booking_id}/rooms`, {
      data: {
        rooms,
      },
      cache: JSON.parse(cache),
    });
  },
};
