<template>
  <elwrapper label="# of Rooms" midtext>
    <v-menu open-on-hover>
      <template v-slot:activator="{ on }">
        <v-col v-on="on" class="caption">
          {{ roomTotalDisplay }}
        </v-col>
      </template>

      <div class="hover-menu">
        <div>
          <span class="caption font-weight-bold">Breakdown</span>
        </div>
        <div v-for="room in reducedRooms" :key="room.value" v-show="room.count > 0">
          <span class="caption">{{ room.count }} - {{ room.label }}</span>
        </div>

        <div>
          <span class="caption font-italic">(per rooming list)</span>
        </div>

        <div class="amber--text lighten-5 caption" v-if="!matchesPax">
          ** Warning: Rooming pax is less than total pax
        </div>
      </div>
    </v-menu>
  </elwrapper>
</template>

<script>
import BookingRoomService from "../../service";
import { BookingSupplierRoomService } from "@/modules/bookings.supplier_rooms";
export default {
  props: {
    pax: [Number, String],
    flattenSuppliers: Boolean,
  },
  data() {
    return {
      roomBreakdownData: {},
    };
  },
  computed: {
    matchesPax() {
      if (!this.roomBreakdownData) return true;
      if (!this.roomBreakdownData.roomingList) return true;
      const pax_total = this.roomBreakdownData.roomingList.reduce((total, v) => total + v.pax, 0);

      return Number(this.pax || 0) !== pax_total;
    },

    specialRoomList() {
      return BookingSupplierRoomService.getSupplierRoomFlatList({
        dayList: this.$store.getters["BookingDayStore/days"],
        bookingSupplierRooms: this.$store.getters["BookingSupplierRoomStore/supplierRoomsRef"],
      });
    },
    specialRoomListCount() {
      return this.specialRoomList.length;
    },

    roomTotal() {
      let useRoom = this.roomBreakdownData.roomingListBreakdown || {};

      var total = 0;
      Object.values(useRoom).forEach((value, key) => {
        if (value) total += Number(value);
      });
      return total;
    },
    roomTotalDisplay() {
      if (this.specialRoomListCount) return `${this.roomTotal}+${this.specialRoomListCount}`;
      return this.roomTotal;
    },
    reducedRooms() {
      let useRoom = this.value || this.roomBreakdownData.roomingListBreakdown || {};

      return BookingRoomService.reduceRooms(useRoom, this.specialRoomList);
    },
  },
  methods: {
    syncRoomData() {
      this.roomBreakdownData = BookingRoomService.getRoomingListBreakdown({
        bookingMetaData: this.$store.getters["BookingStore/data"],
        bookingRoomingList: this.$store.getters["BookingRoomStore/rooms"],
        bookingDays: this.$store.getters["BookingDayStore/days"],
        supplierRoomList: this.$store.getters["BookingSupplierRoomStore/supplier_rooms"],
      });
    },
  },
  mounted() {
    this.syncRoomData();
    this.$roomingPing.$on("sync", this.syncRoomData);
  },
  beforeDestroy() {
    this.$roomingPing.$off("sync", this.syncRoomData);
  },
};
</script>
