<template>
  <a-card nopad v-if="flights.length > 0">
    <div class="booking-title grey darken-1 white--text">
      <v-icon left small color="white">flight</v-icon>
      <span>Flights</span>
    </div>

    <FlightRow v-for="(f, index) in flights" :key="index" :flight="f" />
  </a-card>
</template>

<script>
import FlightRow from "./FlightRow";
export default {
  props: {
    date: [String, Date],
  },
  components: {
    FlightRow,
  },
  data() {
    return {};
  },
  computed: {
    flights() {
      return this.$store.getters["BookingFlightStore/flightOnDay"](this.date) || [];
    },
  },
};
</script>
