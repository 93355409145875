<template>
  <div>
    <v-row>
      <v-col class="subtitle-2" cols="3" align-self="center"> Label </v-col>

      <v-col class="d-flex justify-end">
        <a-text-field v-model="expenseName" />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="subtitle-2" cols="3" align-self="center"> Amount </v-col>

      <v-col class="d-flex justify-end">
        <div class="d-flex align-center pr-1">$</div>
        <enumber v-model="expense" />
      </v-col>
    </v-row>

    <v-card-actions>
      <g-button @onclick="$emit('close')" label="Cancel" />
      <div class="flex-grow-1"></div>
      <g-button type="primary" @onclick="addExpense" label="Add" />
    </v-card-actions>
  </div>
</template>

<script>
import format from "@/services/format";
export default {
  props: {
    editSupplier: Object,
  },
  data() {
    return {
      expenseName: null,
      expense: null,
    };
  },
  methods: {
    addExpense() {
      this.$store
        .dispatch("FinanceCompileStore/addExtraExpenses", {
          booking_id: this.$store.getters["FinanceReportStore/booking_id"],
          data: {
            supplier_id: this.editSupplier.supplier_id,
            type_as: this.editSupplier.type_as,
            expense_type: "DEFAULT",
            label: this.expenseName,
            amount: this.expense,
          },
        })
        .then((v) => {
          this.$emit("close");
          this.$root.$success("Added Expense");
        })
        .catch((err) => this.$root.$error(err));
    },
  },
};
</script>
