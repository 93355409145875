<template>
  <v-card flat>
    <v-form @submit.prevent="updateSearch">
      <v-card-text class="py-0">
        <div class="caption grey lighten-2 py-1 px-3" v-html="searchResultInEnglish"></div>

        <GeneralSearchRow
          v-for="(group, i) in searchGroups"
          :key="group.tid"
          :mod="mod"
          :dbConfig="dbConfig"
          :group="group"
          :showClear="i !== 0 || searchGroups.length > 1"
          :cities="cities"
          :subCities="subCities"
          @change="(v) => adjustCheck(group, v)"
          @popGroup="popGroup(i)"
        />

        <div>
          <v-btn small text color="primary" @click="addGroup('AND')">+ AND</v-btn>
          <v-btn small text color="primary" @click="addGroup('OR')">+ OR</v-btn>
        </div>
      </v-card-text>

      <v-card-actions>
        <g-button label="Clear" @onclick="clearSearch" />
        <v-spacer />
        <search-button />
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import Vue from "vue";
import SEARCH from "@/config/fields/SEARCH";
import GeneralSearchRow from "./__GeneralSearchRow";

function makeGroup(type) {
  return {
    tid: new Date().getTime(),
    ...(type && { type }),
    value: "",
    columns: "",
  };
}

function defaultGroup(onlyName) {
  if (onlyName) {
    return [
      {
        tid: "b" + new Date().getTime(),
        value: "",
        columns: "name",
      },
    ];
  }

  return [
    {
      tid: "a" + new Date().getTime(),
      value: 1,
      columns: "active",
    },
    {
      tid: "b" + new Date().getTime(),
      type: "AND",
      value: "",
      columns: "name",
    },
  ];
}

export default {
  props: {
    isApplied: Boolean,
    mod: String,
    dbConfig: String,
  },
  components: {
    GeneralSearchRow,
  },
  data() {
    return {
      cities: [],
      subCities: [],
    };
  },
  computed: {
    source() {
      const data = SEARCH[this.dbConfig](this.mod);
      return Object.keys(data).map((key) => {
        return {
          value: key,
          ...data[key],
        };
      });
    },
    searchGroups: {
      get() {
        return this.$store.getters[`DatabaseStore/generalSearch`][this.dbConfig];
      },
      set(nuval) {
        this.$store.dispatch("DatabaseStore/updateSearch", {
          tab: "general",
          dbconfig: this.dbConfig,
          data: nuval,
        });
      },
    },
    searchResultInEnglish() {
      let english = "Find suppliers where...";

      this.searchGroups.forEach((item) => {
        if (item.type === "OR") english += `OR suppliers where...`;
        if (item.type === "AND") english += `and `;
        english += `${item.columns} is ${_valueToEnglish(item.value)} `;
      });

      return english;
    },
  },

  methods: {
    hasSource(columnName) {
      return !!this.source.find((item) => columnName === item.value && item.source);
    },
    dropdownSource(columnName) {
      const temp = this.source.find((item) => columnName === item.value);
      const env = this.$store.getters["AccountStore/environment"];
      if (temp.optionListSource) return this.$store.getters["ListStore/" + temp.optionListSource](env);
      return temp.source;
    },

    addGroup(type) {
      this.searchGroups.push(makeGroup(type));
    },
    popGroup(index) {
      this.searchGroups.splice(index, 1);
      this.searchGroups[0].type = null;
    },

    adjustCheck(group, proposed) {
      const existing = group.columns;
      Vue.set(group, proposed.key, proposed.value);
      if (proposed.key !== "columns") return;

      // Changing value
      if (["active"].includes(group.columns)) {
        // Set boolean to default (number)
        group.value = 1;
      } else {
        // Check source type
        const column = this.source.find((f) => f.value === proposed.value);
        const prev = this.source.find((f) => f.value === existing);
        // Clear if changing column unless text
        if (column.inputType === "TEXT" && prev.inputType === "TEXT") return;
        Vue.set(group, "value", null);
      }
    },

    clearSearch() {
      this.searchGroups = defaultGroup(this.dbConfig === "clients");

      // Clear filters + table if applied
      if (this.isApplied) {
        this.$emit("search", {});
        this.$store.dispatch("DatabaseStore/applySearch", {
          dbconfig: this.dbConfig,
        });
      }
    },
    updateSearch() {
      const search = {
        multiple: true,
        list: this.searchGroups,
      };

      this.$emit("search", search);
      this.$store.dispatch("DatabaseStore/applySearch", {
        tab: "general",
        dbconfig: this.dbConfig,
        data: search,
      });
    },
    queryCities() {
      return this.$store.dispatch("DatabaseStore/querySuppliers", { type: "ALL" }).then((data) => {
        this.cities = data.map((item) => item.city).filter((city) => city !== null && city !== undefined);
        this.subCities = data
          .map((item) => item.subcity)
          .filter((subcity) => subcity !== null && subcity !== undefined);
      });
    },
  },
  mounted() {
    // If empty list, add the initial search
    if (this.searchGroups.length === 0 || !this.isApplied) {
      this.searchGroups = defaultGroup(this.dbConfig === "clients");
    }
    this.queryCities();
  },
};

function _valueToEnglish(value) {
  if (Array.isArray(value)) return `(${value.join(" or ")})`;
  return value;
}
</script>
