<template>
  <div>
    <CreateSupplierBreadCrumbs activelabel="Mass Add" />

    <v-container fluid class="justify-center">
      <create-header label="Mass Add Suppliers" :goBackTo="goback" :disabled="!canCreate" :oncreate="create" />

      <div>
        <PasteExcelDialog :supplierType="base.supplier_type" @insert="(v) => (dataCells = v)" />
        <g-button label="Clear All" @onclick="reset" />
        <span class="mx-3 white--text" v-if="showMessage">-- {{ dataCells.length }} suppliers added</span>
      </div>
      <SupplierBaseRequirementForm :value="base" @input="(v) => (base[v.cell] = v.value)" />
      <SupplierMassAddForm
        :dataCells="dataCells"
        :supplierType="base.supplier_type"
        @insert="(v) => (dataCells = v)"
        @add="(v) => dataCells.push(v)"
        @remove="(v) => dataCells.pop()"
      />
    </v-container>
  </div>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import { CreateSupplierBreadCrumbs } from "@/modules/suppliers";
import {
  extractService,
  PasteExcelDialog,
  SupplierBaseRequirementForm,
  SupplierMassAddForm,
} from "@/modules/database.mass_add";
import ENV from "@/config/ENV";

export default {
  components: {
    CreateSupplierBreadCrumbs,
    PasteExcelDialog,
    SupplierBaseRequirementForm,
    SupplierMassAddForm,
  },
  data() {
    return {
      showMessage: false,
      base: {
        supplier_type: "",
        country: "",
        region: "",
        op_area: "",
      },
      dataCells: [
        {
          saved: false,
          supplier: {},
          meta: {},
          primary_contact: {},
        },
      ],
    };
  },
  computed: {
    goback() {
      return {
        name: "database_view",
        params: {
          view: "suppliers",
          ...(this.$route.query.type && { sub: this.$route.query.type }),
        },
      };
    },
    canCreate() {
      var c = this.base.supplier_type;
      // One type is specified, need country + region
      if (this.base.supplier_type)
        c = this.base.country && (this.base.region || this.base.supplier_type === "TOUR_GUIDE");
      return c;
    },
  },
  methods: {
    async create() {
      for (var i = this.dataCells.length - 1; i >= 0; i--) {
        let cell = this.dataCells[i];
        await this._createOneSupplier(cell);
      }

      this.showMessage = true;
    },
    _createOneSupplier(cell) {
      return this.$store
        .dispatch("DatabaseStore/createSupplier", {
          type: this.base.supplier_type,
          supplier: {
            ..._.omit(cell.supplier, ["business_number", "business_extension"]),
            business_number: {
              phone: cell.supplier.business_number,
              ext: cell.supplier.business_extension,
            },
            comments: cell.meta.comments,
            ...this.base,
            meta: extractService(this.base.supplier_type, this.base.country, cell.meta),
          },
          primary_contact: cell.primary_contact,
        })
        .then((v) => Vue.set(cell, "saved", true))
        .catch((err) => {
          Vue.set(cell, "error", true);
          this.$root.$error(err);
        });
    },

    reset() {
      this.dataCells = [
        {
          saved: false,
          supplier: {},
          meta: {},
          primary_contact: {},
        },
      ];
      this.showMessage = false;
    },
  },
  mounted() {
    const env = this.$store.getters["AccountStore/environment"];
    const countryMap = ENV.find((x) => x.value === env);

    var q = this.$route.query.type;
    this.base.supplier_type = q;
    this.base.country = countryMap.defaultCountry;
    // this.primary_contact.phone_number = { code: countryMap.areaCode };
  },
};
</script>
