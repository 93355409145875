import axios from "@/plugins/axios";

export default {
  getAllBookingDays(booking_id) {
    return axios.get(`/bookings/${booking_id}/days`);
  },
  getOneBookingDay(day_id) {
    return axios.get(`/booking_days/${day_id}`);
  },

  createBookingSupplier(booking_id, type_as, supplier, product_year) {
    return axios.post(`/booking_suppliers`, {
      booking_id,
      type_as,
      supplier,
      product_year,
    });
  },
  updateBookingDays(booking_id, data, historyBlob) {
    return axios.post(`/bookings/${booking_id}/days`, { days: data, historyBlob });
  },
  updateBookingDaySupplier(booking_supplier_id, data) {
    return axios.post(`/booking_suppliers/${booking_supplier_id}`, data);
  },
  removeBookingSupplier(booking_supplier_id, data) {
    return axios.delete(`/booking_suppliers/${booking_supplier_id}`, { data: data });
  },

  updateRoomingReceived(booking_supplier_id, value) {
    return axios.post(`/booking_suppliers/${booking_supplier_id}/rooming_received`, { value });
  },
};
