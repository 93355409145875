<template>
  <v-tooltip top left>
    <template v-slot:activator="{ on }">
      <div class="text-truncate" :class="{ 'subtitle-2': bold, 'font-italic': italic }" v-on="on" v-html="text"></div>
    </template>
    <span v-html="hoverText || text"></span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    bold: Boolean,
    italic: Boolean,
    text: String,
    hoverText: String,
  },
};
</script>
