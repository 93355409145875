import axios from "@/plugins/axios";

export default {
  getContact(id) {
    return axios.get(`/client_contacts/${id}`);
  },
  getContactList(client_id) {
    return axios.get(`/clients/${client_id}/contacts`);
  },

  addContact(client_id, contact) {
    return axios.post(`/clients/${client_id}/contacts`, contact);
  },
  updateContact(id, contact, original) {
    return axios.post(`/client_contacts/${id}`, { data: contact, cache: original });
  },
  deleteContactList(idList) {
    return axios.delete(`/client_contacts`, {
      data: { contact_list: idList },
    });
  },

  setPrimaryContact(client_id, contact_id, old_primary) {
    return axios.post(`/clients/${client_id}`, {
      data: { primary_contact_id: contact_id },
      cache: { primary_contact_id: old_primary },
    });
  },
};
