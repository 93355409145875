<template>
  <div>
    <v-row justify="end" class="ma-3">
      <g-button
        label="Upload"
        icon="cloud_upload"
        type="primary"
        :disabled="!(selectEnv && selectedDb && selectedModule && selectedFile)"
        @onclick="uploadCvs"
      />
    </v-row>

    <a-card>
      <a-select label="Environment" :items="ENV" v-model="selectEnv" />
      <a-select label="Database" :items="dbList" v-model="selectedDb" />
      <a-select
        :items="moduleList"
        dependant
        :dependantValue="selectedDb"
        dependantText="Select Database"
        label="Type"
        v-model="selectedModule"
      />
      <vue-excel-editor v-model="jsondata">
        <vue-excel-column field="user" label="User" />
        <vue-excel-column field="name" label="Name" />
        <vue-excel-column field="phone" label="Contact" />
        <vue-excel-column field="gender" label="Gender" />
        <vue-excel-column field="age" label="Age" />
        <vue-excel-column field="birth" label="Date Of Birth" />
      </vue-excel-editor>

      {{ jsondata }}
    </a-card>
  </div>
</template>

<script>
// DEBRICATED -- FILE EXTRACTION DOESN"T WORK ANYMROE
import ENV from "@/config/ENV";
import api from "../api";
import uploadService from "../service";
import { SuppliersMenu, ProductMenu } from "@/config/menus";

var MENUS = {
  PRODUCT: ProductMenu,
  SUPPLIER: SuppliersMenu.FULL,
};

export default {
  data() {
    return {
      ENV,
      dbList: [
        {
          label: "Suppliers",
          value: "SUPPLIER",
        },
        {
          label: "Products",
          value: "PRODUCT",
        },
      ],

      selectEnv: "",
      selectedDb: "",
      selectedModule: "",
      jsondata: [{}],
    };
  },
  computed: {
    moduleList() {
      let temp = MENUS[this.selectedDb];
      if (!temp) return [];

      let list = [];
      temp.forEach((item) =>
        list.push({
          label: item.title,
          value: item.module,
        })
      );

      return list;
    },
  },
  methods: {
    setFile(file) {
      const reader = new FileReader();
      const use = reader.readAsDataURL(file);

      this.selectedFile = file;
    },
    uploadCvs() {
      const filePath = this.selectedFile.path;
      const type = this.selectedFile.name.split(".").pop();

      if (type.includes("xls")) {
        this.$root.$loading.open("Extracting...");
        this.$root.$success("Upload in progress...");

        let parsed = uploadService.extractData(filePath);
        this.saveToDb(parsed);
      }
    },
    saveToDb(parsedData) {
      const SIZE = parsedData.size;
      const DATA = parsedData.data;
      let currentIndex = 0;

      this.$root.$loading.update(uploadService.message(0, SIZE));

      this.recurseGroupQuery(DATA, currentIndex, 0, SIZE);
    },

    recurseGroupQuery(data, currentIndex, total, size) {
      api
        .uploadToDb({
          env: this.selectEnv,
          db: this.selectedDb,
          type: this.selectedModule,
          data: data[currentIndex],
        })
        .then(() => {
          // End recursive loop here
          if (data.length === currentIndex + 1) {
            this.$root.$loading.end();
            this.$root.$success("Successfully uploaded data");
            return;
          }

          var totalDone = total + data[currentIndex].length;
          this.$root.$loading.update(uploadService.message(totalDone, size));

          // continue recursive loop
          this.recurseGroupQuery(data, currentIndex + 1, totalDone, size);
        })
        .catch((err) => {
          this.$root.$loading.end();
          this.$root.$error(err);
        });
    },
  },
};
</script>
