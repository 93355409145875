<template>
  <div>
    <elwrapper :label="label || '# of Rooms'" :class="{ 'warning--text': !matchesPax }">
      <v-menu open-on-hover :offset-x="!offmain" :offset-y="offmain" :top="offmain">
        <template v-slot:activator="{ on }">
          <v-text-field
            :disabled="disabled"
            :value="roomTotalDisplay"
            outlined
            hide-details
            dense
            readonly
            v-on="on"
            @click="dialog = true"
          ></v-text-field>
        </template>

        <div class="hover-menu" @click="dialog = true">
          <div>
            <span class="caption font-weight-bold">Breakdown</span>
          </div>
          <div v-for="room in reducedRooms" :key="room.value" v-show="room.count > 0">
            <span class="caption">{{ room.count }} - {{ room.label }}</span>
          </div>

          <div v-if="!est">
            <span v-if="usingRoominglist" class="caption font-italic">(per rooming list)</span>
            <span v-else-if="usingSupplierRoomingList" class="caption font-italic">(per supplier)</span>
            <span v-else class="caption font-italic">(per est)</span>
          </div>

          <div class="amber--text lighten-5 caption" v-if="!matchesPax">
            ** Warning: Rooming pax is less than total pax
          </div>
        </div>
      </v-menu>
    </elwrapper>

    <RoomNumberDialog
      v-model="dialog"
      :current="value"
      :extraTotal="specialRoomListCount"
      @update="(v) => $emit('input', v)"
    />
  </div>
</template>

<script>
import RoomNumberDialog from "./_RoomNumberDialog";
import BookingRoomService from "../../service";
import { BookingSupplierRoomService } from "@/modules/bookings.supplier_rooms";
import BOOKING_ROOMS from "@/config/BOOKING_ROOMS";
export default {
  props: {
    value: Object,
    label: String,
    pax: [Number, String],
    est: Boolean,
    offmain: Boolean,
    flattenSuppliers: Boolean,
    disabled: Boolean,
  },
  components: {
    RoomNumberDialog,
  },
  data() {
    return {
      dialog: false,
      roomBreakdownData: {},
    };
  },
  watch: {
    dialog(v) {
      this.syncRoomData();
    },
  },
  computed: {
    matchesPax() {
      if (!this.value) return true;
      // Get the total number of assigned rooms vs. pax
      var total = 0,
        temp;
      Object.keys(this.value).forEach((key) => {
        if (this.value[key]) total += Number(this.value[key]) * BOOKING_ROOMS.find((item) => item.value === key).pax;
      });

      return Number(this.pax || 0) <= total;
    },

    specialRoomList() {
      return BookingSupplierRoomService.getSupplierRoomFlatList({
        dayList: this.$store.getters["BookingDayStore/days"],
        bookingSupplierRooms: this.$store.getters["BookingSupplierRoomStore/supplierRoomsRef"],
      });
    },
    specialRoomListCount() {
      return this.specialRoomList.length;
    },

    roomTotal() {
      let useRoom = this.est ? this.value || {} : this.value || this.roomBreakdownData.roomingListBreakdown || {};

      var total = 0;
      Object.keys(useRoom).forEach((key) => {
        if (key === "SUPPLIER.TOUR_GUIDE" || key === "SUPPLIER.TRANSPORT") return;
        if (useRoom[key]) total += Number(useRoom[key]);
      });

      return total;
    },
    roomTotalExtra() {
      let useRoom = this.est ? this.value || {} : this.value || this.roomBreakdownData.roomingListBreakdown || {};
      let extra = 0;

      extra += Number(useRoom["SUPPLIER.TOUR_GUIDE"]) || 0;
      extra += Number(useRoom["SUPPLIER.TRANSPORT"]) || 0;

      return extra || this.specialRoomListCount;
    },
    roomTotalDisplay() {
      if (this.specialRoomListCount) return `${this.roomTotal}+${this.roomTotalExtra}`;
      return this.roomTotal;
    },
    reducedRooms() {
      let useRoom = this.value || this.roomBreakdownData.roomingListBreakdown || {};

      return BookingRoomService.reduceRooms(useRoom, this.specialRoomList);
    },
    usingRoominglist() {
      if (this.value) return false;
      // Have rooming list, but no value, then show rooming list here
      if (this.reducedRooms.length) return true;
      return false;
    },
    usingSupplierRoomingList() {
      if (this.value && this.offmain) return true;
      return false;
    },
  },
  methods: {
    syncRoomData() {
      this.roomBreakdownData = BookingRoomService.getRoomingListBreakdown({
        bookingMetaData: this.$store.getters["BookingStore/data"],
        bookingRoomingList: this.$store.getters["BookingRoomStore/rooms"],
        bookingDays: this.$store.getters["BookingDayStore/days"],
        supplierRoomList: this.$store.getters["BookingSupplierRoomStore/supplier_rooms"],
      });

      if (
        !this.$store.getters["BookingStore/data"]["room_num"] &&
        this.roomBreakdownData &&
        this.roomBreakdownData.roomingListBreakdown &&
        Object.keys(this.roomBreakdownData.roomingListBreakdown).length > 0
      ) {
        this.$store.dispatch("BookingStore/update", {
          deep: true,
          key: "room_num",
          val: this.roomBreakdownData.roomingListBreakdown,
        });
      }
    },
  },
  mounted() {
    this.syncRoomData();
    this.$roomingPing.$on("sync", this.syncRoomData);
  },
  beforeDestroy() {
    this.$roomingPing.$off("sync", this.syncRoomData);
  },
};
</script>
