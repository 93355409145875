<template>
  <v-card class="booking-group-datatable height">
    <v-row v-if="!rows.length" class="mx-0 px-3">
      <span v-if="query.status === null" class="caption">(Waiting for search input)</span>
      <span v-else class="caption">(No data found)</span>
    </v-row>

    <div :style="groupedTableStyle">
      <div v-for="row in filteredBookings" :key="row.id" class="booking-search-container">
        <v-row
          @click="goToPage(row)"
          class="booking-row-header px-0 grey darken-1 font-weight-bold caption elevation-1"
        >
          <v-col style="max-width: 140px">{{ row.booking.client_name }}</v-col>
          <v-col>{{ row.booking.group_name }}</v-col>
          <v-col class="font-weight-regular brh-hover" style="max-width: 50px">From: </v-col>
          <v-col class="brh-hover"><d-date :date="row.booking.from" /></v-col>
          <v-col class="font-weight-regular brh-hover" style="max-width: 30px">To: </v-col>
          <v-col class="brh-hover"><d-date :date="row.booking.to" /></v-col>
          <v-col class="font-weight-regular brh-hover" style="max-width: 50px">Start: </v-col>
          <v-col class="brh-hover">{{ row.booking.where_start }}</v-col>
          <v-col class="font-weight-regular brh-hover" style="max-width: 50px">End: </v-col>
          <v-col class="brh-hover">{{ row.booking.where_end }}</v-col>
        </v-row>
        <SupplierRowWrapper
          external
          v-for="supplier in row.displayRows"
          :key="supplier.key"
          :bookingId="row.booking_id"
          :supplier="supplier"
          :typeAs="supplier.type_as"
          :bookingExtraExpenses="row.extra_expenses"
          :supplierInvoice="row.supplier_invoices[supplier.key]"
          :supplierDelta="row.deltas[supplier.key]"
          :commissionReceivedHistory="row.commission_received_history"
          :customCommissionList="row.custom_commissions"
          @update="(v) => updateSupplierInvoice(row, v)"
          @pop-expense="(v) => popExpense(row, v)"
          @pop-invoice="popInvoice(row, supplier.supplier_id, supplier.type_as)"
          @sync-commission="(v) => syncCommission(row, supplier, v)"
          @toggle-commission-received="toggleCommissionReceived(row, supplier)"
        />

        <v-divider />
      </div>

      <infinite-loading spinner="waveDots" :identifier="identifyScrollRef" @infinite="infiniteHandler">
        <div slot="no-more">
          <div class="overline ma-2">(End)</div>
        </div>
        <div slot="no-results"></div>
      </infinite-loading>
    </div>
  </v-card>
</template>

<script>
import Vue from "vue";
import InfiniteLoading from "vue-infinite-loading";
import SUPMENU from "@/config/menus/suppliersMenu";
import service from "../../service";
import { ReportService } from "@/modules/finances.report";
import SupplierRowWrapper from "./supplier/_SupplierRowWrapper";
import _ from "lodash";
export default {
  props: {
    query: Object,
    height: [Number, String],
    showRecon: Array,
    showArchive: Array,
    showTotal: Boolean,
    splitTotal: Boolean,
  },
  components: {
    InfiniteLoading,
    SupplierRowWrapper,
  },
  data() {
    return {
      rows: [],
      loading: true,
      page: 0,

      firstRender: false, // Infinite component does first query
      identifyScrollRef: new Date().getTime(),
      completed: false,
    };
  },
  watch: {
    query(searchQuery) {
      if (this.queryActive) return;

      if (this.firstRender) {
        this.resetScrolling();
        this.$emit("setComplete", false);
        this.identifyScrollRef = new Date().getTime();
      }
    },
  },
  computed: {
    groupedTableStyle() {
      return {
        height: this.height,
        overflow: "auto",
      };
    },
    filteredBookings() {
      // Sorts out booking
      const filteredData = this.rows.filter((item) => {
        if (this.showArchive.includes("CANCELLED") && item.booking.status <= 0) return true;
        if (!this.showArchive.includes("CANCELLED") && item.booking.status <= 0) return false;

        if (!this.showArchive.includes("ARCHIVED") && item.booking.report_archived) return false;
        if (!this.showArchive.includes("ACTIVE") && !item.booking.report_archived) return false;
        if (!("reconciliation" in item)) return true;
        if (item.reconciliation === 0 && this.showRecon.includes("REC_NONE")) return true;
        if (item.reconciliation > 0 && item.reconciliation < 1 && this.showRecon.includes("REC_PART")) return true;
        if (item.reconciliation >= 1 && this.showRecon.includes("REC_ALL")) return true;
        return false;
      });

      this.$store.dispatch("FinanceStore/saveResult", filteredData);

      return filteredData;
    },
  },
  methods: {
    goToPage(item) {
      this.$router.push({
        name: "finance_report",
        params: {
          booking_id: item.booking_id,
        },
      });
    },

    updateSupplierInvoice(booking, sup) {
      let code = sup.supplier_id + sup.type_as;
      let invoice = booking.supplier_invoices[code];
      if (!invoice) {
        Vue.set(booking.supplier_invoices, code, sup.data);
      } else {
        Vue.set(booking.supplier_invoices, code, Object.assign(JSON.parse(JSON.stringify(invoice)), sup.data));
      }
    },
    popExpense(booking, expense_id) {
      booking.extra_expenses = booking.extra_expenses.filter((p) => p.id !== expense_id);
    },
    popInvoice(booking, supplier_id, type_as) {
      Vue.delete(booking.supplier_invoices, supplier_id + type_as);
    },
    syncCommission(booking, supplier, data) {
      // Need to Recalculate entire booking
      if (data.type === "UPDATE") {
        // Remove old commision if already here
        booking.custom_commissions = booking.custom_commissions.filter((v) => {
          return v.supplier_id === data.supplier_id && v.type_as === data.type_as;
        });
        booking.custom_commissions.push(data.data);
      } else if (data.type === "DELETE") {
        // Remove commision if removing commision
        booking.custom_commissions = booking.custom_commissions.filter((v) => v.id !== data.custom_commission_id);
      }

      Object.assign(booking, _buildBookingDisplay(booking, this.query, this.$store.getters));
    },
    toggleCommissionReceived(booking, supplier) {
      // This should have list of supplier_ids (type only hotel)

      console.log("NEEDS REFACTOR: use commission_received_history, not commission_received_idlist");

      // const list = booking.commission_received_idlist
      // const supplier_id = supplier.supplier_id

      // if(list.indexOf(supplier_id) > -1){
      //   booking.commission_received_idlist = list.filter(v => v !== supplier_id)
      // } else {
      //   booking.commission_received_idlist.push(supplier_id)
      // }

      // Object.assign(booking, _buildBookingDisplay(booking, this.query, this.$store.getters))
    },

    // MAIN GRID
    resetScrolling() {
      this.page = 0;
      this.rows = [];
      this.queryActive = true;
    },
    queryDatabase(searchQuery) {
      this.loading = true;

      return this.$store
        .dispatch("FinanceStore/getFinances", { page: this.page, query: searchQuery })
        .then((rows) => {
          this.loading = false;

          // First set of data
          if (!this.rows.length && rows.length) {
            this.rows = rows;
            return { rows: [], continue: true };
          }
          return { rows, continue: false };
        })
        .catch((err) => {
          this.loading = false;
          this.$root.$error(err);
        });
    },

    infiniteHandler($state) {
      this.queryDatabase(this.query)
        .then((data) => {
          this.firstRender = true;
          this.queryActive = false;

          if (data.rows.length || data.continue) {
            this.page += 1;
            this.rows.push(...data.rows);
            $state.loaded();

            // If less than 50, assume complete
            if (data.rows.length === 50 || data.continue) return;
          }

          this.completed = true;
          this.$emit("setComplete", true);
          $state.complete();
        })
        .then((n) => {
          let q = this.query;
          // Add compiled price stuff
          this.rows = this.rows
            .map((item) => {
              // COMPILE BOOKING PRICE CALCULATIONS
              let flattened = {
                ...item,
                // OVERWTIE HERE
                booking_suppliers: _.pickBy(item.booking_suppliers, (val, key) => {
                  // console.log(this.query)
                  let keep = true;
                  // Remove any not in specified supplier in search
                  if (q.supplier_id) keep = val.supplier_id === q.supplier_id;
                  if (q.supplier_type) keep = val.supplier_type === q.supplier_type;

                  return keep;
                }),
              };

              return _buildBookingDisplay(flattened, q, this.$store.getters);
            })
            .filter((row) => {
              // FILTER SUPLIER AND BOOKING THAT DON'T MATCH
              row.displayRows = (row.displayRows || []).filter((supplier) => {
                let keep = true;
                let tempsi = row.supplier_invoices[supplier.key];
                if (q.recondStatus !== null) {
                  keep = tempsi // Invoice has been set
                    ? q.recondStatus === tempsi.reconciled
                    : q.recondStatus === 0; // No supplier invoice has been set
                }
                return keep;
              });
              return row.displayRows.length;
            });
        })
        .catch((err) => {
          console.log(err);
          $state.error();
        });
    },
  },
};

function _buildBookingDisplay(booking, query, storeGetters) {
  let compiled = service.compileOneBooking(booking, storeGetters);
  let merge = Object.assign({}, booking, compiled);

  return {
    ...merge,
    deltas: ReportService.extractDeltas(compiled.supplierBreakdown, booking.supplier_invoices),
    displayRows: _flattenSupplierRows(merge),
  };
}

function _flattenSupplierRows(booking) {
  // Suppliers are grouped by hotel, restauant, etc.
  // Flatten them here
  let adjustedRows = [];

  SUPMENU.FULL.forEach((item) => {
    if (item.module === "ALL" || !booking.supplierBreakdown) return;
    adjustedRows.push(
      ...Object.values(booking.supplierBreakdown[item.module]).map((sup) => {
        return {
          key: sup.supplier_id + item.module,
          ...sup,
        };
      })
    );
  });

  return adjustedRows;
}
</script>
