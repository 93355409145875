<template>
  <div>
    <input type="file" id="listupload" class="d-none" @change="importFile" accept=".xlsx" />
    <g-button icon="cloud_upload" label="Import List" class="mr-2" @onclick="openFileUpload()" />
  </div>
</template>

<script>
import readXlsxFile from "read-excel-file";
import BOOKING_ROOMS from "@/config/BOOKING_ROOMS";
import IMPORT_ROOM_SCHEMA from "@/config/IMPORT_ROOM_SCHEMA";

export default {
  methods: {
    openFileUpload() {
      document.getElementById("listupload").click();
    },
    importFile(e) {
      if (!e.target.files.length) {
        return;
      }

      const file = e.target.files[0];
      readXlsxFile(file, { schema: IMPORT_ROOM_SCHEMA }).then(({ rows, errors }) => {
        if (errors.length != 0) {
          // display only the top error
          // could change in future if that is more convenient
          const error = errors[0];
          if (error.error === "required") {
            this.$root.$error(`A value for column '${error.column}' is required in row ${error.row}`);
          } else if (error.error === "invalid") {
            this.$root.$error(
              `Value of '${error.value}' in column '${error.column}', row ${error.row} is invalid. Reason: ${error.reason}`
            );
          } else {
            this.$root.$error("Unhandled import error");
            console.log(error);
          }
          return;
        }

        try {
          const rooms = this.validateRooms(rows);
          this.addRooms(rooms);
        } catch (error) {
          this.$root.$error(error.message);
        }
      });
    },
    addRooms(rooms) {
      const roomsTypes = BOOKING_ROOMS.reduce((obj, item) => {
        return {
          ...obj,
          [item["print"]]: item,
        };
      }, {});

      rooms.forEach((room) => {
        this.$store.dispatch("BookingRoomStore/addRoom", {
          type: roomsTypes[room.room_type].value,
          pax: room.people.length,
          nameList: room.people.map((p) => {
            return {
              first: p.first_name,
              last: p.last_name,
              ...(p.adult_or_child && {
                about: {
                  age: p.child_age || "",
                  type: p.adult_or_child.toUpperCase(),
                },
              }),
            };
          }),
          meta: {
            ...(room.requests && { request: room.requests }),
          },
        });
      });
    },
    validateRooms(rows) {
      /*
            Schema: 
            room_number: {
                room_type: String,
                people: {
                    first_name: String,
                    last_name: String,
                    adult_or_child: String,
                    child_age?: Number,
                }[],
                requests: String
            }
            */
      const rooms = {};
      const validPersonType = new Set(["Adult", "Child"]);
      const validRoomTypes = new Set(BOOKING_ROOMS.map((room) => room.print));

      const toTitleCase = (str) => {
        return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
      };

      /* validate rooms
       * checks:
       * Valid room type
       * Valid adult / child value
       * Room types are equal for same room #s
       * Compiles requests together. Separated by new lines
       */

      for (let i = 0; i < rows.length; i++) {
        const room = rows[i];
        // format fields
        room["room_type"] = toTitleCase(room["room_type"].trim());
        room["first_name"] = toTitleCase(room["first_name"] || "");
        room["last_name"] = toTitleCase(room["last_name"] || "");

        if (!validRoomTypes.has(room["room_type"])) {
          throw new Error(`Invalid room type: '${room["room_type"]}'`);
        }

        // not a required field so check it exists first
        if (room["adult_or_child"]) {
          room["adult_or_child"] = toTitleCase(room["adult_or_child"].trim());
          if (!validPersonType.has(room["adult_or_child"])) {
            throw new Error(`Invalid value for 'Adult / Child' column: '${room["adult_or_child"]}'`);
          }
        }

        if (rooms[room["room_number"]]) {
          if (rooms[room["room_number"]]["room_type"] !== room["room_type"]) {
            throw new Error(
              `Room #${room["room_number"]} cannot be of type` +
                ` '${rooms[room["room_number"]]["room_type"]}'` +
                ` and '${room["room_type"]}'`
            );
          }

          rooms[room["room_number"]]["people"].push({
            first_name: room["first_name"],
            last_name: room["last_name"],
            adult_or_child: room["adult_or_child"],
            ...(room["adult_or_child"] === "Child" && { child_age: room["child_age"] }),
          });
          // use || because 'undefined' is auto for a null string
          if (/\S/.test(room["requests"] || "")) {
            rooms[room["room_number"]].requests += `\n${room["requests"]}`;
          }
        } else {
          rooms[room["room_number"]] = {
            room_type: room["room_type"],
            people: [
              {
                first_name: room["first_name"],
                last_name: room["last_name"],
                adult_or_child: room["adult_or_child"],
                ...(room["adult_or_child"] === "Child" && { child_age: room["child_age"] }),
              },
            ],
            requests: /\S/.test(room["requests"]) ? room["requests"] : "",
          };
        }
      }
      return Object.values(rooms);
    },
  },
};
</script>
