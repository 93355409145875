<template>
  <g-button class="mx-2" label="Export" icon="get_app" :loading="loading" @onclick="exportData" />
</template>

<script>
import PhoneService from "@/components/form/phones/phoneService";
import PHONE_MASK from "@/config/PHONE_MASK";
import { createObjectCsvWriter } from "csv-writer";
export default {
  props: {
    searchQuery: Object,
    dbSource: String,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    exportData() {
      this.loading = true;
      return this.$store
        .dispatch("DatabaseStore/querySuppliers", {
          type: this.dbSource,
          query: this.searchQuery,
          page: 0,
          ignoreInfinite: true,
          attachContact: true,
        })
        .then((rows) => {
          this.loading = false;

          let base = ["City", "Name", "Email", "Phone", "Primary Contact"];
          let csvRows = rows.map((item) => {
            // Phone Fix
            if (item.business_number && typeof item.business_number === "string" && item.business_number[0] === "{") {
              item.business_number = JSON.parse(item.business_number);
            }
            if (
              item.contact.phone_number &&
              typeof item.contact.phone_number === "string" &&
              item.contact.phone_number[0] === "{"
            ) {
              item.contact.phone_number = JSON.parse(item.contact.phone_number);
            }

            const number = PhoneService.phoneDisplay(
              item.business_number || item.contact.phone_number,
              PHONE_MASK("US")[0],
              "US"
            );

            return Object.values({
              city: `${this.clean(item.city || "")}${item.subcity ? "(" + this.clean(item.subcity) + ")" : ""}`,
              name: this.clean(item.name),
              email: this.clean(item.contact.email),
              phone: number ? this.clean(number.phone) : "",
              primary_contact: this.clean(item.contact.name),
            });
          });

          let csvContent = "data:text/csv;charset=utf-8," + [base, ...csvRows].map((e) => e.join(",")).join("\n");

          var encodedUri = encodeURI(csvContent);
          var link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", `suppliers-${new Date().getTime()}.csv`);
          document.body.appendChild(link); // Required for FF

          link.click(); // This will download the data file named "my_data.csv".
        })
        .catch((err) => {
          this.loading = false;
          this.$root.$error(err);
        });
    },
    clean(str) {
      // Remove special characters that might break csv
      if (!str) return "";
      return str.replace(/[#,'"]/g, "");
    },
  },
};
</script>
