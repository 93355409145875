import api from "./api";

const namespaced = true;

const SET_LIST = "SET_LIST";

const state = {
  OPERATING_AREA: [],
  RESTAURANT_STYLE: [],
  BREAKFAST: [],
};

const mutations = {
  SET_LIST(state, data) {
    state[data.refkey] = data.data;
  },
};

const actions = {
  getOptionList({ commit }, refkey) {
    return api
      .getOptionList(refkey)
      .then((data) => {
        commit(SET_LIST, { refkey, data: data.data });
      })
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  updateOptionList({ commit }, { refkey, data }) {
    return api.updateList(refkey, data).catch((err) => {
      if (err) throw err.data;
    });
  },
  getAndSyncOptionList({ commit }, refkey) {
    return api
      .syncRawOptionsList(refkey)
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
};

const getters = {
  OPERATING_AREA: (state) => (env) => {
    const source = state.OPERATING_AREA;
    return _extract(source, env);
  },
  RESTAURANT_STYLE: (state) => (env) => {
    const source = state.RESTAURANT_STYLE;
    return _extract(source, env);
  },
  BREAKFAST: (state) => (env) => {
    const source = state.BREAKFAST;
    return _extract(source, env);
  },
};

function _extract(source, env) {
  return Object.keys(source)
    .filter((key) => (source[key] || []).includes(env || "USA"))
    .sort((a, b) => {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    })
    .map((key) => {
      return {
        label: key,
        value: key,
      };
    });
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
