import api from "./api";

const namespaced = true;

const actions = {
  getOneAccount({ commit }, account_id) {
    return api
      .getOneAccount(account_id)
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  getAccountList({ commit }) {
    return api
      .getAccountList()
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  createAccount({ commit }, data) {
    return api.createAccount(data).catch((err) => {
      if (err) throw err.data;
    });
  },
  updateAccountRole({ commit }, data) {
    return api.updateAccountRole(data.account_id, data).catch((err) => {
      if (err) throw err.data;
    });
  },
  deleteAccount({ commit }, account_id) {
    return api.deleteAccount(account_id).catch((err) => {
      if (err) throw err.data;
    });
  },
  generatePassword({ commit }, { accountId, email }) {
    return api
      .generatePassword(accountId, email)
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },

  getAccountEmailList({ commit }, accountId) {
    return api
      .getAccountEmailList(accountId)
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
};

export default {
  namespaced,
  actions,
};
