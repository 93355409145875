<template>
  <a-group icon="event" :label="label">
    <ADate
      maxwidth
      :datewall="datewall"
      :datewallEnd="datewallEnd"
      :label="range || rangeAlways ? 'From' : 'On'"
      :value="value.from"
      @input="(v) => set('from', v)"
    />

    <ADate
      v-if="range || rangeAlways"
      :datewall="secondWall"
      :datewallEnd="datewallEnd"
      maxwidth
      label="To"
      :value="value.to"
      @input="(v) => set('to', v)"
    />

    <div class="d-flex align-center mr-3" v-if="!rangeAlways">
      <input type="checkbox" v-model="range" @change="setDate" />
      <span class="caption ml-2">Range</span>
    </div>
  </a-group>
</template>

<script>
import ADate from "./ADate";
export default {
  components: {
    ADate,
  },
  props: {
    value: Object,
    label: String,
    datewall: [String, Date],
    datewallEnd: [String, Date],
    daycount: Boolean,

    rangeAlways: Boolean,
  },
  data() {
    return {
      range: false,
      until: "",
    };
  },
  computed: {
    secondWall() {
      return this.value && this.value.from ? this.value.from : null;
    },
  },
  methods: {
    set(key, val) {
      let t = this.value ? { ...this.value } : {};
      this.$emit("input", {
        from: key === "from" ? val : t.from,
        to: this.range || this.rangeAlways ? (key === "to" ? val : t.to) : null,
        range: this.range || this.rangeAlways,
      });
    },
    setDate() {
      let t = this.value ? { ...this.value } : {};
      if (this.range || this.rangeAlways) t.to = this.value.from;
      if (!this.range) t.to = null;
      this.$emit("input", t);
    },
  },
};
</script>
