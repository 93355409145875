import fetchCurrency from "./fetchCurrency";

const UPDATE_RATE = "UPDATE_RATE";

const namespaced = true;

const state = {
  exchangeRate: {
    USD_JPY: "",
    JPY_USD: "",
    USD_CAD: "",
    CAD_USD: "",
  },
};

const mutations = {
  UPDATE_RATE(state, data) {
    state.exchangeRate = data;
  },
};

const actions = {
  fetchRates({ commit }) {
    const rates = {
      USD_JPY: "",
      JPY_USD: "",
      USD_CAD: "",
      CAD_USD: "",
    };
    return fetchCurrency
      .fetch(["CAD", "JPY"])
      .then((response) => {
        const data = response.data;
        rates.USD_JPY = data["JPY"];
        rates.JPY_USD = 1 / data["JPY"];
        rates.USD_CAD = data["CAD"];
        rates.CAD_USD = 1 / data["CAD"];
        commit(UPDATE_RATE, rates);
      })
      .catch((err) => {
        if (err) throw err.data;
      });
  },
};

const getters = {
  USD_JPY: (state) => (d) => _conv(state, d, "USD_JPY").toFixed(0),
  JPY_USD: (state) => (d) => _conv(state, d, "JPY_USD").toFixed(2),

  USD_CAD: (state) => (d) => _conv(state, d, "USD_CAD").toFixed(2),
  CAD_USD: (state) => (d) => _conv(state, d, "CAD_USD").toFixed(2),
};

function _conv(state, d, key) {
  return Number(parseFloat(d) * state.exchangeRate[key]);
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
