<template>
  <tr>
    <td>
      <span>{{ displayEnvironment }}</span>
    </td>
    <td>
      <span>{{ displayCountry }}</span>
    </td>
    <td>{{ item.subjur }}</td>
    <td>{{ item.city }}</td>
    <td>{{ item.subcity }}</td>
    <td>{{ displayDatabase }}</td>
    <td>{{ displayDatabaseType }}</td>
    <td>{{ displayField }}</td>
    <td>{{ displayValue }}</td>
  </tr>
</template>

<script>
import ENV from "@/config/ENV";
import PLACES from "@/config/fields/PLACES";
import { SuppliersMenu } from "@/config/menus";
import { ProductMenu } from "@/config/menus";
import { formConfig } from "@/modules/suppliers.information";
import EXTRA_OPTIONS from "@/config/products/EXTRA_OPTIONS";
export default {
  props: {
    item: Object,
  },
  computed: {
    displayEnvironment() {
      if (!this.item.env) return "";
      return ENV.find((v) => this.item.env === v.value).label;
    },
    displayCountry() {
      if (!this.item.country) return "";
      return PLACES.COUNTRY_LIST.WORLD.find((v) => this.item.country === v.value).label;
    },
    displayDatabase() {
      if (!this.item.db) return "";
      return {
        suppliers: "Suppliers",
        products: "Products",
      }[this.item.db];
    },
    displayDatabaseType() {
      const base = this.item.db;
      if (!(base && this.item.databaseType)) return "";
      const options = base === "suppliers" ? SuppliersMenu.FULL : base === "products" ? ProductMenu : [];

      return options.find((v) => v.module === this.item.databaseType).title;
    },
    displayField() {
      const base = this.item.db;
      if (!base || !this.item.databaseType) return "";
      const field =
        {
          suppliers: formConfig,
          products: { [this.item.databaseType]: EXTRA_OPTIONS },
        }[base][this.item.databaseType] || {};

      if (!this.item.field) return "";
      return (field.meta || field || {})[this.item.field].label;
    },
    displayValue() {
      let value = this.item.value;
      if (typeof value === "object") {
        return `${value.value} ${value.text ? "(" + value.text + ")" : ""}`;
      } else {
        return value;
      }
    },
  },
};
</script>
