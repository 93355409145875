import api from "./api";

const namespaced = true;

const state = {};

const mutations = {};

const actions = {
  getContact(v, id) {
    return api
      .getContact(id)
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  getContactList(v, id) {
    return api
      .getContactList(id)
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },

  addContact({ commit }, { client_id, contact }) {
    return api
      .addContact(client_id, contact)
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  updateContact({ commit }, { id, contact, original }) {
    return api
      .updateContact(id, contact, JSON.parse(original))
      .then((data) => contact)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  deleteContactList({ commit }, idList) {
    return api.deleteContactList(idList).catch((err) => {
      if (err) throw err.data;
    });
  },

  setPrimaryContact(v, { client_id, contact_id, old_primary }) {
    return api
      .setPrimaryContact(client_id, contact_id, old_primary)
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
};

const getters = {};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
