import HistoryView from "./HistoryView";
import NewNote from "./NewNote";
import EditNote from "./EditNote";

export default [
  {
    path: "history",
    name: "suppliers_history",
    component: HistoryView,
    meta: {
      toolbar: "supplier",
      tabAccess: "DATABASE",
    },
  },
  {
    path: "history/add_note",
    name: "suppliers_history_addnote",
    component: NewNote,
    meta: {
      toolbar: "supplier",
      tabAccess: "DATABASE",
    },
  },
  {
    path: "history/edit_note/:note_id",
    name: "suppliers_history_editnote",
    component: EditNote,
    meta: {
      toolbar: "supplier",
      tabAccess: "DATABASE",
    },
  },
];
