<template>
  <v-col>
    <button-control-header
      :menu-items="menuItems"
      @add="$router.push({ name: 'clients_contacts_new' })"
      @setmode="setMode"
      :ondelete="deleteContacts"
      :onsave="onSave"
    />

    <CheckboxWrapper
      v-for="(item, index) in contactList"
      :key="item.id"
      :value="item.id"
      cols="12"
      :show-check="showChecks"
      :checklist="deleteGroup"
      @toggle="toggleContact"
      :single="selectSingleOnly"
      :selected="primaryContactId"
      @select="selectContact"
    >
      <ContactCard :contact="item" :primary="primaryContactId === item.id" @goto="goto" />
    </CheckboxWrapper>

    <d-no-display v-if="contactList.length === 0" label="(No contacts to display)" />
  </v-col>
</template>

<script>
import { ContactCard } from "@/modules/contacts";

export default {
  components: {
    ContactCard,
  },
  data() {
    var vm = this;
    return {
      menuItems: [
        {
          title: "Change Primary",
          showMode: "SAVE",
          onClick: () => {
            vm.selectSingleOnly = true;
            vm.saveMode = "PRIMARY";
          },
        },
        {
          title: "Delete",
          showMode: "DELETE",
          onClick: () => (vm.selectSingleOnly = false),
        },
      ],

      showChecks: false,
      selectSingleOnly: false,

      primaryContactId: null,
      deleteGroup: [],
    };
  },
  computed: {
    clientId() {
      return this.$store.getters["ClientStore/client_id"];
    },
    contactList() {
      return this.$store.getters["ClientStore/all_contacts"];
    },
  },
  methods: {
    goto(contact_id) {
      this.$router.push({
        name: "clients_contacts_edit",
        params: {
          contact_id: contact_id,
        },
      });
    },
    setMode(mode) {
      let fn = {
        DEFAULT: function (vm) {
          vm.showChecks = false;
          vm.deleteGroup = [];
        },
        ADD: function (vm) {
          vm.saveMode = mode;
        },
        DELETE: function (vm) {
          vm.showChecks = true;
        },
        SAVE: function (vm) {
          vm.showChecks = true;
        },
      };

      fn[mode](this);
    },

    selectContact({ select, value }) {
      this.primaryContactId = value;
    },
    toggleContact({ check, value }) {
      // Add to delete list group if checked
      // Remove from list group if not checked
      if (check) {
        this.deleteGroup.push(value);
      } else {
        var index = this.deleteGroup.findIndex((x) => x.id === value);
        this.deleteGroup.splice(index, 1);
      }
    },

    onSave() {
      if (this.saveMode === "PRIMARY") return this.savePrimary();
    },
    savePrimary() {
      // Save
      // This should return a promise as used to delete primary contact as well
      return this.$store
        .dispatch("ClientContactStore/setPrimaryContact", {
          client_id: this.clientId,
          contact_id: this.primaryContactId,
          old_primary: this.cachedPrimaryContactId,
        })
        .then((v) => {
          // Update client store
          this.$store.dispatch("ClientStore/changePrimary", this.primaryContactId);
          this.$root.$success("Updated primary contact");
        })
        .catch((err) => this.$store.$error(err));
    },

    // DELETE
    deletePrimary() {
      if (this.deleteGroup.includes(this.primaryContactId)) {
        this.primaryContactId = null;
        return this.savePrimary();
      }

      return new Promise((resolve, rej) => resolve());
    },
    deleteContacts() {
      return this.$store
        .dispatch("ClientContactStore/deleteContactList", this.deleteGroup)
        .then((v) => {
          this.$store.dispatch("ClientStore/removeContacts", this.deleteGroup);
          return this.deletePrimary();
        })
        .then((v) => {
          this.deleteGroup = [];
          this.$root.$success("Deleted contacts");
        })
        .catch((err) => this.$store.$error(err));
    },
  },
  mounted() {
    this.primaryContactId = this.$store.getters["ClientStore/primary_contact"].id;
    this.cachedPrimaryContactId = this.primaryContactId;
  },
};
</script>
