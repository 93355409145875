import { render, staticRenderFns } from "./ClientsCreate.vue?vue&type=template&id=6a4cb702"
import script from "./ClientsCreate.vue?vue&type=script&lang=js"
export * from "./ClientsCreate.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports