<template>
  <div>
    <v-row class="grey lighten-2 mt-2">
      <v-col class="subtitle-2" cols="3" align-self="center"> TOTAL </v-col>

      <v-col class="d-flex justify-end">
        <div class="d-flex align-center pr-1">{{ formatedTotal }}</div>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="subtitle-2" align-self="center"> Paid </v-col>

      <v-col class="d-flex justify-end">
        <div class="d-flex align-center pr-1">{{ formatedPaid }}</div>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="subtitle-2" align-self="center"> Unpaid </v-col>

      <v-col class="d-flex justify-end">
        <div class="d-flex align-center pr-1">{{ formatedRemainingTotal }}</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import money from "@/services/money";
import format from "@/services/format";
export default {
  props: {
    remainingTotal: [String, Number],
    paidAmount: [String, Number],
    totalInvoice: [String, Number],
  },
  computed: {
    formatedRemainingTotal() {
      return this.remainingTotal ? `$ ${money.format(this.remainingTotal, { precision: 2 })}` : "$ --";
    },
    formatedPaid() {
      return this.paidAmount ? `$ ${money.format(this.paidAmount, { precision: 2 })}` : "$ --";
    },
    formatedTotal() {
      return this.totalInvoice ? `$ ${money.format(this.totalInvoice, { precision: 2 })}` : "$ --";
    },
  },
};
</script>
