<template>
  <div>
    <a-hover-menu>
      <template v-slot:activator>
        <div class="text-truncate">{{ text }}</div>
      </template>
      <div class="caption">
        <div v-for="(product, pindex) in displayList" :key="'prod' + pindex">
          <div v-if="multi" :class="getProductNameClass()">
            {{ product.productName }}
          </div>
          <v-row v-for="(item, index) in product.data" :key="index" class="pa-0">
            <v-col :class="getLabelClass()" style="width: 150px">{{ item.label }}</v-col>
            <v-col :class="getValueClassByKey(item, product.data)">{{ item.displayValue }}</v-col>
          </v-row>
        </div>
      </div>
    </a-hover-menu>
  </div>
</template>

<script>
import moneyService from "@/services/money";

export default {
  props: {
    text: String,
    original: [String, Array],
    multi: Boolean,
    quotePrice: String,
  },
  computed: {
    displayList() {
      if (this.multi) {
        return this.original.map((product) => ({
          productName: product.hoverName,
          data: this.compileProduct(product.rate, product.quotePrice),
        }));
      } else {
        const data = this.compileProduct(this.original, this.quotePrice);
        return [{ data }];
      }
    },
  },
  methods: {
    compileProduct(bookingPrice, quotePrice) {
      const prices = [];
      if (quotePrice != null) {
        prices.push({ key: "quote", label: "Quote price", displayValue: quotePrice });
      }
      prices.push({ key: "product", label: "Product price", displayValue: bookingPrice });
      return prices;
    },
    getProductNameClass() {
      return "text-decoration-underline";
    },
    getLabelClass() {
      return "d-flex flex-nowrap pa-0";
    },
    getValueClassByKey(item, data) {
      const { key } = item;

      if (key !== "product") return "d-flex flex-nowrap pa-0";

      const quote = data.find((value) => value.key === "quote");
      const product = data.find((value) => value.key === "product");

      if (!quote) return "d-flex flex-nowrap pa-0";

      const workingEnv = this.$store.getters["AccountStore/environment"];
      const formatQuote = moneyService.stripFormatting(quote.displayValue, workingEnv);
      const formatProduct = moneyService.stripFormatting(product.displayValue, workingEnv);

      const isProductHigher = formatProduct > formatQuote;
      const textColorClass = isProductHigher ? "lightred-text" : "lightgreen-text";

      return `d-flex flex-nowrap pa-0 font-weight-bold ${textColorClass}`;
    },
  },
};
</script>
