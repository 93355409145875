<template>
  <div>
    <a-hover-menu>
      <template v-slot:activator>
        <div class="text-truncate">{{ displayText }}</div>
      </template>
      <div class="caption">
        <v-row class="pa-0">
          <v-col class="d-flex flex-nowrap pa-0">{{ displayPrice }}</v-col>
        </v-row>
      </div>
    </a-hover-menu>
  </div>
</template>

<script>
export default {
  props: {
    porterage: Object,
  },
  computed: {
    displayText() {
      const price = this.porterage?.value;
      let text = "No porterage";
      if (price !== undefined && price !== null) {
        switch (this.porterage.text) {
          case "mandatory":
            text = "+" + price + " port. (mand)";
            break;
          case "optional":
            text = "+" + price + " port. (opt)";
            break;
          case "not offered":
            text = "No porterage";
            break;
          default:
            break;
        }
      }
      return text;
    },
    displayPrice() {
      const price = this.porterage?.value;
      let text = "No porterage";
      if (price !== undefined && price !== null) {
        switch (this.porterage.text) {
          case "mandatory":
            text = "+ " + price + ", mandatory porterage";
            break;
          case "optional":
            text = "+ " + price + ", optional porterage";
            break;
          case "not offered":
            text = "No porterage";
            break;
          default:
            break;
        }
      }
      return text;
    },
  },
};
</script>
