<template>
  <div class="pa-1" :class="{ highlight: highlightedRows[rawDate + focusBsid] }" @click="selectRowToggle">
    <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
    <slot></slot>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  props: {
    mode: String,
    asrooming: Boolean,
    module: String,

    selectedBsid: String,
    dateList: Array,
    highlightedRows: Object,
    supplierId: String,

    rawDate: String,
    focusBsid: String,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    bsidOnDate() {
      return this.$store.getters["BookingDayStore/allSuppliersOnDay"](this.rawDate, this.module, this.supplierId);
    },
  },
  methods: {
    selectRowToggle(e) {
      if (this.loading) return;

      const KEY = this.rawDate + this.focusBsid;

      if (e.ctrlKey || e.metaKey) {
        if (this.module === "RESTAURANT") return;
        const already = !!this.highlightedRows[KEY];
        Vue.set(this.highlightedRows, KEY, !already);

        if (!this.bsidOnDate.includes(this.rawDate)) {
          if (this.asrooming) return this.addSupplierRoomDay([this.rawDate]);

          return this.addProductDay([this.rawDate]);
        }
      } else if (e.shiftKey) {
        // NOTE: This will disabled the shift function when the modal is at the meal menu
        if (this.module === "RESTAURANT") return;
        const nu = this.dateList.indexOf(this.rawDate);
        const reference = this.dateList.findIndex((date) => this.highlightedRows[KEY]);
        const topdown = reference < nu;

        let dateToAdd = [];
        let floatingSet = {};
        this.dateList.forEach((date, index) => {
          let subkey = date + this.focusBsid;
          if (topdown && reference <= index && index <= nu) {
            Vue.set(floatingSet, subkey, true);
            if (!this.bsidOnDate.includes(this.rawDate)) dateToAdd.push(date);
            // Vue.set(this.productMapping, date, this.selectedRow)
          } else if (nu <= index && index <= reference) {
            Vue.set(floatingSet, subkey, true);
            if (!this.bsidOnDate.includes(this.rawDate)) dateToAdd.push(date);
          }
        });

        this.$emit("set", floatingSet);
        if (this.asrooming) return this.addSupplierRoomDay(dateToAdd);
        this.addProductDay(dateToAdd);
      } else {
        let multi = Object.values(this.highlightedRows).filter((v) => v).length;
        this.$emit("set", multi > 1 || multi === 0 ? { [KEY]: true } : {});
        this.$emit("mark", this.focusBsid);
      }
    },
    addProductDay(dateList) {
      if (!this.selectedBsid) return;

      const TARGET = {
        BOOKING: "BookingDayStore/assignBookingSupplierToDay",
        QUOTE: "QuoteStore/assignBookingSupplierToDay",
      }[this.mode];
      let CORE_ID = {
        BOOKING: { booking_id: this.$store.getters["BookingStore/booking_id"] },
        QUOTE: { quote_id: this.$store.getters["QuoteStore/quote_id"] },
      }[this.mode];

      this.loading = true;
      this.$store
        .dispatch(TARGET, {
          ...CORE_ID,
          bookingSupplierId: this.selectedBsid,
          type_as: this.module,
          dateList,
        })
        .then(() => {
          this.loading = false;
          this.$root.$success("Updated Supplier");
        })
        .catch((err) => {
          this.loading = false;
          this.$root.$error(err);
        });
    },
    addSupplierRoomDay(dateList) {
      if (!this.selectedBsid) return;

      let CORE_ID = {
        BOOKING: { booking_id: this.$store.getters["BookingStore/booking_id"] },
        QUOTE: { quote_id: this.$store.getters["QuoteStore/quote_id"] },
      }[this.mode];

      this.$store
        .dispatch("BookingDayStore/addSupplierRoomToDate", {
          ...CORE_ID,
          supplierRoomIdList: [this.selectedBsid],
          dateList: dateList,
        })
        .then(() => this.$root.$success("Updated supplier room"))
        .catch((err) => {
          this.$root.$error(err);
        });
    },
  },
};
</script>
