import PLACES from "../PLACES";
import SUPMENU from "../../menus/suppliersMenu";
import ENVMENU from "../../menus/environmentsMenu";
import CLIENT_EXTRA from "../client/extra";

export default {
  pid: {
    label: "ID",
    description: "Supplier ID",
    inputType: "NO_EDIT",
  },
  name: {
    label: "Name",
    description: "Supplier name",
    inputType: "TEXT",
  },
  en_name: {
    label: "(English)",
    inputType: "TEXT",
  },

  // TYPE STUFF
  search_types: {
    label: "Searchable",
    inputType: "DROPDOWN",
    multiple: true,
    getSource(env, country, type) {
      return SUPMENU.FULL.filter((item) => {
        if (["ALL", type].includes(item.module)) return false;
        return true;
      }).map((item) => {
        return {
          label: item.title,
          value: item.module,
        };
      });
    },
  },
  search_env: {
    label: "Environment",
    inputType: "DROPDOWN",
    multiple: true,
    getSource(env) {
      return ENVMENU.FULL.filter((item) => {
        if (item.module == env) {
          return false;
        }
        return true;
      }).map((item) => {
        return {
          label: item.module,
          value: item.module,
        };
      });
    },
  },

  // LOCATION
  country: {
    label: "Country",
    inputType: "DROPDOWN",
    getSource(env, country, type) {
      if (type === "TOUR_GUIDE") return PLACES.COUNTRY_LIST.TOUR[env];
      return PLACES.COUNTRY_LIST.DEFAULT[env];
    },
  },
  subjur: {
    label: "State/Pref/Prov",
    dependant: true,
    dependantText: "Please select country",
    getLabel: function (env, country) {
      if (country === "JP") return "Prefecture";
      if (country === "US") return "State";
      return "Province";
    },
    inputType: "DROPDOWN",
    noSource: " ",
    getSource: function (env, country) {
      return PLACES.SUBJUR[country] || PLACES.SUBJUR.ANY(country);
    },
  },
  city: {
    label: "City",
    inputType: "TEXT",
  },
  subcity: {
    label: "Subcity",
    inputType: "TEXT",
  },
  region: {
    label: "Region",
    inputType: "DROPDOWN",
    dependant: true,
    dependantText: "Please select country",
    getSource: function (env, country) {
      if (!country) return [];
      return PLACES.REGIONS[country].REGIONS;
    },
  },
  op_area: {
    label: "Operating Area",
    inputType: "DROPDOWN",
    multiple: true,
    getOptionListSource: "OPERATING_AREA",
  },
  zip: {
    label: "Zip",
    inputType: "TEXT",
  },
  address: {
    label: "Address",
    inputType: "TEXTAREA",
  },
  active: {
    label: "Active",
    offLabel: "Inactive",
    inputType: "BOOLEAN",
    searchHint: 'Active search: Please enter "Active" or "Inactive"',
  },
  favorite: {
    label: "Favorite",
    inputType: "BOOLEAN",
  },

  // CONTACT
  contact_name: {
    label: "Contact",
    description: "Main person of contact",
    inputType: "TEXT",
  },
  contact_title: {
    label: "Title",
    description: "Main person of contact",
    inputType: "TEXT",
  },
  phone_number: {
    icon: "phone",
    description: "Office Number / Extension",
    inputType: "PHONE",
    noSearch: true,
  },
  fax_number: {
    icon: "print",
    description: "Fax Number",
    inputType: "PHONE",
    noSearch: true,
  },
  email: {
    icon: "mail",
    description: "Email Address",
    inputType: "TEXT",
    noSearch: true,
  },
  website: {
    icon: "http",
    description: "Website",
    inputType: "TEXT",
    noSearch: true,
  },

  // COMMENTS
  comments: {
    label: "Comments",
    inputType: "TEXTAREA",
    noSearch: true,
    bullets: true,
    rows: 5,
  },
  social_responsibilities: {
    label: "Social Engagements",
    inputType: "TEXTAREA",
    noSearch: true,
    bullets: true,
    rows: 5,
  },

  // CLIENT CATEGORY
  category: {
    label: "Category",
    inputType: "DROPDOWN",
    source: CLIENT_EXTRA.category,
  },
  // CLIENT TYPE
  type: {
    label: "Type",
    inputType: "DROPDOWN",
    source: CLIENT_EXTRA.type,
  },
};
