<template>
  <elwrapper label="Set Status">
    <div class="select-wrapper">
      <v-autocomplete
        dense
        outlined
        hide-details
        item-text="label"
        item-value="value"
        :items="colors"
        :value="value"
        @input="(v) => $emit('input', v)"
      >
        <template v-slot:selection="item">
          <StatusSelectItem :item="item.item" />
        </template>
        <template v-slot:item="item">
          <StatusSelectItem :item="item.item" />
        </template>
      </v-autocomplete>
      <v-btn v-show="value" @click="$emit('input', null)" x-small icon class="select-clear">
        <v-icon>clear</v-icon>
      </v-btn>
    </div>
  </elwrapper>
</template>

<script>
import StatusSelectItem from "./_StatusSelectItem";
import STATUS_COLORS from "@/config/STATUS_COLORS";
export default {
  props: {
    value: String,
  },
  components: {
    StatusSelectItem,
  },
  computed: {
    colors() {
      return Object.values(STATUS_COLORS.COLORS).map((item) => {
        return {
          label: item.label,
          color: item.color,
          value: `${item.value}`, // requires string
        };
      });
    },
  },
};
</script>

<style lang="scss">
.select-wrapper {
  display: flex;
  align-items: center;

  .select-clear {
    margin-left: 10px;
  }
}
</style>
