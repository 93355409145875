<template>
  <v-app>
    <snackbar-alert v-model="showAlert" :text="alertText" :color="alertColor" />

    <v-snackbar dense top :timeout="offlineTime" color="warning" style="max-height: 40px" :value="isOffline">
      <v-icon>warning</v-icon>
      You are offline. Data cannot be retirieved, saved, or removed.
    </v-snackbar>

    <LoadingOverlay v-model="overlay" :message="overlayMessage" />

    <TopTabs v-if="loggedIn && !loading" :class="{ docview: hidden }">
      <template>
        <MainNavigation id="mainnav" :switchEnv="storeAccount" />
        <v-main id="amain" class="full-height" fill-height>
          <router-view></router-view>
        </v-main>
      </template>
    </TopTabs>

    <section v-else-if="loading">
      <LoadingPage />
    </section>

    <section v-else>
      <LoginWrapper :loggedin="storeAccount" />
    </section>
  </v-app>
</template>

<script>
import OPTION_LIST_CONFIG from "@/config/OPTION_LIST_CONFIG";
import { LoginWrapper } from "@/modules/login";
import { LoadingPage, LoadingOverlay } from "@/modules/loading";
import { MainNavigation } from "@/modules/mainNavigation";
import { TopTabs } from "@/modules/tabs";

export default {
  name: "App",
  components: {
    LoginWrapper,
    LoadingPage,
    LoadingOverlay,
    MainNavigation,
    TopTabs,
  },
  data() {
    return {
      showAlert: false,
      alertText: "",
      alertColor: "",

      overlay: false,
      overlayMessage: "",

      offlineTime: -1,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    loading() {
      return this.$store.getters.loading;
    },
    hidden() {
      return ["email_reply"].includes(this.$route.name);
    },
  },
  methods: {
    successHandler(msg) {
      this.showAlert = true;
      this.alertText = msg;
      this.alertColor = "success";
    },
    errorHandler(err) {
      this.setLoading(false);

      var msg = {
        string: err,
        ...(typeof err === "object" && { object: err.data || err.message || err }),
      };

      var text = msg[typeof err];

      if (!this.$store.getters["isLoggedIn"]) return;

      if (text && typeof text === "string" && text.includes("jwt expired")) {
        this.$store.dispatch("redirectLogout");
        return;
      }

      this.showAlert = true;
      this.alertText = text;
      this.alertColor = "error";

      console.log(new Error(err));
    },
    warnHandler(msg) {
      this.showAlert = true;
      this.alertText = msg;
      this.alertColor = "warning";
    },
    storeAccount(account, error) {
      if (error) {
        return this.errorHandler(error);
      }
      this.$store.dispatch("AccountStore/saveLocalAccount", account);
      this.$store.dispatch("AccountStore/getPersonalEmailList");
    },

    // LOADING VIEW
    setLoading(bool, message) {
      this.overlay = bool;
      this.overlayMessage = message;
    },
  },

  mounted() {
    // Get init currency
    this.$store.dispatch("CurrencyExchangeStore/fetchRates");

    // Popup config
    this.$root.$error = this.errorHandler;
    this.$root.$success = this.successHandler;
    this.$root.$warn = this.warnHandler;

    var vm = this;
    this.$root.$loading = {
      open(message) {
        vm.setLoading(true, message);
      },
      update(message) {
        vm.setLoading(true, message);
      },
      end() {
        vm.setLoading(false);
      },
    };

    // Option List Stuff
    Object.keys(OPTION_LIST_CONFIG).forEach((key) => vm.$store.dispatch("ListStore/getOptionList", key));
  },
};
</script>

<style lang="scss">
html,
body {
  height: 100%;
}

body {
  background: #1f305c;
}

section,
.full-height {
  height: 100%;
}

/** VUE MODIFICATION **/
#app {
  height: 100%;
  background: #1f305c;
  .v-tab-bar {
    height: 35px;
  }

  #amain {
    padding-top: 30px !important;
  }

  .docview {
    #mainnav {
      display: none;
    }
    #amain {
      padding-top: 0 !important;
    }
  }

  .ring {
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .color-alt:nth-child(even) {
    background: #f5f5f5;

    &.canclick:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
  .color-alt:nth-child(odd) {
    background: #fff;

    &.canclick:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .hover:hover {
    background: lightgray;
    cursor: pointer;
  }

  a.docdownload {
    color: black;
    text-decoration: none;
  }

  .disable-click {
    pointer-events: none;
    background: lightgrey;
    opacity: 0.8;
  }
}

/* REMOVE ARROWS IN NUMBER INPUTS */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
