<template>
  <v-dialog :value="value" width="800px" persistent>
    <v-card>
      <v-card-title>Overview Option</v-card-title>
      <v-card-text class="black--text">
        <div>Included</div>
        <textarea
          :value="overviewIncluded"
          :style="{ border: '2px solid #000', width: '100%' }"
          rows="10"
          @input="updateIncluded($event.target.value)"
        ></textarea>
        <div>Non-Included</div>
        <textarea
          :value="overviewExcluded"
          :style="{ border: '2px solid #000', width: '100%' }"
          rows="10"
          @input="updateExcluded($event.target.value)"
        ></textarea>
        <div>Disclaimer</div>
        <textarea
          :value="overviewDisclaimer"
          :style="{ border: '2px solid #000', width: '100%' }"
          rows="10"
          @input="updateDisclaimer($event.target.value)"
        ></textarea>
      </v-card-text>
      <v-card-actions>
        <g-button label="Cancel" @onclick="close" />
        <div class="flex-grow-1"></div>
        <g-button label="Save" @onclick="save" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    data: Object,
  },
  data() {
    return {
      overviewIncluded: "",
      overviewExcluded: "",
      overviewDisclaimer: "",
    };
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.overviewIncluded = newValue?.overviewOptions?.included || "";
        this.overviewExcluded = newValue?.overviewOptions?.excluded || "";
        this.overviewDisclaimer = newValue?.overviewOptions?.disclaimer || "";
      },
    },
  },
  methods: {
    updateIncluded(value) {
      this.overviewIncluded = value;
    },
    updateExcluded(value) {
      this.overviewExcluded = value;
    },
    updateDisclaimer(value) {
      this.overviewDisclaimer = value;
    },
    close() {
      this.$emit("input", false);
    },
    save() {
      this.$emit("update", {
        overviewIncluded: this.overviewIncluded,
        overviewExcluded: this.overviewExcluded,
        overviewDisclaimer: this.overviewDisclaimer,
      });
      this.close();
    },
  },
};
</script>
