import format from "@/services/format";
const CONFIRM = 0;

export default {
  compileTechnical,
  mergeTechnical,
  getTechnicalDisplayName,
};

// ****************
// COMPILE TECHNICAL
// ****************
function compileTechnical(supplierMap, daysMap, flightMap) {
  let mastIntinerary = {};
  // Tracker of unconfirmed suppliers
  // Used to update canceled/reverted status in technical
  let INVALID = {};

  let dayIntinerary,
    hotelTracker = {};
  daysMap.forEach((item, index) => {
    dayIntinerary = [];

    // Extract hotels
    let hotels = _extractHotels(supplierMap, item.hotels, hotelTracker, index === 0, INVALID);
    let breakfast = index === 0 ? [] : _extractBreakfact(supplierMap, daysMap[index - 1].hotels, INVALID);

    // Extract Restaurants
    const restaurants = _extractRestaurant(supplierMap, item.restaurants, INVALID);
    const parks = _extractNormal(supplierMap, item.parks, INVALID);
    const excursions = _extractNormal(supplierMap, item.excursions, INVALID);

    // Free time stuff (Every day BUT last day)
    const freeTime = index !== daysMap.length - 1 ? _extractFreeTime(item, index === 0) : [];
    // Remove hotel stuff if free day
    // ONLY show checkin if applicable
    hotels = hotels.filter((item) => {
      if (freeTime.length && !item.mockid.includes("checkin")) return false;
      return true;
    });

    // Flight stuff
    const flights = _extractFlights(flightMap, item.date, INVALID);

    dayIntinerary = dayIntinerary.concat(hotels, breakfast, freeTime, restaurants, parks, excursions, flights);

    // Sort by time + push
    mastIntinerary[item.date] = dayIntinerary.sort((a, b) => {
      if (a.time > b.time) return 1;
      if (a.time < b.time) return -1;
      return 0;
    });
  });

  return { itinerary: mastIntinerary, invalid: INVALID };
}

function _extractHotels(suppliers, hotelBids, tracker, firstDay, INVALID) {
  const SEVEN_AM = "07:00";
  const EIGHT_AM = "08:00";
  const SIX_PM = "18:00";
  let found,
    data = [];
  hotelBids.forEach((bsid) => {
    found = suppliers[bsid];
    if (found.status < CONFIRM) return (INVALID[bsid] = bsid);

    // REMOVED ON REQUEST
    // if(!firstDay){
    // 	data.push({
    // 		mockid: 'depart_hotel',
    // 		time: EIGHT_AM,
    // 		float: 'Depart hotel'
    // 	})
    // }

    // Return/Check-into hotel
    if (tracker[bsid]) {
      // Return to hotel
      data.push({
        mockid: "return" + bsid,
        time: SIX_PM,
        float: "Return to hotel",
      });
      return;
    }

    // NEW HOTEL, Check-in
    tracker[bsid] = found;
    let displayText = getTechnicalDisplayName(found.type_as, found, found.products, found.meta);
    data.push({
      mockid: "checkin" + bsid,
      time: SIX_PM,
      float: displayText,
    });
  });

  return data;
}

function _extractBreakfact(suppliers, hotelBids, INVALID) {
  const SEVEN_AM = "07:00";
  let found,
    data = [];
  hotelBids.forEach((bsid) => {
    found = suppliers[bsid];
    if (found.status < CONFIRM) return (INVALID[bsid] = bsid);

    // Breakfast included
    if (found.supplier_meta && found.supplier_meta.breakfast && found.supplier_meta.breakfast.value === "included") {
      data.push({
        mockid: "bf_hotel" + bsid,
        time: SEVEN_AM,
        float: `${found.supplier_meta.breakfast.text} Breakfast at hotel`,
      });
    }
  });

  return data;
}

function _extractRestaurant(suppliers, modBids, INVALID) {
  let found,
    data = [];
  modBids.forEach((bsid) => {
    found = suppliers[bsid];
    if (found.status < CONFIRM) return (INVALID[bsid] = bsid);

    let displayText = getTechnicalDisplayName(found.type_as, found, found.products, found.meta);

    data.push({
      mockid: bsid,
      time: found.time || "19:30",
      float: displayText,
      technical_notes: getTechnicalProductNotes(found.products),
      menu_notes: getTechnicalProductMenu(found.products),
    });
  });

  return data;
}

function _extractNormal(suppliers, modBids, INVALID) {
  let found,
    data = [];
  modBids.forEach((bsid) => {
    found = suppliers[bsid];
    if (found.status < CONFIRM) return (INVALID[bsid] = bsid);

    let displayText = getTechnicalDisplayName(found.type_as, found, found.products, found.meta);
    let technicalNotes = getTechnicalProductNotes(found.products);
    data.push({
      mockid: bsid,
      time: found.time,
      float: displayText,
      technical_notes: technicalNotes,
    });
  });

  return data;
}

function _extractFlights(flights, date) {
  let found,
    data = [];

  flights
    .filter((item) => format.isSameDay(item.date, date))
    .forEach((item) => {
      // *** POSSIBLE BUG ALERT: ASSUMING ONLY ONE ARRIVA/TRANSFER/DEPART PER DAY
      if (!item.depart) {
        data.push({
          mockid: "arrive" + item.date,
          time: item.time,
          fixed: `Arrival @ <strong>${item.airport} Airport – Flight #${item.flight_num || ""}</strong>`,
          flight: true,
          lock: true,
        });
        return;
      }

      // Departure, need Transfer 3 hours before
      const flightTime = item.time ? item.time.split(":") : [0, "00"];
      let transferTime = flightTime[0] ? Number(flightTime[0]) - 3 : 0;
      if (transferTime < 0) transferTime += 24;

      data.push(
        {
          mockid: "transfer" + item.date,
          time: `${transferTime < 10 ? "0" : ""}${transferTime}:${flightTime[1]}`,
          fixed: `Transfer to <strong>${item.airport} Airport – Flight #${item.flight_num || ""}</strong>`,
          flight: true,
          lock: true,
        },
        {
          mockid: "depart" + item.date,
          time: item.time,
          float: "Flight departs",
          flight: true,
          lock: true,
        }
      );
    });

  return data;
}

function _extractFreeTime(dayInfo, firstDay) {
  if (firstDay) return [];

  let content = [];
  const NOON = "12:00";

  // No Park, Excursion, Restaurant => “Day Free”
  if (!(dayInfo.restaurants.length || dayInfo.parks.length || dayInfo.excursions.length)) {
    content.push({
      mockid: "free_" + dayInfo.date,
      mid: 1,
      time: NOON,
      float: "Day Free",
    });
  }

  // No Tour Guide => “No Tour Guide”
  // if(!dayInfo.tour_guides.length){
  // 	content.push({
  // 		mockid: 'notg_'+dayInfo.date,
  // 		mid: 1,
  // 		time: NOON,
  // 		float: 'No Tour Guide'
  // 	})
  // }

  return content;
}

// ****************
// MERGE TECHNICAL
// ****************
function mergeTechnical(savedDays, compiledDays, invalidMap) {
  let dest = { ...savedDays },
    temp;

  Object.keys(compiledDays).forEach((date) => {
    // Date doesn't exist; Add in new stuff
    if (!dest[date]) return (dest[date] = compiledDays[date]);

    // Date exists, STRIP trashed suppliers UNLESS modified
    dest[date] = dest[date].filter((item) => {
      if (item.mod) return true;
      return !Object.keys(invalidMap).find((x) => item.mockid.includes(x));
    });

    temp = dest[date];
    // Date exists, proceed with merge
    compiledDays[date].forEach((item) => {
      // Check is ID exists
      const found = temp.find((x) => item.mockid === x.mockid);
      // Doesn't exist add it
      if (!found) return temp.push(item);

      // Fixed (This text CANNOT be changed by user)
      found.fixed = item.fixed;
      // Technical Notes (Set in product, cannot be changed here)
      found.technical_notes = item.technical_notes;
      // Menu Notes (Set in product, cannot be changed here)
      found.menu_notes = item.menu_notes;
      // Does exist, but float NOT modified
      if (!found.mod) found.float = item.float;
      // Does exist, but time NOT modified
      if (!found.modtime) found.time = item.time;
    });

    // SORT TIME
    dest[date] = temp.sort((a, b) => {
      if (a.time > b.time) return 1;
      if (a.time < b.time) return -1;
      return 0;
    });
  });

  return dest;
}

// ****************
// NAME DISPLAY
// ****************
function getTechnicalDisplayName(type, supplier, products, bookingSupplierMeta) {
  // Figures out the display name to go in the technical document
  // PARAMS
  // 	type 			@{String}
  // 	supplier 		@{Object} The booking supplier
  //	products 		@{Object} Mapping of products in this booking supplier
  // 	bookingSupplierMeta 	@{Object} Meta content, with alternative names
  // RETURNS	@{String}

  // Custom Technical Text
  if (bookingSupplierMeta.technical_text) return bookingSupplierMeta.technical_text;

  const supplierName = (supplier.supplier_meta && supplier.supplier_meta.en_name) || supplier.name;

  // These defacto to the name of the supplier
  if (["HOTEL"].includes(type)) return "Check in at " + supplierName;

  // These defacto to the name of the products
  let productNames = Object.values(products)
    .map((x) => {
      // Use custom name if specified
      if (bookingSupplierMeta.custom_names && bookingSupplierMeta.custom_names[x.id]) {
        return bookingSupplierMeta.custom_names[x.id];
      }
      return x.default_technical || x.name;
    })
    .join(", ");

  if (["RESTAURANT", "PARK", "EXCURSION"].includes(type)) return `${productNames} at ${supplierName}`;

  return productNames;
}

function getTechnicalProductNotes(products) {
  let notes = Object.values(products)
    .filter((item) => {
      return item.technical_notes;
    })
    .map((item) => item.technical_notes)
    .join("<br/>");

  return notes.length ? notes : null;
}

function getTechnicalProductMenu(products) {
  let notes = Object.values(products)
    .filter((item) => {
      return item.menu_notes;
    })
    .map((item) => item.menu_notes)
    .join("<br/>");

  return notes.length ? notes : null;
}
