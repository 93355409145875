<template>
  <v-col class="pa-2 quotes">
    <v-row justify="space-between" class="pa-2 quotes-header">
      <g-button
        :label="showSearch ? 'hide' : 'show'"
        :icon="showSearch ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
        @onclick="toggleSearch"
      />

      <d-header label="Quotes" />

      <add-button @click="createNew" />
    </v-row>

    <QuoteSearch v-show="showSearch" @search="updateQuery" />

    <QuoteTable :query="dbQuery" class="mx-2 table" ref="editDBwrapper" :height="dbHeight" />
  </v-col>
</template>

<script>
import { QuoteTable, QuoteSearch } from "@/modules/quotes";
export default {
  components: {
    QuoteTable,
    QuoteSearch,
  },
  data() {
    return {
      showSearch: true,
      dbQuery: {},
      dbHeight: "0px",
    };
  },
  methods: {
    createNew() {
      this.$router.push({
        name: "quotes_new",
      });
    },
    updateQuery(query) {
      this.dbQuery = { ...query };
    },

    toggleSearch() {
      this.showSearch = !this.showSearch;
      this.$nextTick().then(function (res) {
        let OFFSET = 50;
        if (res.showSearch) OFFSET += 175;
        if (!res.$refs.editDBwrapper) return;
        res.dbHeight = res.$refs.editDBwrapper.$el.clientHeight - OFFSET + "px";
      });
    },
  },
  mounted() {
    this.$nextTick().then(function (res) {
      let OFFSET = 50;
      if (res.showSearch) OFFSET += 175;
      if (!res.$refs.editDBwrapper) return;
      res.dbHeight = res.$refs.editDBwrapper.$el.clientHeight + "px";
    });
  },
};
</script>

<style lang="scss">
.quotes {
  height: 100%;
  display: flex;
  flex-direction: column;

  .quotes-header {
    max-height: 50px;
  }

  .quotes-list {
    max-height: 400px;
    overflow: auto;

    &.longlist {
      max-height: 500px;
    }
  }

  .table {
    flex: 1;
  }
}

.db-card:hover {
  cursor: pointer;
}
</style>
