import Invoices from "./Invoices";

export default [
  {
    path: "invoices",
    name: "booking_invoices",
    component: Invoices,
    meta: {
      toolbar: "booking",
      tabAccess: "BOOKING",
    },
  },
];
