<template>
  <v-text-field
    outlined
    hide-details
    dense
    :disabled="disabled"
    :value="value"
    @input="(v) => $emit('input', v)"
    type="time"
  ></v-text-field>
</template>

<script>
export default {
  props: {
    value: String,
    disabled: Boolean,
  },
};
</script>
