<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="350">
      <template v-slot:activator="{ on }">
        <a-hover-menu>
          <template v-slot:activator>
            <v-row class="pa-0" align="center">
              <v-icon v-if="outOfSync && offsync" small color="error">report_problem</v-icon>
              <v-btn v-on="on" v-else-if="outOfSync" small icon text>
                <v-icon small color="error">report_problem</v-icon>
              </v-btn>
              <span v-else style="width: 24px; text-align: center">x</span>

              <span :class="{ 'warning--text': !roomingListMatch }"
                >{{ extraTotal ? `${roomCount}+${extraTotal}` : roomCount }} rooms</span
              >
            </v-row>
          </template>

          <div v-on="on">
            <div>
              <span class="caption font-weight-bold">Breakdown</span>
            </div>
            <div v-for="room in reducedRooms" :key="room.value">
              <span class="caption">{{ room.count }} - {{ room.label }}</span>
            </div>
            <div>
              <span v-if="useRoomingList" class="caption font-italic">(per rooming list)</span>
              <span v-else-if="usingSupplierRoomingList" class="caption font-italic">(custom)</span>
              <span v-else class="caption font-italic">(per est)</span>
            </div>
            <div v-if="!roomingListMatch" class="caption amber--text lighten-5">
              <span>* Custom list assigned does not match rooming list.</span>
            </div>

            <v-divider />

            <div v-if="outOfSync" class="caption">
              <v-row>
                <v-icon small color="error" left>report_problem</v-icon>
                Room/PAX count changed. Suppliers must be updated.
              </v-row>
              <v-row class="ma-0">
                <v-col>Before:</v-col>
                <v-col>
                  <div>
                    {{ confirmedExtraTotal ? `${confirmedRoomTotal}+${confirmedExtraTotal}` : confirmedRoomTotal }}
                    rooms
                  </div>
                  <div>({{ confirmedRoomCount.pax_total }} PAX)</div>
                </v-col>
                <v-col>Current:</v-col>
                <v-col>
                  <div>{{ extraTotal ? `${roomCount}+${extraTotal}` : roomCount }} rooms</div>
                  <div>({{ roomData.pax_total }} PAX)</div>
                </v-col>
              </v-row>
            </div>
          </div>
        </a-hover-menu>
      </template>

      <v-card>
        <v-card-text class="pt-3" style="color: black">
          <div class="my-2">Do you want to sync room count?</div>
          <div>Suppliers will not be notified.</div>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn text depressed small @click="dialog = false">Cancel</v-btn>

          <v-btn
            small
            outlined
            color="green"
            @click="
              $emit('syncmeta', 'confirmed_roomcount');
              dialog = false;
            "
          >
            <v-icon small left>sync</v-icon>
            <span>Yes, Sync</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BookingRoomService from "../../service";
import format from "@/services/format";
export default {
  name: "RoomNumberHover",
  props: {
    confirmed: Boolean,
    outside: Boolean,
    dateList: Array,
    roomBreakdownData: Object,
    supplierCustomRoom: Object,
    bsmeta: Object,

    offsync: Boolean,
  },
  data() {
    return {
      dialog: false,
    };
  },
  watch: {
    outOfSync(v) {
      if (v) {
        this.$syncCounts.$emit("show");
      }

      this.$emit("onSyncToggle", v);
    },
  },
  computed: {
    outOfSync() {
      if (!this.confirmed) return false;

      // Check PAX count
      if (
        this.confirmedRoomCount.pax_total + this.confirmedExtraTotal !==
        this.roomData.pax_total + this.roomData.extraTotal
      )
        return true;

      // Check ROOM count
      return (
        this.confirmedRoomCount.room_total + this.confirmedExtraTotal !==
        this.roomData.room_total + this.roomData.extraTotal
      );
    },
    roomData() {
      return BookingRoomService.flattenAndGetRoomingInfo({
        confirmed: this.confirmed,
        dateList: this.dateList,
        ...this.roomBreakdownData,
        supplierCustomRoom: this.supplierCustomRoom,
      });
    },
    useRoomingList() {
      return this.roomData.using === "ROOMING";
    },
    usingSupplierRoomingList() {
      return this.roomData.using === "CUSTOM";
    },
    extraTotal() {
      return this.roomData.extraTotal;
    },
    roomCount() {
      return this.roomData.room_total;
    },
    reducedRooms() {
      return BookingRoomService.reduceRooms(this.roomData.room_breakdown, this.roomData.extraFilter);
    },

    totalCount() {
      return this.roomData.extraTotal + this.roomData.room_total;
    },
    confirmedRoomCount() {
      const meta = this.bsmeta && this.bsmeta.confirmed_roomcount && this.bsmeta.confirmed_roomcount[this.dateList[0]];
      if (!meta) return this.roomData;
      return meta || this.roomData;
    },
    confirmedRoomTotal() {
      return this.confirmedRoomCount.room_total;
    },
    confirmedExtraTotal() {
      const extra = this.confirmedRoomCount.extraFilter;
      if (!extra) return 0;

      // Get only suppliers in date range
      return extra.length;
    },

    roomingListMatch() {
      if (!this.supplierCustomRoom) return true;
      if (!this.roomBreakdownData.roomingListBreakdown) return true;
      const roomingListTotal = Object.values(this.roomBreakdownData.roomingListBreakdown).reduce((total, num) => {
        total += Number(num);
        return total;
      }, 0);
      const supplierCustomTotal = Object.values(this.supplierCustomRoom).reduce((total, num) => {
        total += Number(num);
        return total;
      }, 0);

      return roomingListTotal === supplierCustomTotal;
    },
  },
  methods: {
    replyPing() {
      if (this.outOfSync) {
        this.$syncCounts.$emit("show", "Room");
        this.$emit("onSyncToggle", true);
      }
    },
  },
  mounted() {
    this.$syncCounts.$on("ping", this.replyPing);
    this.replyPing();
  },
  beforeDestroy() {
    this.$syncCounts.$off("ping", this.replyPing);
  },
};
</script>
