<template>
  <div>
    <OverviewGrid />
  </div>
</template>

<script>
import { OverviewGrid } from "@/modules/bookings.overview";
export default {
  components: {
    OverviewGrid,
  },
};
</script>
