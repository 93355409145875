<template>
  <table
    class="roomsummarytotal"
    cellspacing="0"
    cellpadding="0"
    style="background-color: lightgrey; font-weight: bold"
  >
    <tr>
      <th class="indexing rleft rright rbottom">Room Type</th>
      <th class="indexing rleft rright rbottom">Total</th>
    </tr>
    <tr>
      <td class="indexing rleft rright rbottom" style="width: 250px">SINGLE Occupancy (1 bed)</td>
      <td class="indexing rleft rright rbottom">{{ roomBreakdown.SINGLE_DK }}</td>
    </tr>
    <tr>
      <td class="indexing rleft rright rbottom">DOUBLE Occupancy (1 bed)</td>
      <td class="indexing rleft rright rbottom">{{ roomBreakdown.DOUBLE_QK }}</td>
    </tr>
    <tr>
      <td class="indexing rleft rright rbottom">TWIN Occupancy(2 beds)</td>
      <td class="indexing rleft rright rbottom">{{ roomBreakdown.DOUBLE_DOUBLE }}</td>
    </tr>
    <tr>
      <td class="indexing rleft rright rbottom">TRIPLE Occupancy (2 beds)</td>
      <td class="indexing rleft rright rbottom">{{ roomBreakdown.TRIPLE }}</td>
    </tr>
    <tr>
      <td class="indexing rleft rright rbottom">QUAD Occupancy (2 beds)</td>
      <td class="indexing rleft rright rbottom">{{ roomBreakdown.QUAD }}</td>
    </tr>
    <tr class="red--text">
      <td class="indexing rleft rright rbottom"><span style="color: red"> # OF ROOMS </span></td>
      <td class="indexing rleft rright rbottom">
        <span style="color: red">{{ totalRooms }}</span>
      </td>
    </tr>
    <tr class="red--text">
      <td class="indexing rleft rright rbottom"><span style="color: red"> # OF PAX </span></td>
      <td class="indexing rleft rright rbottom">
        <span style="color: red">{{ totalPax }}</span>
      </td>
    </tr>
  </table>
</template>

<script>
import format from "@/services/format";
export default {
  props: {
    roomList: Array,
    date: [String, Array],
    specialList: Object,
  },
  computed: {
    roomBreakdown() {
      // Better way to do this? Yes.
      // Get the total of each room type
      // Split and combine to room category
      let all = {
        SINGLE_DK: 0,
        DOUBLE_QK: 0,
        DOUBLE_DOUBLE: 0,
        TRIPLE: 0,
        QUAD: 0,
      };
      this.roomList.forEach((item) => {
        if (item.meta && item.meta.cancelled) return;
        all[item.type] += 1;
      });

      all.SINGLE_DK += this.adjustedSpecialList.length;

      return all;
    },
    // twin(){
    // 	return parseInt(this.roomBreakdown.DOUBLE_QK)
    // 		+ parseInt(this.roomBreakdown.DOUBLE_DOUBLE)
    // },

    adjustedSpecialList() {
      if (!this.specialList) return [];
      if (this.date === "ALL") return this.specialList.ALL;
      return this.specialList[this.date] || [];
    },
    totalRooms() {
      const total = this.$store.getters["BookingRoomStore/totalRooms"];

      return Number(total) + this.adjustedSpecialList.length;
    },
    totalPax() {
      const total = this.$store.getters["BookingRoomStore/totalPax"];

      return this.adjustedSpecialList.length ? `${total} + ${this.adjustedSpecialList.length}` : total;
    },
  },
};
</script>

<style lang="scss">
/* MUST BE CSS*/
@import "./style.css";
</style>
