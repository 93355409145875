import Vue from "vue";

import "./AForm.scss";

import "./dates";
import "./groups";
import "./items";
import "./phones";
import "./times";
import "./wrappers";
import "./special";

import RichTextEditor from "./richtext/RichTextEditor";

import ATextField from "./ATextField";
import ASelect from "./ASelect";
import AField from "./AField";

import FPassword from "./items/FPassword";

Vue.component("a-text-field", ATextField);
Vue.component("f-password", FPassword);
Vue.component("a-select", ASelect);
Vue.component("a-field", AField);
Vue.component("RichTextEditor", RichTextEditor);
