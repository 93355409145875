const namespaced = true;

const SET_REMINDER = "SET_REMINDER";

const state = {
  reminderMapping: {},
};

const mutations = {
  SET_REMINDER(state, data) {
    state.reminderMapping = data;
  },
};

const actions = {
  setReminders({ commit }, reminderMapping) {
    commit(SET_REMINDER, reminderMapping);
  },
};

const getters = {
  reminderMapping: (state) => state.reminderMapping,
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
