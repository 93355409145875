<template>
  <a-card nopad>
    <div class="ma-2 subtitle-2" style="text-align: center">{{ title }}</div>

    <div v-if="!dateGroups.length" class="caption ma-2 text-align-center">(None)</div>

    <div style="max-height: 500px; overflow: auto">
      <div v-for="group in dateGroups" :key="group.date" :class="{ fade: group.past }">
        <v-row class="booking-title grey darken-1 white--text">
          <v-col>{{ group.formatDate }}</v-col>
        </v-row>

        <div v-if="mode === 'BOOKING'">
          <ReminderItem
            v-for="reminder in group.reminders"
            :key="reminder.id"
            :accountList="accountList"
            :supplierData="reminder"
            hidebuttons
            class="color-alt hover px-2"
            :mode="mode"
            @click.native="$emit('onfocus', reminder)"
          />
        </div>
        <div v-else class="ml-2">
          <div v-for="booking in group.reminders" :key="booking.booking_id">
            <v-row class="booking-title grey darken-1 white--text">
              <v-col>{{ booking.client_name }}</v-col>
              <v-col>{{ booking.group_name }}</v-col>
            </v-row>
            <ReminderItem
              v-for="reminder in booking.reminderList"
              :key="reminder.id"
              :accountList="accountList"
              :supplierData="reminder"
              hidebuttons
              class="color-alt hover"
              :mode="mode"
              @oncomplete="(v) => $emit('oncomplete', v)"
              @click.native="$emit('onfocus', reminder)"
            />
          </div>
        </div>
      </div>
    </div>
  </a-card>
</template>

<script>
import moment from "moment";
import format from "@/services/format";
import { SuppliersMenu } from "@/config/menus";
import ReminderItem from "./ReminderItem";
export default {
  props: {
    upcomingMapping: Object,
    mode: String,
    title: String,
  },
  components: {
    ReminderItem,
  },
  data() {
    return {
      accountList: [],
    };
  },
  computed: {
    dateGroups() {
      // Create list sorted by date
      const today = moment().format("YYYY-MM-DD");
      const dates = Object.keys(this.upcomingMapping)
        .sort((a, b) => {
          if (a > b) return 1;
          if (a < b) return -1;
          return 0;
        })
        .map((d) => {
          let unfinished = this.hasUnfinished(this.upcomingMapping[d]);
          return {
            date: d,
            past: !unfinished,
            formatDate: format.formatDate(d),
            reminders: this.sortSubmapping(this.upcomingMapping[d]),
          };
        });

      // Upcoming dates on top
      // Past dates on the bottom
      const groups = [...dates.filter((i) => !i.past), ...dates.filter((i) => i.past)];

      return groups;
    },
  },
  methods: {
    sortSubmapping(list) {
      if (this.mode === "BOOKING") {
        return this.sortSupplier(list);
      }
      return this.sortBookings(list);
    },
    sortBookings(list) {
      let o = {};
      list.forEach((rem) => {
        if (!o[rem.booking_id]) {
          o[rem.booking_id] = {
            booking_id: rem.booking_id,
            client_name: rem.client_name,
            group_name: rem.group_name,
            reminderList: [],
          };
        }

        o[rem.booking_id].reminderList.push(rem);
      });
      return Object.values(o).map((s) => {
        s.reminderList = this.sortSupplier(s.reminderList);
        return s;
      });
    },
    sortSupplier(list) {
      const DBFIELDS = SuppliersMenu.MOD;
      return list.sort((a, b) => {
        // Sort then by type
        const aIndex = DBFIELDS.indexOf(a.type_as);
        const bIndex = DBFIELDS.indexOf(b.type_as);
        if (aIndex < bIndex) return -1;
        if (aIndex > bIndex) return 1;
        return 0;
      });
    },
    hasUnfinished(list) {
      return list.filter((item) => {
        return !item.done_by;
      }).length;
    },
  },
  mounted() {
    this.$store
      .dispatch("AdminStore/getAccountList")
      .then((list) => {
        this.accountList = list;
      })
      .catch((err) => this.$root.$error(err));
  },
};
</script>

<style lang="scss">
.fade {
  opacity: 0.5;
}
</style>
