import {
  SupplierFields,
  SupplierHotelMeta,
  SupplierRestaurantMeta,
  SupplierTourMeta,
  SupplierTransportMeta,
  SupplierParkMeta,
  SupplierExcursionMeta,
} from "@/config/fields/suppliers";

export default {
  HOTEL: {
    ...SupplierFields,
    meta: { ...SupplierHotelMeta },
  },
  RESTAURANT: {
    ...SupplierFields,
    meta: { ...SupplierRestaurantMeta },
  },
  TRANSPORT: {
    ...SupplierFields,
    meta: { ...SupplierTransportMeta },
  },
  PARK: {
    ...SupplierFields,
    meta: { ...SupplierParkMeta },
  },
  TOUR_GUIDE: {
    ...SupplierFields,
    meta: { ...SupplierTourMeta },
  },
  EXCURSION: {
    ...SupplierFields,
    meta: { ...SupplierExcursionMeta },
  },
};
