<template>
  <div>
    <elwrapper :label="label" :smlabel="smlabel">
      <v-text-field :value="displayValue" outlined hide-details dense readonly @click="dialog = true"></v-text-field>
    </elwrapper>

    <SelectDialog
      :label="label"
      :type="type"
      :currentValue="value"
      :datewall="datewall"
      v-model="dialog"
      @update="(v) => $emit('input', v)"
    />
  </div>
</template>

<script>
import SelectDialog from "./_SelectDialog";
export default {
  components: {
    SelectDialog,
  },
  props: {
    smlabel: Boolean,
    label: String,
    value: [String, Object],
    type: String,
    datewall: [String, Date],
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    displayValue() {
      if (this.type === "CLIENT") {
        return this.value && this.value.name ? this.value.name : "(Client Deleted)";
      }
      return this.value;
    },
  },
};
</script>

<style lang="scss"></style>
