import Vue from "vue";

import AddButton from "./AddButton";
import ToggleButton from "./ToggleButton";
import DeleteButton from "./DeleteButton";
import DotMenu from "./DotMenu";
import NavButton from "./NavButton";
import SaveButton from "./SaveButton";
import SearchButton from "./SearchButton";
import GeneralButton from "./GeneralButton";
import IconBtn from "./IconBtn";

Vue.component("toggle-button", ToggleButton);
Vue.component("add-button", AddButton);
Vue.component("delete-button", DeleteButton);
Vue.component("dot-menu", DotMenu);
Vue.component("nav-button", NavButton);
Vue.component("save-button", SaveButton);
Vue.component("search-button", SearchButton);
Vue.component("g-button", GeneralButton);
Vue.component("icon-btn", IconBtn);
