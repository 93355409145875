<template>
  <v-data-table
    disable-pagination
    hide-default-footer
    fixed-header
    :headers="headers"
    :items="dataList"
    :loading="loading"
    loading-text="Fetching suppliers"
    height="500px"
    show-select
    single-select
    class="database-table a-card nested-rows"
  >
    <template v-slot:body="{ items }">
      <tbody>
        <tr class="database-row" :class="{ 'primary--text grey lighten-2': selectAll }" @click="toggleSelectAll">
          <td>
            <input type="checkbox" :checked="selectAll" />
          </td>
          <td>SELECT ALL</td>
        </tr>
        <BookingInfoRow
          v-for="item in items"
          :key="item.id"
          :booking="item"
          :searchRecord="searchRecord"
          nosub
          :checked="!!value[item.id]"
          pluckProducts
          :supplierId="searchRecord.supplier_id"
          @onclick="onclickBooking"
        >
          <template v-slot:default="slotProps">
            <ProductInfoBlock
              :supplierId="searchRecord.supplier_id"
              :supplier="slotProps.suppliers"
              :bookingSuppliers="item.booking_suppliers"
              :bookingDays="item.days"
              :pax="slotProps.pax"
              :roomBreakdownData="slotProps.rooms"
            />
          </template>
        </BookingInfoRow>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
import Vue from "vue";
import { BookingInfoRow } from "@/modules/bookings.email";
import ProductInfoBlock from "./_ProductInfoBlock";
export default {
  props: {
    value: Object,
    supplierId: String,
    dataList: Array,
    contactList: Array,
    searchRecord: Object,
    loading: Boolean,
  },
  components: {
    BookingInfoRow,
    ProductInfoBlock,
  },
  data() {
    return {
      selectAll: false,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Name",
          value: "name",
        },
      ];
    },
  },
  methods: {
    onclickBooking({ booking }) {
      let changed = { ...this.value };
      if (this.selectAll && this.value[booking.id]) {
        // Unselect
        this.selectAll = !this.selectAll;
        Vue.delete(changed, booking.id);
      } else if (this.value[booking.id]) {
        Vue.delete(changed, booking.id);
      } else {
        Vue.set(changed, booking.id, booking);
        this.selectAll = this.dataList.length === Object.keys(changed).length;
      }

      this.$emit("input", changed);
    },

    toggleSelectAll() {
      this.selectAll = !this.selectAll;
      this.$emit(
        "input",
        this.selectAll
          ? this.dataList.reduce((obj, item) => {
              obj[item.id] = item;
              return obj;
            }, {})
          : {}
      );
    },

    updateIgnoreProduct({ product, toIgnore }, bookingId) {
      if (!this.value.ignoreProducts[bookingId]) Vue.set(this.value.ignoreProducts, bookingId, {});
      // This will directly mutate parent object
      // But I don't care right now
      if (toIgnore) {
        Vue.set(this.value.ignoreProducts[bookingId], product.ref_id, 1);
      } else {
        Vue.delete(this.value.ignoreProducts[bookingId], product.ref_id);
      }
      this.$emit("input", this.value);
    },
    setGroupping({ supplier_id, supplier, groupping }, bookingId) {
      if (!this.value.groupBy[bookingId]) Vue.set(this.value.groupBy, bookingId, {});

      Vue.set(this.value.groupBy[bookingId], supplier_id, groupping);
    },
  },
};
</script>
