import { ReportService, ReportCompileService } from "@/modules/finances.report";
import { BookingRoomService } from "@/modules/bookings.rooms";
import { BookingDayService } from "@/modules/bookings.days";

export default {
  compileOneBooking,
};

function compileOneBooking(item, storeGetter, errorPopup) {
  /*
		PARAMS
		item	@{Object}
			booking 	@{Object/Database: booking}	Booking in Question
			booking_suppliers @{Array/Database: booking_suppliers}
	 		days 		@{Array/Database: booking_days}
			rooms 		@{Array/Database: booking_rooms}
			supplier_rooms 	@{Array/Database: supplier_rooms}
			extra_expenses 	@{Array/Database: extra_expenses}
			client_invoice_history @{Array/Database: client_invoice}
			supplier_invoices @{Array/Database: supplier_invoices}
			custom_commissions @{Array/Database: custom_commissions}
			commission_received_history @{Array/Database: received_commisions}
			est_invoice	@{Object} Estimated Invoice
		storeGetter 	@{Vue getter}
		errorPopup		@{Function}	Utility function to help catch/print bugs
	*/

  const i = item;
  const pax = i.booking.data && (i.booking.data.pax_num || i.booking.data.est_pax_num);
  // BookingRoomService.getPaxCount(i.booking.data && i.booking.data.room_num,
  // 	i.rooms && i.rooms[0].rooms);

  const productPriceBlob = ReportCompileService.compileProductPrices({
    pax,
    booking_suppliers: i.booking_suppliers,
    booking_days: i.days,
    roomBreakdown: BookingRoomService.getRoomingListBreakdown({
      bookingMetaData: i.booking.data,
      bookingRoomingList: i.rooms && i.rooms[0].rooms,
      bookingDays: i.days,
      supplierRoomList: i.supplier_rooms,
    }),
    storeGetter: storeGetter,
    errorPopup,
  });

  const supplierBreakdown = ReportCompileService.compileSupplierBreakdown(item.booking_suppliers, productPriceBlob);

  /******
   * SUPPLIER & PRODUCT TOTAL COMPILATION
   * ****/
  const productTotalMerge = ReportService.getProductListTotal(Object.values(productPriceBlob), false, storeGetter);
  const porterage = ReportService.getPorterageTotal(supplierBreakdown, storeGetter);
  const parking = ReportService.getParkingTotal(supplierBreakdown, storeGetter);
  const extraCost = ReportService.getExtraTotal(item.extra_expenses, null, true, storeGetter);

  // Accounts for suppliers shared across multiple environments
  // Need to be able to "split" them and see breakdown of environment total
  const totalCost = {
    COMBINED: 0,
    USA: 0,
    CDA: 0,
  };

  for (const key in totalCost) {
    totalCost[key] += productTotalMerge[key] + parking[key] + porterage[key] + extraCost[key];
  }

  const billed = ReportService.getCompiledBilled({
    supplierBreakdownBlob: supplierBreakdown,
    supplierInvoiceBlob: item.supplier_invoices,
    compiledProductPriceBlob: productPriceBlob,
    defaultToEst: true,
    extraCostList: item.extra_expenses,
    split: true,
    storeGetter,
  });

  const billedDelta = {
    COMBINED: 0,
    USA: 0,
    CDA: 0,
  };

  for (const key in billedDelta) {
    billedDelta[key] = billed[key] - totalCost[key];
  }

  // console.log(billed, billedDelta)

  // Client -- INvoice
  const invoiceTotal = item.client_invoice_history.reduce((total, h) => total + Number(h.total_invoice), 0);
  const invoiceType = (item.client_invoice_history[0] || {}).invoice_type; // Get type of most recent added
  const fullInvoiceSet = invoiceType === "FULL";
  const invoicePaid = item.payment_history.reduce((total, h) => total + Number(h.paid_amount), 0);

  // Margin Stuff
  const custom = i.custom_commissions ? Object.values(i.custom_commissions) : [];
  const commission = ReportService.getCommission(
    supplierBreakdown,
    productPriceBlob,
    custom,
    item.commission_received_history
  );
  const estInvoice = item.est_invoice ? Number(item.est_invoice.estimate_amount || 0) : 0;
  const margin = ReportService.getMargin(
    fullInvoiceSet ? invoiceTotal : estInvoice,
    totalCost.COMBINED + billedDelta.COMBINED,
    commission
  );

  // Reconciled
  const supplier_flatten = Object.values(item.booking_suppliers).reduce((obj, item) => {
    if (item.type_as === "HOTEL" || item.type_as === "RESTAURANT") {
      // Hotel and restautants of same supplier are combined
      // Other suppliers are seperated, so assume them the same here
      obj[item.supplier_id + "LUMP"] = 1;
    } else {
      obj[item.supplier_id + item.type_as] = 1;
    }
    return obj;
  }, {});
  const supplier_invoices = Object.values(item.supplier_invoices).filter((item) => {
    return item.reconciled;
  });
  const reconciled = supplier_invoices.length / (Object.keys(supplier_flatten).length || 1);

  // Commission Received?
  const commission_not_received = getCommissionNotReceived(supplierBreakdown, item.commission_received_history);

  return {
    supplierBreakdown,

    total_cost: totalCost,
    total_billed: billed,
    total_billed_delta: billedDelta,

    margin: margin ? margin : {},
    margin_val: margin && margin.diff,
    margin_per: margin && margin.percentage,

    reconciliation: reconciled,
    recon_total: supplier_invoices.length,
    supplier_total: Object.keys(supplier_flatten).length,

    commission,
    commission_not_received,

    total_invoice: invoiceTotal,
    invoice_paid: invoicePaid,
    invoice_type: invoiceType,
  };
}

function getCommissionNotReceived(supplierBreakdown, commissionReceivedList) {
  let commissionCount = 0;
  let commissionResolved = 0;
  Object.values(supplierBreakdown)
    .reduce((arr, obj) => {
      if (!Object.keys(obj)) return arr;
      return [...arr, ...Object.values(obj)];
    }, [])
    .forEach((bs) => {
      if (!(bs.supplier_meta && bs.supplier_meta.commission)) return;
      if (bs.supplier_meta.commission.value === "no") return;

      // Add to count and check if resolved
      commissionCount += 1;
      if (commissionReceivedList && commissionReceivedList.find((v) => v.supplier_id === bs.supplier_id))
        commissionResolved += 1;
    });

  return commissionCount === 0 ? 0 : commissionCount - commissionResolved;
}
