<template>
  <a-card>
    <a-field :config="config.address" v-model="address" class="px-1" />

    <v-row>
      <v-col class="py-0">
        <a-field :config="config.city" v-model="city" />
      </v-col>
      <v-col class="py-0">
        <a-field :config="config.subjur" :country="country" :dependantValue="country" v-model="subjur" />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="py-0">
        <a-field :config="config.country" :env="env" v-model="country" />
      </v-col>
      <v-col class="py-0">
        <a-field :config="config.zip" v-model="zip_code" />
      </v-col>
    </v-row>
  </a-card>
</template>

<script>
import config from "../config/clientConfig";

export default {
  data() {
    return {
      config: config,
    };
  },
  computed: {
    env() {
      return this.$store.getters["AccountStore/environment"];
    },
    address: {
      get() {
        return this.get("address");
      },
      set(newValue) {
        return this.set("address", newValue);
      },
    },
    city: {
      get() {
        return this.get("city");
      },
      set(newValue) {
        return this.set("city", newValue);
      },
    },
    subjur: {
      get() {
        return this.get("subjur");
      },
      set(newValue) {
        return this.set("subjur", newValue);
      },
    },
    country: {
      get() {
        return this.$store.getters["ClientStore/active"].country;
      },
      set(val) {
        this.$store.dispatch("ClientStore/setUpdate", { key: "country", val });
      },
    },
    zip_code: {
      get() {
        return this.get("zip_code");
      },
      set(newValue) {
        return this.set("zip_code", newValue);
      },
    },
  },
  methods: {
    get(val) {
      return this.$store.getters["ClientStore/address"][val];
    },
    set(key, val) {
      return this.$store.dispatch("ClientStore/setUpdateAddress", { key, val });
    },
  },
};
</script>
