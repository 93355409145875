import Vue from "vue";
import Vuex from "vuex";

import { LoginStore } from "@/modules/login";
import { LoadingStore } from "@/modules/loading";
import { MainNavigationStore } from "@/modules/mainNavigation";
import { TabStore } from "@/modules/tabs";

import { CurrencyExchangeStore } from "@/modules/currencyExchange";
import { EmailStore } from "@/modules/email";
import {
  DocumentStore,
  BookingContractStore,
  BookingInvoiceStore,
  FinanceDocumentStore,
} from "@/modules/media.documents";
import { SupplierImageStore, ProductImageStore, ClientImageStore, SignatureImageStore } from "@/modules/media.pictures";

import { AccountStore } from "@/modules/account";
import { AdminStore } from "@/modules/admin";
import { AdminHistoryStore } from "@/modules/admin.history";
import { EmailTemplateStore } from "@/modules/admin.email_template";
import { ListStore } from "@/modules/admin.lists";
import { TextConfigStore } from "@/modules/admin.text_configs";

import { DatabaseStore } from "@/modules/database";

import { ProductStore } from "@/modules/products";

import { SupplierStore } from "@/modules/suppliers";
import { SupplierHistoryStore } from "@/modules/suppliers.history";
import { SupplierContactStore } from "@/modules/suppliers.contacts";

import { ClientStore } from "@/modules/clients";
import { ClientContactStore } from "@/modules/clients.contacts";
import { ClientHistoryStore } from "@/modules/clients.history";

import { BookingStore } from "@/modules/bookings";
import { BookingDayStore } from "@/modules/bookings.days";
import { BookingEmailStore } from "@/modules/bookings.email";
import { SupplierBookingEmailStore } from "@/modules/bookings.supplier_email";
import { BookingRoomStore } from "@/modules/bookings.rooms";
import { BookingSupplierRoomStore } from "@/modules/bookings.supplier_rooms";
import { SelectSupplierStore } from "@/modules/bookings.select_dialog";
import { BookingFlightStore } from "@/modules/bookings.flights";
import { BookingTechnicalStore } from "@/modules/bookings.technical";
import { BookingReminderStore } from "@/modules/bookings.reminders";

import { QuoteStore } from "@/modules/quotes";
import { QuoteDayStore } from "@/modules/quotes.days";

import { ReminderStore } from "@/modules/reminders";

import { FinanceStore } from "@/modules/finances";
import { FinanceReportStore, FinanceCompileStore } from "@/modules/finances.report";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    // CORE
    LoginStore,
    LoadingStore,
    MainNavigationStore,
    TabStore,

    // Namespaced
    AdminStore,
    AdminHistoryStore,
    EmailTemplateStore,
    ListStore,
    TextConfigStore,

    CurrencyExchangeStore,
    EmailStore,
    DocumentStore,
    BookingContractStore,
    BookingInvoiceStore,
    FinanceDocumentStore,
    SupplierImageStore,
    ProductImageStore,
    ClientImageStore,
    SignatureImageStore,
    AccountStore,
    DatabaseStore,

    ProductStore,
    SupplierStore,
    SupplierHistoryStore,
    SupplierContactStore,

    ClientStore,
    ClientContactStore,
    ClientHistoryStore,

    BookingStore,
    BookingDayStore,
    BookingEmailStore,
    SupplierBookingEmailStore,
    BookingRoomStore,
    BookingSupplierRoomStore,
    SelectSupplierStore,
    BookingFlightStore,
    BookingTechnicalStore,
    BookingReminderStore,

    QuoteStore,
    QuoteDayStore,

    ReminderStore,

    FinanceStore,
    FinanceReportStore,
    FinanceCompileStore,
  },
});
