<template>
  <v-col>
    <d-header label="New Booking" />
    <create-header label="Booking" :goBackTo="goback" :disabled="!canCreate" :oncreate="createBooking" />

    <a-card>
      <v-row align="center" justify="center" class="px-1 flex-nowrap">
        <div v-if="isgroup === null">
          <g-button label="Group" @onclick="isgroup = true" />
          <span class="caption">or</span>
          <g-button label="FIT" @onclick="isgroup = false" />
        </div>

        <div v-else>
          <g-button type="black" :label="isgroup ? 'Group' : 'FIT'" />
          <v-btn icon x-small @click="isgroup = null">
            <v-icon>clear</v-icon>
          </v-btn>
        </div>
      </v-row>
    </a-card>

    <a-card>
      <v-row align="center" justify="space-between" class="px-1 flex-nowrap">
        <ClientAutocomplete v-model="client" />
        <a-text-field label="Group Name" v-model="group_name" v-caps />

        <a-group label="City" icon="location_city">
          <elwrapper label="Start" smlabel>
            <autocomplete-string-free-text :items="this.cities" v-model="where_start" />
          </elwrapper>
          <elwrapper label="End" smlabel>
            <autocomplete-string-free-text :items="this.cities" v-model="where_end" />
          </elwrapper>
        </a-group>
        <a-group icon="event">
          <a-date label="From" smlabel v-model="from" :datewall="today" />
          <a-date label="To" smlabel v-model="to" :datewall="from || today" />
        </a-group>
      </v-row>
    </a-card>

    <a-card>
      <v-row>
        <elwrapper widelabel>
          <v-row align="center">
            <v-switch v-model="toggles.invoice"></v-switch>
            <span class="caption">Create Final invoice reminder</span>
          </v-row>
        </elwrapper>
        <elwrapper widelabel>
          <v-row align="center">
            <v-switch v-model="toggles.rooming"></v-switch>
            <span class="caption">Create Rooming list reminder</span>
          </v-row>
        </elwrapper>
        <elwrapper widelabel>
          <v-row align="center">
            <v-switch v-model="toggles.cancel"></v-switch>
            <span class="caption">Create Cancellation reminder</span>
          </v-row>
        </elwrapper>
      </v-row>
      <v-row>
        <AutoReminderSetField
          label="Invoice"
          :disabled="!toggles.invoice"
          :startDay="from"
          :autoBeforeDays="defaultDay"
          v-model="reminderDates.invoice"
        />
        <AutoReminderSetField
          label="Rooming"
          :disabled="!toggles.rooming"
          :startDay="from"
          :autoBeforeDays="defaultDay"
          v-model="reminderDates.rooming"
        />
        <AutoReminderSetField
          label="Cancellation"
          :disabled="!toggles.cancel"
          :startDay="from"
          :autoBeforeDays="defaultDay"
          v-model="reminderDates.cancel"
        />
      </v-row>
    </a-card>

    <BookingExistsDialog :recommend="recommendedName" v-model="dialog" />
  </v-col>
</template>

<script>
import { BookingExistsDialog } from "@/modules/bookings";
import { AutoReminderSetField } from "@/modules/bookings.reminders";
import { ClientAutocomplete } from "@/modules/clients";
import format from "@/services/format";
import moment from "moment";
export default {
  components: {
    ClientAutocomplete,
    BookingExistsDialog,
    AutoReminderSetField,
  },
  data() {
    return {
      goback: { name: "bookings" },
      today: format.convertDate(new Date()),

      client: {},
      group_name: "",
      where_start: "",
      where_end: "",
      from: "",
      to: "",
      isgroup: null,

      dialog: false,
      recommendedName: "",

      defaultDay: 60,

      // REMINDERS
      toggles: {
        invoice: true,
        rooming: true,
        cancel: true,
      },
      reminderDates: {
        invoice: null,
        rooming: null,
        cancel: null,
        default: moment().add({ days: 60 }).format("YYYY-MM-DD"),
      },
      cities: [],
    };
  },
  computed: {
    canCreate() {
      return this.client.id && this.where_start && this.where_end && this.from && this.to && this.isgroup !== null;
    },
  },
  methods: {
    createBooking() {
      return this.$store
        .dispatch("BookingStore/confirmCanAddBooking", {
          client_id: this.client.id,
          group_name: this.group_name,
        })
        .then((e) => {
          if (!e.exists) return this.continueCreate();

          // If exists PROMPT DIFFERENT ACTION
          this.dialog = true;
          this.recommendedName = e.recommend;
        })
        .catch((err) => this.$root.$error(err));
    },
    continueCreate() {
      return this.$store
        .dispatch("BookingStore/createBooking", {
          client_id: this.client.id,
          group_name: this.group_name,
          where_start: this.where_start,
          where_end: this.where_end,
          from: this.from,
          to: this.to,
          isgroup: this.isgroup,
        })
        .then((bookingId) => {
          return this._createReminders(bookingId).then((v) => bookingId);
        })
        .then((bookingId) => {
          this.$root.$success("Created Booking");
          this.$router.push({
            name: "bookings_view",
            params: {
              booking_id: bookingId,
            },
          });
        });
    },
    _createReminders(bookingId) {
      let query = [];

      if (this.toggles.invoice && this.reminderDates.invoice) {
        query.push(this._sendReminder(bookingId, this.reminderDates.invoice, "Send Invoice"));
      }

      if (this.toggles.rooming && this.reminderDates.rooming) {
        query.push(this._sendReminder(bookingId, this.reminderDates.rooming, "Send Rooming List"));
      }

      if (this.toggles.cancel && this.reminderDates.cancel) {
        query.push(this._sendReminder(bookingId, this.reminderDates.cancel, "Cancellation Deadline"));
      }

      query.push(this._sendReminder(bookingId, this.reminderDates.default, "Deposit Reminder"));

      return Promise.all(query);
    },
    _sendReminder(bookingId, displayDate, note) {
      return this.$store.dispatch("ReminderStore/addReminder", {
        bookingId: bookingId,
        data: {
          supplier_id: "GENERAL",
          date: displayDate,
          created_by: this.$store.getters["AccountStore/id"],
          note: note,
        },
      });
    },
    _querySuppliers() {
      return this.$store.dispatch("DatabaseStore/querySuppliers", { type: "ALL" }).then((data) => {
        this.cities = data.map((item) => item.city).filter((city) => city !== null && city !== undefined);
      });
    },
  },
  mounted() {
    this._querySuppliers();
  },
};
</script>
