<template>
  <table style="width: 100%">
    <tbody>
      <v-row class="d-flex justify-space-between" v-for="bs in extractedBookingSuppliers" :key="bs.id">
        <v-col class="d-flex align-start justify-space-between" style="min-width: 50px; max-width: 50px">
          <div>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-icon x-small :class="bs.bleep.color">brightness_1</v-icon>
                  <span v-if="bs.nest_type === 'ALT'" class="caption">/ALT</span>
                </div>
              </template>
              <span>{{ bs.bleep.label }}</span>
            </v-tooltip>
          </div>
        </v-col>

        <v-col cols="2">
          <span>{{ bs.productDate }}</span>
        </v-col>

        <v-col>
          <v-icon small class="mx-3">{{ bs.icon }}</v-icon>
          {{ bs.productName }}
        </v-col>

        <v-col>
          {{ bs.time }}
        </v-col>

        <v-col class="d-flex justify-end">
          <div v-if="bs.showHotel">
            <RoomNumberHover
              :confirmed="true"
              :dateList="bs.dateList"
              :roomBreakdownData="roomBreakdownData"
              :supplierCustomRoom="bs.meta && bs.meta.custom_room_num"
              :bsmeta="bs.meta"
              offsync
            />
          </div>
        </v-col>
      </v-row>
    </tbody>
  </table>
</template>

<script>
import format from "@/services/format";
import { SuppliersMenu } from "@/config/menus";
import { EmailTemplateService } from "@/modules/admin.email_template";
import STATUS_COLORS from "@/config/STATUS_COLORS";
// import RoomNumberHover from '@/modules/bookings.rooms/components/roomNumberHover/RoomNumberHover'
import { RoomNumberHover } from "@/modules/bookings.rooms";

const COLORS = STATUS_COLORS.COLORS;

export default {
  props: {
    supplier: Object,
    bookingSuppliers: Object,
    bookingDays: Array,
    pax: [String, Number],

    roomBreakdownData: Object,
    supplierCustomRoom: Object,
  },
  components: {
    RoomNumberHover,
  },
  computed: {
    extractedBookingSuppliers() {
      let temp, products;

      return this.supplier.bsid
        .map((bsid) => {
          temp = this.bookingSuppliers[bsid];
          products = _extractProducts(temp, this.bookingDays);

          return {
            id: temp.id,
            status: temp.status,
            meta: temp.meta || null,
            time: temp.time ? format.formatTime(temp.time) : "",
            nest_type: temp.nest_type,
            bleep: Object.values(COLORS).find((item) => item.value === temp.status),
            productOptions: products,
            productName: products.map((item) => item.name).join(", "),
            productDate: products.map((item) => item.dates).join(", "),
            firstDay: this.bookingDays.find((item) => {
              return item[SuppliersMenu.MOD_DBKEY[temp.type_as]].find((id) => bsid === id);
            }).date,
            dateList: _extractDates(this.bookingDays, bsid),

            icon: SuppliersMenu.FULL.find((s) => s.module === temp.type_as).icon,

            showHotel: temp.type_as === "HOTEL",
          };
        })
        .sort((a, b) => {
          if (a.firstDay > b.firstDay) return 1;
          if (a.firstDay < b.firstDay) return -1;
          return 0;
        });
    },

    showHotelInfo() {
      return this.maptype === "HOTEL" || this.supplier.hotelType;
    },
    showRestaurantInfo() {
      return (this.maptype === "RESTAURANT" || this.supplier.restaurantType) && !this.showHotelInfo;
    },
    showExcursion() {
      return this.maptype === "EXCURSION" || this.supplier.excursionType;
    },
    showOther() {
      return !this.showHotelInfo && !this.showRestaurantInfo && !this.showExcursion;
    },
  },
};

function _extractDates(bookingDays, bsid) {
  // Extract Dates
  return bookingDays.reduce((arr, day) => {
    if (day.hotels.includes(bsid)) {
      arr.push(day.date);
    }
    return arr;
  }, []);
}

function _extractProducts(bsRef, bookingDays) {
  let customProductName,
    customCount,
    dump = [];

  Object.values(bsRef.products).forEach((product) => {
    customProductName = bsRef.meta && bsRef.meta.custom_names && bsRef.meta.custom_names[product.id];
    customCount = bsRef.meta && bsRef.meta.count && bsRef.meta.count[product.id];

    dump.push({
      ref_id: `${bsRef.id}_${product.id}`,
      product_id: product.id,
      product_type: product.product_type,
      name: customProductName || product.name,
      count: customCount || 1,
      dates: EmailTemplateService._compileDateListReadable(
        _getWhatDays(bsRef.id, bookingDays),
        product.product_type === "ROOM"
      ),
    });
  });

  return dump;
}

function _getWhatDays(bookingSupplierId, bookingDays) {
  // What days supplier appears
  let dateList = [];
  bookingDays.forEach((item) => {
    SuppliersMenu.FULL.forEach((mod) => {
      // Only one module
      if (item[mod.alt] && item[mod.alt].includes(bookingSupplierId)) dateList.push(item.date);
    });
  });
  return dateList;
}
</script>
