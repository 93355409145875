<template>
  <v-form :class="saved ? 'blue' : error ? 'red' : 'white'">
    <v-row :class="saved ? 'blue darken-4 white--text' : error ? 'red darken-4 white--text' : 'grey'">
      <v-col v-for="cell in supplierCells" :key="cell.label" class="pa-1 caption" :style="cell.style">
        {{ cell.label }}
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="cell in supplierCells" :key="cell.key" class="pa-0" :style="cell.style">
        <f-text :value="value[cell.key]" @input="(v) => $emit('input', { cell: cell.key, value: v })" />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  props: {
    saved: Boolean,
    error: Boolean,
    index: Number,
    value: Object,
  },
  data() {
    return {
      supplierCells: [
        {
          label: `${this.index + 1} - Name`,
          key: "name",
        },
        {
          label: "Address",
          key: "address",
        },
        {
          label: "City",
          key: "city",
        },
        {
          label: "Subcity",
          key: "subcity",
        },
        {
          label: "State/Prov",
          key: "subjur",
          style: "max-width:70px",
        },
        {
          label: "Zip Code",
          key: "zip_code",
          style: "max-width:70px",
        },
        {
          label: "Fax",
          key: "fax_number",
        },
        {
          label: "Business #",
          key: "business_number",
        },
        {
          label: "Extension",
          key: "business_extension",
          style: "max-width:70px",
        },
        {
          label: "Website",
          key: "website",
        },
      ],
    };
  },
};
</script>
