<template>
  <v-row class="compare-row black--text" :class="{ 'equal-row': isEqual }">
    <v-col :class="compiledClass" @click="$emit('toggle', 'COMPILED')">
      <CompareDayDisplay :item="compiled" />
    </v-col>
    <v-col :class="computedClass" @click="$emit('toggle', 'SAVED')">
      <CompareDayDisplay :item="computed" />
    </v-col>
  </v-row>
</template>

<script>
import CompareDayDisplay from "./_CompareDayDisplay";
export default {
  props: {
    keep: String,
    compiled: Object,
    computed: Object,
  },
  components: {
    CompareDayDisplay,
  },
  data() {
    return {
      inc: 0,
    };
  },
  computed: {
    isEqual() {
      if (!this.compiled) return false;
      if (!this.computed) return false;

      const a = this.compiled.time + this.compiled.float + this.compiled.fixed;
      const b = this.computed.time + this.computed.float + this.computed.fixed;
      return a === b;
    },
    computedClass() {
      if (this.isEqual) return "unselected";
      return !this.keep || this.keep === "SAVED" ? "selected" : "unselected";
    },
    compiledClass() {
      if (this.isEqual) return "unselected";
      return this.keep === "COMPILED" ? "selected" : "unselected";
    },
  },
};
</script>

<style lang="scss">
.compare-row {
  &.equal-row {
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {
    background: lightgray;
    cursor: pointer;

    .selected {
      background: green;
    }
  }

  .unselected {
  }
  .selected {
    background: lightgreen;
  }
}
</style>
