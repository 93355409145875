<template>
  <div :class="{ 'd-flex full-height': !noflex }">
    <a-config-switch
      v-if="useConfig.inputType === 'BOOLEAN'"
      :label="label"
      :offLabel="useConfig.offLabel"
      :icon="useConfig.icon"
      :value="value"
      @input="(v) => $emit('input', v)"
    />

    <v-row v-else class="a-field">
      <v-col class="label py-0" :class="{ smlabel: smlabel, widelabel: widelabel }">
        <div class="label-wrapper-label">
          <span v-if="label" class="subtitle-2">{{ label }}</span>
          <span v-if="req" class="subtitle-2"> *</span>
          <v-icon v-if="useConfig.icon" small>{{ useConfig.icon }}</v-icon>
        </div>
      </v-col>
      <v-col class="py-0 d-flex" :align-self="midtext ? 'center' : 'start'">
        <FText
          v-if="useConfig.inputType === 'TEXT'"
          :disabled="disabled"
          :value="value"
          @input="(v) => $emit('input', v)"
        />
        <FTextarea
          v-else-if="useConfig.inputType === 'TEXTAREA'"
          :rows="useConfig.rows || '2'"
          :disabled="disabled"
          :value="value"
          :bullets="useConfig.bullets"
          @input="(v) => $emit('input', v)"
        />
        <FDropdown
          v-else-if="useConfig.inputType === 'DROPDOWN'"
          :multiple="useConfig.multiple"
          :dependant="useConfig.dependant"
          :dependantValue="dependantValue"
          :dependantText="useConfig.dependantText"
          :items="dropDownSource"
          :disabled="disabled"
          :value="value"
          :hideClear="useConfig.hideClear"
          @input="(v) => $emit('input', v)"
        />
        <FDate
          v-else-if="useConfig.inputType === 'DATE'"
          maxwidth
          :datewall="datewall"
          :datewallEnd="datewallEnd"
          :disabled="disabled"
          :value="value"
          @input="(v) => $emit('input', v)"
        />
        <FPhone
          v-else-if="useConfig.inputType === 'PHONE'"
          :country="country"
          :disabled="disabled"
          :value="value"
          @input="(v) => $emit('input', v)"
        />
        <FTime
          v-else-if="useConfig.inputType === 'TIME'"
          :disabled="disabled"
          :value="value"
          @input="(v) => $emit('input', v)"
        />
        <FPercent
          v-else-if="useConfig.inputType === 'PERCENT'"
          :disabled="disabled"
          :value="value"
          @input="(v) => $emit('input', v)"
        />
        <FNumber
          v-else-if="useConfig.inputType === 'NUMBER'"
          :disabled="disabled"
          :value="value"
          @input="(v) => $emit('input', v)"
        />
        <e-number
          v-else-if="useConfig.inputType === 'COST' && !clearable"
          :disabled="disabled"
          :env="env"
          :value="value"
          @input="(v) => $emit('input', v)"
        />
        <ClearCyoaBtn v-else-if="useConfig.inputType === 'COST' && clearable" @clear="$emit('input', null)">
          <e-number :disabled="disabled" :env="env" :value="value" @input="(v) => $emit('input', v)" />
        </ClearCyoaBtn>
        <a-config-cyoa
          v-else-if="useConfig.inputType === 'CYOA'"
          :choosePath="useConfig.choosePath"
          :value="value"
          :country="country"
          :env="env"
          :disabled="disabled"
          :config="useConfig.config"
          :cols="useConfig.cols"
          @input="(v) => $emit('input', v)"
        />

        <div v-else-if="useConfig.inputType === 'NO_EDIT'" class="d-flex align-center">
          {{ value }}
        </div>
        <PLink v-if="useConfig.inputType === 'LINK'" :disabled="disabled" :link="value" />

        <div v-if="useConfig.sideLabel" class="caption px-2 d-flex align-center">{{ useConfig.sideLabel }}</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FDate from "./items/FDate";
import FDropdown from "./items/FDropdown";
import FNumber from "./items/FNumber";
import FPercent from "./items/FPercent";
import FPhone from "./phones/FPhone";
import FText from "./items/FText";
import FTextarea from "./items/FTextarea";
import FTime from "./items/FTime";

import AConfigCyoa from "./groups/Cyoa";
import AConfigSwitch from "./special/PSwitch";
import ENumber from "./special/ENumber";
import PLink from "./special/PLink";

import ClearCyoaBtn from "./groups/cyoa/_clearCyoaButton";

export default {
  props: {
    config: {
      type: Object,
      //required: true
    },
    req: Boolean,
    noflex: Boolean,
    src: [String, Object],
    env: String,
    country: String,
    dependantValue: String,
    datewall: [String, Date],
    datewallEnd: [String, Date],

    disabled: Boolean,
    smlabel: Boolean,
    widelabel: Boolean,
    midtext: Boolean,
    value: {
      // ANY TYPE ALLOWED
      required: true,
    },

    customSource: [Array],
    clearable: Boolean,
  },
  components: {
    FDate,
    FDropdown,
    FNumber,
    FPercent,
    FPhone,
    FText,
    FTextarea,
    FTime,

    AConfigCyoa,
    AConfigSwitch,
    ENumber,
    PLink,

    ClearCyoaBtn,
  },
  computed: {
    useConfig() {
      return this.config || {};
    },
    label() {
      if (!this.config) return "";

      if (this.config.getLabel && (this.env || this.country || this.src))
        return this.config.getLabel(this.env, this.country, this.src);
      if (this.config.getLabel) return this.config.noSource || "(No Source)";
      return this.config.label;
    },
    dropDownSource() {
      if (this.customSource) return this.customSource;
      if (!this.config) return [];
      if (!this.config.inputType === "DROPDOWN") return [];
      if (this.config.getSource) {
        return this.config.getSource(this.env, this.country, this.src) || [];
      }
      if (this.config.getOptionListSource) {
        return this.$store.getters["ListStore/" + this.config.getOptionListSource](this.env);
      }
      return this.config.source || [];
    },
    labelAlign() {
      if (!this.config) return "start";
      return this.config.inputType === "TEXTAREA" ? "start" : "center";
    },
  },
};
</script>
