export default {
  // These need to start with extra_
  extra_tips: {
    label: "Tips",
    value: "extra_tips",
    defaultType: "COST",
    inputType: "PRODUCT_EXTRA",
  },
  extra_event_fee: {
    label: "Event Fee",
    value: "extra_event_fee",
    defaultType: "COST",
    inputType: "PRODUCT_EXTRA",
  },
  extra_resort_fee: {
    label: "Extra Fee",
    value: "extra_resort_fee",
    beforeTax: true,
    defaultType: "COST",
    inputType: "PRODUCT_EXTRA",
  },
};
