<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-wrap align-center">
      <v-col style="max-width: 50px">
        <span class="blue--text">{{ group.type }}</span>
      </v-col>
      <v-col>
        <elwrapper label="Field">
          <v-autocomplete
            dense
            outlined
            hide-details
            :items="fieldOptions"
            item-text="label"
            :value="group.columns"
            @change="(v) => updateRow('columns', v)"
          ></v-autocomplete>
        </elwrapper>
      </v-col>

      <v-col>
        <v-row>
          <a-select
            v-if="hasSource"
            label="Value"
            :items="dropdownSource"
            :multiple="multiple"
            :value="group.value"
            @change="(v) => updateRow('value', v)"
          />
          <a-text-field v-else label="Value" :value="group.value" @input="(v) => updateRow('value', v)" />

          <div v-if="showNone" class="d-flex align-center">
            <input type="checkbox" class="mr-2" :checked="group.none" @input="updateRow('none', !group.none)" />
            <span class="caption">(None)</span>
          </div>

          <v-btn v-if="showClear" icon small @click="$emit('popGroup')">
            <v-icon>clear</v-icon>
          </v-btn>
        </v-row>
      </v-col>
    </div>
  </div>
</template>

<script>
import SEARCH from "@/config/fields/SEARCH";

export default {
  props: {
    mod: String,
    dbConfig: String,
    group: Object,
    showClear: Boolean,
    cities: Array,
    subCities: Array,
  },
  computed: {
    fieldOptions() {
      const data = SEARCH[this.dbConfig](this.mod);
      return Object.keys(data).map((key) => {
        return {
          value: key,
          ...data[key],
        };
      });
    },
    source() {
      return this.fieldOptions.find((item) => this.group.columns === item.value) || {};
    },

    hasSource() {
      return !!this.source.source || this.source.getSource;
    },

    dropdownSource() {
      const temp = this.source;
      const env = this.$store.getters["AccountStore/environment"];
      if (temp.optionListSource) return this.$store.getters["ListStore/" + temp.optionListSource](env);
      if (temp.getSource) return temp.getSource(env);
      if (temp.source && temp.storeSource) {
        return this.$store.getters["ListStore/" + temp.storeSource.toUpperCase()](env);
      }
      if (temp.source == "cities") {
        return this.cities;
      }
      if (temp.source == "subCities") {
        return this.subCities;
      }
      return temp.source;
    },
    multiple() {
      return this.source.multiple;
    },

    showNone() {
      return ["contact_name", "email"].includes(this.group.columns);
    },
  },

  methods: {
    updateRow(key, v) {
      this.$emit("change", {
        key: key,
        value: v,
      });
    },
  },
};
</script>
