<template>
  <v-dialog v-model="dialog" persistent fullscreen>
    <template v-slot:activator="{ on }">
      <g-button class="mx-2" :disabled="!editMode" label="Clean Merge" icon="merge_type" @onclick="openDialog" />
    </template>

    <v-card class="supplier-select-wrapper">
      <v-card-title>
        <v-row>
          <v-col>
            <v-row>
              <div>Clean Technical Compiled vs. Custom Saved</div>
              <v-menu open-on-hover bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon text v-bind="attrs" v-on="on">
                    <v-icon>help_outline</v-icon>
                  </v-btn>
                </template>

                <div class="white pa-3">
                  <p>There are two versions of technical.</p>
                  <p>
                    1) What is compiled by the application based on what suppliers, flights, etc. you have added in the
                    booking. Everytime you make changes to the booking, this is updated.
                  </p>
                  <p>
                    2) A copy you make changes to. Every time you edit the technical, add a note, hide or show
                    something, this is updated.
                  </p>
                  <p>
                    These are merged together to create what you can see. In order to prevent unnoticed data loss, we
                    try not to delete stuff in the saved copy that is not present in the raw compiled version. However,
                    this can result in weird side effects, or "merge conflicts," where things in technical are present
                    when they shouldn't be, and it can be difficult to notice them. This wizard is intended to help
                    resolve these conflicts and "clean up" the technical.
                  </p>
                </div>
              </v-menu>
            </v-row>
          </v-col>
          <v-btn icon text @click="dialog = false">
            <v-icon>clear</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-row class="text-h6">
          <v-col>
            <div>Compiled</div>
          </v-col>
          <v-col>
            <div>Saved</div>
          </v-col>
        </v-row>

        <div class="black--text">
          <v-row>
            <v-col class="font-weight-bold underline caps">Attention:</v-col>
            <v-col class="font-weight-bold underline caps">Attention:</v-col>
          </v-row>
          <CompareRow
            :keep="attentionToggle"
            :compiled="{ fixed: defaultHeader }"
            :computed="{ fixed: attentionShow }"
            @toggle="(v) => (attentionToggle = v)"
          />
        </div>

        <div v-for="(date, index) in days" :key="date.date" class="black--text">
          <v-row>
            <v-col>
              <CompareDayHeader :date="date.date" :city="date.city" :dayIndex="index" />
            </v-col>
            <v-col>
              <CompareDayHeader :date="date.date" :city="date.city" :dayIndex="index" />
            </v-col>
          </v-row>

          <CompareRow
            v-for="(row, index) in blob[date.date]"
            :key="date.date + row.mockid"
            :date="date.date"
            :dayIndex="index"
            :keep="mapping[date.date + row.mockid]"
            :compiled="row.compiled"
            :computed="row.computed"
            @toggle="(v) => updateRow(date.date + row.mockid, v)"
          />
        </div>
      </v-card-text>

      <v-card-actions class="d-flex justify-end pa-5">
        <v-btn text class="ma-2" @click="dialog = false">Cancel</v-btn>
        <v-btn color="primary" class="ma-2" @click="apply">Apply</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import Vue from "vue";
import TechnicalService from "../../service";
import CompareRow from "./_CompareRow";
import CompareDayHeader from "./_CompareDayHeader";
export default {
  props: {
    editMode: Boolean,
    days: Array,
    compiledDays: Object,
    savedTechnical: Object,
  },
  components: {
    CompareRow,
    CompareDayHeader,
  },
  data() {
    return {
      dialog: false,
      blob: {},
      attentionToggle: null,
      mapping: {},
    };
  },
  computed: {
    defaultHeader() {
      return this.$store.getters["TextConfigStore/TECHNICAL_ATTENTION"];
    },
    attentionShow() {
      const tech = this.savedTechnical;
      return tech.header || this.defaultHeader;
    },
    displayTechnical() {
      const saved = this.savedTechnical;
      return {
        ..._.omit(saved, ["days"]),
        days: TechnicalService.mergeTechnical(saved.days, this.compiledDays, {}),
      };
    },
  },
  methods: {
    openDialog() {
      this.days.forEach((day) => {
        this.blob[day.date] = this.getDayRows(day.date);
      });
      this.dialog = true;
    },
    getDayRows(date) {
      // Assuming the rows are already sorted by date
      const compiled = this.compiledDays[date];
      const custom = this.displayTechnical.days[date];
      let merged = [];

      if (!compiled) return merged; // Bug catcher

      // Get base
      compiled.forEach((item) => {
        merged.push({
          mockid: item.mockid,
          time: item.time,
          compiled: JSON.parse(JSON.stringify(item)),
        });
      });

      // Add in custom stuff
      custom.forEach((item) => {
        // Find shared mockid + add as custom version
        const focus = _.find(merged, (m) => {
          if (!m.compiled) return false;
          return m.compiled.mockid === item.mockid;
        });
        if (focus) {
          focus.computed = JSON.parse(JSON.stringify(item));
        } else {
          // If not in compiled, add to end
          merged.push({
            mockid: item.mockid,
            time: item.time,
            computed: JSON.parse(JSON.stringify(item)),
          });
        }
      });

      merged = merged.sort((a, b) => {
        if (!b.time || a.time > b.time) return 1;
        if (!a.time || a.time < b.time) return -1;
        return 0;
      });

      // Sort by time
      return merged;
    },
    updateRow(key, value) {
      // Semi bug workaround
      // DOM doesn't update when editing row directly
      // So modifiy at blob level
      Vue.set(this.mapping, key, value);
    },

    apply() {
      let cleanup = {
        ..._.omit(this.savedTechnical, ["days"]),
        days: {},
      };

      if (this.attentionToggle && this.attentionToggle === "COMPILED") {
        cleanup.header = this.defaultHeader;
      }

      Object.keys(this.savedTechnical.days).forEach((date) => {
        let rows = this.savedTechnical.days[date];
        cleanup.days[date] = rows.filter((item) => {
          let map = this.mapping[date + item.mockid];
          if (!map || map === "SAVED") return true;
          return false;
        });
      });

      this.$emit("overwrite", cleanup);
      this.dialog = false;
    },
  },
};
</script>
