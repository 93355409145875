<template>
  <div class="d-flex justify-end">
    <span class="caption px-3">Show:</span>
    <div class="d-flex caption align-center">
      <input type="radio" value="1" v-model="status" />
      <label class="px-2">Active</label>
    </div>

    <div class="d-flex caption align-center">
      <input type="radio" value="0" v-model="status" />
      <label class="px-2">To Be Cancelled</label>
    </div>

    <div class="d-flex caption align-center">
      <input type="radio" value="-1" v-model="status" />
      <label class="px-2">Cancelled</label>

      <div class="d-flex caption align-center">
        <input type="radio" value="2" v-model="status" />
        <label class="px-2">Archived</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
  },
  computed: {
    status: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", `${v}`);
      },
    },
  },
};
</script>
