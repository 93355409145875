<template>
  <v-col cols="1" class="pr-5">
    <v-row class="pa-0">
      <v-col class="pa-0">
        <f-number :value="displayCount" @input="(v) => setCustomCount(v)" />
      </v-col>

      <v-col cols="2" class="pa-0">
        <v-btn icon small v-if="displayLock" @click="toggleCountLock(false)">
          <v-icon icon>lock</v-icon>
        </v-btn>
        <v-btn icon small v-else @click="toggleCountLock(true)">
          <v-icon icon>lock_open</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import Vue from "vue";

export default {
  props: {
    product: Object,
    allCountsLocked: Boolean,
    bookingSupplierMeta: Object,
  },
  computed: {
    productId() {
      return this.product.product_id || this.product.id;
    },
    displayCount() {
      if (!this.bookingSupplierMeta.count) return 1;
      // Default
      return this.bookingSupplierMeta.count[this.productId] || 1;
    },
    displayLock() {
      if (this.allCountsLocked) return true;
      if (!this.bookingSupplierMeta.count_lock) return false;
      // Default
      return this.bookingSupplierMeta.count_lock[this.productId] || false;
    },
  },
  methods: {
    setCustomCount(value) {
      if (!this.bookingSupplierMeta.count) Vue.set(this.bookingSupplierMeta, "count", {});
      Vue.set(this.bookingSupplierMeta.count, this.productId, value);
    },
    toggleCountLock(value) {
      if (!this.bookingSupplierMeta.count_lock) Vue.set(this.bookingSupplierMeta, "count_lock", {});
      Vue.set(this.bookingSupplierMeta.count_lock, this.productId, value);
      if (!value) Vue.set(this.bookingSupplierMeta, "confirmed_offcount", value);
    },
  },
};
</script>
