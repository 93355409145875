const base = [
  {
    title: "Canada",
    module: "CDA",
    alt: "canada",
  },
  {
    title: "United States",
    module: "USA",
    alt: "united states",
  },
];

export default {
  FULL: base,
};
