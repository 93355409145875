<template>
  <v-hover v-slot="{ hover }">
    <v-btn icon @click="$emit('input', !value)">
      <v-icon v-show="!hover" small>{{ value ? "block" : "hotel" }}</v-icon>
      <v-icon v-show="hover" small>{{ value ? "hotel" : "block" }}</v-icon>
    </v-btn>
  </v-hover>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
};
</script>
