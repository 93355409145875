<template>
  <div>
    <v-hover v-slot:default="{ hover }">
      <v-row
        class="justify-space-between color-alt hover over-bb"
        align="center"
        :class="{ 'elevation-15 black-bb': hover && showHover }"
      >
        <v-col style="max-width: 30px" v-if="top">
          <v-btn v-show="nestedSuppliers.length" icon small @click="toggle">
            <v-icon v-if="toggleDrop">arrow_drop_down</v-icon>
            <v-icon v-else>arrow_right</v-icon>
          </v-btn>
        </v-col>
        <v-col class="py-0">
          <SupplierBlock
            :status="status"
            :smstatus="smstatus"
            :data="d"
            :type="type"
            :date="date"
            :bookingDay="bookingDay"
            :bookingSuppliers="suppliers"
            :bookingSupplierRoomList="bookingSupplierRoomList"
            :bookingMeta="bookingMeta"
            :pax="pax_num"
            :prevdata="prevdata"
            :prevdays="prevdays"
            :roomBreakdownData="roomBreakdownData"
            @altstatus="(data) => $emit('altstatus', { supplier: data, type: type })"
            @syncmeta="(v) => $emit('syncmeta', v)"
            @editRoomName="$emit('editRoomName', d)"
            @editConfirm="$emit('editConfirm', d)"
          />
        </v-col>

        <v-col v-if="!status" style="max-width: 60px" class="pa-0 d-flex align-center">
          <v-btn v-if="d.status === -1 || cancelled" x-small icon text color="black" style="pointer-events: none">
            <v-icon small>cancel</v-icon>
          </v-btn>
          <v-btn v-else-if="d.status && d.status < 4" x-small icon text color="blue" style="pointer-events: none">
            <v-icon small>phone_in_talk</v-icon>
          </v-btn>
          <v-btn v-else-if="d.status >= 4" x-small icon text color="green" style="pointer-events: none">
            <v-icon small>check</v-icon>
          </v-btn>
          <v-btn v-else x-small icon text color="green" style="pointer-events: none">
            <span></span>
          </v-btn>

          <v-btn v-if="!d.status || (d.status && d.status < 3)" small icon @click="($event) => removeSupplier($event)">
            <v-icon small>clear</v-icon>
          </v-btn>
          <v-tooltip v-else left>
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-btn small icon disabled>
                  <v-icon small>clear</v-icon>
                </v-btn>
              </div>
            </template>
            <span>Cannot remove until cancelled.</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-hover>

    <div v-show="toggleDrop" class="grey lighten-1" style="padding-left: 30px">
      <slot :nestedSuppliers="nestedSuppliers"></slot>
    </div>
  </div>
</template>

<script>
import { BookingColors } from "@/config/fields/bookings";
import { BookingRoomService } from "@/modules/bookings.rooms";
import SupplierBlock from "./SupplierBlock";
export default {
  props: {
    top: Boolean,
    status: Boolean,
    smstatus: Boolean,
    showHover: Boolean,

    d: Object,
    type: String,
    daynum: Number,
    date: String,
    dbkey: String,
    contentIndex: Number,
    cancelled: Boolean,

    roomBreakdownData: Object,
  },
  components: {
    SupplierBlock,
  },
  data() {
    return {
      toggleDrop: false,
    };
  },
  computed: {
    bookingDay() {
      const dayList = this.$store.getters["BookingDayStore/days"];
      return dayList.find((d) => d.date === this.date);
    },
    bookingMeta() {
      return this.$store.getters["BookingDayStore/data"];
    },
    suppliers() {
      return this.$store.getters["BookingDayStore/suppliers"];
    },
    bookingSupplierRoomList() {
      return this.$store.getters["BookingSupplierRoomStore/supplier_rooms"];
    },
    nestedSuppliers() {
      return this.$store.getters["BookingDayStore/daySupplier"](this.daynum, this.dbkey)
        .map((data, index) => {
          return { ...data, contentIndex: index };
        }) // MUST be before filter (used in delete)
        .filter((v) => v.parent_bsid === this.d.id)
        .sort((a, b) => {
          if (a.time > b.time) return 1;
          if (a.time < b.time) return -1;
          return 0;
        });
    },
    prevdata() {
      let temp = this.$store.getters["BookingStore/oldDates"].old_suppliers;
      return this.d ? temp[this.d.id] : null;
    },
    prevdays() {
      return this.$store.getters["BookingStore/oldDates"].old_days;
    },
    pax_num() {
      // Get CURRENT Pax
      return this.$store.getters["BookingStore/pax_num"];
    },
  },
  methods: {
    removeSupplier(e) {
      e.stopPropagation();
      this.$emit("remove", {
        supplierData: this.d,

        daynum: this.daynum,
        dbkey: this.dbkey,
        typeAs: this.type,
        contentIndex: this.contentIndex,
      });
    },
    toggle(e) {
      e.stopPropagation();
      this.toggleDrop = !this.toggleDrop;
    },
  },
};
</script>

<style lang="scss">
.over-bb {
  border: 2px outset transparent;
  &.black-bb {
    border: 2px outset grey;
  }
}
</style>
