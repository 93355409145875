<template>
  <div>
    <Calendar
      :mode="mode"
      :addButton="addButton"
      :dateStart="dateStart"
      :focusDate="focusDate"
      :reminderMapping="reminderMapping"
      @add="$emit('add')"
      @onfocus="(v) => $emit('onfocus', v)"
      @updateDates="(v) => $emit('updateDates', v)"
    />
  </div>
</template>

<script>
import Calendar from "./_Calendar";
export default {
  props: {
    mode: String, // ALL, BOOKING
    addButton: Boolean,
    reminderMapping: Object,
    dateStart: String,
    focusDate: String,
  },
  components: {
    Calendar,
  },
  data() {
    return {};
  },
};
</script>
