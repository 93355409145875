<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)" persistent>
    <v-card>
      <v-toolbar depressed flat>
        <span class="headline">Flights</span>
        <div class="flex-grow-1"></div>
      </v-toolbar>

      <v-card-text>
        <div v-for="(f, index) in displayList" :key="index" class="d-flex justify-center align-start">
          <div class="pt-2">
            <v-icon small class="mr-3">flight</v-icon>
          </div>
          <a-date label="Date" :datewall="datewall" :datewallEnd="datewallEnd" v-model="f.date" />

          <span class="subtitle-1 pr-3">Airport</span>
          <div>
            <a-text-field label="Departing" v-model="f.depart_airport" />
            <a-text-field label="Arriving" v-model="f.arrive_airport" />
          </div>

          <span class="subtitle-1 pr-3">Time</span>
          <div>
            <elwrapper label="Departure">
              <f-time v-model="f.depart_time" />
            </elwrapper>
            <elwrapper label="Arrival">
              <f-time v-model="f.arrive_time" />
            </elwrapper>
          </div>

          <a-text-field label="Flight #" v-model="f.flight_num" />

          <v-btn small icon @click="deleteRow(index)">
            <v-icon small>clear</v-icon>
          </v-btn>
        </div>
        <add-button @click="addRow" />
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <g-button label="Cancel" @onclick="$emit('input', false)" />
        <g-button type="primary" label="Update" @onclick="eject" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { BookingFields } from "@/config/fields/bookings";
export default {
  props: {
    value: Boolean,
    datewall: [String, Date],
    datewallEnd: [String, Date],
    flightList: Array,
    flightType: String,
  },
  data() {
    return {
      config: BookingFields.flight,
      inputList: [],
    };
  },
  watch: {
    value(v) {
      if (!v) return;
      this.inputList = JSON.parse(JSON.stringify(this.flightList));
    },
  },
  computed: {
    displayList() {
      return this.inputList.filter((item) => {
        if (!item.flight_type && this.flightType === "DOMESTIC") return true;
        return item.flight_type === this.flightType;
      });
    },
  },
  methods: {
    addRow() {
      // Add empty row
      this.inputList.push({
        flight_type: this.flightType,
        date: "",
        depart_airport: "",
        arrive_airport: "",
        depart_time: "",
        arrive_time: "",
        flight_num: "",
      });
    },
    deleteRow(index) {
      this.inputList.splice(index, 1);
    },
    eject() {
      this.$emit("update", this.inputList);
      this.$emit("input", false);
    },
  },
};
</script>
