import Vue from "vue";
import api from "./api";
import service from "./service";

const SET_ROOMS = "SET_ROOMS";
const TRANSFER_ROOM = "TRANSFER_ROOM";
const UPDATE_ROOM = "UPDATE_ROOM";

// mods
const ADD_ROOM = "ADD_ROOM";
const POP_ROOM = "POP_ROOM";
const SET_SUPPLIER_ROOMS = "SET_SUPPLIER_ROOMS";

const namespaced = true;

let cached = _getRooms();
const state = {
  rooms: cached.rooms,
  cache: cached,
};

function _getRooms() {
  return JSON.parse(localStorage.getItem("booking_rooms") || "{}");
}

const mutations = {
  SET_ROOMS(state, rooms) {
    state.rooms = rooms.rooms || [];
    state.rooms.forEach((r) => {
      if (!r.meta) r.meta = { request: "" };
    });

    state.cache = JSON.stringify({
      rooms: state.rooms,
    });
    localStorage.setItem("booking_rooms", state.cache);
  },
  TRANSFER_ROOM(state) {
    state.cache = JSON.stringify({
      rooms: state.rooms,
    });
    localStorage.setItem("booking_rooms", state.cache);
  },
  UPDATE_ROOM(state, { room_index, key, val }) {
    Vue.set(state.rooms[room_index], key, val);
  },

  ADD_ROOM(state, room) {
    state.rooms.push(room);
  },
  POP_ROOM(state, index) {
    state.rooms.splice(index, 1);
  },
};

const actions = {
  setRooms({ commit }, rooms) {
    commit(SET_ROOMS, rooms);
  },
  update({ commit }, { room_index, key, val }) {
    commit(UPDATE_ROOM, { room_index, key, val });
  },
  addRoom({ commit }, room) {
    commit(ADD_ROOM, room);
  },
  popRoom({ commit }, index) {
    commit(POP_ROOM, index);
  },

  getBookingRoomBreakdown({ commit }, booking_id) {
    return api
      .getBookingRoomBreakdown(booking_id)
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },

  saveRooms({ commit, state }, booking_id) {
    return api
      .updateRoom(booking_id, state)
      .then((v) => {
        commit(TRANSFER_ROOM);
      })
      .catch((err) => {
        if (err) throw err.data;
      });
  },
};

const getters = {
  rooms: (state) => (state.rooms ? state.rooms : []),
  totalRooms: (state) => {
    if (!state.rooms) return 0;
    return state.rooms.reduce((total, item) => {
      if (item.meta && item.meta.cancelled) return total;
      return total + 1;
    }, 0);
  },
  totalPax: (state) => {
    if (!state.rooms) return 0;
    let total = state.rooms.reduce((total, item) => {
      if (item.meta && item.meta.cancelled) return total;
      return total + Number(item.pax);
    }, 0);
    return total;
  },

  roomBreakdown: (state) => {
    if (!state.rooms) return {};
    let o = {};
    state.rooms.forEach((item) => {
      if (item.meta && item.meta.cancelled) return;
      if (!o[item.type]) {
        o[item.type] = 1;
      } else {
        o[item.type] += 1;
      }
    });
    return o;
  },

  hasChanges: (state) => {
    return JSON.stringify(state) !== localStorage.getItem("booking_rooms");
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
