<template>
  <v-navigation-drawer permanent color="grey lighten-3" width="150">
    <v-list dense nav class="pa-0">
      <v-list-item
        v-for="item in baseList"
        :key="item.label"
        :to="item.to"
        class="py-0"
        @click="$emit('updateSource', item)"
      >
        <v-list-item-content>
          <v-list-item-title>{{ item.label }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-for="item in dayList"
        :key="item.label"
        :to="item.to"
        class="py-0"
        @click="$emit('updateSource', item)"
      >
        <v-list-item-content>
          <v-list-item-title>
            <span>{{ item.day }}:</span>
            <span class="mx-2 font-weight-light">{{ item.date }}</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    quoteId: String,
  },
  data() {
    return {};
  },
  computed: {
    baseList() {
      return [
        {
          label: "Main",
          to: { name: "bookings_view", params: { booking_id: this.quoteId } },
        },
        {
          label: "All Days",
          to: { name: "bookings_days", params: { booking_id: this.quoteId } },
        },
      ];
    },
    dayList() {
      return this.$store.getters["BookingDayStore/dayLinkList"](this.quoteId);
    },
  },
};
</script>
