<template>
  <div>
    <v-row class="custom-price-row" v-for="p in productPriceList" :key="p.id">
      <ProductCustomName :product="p" :bookingSupplierMeta="bookingSupplierMeta" />
      <ProductCustomCount
        v-if="showCount"
        :product="p"
        :allCountsLocked="allCountsLocked"
        :bookingSupplierMeta="bookingSupplierMeta"
      />

      <v-col v-if="p.seasonal">
        <CustomPriceRow
          v-for="(r, index) in p.price"
          :key="index"
          :type="type"
          :country="supplier.country"
          :disabled="disabled"
          :product="products[p.product_id]"
          :showKind="p.rate_kind && index === p.price.length - 1"
          :price="r"
          :supplierMeta="p.supplier_meta"
          :custom="customPrice[p.product_id] ? customPrice[p.product_id][index] : null"
          :bookingSupplierMeta="bookingSupplierMeta"
          :bookingDayYear="bookingDayYear"
          @clear="removeCustom(p.product_id, index)"
          @set="(v) => setCustom(v, p, index)"
        />
      </v-col>
      <v-col v-else>
        <CustomPriceRow
          :type="type"
          :country="supplier.country"
          :disabled="disabled"
          :product="products[p.product_id]"
          :showKind="!!p.rate_kind"
          :price="p.price"
          :supplierMeta="p.supplier_meta"
          :custom="customPrice[p.product_id]"
          :bookingSupplierMeta="bookingSupplierMeta"
          :bookingDayYear="bookingDayYear"
          @clear="removeCustom(p.product_id)"
          @set="(v) => setCustom(v, p)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import format from "@/services/format";
import ProductCustomName from "./custom/__ProductCustomName";
import ProductCustomCount from "./custom/__ProductCustomCount";
import CustomPriceRow from "./custom/__CustomPriceRow";
import { ProductService } from "@/modules/products";

export default {
  props: {
    showCount: Boolean,

    type: String,
    supplier: Object,
    products: Object,
    useDate: Object,
    bookingSupplierMeta: Object,

    bookingDayYear: String,
    allCountsLocked: Boolean,

    disabled: Boolean,
  },
  components: {
    ProductCustomName,
    ProductCustomCount,
    CustomPriceRow,
  },
  computed: {
    customPrice() {
      return this.bookingSupplierMeta.custom_prices || {};
    },
    productPriceList() {
      let price,
        priceList = [],
        supplierMeta,
        country;

      Object.values(this.products).forEach((item) => {
        // HACKY BUG FIX
        if (!item.rate && item.rate_history) {
          item.rate = item.rate_history.find((v) => v.rate_year === this.bookingDayYear).rate;
        }

        supplierMeta = this.supplier.supplier_meta || item.supplier_meta;
        country = this.supplier.country;

        price = ProductService.getPrice(
          item,
          supplierMeta,
          country,
          null,
          this.$store.getters,
          true,
          this.useDate,
          this.bookingSupplierMeta
        );
        priceList.push({
          product_id: item.id,
          name: item.name,
          type: item.product_type,
          seasonal: item.seasonal,
          rate_kind: item.meta ? item.meta.rate_kind : "",
          supplier_meta: supplierMeta,
          rate: item.rate,
          price: price,
          meta: item.meta && typeof item.meta === "string" ? JSON.parse(item.meta) : item.meta,
        });
      });

      return priceList;
    },
  },
  methods: {
    setCustom(value, product, index) {
      if (!this.bookingSupplierMeta.custom_prices) Vue.set(this.bookingSupplierMeta, "custom_prices", {});
      const customRef = this.bookingSupplierMeta.custom_prices;

      const productId = product.id || product.product_id;
      // Revert
      if (!value) {
        if (isNaN(index)) {
          delete customRef[productId];
        } else {
          delete customRef[productId][index];
        }
        return;
      }

      // Not seasonal
      if (isNaN(index)) {
        // To show in view
        Vue.set(customRef, productId, value);
        return;
      }

      // Seasonal
      if (!customRef[productId]) Vue.set(customRef, productId, {});
      Vue.set(customRef[productId], index, value);
    },
    removeCustom(product_id, index) {
      if (isNaN(index)) {
        Vue.delete(this.bookingSupplierMeta.custom_prices, product_id);
        return;
      }
      Vue.delete(this.bookingSupplierMeta.custom_prices[product_id], index);
    },
  },
};
</script>

<style lang="scss">
.custom-price-row {
  border-bottom: 1px solid lightgrey;

  &:focus-within {
    color: #1976d2;
  }
}
</style>
