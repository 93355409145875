<template>
  <div class="d-flex">
    <div v-if="hasMultipleProducts">
      <v-tooltip top left>
        <template v-slot:activator="{ on }">
          <v-btn
            x-small
            v-on="on"
            :text="!isSplit"
            :outlined="isSplit"
            :color="isSplit ? 'primary' : ''"
            @click="(e) => toggleButton(e, 'SPLIT')"
            >split</v-btn
          >
        </template>
        <span>Seperate email for each</span>
      </v-tooltip>

      <v-tooltip top left>
        <template v-slot:activator="{ on }">
          <v-btn
            x-small
            v-on="on"
            :text="!isGroup"
            :outlined="isGroup"
            :color="isGroup ? 'primary' : ''"
            @click="(e) => toggleButton(e, 'GROUP')"
            >group</v-btn
          >
        </template>
        <span>All in one email</span>
      </v-tooltip>
    </div>
    <v-btn v-else x-small text disabled>one</v-btn>
  </div>
</template>

<script>
export default {
  props: {
    compileType: String,

    hasMultipleProducts: Boolean,
  },
  computed: {
    isSplit() {
      return this.compileType === "SPLIT";
    },
    isGroup() {
      return this.compileType === "GROUP";
    },
  },
  methods: {
    toggleHide(e) {
      e.stopPropagation();
      this.$emit("toggleHide");
    },
    toggleButton(e, val) {
      e.stopPropagation();

      this.$emit("setProductOption", val);
    },
  },
};
</script>
