<template>
  <v-navigation-drawer permanent color="grey lighten-3" width="180">
    <v-list dense nav class="pa-0">
      <v-list-item v-for="item in baseList" :key="item.label" :to="item.to" class="py-0">
        <v-list-item-content>
          <v-list-item-title>{{ item.label }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-for="(item, dayIndex) in dayList" :key="item.label" :to="item.to" class="py-0">
        <v-list-item-content>
          <v-list-item-title>
            <span>{{ item.day }} ({{ item.weekday }}):</span>
            <span class="mx-2 font-weight-light">{{ item.date }}</span>

            <HoverAddRemove v-if="config === 'QUOTE'" :dayIndex="dayIndex" :hookId="hookId" />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import HoverAddRemove from "./_HoverAddRemove";
export default {
  props: {
    config: String,
    hookId: String,
  },
  components: {
    HoverAddRemove,
  },
  computed: {
    cstore() {
      return {
        BOOKING: {
          VIEW: { name: "bookings_view", params: { booking_id: this.hookId } },
          OVERVIEW: { name: "bookings_overview", params: { booking_id: this.hookId } },
          DAYS: { name: "bookings_days", params: { booking_id: this.hookId } },
          STORE: "BookingDayStore",
        },
        QUOTE: {
          VIEW: { name: "quotes_view", params: { quote_id: this.hookId } },
          OVERVIEW: { name: "quotes_overview", params: { quote_id: this.hookId } },
          DAYS: { name: "quotes_days", params: { quote_id: this.hookId } },
          STORE: "QuoteDayStore",
        },
      }[this.config];
    },
    baseList() {
      return [
        {
          label: "Main",
          to: this.cstore.VIEW,
        },
        {
          label: "Overview",
          to: this.cstore.OVERVIEW,
        },
        {
          label: "All Days",
          to: this.cstore.DAYS,
        },
      ];
    },
    dayList() {
      return this.$store.getters[`${this.cstore.STORE}/dayLinkList`](this.hookId);
    },
  },
};
</script>
