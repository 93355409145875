import axios from "@/plugins/axios";

export default {
  getDocument(id) {
    return axios.get(`/finance_documents/${id}`);
  },
  getDocumentList(booking_id) {
    return axios.get(`/reports/${booking_id}/documents`);
  },

  addDocument(booking_id, { name, file }) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("file", file);
    return axios.post(`/reports/${booking_id}/documents`, formData, {
      "Content-Type": "multipart/form-data",
    });
  },
  updateDocument(id, { display_name, old_name, dependent_id }) {
    // Note: CANNOT replace actual document
    // Other specifics (eg. Name) only
    return axios.post(`/finance_documents/${id}`, {
      display_name,
      old_name,
      booking_id: dependent_id,
    });
  },
  deleteDocument(id) {
    return axios.delete(`/finance_documents/${id}`);
  },
  deleteDocumentList(idList) {
    return axios.delete("/finance_documents", {
      data: { document_list: idList },
    });
  },
};
