import Vue from "vue";
import api from "./api";

const SET_SUPPLIER = "SET_SUPPLIER";
const UPDATE_SUPPLIER = "UPDATE_SUPPLIER";
const UPDATE_SUPPLIER_META = "UPDATE_SUPPLIER_META";
const TRANSFER_SUPPLIER = "TRANSFER_SUPPLIER";

const SET_YEAR = "SET_YEAR";

const SET_CONTACT_LIST = "SET_CONTACT_LIST";
const ADD_CONTACT = "ADD_CONTACT";
const UPDATE_CONTACT = "UPDATE_CONTACT";
const DELETE_CONTACT_LIST = "DELETE_CONTACT_LIST";
const UPDATE_PRIMARY = "UPDATE_PRIMARY";

const namespaced = true;

const state = {
  current: _getSupplier(),
  cached: _getSupplier(),
  use_year: `${new Date().getFullYear()}`,

  contacts: _getContacts(),
};

function _getSupplier() {
  return JSON.parse(localStorage.getItem("supplier") || "{}");
}
function _getContacts() {
  return JSON.parse(localStorage.getItem("supplier_contacts") || "{}");
}

const mutations = {
  SET_SUPPLIER(state, data) {
    state.current = data.supplier || {};
    state.contacts = data.contacts || {};
    state.cached = JSON.parse(JSON.stringify(data.supplier));

    localStorage.setItem("supplier", JSON.stringify(state.current));
    localStorage.setItem("supplier_contacts", JSON.stringify(state.contacts));
  },
  UPDATE_SUPPLIER(state, data) {
    Object.assign(state.current, data);
  },
  UPDATE_SUPPLIER_META(state, { key, value }) {
    if (!state.current.meta) state.current.meta = {};
    Vue.set(state.current.meta, key, value);
  },
  TRANSFER_SUPPLIER(state) {
    state.cached = Object.assign({}, state.current);
    localStorage.setItem("supplier", JSON.stringify(state.current));
  },

  SET_YEAR(state, year) {
    state.use_year = year;
  },

  // Contacts
  UPDATE_PRIMARY(state, nuId) {
    var newPrimary = state.contacts.all_contacts.find((x) => x.id === nuId);
    Vue.set(state.contacts, "primary_contact", newPrimary);
    localStorage.setItem("supplier_contacts", JSON.stringify(state.contacts));
  },
  SET_CONTACT_LIST(state, contactList) {
    Vue.set(state.contacts, "all_contacts", contactList);
    localStorage.setItem("supplier_contacts", JSON.stringify(state.contacts));
  },
  ADD_CONTACT(state, contact) {
    state.contacts.all_contacts.push(contact);
    localStorage.setItem("supplier_contacts", JSON.stringify(state.contacts));
  },
  UPDATE_CONTACT(state, { id, contact }) {
    var index = state.contacts.all_contacts.findIndex((x) => x.id === id);
    const updatedContact = Object.assign({ ...state.contacts.all_contacts[index] }, contact);
    state.contacts.all_contacts[index] = updatedContact;

    // In case seperate object
    if (id === state.contacts.primary_contact.id) {
      state.contacts.primary_contact = updatedContact;
    }
    localStorage.setItem("supplier_contacts", JSON.stringify(state.contacts));
  },
  DELETE_CONTACT_LIST(state, idList) {
    state.contacts.all_contacts = state.contacts.all_contacts.filter(function (item) {
      return idList.indexOf(item.id) === -1;
    });
    localStorage.setItem("supplier_contacts", JSON.stringify(state.contacts));
  },
};

const actions = {
  setFocusSupplier({ commit }, data) {
    commit(SET_SUPPLIER, data);
    commit(SET_YEAR, `${new Date().getFullYear()}`);
  },
  setUpdate({ commit }, data) {
    commit(UPDATE_SUPPLIER, data);
  },
  setUpdateMeta({ commit }, { key, value }) {
    commit(UPDATE_SUPPLIER_META, { key, value });
  },
  updateFocusYear({ commit }, year) {
    commit(SET_YEAR, year);
  },

  // Contact Controls
  setContactList({ commit }, contactList) {
    commit(SET_CONTACT_LIST, contactList);
  },
  insertContact({ commit }, contactData) {
    commit(ADD_CONTACT, contactData);
  },
  updateContact({ commit }, contact) {
    commit(UPDATE_CONTACT, {
      id: contact.id,
      contact: contact,
    });
  },
  removeContacts({ commit }, contactIdList) {
    commit(DELETE_CONTACT_LIST, contactIdList);
  },
  changePrimary({ commit }, new_primary_id) {
    commit(UPDATE_PRIMARY, new_primary_id);
  },

  // Supplier Controls
  getSupplier({ commit }, id) {
    return api
      .getSupplier(id)
      .then((data) => {
        commit(SET_SUPPLIER, data.data);
        return data.data;
      })
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  copySupplier({ commit }, id) {
    return api
      .copySupplier(id)
      .then((data) => {
        // Returns new supplier id
        return data.data;
      })
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  updateSupplierById({ commit }, { supplierId, data, cache }) {
    return api.updateSupplier(supplierId, data, cache).catch((err) => {
      if (err) throw err.data;
    });
  },
  updateSupplier({ commit, state }, data) {
    let update = data ? data : state.current;

    return api
      .updateSupplier(update.id, update, state.cached)
      .then((res) => (data ? null : commit(TRANSFER_SUPPLIER)))
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  updateSupplierType({ commit, state }, nutype) {
    commit(UPDATE_SUPPLIER, { key: "supplier_type", val: nutype });

    return api
      .updateSupplierType(state.current.id, nutype, state.cached.supplier_type)
      .then((res) => commit(TRANSFER_SUPPLIER))
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  resetPid({ commit, state }, id) {
    return api.resetPid(id || state.current.id).catch((err) => {
      if (err) throw err.data;
    });
  },
  deleteSupplier({ commit, state }, id) {
    return api
      .deleteSupplier(id ? id : state.current.id)
      .then((res) => {
        // Clear this supplier if it's the focus
        if (state.current.id === id) {
          commit(SET_SUPPLIER, null);
        }
      })
      .catch((err) => {
        if (err) throw err.data;
      });
  },

  // Check if supplier is used in booking
  checkSupplierInBooking({ commit }, supplier_id) {
    return api
      .checkSupplierInBooking(supplier_id)
      .then((res) => res.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
};

const getters = {
  id: (state) => state.current.id || "",
  pid: (state) => state.current.pid || "",
  name: (state) => state.current.name || "",
  type: (state) => state.current.supplier_type || "",
  active: (state) => state.current.active || 0,
  search_types: (state) => state.current.search_types || [],
  env: (state) => state.current.env || "",
  search_env: (state) => state.current.search_env || [],

  use_year: (state) => state.use_year,

  address: (state) => state.current.address || "",
  city: (state) => state.current.city || "",
  subcity: (state) => state.current.subcity || "",
  region: (state) => state.current.region || "",
  op_area: (state) => state.current.op_area || "",
  subjur: (state) => state.current.subjur || "",
  country: (state) => state.current.country || "",
  zip_code: (state) => state.current.zip_code || "",

  all_contacts: (state) => state.contacts.all_contacts || [],
  primary_contact: (state) => state.contacts.primary_contact || {},
  fax_number: (state) => state.current.fax_number || "",
  business_number: (state) => state.current.business_number || "",
  website: (state) => state.current.website || "",

  social_security: (state) => state.current.social || null,

  meta: (state) => (state.current ? state.current.meta || {} : {}),
  comments: (state) => (state.current ? state.current.comments || "" : ""),
  social_responsibilities: (state) => (state.current ? state.current.social_responsibilities || "" : ""),

  needsPidChange: (state) => {
    const current = JSON.stringify(getPidInformation(state.current));
    const cached = JSON.stringify(getPidInformation(state.cached));
    if (current === cached) return false;
    return true;
  },
  hasChanges: (state) => {
    let current = JSON.stringify(state.current);
    let cached = JSON.stringify(state.cached);
    if (current === cached) return false;
    return true;
  },
};

function getPidInformation(sup) {
  return {
    name: sup.name,
    city: sup.city,
    subcity: sup.subcity,
    region: sup.region,
    op_area: sup.op_area,
    subjur: sup.subjur,
    country: sup.country,
  };
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
