<template>
  <v-form>
    <v-row>
      <v-col style="max-width: 30px"><!--SPACER--></v-col>
      <v-col
        v-for="cell in refCells"
        :key="cell.label"
        class="pa-1 caption"
        :class="saved ? 'blue darken-4 white--text' : error ? 'red darken-4 white--text' : 'grey'"
        :style="cell.style"
      >
        {{ cell.label }}
      </v-col>
    </v-row>
    <v-row>
      <v-col style="max-width: 30px"><!--SPACER--></v-col>
      <v-col
        v-for="cell in refCells"
        :key="cell.key"
        class="pa-0"
        :class="saved ? 'blue' : error ? 'red' : 'white'"
        :style="cell.style"
      >
        <f-text :value="value[cell.key]" @input="(v) => $emit('input', { cell: cell.key, value: v })" />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  props: {
    saved: Boolean,
    error: Boolean,
    refCells: Array,
    value: Object,
  },
};
</script>
