import axios from "@/plugins/axios";

export default {
  getAllQuoteDays(quote_id) {
    return axios.get(`/quotes/${quote_id}/days`);
  },

  createQuoteSupplier(quote_id, type_as, supplier, product_year) {
    return axios.post(`/quote_suppliers`, {
      quote_id,
      type_as,
      supplier,
      product_year,
    });
  },
  updateQuoteDays(quote_id, data, historyBlob) {
    return axios.post(`/quotes/${quote_id}/days`, { days: data, historyBlob });
  },
  updateQuoteDaySupplier(quote_supplier_id, data) {
    return axios.post(`/quote_suppliers/${quote_supplier_id}`, data);
  },
  removeQuoteSupplier(quote_supplier_id, data) {
    return axios.delete(`/quote_suppliers/${quote_supplier_id}`, { data: data });
  },

  // CHANGE QUOTE DAY LENGTH
  insertDaysIntoQuote(quote_id, dayIndex) {
    return axios.post(`/quotes/${quote_id}/order`, { dayIndex });
  },
  removeDaysFromQuote(quote_id, dayIndex) {
    return axios.delete(`/quotes/${quote_id}/order/${dayIndex}`);
  },
};
