import Vue from "vue";
import api from "./api";

const namespaced = true;

const UPDATE_SEARCH = "UPDATE_SEARCH";
const APPLY_SEARCH = "APPLY_SEARCH";
const RESET_SEARCH = "RESET_SEARCH";
const SAVE_RESULT = "SAVE_RESULT";
const SAVE_FINANCE_TABLE_HEADER = "SAVE_FINANCE_TABLE_HEADER";

const today = new Date();
const todayYear = today.getUTCFullYear();
const cutoffDate = new Date(Date.UTC(todayYear, 2, 1)); // March 1st of this year in UTC
const year = today > cutoffDate ? todayYear : todayYear - 1;
const defaultDate = new Date(Date.UTC(year, 0, 1));

const state = {
  searchRecord: {
    supplier: {}, // Object
    general: {
      from: defaultDate,
      clientPaid: null,
      recondStatus: null,
    }, // Object
  },
  appliedSearch: "",
  // tab: Which tab is "active"
  data: {}, // search blob
};

const mutations = {
  APPLY_SEARCH(state, { tab, data }) {
    state.appliedSearch = tab;
    Vue.set(state.searchRecord, tab, data);
  },
  UPDATE_SEARCH(state, { tab, data }) {
    Vue.set(state.searchRecord, tab, { ...data });
  },
  RESET_SEARCH(state) {
    state.searchRecord = {
      supplier: {},
      general: {},
    };

    state.appliedSearch = "";
  },
  SAVE_RESULT(state, data) {
    Vue.set(state.data, "data", data);
  },
  SAVE_FINANCE_TABLE_HEADER(state, data) {
    Vue.set(state.data, "header", data);
  },
};

const actions = {
  updateSearch({ commit }, { tab, data }) {
    commit(UPDATE_SEARCH, { tab, data });
  },
  applySearch({ commit }, { tab, data }) {
    commit(APPLY_SEARCH, { tab, data });
  },
  resetSearch({ commit }) {
    commit(RESET_SEARCH);
  },
  saveResult({ commit }, data) {
    commit(SAVE_RESULT, data);
  },
  saveFinanceTableHeader({ commit }, data) {
    commit(SAVE_FINANCE_TABLE_HEADER, data);
  },

  getFinances({ commit }, data) {
    return api
      .queryFinances(data)
      .then((res) => res.data)
      .catch((err) => err.data);
  },
  getGroupListWithClients({ commit }) {
    return api
      .getGroupListWithClients()
      .then((res) => res.data)
      .catch((err) => err.data);
  },
};

const getters = {
  // Search Tab data
  generalSearch: (state) => state.searchRecord.general,
  supplierSearch: (state) => state.searchRecord.supplier,

  // Search Result data
  data: (state) => state.data.data,
  tableHeader: (state) => state.data.header,

  // Applied Search stuff
  activeTab: (state) => state.appliedSearch,
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
