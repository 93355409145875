<template>
  <div class="black--text">
    <div>
      <v-row class="my-2" justify="space-between">
        <span class="subtitle-2 grey--text"> Alternative/Backup Suppliers</span>
        <g-button icon="add" label="Add" @onclick="dialog = true" />
      </v-row>

      <div v-if="swappingInProgress" class="mb-3">
        <div class="text-center subtitle-1">Swapping Suppliers. Please wait...</div>
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </div>
      <v-row v-if="!nestedBookingSupplierIds.length" class="grey lighten-2 pa-3"> (No Alternate Suppliers) </v-row>
    </div>

    <div class="table">
      <v-row v-for="bsid in nestedBookingSupplierIds" :key="bsid">
        <v-col cols="1">
          <v-btn small text @click="swapSupplierWithParent(bsid)">
            <v-icon>swap_vert</v-icon>
            <span>Swap</span>
          </v-btn>
        </v-col>
        <SupplierBlock
          style="border: 1px solid grey"
          class="px-2"
          :data="suppliers[bsid]"
          :type="module"
          :date="dateInfo.date"
          :bookingDay="bookingDay"
          :bookingSuppliers="suppliers"
          :bookingSupplierRoomList="bookingSupplierRoomList"
          :bookingMeta="bookingMeta"
          :pax="pax_num"
        />
        <v-col cols="1">
          <v-btn small icon @click="removeBookingSupplier(bsid)">
            <v-icon small>clear</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <v-row class="mx-0 pt-3 button-row" align-self="end" justify="start">
      <g-button label="Close" icon="clear" @onclick="$emit('close')" />
    </v-row>

    <SelectSupplierDialog
      v-model="dialog"
      nested
      nestedType="ALT"
      :module="module"
      :dbkey="dbkey"
      :parentBookingSupplierId="parentBookingSupplierId"
      :dayIndex="dayIndex"
      :bookingDayYear="bookingDayYear"
      :startDay="dateInfo.date"
      @postadd="passUpAdded"
    />
  </div>
</template>

<script>
import format from "@/services/format";
import { SuppliersMenu } from "@/config/menus";
import { SupplierBlock } from "@/modules/bookings.days";
import { BookingRoomService } from "@/modules/bookings.rooms";
export default {
  props: {
    parentBookingSupplierId: String,
    nestedBookingSupplierIds: Array,

    // Regular stuff
    module: String,
    dayIndex: Number,
    bookingDayYear: String,
  },
  components: {
    SupplierBlock,
    // Recursive nesting
    SelectSupplierDialog: () => import("@/modules/bookings.select_dialog/components/SelectSupplierDialog.vue"),
  },
  data() {
    return {
      dialog: false,
      swappingInProgress: false,
      nestedAlts: [],
    };
  },
  computed: {
    dbkey() {
      return SuppliersMenu.MOD_DBKEY[this.module];
    },
    dateInfo() {
      return this.$store.getters["BookingDayStore/day"](this.dayIndex);
    },
    lastDay() {
      return this.$store.getters["BookingDayStore/lastDay"].date;
    },

    // ----------
    // DISPLAY STUFF
    // ----------
    bookingDay() {
      const dayList = this.$store.getters["BookingDayStore/days"];
      return dayList.find((d) => d.date === this.dateInfo.date);
    },
    bookingMeta() {
      return this.$store.getters["BookingDayStore/data"];
    },
    suppliers() {
      return this.$store.getters["BookingDayStore/suppliers"];
    },
    bookingSupplierRoomList() {
      return this.$store.getters["BookingSupplierRoomStore/supplier_rooms"];
    },
    pax_num() {
      // Get CURRENT Pax
      return this.$store.getters["BookingStore/pax_num"];
    },
  },
  methods: {
    passUpAdded(bsid) {
      this.$emit("addnest", bsid);
    },
    swapSupplierWithParent(bsid) {
      this.swappingInProgress = true;
      this.$root.$loading.open("Swapping supplier");
      this.$store
        .dispatch("BookingDayStore/swapParentAndChildSupplier", {
          bookingId: this.$store.getters["BookingStore/booking_id"],
          childid: bsid,
          parentid: this.parentBookingSupplierId,
          nestedBookingSupplierIds: this.nestedBookingSupplierIds,
        })
        .then((v) => {
          this.swappingInProgress = false;
          this.$emit("post-swap");
          this.$root.$success("Suppliers switched");
          this.$root.$loading.end();
        })
        .catch((err) => {
          this.swappingInProgress = false;
          this.$root.$error(err);
        });
    },
    removeBookingSupplier(bsid) {
      this.$root.$loading.open("Removing supplier");
      this.$store
        .dispatch("BookingDayStore/removeAllOfBookingSupplier", {
          data: {
            bookingSupplierId: bsid,
            bookingId: this.$store.getters["BookingStore/booking_id"],
            supplierId: this.suppliers[bsid].supplier_id,
            dbkey: this.dbkey,
          },
        })
        .then((v) => {
          this.$emit("popnest", bsid);
          this.$root.$success("Removed Supplier");
          this.$root.$loading.end();
        })
        .catch((err) => {
          this.$root.$error(err);
        });
    },
  },
};
</script>
