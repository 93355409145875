import axios from "@/plugins/axios";

export default {
  getContact(id) {
    return axios.get(`/supplier_contacts/${id}`);
  },
  getContactList(supplier_id) {
    return axios.get(`/suppliers/${supplier_id}/contacts`);
  },

  addContact(supplier_id, contact) {
    return axios.post(`/suppliers/${supplier_id}/contacts`, contact);
  },
  updateContact(id, contact, original) {
    return axios.post(`/supplier_contacts/${id}`, { data: contact, cache: original });
  },
  copyContacts(supplier_id, copy_from) {
    return axios.post(`/suppliers/${supplier_id}/contacts/copy`, { copy_from });
  },
  deleteContactList(idList) {
    return axios.delete(`/supplier_contacts`, {
      data: { contact_list: idList },
    });
  },

  setPrimaryContact(supplier_id, contact_id, old_primary) {
    return axios.post(`/suppliers/${supplier_id}`, {
      data: { primary_contact_id: contact_id },
      cache: { primary_contact_id: old_primary },
    });
  },
};
