<template>
  <div>
    <v-row :justify="isAdmin ? 'space-between' : 'end'" class="mx-0 mb-1">
      <v-hover v-slot:default="{ hover }">
        <v-btn v-if="!hover" color="grey" icon small>
          <v-icon small>delete</v-icon>
        </v-btn>

        <v-btn v-else color="red lighten-1" @click="deleteQuote" small>
          <v-icon left small>delete</v-icon>
          <span>Delete</span>
        </v-btn>
      </v-hover>

      <save-button :onsave="saveQuote" />
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    isAdmin() {
      return this.$store.getters["isAdmin"];
    },
    quoteId() {
      return this.$store.getters["QuoteStore/quote_id"];
    },
  },
  methods: {
    saveQuote() {
      return this.$store
        .dispatch("QuoteStore/updateQuote")
        .then((v) => this.$root.$success("Updated Quote"))
        .catch((err) => this.$root.$error(err));
    },
    deleteQuote() {
      this.$root.$loading.open("Deleting quote");
      this.$store
        .dispatch("QuoteStore/deleteQuote", this.quoteId)
        .then(() => {
          this.$root.$loading.end();
          this.$router.push({ name: "quotes" });
          this.$root.$success("Removed Quote");
        })
        .catch((err) => this.$root.$error(err));
    },
  },
};
</script>
