<template>
  <div>
    <a-card v-for="email in linkedEmailList" :key="email.email" :class="{ 'primary white--text': email.ismain }">
      <v-row justify="space-between">
        <div v-if="!email.ismain">
          <input class="mx-2" type="checkbox" :checked="email.ismain" @change="toggleActive(email.id)" />
          <span>Set Active</span>
        </div>
        <div v-else>ACTIVE</div>
        <v-btn icon small @click="deleteLinkedEmail(email.id, email.email_source)">
          <v-icon small>delete</v-icon>
        </v-btn>
      </v-row>
      <v-divider />
      <elwrapper widelabel label="Email">
        <v-row align="center" justify="space-between">
          <span>{{ email.email }}</span>
        </v-row>
      </elwrapper>
      <AccountEditField
        password
        nopass
        label="Webmail Password"
        displayValue="********"
        :disabled="editing"
        :savefn="(v) => saveEmailPassword(email.id, v.value)"
        @clear="$emit('clear')"
        @onedit="$emit('onedit')"
      />

      <elwrapper label="Signature" widelabel>
        <div class="d-flex justify-space-between">
          <div v-html="email.signature"></div>
          <v-btn icon small :disabled="editing" @click="openSignatureEdit(email.id, email.signature)">
            <v-icon small>edit</v-icon>
          </v-btn>
        </div>
      </elwrapper>
    </a-card>

    <a-card>
      <g-button v-if="!addToggle" icon="add" label="Add" @onclick="addToggle = true" />
      <AddEmailAccount
        v-else
        :accountId="accountId"
        :linkedEmailList="linkedEmailList"
        @close="addToggle = false"
        @added="(v) => $emit('sync')"
      />
    </a-card>

    <SignatureDialog v-model="dialog" :editEmailId="editEmailId" :editSignature="editSignature" />
  </div>
</template>

<script>
import AccountEditField from "./AccountEditField";
import AddEmailAccount from "./_AddEmailAccount";
import SignatureDialog from "./_SignatureDialog";
export default {
  props: {
    accountId: String,
    editing: Boolean,
    linkedEmailList: Array,
  },
  components: {
    AccountEditField,
    AddEmailAccount,
    SignatureDialog,
  },
  data() {
    return {
      addToggle: false,
      dialog: false,

      editEmailId: "",
      editSignature: "",
    };
  },
  methods: {
    deleteLinkedEmail(customEmailId, source) {
      this.$store
        .dispatch("AccountStore/deleteCustomEmail", customEmailId)
        .then(() => {
          this.$emit("sync");
          this.$root.$success("Removed linked email");
        })
        .catch((err) => this.$root.$error(err));
    },
    toggleActive(email_id) {
      const query = this.linkedEmailList.map((item) => {
        return this.$store.dispatch("AccountStore/updateCustomEmail", {
          emailaccount_id: item.id,
          data: {
            ismain: item.id === email_id ? 1 : 0,
          },
        });
      });

      Promise.all(query)
        .then(() => this.$root.$success("Updated Email"))
        .catch((err) => {
          this.$root.$error(err);
        });
    },
    saveEmailPassword(email_id, confirmPassword) {
      return this.$store
        .dispatch("AccountStore/updateEmailPassword", {
          emailaccount_id: email_id,
          email_password: confirmPassword,
        })
        .then(() => this.$root.$success("Updated Email Password"))
        .catch((err) => this.$root.$error(err));
    },

    openSignatureEdit(editEmailId, baseValue) {
      this.editEmailId = editEmailId;
      this.editSignature = baseValue;
      this.dialog = true;
    },
  },
};
</script>
