<template>
  <div>
    <div v-if="confirmed">
      <v-btn v-show="!disabled" depressed small @click="confirmed = false">
        <v-icon small left>swap_horiz</v-icon>
        <span class="caption">Custom</span>
      </v-btn>

      <v-btn color="green" depressed small style="pointer-events: none">
        <v-icon color="white" small left>check_circle</v-icon>
        <span class="caption">Default</span>
      </v-btn>
    </div>
    <div class="d-flex" v-else>
      <cur-per-val
        :env="env"
        :disabled="disabled"
        :dropdown="dropdownSource"
        :value="customPrice"
        :default="defaultType"
        @input="setPrice"
      />
      <v-btn small icon :disabled="disabled" @click="cancel">
        <v-icon small>clear</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { ProductFields } from "@/config/fields/products";
export default {
  props: {
    disabled: Boolean,

    env: String,
    productType: String,
    supplierType: String,
    defaultType: String,

    sync: Boolean,
    saved: Object,
  },
  data() {
    return {
      confirmed: true,
      customPrice: {},
    };
  },
  watch: {
    saved(v) {
      if (!this.sync) return;
      if (!v) return;

      this.confirmed = false;
      this.customPrice = v.price ? v.price : v;
    },
  },
  computed: {
    dropdownSource() {
      return ProductFields.rate_type.getSource(this.productType, this.supplierType);
    },
  },
  methods: {
    cancel(v) {
      this.confirmed = true;
      this.customPrice = {};

      this.$emit("set", false);
    },
    setPrice(v) {
      this.customPrice = v;
      this.$emit("set", this.customPrice);
    },
  },
  mounted() {
    if (this.saved && this.saved.price) {
      this.confirmed = false;
      this.customPrice = this.saved.price;
    }
  },
};
</script>
