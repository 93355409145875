<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)" width="400px" persistent>
    <v-card>
      <v-card-title>
        <v-icon left color="warning">warning</v-icon>
        <span>Different Number of Days</span>
      </v-card-title>

      <v-card-text>
        <div class="mx-5">
          <div>Original: {{ previousDayCount }} days</div>
          <div>New: {{ newDayCount }} days</div>
        </div>

        <div v-if="newDayCount < previousDayCount">
          <v-alert dense text type="warning"> Suppliers may be deleted. </v-alert>
        </div>
      </v-card-text>

      <v-card-actions>
        <g-button label="Cancel" @onclick="$emit('input', false)" />
        <div class="flex-grow-1"></div>

        <g-button type="primary" label="Yes, this is correct" @onclick="$emit('next')" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    previousDayCount: Number,
    newDayCount: Number,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
