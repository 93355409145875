export default {
  DATABASE: {
    title: "Databases",
    value: "DATABASE",
    link: { name: "database" },
  },
  BOOKING: {
    title: "Bookings",
    value: "BOOKING",
    link: { name: "bookings" },
  },
  QUOTE: {
    title: "Quotes",
    value: "QUOTE",
    link: { name: "quotes" },
  },
  REMINDER: {
    title: "Reminders",
    value: "REMINDER",
    link: { name: "reminders" },
  },
  REPORTING: {
    title: "Reporting",
    value: "REPORTING",
    link: { name: "finances" },
  },
  ADMIN: {
    title: "Admin",
    value: "ADMIN",
    link: { name: "admin" },
  },
};
