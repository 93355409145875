<template>
  <div>
    <div class="flex-grow-1"></div>

    <CopyButton :supplier-id="supplierId" :name="name" />

    <EmailSupplierButton />
  </div>
</template>

<script>
import CopyButton from "./buttons/_CopyButton";
import EmailSupplierButton from "./buttons/_EmailSupplierButton";

export default {
  components: {
    CopyButton,
    EmailSupplierButton,
  },
  computed: {
    supplierId() {
      return this.$store.getters["SupplierStore/id"];
    },
    name() {
      // This shouldn't update until after the user saves
      return this.$store.state.SupplierStore.cached.name;
    },
  },
};
</script>
