<template>
  <v-dialog :value="value" persistent max-width="350">
    <v-card>
      <v-card-title>
        <v-icon left color="error">not_interested</v-icon>
        <span class="subtitle-2 error--text">Booking Already Exists</span>
      </v-card-title>
      <v-card-text>
        <p>This client already has a group with this name. Please create a new group.</p>
        <p>
          Available Group Name: <span class="font-weight-bold">{{ recommend }}</span>
        </p>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn text depressed small @click="$emit('input', false)">Okay</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    recommend: String,
  },
};
</script>
