<template>
  <span>{{ displayCount }}</span>
</template>

<script>
import format from "@/services/format";
export default {
  props: {
    total: Number,
    extraCount: Number,
  },
  computed: {
    displayCount() {
      return this.extraCount ? `${this.total} + ${this.extraCount}` : this.total;
    },
  },
};
</script>
