<template>
  <div class="checkbox-wrapper" :class="{ active: checkbox || switchCtl }">
    <div v-if="showCheck" class="pa-2 checkbox">
      <v-switch
        v-if="single"
        color="white"
        v-model="switchCtl"
        :disabled="switchCtl"
        @change="(v) => $emit('select', { select: v, value: value })"
      ></v-switch>

      <v-tooltip v-else-if="block" left>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>clear</v-icon>
          </v-btn>
        </template>
        {{ blockMessage }}
      </v-tooltip>

      <v-checkbox v-else :value="checkbox" @change="(v) => $emit('toggle', { check: v, value: value })"></v-checkbox>
    </div>

    <div class="pa-0 checkbox-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showCheck: Boolean,
    checked: Boolean,
    checklist: Array,

    single: Boolean,
    value: String,
    selected: String,

    block: Boolean,
    blockMessage: String,
  },
  data() {
    return {
      checkbox: false,
      switchCtl: false,
    };
  },
  watch: {
    selected(val) {
      this.switchCtl = val === this.value;
    },
    showCheck(val) {
      // Clear checkbox when closing
      // IF not saved in selected group
      if (!val) this.checkbox = false;
    },
  },
  mounted() {
    this.switchCtl = this.selected === this.value;
    this.checkbox = this.checked;
  },
};
</script>

<style lang="scss">
.checkbox-wrapper {
  display: flex;
  align-items: stretch;

  div.checkbox {
    max-width: 90px;
    max-height: 30px;
    min-height: 30px;
    height: 30px;

    .v-input--switch,
    .v-input--selection-controls {
      margin-top: 5px;
      max-height: 30px;
      min-height: 30px;
      height: 30px;
    }
  }

  div.checkbox-content {
    flex-grow: 1;
  }

  .active {
  }
}
</style>
