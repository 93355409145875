<template>
  <LabelWrapper :label="label" :icon="icon" :widelabel="widelabel" :smlabel="smlabel" texttop>
    <FDropdown
      :freeopt="freeopt"
      :dependant="dependant"
      :dependantValue="dependantValue"
      :dependantText="dependantText"
      :returnObject="returnObject"
      :multiple="multiple"
      :items="items"
      :value="value"
      :maxwidth="maxwidth"
      :disabled="disabled"
      :hint="hint"
      @input="(v) => $emit('input', v)"
      @change="(v) => $emit('change', v)"
    />
  </LabelWrapper>
</template>

<script>
import LabelWrapper from "./wrappers/LabelWrapper";
import FDropdown from "./items/FDropdown";
export default {
  props: {
    dependant: Boolean,
    dependantValue: String,
    dependantText: String,

    widelabel: Boolean,
    smlabel: Boolean,
    label: String,
    icon: String,
    multiple: Boolean,
    items: Array,
    value: {
      //type: [String, Array],
      //required: true
    },

    returnObject: Boolean,
    disabled: Boolean,
    freeopt: Boolean,
    maxwidth: String,

    hint: String,
  },
  components: {
    LabelWrapper,
    FDropdown,
  },
};
</script>
