<template>
  <div class="history-card-header">
    <v-row justify="space-between" align="center">
      <v-col class="d-flex align-center" cols="9">
        <div style="min-width: 100px">
          <span v-if="inOrOut === 'INBOX'">
            <v-icon left color="green">arrow_downward</v-icon>
            <v-icon left color="green">mail_outline</v-icon>
            <span class="caption">IN</span>
          </span>

          <span v-else-if="inOrOut === 'OUTBOX'">
            <v-icon left color="orange">arrow_upward</v-icon>
            <v-icon left color="orange">mail_outline</v-icon>
            <span class="caption">OUT</span>
          </span>

          <span v-else>
            <v-icon left>note</v-icon>
          </span>
        </div>

        <div v-if="['SENT', 'RECEIVED'].includes(type)" style="width: 150px">
          <span class="caption px-3">{{ displayContact }}</span>
        </div>
        <span class="subtitle-2">{{ displaySubject }}</span>
        <span v-if="hasAttachments" class="mx-3">
          <v-icon>attachment</v-icon>
        </span>
      </v-col>

      <v-col cols="1">
        <g-button
          v-if="type === 'NOTE'"
          class="replybtn"
          label="Edit"
          icon="edit"
          @onclick="$emit('edit', content.id)"
        />
        <g-button
          v-if="type === 'RECEIVED'"
          class="replybtn"
          label="Reply"
          icon="reply"
          @onclick="$emit('reply', content)"
        />
      </v-col>

      <v-col class="caption" cols="2">
        <span>{{ displayUser }}</span>
        <span class="font-weight-bold px-2">|</span>
        <span class="font-italic">{{ displayDate }}</span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    type: String,
    date: Number, // MUST be UTC seconds
    content: Object,

    contactMap: Object,
    accountMap: Object,
  },
  computed: {
    hasAttachments() {
      return this.content.attachment;
    },
    inOrOut() {
      if (this.type === "NOTE") return "NONE";
      // Figures out whether this was incoming or outgoing mail
      // ALL EMAILS OUTGOING MAIL MUST BE IN DATABASE
      if (this.type === "SENT") return "OUTBOX";
      return "INBOX";
    },
    displaySubject() {
      if (["SENT", "RECEIVED"].includes(this.type)) return this.content.subject;

      // Note, want to get an abridged version
      return this.htmlToElement(this.content.content).textContent;
    },
    displayContact() {
      if (this.type === "NOTE") return;
      return this.inOrOut === "OUTBOX" ? this.getName() : this.getNameFlip();
    },
    displayUser() {
      if (this.type === "NOTE") return this.content.creator_name;
      return this.inOrOut === "OUTBOX" ? this.getName(true) : this.getNameFlip(true);
    },
    displayDate() {
      var options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      var d = new Date(this.date);
      return d.toLocaleDateString("en-US", options);
    },
  },
  methods: {
    getName(account) {
      if (account) return this.getNameMapping(this.content.from_mail, this.accountMap);
      return this.getNameMapping(this.content.to_mail, this.contactMap);
    },
    getNameFlip(account) {
      if (account) return this.getNameMapping(this.content.to_mail, this.accountMap);
      return this.getNameMapping(this.content.from_mail, this.contactMap);
    },

    getNameMapping(arr, mapping) {
      return arr
        .map((item) => {
          if (!mapping[item.address.toLowerCase()]) return item.name;
          return mapping[item.address.toLowerCase()].name || item.name;
        })
        .join(", ");
    },

    htmlToElement(html) {
      var template = document.createElement("template");
      html = html.trim(); // Never return a text node of whitespace as the result
      template.innerHTML = html;
      return template.content.firstChild;
    },
  },
};
</script>

<style lang="scss">
.history-card-header {
  .replybtn {
    opacity: 0;
  }

  &:hover .replybtn {
    opacity: 1;
  }
}
</style>
