<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)" width="400" persistent>
    <v-card>
      <v-card-title> Are you sure you want to remote this expense? </v-card-title>
      <v-card-text class="black--text">
        <v-row>
          <v-col class="subtitle-2" align-self="center"> Supplier </v-col>

          <v-col class="d-flex">
            {{ supplierName }}
          </v-col>
        </v-row>

        <v-row>
          <v-col class="subtitle-2" align-self="center"> Label </v-col>

          <v-col class="d-flex">
            {{ removingExpense.label }}
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <g-button @onclick="$emit('input', false)" label="Cancel" />
        <div class="flex-grow-1"></div>
        <g-button type="red" @onclick="removeExpense" label="Yes, remove" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import format from "@/services/format";
export default {
  props: {
    value: Boolean,
    supplierName: String,
    removingExpense: Object,
  },
  methods: {
    removeExpense() {
      this.$store
        .dispatch("FinanceCompileStore/removeExtraExpenses", this.removingExpense.id)
        .then((v) => {
          this.$emit("input", false);
          this.$emit("pop-expense", this.removingExpense.id);
          this.$root.$success("Removed Expense");
        })
        .catch((err) => this.$root.$error(err));
    },
  },
};
</script>
