<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)" width="400" persistent>
    <v-card>
      <v-card-title class="d-flex flex-column align-start">
        <div>Supplier Cost:</div>
        <div class="subtitle-2">{{ editSupplier.name }}</div>
      </v-card-title>
      <v-card-text class="black--text">
        <v-row>
          <v-col class="subtitle-2" cols="3" align-self="center"> Amount </v-col>

          <v-col class="d-flex justify-end">
            <f-number :value="displayNumber" @input="(v) => (invoiceValue = v)" />
            <div class="d-flex align-center px-1">/</div>
            <div class="d-flex align-center pr-1">{{ customSupplierCost || "$ --" }}</div>
            <v-btn icon small @click="invoiceValue = 0">
              <v-icon small>clear</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <g-button @onclick="cancel" label="Cancel" />
        <div class="flex-grow-1"></div>
        <g-button type="red" @onclick="clearCost" label="Remove" />
        <g-button type="primary" @onclick="update" label="Update" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import money from "@/services/money";
export default {
  props: {
    value: Boolean,
    editSupplier: Object,
    supplierInvoice: Object,
  },
  components: {},
  data() {
    return {
      invoiceValue: null,
    };
  },
  computed: {
    customSupplierCost() {
      return this.supplierInvoice && this.supplierInvoice.total_cost;
    },
    displayNumber() {
      if (this.invoiceValue === 0) return 0;
      return this.invoiceValue || this.customSupplierCost || this.remainingTotal;
    },
    remainingTotal() {
      if (!this.editSupplier.products) return 0;
      return this.editSupplier.products.reduce((total, product) => {
        return total + product.compiledTotal;
      }, 0);
    },
    formatedTotal() {
      return this.remainingTotal ? `$ ${money.format(this.remainingTotal, { precision: 2 })}` : "$ --";
    },
  },
  methods: {
    cancel() {
      this.invoiceValue = null;
      this.$emit("input", false);
    },
    update() {
      this.$emit("update", {
        supplier: this.editSupplier,
        customCost: this.displayNumber,
      });

      this.invoiceValue = null;
    },
    clearCost() {
      this.$emit("remove", this.editSupplier);

      this.invoiceValue = null;
    },
  },
};
</script>
