<template>
  <v-row class="mx-0 flex-nowrap caption" justify="space-between">
    <v-col :cols="mainColCount" align-self="center">
      <a-truncate :text="supplierName" :hoverText="hoverText" />
    </v-col>

    <v-col cols="1" v-if="hasBreakfast">
      <Breakfast />
    </v-col>
  </v-row>
</template>

<script>
import Breakfast from "./_Breakfast";
export default {
  props: {
    supplier: Object,
    supplierMeta: Object,
  },
  components: {
    Breakfast,
  },
  computed: {
    mainColCount() {
      let col = 12;
      if (this.hasBreakfast) col -= 1;
      return col;
    },
    hasBreakfast() {
      if (!this.supplierMeta) return false;
      if (!this.supplierMeta.breakfast) return false;
      return this.supplierMeta.breakfast.value === "included";
    },
    hoverText() {
      if (this.supplier.env !== this.$store.getters["AccountStore/environment"]) {
        return `${this.supplier.name} *(${this.supplier.env})`;
      }
      return this.supplier.name;
    },
    supplierName() {
      if (this.supplier.env !== this.$store.getters["AccountStore/environment"]) {
        return `*${this.supplier.name}`;
      }
      return this.supplier.name;
    },
  },
};
</script>
