<template>
  <v-overlay :value="value" color="grey">
    <div class="d-flex flex-column align-center">
      <v-progress-circular indeterminate :size="50" color="primary"></v-progress-circular>

      <div class="title black--text ma-2">{{ message }}</div>
    </div>
  </v-overlay>
</template>

<script>
export default {
  props: {
    value: Boolean,
    message: String,
  },
};
</script>
