import api from "./api";

const SET_LIST = "SET_LIST";
const ADD_LIST = "ADD_LIST";
const DELETE_TEMPLATE = "DELETE_TEMPLATE";
const ADJUST_TEMPLATE_ORDER = "ADJUST_TEMPLATE_ORDER";

const namespaced = true;

const state = {
  emailList: [],
};

const mutations = {
  SET_LIST(state, data) {
    state.emailList = data.map((item) => {
      if (!item.parent_id) return item;
      let parent = data.find((p) => p.id === item.parent_id);
      return Object.assign(item, {
        sort_order: parent.sort_order,
        status_set: parent.status_set,
      });
    });
  },
  ADD_LIST(state, nutemplate) {
    state.emailList.push(nutemplate);
  },
  DELETE_TEMPLATE(state, template_id) {
    state.emailList = state.emailList.filter((x) => {
      x.id !== template_id;
    });
  },

  ADJUST_TEMPLATE_ORDER(state, updatedList) {
    updatedList.forEach((item, index) => {
      let found = state.emailList.find((template) => template.id === item.id);
      found.order = index;
    });
  },
};

const actions = {
  adjustTemplateOrder({ commit }, list) {
    commit(ADJUST_TEMPLATE_ORDER, list);
  },

  getTemplates({ commit }, all) {
    return api
      .getTemplates(all)
      .then((data) => commit(SET_LIST, data.data || []))
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  getOneTemplate({ commit }, template_id) {
    return api
      .getOneTemplate(template_id)
      .then((data) => data.data)
      .catch((err) => {
        throw err;
      });
  },
  createTemplate({ commit }, data) {
    return api
      .addTemplate(data)
      .then((template) => {
        commit(ADD_LIST, template.data);
        return template.data;
      })
      .catch((err) => {
        throw err;
      });
  },
  updateTemplate({ commit }, { template_id, data }) {
    return api.updateTemplate(template_id, data).catch((err) => {
      throw err;
    });
  },
  deleteTemplate({ commit }, template_id) {
    return api
      .deleteTemplate(template_id)
      .then((v) => commit(DELETE_TEMPLATE, template_id))
      .catch((err) => {
        throw err;
      });
  },

  reorderTemplates({ commit }, updateOrderList) {
    return api.updateTemplateOrder(updateOrderList).catch((err) => {
      throw err;
    });
  },
};

const getters = {
  emailList: (state) => state.emailList,
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
