import axios from "@/plugins/axios";

export default {
  getTemplates(all) {
    return axios.get("/email_templates", { params: { all } });
  },
  getOneTemplate(template_id) {
    return axios.get(`/email_templates/${template_id}`);
  },
  addTemplate(data) {
    return axios.post("/email_templates", data);
  },
  updateTemplate(template_id, data) {
    return axios.post(`/email_templates/${template_id}`, data);
  },
  deleteTemplate(template_id) {
    return axios.delete(`/email_templates/${template_id}`);
  },

  updateTemplateOrder(emailList) {
    return axios.post(`/email_templates/order`, { emailList });
  },
};
