<template>
  <v-row align="center mx-0">
    <a :href="actualLink" target="_blank" class="text-truncate" @click.stop style="max-width: 90%">{{ link }}</a>
  </v-row>
</template>

<script>
export default {
  props: {
    link: String,
  },
  computed: {
    actualLink() {
      return !this.link.includes("http") ? `http://${this.link}` : this.link;
    },
  },
};
</script>
