<template>
  <v-data-table
    disable-pagination
    hide-default-footer
    :headers="headers"
    :items="rows"
    :height="height"
    :loading="loading"
    loading-text="Fetching..."
    class="database-table"
  >
    <template v-slot:body="{ items }">
      <tbody>
        <QuoteTableRow
          v-for="item in items"
          :key="item.id"
          :item="item"
          class="database-row"
          @click.native="goToPage(item)"
        />
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
import QuoteTableRow from "./_QuoteTableRow";
export default {
  props: {
    query: Object,
    height: [Number, String],
  },
  components: {
    QuoteTableRow,
  },
  data() {
    return {
      headers: [
        {
          text: "",
          value: "favorite",
        },
        {
          text: "Client",
          value: "client_name",
        },
        {
          text: "Reference",
          value: "reference",
        },
        {
          text: "City Start",
          value: "city_start",
        },
        {
          text: "City End",
          value: "city_end",
        },
        {
          text: "Version",
          value: "version",
        },
        {
          text: "Date Start",
          value: "start_date",
        },
        {
          text: "Date End",
          value: "end_date",
        },
        {
          text: "Nights",
          value: "number_of_nights",
        },
      ],

      rows: [],
      loading: true,
      queryActive: true,
    };
  },
  watch: {
    query(searchQuery) {
      if (this.queryActive) return;

      this.queryDatabase(searchQuery);
    },
  },
  methods: {
    goToPage(item) {
      this.$router.push({
        name: "quotes_view",
        params: {
          quote_id: item.id,
        },
      });
    },
    queryDatabase(searchQuery) {
      this.loading = true;
      this.queryActive = true;

      return this.$store
        .dispatch("QuoteStore/getQuoteList", { query: searchQuery })
        .then((rows) => {
          this.loading = false;
          this.queryActive = false;
          this.rows = rows;
        })
        .catch((err) => {
          this.loading = false;
          this.$root.$error(err);
        });
    },
  },
  mounted() {
    this.queryDatabase(this.query);
  },
};
</script>
