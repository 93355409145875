<template>
  <div>
    <elwrapper label="Project Manager" widelabel>
      <v-text-field :value="displayName" outlined hide-details dense readonly @click="dialog = true"></v-text-field>
    </elwrapper>

    <ProjectManagerDialog v-model="dialog" :accountList="accountList" @update="(v) => $emit('input', v)" />
  </div>
</template>

<script>
import ProjectManagerDialog from "./_ProjectManagerDialog";
export default {
  components: {
    ProjectManagerDialog,
  },
  props: {
    value: [Object, String],
  },
  data() {
    return {
      dialog: false,
      accountList: [],
    };
  },
  computed: {
    displayName() {
      const a = this.accountList.find((x) => x.id === this.value);
      return a ? a.name : a;
    },
  },
  mounted() {
    this.$store
      .dispatch("AdminStore/getAccountList")
      .then((list) => {
        this.accountList = list.filter((item) => {
          return item.email !== "devtest@supernature.travel";
        });
      })
      .catch((err) => this.$root.$error(err));
  },
};
</script>
