<template>
  <div>
    <div v-if="!type" class="room-btn">
      <g-button label="Adult" @onclick="set('type', 'ADULT')" />
      <g-button label="Child" @onclick="set('type', 'CHILD')" />
    </div>

    <div v-else-if="type === 'ADULT'" class="room-btn">
      <g-button label="Adult" type="black" />
      <v-btn icon small @click="clearField">
        <v-icon small>clear</v-icon>
      </v-btn>
    </div>

    <div class="room-btn" v-else>
      <g-button label="Child" type="black" />
      <span class="caption mr-1">Age</span>
      <f-number :value="value.age" @input="(v) => set('age', v)" />
      <v-btn icon small @click="clearField">
        <v-icon small>clear</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Object, // { type, age }
  },
  computed: {
    type() {
      return this.value ? this.value.type : null;
    },
  },
  methods: {
    set(key, value) {
      var cur = { ...this.value };
      cur[key] = value;

      this.$emit("input", cur);
    },
    clearField() {
      this.$emit("input", null);
    },
  },
};
</script>

<style lang="scss">
.room-btn {
  width: 160px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  button {
    transform: scale(0.875);
    transform-origin: left;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
</style>
