<template>
  <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y>
    <template v-slot:activator="{ on }">
      <div class="d-flex">
        <v-text-field
          :value="displayValue"
          :prepend-inner-icon="!multi ? 'event' : ''"
          :disabled="disabled"
          outlined
          hide-details
          dense
          readonly
          @click="setDefault"
          v-on="on"
          :class="{ 'f-date-style': maxwidth }"
        ></v-text-field>
      </div>
    </template>

    <v-date-picker :allowed-dates="blockBefore" v-model="date" @input="eject" :type="type"></v-date-picker>
  </v-menu>
</template>

<script>
import format from "@/services/format";
export default {
  props: {
    disabled: Boolean,
    label: String,
    value: String,
    multi: Boolean,
    maxwidth: Boolean,
    type: String,
    datewall: [String, Date],
    datewallEnd: [String, Date],
  },
  data() {
    return {
      date: "",
      menu: false,
    };
  },
  computed: {
    displayValue() {
      return this.value ? format.formatDate(this.value) : "";
    },
  },
  methods: {
    setDefault() {
      if (this.datewall && !this.value) this.date = this.datewall;
    },
    blockBefore(val) {
      if (!this.datewall && !this.datewallEnd) return true;
      if (!this.datewallEnd) return this.isAfter(this.datewall, val);
      if (!this.datewall) return !this.isAfter(this.datewallEnd, val);

      return (
        this.isAfter(this.datewall, val) && // Date is after/on wall start
        !this.isAfter(format.addDay(this.datewallEnd), val)
      ); // Date is BEFORE (just invert)
    },
    isAfter(dateFirst, val) {
      const datewall = format.dateToObj(dateFirst);
      const year = datewall.year;
      const month = datewall.month;
      const day = datewall.day;

      const v = format.dateToObj(val);

      if (year < v.year) return true;
      if (year > v.year) return false;

      // Current year
      if (month < v.month) return true;
      if (month > v.month) return false;

      // Current month
      if (day <= v.day) return true;
      if (day > v.day) return false;
    },

    eject(v) {
      this.menu = false;
      this.$emit("input", v);
    },
  },
};
</script>

<style lang="scss">
.f-date-style {
  max-width: 120px;
}
</style>
