<template>
  <a-card>
    <v-row>
      <v-col class="py-0">
        <a-field :config="config.name" v-model="name" v-uppercase="!showTranslation" />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <a-field
          v-if="showTranslation"
          :config="config.en_name"
          :value="metaValues.en_name"
          @input="(v) => updateField('en_name', v)"
          v-uppercase
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="caption py-0">
        <div class="d-flex align-center">
          <a-field :config="config.pid" :value="pid" midtext />
          <v-btn icon x-small @click="dialog = true">
            <v-icon x-small>edit</v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col class="py-0">
        <a-field :config="config.active" v-model="active" />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="py-0">
        <elwrapper :label="config.search_types ? config.search_types.label : ''">
          <div class="d-flex align-center">
            <div class="mr-3">
              <v-chip small class="pa-3">
                <span>{{ searchText }}</span>
              </v-chip>
            </div>
            <f-dropdown multiple :items="searchItems" v-model="search_types" />
          </div>
        </elwrapper>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="py-0">
        <elwrapper :label="config.search_env ? config.search_env.label : ''">
          <div class="d-flex align-center">
            <div class="mr-3">
              <v-chip small class="pa-3">
                <span>{{ envText }}</span>
              </v-chip>
            </div>
            <f-dropdown v-if="isAdmin" multiple :items="envItems" v-model="search_env" />
          </div>
        </elwrapper>
      </v-col>
    </v-row>

    <SwitchSupplierTypeDialog
      :supplierPid="pid"
      :currentType="currentType"
      v-model="dialog"
      @switch="updateSupplierType"
      @resetId="updateSupplierPid"
    />
  </a-card>
</template>

<script>
import SUPMENU from "@/config/menus/suppliersMenu";
import info from "../config/formConfig";
import SwitchSupplierTypeDialog from "./_SwitchSupplierTypeDialog";
export default {
  props: {
    supplierId: String,
    supplierType: String,
  },
  components: {
    SwitchSupplierTypeDialog,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters["isAdmin"];
    },
    config() {
      return this.supplierType ? info[this.supplierType] || {} : {};
    },
    showTranslation() {
      return this.$store.getters["AccountStore/environment"] !== "USA";
    },

    name: {
      get() {
        return this.$store.getters["SupplierStore/name"];
      },
      set(newValue) {
        return this.$store.dispatch("SupplierStore/setUpdate", { name: newValue.trim() });
      },
    },
    searchEnv() {
      return this.$store.getters["SupplierStore/search_env"];
    },
    pid() {
      return this.$store.getters["SupplierStore/pid"];
    },
    currentType() {
      return this.$store.getters["SupplierStore/type"];
    },
    active: {
      get() {
        return this.$store.getters["SupplierStore/active"];
      },
      set(newValue) {
        return this.$store.dispatch("SupplierStore/setUpdate", { active: newValue });
      },
    },

    search_types: {
      get() {
        return this.$store.getters["SupplierStore/search_types"];
      },
      set(newValue) {
        return this.$store.dispatch("SupplierStore/setUpdate", { search_types: newValue });
      },
    },
    searchItems() {
      if (!(this.config && this.config.search_types)) return [];
      return this.config.search_types.getSource(this.currentType);
    },
    searchText() {
      const c = SUPMENU.FULL.find((x) => x.module === this.currentType) || {};
      return c.title;
    },

    search_env: {
      get() {
        return this.$store.getters["SupplierStore/search_env"];
      },
      set(newValue) {
        return this.$store.dispatch("SupplierStore/setUpdate", { search_env: newValue });
      },
    },
    envItems() {
      if (!(this.config && this.config.search_env)) return [];
      return this.config.search_env.getSource(this.envText);
    },
    envText() {
      return this.$store.getters["SupplierStore/env"];
    },

    metaValues() {
      return this.$store.getters["SupplierStore/meta"];
    },
  },
  methods: {
    updateField(key, value) {
      return this.$store.dispatch("SupplierStore/setUpdateMeta", { key, value });
    },

    updateSupplierType(nuType) {
      // Close the dialog
      // Launch update page (need loading since current view affected)
      this.dialog = false;
      this.$root.$loading.open("Updating current supplier type");
      this.$store
        .dispatch("SupplierStore/updateSupplierType", nuType)
        .then(() => this.$store.dispatch("SupplierStore/getSupplier", this.supplierId))
        .then(() => {
          this.$root.$loading.end();
          this.$root.$success("Updated " + this.name);
        })
        .catch((err) => {
          this.$root.$error(err);
        });
    },
    updateSupplierPid() {
      this.dialog = false;
      this.$root.$loading.open("Resetting supplier id");
      this.$store
        .dispatch("SupplierStore/updateSupplier")
        .then(() => this.$store.dispatch("SupplierStore/resetPid"))
        .then(() => this.$store.dispatch("SupplierStore/getSupplier", this.supplierId))
        .then(() => {
          this.$root.$loading.end();
          this.$root.$success("Updated " + this.name);
        })
        .catch((err) => {
          this.$root.$error(err);
        });
    },
  },
};
</script>
