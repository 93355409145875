<template>
  <div>
    <!-- <Payment 
    v-model="expense"
    :totalInvoice="supplierTotal"
    :remainingTotal="remainingTotal"
    :paidAmount="paidAmount"
    /> -->

    <TotalPaidUnpaidRow :totalInvoice="supplierTotal" :remainingTotal="remainingTotal" :paidAmount="paidAmount" />

    <v-divider />

    <v-row>
      <v-col class="subtitle-2" align-self="center"> Set Balance </v-col>

      <v-col class="d-flex justify-end">
        <div class="d-flex align-center pr-1">$</div>
        <f-number v-model="expense" />
      </v-col>
    </v-row>

    <!-- <PaymentDateRow 
		v-model="expenseDate"
		/> -->

    <v-card-actions>
      <g-button @onclick="$emit('close')" label="Cancel" />
      <div class="flex-grow-1"></div>
      <g-button type="primary" @onclick="setCost" label="Add" />
    </v-card-actions>
  </div>
</template>

<script>
import money from "@/services/money";
import format from "@/services/format";
// import Payment from '../paymentTypes/Payment'
import TotalPaidUnpaidRow from "@/modules/finances.report/components/paymentTypes/_TotalPaidUnpaidRow";
// import PaymentDateRow from '../paymentTypes/_PaymentDateRow'
import ReportService from "@/modules/finances.report/service";
export default {
  props: {
    editSupplier: Object,
    editDeposit: Array,
  },
  components: {
    TotalPaidUnpaidRow,
    // PaymentDateRow,
    // Payment
  },
  data() {
    return {
      // expenseDate: format.convertDate(new Date()),
      expense: null,
    };
  },
  computed: {
    supplierTotal() {
      return ReportService.getProductListTotal(this.editSupplier.products, true, this.$store.getters);
    },
    paidAmount() {
      return this.editDeposit.reduce((total, item) => {
        let value = money.stripFormatting(item.amount && item.amount.value);
        return total + (value || 0);
      }, 0);
    },
    remainingTotal() {
      return this.paidAmount && this.supplierTotal ? this.supplierTotal - this.paidAmount : this.supplierTotal || 0;
    },
  },
  methods: {
    setCost() {
      this.$emit("set-cost", {
        supplier: this.editSupplier,
        customCost: Number(this.expense) + Number(this.paidAmount),
      });

      this.$emit("close");
    },
  },
};
</script>
