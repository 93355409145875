<template>
  <div>
    <v-banner sticky single-line max-height="45" style="background: #1f305c">
      <v-row justify="space-between" align="center" class="mx-0 mb-1">
        <v-alert dense text class="pa-0 my-1 white--text">
          <pcheck label="Show Cancelled" v-model="showCancelled" />
        </v-alert>

        <v-alert dense text color="error" class="pa-0 my-1" v-if="suppliersNeedUpdate">
          <div class="white--text d-flex align-center">
            <span class="ml-4 caption">Date Changed. Suppliers Must Be Updated. </span>
            <g-button label="Finished" type="error" class="mx-3" icon="check" @onclick="markSuppliersAsUpdated" />
          </div>
        </v-alert>

        <!-- <span v-if="isCancelled" class="white--text">CANCELLED -- Messaging and Buttons disabled</span> -->
        <SyncCountButton />
        <g-button type="primary" label="Email" icon="send" @onclick="massMessage" />
      </v-row>
    </v-banner>

    <DayInfo
      v-for="dindex in dayCount"
      :key="dindex"
      :dayNum="dindex"
      :dayCount="dayCount.length"
      :showCancelled="showCancelled"
      @altstatus="openChangeStatus"
      @editRoomName="openEditRoomDialog"
      @editConfirm="openConfirmDialog"
    />

    <StatusDialog
      mode="IN"
      v-model="statusDialog"
      :focusBookingSupplier="supplierDialogData"
      :type="statusType"
      :booking="booking"
      :bookingSuppliers="bookingSuppliers"
      :flattenDays="flattenDays"
      :dayinfo="dayinfo"
    />

    <EditSupplierRoomNameDialog v-model="nameDialog" :clickedSupplier="supplierDialogData" :updateFn="updatePart" />

    <EditConfirmNumberDialog v-model="confirmDialog" :clickedSupplier="supplierDialogData" :updateFn="updatePart" />
  </div>
</template>

<script>
import { SuppliersMenu } from "@/config/menus";
import { DayInfo } from "@/modules/bookings";
import {
  StatusDialog,
  EditSupplierRoomNameDialog,
  EditConfirmNumberDialog,
  SyncCountButton,
} from "@/modules/bookings.days";
import { BookingRoomService } from "@/modules/bookings.rooms";

export default {
  components: {
    DayInfo,
    StatusDialog,
    EditSupplierRoomNameDialog,
    EditConfirmNumberDialog,

    SyncCountButton,
  },
  data() {
    return {
      statusDialog: false,
      nameDialog: false,
      confirmDialog: false,
      supplierDialogData: {},
      statusType: "",

      showCancelled: false,
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters["isAdmin"];
    },
    bookingId() {
      return this.$store.getters["BookingStore/active"].id;
    },
    dayCount() {
      var count = this.$store.getters["BookingDayStore/dayCount"];
      return Array.from(Array(count).keys());
    },
    isCancelled() {
      return this.$store.getters["BookingStore/isCancelled"];
    },

    suppliersNeedUpdate() {
      return this.$store.getters["BookingStore/suppliersNeedUpdate"];
    },

    booking() {
      return this.$store.getters["BookingStore/active"];
    },
    bookingSuppliers() {
      return this.$store.getters["BookingDayStore/suppliers"];
    },
    dayinfo() {
      if (!this.statusType) return [];
      return this.$store.getters["BookingDayStore/daySupplierFlat"](SuppliersMenu.MOD_DBKEY[this.statusType]);
    },
    flattenDays() {
      return this.$store.getters["BookingDayStore/daySupplierFlat"]("ALL");
    },
  },
  methods: {
    massMessage() {
      this.$router.push({
        name: "booking_email",
        params: {
          booking_id: this.bookingId,
        },
      });
    },
    openChangeStatus({ supplier, type }) {
      if (this.isCancelled) return;
      this.supplierDialogData = supplier;
      this.statusType = type;
      this.statusDialog = true;
    },

    openEditRoomDialog(bs) {
      this.supplierDialogData = bs;
      this.nameDialog = true;
    },
    openConfirmDialog(bs) {
      this.supplierDialogData = bs;
      this.confirmDialog = true;
    },

    markSuppliersAsUpdated() {
      this.$store.dispatch("BookingStore/update", { key: "need_sup_update", val: 0 });

      this.$root.$loading.open("Updating...");
      this.$store
        .dispatch("BookingStore/updateBooking")
        .then((v) => {
          this.$root.$loading.end();
          this.$root.$success("Updated Booking");
        })
        .catch((err) => this.$root.$error(err));
    },

    updatePart(data) {
      const bookingSuppliers = this.$store.getters["BookingDayStore/suppliers"];
      let updateQuery = [];
      // Only update current one
      if (!data.bound) {
        updateQuery.push(
          this.$store.dispatch("BookingDayStore/updateBookingSupplier", {
            bookingSupplierId: data.bsid,
            bookingId: this.bookingId,
            data: {
              content: {
                meta: Object.assign(bookingSuppliers[data.bsid].meta, data.metaMerge),
              },
            },
          })
        );
      } else {
        // Multiple booking suppliers to update
        Object.values(bookingSuppliers)
          .filter((item) => item.supplier_id === data.supplier_id)
          .forEach((bs) => {
            updateQuery.push(
              this.$store.dispatch("BookingDayStore/updateBookingSupplier", {
                bookingSupplierId: bs.id,
                bookingId: this.bookingId,
                data: {
                  content: {
                    meta: Object.assign(bs.meta, data.metaMerge),
                  },
                },
              })
            );
          });
      }

      return Promise.all(updateQuery)
        .then((s) => {
          this._syncBookingRooms();
        })
        .catch((err) => this.$root.$error(err));
    },
  },
  mounted() {
    this.$store.dispatch("toggleBookingScroll", true);
    document.querySelector(".scroll-wrapper").scrollTop = this.$store.getters["bookingAllDaysScroll"];
  },
};
</script>
