<template>
  <v-data-table
    disable-pagination
    hide-default-footer
    fixed-header
    :headers="headers"
    :items="dataList"
    :loading="loading"
    loading-text="Fetching suppliers"
    height="500px"
    show-select
    single-select
    class="database-table a-card nested-rows"
  >
    <template v-slot:body="{ items }">
      <tbody>
        <tr class="database-row" :class="{ 'primary--text grey lighten-2': selectAll }" @click="toggleSelectAll">
          <td>
            <input type="checkbox" :checked="selectAll" />
          </td>
          <td>SELECT ALL</td>
        </tr>
        <BookingInfoRow
          v-for="item in items"
          :key="item.id"
          :booking="item"
          :contactList="contactList"
          :searchRecord="searchRecord"
          :selectedContacts="value.contacts[item.id]"
          @onclick="onclickBooking"
        >
          <template v-slot:default="slotProps">
            <table style="width: 100%">
              <tbody>
                <SelectSupplierRow
                  v-for="supplier in slotProps.suppliers"
                  :key="supplier.id"
                  :supplier="supplier"
                  :selectedContacts="value.contacts[item.id]"
                  :bookingId="item.id"
                  :bookingSuppliers="item.booking_suppliers"
                  :bookingDays="item.days"
                  :pax="slotProps.pax"
                  :roomBreakdownData="slotProps.rooms"
                  :compileType="(value.groupBy[item.id] && value.groupBy[item.id][supplier.supplier_id]) || 'GROUP'"
                  @onclick="(v) => onclickSupplier(v, item.id)"
                  @setGroupping="(v) => setGroupping(v, item.id)"
                  :ignoreProducts="value.ignoreProducts[item.id]"
                  @updateIgnoreProduct="(v) => updateIgnoreProduct(v, item.id)"
                >
                  <template v-slot:default="slotProps">
                    <ContactRow
                      v-for="contact in slotProps.contacts"
                      :key="contact.id"
                      :contact="contact"
                      :selectedContacts="value.contacts[item.id]"
                      @onclick="(v) => onclickContact(v, item.id)"
                    />
                  </template>
                </SelectSupplierRow>
              </tbody>
            </table>
          </template>
        </BookingInfoRow>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
import Vue from "vue";
import BookingEmailService from "../../service";
import BookingInfoRow from "./_BookingInfoRow";
import SelectSupplierRow from "../_SelectSupplierRow/_SelectSupplierRow";
import ContactRow from "../_ContactRow";
export default {
  props: {
    value: Object,
    dataList: Array,
    contactList: Array,
    searchRecord: Object,
    loading: Boolean,
  },
  components: {
    BookingInfoRow,
    SelectSupplierRow,
    ContactRow,
  },
  data() {
    return {
      selectAll: false,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Name",
          value: "name",
        },
      ];
    },
  },
  methods: {
    onclickBooking({ booking, suppliers, allSelected }) {
      if (!this.value.contacts[booking.id]) Vue.set(this.value.contacts, booking.id, {});

      if (allSelected) {
        Object.values(suppliers).forEach((sup) => {
          this.onclickSupplier(
            {
              supplier: sup,
              allSelected: true,
            },
            booking.id
          );
        });
      } else {
        Object.values(suppliers).forEach((sup) => {
          this.onclickSupplier(
            {
              supplier: sup,
              allSelected: false,
            },
            booking.id
          );
        });
      }
    },

    onclickSupplier({ supplier, allSelected }, bookingId) {
      if (!this.value.contacts[bookingId]) Vue.set(this.value.contacts, bookingId, {});

      if (allSelected) {
        // Remove EVERYTHING
        supplier.contacts.forEach((contact) => {
          Vue.delete(this.value.contacts[bookingId], contact.id);
        });
        this.selectAll = false;
      } else {
        supplier.contacts.forEach((contact) => {
          Vue.set(this.value.contacts[bookingId], contact.id, contact);
        });
      }

      this.$emit("input", { ...this.value });
    },
    onclickContact(item, bookingId) {
      if (!this.value.contacts[bookingId]) Vue.set(this.value.contacts, bookingId, {});

      if (this.value.contacts[bookingId][item.id]) {
        Vue.delete(this.value.contacts[bookingId], item.id);
        this.selectAll = false;
      } else {
        Vue.set(this.value.contacts[bookingId], item.id, item);
      }

      this.$emit("input", { ...this.value });
    },

    updateIgnoreProduct({ product, toIgnore }, bookingId) {
      if (!this.value.ignoreProducts[bookingId]) Vue.set(this.value.ignoreProducts, bookingId, {});
      // This will directly mutate parent object
      // But I don't care right now
      if (toIgnore) {
        Vue.set(this.value.ignoreProducts[bookingId], product.ref_id, 1);
      } else {
        Vue.delete(this.value.ignoreProducts[bookingId], product.ref_id);
      }
      this.$emit("input", this.value);
    },
    setGroupping({ supplier_id, supplier, groupping }, bookingId) {
      if (!this.value.groupBy[bookingId]) Vue.set(this.value.groupBy, bookingId, {});

      Vue.set(this.value.groupBy[bookingId], supplier_id, groupping);
    },

    toggleSelectAll() {
      // Select all here
      let temp = {};
      this.selectAll = !this.selectAll;
      this.dataList.forEach((item) => {
        this.onclickBooking({
          booking: item,
          suppliers: this._extractBookingSuppliers(item),
          allSelected: !this.selectAll,
        });
      });
      this.$emit("input", this.value);
    },
    _extractBookingSuppliers(booking) {
      let maptype = this.searchRecord?.supplier_type || null;
      if (maptype === "ALL") maptype = null;

      let supplierRef = BookingEmailService._pickFoundSuppliers(booking.booking_suppliers, maptype);
      // Filter out contacts for other bookings
      // (This is a mega list contains all contacts for everything)
      const filteredContacts = this.contactList.filter((item) => supplierRef[item.supplier_id]);
      return BookingEmailService._attachFirstDay(
        BookingEmailService._mergeContactsWithSuppliers(supplierRef, filteredContacts),
        booking.days,
        booking.booking_suppliers
      );
    },
  },
};
</script>
