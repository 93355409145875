import format from "@/services/format";
import { SuppliersMenu } from "@/config/menus";
import { BookingRoomService } from "@/modules/bookings.rooms";
import BOOKING_ROOMS from "@/config/BOOKING_ROOMS";

export default {
  replaceContact,

  replaceSupplier,
  supplierBasicFill,
  subjectBasicFill,

  bookingBasicFill,
  bookingFillLoop,
  fillProductsByBookingSupplier,
  fillProductsBySupplierAndProductGroup,

  _compileDateListReadable,

  hasReplaceContent,
  getReplaceContent,
  fillReplaceContent,
};

/***************
 * CONTACT REPLACE
 ****************/
function replaceContact(contactNameList, message, language = "EN") {
  const conjunction = language === "FR" ? "et" : "and";
  let contacNameListCopy = [...contactNameList]; // Prevent mutation
  // Get last element
  let contactName = contacNameListCopy.pop();
  if (contacNameListCopy.length === 1) {
    // Two names
    contactName = `${contacNameListCopy[0]} ${conjunction} ${contactName}`;
  } else if (contacNameListCopy.length > 1) {
    contactName = `${contacNameListCopy.join(", ")}, ${conjunction} ${contactName}`;
  }

  let nu = message;
  nu = nu.replace(/\${\s*contact.name\s*}/gi, contactName);
  return nu;
}

/***************
 * SUPPLIER DUMP
 ****************/
function replaceSupplier(message, fill) {
  let nu = message;
  nu = nu.replace(/\${\s*supplier.name\s*}/gi, fill.supplier_name);
  nu = nu.replace(/\${\s*supplier.city\s*}/gi, fill.supplier_city || "");
  return nu;
}

function supplierBasicFill(template, skipHTMLWrappers) {
  // Replaces Booking-specific stuff with ${REPLACE ...}
  let foundText = bookingBasicFill(
    template || "",
    {
      group_name: null,
      rooms_total: null,
      rooms_breakdown: null,
    },
    skipHTMLWrappers
  );

  foundText = _replaceTemplateDefault(foundText, skipHTMLWrappers);
  foundText = _stripProductLoop(foundText, skipHTMLWrappers);
  foundText = _stripBookingLoop(foundText, skipHTMLWrappers);
  return foundText;
}

function _stripBookingLoop(template, skipHTMLWrappers) {
  let nu = template;
  nu = nu.replace(/#BSTART/gi, "").replace(/#BEND/gi, "");
  return nu;
}

function _stripProductLoop(template, skipHTMLWrappers) {
  // Removes product looping text for supplier fill
  // PARAMS
  // 	template	@{String}
  // RETURN @{String}
  let nu = template;

  nu = nu.replace(/#PSTART\.ROOM/gi, "");
  nu = nu.replace(/#PSTART\.MEAL/gi, "");
  nu = nu.replace(/#PSTART\.EXCURSION/gi, "");
  nu = nu.replace(/#PSTART\.TOUR/gi, "");
  nu = nu.replace(/#PSTART\.OTHER/gi, "");
  nu = nu.replace(/#PSTART/gi, "");
  nu = nu.replace(/#PEND/gi, "");

  nu = nu.replace(/\${\s*product.name\s*}/gi, _getReplaceContent(null, "Products", null, skipHTMLWrappers));
  nu = nu.replace(/\${\s*product.count\s*}/gi, _getReplaceContent(null, "Number of products", null, skipHTMLWrappers));
  nu = nu.replace(/\${\s*product.rate\s*}/gi, _getReplaceContent(null, "Product Rate", null, skipHTMLWrappers));

  return nu;
}

function _replaceTemplateDefault(message, skipHTMLWrappers) {
  let nu = message;

  nu = nu.replace(/\${\s*date.list\s*}/gi, _getReplaceContent(null, "Dates", null, skipHTMLWrappers));
  nu = nu.replace(/\${\s*date.of\s*}/gi, _getReplaceContent(null, "Date", null, skipHTMLWrappers));
  nu = nu.replace(/\${\s*date.start\s*}/gi, _getReplaceContent(null, "Start Date", null, skipHTMLWrappers));
  nu = nu.replace(/\${\s*date.end\s*}/gi, _getReplaceContent(null, "End Date", null, skipHTMLWrappers));
  nu = nu.replace(/\${\s*date.days\s*}/gi, _getReplaceContent(null, "Number of days", null, skipHTMLWrappers));
  nu = nu.replace(/\${\s*date.nights\s*}/gi, _getReplaceContent(null, "Number of nights", null, skipHTMLWrappers));
  nu = nu.replace(/\${\s*rooms.total\s*}/gi, _getReplaceContent(null, "Room Total", null, skipHTMLWrappers));
  nu = nu.replace(/\${\s*rooms.breakdown\s*}/gi, _getReplaceContent(null, "Room Breakdown", null, skipHTMLWrappers));
  nu = nu.replace(/\${\s*time\s*}/gi, _getReplaceContent(null, "Time", null, skipHTMLWrappers));
  nu = nu.replace(/\${\s*rooms.single\s*}/gi, _getReplaceContent(null, "nb single room", null, skipHTMLWrappers));
  nu = nu.replace(/\${\s*rooms.double\s*}/gi, _getReplaceContent(null, "nb double room", null, skipHTMLWrappers));

  return nu;
}

/***************
 * SUBJECT
 ****************/
function subjectBasicFill(subject, fill) {
  // Email subject specific
  let foundText = subject; // Copy so don't mutated

  // BASIC REPLACEMENTS
  foundText = foundText.replace(/\${\s*group.name\s*}/gi, fill.group_name);

  return foundText;
}

/***************
 * BOOKING RELATED
 ****************/
function bookingBasicFill(template, fill, skipHTMLWrappers) {
  // Booking specific
  let foundText = template; // Copy so don't mutated

  const roomTotal = fill.rooms_total + (fill.supplier_room_count || 0);

  // BASIC REPLACEMENTS
  foundText = foundText.replace(
    /\${\s*group.name\s*}/gi,
    _getReplaceContent(fill.group_name, "Group Name", null, skipHTMLWrappers)
  );
  foundText = foundText.replace(/\${\s*pax\s*}/gi, _getReplaceContent(fill.pax, "PAX", null, skipHTMLWrappers));

  return foundText;
}

function bookingFillLoop(message, bookingList, supplierId, skipHTMLWrappers, subject) {
  // PARAMS
  // message 				@{String} Message to fill
  // bookingList 			@{Array} List of bookings
  let modifiedMessage = message;

  // STEP 1 -- Fill in information per booking
  if (_hasBookingLoop(message)) {
    modifiedMessage = _assignBookingLoopData(message, bookingList, supplierId, skipHTMLWrappers);
  }

  // STEP 2 -- Fill in information by booking chain (example: 7 rooms and 6 rooms)
  modifiedMessage = bookingBasicFill(
    modifiedMessage,
    {
      group_name: _chainString(bookingList.map((booking) => booking.group_name)),
      pax: _chainString(
        bookingList.map((booking) => {
          return booking.data && booking.data.pax_num;
          // return BookingRoomService.getPaxCount(booking.data && booking.data.room_num,
          // 	booking.rooming_data)
        })
      ),
    },
    skipHTMLWrappers
  );
  // Wrap stuff in #BSTART and #BEND, then re-run loop
  // Product loop
  modifiedMessage = modifiedMessage.replace(/#PSTART/gi, "#BSTART #PSTART").replace(/#PEND/gi, "#PEND #BEND");
  modifiedMessage = _assignBookingLoopData(modifiedMessage, bookingList, supplierId, skipHTMLWrappers, subject);
  // Remaining stuff
  modifiedMessage = modifiedMessage.replace(/\${\s*/gi, "#BSTART ${").replace(/}/gi, "} #BEND");
  modifiedMessage = _assignBookingLoopData(modifiedMessage, bookingList, supplierId, skipHTMLWrappers, subject);
  modifiedMessage = modifiedMessage.replace(/#BSTART/gi, "").replace(/#BEND/gi, "");

  return modifiedMessage;
}

function _assignBookingLoopData(message, bookingList, supplierId, skipHTMLWrappers, subject) {
  let modifiedMessage = message;

  _getBookingMatches(message).forEach((match) => {
    // Compile each booking's information
    const replaceBlock = match[0];
    let contentToFill = match[1];
    let bookingBlobChain = [],
      tempMessage;

    bookingList.forEach((booking) => {
      // Fill basic booking stuff
      tempMessage = bookingBasicFill(
        contentToFill,
        {
          group_name: booking.group_name,
          pax: booking.data && booking.data.pax_num,
          // BookingRoomService.getPaxCount(booking.data && booking.data.room_num,
          // booking.rooming_data)
        },
        skipHTMLWrappers
      );
      // Fill focused supplier
      // Okay if some of these are null as they are only return values
      let bsWithSupplierId = Object.values(booking.booking_suppliers).filter((bs) => {
        return bs.supplier_id === supplierId;
      });
      let supplierFill = fillProductsBySupplierAndProductGroup(
        {
          supplier_id: supplierId,
          content: {
            message: tempMessage,
          },
          bookingSuppliers: bsWithSupplierId,
          bookingDays: _getFlattenedDays(null, booking.days),
          oldDates: {
            old_days: booking.revert_status_blob ? booking.revert_status_blob.oldDayRows : [],
            suppliers: booking.revert_status_blob ? booking.revert_status_blob.suppliers : {},
          },
          productData: bsWithSupplierId.reduce((map, bs) => {
            let bsProducts = Object.values(bs.products).reduce((submap, product) => {
              return [
                ...submap,
                {
                  product: product,
                  booking_supplier_id: bs.id,
                  bs_meta: _getProductMeta(
                    product.id,
                    bs.meta,
                    _getWhatDays(bs.id, bs.type_as, booking.days),
                    booking.all_booking_suppliers,
                    _getFlattenedDays(null, booking.all_days)
                  ),
                  dates: _getWhatDays(bs.id, bs.type_as, booking.days),
                  old_dates: _getWhatDays(
                    bs.id,
                    bs.type_as,
                    booking.revert_status_blob ? booking.revert_status_blob.oldDayRows || [] : []
                  ),
                },
              ];
            }, []);

            return [...map, ...bsProducts];
          }, []),
        },
        BookingRoomService.getRoomingListBreakdown({
          bookingMetaData: booking.data,
          bookingRoomingList: booking.rooming_data,
          bookingDays: booking.days,
          supplierRoomList: booking.supplier_rooms,
        }),
        subject
      );

      tempMessage = supplierFill.message;
      bookingBlobChain.push(tempMessage);
    });

    modifiedMessage = modifiedMessage.replace(replaceBlock, _chainString(bookingBlobChain));
  });

  return modifiedMessage;
}

function _hasBookingLoop(template) {
  // PARAMS
  // 	template @{String}
  // RETURNS @{Boolean}
  return /#BSTART.+?#BEND/gim.test(template);
}

function _getBookingMatches(template) {
  // Want match BETWEEN quotes
  const regex = /#BSTART(.+?)#BEND/gim;
  return Array.from(template.matchAll(regex));
}

function _getProductMeta(productId, bsMeta, dayList, bookingSuppliers, flattenedBookingDays) {
  // Get Extra suppliers
  let map = flattenedBookingDays
    .filter((d) => dayList.includes(d.date))
    .map((day) => {
      let adjust = 0;
      day.suppliers.forEach((bsid) => {
        if (["TOUR_GUIDE", "TRANSPORT"].includes(bookingSuppliers[bsid].type_as)) {
          if (bsMeta.exclude_suppliers && bsMeta.exclude_suppliers && bsMeta.exclude_suppliers[day.date][bsid]) return;
          let count = (bookingSuppliers[bsid].meta && bookingSuppliers[bsid].meta.count) || 0;
          adjust += Object.values(count).reduce((t, c) => t + Number(c), 0);
        }
      });
      return adjust;
    });

  return {
    count: bsMeta.count && bsMeta.count[productId],
    adjust_count: Math.max(...map),
    custom_name: bsMeta.custom_names && bsMeta.custom_names[productId],
  };
}

function _getFlattenedDays(maptype, bookingDays) {
  if (!maptype || maptype === "ALL") {
    // Flatten ALL suppliers for these days
    let blob;
    return bookingDays.map((day) => {
      return {
        date: day.date,
        suppliers: SuppliersMenu.FULL.reduce((arr, x) => {
          if (x.module === "ALL") return arr;
          return [...arr, ...(day[x.alt] || [])];
        }, []),
      };
    });
  }

  const mod = SuppliersMenu.MOD_DBKEY[maptype];
  return bookingDays.map((day) => {
    return {
      date: day.date,
      suppliers: day[mod],
    };
  });
}

function _getWhatDays(bookingSupplierId, maptype, bookingDays) {
  // What days supplier appears
  let dateList = [];

  if (!maptype) {
    bookingDays.forEach((item) => {
      SuppliersMenu.DBKEY.forEach((x) => {
        if (item[x].includes(bookingSupplierId)) dateList.push(item.date);
      });
    });
  } else {
    const mod = SuppliersMenu.MOD_DBKEY[maptype];
    bookingDays.forEach((item) => {
      if (item[mod].includes(bookingSupplierId)) dateList.push(item.date);
    });
  }

  return dateList.sort((a, b) => {
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
  });
}

function fillProductsByBookingSupplier(
  message,
  booking_supplier_id,
  bookingSupplier,
  bookingDays,
  bookingRoomData,
  oldData
) {
  // Used by Status Dialog to fill a specific booking supplier case
  // PARAMS
  // 	booking_supplier_id 	@{String} Booking suppler id to update
  // 	bookingSupplier			@{Object} Booking suppler information
  //		=> products
  // 		=> meta
  // 	bookingDays 			@{Object} Flattened days this booking supplier appears in
  // 	bookingRoomData 		@{Object} Breakdown of rooms from different sources
  // RETURN @{String}	Modified message

  const currentbsup = bookingSupplier;
  let modifiedMessage = message;

  // STEP 1 -- Fill in information per product
  // #PSTART ... #PEND
  if (_hasProductLoop(message)) {
    // For each loop block
    _getProductMatches(message).forEach((match) => {
      // Compile each product's information
      const replaceBlock = match[0];
      let contentToFill = match[1];
      let productMessageCompile = [],
        tempMessage;

      let onlyType = _checkProductNarrow(contentToFill);
      if (onlyType) contentToFill = contentToFill.replace(new RegExp("." + onlyType, "gi"), "");

      Object.values(currentbsup.products).forEach((product) => {
        if (onlyType && product.product.product_type !== onlyType) return;

        tempMessage = _replaceDateStuff(contentToFill, booking_supplier_id, bookingDays);
        tempMessage = _replaceOldDateStuff(contentToFill, oldData, booking_supplier_id);
        tempMessage = _replaceProductStuff(tempMessage, product, currentbsup.meta || {}, currentbsup.mock_meta);
        tempMessage = _fillBookingSupplierRoom(tempMessage, {
          status: currentbsup.status,
          dates: product.dates || _extractProductDays(bookingDays, booking_supplier_id),
          bookingRoomData,
          supplierCustomRoom: currentbsup.meta && currentbsup.meta.custom_room_num,
        });

        productMessageCompile.push(tempMessage);
      });

      modifiedMessage = modifiedMessage.replace(replaceBlock, _chainString(productMessageCompile));
    });
  }
  // STEP 2 -- Fill in remaingin information
  modifiedMessage = _replaceDateStuff(modifiedMessage, booking_supplier_id, bookingDays);
  modifiedMessage = _replaceOldDateStuff(modifiedMessage, oldData, booking_supplier_id);
  modifiedMessage = _fillBookingSupplierRoom(modifiedMessage, {
    status: bookingSupplier.status,
    bookingRoomData,
    supplierCustomRoom: bookingSupplier.meta && bookingSupplier.meta.custom_room_num,
    dates: _extractProductDays(bookingDays, booking_supplier_id),
  });
  modifiedMessage = modifiedMessage.replace(/#BSTART/gi, "").replace(/#BEND/gi, "");

  // STEP 3 -- Remaining Products
  modifiedMessage = _extraMergeProducts(modifiedMessage, Object.values(currentbsup.products));

  return modifiedMessage;
}

function _extractProductDays(bookingDays, booking_supplier_id) {
  return bookingDays
    .filter((day) => {
      return day.suppliers.some((item) => item === booking_supplier_id);
    })
    .map((day) => day.date);
}

function _hasProductLoop(template) {
  // PARAMS
  // 	template @{String}
  // RETURNS @{Boolean}
  return /#PSTART.+?#PEND/gim.test(template);
}

function _getProductMatches(template) {
  // Want match BETWEEN quotes
  const regex = /#PSTART(.+?)#PEND/gim;
  return Array.from(template.matchAll(regex));
}

function _replaceProductStuff(message, productInfo, bookingSupplierMeta, mockMeta) {
  const curproduct = mockMeta[productInfo.product_id || productInfo.id];
  const productName = curproduct.custom_names || productInfo.name;
  const productCount =
    (Number(curproduct.count) || 1) + (productInfo.product_type === "MEAL" ? curproduct.adjust_count : 0);

  return _replaceProductCore(message, {
    productName,
    productCount,
    productRate: productInfo.rate,
  });
}

function _extraMergeProducts(message, projectList) {
  // If not using a product loop, need to merge into one field
  const p = Object.values(projectList).reduce(
    (blob, v) => {
      blob.productName.push(v.bs_meta?.custom_name || v.product?.name || v.name);
      blob.productCount.push(
        (Number(v.bs_meta?.count || v.active) || 1) + (v.product?.product_type === "MEAL" ? v.bs_meta?.adjust_count : 0)
      );
      blob.productRate.push(v.product?.rate || v.rate);
      return blob;
    },
    {
      productName: [],
      productCount: [],
      productRate: [],
    }
  );
  return _replaceProductCore(message, {
    productName: p.productName.join(", "),
    productCount: p.productCount.join(", "),
    productRate: p.productRate.join(", "),
  });
}

function _replaceDateStuff(message, bsid, dayinfo, skip, old) {
  if (skip) return message;

  let dayCount = _dayCount(bsid, dayinfo);
  let fn = {
    getStart: () => {
      const d = dayinfo.find((day) => {
        return day.suppliers.includes(bsid);
      });
      return d ? d.date : null;
    },
    getEnd: () => {
      const d = dayinfo.reverse().find((day) => {
        return day.suppliers.includes(bsid);
      });
      return d ? d.date : null;
    },
    getList: () => {
      return dayinfo
        .filter((day) => {
          return day.suppliers.includes(bsid);
        })
        .map((day) => day.date);
    },
    dayCount: dayCount,
  };

  return old ? _replaceDateOldCore(message, fn) : _replaceDateCore(message, fn);
}

function _replaceOldDateStuff(message, oldDates, bsid, skip) {
  if (skip) return message;

  let dayinfo = _getFlattenedDays(null, oldDates.old_days);

  return _replaceDateStuff(message, bsid, dayinfo, skip, true);
}

function _dayCount(id, dayinfo) {
  let count = 0;
  dayinfo.forEach((day) => {
    if (day.suppliers.includes(id)) count += 1;
  });
  return count;
}

function _chainString(arr) {
  // converts array into chained string with: comma + oxford comma + and
  // PARAMS
  // 	arr @{Array}
  // RETURN @{String}
  if (!arr.length) return "";
  if (arr.length === 1) return arr[0];
  if (_confirmEnding(arr[0], "<br>") || _confirmEnding(arr[0], "</br>")) return arr.join("");
  if (arr.length === 2) return arr.join(" and ");
  return `${arr.slice(0, arr.length - 1).join(", ")}, and ${arr[arr.length - 1]}`;
}

function _confirmEnding(string, target) {
  if (string.substr(-target.length) === target) {
    return true;
  } else {
    return false;
  }
}

function fillProductsBySupplierAndProductGroup(emailGroupData, bookingRoomData, subject) {
  // Extracts data, fills in template information
  // The param is an information blob, Remakes mail object
  // Handles case of multiple booking suppliers of the same supplier/product
  // PARAMS
  // 	emailGroupData 	@{Object} Origin
  // 		=> content 		@{Object}
  // 		=> supplier_id 	@{String}
  // 		=> bookingSuppliers 	@{Array} => @{Object}
  // 		=> bookingDays 	@{Array}
  // 		=> productData 	@{Object}
  // 		=> oldDates 	@{Object}

  // RETURN @{Object} 	Mail content
  // 		=> supplier_id 	@{String}
  // 		=> contacts 	@{Array}
  // 		=> message 		@{String}

  const ec = emailGroupData.content || {};
  const supplier_id = emailGroupData.supplier_id;
  const supplier_name = ec.supplier_name;
  const contacts = ec.contacts;

  return {
    supplier_id,
    supplier_name,
    contacts,
    customSubject: _extractMessage(subject || "", emailGroupData, bookingRoomData, true),
    message: _extractMessage(ec.message || "", emailGroupData, bookingRoomData),
  };
}

function _extractMessage(message, emailGroupData, bookingRoomData, skipHTMLWrappers) {
  const supplier_id = emailGroupData.supplier_id;
  let modifiedMessage = message;

  // STEP 1 -- Fill in information per product
  // #PSTART ... #PEND
  if (_hasProductLoop(message)) {
    const productData = emailGroupData.productData;
    // For each loop block
    _getProductMatches(message).forEach((match) => {
      // Compile each product's information
      const replaceBlock = match[0];
      let contentToFill = match[1];
      let productMessageCompile = [],
        tempMessage;

      let onlyType = _checkProductNarrow(contentToFill);
      if (onlyType) contentToFill = contentToFill.replace(new RegExp("." + onlyType, "gi"), "");

      let bs;
      Object.values(productData).forEach((product) => {
        if (onlyType && product.product.product_type !== onlyType) return;
        bs = emailGroupData.bookingSuppliers.find((bs) => bs.id === product.booking_supplier_id);

        // Replace time
        tempMessage = _replaceProductDate(
          contentToFill,
          product.dates,
          product.product.product_type === "ROOM",
          false,
          skipHTMLWrappers
        );
        tempMessage = _replaceProductDate(
          tempMessage,
          product.old_dates,
          product.product.product_type === "ROOM",
          true,
          skipHTMLWrappers
        );
        tempMessage = _replaceTime(tempMessage, bs.time, skipHTMLWrappers);
        tempMessage = _replaceProductInfo(tempMessage, product.product, product.bs_meta, skipHTMLWrappers);
        tempMessage = _fillBookingSupplierRoom(
          tempMessage,
          {
            status: bs.status,
            bookingRoomData,
            dates: product.dates || _extractProductDays(emailGroupData.bookingDays, bs.id),
            supplierCustomRoom: product.bs_meta && product.bs_meta.custom_room_num,
          },
          skipHTMLWrappers
        );

        productMessageCompile.push(tempMessage);
      });

      modifiedMessage = modifiedMessage.replace(replaceBlock, _chainString(productMessageCompile));
    });
  }

  // STEP 2 -- Fill in Dates
  modifiedMessage = _replaceSupplierDate(
    modifiedMessage,
    emailGroupData.bookingSuppliers,
    emailGroupData.bookingDays,
    false,
    skipHTMLWrappers
  );
  let old = emailGroupData.oldDates || { suppliers: {}, old_days: [] };
  modifiedMessage = _replaceSupplierDate(
    modifiedMessage,
    Object.values(old.suppliers || old.old_suppliers),
    _getFlattenedDays(null, old.old_days),
    true
  );

  // STEP 3 -- Fill in Rooms
  // Only picking FIRST relevant
  const foundbs = emailGroupData.bookingSuppliers.find(
    (bs) => bs.supplier_id === supplier_id && bs.type_as === "HOTEL"
  );
  modifiedMessage = _fillBookingSupplierRoom(modifiedMessage, {
    status: (foundbs && foundbs.status) || 0,
    bookingRoomData,
    supplierCustomRoom: foundbs && foundbs.meta && foundbs.meta.custom_room_num,
    dates: emailGroupData.bookingDays
      .filter((day) => {
        return day.suppliers.some((item) => emailGroupData.bookingSuppliers.map((b) => b.id).includes(item));
      })
      .map((day) => day.date),
  });

  // STEP 4 -- Fill in Time, etc
  const firstbs = emailGroupData.bookingSuppliers.find((bs) => bs.supplier_id === supplier_id);
  modifiedMessage = _replaceTime(modifiedMessage, firstbs.time);

  // Step 5 -- Remaining products
  modifiedMessage = _extraMergeProducts(modifiedMessage, Object.values(emailGroupData.productData));

  // Cleanup
  modifiedMessage = modifiedMessage.replace(/#BSTART/gi, "").replace(/#BEND/gi, "");

  return modifiedMessage;
}

function _checkProductNarrow(text) {
  if (/\.ROOM/gi.test(text)) return "ROOM";
  if (/\.MEAL/gi.test(text)) return "MEAL";
  if (/\.EXCURSION/gi.test(text)) return "EXCURSION";
  if (/\.TOUR/gi.test(text)) return "TOUR";
  if (/\.OTHER/gi.test(text)) return "OTHER";
  return null;
}

function _fillBookingSupplierRoom(template, { status, bookingRoomData, supplierCustomRoom, dates }) {
  let foundText = template; // Copy so don't mutated

  const fill = BookingRoomService.flattenAndGetRoomingInfo({
    confirmed: status >= 4,
    dateList: dates,
    ...bookingRoomData,
    supplierCustomRoom,
  });

  const rtotal = fill.room_total + fill.extraTotal;
  const roomBreakdown = _getRoomBreakdown(fill.room_breakdown, fill.extraTotal);

  foundText = foundText.replace(/\${\s*rooms.total\s*}/gi, _getReplaceContent(rtotal, "Room Total", "room"));
  foundText = foundText.replace(/\${\s*rooms.breakdown\s*}/gi, _getReplaceContent(roomBreakdown, "Room breakdown"));

  return foundText;
}

function _getRoomBreakdown(breakdown, extraTotal) {
  // PARAMS
  // 	breakdown 	@{Object}
  // RETURN @{String}
  if (!breakdown) return null;
  if (!Object.values(breakdown).length) return null;
  let temp, count;
  return Object.keys(breakdown).reduce((str, key) => {
    temp = BOOKING_ROOMS.find((v) => v.value === key).print;
    count = Number(breakdown[key]);
    if (key === "SINGLE_DK") count += Number(extraTotal);
    return str + `${count} ${temp} ${count === 1 ? "room" : "rooms"}<br>`;
  }, "");
}

function _replaceTime(message, time) {
  let nu = message;

  const timeFormat = time ? format.formatTime(time) : null;

  nu = nu.replace(/\${\s*time\s*}/gi, _getReplaceContent(timeFormat, "Time"));

  return nu;
}

function _replaceProductDate(message, dateList, isRoom, old, skipHTMLWrappers) {
  // PARAMS
  // 	message 	@{String}
  // 	dateList 	@{Array} Sorted list of dates
  // RETURN @{String} Updated message
  const dayCount = dateList.length;
  const fn = {
    // If product is a room, need to include next day
    asNight: isRoom,
    dayCount,
    getStart: () => dateList[0],
    getEnd: () => dateList[dayCount - 1],
    getList: () => dateList,
  };
  return old ? _replaceDateOldCore(message, fn, skipHTMLWrappers) : _replaceDateCore(message, fn, skipHTMLWrappers);
}

function _replaceProductInfo(message, product, bsMeta) {
  return _replaceProductCore(message, {
    productName: bsMeta.custom_name || product.name,
    productCount: (Number(bsMeta.count) || 1) + (product.product_type === "MEAL" ? bsMeta.adjust_count : 0),
    productRate: product.rate,
  });
}

function _replaceSupplierDate(message, bookingSuppliers, bookingDays, old, skipHTMLWrappers) {
  // PARAMS
  // 	bookingSuppliers 	@{Object}
  // 	bookingDays 		@{Array}
  // RETURN @{String} Updated message
  const bsIdList = bookingSuppliers.map((bs) => bs.id);
  const days = bookingDays
    .filter((day) => {
      return day.suppliers.some((item) => bsIdList.includes(item));
    })
    .map((day) => day.date);

  let fn = {
    // If one of the suppliers is matched as a hotel, then need to include next day
    asNight: bookingSuppliers.includes((v) => v.type_as === "HOTEL"),
    dayCount: days.length,
    getStart: () => {
      // FIRST day a booking supplier appears in booking
      // Skipped days will be ignored
      return days[0];
    },
    getEnd: () => {
      // LAST day a booking supplier appears in booking
      // Skipped days will be ignored
      return days[days.length - 1];
    },
    getList: () => days,
  };

  return old ? _replaceDateOldCore(message, fn, skipHTMLWrappers) : _replaceDateCore(message, fn, skipHTMLWrappers);
}

function _replaceDateCore(message, { getStart, getEnd, getList, dayCount, asNight }, skipHTMLWrappers) {
  let nu = message;

  const dateList = _compileDateListReadable(getList(), asNight);
  const dateStart = format.formatDate(getStart());
  const dateEnd = format.formatDate(getEnd());

  nu = nu.replace(/\${\s*date.list\s*}/gi, _getReplaceContent(dateList, "Dates", null, skipHTMLWrappers));
  nu = nu.replace(/\${\s*date.of\s*}/gi, _getReplaceContent(dateStart, "Date", null, skipHTMLWrappers));
  nu = nu.replace(/\${\s*date.start\s*}/gi, _getReplaceContent(dateStart, "Start Date", null, skipHTMLWrappers));
  nu = nu.replace(/\${\s*date.end\s*}/gi, _getReplaceContent(dateEnd, "End Date", null, skipHTMLWrappers));
  nu = nu.replace(
    /\${\s*date.days\s*}/gi,
    _getReplaceContent(Number(dayCount), "Number of Days", "day", skipHTMLWrappers)
  );
  nu = nu.replace(
    /\${\s*date.nights\s*}/gi,
    _getReplaceContent(Number(dayCount), "Number of Nights", "night", skipHTMLWrappers)
  );

  return nu;
}

function _replaceDateOldCore(message, { getStart, getEnd, getList, dayCount, asNight }, skipHTMLWrappers) {
  let nu = message;

  const dateList = _compileDateListReadable(getList(), asNight);
  const dateStart = format.formatDate(getStart());
  const dateEnd = format.formatDate(getEnd());

  nu = nu.replace(/\${\s*date.old_list\s*}/gi, _getReplaceContent(dateList, "Old Dates", null, skipHTMLWrappers));
  nu = nu.replace(/\${\s*date.old_of\s*}/gi, _getReplaceContent(dateStart, "Old Date", null, skipHTMLWrappers));
  nu = nu.replace(
    /\${\s*date.old_start\s*}/gi,
    _getReplaceContent(dateStart, "Old Start Date", null, skipHTMLWrappers)
  );
  nu = nu.replace(/\${\s*date.old_end\s*}/gi, _getReplaceContent(dateEnd, "Old End Date", null, skipHTMLWrappers));
  nu = nu.replace(
    /\${\s*date.old_days\s*}/gi,
    _getReplaceContent(Number(dayCount), "Old Number of Days", null, skipHTMLWrappers)
  );
  nu = nu.replace(
    /\${\s*date.old_nights\s*}/gi,
    _getReplaceContent(Number(dayCount), "Old Number of Nights", null, skipHTMLWrappers)
  );
  return nu;
}

function _compileDateListReadable(inputDateList, asNight) {
  // Converts day list into readable days with range
  // So [2012-05-01, 2012-05-02, 2012-05-03, 2012-05-05]
  // Becomes [[2012-05-01, 2012-05-02, 2012-05-03], [2012-05-05]]
  // Becomes May 1 to May 3, and May 5, 2012
  // PARAMS
  // 	dateList 	@{Array} SORTED list of dates
  // RETURNS 	@{String}
  const dateList = inputDateList ? [...inputDateList] : null;
  if (!dateList || !dateList.length) return;
  if (dateList.length === 1 && !asNight) return format.formatDate(dateList[0]);
  //if(dateList.length === 1 && asNight) dateList.push(format.addDay(dateList[0]));

  let current, isDayAfter;
  const list = dateList
    .reduce((arr, date) => {
      // Group dates by their ranges
      if (!arr.length) return [[date]];
      // Check if new date is day after previous
      current = arr[arr.length - 1];
      isDayAfter = format.isSameDay(format.addDay(current[current.length - 1]), date);
      if (isDayAfter) {
        // Push to last range
        current.push(date);
        return arr;
      }
      // Not the day after, add new
      return [...arr, [date]];
    }, [])
    .map((dateRange, index, orig) => {
      // Only display year if last
      let isLast = orig.length - 1 === index;
      // Only ONE day (not range)
      if (dateRange.length === 1 && !asNight) return format.formatDate(dateRange[0], !isLast);
      if (asNight) dateRange.push(format.addDay(dateList[dateRange.length - 1]));
      // Range, only want first and last
      return `${format.formatDate(dateRange[0], true)} to ${format.formatDate(
        dateRange[dateRange.length - 1],
        !isLast
      )}`;
    }, "");

  return _chainString(list);
}

function _replaceProductCore(message, { productName, productCount, productRate }, skipHTMLWrappers) {
  let nu = message;
  if (!productRate) return nu;

  const rateDisplay = `${productRate.cur || "$"} ${productRate.value}`;

  nu = nu.replace(/\${\s*product.name\s*}/gi, _getReplaceContent(productName, "Product Name", null, skipHTMLWrappers));
  nu = nu.replace(
    /\${\s*product.count\s*}/gi,
    _getReplaceContent(productCount, "Product Count", null, skipHTMLWrappers)
  );
  nu = nu.replace(/\${\s*product.rate\s*}/gi, _getReplaceContent(rateDisplay, "Product Rate", null, skipHTMLWrappers));

  return nu;
}

/***************
 * GENERAL REPLACE
 ****************/
function hasReplaceContent(template) {
  return /\${REPLACE\s+.+?}/gi.test(template);
}

function getReplaceContent(template) {
  const regex = /\${REPLACE\s+.+?}/gi;
  const matches = Array.from(template.matchAll(regex));
  let textGuide = [];

  matches.forEach((item) => {
    textGuide.push(item[0].replace(/\${REPLACE\s+/g, "").replace("}", ""));
  });

  return textGuide;
}

function fillReplaceContent(template, fillDataArr) {
  // fillDataArr = What to replace IN ORDER
  const regex = /\${REPLACE\s+.+?}/g;
  const matches = Array.from(template.matchAll(regex));
  let textcopy = template;
  let replaceText = getReplaceContent(template);

  matches.forEach((item, index) => {
    textcopy = textcopy.replace(item[0], fillDataArr[index] || `<b>[IGNORED: ${replaceText[index]}]</b>`);
  });

  // Plural adjustment
  textcopy = textcopy.split(">1 nights").join(">1 night").split(" 1 nights").join(" 1 night");
  textcopy = textcopy.split(">1 days").join(">1 day").split(" 1 days").join(" 1 day");

  return textcopy;
}

/***************
 * UTIL
 ****************/
function _getReplaceContent(main, displayText, trail, skipHTMLWrappers) {
  const replace = "${REPLACE " + displayText + "}";
  const trailpl = trail + "s";
  let trailFill = !trail ? "" : " " + (main ? (isNaN(main) ? trailpl : Number(main) === 1 ? trail : trailpl) : trailpl);
  return `${skipHTMLWrappers ? "" : "<b>"}${main || replace}${trailFill}${skipHTMLWrappers ? "" : "</b>"}`;
}
