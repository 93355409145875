<template>
  <div>
    <div class="table py-5">
      <v-row>
        <v-col style="max-width: 60px"><!-- Spacer --></v-col>
        <v-col style="max-width: 110px">Date</v-col>
        <v-col>Breakfast</v-col>
        <v-col>Lunch</v-col>
        <v-col>Dinner</v-col>
      </v-row>

      <v-row v-for="(day, index) in formatedDayList" :key="day.rawDate">
        <v-col style="max-width: 60px">
          <span>{{ day.day }}</span>
        </v-col>
        <v-col class="black--text font-weight-bold" style="max-width: 110px">
          <span>{{ day.date }}</span>
        </v-col>

        <v-col class="product-select-row">
          <span v-if="!day.hasBreakfast"><!-- SPACER --></span>
          <MealBlock
            v-else
            :mode="mode"
            :selectedBsid="selectedRow"
            :dateList="dateList"
            :highlightedRows="highlightedRows"
            @set="(v) => (highlightedRows = v)"
            @mark="(v) => (selectedRow = v)"
            nestedType="BREAKFAST"
            :focusDate="day.rawDate"
            :supplierId="supplier.id"
            @add="openSelect(day, 'BREAKFAST')"
            @edit="(v) => openEdit(day, v, 'BREAKFAST')"
          />
        </v-col>
        <v-col class="product-select-row">
          <span v-if="!day.hasDayMeals"><!-- SPACER --></span>
          <MealBlock
            v-else
            :mode="mode"
            :selectedBsid="selectedRow"
            :dateList="dateList"
            :highlightedRows="highlightedRows"
            @set="(v) => (highlightedRows = v)"
            @mark="(v) => (selectedRow = v)"
            nestedType="LUNCH"
            :focusDate="day.rawDate"
            :supplierId="supplier.id"
            @add="openSelect(day, 'LUNCH')"
            @edit="(v) => openEdit(day, v, 'LUNCH')"
          />
        </v-col>
        <v-col class="product-select-row">
          <span v-if="!day.hasDayMeals"><!-- SPACER --></span>
          <MealBlock
            v-else
            :mode="mode"
            :selectedBsid="selectedRow"
            :dateList="dateList"
            :highlightedRows="highlightedRows"
            @set="(v) => (highlightedRows = v)"
            @mark="(v) => (selectedRow = v)"
            nestedType="DINNER"
            :focusDate="day.rawDate"
            :supplierId="supplier.id"
            @add="openSelect(day, 'DINNER')"
            @edit="(v) => openEdit(day, v, 'DINNER')"
          />
        </v-col>
      </v-row>
    </div>

    <div class="ma-2">
      <v-row class="button-row" justify="space-between" align="end">
        <g-button label="Close" icon="clear" @onclick="$emit('close')" />

        <div>
          <g-button label="Back" icon="keyboard_arrow_left" @onclick="$emit('revert')" />
          <g-button type="primary" label="Next" @onclick="setMeals" />
        </div>
      </v-row>
    </div>

    <ProductSelectDialog
      nested
      :mode="mode"
      v-model="dialog"
      module="RESTAURANT"
      :supplier="supplier"
      :productFilter="productFilter"
      :nestedType="selected.nestedType"
      :parentBookingSupplierId="selected.parentBookingSupplierId"
      :startDay="selectedDate"
      :editBsSupplierId="edit.editBsSupplierId"
      :editProduct="edit.editProduct"
      :editTime="edit.editTime"
      :editConfig="edit.editConfig"
      @select="selectProduct"
    />
  </div>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import format from "@/services/format";
import { ProductService } from "@/modules/products";

import MealBlock from "./meals/_MealBlock";
import ProductSelectDialog from "../step2_products/dialog/ProductSelectDialog";

export default {
  name: "MealSelectWrapper",
  props: {
    mode: String,
    // NESTED STUFF
    nested: Boolean,

    // Main Reference
    supplier: Object,
    productFilter: Object,
    dateToBsidMapping: Object,
    dateList: Array,
  },
  components: {
    ProductSelectDialog,
    MealBlock,
  },
  data() {
    return {
      dialog: false,
      selectedDate: null,
      selected: {
        parentBookingSupplierId: null,
        nestedType: null,
      },
      edit: {
        editBsSupplierId: null,
        editProduct: null,
        editTime: null,
        editConfig: null,
      },

      // Highlights
      highlightedRows: {},
      selectedRow: null,
    };
  },
  computed: {
    formatedDayList() {
      const list = this.$store.getters["BookingDayStore/dayLinkList"]();
      return list
        .map((item, index) => {
          let current = this.dateToBsidMapping[item.rawDate];
          let dayafter = index > 0 ? this.dateToBsidMapping[(list[index - 1] || {}).rawDate] : null;
          return {
            ...item,
            parent_bsid: current || dayafter,

            hasBreakfast: !!dayafter,
            hasDayMeals: !!current,
          };
        })
        .filter((item, index) => {
          // Add current OR dayafter a current (for breakfast)
          return item.parent_bsid;
        });
    },
    suppliers() {
      return this.$store.getters["BookingDayStore/suppliers"];
    },
  },
  methods: {
    openSelect(day, mealType) {
      this.selected = {
        parentBookingSupplierId: day.parent_bsid,
        nestedType: mealType,
      };
      this.selectedDate = day.rawDate;
      this.dialog = true;
    },
    openEdit(day, bsid, mealType) {
      this.selected = {
        parentBookingSupplierId: day.parent_bsid,
        nestedType: mealType,
      };
      this.edit = {
        editBsSupplierId: bsid,
        editProduct: this.suppliers[bsid].products,
        editTime: this.suppliers[bsid].time,
        editConfig: this.suppliers[bsid].meta,
      };
      this.selectedDate = day.rawDate;
      this.dialog = true;
    },

    selectProduct(bsid) {
      Vue.set(this.highlightedRows, this.selectedDate + bsid, true);
      this.selectedRow = this.selectedDate + bsid;
    },

    /***********
     * BREAKFAST
     * ********/
    setMeals() {
      this.$emit("next");
    },
  },
  mounted() {},
};
</script>

<style lang="scss"></style>
