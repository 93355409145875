<template>
  <v-row class="pa-0 black--text" :class="excluded ? 'red lighten-5' : 'green lighten-5'">
    <v-col cols="1" style="max-width: 70px">
      <v-btn
        x-small
        icon
        class="mx-1 cyoa-btn"
        :color="excluded ? 'red' : 'grey lighten-1'"
        @click="toggleExclude(true)"
      >
        <v-icon>clear</v-icon>
      </v-btn>

      <v-btn
        x-small
        icon
        class="mx-1 cyoa-btn"
        :color="!excluded ? 'green' : 'grey lighten-1'"
        @click="toggleExclude(false)"
      >
        <v-icon>check</v-icon>
      </v-btn>
    </v-col>

    <v-col align="end" style="max-width: 50px">
      <span>x {{ sprod.count }}</span>
    </v-col>

    <v-col class="pa-0" align-self="center">
      <span>{{ sprod.label }}</span>
    </v-col>

    <v-col class="pa-0" align-self="center">
      <v-icon small left>{{ displayIcon }}</v-icon>
      <span class="subtitle-2">{{ sprod.supplier_name }}</span>
    </v-col>
  </v-row>
</template>

<script>
import SUPMENU from "@/config/menus/suppliersMenu";
export default {
  props: {
    bookingSupplierId: String,
    date: String,
    sprod: Object,
    excludeMapping: Object,
  },
  computed: {
    displayIcon() {
      const type = this.sprod.type_as;
      return SUPMENU.FULL.find((v) => v.module === type).icon;
    },
    excluded() {
      if (!this.excludeMapping) return false;
      if (!this.excludeMapping[this.date]) return false;
      return this.excludeMapping[this.date][this.sprod.bsid];
    },
  },
  methods: {
    toggleExclude(value) {
      this.$emit("toggle", {
        bsid: this.bookingSupplierId,
        date: this.date,
        value,
      });
    },
  },
};
</script>
