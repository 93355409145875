<template>
  <div>
    <AllFinanceTable
      v-if="view === 'GENERAL'"
      :query="query"
      :showRecon="showRecon"
      :showArchive="showArchive"
      :showTotal="showTotal"
      :splitTotal="splitTotal"
      class="finance-table"
    />
    <SupplierTable
      v-if="view === 'SUPPLIER'"
      :query="query"
      :showRecon="showRecon"
      :showArchive="showArchive"
      :showTotal="showTotal"
      :splitTotal="splitTotal"
      class="finance-table"
    />
  </div>
</template>

<script>
import AllFinanceTable from "./table/_AllFinanceTable";
import SupplierTable from "./table/_SupplierTable";
export default {
  props: {
    showTotal: Boolean,
    splitTotal: Boolean,
    query: Object,
    view: String,
    showRecon: Array,
    showArchive: Array,
    height: [Number, String],
  },
  components: {
    AllFinanceTable,
    SupplierTable,
  },
};
</script>

<style lang="scss">
.finance-table th {
  color: black !important;
  background: #e0e0e0;
  border: 1px solid lightgrey;
}
.excel {
  border: 1px solid lightgrey;
  text-align: center;
}
</style>
