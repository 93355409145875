<template>
  <a-card nopad class="d-flex caption">
    <v-col>
      <elwrapper label="Single">
        <div class="room-sum">
          {{ roomBreakdown.SINGLE_DK }} {{ roomBreakdown.SINGLE_SUPPLIER ? "+ " + roomBreakdown.SINGLE_SUPPLIER : "" }}
        </div>
      </elwrapper>
      <elwrapper label="Double">
        <div class="room-sum">{{ roomBreakdown.DOUBLE_QK }}</div>
      </elwrapper>
      <elwrapper label="Twin">
        <div class="room-sum">{{ roomBreakdown.DOUBLE_DOUBLE }}</div>
      </elwrapper>
      <elwrapper label="Triple">
        <div class="room-sum">{{ roomBreakdown.TRIPLE }}</div>
      </elwrapper>
      <elwrapper label="Quad">
        <div class="room-sum">{{ roomBreakdown.QUAD }}</div>
      </elwrapper>
    </v-col>

    <v-col>
      <elwrapper label="# of rooms">
        <div class="room-sum">{{ totalRooms }}</div>
      </elwrapper>
      <elwrapper label="# of pax">
        <div class="room-sum">{{ totalPax }}</div>
      </elwrapper>
    </v-col>
  </a-card>
</template>

<script>
export default {
  props: {
    roomList: Array,
    specialList: Object,
  },
  computed: {
    roomBreakdown() {
      // Better way to do this? Yes.
      // Get the total of each room type
      // Split and combine to room category
      let all = {
        SINGLE_DK: 0,
        DOUBLE_QK: 0,
        DOUBLE_DOUBLE: 0,
        TRIPLE: 0,
        QUAD: 0,

        // Add any special rooms to total
        SINGLE_SUPPLIER: this.specialList ? this.specialList.ALL.length : 0,
      };
      this.roomList.forEach((item) => {
        if (item.meta && item.meta.cancelled) return;
        all[item.type] += 1;
      });

      return all;
    },
    twin() {
      return parseInt(this.roomBreakdown.DOUBLE_QK) + parseInt(this.roomBreakdown.DOUBLE_DOUBLE);
    },
    totalRooms() {
      const total = this.$store.getters["BookingRoomStore/totalRooms"];

      return this.specialList && this.specialList.ALL.length ? `${total} + ${this.specialList.ALL.length}` : total;
    },
    totalPax() {
      const total = this.$store.getters["BookingRoomStore/totalPax"];
      return this.specialList && this.specialList.ALL.length ? `${total} + ${this.specialList.ALL.length}` : total;
    },
  },
};
</script>

<style lang="scss">
.room-sum {
  height: 34px;
  display: flex;
  align-items: center;
}
</style>
