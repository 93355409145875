<template>
  <div :class="colorStatus">
    <v-row justify="space-between" align="center" class="reminder-item">
      <div class="d-flex align-center">
        <v-icon v-if="displayIcon" small class="px-2">{{ displayIcon }}</v-icon>
        <div class="subtitle-2">{{ supplierData.name || "General Reminder" }}</div>

        <v-btn
          v-if="!hidebuttons && insideBooking"
          icon
          small
          class="reminder-clear"
          @click="$emit('edit', supplierData)"
        >
          <v-icon small>edit</v-icon>
        </v-btn>
        <v-btn
          v-if="!hidebuttons && insideBooking"
          icon
          small
          class="reminder-clear"
          @click="$emit('copy', supplierData)"
        >
          <v-icon small>content_copy</v-icon>
        </v-btn>
      </div>

      <div class="reminder-clear">
        <div v-if="isDone">
          <v-tooltip top left>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                <v-btn small text disabled>
                  <v-icon small>check_circle</v-icon>
                  <span>Done</span>
                </v-btn>
              </span>
            </template>
            <div>
              <v-row class="pa-0">
                <v-col class="pa-0">{{ displayDoneName }}</v-col>
              </v-row>
              <v-row class="pa-0">
                <v-col class="pa-0">{{ displayDate }}</v-col>
              </v-row>
            </div>
          </v-tooltip>

          <v-btn v-if="!hidebuttons" small icon @click="revertComplete">
            <v-icon small>undo</v-icon>
          </v-btn>
        </div>
        <div v-else-if="!hidebuttons">
          <v-btn small text @click="markComplete">
            <v-icon small>check_circle_outline</v-icon>
            <span>Done</span>
          </v-btn>
          <v-btn small icon @click="$emit('remove', supplierData)">
            <v-icon small>clear</v-icon>
          </v-btn>
        </div>
      </div>
    </v-row>
    <div class="caption pb-1" style="margin-left: 40px">
      {{ supplierData.note }}
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { SuppliersMenu } from "@/config/menus";
import format from "@/services/format";
export default {
  props: {
    mode: String,
    supplierData: Object,
    accountList: Array,
    hidebuttons: Boolean,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    displayIcon() {
      if (!this.supplierData.type_as || this.supplierData.type_as === "GENERAL") return "notifications";

      return SuppliersMenu.FULL.find((item) => item.module === this.supplierData.type_as).icon;
    },
    insideBooking() {
      return this.mode && this.mode === "BOOKING";
    },
    isDone() {
      return this.supplierData.done_by;
    },

    displayDoneName() {
      const d = this.accountList && this.accountList.find((item) => item.id === this.isDone);
      return (d && d.name) || "";
    },
    displayDate() {
      return this.supplierData.done_date && format.formatDate(this.supplierData.done_date);
    },

    colorStatus() {
      if (this.supplierData.done_date) return "green lighten-2";
      if (moment(this.supplierData.date).diff(moment(), "hours") <= 72) return "red lighten-2";
      if (moment(this.supplierData.date).diff(moment(), "days") <= 14) return "warning lighten-2";
      return "grey";
    },
  },
  methods: {
    markComplete() {
      this.loading = true;
      this.$store
        .dispatch("ReminderStore/markComplete", {
          reminder_id: this.supplierData.id,
          done_by: this.$store.getters["AccountStore/id"],
          done_date: format.convertDate(new Date()),
        })
        .then((data) => {
          this.loading = false;
          this.$emit("oncomplete", data);
        })
        .catch((err) => this.$root.$error(err));
    },
    revertComplete() {
      this.loading = true;
      this.$store
        .dispatch("ReminderStore/markComplete", {
          reminder_id: this.supplierData.id,
          done_by: null,
          done_date: null,
        })
        .then((data) => {
          this.loading = false;
          this.$emit("oncomplete", data);
        })
        .catch((err) => this.$root.$error(err));
    },
  },
};
</script>

<style lang="scss">
.reminder-item {
  min-height: 30px;

  .reminder-clear {
    display: none;
  }

  &:hover .reminder-clear {
    display: inline;
  }
}
</style>
