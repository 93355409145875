import api from "./api";
const STORE_EMAILS = "STORE_EMAILS";
const STORE_ACCOUNTS = "STORE_ACCOUNTS";

const namespaced = true;

const state = {
  currentClient: null,
  emailList: null,

  accountMap: {},
};

const mutations = {
  STORE_EMAILS(state, { clientId, emails }) {
    state.currentClient = clientId;
    state.emailList = emails;
  },
  STORE_ACCOUNTS(state, data) {
    state.accountMap = data;
  },
};

const actions = {
  getClientEmailHistory({ commit }, { clientId, query }) {
    return api
      .getEmailArchives(clientId)
      .then((res) => {
        commit(STORE_EMAILS, {
          clientId,
          emails: res.data,
        });
      })
      .catch((err) => {
        if (err) throw err.data;
      });
  },

  storeAccounts({ commit }, data) {
    commit(STORE_ACCOUNTS, data);
  },
};

const getters = {
  account_ref: (state) => state.accountMap || {},
  savedClientId: (state) => state.currentClient,
  emailList: (state) => state.emailList || [],
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
