import Vue from "vue";

import ENumber from "./ENumber";
import EConvert from "./EConvert";
import PCheckbox from "./PCheckbox";
import PSwitch from "./PSwitch";
import PLink from "./PLink";

Vue.component("enumber", ENumber);
Vue.component("econvert", EConvert);
Vue.component("pcheck", PCheckbox);
Vue.component("pswitch", PSwitch);
Vue.component("plink", PLink);
