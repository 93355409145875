<template>
  <a-card>
    <v-row class="mx-2 py-2" justify="space-between">
      <span class="subtitle-2">{{ supplierName }} Bookings</span>
    </v-row>

    <SupplierBookingTable
      :dataList="bookingList"
      :contactList="contacts"
      :searchRecord="searchRecord"
      :loading="loadingSuppliers"
      :value="value"
      @input="(v) => $emit('input', v)"
    />
  </a-card>
</template>

<script>
import SupplierBookingTable from "./_SupplierBookingTable";
export default {
  props: {
    value: Object,
  },
  components: {
    SupplierBookingTable,
  },
  data() {
    return {
      headers: [
        {
          text: "Name",
          value: "name",
        },
      ],
      selectedBookings: {},
      selectedContacts: {},

      supplierName: null,
      bookingList: [],
      contacts: [],
      loadingSuppliers: false,
      searchRecord: null,
    };
  },
  computed: {},
  mounted() {
    this.loadingSuppliers = true;
    // Get query
    const savedTab = this.$store.getters["BookingEmailStore/activeTab"];
    const record = this.$store.getters["BookingEmailStore/" + savedTab + "Search"];

    this.searchRecord = record;

    // Existing query
    // List of suppliers + their contacts the user can select
    this.$store
      .dispatch("SupplierBookingEmailStore/getSupplierBookings", record || {})
      .then((data) => {
        this.loadingSuppliers = false;
        this.selectedBookings = {};

        let ds = data.supplier;
        this.supplierName = `${ds.name} (${ds.city || ""}${ds.subcity ? " / " + ds.subcity : ""})`;
        this.bookingList = data.bookings;
        this.contacts = data.contacts;

        this.$emit("setBookingRef", data.bookings);
      })
      .catch((err) => {
        this.loadingSuppliers = false;
        this.$root.$error(err);
      });
  },
};
</script>
