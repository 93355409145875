<template>
  <v-container class="pa-2" fluid>
    <NoEmailAlert />
    <a-card nopad>
      <v-row class="pa-0">
        <v-col class="pa-0">
          <g-button icon="arrow_left" label="Return" @onclick="goBack" />
          <span class="subtitle-2 px-2">1 - {{ title }}</span>

          <!-- <g-button disabled label="1 - Select" class="mx-1" /> -->
          <g-button :disabled="emailView === 1" label="2 - Compose" @onclick="emailView = 1" />
          <g-button :disabled="emailView === 2" label="3 - Preview" @onclick="emailView = 2" />
        </v-col>

        <v-col class="pa-0 d-flex justify-end" cols="2">
          <EmailSendButton
            :disabled="disabled"
            :subject="formData.subject"
            :attachments="formData.attachments"
            :compileMailFn="compileMailFn"
            :onFinishFn="goBack"
          />
        </v-col>
      </v-row>
    </a-card>

    <a-card v-show="emailView === 1">
      <TemplateSelect vertical @input="fillTemplate" />

      <v-divider class="my-3" />

      <SendToForm v-model="mailGroup" :supplierId="supplierId" :clientId="clientId" :isclient="isclient" class="px-2" />

      <EmailForm v-model="formData" class="px-2" />
    </a-card>

    <a-card v-if="emailView === 2">
      <PreviewEmailWrapper
        :subject="formData.subject"
        :attachments="formData.attachments"
        :compileMailFn="compileMailFn"
      />
    </a-card>

    <TemplateDialog
      v-model="dialog"
      :title="selectedTemplateTitle"
      :floatingText="floatingText"
      :subject="this.formData.subject"
      @set="setTemplateMessage"
      @setSubject="setSubject"
    />
  </v-container>
</template>

<script>
import Vue from "vue";
import TemplateDialog from "@/modules/admin.email_template/components/TemplateDialog";
import {
  TemplateSelect,
  // TemplateDialog,
  PreviewEmailWrapper,
  EmailTemplateService,
} from "@/modules/admin.email_template";
import { EmailForm, EmailSendButton, NoEmailAlert, SendToForm, EmailService } from "@/modules/email";
export default {
  props: {
    value: Object,
  },
  components: {
    TemplateDialog,
    EmailSendButton,
    NoEmailAlert,
    EmailForm,
    SendToForm,
    PreviewEmailWrapper,
    TemplateSelect,
  },
  data() {
    return {
      // View
      emailView: 1,

      // Template fill stuff
      dialog: false,
      selectedTemplateTitle: "",
      floatingText: "",

      // Form
      mailGroup: [],
      formData: {},
    };
  },
  watch: {
    value(v) {
      this.formData = v;
    },
  },
  computed: {
    returnTo() {
      return this.$store.state.EmailStore.returnTo;
    },

    // Supplier specific
    title() {
      return this.$store.getters["EmailStore/title"];
    },
    isclient() {
      return this.$store.getters["EmailStore/isclient"];
    },
    supplierId() {
      return this.$store.getters["EmailStore/supplierId"];
    },
    clientId() {
      return this.$store.getters["EmailStore/clientId"];
    },

    disabled() {
      return !this.mailGroup.length || !(this.formData && this.formData.subject);
    },
  },
  methods: {
    goBack() {
      this.$router.push(this.returnTo);
    },
    fillTemplate(found) {
      this.selectedTemplateTitle = found.name;

      // Fill out defaults
      // Pass to dialog to fill out the rest
      Vue.set(this.formData, "subject", EmailTemplateService.supplierBasicFill(found.subject, true));

      // Set Language
      Vue.set(this.formData, "language", found.language);

      // Replace markers with stuff backend isn't going to fill out
      this.floatingText = EmailTemplateService.supplierBasicFill(found.text);

      this.dialog = EmailTemplateService.hasReplaceContent(this.floatingText);
      if (!this.dialog) {
        Vue.set(this.formData, "message", this.floatingText);
      }
    },
    setTemplateMessage(text) {
      Vue.set(this.formData, "message", text);
    },
    setSubject(text) {
      Vue.set(this.formData, "subject", text);
    },

    compileMailFn() {
      const message = this.formData.message;
      const language = this.formData.language;

      this.mailGroup.forEach((selectedSupplier) => {
        // Replace contact names her
        // Any more, leave to custom compile function
        selectedSupplier.message = EmailTemplateService.replaceContact(
          selectedSupplier.contacts.nameList,
          message,
          language
        );
        selectedSupplier.message = EmailTemplateService.replaceSupplier(selectedSupplier.message, selectedSupplier);
      });

      return Promise.resolve(this.mailGroup);
    },
  },
};
</script>
