<template>
  <v-tabs v-model="tab" :grow="true" dense>
    <v-tabs-slider></v-tabs-slider>
    <v-tab v-for="(v, i) in tabs" :key="i" :to="v.to">
      {{ v.text }}
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  data() {
    return {
      tab: 0,
      tabs: [
        {
          text: "Information",
          to: { name: "suppliers_info" },
        },
        {
          text: "Contacts",
          to: { name: "suppliers_contacts" },
        },
        {
          text: "Products",
          to: { name: "suppliers_products" },
        },
        {
          text: "Documents",
          to: { name: "suppliers_documents" },
        },
        {
          text: "Pictures",
          to: { name: "suppliers_pictures" },
        },
        {
          text: "History",
          to: { name: "suppliers_history" },
        },
      ],
    };
  },
};
</script>
