<template>
  <a-card nopad class="history-search">
    <v-form @submit.prevent="search">
      <v-row>
        <a-select
          label="User"
          freeopt
          smlabel
          :value="displayUser"
          @input="(v) => (inputUser = v)"
          :items="accountContacts"
        />

        <a-select
          label="Contact"
          freeopt
          smlabel
          :value="displayContact"
          @input="(v) => (inputContact = v)"
          :hint="hiddenContent"
          :items="contactData.emailList"
        />

        <date-range rangeAlways :datewallEnd="datewallEnd" :value="displayDates" @input="(v) => (inputDates = v)" />
      </v-row>

      <v-row class="px-2 pt-2" justify="end">
        <div>
          <g-button label="Clear" @onclick="clear" />
          <search-button class="mx-5" />
        </div>
      </v-row>
    </v-form>
  </a-card>
</template>

<script>
import { EmailService } from "@/modules/email";
import format from "@/services/format";
export default {
  props: {
    value: Object,
  },
  data() {
    return {
      inputContact: "",
      inputUser: "",
      inputDates: {
        from: "",
        to: "",
        range: true,
      },

      datewallEnd: format.convertDate(new Date()),
    };
  },
  computed: {
    displayUser() {
      return this.inputUser || this.value.user || "";
    },
    displayContact() {
      return this.inputContact || this.value.contact || "";
    },
    displayDates() {
      return {
        from: this.inputDates.from || this.value.dates.from,
        to: this.inputDates.to || this.value.dates.to,
        range: true,
      };
    },

    accountContacts() {
      const accounts = this.$store.getters["ClientHistoryStore/account_ref"];
      return Object.keys(accounts).map((key) => {
        return {
          label: `${accounts[key].name} -- ${key}`,
          value: accounts[key],
        };
      });
    },
    contactData() {
      const allContacts = this.$store.getters["ClientStore/all_contacts"];
      const primary = this.$store.getters["ClientStore/primary_contact"];
      return EmailService.compileContactDropdownData(allContacts, primary.id);
    },
    hiddenContent() {
      const hidden = this.contactData.hidden;
      return hidden ? `Hiding ${hidden} contacts (no email)` : "";
    },
  },
  methods: {
    clear() {
      this.user = "";
      this.contact = "";
      this.dates = {
        // THREE DAYS AGO
        from: format.convertDate(new Date(Date.now() - 3 * 24 * 60 * 60 * 1000)),
        to: format.convertDate(new Date()),
        range: true,
      };

      this.$emit("search", {
        user: this.user,
        contact: this.contact,
        dates: this.dates,
      });
    },
    search() {
      this.$emit("search", {
        user: this.displayUser,
        contact: this.displayContact,
        dates: this.displayDates,
      });
    },
  },
};
</script>

<style lang="scss">
.history-search {
  display: flex;
  align-items: center;

  .date-group {
    display: flex;
    align-items: center;
  }

  .search-item {
    max-width: 200px;
  }
}
</style>
