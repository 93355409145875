import axios from "@/plugins/axios";

export default {
  getImage(id) {
    return axios.get(`/signature_images/${id}`);
  },
  getImageList(account_id) {
    return axios.get(`/signatures/${account_id}/images`);
  },

  addImage(account_id, { desciption, file }) {
    const formData = new FormData();
    formData.append("desciption", desciption);
    formData.append("file", file);
    return axios.post(`/signatures/${account_id}/images`, formData, {
      "Content-Type": "multipart/form-data",
    });
  },
  updateImage(id, { description, description_cache, dependent_id }) {
    // Note: CANNOT replace actual image
    return axios.post(`/signature_images/${id}`, {
      description,
      description_cache,
      account_id: dependent_id,
    });
  },
  saveDefaultImages(imageList) {
    return axios.post("/signature_image_defaults", {
      images: imageList,
    });
  },
  deleteImage(id) {
    return axios.delete(`/signature_images/${id}`);
  },
  deleteImageList(idList) {
    return axios.delete("/signature_images", {
      data: { image_list: idList },
    });
  },
};
