<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <v-btn small icon v-on="on">
        <v-icon small color="grey lighten-1">free_breakfast</v-icon>
      </v-btn>
    </template>
    <span>Breakfast Included</span>
  </v-tooltip>
</template>

<script>
export default {};
</script>
