<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <template v-slot:activator="{ on }">
      <v-btn color="black" v-on="on" :disabled="disabled" small outlined>
        <v-icon left small>cancel</v-icon>
        <span>Cancel Booking</span>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <span class="subtitle-2">Notes (ie. Reason for cancel, etc.)</span>
      </v-card-title>

      <v-card-text>
        <f-textarea rows="5" v-model="notes" />
      </v-card-text>

      <v-card-actions>
        <g-button label="Nevermind" @onclick="dialog = false" />
        <div class="flex-grow-1"></div>
        <g-button label="Cancel Booking" icon="cancel" type="black" @onclick="this.handleCancel" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
  },
  data() {
    return {
      dialog: false,
      notes: "",
    };
  },
  methods: {
    handleCancel() {
      this.dialog = false;
      this.$emit("cancel", this.notes);
    },
  },
};
</script>
