<template>
  <a-card nopad class="special-roomlist">
    <div class="subtitle-2">Supplier Rooms</div>
    <div class="booking-title d-flex justify-space-between">
      <span>{{ roomData.label }}</span>
      <span>({{ roomData.pax }} pax)</span>
    </div>
    <div v-for="(g, index) in specialList.ALL" :key="index" class="roomrow mx-3 px-2">
      <div class="pr-3">
        <!-- <v-icon small>hotel</v-icon> -->
        <RoomDateHover
          :first="g.first_name"
          :last="g.last_name"
          :roomType="g.type_as"
          :roomDateRange="g.roomDateRange"
        />
      </div>
      <div class="namelist">
        <span class="namefield">{{ g.first_name }} {{ g.last_name }}</span>
        <v-btn icon small @click="openEdit(g)">
          <v-icon small>edit</v-icon>
        </v-btn>
      </div>
    </div>
    <div v-if="!specialList.ALL.length">(None)</div>

    <SupplierNameDialog v-model="editNameDialog" :editingSupplierRoom="editingSupplierRoom" />
  </a-card>
</template>

<script>
import BOOKING_ROOMS from "@/config/BOOKING_ROOMS";
import RoomDateHover from "./_RoomDateHover";
import { SupplierNameDialog } from "@/modules/bookings.supplier_rooms";
export default {
  components: {
    RoomDateHover,
    SupplierNameDialog,
  },
  props: {
    specialList: Object,
  },
  data() {
    return {
      editNameDialog: false,
      editingSupplierRoom: {},
    };
  },
  computed: {
    roomData() {
      const type = BOOKING_ROOMS.find((item) => item.value === "SINGLE_DK");
      return type;
    },
  },
  methods: {
    getDisplay(type) {
      if (type === "TOUR_GUIDE") return "Tour Guide";
      return "Bus Driver";
    },
    openEdit(sroom) {
      this.editNameDialog = true;
      this.editingSupplierRoom = sroom;
    },
  },
};
</script>

<style lang="scss">
.special-roomlist {
  .roomrow {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .namelist {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
</style>
