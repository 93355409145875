<template>
  <div class="d-flex justify-end">
    <!-- <div v-if="value" style="opacity: 0.5">
      <v-icon x-small color="green" left>check</v-icon>
      <span class="overline green--text">Confirmed</span>
    </div> -->
    <!-- <div v-else class="d-flex align-center"> -->
    <input type="checkbox" class="mr-2" :checked="value" @click="$emit('input', $event.target.checked)" />
    <span class="caption">Confirm {{ customYear }} Year Rate</span>
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  props: {
    value: Number,
    customYear: String,
  },
};
</script>
