import { render, staticRenderFns } from "./_SupplierRoom.vue?vue&type=template&id=23d7c7a0"
import script from "./_SupplierRoom.vue?vue&type=script&lang=js"
export * from "./_SupplierRoom.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports