<template>
  <v-menu bottom right open-on-hover>
    <template v-slot:activator="{ on }">
      <v-btn icon color="grey" v-on="on">
        <v-icon>minimize</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-btn
        depressed
        x-small
        class="mx-1 cyoa-btn"
        color="grey lighten-1"
        v-for="(path, option) in choosePath"
        :key="option"
        @click="$emit('select', { type: path, text: option })"
      >
        {{ option }}
      </v-btn>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    choosePath: Object,
  },
};
</script>
