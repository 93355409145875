<template>
  <a-card nopad>
    <div class="booking-title d-flex justify-space-between grey darken-1 white--text">
      <div>
        <v-icon left small color="white">{{ icon }}</v-icon>
        <span>{{ title }}</span>
      </div>
      <div>
        <g-button
          style="height: 20px"
          icon="add"
          label="Add"
          :disabled="cancelled"
          @onclick="$emit('dialog', { mod: type, dbkey: dbkey })"
        />
      </div>
    </div>

    <SupplierBlockRowWrapper
      v-for="(d, index) in data"
      :key="daynum + d.id"
      @click.native="$emit('edit', { data: d, type: type })"
      top
      :d="d"
      :type="type"
      :daynum="daynum"
      :date="date"
      :dbkey="dbkey"
      :contentIndex="d.contentIndex"
      :cancelled="cancelled"
      :roomBreakdownData="roomBreakdownData"
      @remove="(v) => $emit('remove', v)"
      @editRoomName="(v) => $emit('editRoomName', v)"
      @editConfirm="(v) => $emit('editConfirm', v)"
      @syncmeta="syncmeta"
    >
      <template v-slot:default="{ nestedSuppliers }">
        <SupplierBlockRowWrapper
          v-for="(subd, subindex) in nestedSuppliers"
          :key="daynum + subd.id"
          @click.native="$emit('edit', { data: subd, type: type })"
          :d="subd"
          :type="type"
          :daynum="daynum"
          :date="date"
          :dbkey="dbkey"
          :contentIndex="subd.contentIndex"
          :cancelled="cancelled"
          :roomBreakdownData="roomBreakdownData"
          @remove="(v) => $emit('remove', v)"
          @editRoomName="(v) => $emit('editRoomName', v)"
          @editConfirm="(v) => $emit('editConfirm', v)"
          @syncmeta="syncmeta"
        />
      </template>
    </SupplierBlockRowWrapper>
  </a-card>
</template>

<script>
import { BookingColors } from "@/config/fields/bookings";
import { BookingDayService } from "@/modules/bookings.days";
import { BookingRoomService } from "@/modules/bookings.rooms";
import SupplierBlockRowWrapper from "./SupplierBlockRowWrapper";
export default {
  props: {
    title: String,
    icon: String,
    type: String,
    daynum: Number,
    dbkey: String,
    date: String,
    cancelled: Boolean,
  },
  components: {
    SupplierBlockRowWrapper,
  },
  data() {
    return {
      roomBreakdownData: {},
    };
  },
  computed: {
    bookingId() {
      return this.$store.getters["BookingStore/booking_id"];
    },
    data() {
      return this.$store.getters["BookingDayStore/daySupplier"](this.daynum, this.dbkey)
        .map((data, index) => {
          return { ...data, contentIndex: index };
        }) // MUST be before filter (used in delete)
        .filter((v) => v.nest_type !== "ALT")
        .sort((a, b) => {
          if (a.time > b.time) return 1;
          if (a.time < b.time) return -1;
          return 0;
        });
    },
    suppliers() {
      return this.$store.getters["BookingDayStore/suppliers"];
    },
  },
  methods: {
    _syncRooming() {
      this.roomBreakdownData = BookingRoomService.getRoomingListBreakdown({
        bookingMetaData: this.$store.getters["BookingStore/data"],
        bookingRoomingList: this.$store.getters["BookingRoomStore/rooms"],
        bookingDays: this.$store.getters["BookingDayStore/days"],
        supplierRoomList: this.$store.getters["BookingSupplierRoomStore/supplier_rooms"],
      });
    },

    syncmeta(v) {
      const overwrite = true;
      const vm = this;
      this._syncRooming();

      const dayinfo = this.$store.getters["BookingDayStore/daySupplierFlat"]("ALL");
      const bookingData = this.$store.getters["BookingStore/data"];

      const paxNum = bookingData && bookingData.pax_num;

      // STEP ONE -- UPDATE Count
      this._presync(v, paxNum)
        .then((s) => {
          return this.$store.dispatch("BookingDayStore/updateOneSupplierStatus", {
            bookingSupplierId: v.data.id,
            bookingSuppliers: this.suppliers,
            bookingId: this.$store.getters["BookingStore/booking_id"],
            supplierId: v.data.supplier_id,
            applyAll: v.data.meta && v.data.meta.bound,

            data: {
              status: v.data.status, // Keep status same
              meta: v.data.meta,
            },

            getLockedPricesFn(item) {
              return BookingDayService.setLockedPrices({
                item,
                status: v.data.status,
                storeGetters: vm.$store.getters,
                useDates: null, // SKIP, don't need locked price
                overwrite,
              });
            },
            getConfirmedRoomDataFn(item, existingMeta) {
              return BookingDayService.setConfirmedRoomData({
                item,
                status: v.data.status,
                existingMeta,
                roomBreakdownData: vm.roomBreakdownData,
                dayinfo,
                overwrite,
              });
            },
            getConfirmedCountFn(item, existingMeta) {
              return BookingDayService.setConfirmedCount({
                env: vm.$store.getters["AccountStore/environment"],
                item,
                status: v.data.status,
                existingMeta,
                days: vm.$store.getters["BookingDayStore/days"],
                bookingSuppliers: vm.$store.getters["BookingDayStore/suppliers"],
                dayinfo,
                overwrite,
              });
            },
          });
        })
        .then(() => {
          this._syncRooming();
          this.$roomingPing.$emit("sync");
          this.$root.$loading.end();
          this.$root.$success("Synced supplier");
        })
        .catch((err) => {
          this.$root.$error(err);
        });
    },
    _presync(tosync, paxNum) {
      let metaref = tosync.data.meta || {};
      if (tosync.key === "confirmed_count_total") {
        // Update the product count to reflect PAX (supplier count not needed here)
        return this.$store.dispatch("BookingDayStore/updateBookingSupplier", {
          bookingSupplierId: tosync.data.id,
          bookingId: this.bookingId,
          data: {
            content: {
              meta: {
                ...metaref,
                count: Object.keys(tosync.data.products).reduce((map, key) => {
                  map[key] = paxNum;
                  return map;
                }, {}),
              },
            },
          },
        });
      }

      if (tosync.key === "confirmed_offcount") {
        return this.$store.dispatch("BookingDayStore/updateBookingSupplier", {
          bookingSupplierId: tosync.data.id,
          bookingId: this.$store.getters["BookingStore/booking_id"],
          data: {
            content: {
              meta: {
                ...metaref,
                confirmed_offcount: tosync.value,
              },
            },
          },
        });
      }

      return Promise.resolve();
    },
  },
  mounted() {
    this._syncRooming();
    this.$roomingPing.$on("sync", this._syncRooming);
  },
  beforeDestroy() {
    this.$roomingPing.$off("sync", this._syncRooming);
  },
};
</script>
