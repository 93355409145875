<template>
  <div></div>
</template>

<script>
import { ExportService } from "@/modules/exports";
import infoUtils from "@/modules/bookings/components/infoblock/utils";
import icon from "./_icon";

import moment from "moment";
export default {
  computed: {
    tourGuideList() {
      return infoUtils.mergeConfirmedSuppliersForOverview(this.$store.getters, "TOUR_GUIDE", "tour_guides");
    },
  },
  methods: {
    printAppreciation(guideName = "") {
      const booking = this.$store.getters["BookingStore/active"];
      const groupName = booking["group_name"];
      const dateFrom = moment(booking["from"]).locale("fr").format("D MMMM").toLowerCase();
      const dateTo = moment(booking["to"]).locale("fr").format("D MMMM YYYY").toLowerCase();

      // texts
      const padding = [""];
      const header = [...padding, "Fiche d'appréciation"];
      const date = [...padding, `${dateFrom} au ${dateTo}`];
      const group = [...padding, groupName];
      const guide = [...padding, guideName !== "" ? `Guide : ${guideName}` : ""];

      const greetingText = [
        [],
        ["Chers amis,"],
        ["Votre voyage touche à sa fin. Notre équipe a tout mis en œuvre depuis des mois pour que votre"],
        ["expérience soit passionnante. Nous espérons que vous garderez de merveilleux souvenirs."],
        ["Afin d’améliorer nos services et nos prestations futures, nous vous remercions de prendre quelques"],
        ["minutes pour nous faire part de vos commentaires et suggestions. Merci de votre coopération."],
        ["Nous vous souhaitons un excellent vol retour !"],
        [],
      ];

      const name = ["Votre nom : "];

      const gradingRow = ["", "Excellent", "Satisfait", "Moyen", "Médiocre"];
      const category = [
        ["Impression générale"],
        ["Guide"],
        ["Autocar"],
        ["Chauffeur"],
        ["Hôtels"],
        ["Repas"],
        ["Visites"],
        ["Organisation sur place"],
      ];

      const comment = ["Commentaires : "];

      // styles
      const textRight = {
        alignment: {
          vertical: "middle",
          horizontal: "right",
        },
      };
      const textCenter = {
        alignment: {
          vertical: "middle",
          horizontal: "center",
        },
      };
      const textTop = {
        alignment: {
          vertical: "top",
          horizontal: "center",
        },
      };

      const avenirFont = { font: { name: "Avenir", size: 12 } };
      const textGrey = { color: { argb: "FF7F7F7F" } };
      const borderGrey = {
        border: {
          top: { style: "thin", color: { argb: "FF7F7F7F" } },
          left: { style: "thin", color: { argb: "FF7F7F7F" } },
          bottom: { style: "thin", color: { argb: "FF7F7F7F" } },
          right: { style: "thin", color: { argb: "FF7F7F7F" } },
        },
      };

      // config
      const config = [
        { column: 1, options: { width: 40 } },
        { column: 2, options: { width: 15 } },
        { column: 3, options: { width: 15 } },
        { column: 4, options: { width: 15 } },
        { column: 5, options: { width: 15 } },

        { row: 2, options: { height: 30 } },
        {
          row: 2,
          cell: 5,
          options: {
            font: { ...avenirFont.font, size: 24, color: { argb: "FFC79D74" } },
            ...textRight,
          },
        },
        { row: 4, cell: 5, options: { ...avenirFont, ...textRight } },
        { row: 5, cell: 5, options: { ...avenirFont, ...textRight } },
        { row: 6, cell: 5, options: { ...avenirFont, ...textRight } },

        ...greetingText.map((_, i) => ({
          row: 8 + i,
          cell: 1,
          options: { font: { ...avenirFont.font, ...textGrey, italic: true } },
        })),

        // margin row
        { row: 7, options: { height: 45 } },
        { row: 14, options: { height: 30 } },

        { row: 15, options: { height: 5 } },
        { row: 16, options: { height: 24 } },
        { row: 16, cell: 1, options: { font: { ...avenirFont.font, size: 14 }, ...textRight } },
        {
          row: 16,
          cell: 2,
          options: {
            fill: { type: "pattern", pattern: "solid", fgColor: { argb: "FFF2F2F2" } },
            ...borderGrey,
          },
        },
        { row: 17, options: { height: 5 } },

        { row: 18, options: { height: 50 } },
        { row: 19, options: { height: 25 } },
        ...Array.from({ length: gradingRow.length - 1 }).map((_, i) => ({
          row: 18,
          cell: 2 + i,
          options: {
            fill: { type: "pattern", pattern: "solid", fgColor: { argb: "FFD9D9D9" } },
            ...textCenter,
          },
        })),
        ...Array.from({ length: gradingRow.length - 1 }).map((_, i) => ({
          row: 19,
          cell: 2 + i,
          options: {
            fill: { type: "pattern", pattern: "solid", fgColor: { argb: "FFD9D9D9" } },
            font: { ...avenirFont.font, size: 14, bold: true, color: { argb: "FFC79D74" } },
            ...textCenter,
          },
        })),

        ...Array.from({ length: category.length }).map((_, i) => ({
          row: 20 + i,
          cell: 1,
          options: {
            font: { ...avenirFont.font, size: 14 },
            fill: { type: "pattern", pattern: "solid", fgColor: { argb: "FFD9D9D9" } },
            ...textCenter,
          },
        })),
        ...Array.from({ length: category.length }).map((_, i) => ({
          row: 20 + i,
          options: { height: 30 },
        })),
        ...Array.from({ length: gradingRow.length - 1 }).map((_, i) => ({
          row: 20,
          cell: 2 + i,
          options: {
            fill: { type: "pattern", pattern: "solid", fgColor: { argb: "FFF2F2F2" } },
            ...borderGrey,
          },
        })),
        ...Array.from({ length: gradingRow.length - 1 }).map((_, i) => ({
          row: 21,
          cell: 2 + i,
          options: {
            fill: { type: "pattern", pattern: "solid", fgColor: { argb: "FFF2F2F2" } },
            ...borderGrey,
          },
        })),
        ...Array.from({ length: gradingRow.length - 1 }).map((_, i) => ({
          row: 22,
          cell: 2 + i,
          options: {
            fill: { type: "pattern", pattern: "solid", fgColor: { argb: "FFF2F2F2" } },
            ...borderGrey,
          },
        })),
        ...Array.from({ length: gradingRow.length - 1 }).map((_, i) => ({
          row: 23,
          cell: 2 + i,
          options: {
            fill: { type: "pattern", pattern: "solid", fgColor: { argb: "FFF2F2F2" } },
            ...borderGrey,
          },
        })),
        ...Array.from({ length: gradingRow.length - 1 }).map((_, i) => ({
          row: 24,
          cell: 2 + i,
          options: {
            fill: { type: "pattern", pattern: "solid", fgColor: { argb: "FFF2F2F2" } },
            ...borderGrey,
          },
        })),
        ...Array.from({ length: gradingRow.length - 1 }).map((_, i) => ({
          row: 25,
          cell: 2 + i,
          options: {
            fill: { type: "pattern", pattern: "solid", fgColor: { argb: "FFF2F2F2" } },
            ...borderGrey,
          },
        })),
        ...Array.from({ length: gradingRow.length - 1 }).map((_, i) => ({
          row: 26,
          cell: 2 + i,
          options: {
            fill: { type: "pattern", pattern: "solid", fgColor: { argb: "FFF2F2F2" } },
            ...borderGrey,
          },
        })),
        ...Array.from({ length: gradingRow.length - 1 }).map((_, i) => ({
          row: 27,
          cell: 2 + i,
          options: {
            fill: { type: "pattern", pattern: "solid", fgColor: { argb: "FFF2F2F2" } },
            ...borderGrey,
          },
        })),

        { row: 28, options: { height: 120 } },
        {
          row: 28,
          cell: 1,
          options: {
            fill: { type: "pattern", pattern: "solid", fgColor: { argb: "FFF2F2F2" } },
            font: { ...avenirFont.font, size: 14 },
            ...borderGrey,
            ...textTop,
          },
        },
      ];

      const image = [
        {
          position: {
            tl: { col: 1.35, row: 17 },
            ext: { width: 60, height: 60 },
            editAs: "oneCell",
          },
          value: icon.excellent,
        },
        {
          position: {
            tl: { col: 2.35, row: 17 },
            ext: { width: 60, height: 60 },
            editAs: "oneCell",
          },
          value: icon.satisfait,
        },
        {
          position: {
            tl: { col: 3.35, row: 17 },
            ext: { width: 60, height: 60 },
            editAs: "oneCell",
          },
          value: icon.moyen,
        },
        {
          position: {
            tl: { col: 4.35, row: 17 },
            ext: { width: 60, height: 60 },
            editAs: "oneCell",
          },
          value: icon.mediocre,
        },
      ];
      const mergeCell = ["B16:E16", "A28:E28", "B2:E2", "B4:E4", "B5:E5", "B6:E6"];

      const blankLine = [];
      const rows = [
        blankLine,
        header,
        blankLine,
        date,
        group,
        guide,
        ...greetingText,
        blankLine,
        name,
        blankLine,
        blankLine,
        gradingRow,
        ...category,
        comment,
      ];

      const fileName = `appreciation-${groupName}`;
      ExportService({
        fileName,
        rows,
        config,
        mergeCell,
        image,
        iconArea: "A1:A5",
        firstRowHeight: 12,
        landscape: false,
        printArea: "A1:E28",
      });
    },
  },
  beforeMount() {
    if (this.tourGuideList.length !== 0) {
      // this.tourGuideList.forEach((tourGuide) => this.printAppreciation(tourGuide.name));
      const tourGuideNameList = this.tourGuideList.map((tourGuide) => tourGuide.name);
      this.printAppreciation(tourGuideNameList.join(", "));
    } else this.printAppreciation();
  },
  mounted() {
    this.$router.go(-1);
  },
};
</script>
