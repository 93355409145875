<template>
  <div class="px-5">
    <v-row v-if="showAddress">
      <v-col cols="1" v-if="editMode" class="pa-0">
        <v-btn icon x-small @click="$emit('input', !value)">
          <v-icon>{{ value ? "visibility_off" : "visibility" }}</v-icon>
        </v-btn>
      </v-col>

      <v-col v-if="!value || editMode" class="d-flex flex-column pa-0" :class="{ 'text--disabled': editMode && value }">
        <v-row>
          <v-col :cols="editMode ? 4 : 3" class="caption font-weight-bold mx-2 mx-2 pa-0">Address: </v-col>
          <v-col class="caption pa-0">{{ address }}</v-col>
        </v-row>
        <v-row>
          <v-col :cols="editMode ? 4 : 3" class="caption font-weight-bold mx-2 pa-0">Phone: </v-col>
          <PhoneDisplay :value="phone" :country="country" class="caption" />
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    supplierId: String,
    contactMap: Object,

    editMode: Boolean,
    value: Boolean, // HIde address
  },
  computed: {
    showAddress() {
      const supplier = this.contactMap[this.supplierId];
      if (supplier && supplier.supplier_type === "PARK") return false;
      return supplier;
    },
    phone() {
      if (this.showAddress) {
        let phone = this.showAddress.phone_number;
        if (isNaN(phone)) return phone;
        // Old Number, format here
        return { phone: phone };
      }
      return null;
    },
    address() {
      if (!this.showAddress) return null;
      let s = this.showAddress;
      let chain = [];
      if (s.address) chain.push(s.address);
      if (s.subcity) chain.push(s.subcity);
      if (s.city) chain.push(s.city);
      if (s.subjur || s.zip_code) {
        if (s.country === "JP") {
          chain.push(s.zip_code || "");
        } else {
          chain.push(`${s.subjur || ""} ${s.zip_code || ""}`);
        }
      }
      return chain.join(", ");
    },
    country() {
      if (this.showAddress) return this.showAddress.country;
      return "US";
    },
  },
};
</script>
