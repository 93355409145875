<template>
  <a-card nopad hover class="d-flex py-1 px-3 align-start justify-space-between">
    <div class="d-flex align-center align-self-center">
      <span class="overline" style="width: 200px">{{ product.pid }}</span>
      <span class="overline grey--text lighten-1" style="width: 100px">{{ groupOrFit }}</span>
      <span class="subtitle-1">{{ product.name }}</span>

      <v-menu open-on-hover offset-x bottom v-if="product.notes">
        <template v-slot:activator="{ on }">
          <v-btn small icon v-on="on">
            <v-icon small color="grey lighten-1">note</v-icon>
          </v-btn>
        </template>

        <div class="hover-menu">
          <div v-html="notes"></div>
        </div>
      </v-menu>
    </div>

    <div class="d-flex align-top">
      <v-col v-if="!showingFocusRate" class="caption" style="opacity: 0.5">({{ actualRate.rate_year }})</v-col>
      <v-col v-if="rateKind" class="overline py-0 pr-5" align-self="center" style="width: 100px">{{ rateKind }}</v-col>
      <TotalBlock
        :isSeasonal="!!focusRate.seasonal"
        :rateInfo="rate"
        :country="country"
        :tax="tax"
        :taxInclusive="tax_inclusive"
        :occupancy="occupancy"
        :resortFee="resort_fee"
        :extraList="extraList"
        showconfim
        :confirmed="confirmed"
        :focusYear="focusYear"
        :style="{ opacity: showingFocusRate ? 1 : 0.5 }"
      />
    </div>
  </a-card>
</template>

<script>
import TotalBlock from "./TotalBlock";
import { ProductMenu } from "@/config/menus";
export default {
  props: {
    product: Object,
    focusYear: String,
  },
  components: {
    TotalBlock,
  },
  data() {
    return {};
  },
  computed: {
    country() {
      return this.$store.getters["AccountStore/environment"];
    },
    tax() {
      if (this.focusRate.tax_inclusive) return 0;

      const supplierTax = this.$store.getters["SupplierStore/meta"].tax;
      const productTax = this.focusRate.meta ? this.focusRate.meta.custom_tax : null;

      return productTax || supplierTax;
    },
    tax_inclusive() {
      return this.focusRate.tax_inclusive;
    },

    focusRate() {
      if (!this.product.rate_history.length) return { rate: {}, meta: {} };
      const history = this.product.rate_history.find((item) => item.rate_year === this.focusYear);
      return history || this.product.rate_history[0] || { rate: {}, meta: {} };
    },
    showingFocusRate() {
      return !!this.product.rate_history.find((item) => item.rate_year === this.focusYear);
    },
    actualRate() {
      return this.product.rate_history[0] || { rate: {}, meta: {} };
    },

    groupOrFit() {
      const group_fit = this.product.group_fit;
      if (group_fit === "FIT") return group_fit;
      if (group_fit === "GROUP") return `Group (${this.focusRate.meta.group_size || 1})`;
      return "Group/FIT";
    },
    notes() {
      if (!this.product.notes) return "";
      return this.product.notes.replace(/\n/gm, "<br/>");
    },

    rate() {
      return this.focusRate.rate ? this.focusRate.rate : this.focusRate.seasonal ? [] : {};
    },
    rateKind() {
      const meta = this.focusRate.meta;
      if (meta.rate_kind === "GENERIC") return "Generic";
      if (meta.rate_kind === "CONTRACT") return "Contract";
      return "";
    },

    confirmed() {
      return this.focusRate && this.focusRate.confirmed;
    },

    occupancy() {
      const product = this.product;
      const focusRate = this.focusRate;
      if (product.product_type !== "ROOM") return null;

      var t = this.$store.getters["SupplierStore/meta"].occupancy_rate;

      // Japanese Environment has custom occupancy option
      // Switch here
      if (this.$store.getters["AccountStore/environment"] === "JPN") {
        if (focusRate.meta && focusRate.meta.custom_occupancy) {
          t = focusRate.meta.custom_occupancy;
        }
      }

      return t && t.value && !focusRate.tax_inclusive ? t : null;
    },
    resort_fee() {
      const product = this.product;
      if (product.product_type !== "ROOM") return null;

      return this.$store.getters["SupplierStore/meta"].resort_fee;
    },

    extraList() {
      const meta = this.focusRate.meta;
      if (!meta) return [];

      return Object.keys(meta)
        .filter((v) => v.includes("extra_"))
        .map((key) => {
          return {
            key,
            ...meta[key],
          };
        });
    },
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="scss">
.handle {
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
</style>
