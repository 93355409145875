<template>
  <v-container fill-height>
    <v-row align="center" justify="center">
      <v-col cols="6" align="center" justify="center">
        <div style="text-align: center">
          <img src="@/assets/img/Logo.png" class="logo" />
        </div>

        <a-card>
          <ForgotPassword v-if="view === 'FORGOT'" class="pa-5" @login="view = 'LOGIN'" />
          <Login v-else @forgot="view = 'FORGOT'" class="pa-5" :loggedin="loggedin" />
        </a-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Login from "./_Login";
import ForgotPassword from "./_ForgotPassword";

export default {
  props: {
    loggedin: Function,
  },
  components: {
    Login,
    ForgotPassword,
  },
  data() {
    return {
      view: "LOGIN",
    };
  },
};
</script>
