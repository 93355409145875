<template>
  <v-row>
    <v-col cols="1">
      <v-btn x-small icon class="mx-1 cyoa-btn" color="grey lighten-1" @click="$emit('add')">
        <v-icon>add</v-icon>
      </v-btn>
    </v-col>
    <DayOptionRow
      :mode="mode"
      module="RESTAURANT"
      :disabled="disabled"
      :selectedBsid="selectedBsid"
      :dateList="dateList"
      :highlightedRows="highlightedRows"
      :focusDate="focusDate"
      :supplierId="supplierId"
      :nestedType="nestedType"
      @set="(v) => $emit('set', v)"
      @mark="(v) => $emit('mark', v)"
      @edit="(v) => $emit('edit', v)"
    />
  </v-row>
</template>

<script>
import format from "@/services/format";
import DayOptionRow from "../../options/DayOptionRow";
export default {
  props: {
    mode: String,
    disabled: Boolean,

    // Copy stuff
    selectedBsid: String,
    dateList: Array,
    highlightedRows: Object,

    // NESTED
    nestedType: String,

    // Regular stuff
    focusDate: String,
    supplierId: String,
  },
  components: {
    DayOptionRow,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {},
  methods: {
    passUpAdded(bsid) {
      // The booking supplier id of what was just added
      this.$emit("addnest", bsid);
    },

    removeMeal(bsid, supplier_id) {
      this.$root.$loading.open("Removing breakfast");
      this.$store
        .dispatch("BookingDayStore/removeAllOfBookingSupplier", {
          data: {
            bookingSupplierId: bsid,
            bookingId: this.$store.getters["BookingStore/booking_id"],
            supplierId: supplier_id,
            dbkey: "restaurants",
          },
        })
        .then((v) => {
          this.$emit("popnest", bsid);
          this.$root.$success("Removed Meal");
          this.$root.$loading.end();
        });
    },
  },
};
</script>
