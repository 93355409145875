<template>
  <LabelWrapper :label="label" :widelabel="widelabel" :smlabel="smlabel">
    <FText
      :disabled="disabled"
      :value="stringValue"
      @keydown="(v) => $emit('keydown', v)"
      @input="(v) => $emit('input', v)"
    />
  </LabelWrapper>
</template>

<script>
import LabelWrapper from "./wrappers/LabelWrapper";
import FText from "./items/FText";
export default {
  props: {
    disabled: Boolean,
    widelabel: Boolean,
    smlabel: Boolean,
    label: String,
    value: [String, Number],
  },
  components: {
    LabelWrapper,
    FText,
  },
  computed: {
    stringValue() {
      if (!this.value) return "";
      return `${this.value}`;
    },
  },
};
</script>
