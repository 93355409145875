<template>
  <v-col>
    <v-row justify="space-between">
      <div>
        <g-button :disabled="emailView === 0" label="1 - Select" class="mx-1" @onclick="emailView = 0" />
        <g-button :disabled="emailView === 1" label="2 - Compose" @onclick="emailView = 1" />
        <g-button :disabled="emailView === 2" label="3 - Preview" @onclick="emailView = 2" />
      </div>

      <div nopad>
        <EmailSendButton
          :disabled="disabled"
          :subject="compiledSubject"
          :attachments="emailContent.attachments"
          :compileMailFn="customCompileHandler"
          :postSend="postSend"
          :onFinishFn="(v) => $emit('onfinish')"
        />
      </div>
    </v-row>

    <v-col v-show="emailView === 0" class="black--text ma-5">
      <elwrapper label="Booking" midtext>
        {{ bookingName }}
      </elwrapper>
      <elwrapper label="Supplier" midtext>
        {{ supplierName }}
      </elwrapper>
    </v-col>

    <v-col v-show="emailView === 1">
      <EmailWrapper
        v-model="emailContent"
        :supplierId="supplierId"
        :groupName="groupName"
        mailType="SINGLE"
        :limitTemplate="limitTemplate"
        :config="mailConfig"
        :selectedDate="selectedDate"
        @input="(v) => updateSubject(v)"
        @setcontacts="(v) => (mailGroup = v)"
      />
    </v-col>

    <v-col v-if="emailView === 2">
      <PreviewEmailWrapper
        :subject="compiledSubject"
        :attachments="emailContent.attachments"
        :compileMailFn="customCompileHandler"
      />
    </v-col>
  </v-col>
</template>

<script>
import format from "@/services/format";
import { EmailTemplateService, PreviewEmailWrapper } from "@/modules/admin.email_template";
import { EmailWrapper } from "@/modules/bookings.email";
import { EmailSendButton, EmailService } from "@/modules/email";
export default {
  props: {
    // Booking Supplier
    bookingSupplierId: {
      required: true,
      type: String,
    },
    focusBookingSupplier: Object,

    // Supplier
    supplierId: {
      required: true,
      type: String,
    },
    supplierName: String,

    // Booking stuff
    booking: Object,
    bookingSuppliers: Object,
    dayinfo: Array,
    flattenDays: Array,
    roomBreakdownData: Object,

    mailConfig: Object,
    limitTemplate: [Number],
    postSend: Function,
  },
  components: {
    EmailWrapper,
    PreviewEmailWrapper,
    EmailSendButton,
  },
  data() {
    return {
      emailView: 1,
      nuStatus: 0,
      confirmDialog: false,

      mailGroup: [],
      emailContent: {
        attachments: [],
      },

      compiledSubject: "",
    };
  },
  watch: {
    bookingSupplierId(v) {
      this.emailView = 1;
      this.mailGroup = [];
      this.emailContent = {
        attachments: [],
      };
    },
  },
  computed: {
    bookingName() {
      return this.$store.getters["BookingStore/booking_name"](this.booking);
    },
    groupName() {
      return this.booking && this.booking.group_name;
    },
    disabled() {
      return !this.mailGroup.length || !(this.emailContent && this.emailContent.subject);
    },
    selectedDate() {
      // Assuming hotels are single cases
      // (So don't need to account for seperate booking suppliers for same supplier)
      const temp = this.dayinfo.find((day) => {
        return day.suppliers.find((bsid) => bsid === this.bookingSupplierId);
      });
      return temp ? temp.date : "ALL";
    },
  },
  methods: {
    updateSubject(v) {
      this.compiledSubject = EmailTemplateService.subjectBasicFill(v.subject || "", {
        group_name: this.groupName,
      });
    },
    customCompileHandler() {
      let message;
      const language = this.emailContent.language;
      this.mailGroup.forEach((item) => {
        message = EmailTemplateService.replaceContact(
          item.contacts.nameList,
          this.emailContent.message || "",
          language
        );
        message = EmailTemplateService.replaceSupplier(message, item);

        // Attach adjust_count to products for extra suppliers
        const shallowMock = { ...this.focusBookingSupplier };
        shallowMock.mock_meta = Object.values(this.focusBookingSupplier.products).reduce((obj, product) => {
          return {
            ...obj,
            [product.id]: EmailService._getProductMeta(
              product.id,
              shallowMock.meta,
              this.dayinfo.filter((day) => day.suppliers.includes(shallowMock.id)).map((day) => day.date),
              this.bookingSuppliers,
              this.flattenDays
            ),
          };
        }, {});

        console.log(shallowMock);

        item.message = EmailTemplateService.fillProductsByBookingSupplier(
          message,
          this.bookingSupplierId,
          shallowMock,
          this.dayinfo,
          this.roomBreakdownData,
          this.$store.getters["BookingStore/oldDates"]
        );
      });
      return Promise.resolve(this.mailGroup);
    },
  },
};
</script>
