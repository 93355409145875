<template>
  <v-textarea
    :v-bullets="bullets"
    outlined
    hide-details
    dense
    :rows="rows"
    :disabled="disabled"
    :value="value"
    @input="insertBullet"
    @focus="insertBulletFocus"
  />
</template>

<script>
export default {
  props: {
    value: String,
    rows: [Number, String],
    disabled: Boolean,
    bullets: Boolean,
  },
  methods: {
    insertBulletFocus(e) {
      if (!this.bullets) return;
      if (!this.value || this.value === "") this.$emit("input", "- ");
    },
    insertBullet(nu) {
      this.$emit("input", nu);
      // Should be a space after bullets. If not, assuming delete
      // if(nu.endsWith('-')) return this.$emit('input', nu.replace(/\n-$/gm, ''))
      // this.$emit('input', nu.endsWith('\n')? nu+'- ' : nu);
    },
  },
};
</script>
