<template>
  <div>
    <v-row justify="space-between">
      <h3 class="title white--text">History</h3>
      <g-button
        :label="showSearch ? 'hide' : 'show search'"
        :icon="showSearch ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
        @onclick="showSearch = !showSearch"
      />
    </v-row>

    <HistorySearch v-show="showSearch" v-model="searchQuery" />

    <HistoryList :query="searchQuery" />
  </div>
</template>

<script>
import { HistorySearch, HistoryList } from "@/modules/admin.history";
export default {
  components: {
    HistorySearch,
    HistoryList,
  },
  data() {
    return {
      showSearch: true,
      searchQuery: null,
    };
  },
};
</script>
