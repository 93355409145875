<template>
  <v-form>
    <div>
      <a-select
        label="To"
        freeopt
        multiple
        v-if="emailOptionList"
        v-model="formEmail"
        :items="emailOptionList"
        @input="update"
      />
      <a-text-field v-else label="To" v-model="formSingleEmail" @input="update" />
    </div>

    <div>
      <a-select
        label="CC"
        freeopt
        multiple
        v-if="emailOptionList"
        v-model="formCC"
        @input="update"
        :items="emailOptionList"
      />
      <a-text-field v-else label="CC" v-model="formSingleCCEmail" @input="update" />
    </div>
  </v-form>
</template>

<script>
import Vue from "vue";
import { EmailService } from "@/modules/email";
export default {
  props: {
    value: [Object, Array],

    supplierId: String,
    clientId: String,
    isclient: Boolean,

    // emailOptionList: Array,
    // hiddenContent: String
  },
  data() {
    return {
      emailOptionList: [],
      hiddenContent: "",

      formEmail: [],
      formCC: [],
      formSingleEmail: "",
      formSingleCCEmail: "",
    };
  },
  watch: {
    supplierId(v) {
      if (this.isclient) return;
      this.getSelectedSupplierContacts(v);

      this.formEmail = [];
      this.formCC = [];
      this.formSingleEmail = "";
      this.formSingleCCEmail = "";
    },
    clientId(v) {
      if (!this.isclient) return;
      this.getSelectedClientContacts(v);

      this.formEmail = [];
      this.formCC = [];
      this.formSingleEmail = "";
      this.formSingleCCEmail = "";
    },
  },
  methods: {
    update(key, v) {
      this.$emit("input", this.compileEmailContacts());
    },

    compileEmailContacts() {
      let selectedEmails, ccEmails;

      if (this.emailOptionList) {
        // Extract selected emails into sending format
        // Strip any invalid emails (custom emails are allowed)
        selectedEmails = this.formEmail.filter((email) => {
          return _isValidEmail(email) || this.emailOptionList.find((i) => i.value === email);
        });
        ccEmails = this.formCC.filter((email) => {
          return _isValidEmail(email) || this.emailOptionList.find((i) => i.value === email);
        });

        // Create email objects
        selectedEmails = selectedEmails
          .map((email, index) => {
            const item = this.emailOptionList.find((i) => i.value === email) || {};
            return {
              name: item.name || email,
              email: item.email || email,
              supplier_name: item.supplier_name,
              supplier_city: item.supplier_city,
              dummyid: 1, // placeholder
              index: index,
            };
          })
          .reduce(function (map, obj) {
            map[obj.index] = obj;
            return map;
          }, {});

        ccEmails = ccEmails
          .map((email) => {
            const item = this.emailOptionList.find((i) => i.value === email) || {};
            return item.email || email;
          })
          .reduce(function (map, obj) {
            map[obj] = obj;
            return map;
          }, {});

        // Flatten and clean
        selectedEmails = EmailService.groupSupplierContacts(selectedEmails, "dummyid", Object.keys(ccEmails));
      } else {
        // Single Email (no mail options)
        selectedEmails = {
          contacts: {
            emailList: [this.formSingleEmail],
            ccEmailList: [this.formSingleCCEmail],
            nameList: [],
          },
        };
      }

      return selectedEmails;
    },

    getSelectedSupplierContacts(supplierId) {
      // Get selected supplier contacts
      this.$store
        .dispatch("SupplierStore/getSupplier", supplierId)
        .then((supplier) => {
          const allContacts = supplier.contacts.all_contacts;
          const primary = supplier.contacts.primary_contact;
          this._setContact(allContacts, primary.id, supplier.supplier);
        })
        .catch(this.$root.$error);
    },
    getSelectedClientContacts(clientId) {
      // Get selected client contacts
      this.$store
        .dispatch("ClientContactStore/getContactList", clientId)
        .then((client) => {
          this._setContact(client.allContacts, client.primary_contact_id);
        })
        .catch(this.$root.$error);
    },
    _setContact(allContacts, primaryId, supplier) {
      const emailInformation = EmailService.compileContactDropdownData(allContacts, primaryId);

      this.emailOptionList = emailInformation.emailList.map((email) => ({
        ...email,
        supplier_name: supplier ? supplier.name : email.label,
        supplier_city: supplier ? supplier.city : email.supplier_city,
      }));
      this.hiddenContent = emailInformation.hidden ? `Hiding ${emailInformation.hidden} contacts (no email)` : "";
    },
  },

  mounted() {
    if (this.isclient) {
      if (!this.clientId) return;
      return this.getSelectedClientContacts(this.clientId);
    }
    if (!this.supplierId) return;
    this.getSelectedSupplierContacts(this.supplierId);
  },
};

function _isValidEmail(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}
</script>
