<template>
  <div>
    <v-row class="mx-0 mb-3" justify="space-between">
      <span class="title white--text">Account List</span>
      <add-button @click="$router.push({ name: 'admin_add_account' })" />
    </v-row>

    <a-card
      nopad
      v-for="account in accountList"
      :key="account.id"
      class="d-flex account justify-space-between pa-2 my-2"
      @click.native="goto(account.id)"
    >
      <div>
        <span class="subtitle-2">{{ account.name }}</span>
        <span class="px-2">|</span>
        <span class="blue--text caption">{{ account.email }}</span>
      </div>

      <div :class="{ 'grey lighten-1 px-2': account.role === 'ADMIN' }" class="d-flex align-center overline">
        {{ account.role }}
      </div>
    </a-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      accountList: [],
    };
  },
  methods: {
    goto(account_id) {
      var myAccountId = this.$store.getters["AccountStore/id"];

      if (account_id === myAccountId) return this.$router.push({ name: "account" });

      return this.$router.push({ name: "admin_account_edit", params: { account_id } });
    },
  },
  mounted() {
    this.$store
      .dispatch("AdminStore/getAccountList")
      .then((list) => {
        this.accountList = list.filter((item) => {
          return item.email !== "devtest@supernature.travel";
        });
      })
      .catch((err) => this.$root.$error(err));
  },
};
</script>

<style lang="scss">
.account {
  &:hover {
    background: lightgray;
    cursor: pointer;
  }
}
</style>
