<template>
  <v-row justify="space-between">
    <v-col class="subtitle-2" cols="3" align-self="center"> Date Paid </v-col>
    <v-col class="d-flex justify-end" cols="5">
      <f-date :value="value" @input="(v) => $emit('input', v)" :datewallEnd="endDate" />
    </v-col>
  </v-row>
</template>

<script>
import format from "@/services/format";
export default {
  props: {
    value: String,
  },
  data() {
    return {
      endDate: format.addDay(format.convertDate(new Date())),
    };
  },
};
</script>
