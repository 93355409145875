import money from "@/services/money";

export default class TotalBlob {
  constructor(activeEnv) {
    // This is the environment the user is current in
    // DEFAULT is USA
    this.activeEnv = activeEnv || "USA";
    // All calculations go here
    this.COMBINED = 0;
    this.USA = 0;
    this.CDA = 0;
  }
  // METHODS
  export() {
    // Convert to formated
    return {
      COMBINED: money._num(this.COMBINED),
      USA: money._num(this.USA),
      CDA: money._num(this.CDA),
    };
  }

  merge(otherBlob) {
    this.COMBINED += otherBlob.COMBINED;
    this.USA += otherBlob.USA;
    this.CDA += otherBlob.CDA;
  }

  addToCombined(value) {
    this.COMBINED += value || 0;
  }

  addToCurrentEnv(value) {
    this[this.activeEnv] += value || 0;
  }

  addToMatchingEnvWithConversion(supplierEnv, value, storeGetter) {
    // This includes price conversion
    if (supplierEnv !== this.activeEnv) {
      // Environment of supplier doesn't match current
      // Shared supplier
      if (supplierEnv === "USA") {
        // Convert US supplier to non-US environment
        this[supplierEnv] += money.getConvertedValue(value, this.activeEnv, storeGetter);
      } else {
        // Convert
        this[supplierEnv] += money.getConvertToForeign(value, supplierEnv, storeGetter);
      }
    } else {
      this[supplierEnv] += value || 0;
    }
  }

  addToAnyEnv(env, value) {
    this[env] += value || 0;
  }
}
