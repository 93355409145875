<template>
  <v-data-table
    disable-pagination
    hide-default-footer
    fixed-header
    :headers="headers"
    :items="list"
    :loading="loading"
    :height="height"
    loading-text="Fetching..."
    :custom-sort="customSort"
    :show-select="selectOnly"
    single-select
  >
    <template v-slot:body="{ items }">
      <div v-if="items.length === 0">
        <span class="caption px-3">{{ emptyMessage }}</span>
      </div>

      <tbody v-else>
        <tr>
          <td :colspan="colspan" class="ma-0 pa-0">
            <RecycleScroller
              :style="virtualScrollHeight"
              :items="items"
              :item-size="40"
              key-field="id"
              v-slot="{ item }"
            >
              <slot :item="item"></slot>
            </RecycleScroller>
          </td>
        </tr>
      </tbody>
    </template>

    <template v-if="!hidefooter" v-slot:footer>
      <v-row class="mx-0 pa-1 grey lighten-2" justify="end" style="width: 100%">
        <g-button v-if="allowRefresh" xsmall icon="refresh" label="Refresh" @onclick="$emit('refresh')" />
        <span class="caption font-weight-bold px-5">Total: {{ list.length }}/{{ count }}</span>
      </v-row>
    </template>
  </v-data-table>
</template>

<script>
/*
 *  NOTE: This breaks column widths for children
 * 	Add (custom) v-col-adjwidth directive to set column width based on the regular column stuff
 */
export default {
  props: {
    headers: Array,
    list: Array,

    height: String,
    loading: Boolean,
    colspan: Number,
    count: Number,
    emptyMessage: String,

    customSort: Function,
    selectOnly: Boolean,
    hidefooter: Boolean,

    allowRefresh: Boolean,
  },
  computed: {
    virtualScrollHeight() {
      return `height: ${Number((this.height || "").replace("px", "")) - 60}px`;
    },
  },
};
</script>
