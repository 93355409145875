<template>
  <v-dialog :value="value" @input="$emit('input', false)">
    <v-card class="pa-3">
      <v-row>
        <v-col class="pa-5">
          <v-card-title class="text-h5"> Signature </v-card-title>

          <div v-html="signature"></div>
        </v-col>

        <v-col>
          <v-tabs v-model="tab">
            <v-tab>Text</v-tab>
            <v-tab>Image</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <RichTextEditor maxheight="300px" v-model="signature" />
            </v-tab-item>
            <v-tab-item>
              <SignatureView :editEmailId="editEmailId" :imageList="imageList" @update="updateImage" />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>

      <v-card-actions class="justify-space-between">
        <g-button label="Cancel" @onclick="$emit('input', false)" />
        <g-button label="Apply" type="primary" @onclick="saveSignature" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SignatureView from "./_SignatureView";
export default {
  props: {
    value: Boolean,
    editEmailId: String,
    editSignature: String,
  },
  components: {
    SignatureView,
  },
  data() {
    return {
      tab: null,

      imageList: [],
      updatedSignature: "",
    };
  },
  watch: {
    value(v) {
      if (!v) return;

      if (this.isImage) {
        this.tab = 1;
      }

      this.$store
        .dispatch("SignatureImageStore/getImageList", this.editEmailId)
        .then((list) => (this.imageList = list))
        .catch((err) => this.$root.$error(err));
    },
  },
  computed: {
    signature: {
      get() {
        return this.updatedSignature || this.editSignature;
      },
      set(v) {
        this.updatedSignature = v;
      },
    },
    isImage() {
      return (this.signature || "").includes("<img");
    },
  },
  methods: {
    updateImage(list) {
      this.imageList = list;
      // Set the signature to the selected image
      const use = list.find((x) => x.isdefault);
      if (use) {
        let url = "https://service.supernature.travel/api/" + use.src;
        const img = `<img src="${url}" width="400"/>`;
        this.updatedSignature = `<a href="https://supernature.travel" target="_blank">${img}</a>`;
      } else {
        this.signature = "";
      }
    },
    saveSignature() {
      return this.$store
        .dispatch("AccountStore/updateCustomEmail", {
          emailaccount_id: this.editEmailId,
          data: {
            signature: this.signature,
          },
        })
        .then(() => {
          this.$root.$success("Updated Email Signature");
          this.$emit("input", false);
        })
        .catch((err) => this.$root.$error(err));
    },
  },
};
</script>
