import OptionListView from "./OptionListView";

export default [
  {
    path: "/admin_lists",
    name: "admin_lists",
    component: OptionListView,
    meta: {
      tabAccess: "ADMIN",
    },
  },
  {
    path: "/admin_edit_list/:list",
    name: "admin_edit_list",
    component: () => import("./EditOptionListView"),
    meta: {
      tabAccess: "ADMIN",
    },
  },
];
