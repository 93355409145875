import Vue from "vue";
import Router from "vue-router";

import home from "./views/home/_routes";
import account from "./views/account/_routes";
import admin from "./views/admin/_routes";
import bookings from "./views/bookings/_routes";
import booking_email from "./views/bookings.email/_routes";
import booking_supplier_email from "./views/bookings.supplier_email/_routes";
import email from "./views/email/_routes";
import finances from "./views/finances/_routes";
import database from "./views/database/_routes";
import databaseEmail from "./views/database.email/_routes";
import clients from "./views/database.clients/_routes";
import suppliers from "./views/database.suppliers/_routes";
import products from "./views/database.products/_routes";
import quotes from "./views/quotes/_routes";
import reminders from "./views/reminders/_routes";

Vue.use(Router);

const router = new Router({
  // mode: 'history',
  routes: [
    ...home,
    ...account,
    ...admin,
    ...bookings,
    ...booking_email,
    ...booking_supplier_email,
    ...email,
    ...finances,
    ...database,
    ...databaseEmail,
    ...clients,
    ...suppliers,
    ...products,
    ...quotes,
    ...reminders,
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
