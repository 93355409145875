<template>
  <a-card nopad>
    <div class="booking-title d-flex justify-space-between">
      <span>Flight Details</span>
      <div>
        <g-button
          :disabled="isCancelled"
          style="height: 20px"
          icon="edit"
          label="Domestic"
          @onclick="openDialog('DOMESTIC')"
        />

        <g-button
          :disabled="isCancelled"
          style="height: 20px"
          icon="edit"
          label="International"
          @onclick="openDialog('INTERNATIONAL')"
        />
      </div>
    </div>
    <v-row class="px-1 caption">
      <v-col cols="1" class="py-0" align-self="center"> Arrival </v-col>
      <v-col class="py-0">
        <v-row>
          <a-field :config="config.date" :datewall="datewall" :datewallEnd="datewallEnd" v-model="arrival_date" />
          <a-field :config="config.airport" class="flightinfo" v-model="arrival_airport" />
          <a-field :config="config.flight_num" class="flightinfo" v-model="arrival_flight_num" />
          <a-field :config="config.time" v-model="arrival_time" />
        </v-row>
      </v-col>
    </v-row>

    <v-row class="px-1">
      <v-col cols="1" class="py-0 caption" align-self="center"> Departure </v-col>
      <v-col class="py-0">
        <v-row>
          <a-field :config="config.date" :datewall="departWall" :datewallEnd="datewallEnd" v-model="depart_date" />
          <a-field :config="config.airport" class="flightinfo" v-model="depart_airport" />
          <a-field :config="config.flight_num" class="flightinfo" v-model="depart_flight_num" />
          <a-field :config="config.time" v-model="depart_time" />
        </v-row>
      </v-col>
    </v-row>

    <v-row class="px-3 subtitle-2"> International </v-row>
    <FlightRow v-for="(f, index) in internationalFlightList" :key="index + 'international'" :flight="f" />

    <v-row class="px-3 subtitle-2"> Domestic </v-row>
    <FlightRow v-for="(f, index) in domesticFlightList" :key="index + 'domestic'" :flight="f" />

    <FlightDialog
      v-model="domesticDialog"
      flightType="DOMESTIC"
      :datewall="datewall"
      :datewallEnd="datewallEnd"
      :flightList="tempFlightList"
      @update="(v) => (flightList = v)"
    />

    <FlightDialog
      v-model="internationalDialog"
      flightType="INTERNATIONAL"
      :datewall="datewall"
      :datewallEnd="datewallEnd"
      :flightList="tempFlightList"
      @update="(v) => (flightList = v)"
    />
  </a-card>
</template>

<script>
import { BookingFields } from "@/config/fields/bookings";
import FlightDialog from "./_FlightDialog";
import FlightRow from "./FlightRow";
import format from "@/services/format";
export default {
  props: {
    bookingId: String,
  },
  components: {
    FlightDialog,
    FlightRow,
  },
  data() {
    return {
      config: BookingFields.flight,

      domesticDialog: false,
      internationalDialog: false,
      tempFlightList: [],
    };
  },
  computed: {
    datewall() {
      return this.$store.getters["BookingStore/active"].from;
    },
    datewallEnd() {
      return this.$store.getters["BookingStore/active"].to;
    },
    departWall() {
      return this.arrival_date || this.datewall;
    },

    arrival_date: {
      get() {
        return this.getArrive("date");
      },
      set(newValue) {
        return this.setArrive("date", newValue);
      },
    },
    arrival_airport: {
      get() {
        return this.getArrive("airport");
      },
      set(newValue) {
        return this.setArrive("airport", newValue);
      },
    },
    arrival_flight_num: {
      get() {
        return this.getArrive("flight_num");
      },
      set(newValue) {
        return this.setArrive("flight_num", newValue);
      },
    },
    arrival_time: {
      get() {
        return this.getArrive("time");
      },
      set(newValue) {
        return this.setArrive("time", newValue);
      },
    },

    depart_date: {
      get() {
        return this.getDeparture("date");
      },
      set(newValue) {
        return this.setDeparture("date", newValue);
      },
    },
    depart_airport: {
      get() {
        return this.getDeparture("airport");
      },
      set(newValue) {
        return this.setDeparture("airport", newValue);
      },
    },
    depart_flight_num: {
      get() {
        return this.getDeparture("flight_num");
      },
      set(newValue) {
        return this.setDeparture("flight_num", newValue);
      },
    },
    depart_time: {
      get() {
        return this.getDeparture("time");
      },
      set(newValue) {
        return this.setDeparture("time", newValue);
      },
    },

    flightList: {
      get() {
        return this.$store.getters["BookingFlightStore/flight_list"];
      },
      set(newValue) {
        return this.set("flight_list", newValue);
      },
    },
    domesticFlightList() {
      return this.flightList.filter((item) => {
        return !item.flight_type || item.flight_type === "DOMESTIC";
      });
    },
    internationalFlightList() {
      return this.flightList.filter((item) => {
        return item.flight_type === "INTERNATIONAL";
      });
    },

    isCancelled() {
      return this.$store.getters["BookingStore/isCancelled"];
    },
  },
  methods: {
    // ARRIVAL
    getArrive(val) {
      return this.$store.getters["BookingFlightStore/arrival"][val];
    },
    setArrive(key, val) {
      return this.$store.dispatch("BookingFlightStore/update", { deep: "arrival", key, val });
    },

    // DEPARTURE
    getDeparture(val) {
      return this.$store.getters["BookingFlightStore/departure"][val];
    },
    setDeparture(key, val) {
      return this.$store.dispatch("BookingFlightStore/update", { deep: "departure", key, val });
    },

    set(key, val) {
      return this.$store.dispatch("BookingFlightStore/update", { key, val });
    },

    openDialog(flightType) {
      // Deep copy array
      this.tempFlightList = JSON.parse(JSON.stringify(this.flightList));
      if (flightType === "DOMESTIC") this.domesticDialog = true;
      if (flightType === "INTERNATIONAL") this.internationalDialog = true;
    },
  },
  beforeDestroy() {
    if (this.isCancelled) return;
    // SAVE FLIGHTS HERE -- cache check already enabled
    this.$store.dispatch("BookingFlightStore/saveFlights", this.bookingId).catch((err) => this.$root.$error(err));
  },
};
</script>

<style lang="scss">
.flightinfo {
  max-width: 200px;
}
</style>
