<template>
  <v-card flat>
    <v-form @submit.prevent="updateSearch">
      <v-row class="mx-0 flex-nowrap">
        <v-col cols="3">
          <a-select
            label="Group Name"
            :items="groupList"
            v-model="group_name"
            @input="(v) => $emit('updateSupplierList', { group_name: v, supplier_type: supplier_type })"
          />
        </v-col>
        <v-col cols="4">
          <a-select
            label="Supplier Types"
            widelabel
            :items="supplierTypes"
            v-model="supplier_type"
            @input="(v) => $emit('updateSupplierList', { supplier_type: v, group_name: group_name })"
          />
        </v-col>

        <v-col cols="5">
          <elwrapper label="Status">
            <div class="select-wrapper">
              <v-autocomplete
                dense
                outlined
                hide-details
                item-text="label"
                item-value="value"
                :items="statusOptions"
                v-model="status"
              >
                <template v-slot:selection="item">
                  <StatusSelectItem :item="item.item" />
                </template>
                <template v-slot:item="item">
                  <StatusSelectItem :item="item.item" />
                </template>
              </v-autocomplete>
              <v-btn v-show="status !== null" @click="status = null" x-small icon class="select-clear">
                <v-icon>clear</v-icon>
              </v-btn>
            </div>
          </elwrapper>
        </v-col>
      </v-row>

      <v-row class="pa-0">
        <v-col class="py-0" cols="3">
          <a-select
            label="Supplier"
            :items="focusedSupplierList"
            v-model="supplier_id"
            @change="(v) => (supplier_id = v)"
          />
        </v-col>

        <v-col class="py-0">
          <ShowStatusOptions v-model="booking_status" @input="updateSearch" />
        </v-col>
      </v-row>

      <div class="d-flex mt-3">
        <g-button label="Clear" @onclick="clearSearch" />
        <v-spacer />
        <search-button :disabled="status === null" />
      </div>
    </v-form>
  </v-card>
</template>

<script>
import { StatusSelectItem } from "@/modules/admin.email_template";
import { SuppliersMenu } from "@/config/menus";
import STATUS_COLORS from "@/config/STATUS_COLORS";
import ShowStatusOptions from "./_ShowStatusOptions";
export default {
  props: {
    groupListBlob: Object,
    supplierListBlob: Array,
  },
  components: {
    StatusSelectItem,
    ShowStatusOptions,
  },
  computed: {
    group_name: {
      get() {
        return this.get("group_name") || "";
      },
      set(nuval) {
        this.set("group_name", nuval);
      },
    },
    supplier_type: {
      get() {
        return this.get("supplier_type") || "ALL";
      },
      set(nuval) {
        this.set("supplier_type", nuval);
      },
    },
    booking_status: {
      get() {
        return this.get("booking_status") || "1";
      },
      set(nuval) {
        this.set("booking_status", nuval);
      },
    },
    status: {
      get() {
        const s = this.get("status");
        return !isNaN(s) ? s : 100;
      },
      set(nuval) {
        this.set("status", nuval);
      },
    },
    supplier_id: {
      get() {
        return this.get("supplier_id") || null;
      },
      set(nuval) {
        this.set("supplier_id", nuval);
      },
    },
    groupList() {
      return Object.keys(this.groupListBlob[this.booking_status || 1] || {}).sort((a, b) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      });
    },

    supplierTypes() {
      return SuppliersMenu.FULL.map((item) => {
        return {
          label: item.title,
          value: item.module,
        };
      });
    },
    statusOptions() {
      return [
        {
          label: "Any status",
          value: 100,
        },
        ...STATUS_COLORS.dotColors(this.supplier_type).filter((item) => item),
      ];
    },

    focusedSupplierList() {
      return this.supplierListBlob.filter((sup) => {
        return sup.bookingStatusList.includes(`${this.booking_status}`);
      });
    },
  },
  methods: {
    get(val) {
      return this.$store.getters["BookingEmailStore/statusSearch"][val];
    },
    set(key, nuval) {
      let data = this.$store.getters["BookingEmailStore/statusSearch"];
      Object.assign(data, { byStatus: true, [key]: nuval });
      this.$store.dispatch("BookingEmailStore/updateSearch", { tab: "status", data });
    },

    bumpSearch() {
      let search = {
        byStatus: true,
        ...(this.group_name && { group_name: this.group_name }),
        ...(this.supplier_type && { supplier_type: this.supplier_type }),
        ...(this.booking_status !== 1 && { booking_status: this.booking_status }),
        ...(this.supplier_id && { supplier_id: this.supplier_id }),
        ...(!isNaN(this.status) && { status: this.status }),
      };

      this.$emit("search", search);

      return search;
    },
    clearSearch() {
      this.group_name = "";
      this.group_name = "ALL";
      this.supplier_id = null;
      this.status = 100;
      this.$emit("search", {});
      this.$store.dispatch("BookingEmailStore/resetSearch");
    },
    updateSearch() {
      const searchData = this.bumpSearch();
      this.$store.dispatch("BookingEmailStore/applySearch", {
        tab: "status",
        data: searchData,
      });
    },
  },
};
</script>

<style lang="scss"></style>
