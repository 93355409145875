<template>
  <v-tabs v-model="tab" dense>
    <v-tabs-slider></v-tabs-slider>
    <v-tab v-for="(v, i) in tabs" :key="i" :to="v.to">
      <span>{{ v.text }}</span>
      <v-icon v-if="v.exists">{{ v.existIcon }}</v-icon>
    </v-tab>
    <v-tab :to="remindersLink">
      <span>Reminders</span>
      <v-badge inline v-if="reminderFlatten.soon" :content="reminderFlatten.soon" color="red"></v-badge>
      <v-badge inline v-if="reminderFlatten.leftover" :content="reminderFlatten.leftover" color="warning"></v-badge>
      <v-badge inline v-if="reminderFlatten.done" :content="reminderFlatten.done" color="green"></v-badge>
    </v-tab>
  </v-tabs>
</template>

<script>
import moment from "moment";
export default {
  props: {
    bookingId: String,
  },
  data() {
    return {
      tab: 0,
    };
  },
  computed: {
    tabs() {
      return [
        {
          text: "Booking",
          to: { name: "bookings_view", params: { booking_id: this.bookingId } },
        },
        {
          text: "Rooming List",
          to: { name: "bookings_rooms", params: { booking_id: this.bookingId } },
          existIcon: "night_shelter",
          exists: this.$store.getters["BookingRoomStore/totalRooms"],
        },
        {
          text: "Contracts",
          to: { name: "booking_contracts", params: { booking_id: this.bookingId } },
          existIcon: "description",
          exists: this.$store.getters["BookingStore/contracts"].length,
        },
        {
          text: "Technical",
          to: { name: "booking_technical", params: { booking_id: this.bookingId } },
        },
        {
          text: "Airport Sign",
          to: { name: "booking_sign", params: { booking_id: this.bookingId } },
        },
        {
          text: "Appreciation Sheet",
          to: { name: "booking_appreciation", params: { booking_id: this.bookingId } },
        },
        {
          text: "Invoices",
          to: { name: "booking_invoices", params: { booking_id: this.bookingId } },
          existIcon: "description",
          exists: this.$store.getters["BookingStore/invoices"].length,
        },
      ];
    },
    remindersLink() {
      return { name: "booking_reminder", params: { booking_id: this.bookingId } };
    },

    reminderFlatten() {
      const soonDate = moment().add(72, "hours").format("YYYY-MM-DD");
      const temp = {
        done: 0,
        soon: 0,
        leftover: 0,
      };

      Object.values(this.$store.getters["BookingReminderStore/reminderMapping"]).forEach((dateGroup) => {
        if (!dateGroup) return;
        dateGroup.forEach((reminder) => {
          // DONE
          if (reminder.done_by) {
            temp.done += 1;
            return;
          }

          // UPCOMING (WITH 1 WEEK)
          if (reminder.date < soonDate) {
            temp.soon += 1;
            return;
          }

          // ALL THE REST
          temp.leftover += 1;
        });
      });

      return temp;
    },
  },
};
</script>
