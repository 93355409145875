import DocumentsView from "./DocumentsView";

export default [
  {
    path: "documents",
    name: "suppliers_documents",
    component: DocumentsView,
    meta: {
      toolbar: "supplier",
      tabAccess: "DATABASE",
    },
  },
];
