import QuotesHome from "./QuotesHome";
import QuoteForm from "./QuoteForm";
import QuoteEditWrapper from "./QuoteEditWrapper";
import QuoteDocuments from "./QuoteDocuments";

import MainInfo from "./subview/MainInfo";
import AllDays from "./subview/AllDays";

export default [
  {
    path: "/quotes",
    name: "quotes",
    component: QuotesHome,
    meta: {
      tabAccess: "QUOTES",
    },
  },
  {
    path: "/quotes_new",
    name: "quotes_new",
    component: QuoteForm,
    meta: {
      tabAccess: "QUOTES",
    },
  },
  {
    path: "/quotes_view/:quote_id",
    component: QuoteEditWrapper,
    children: [
      {
        path: "",
        name: "quotes_view",
        component: QuoteForm,
        meta: {
          tabAccess: "QUOTES",
        },
      },
      {
        path: "documents",
        name: "quotes_documents",
        component: QuoteDocuments,
        meta: {
          tabAccess: "QUOTES",
        },
      },
    ],
  },
];
