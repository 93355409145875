<template>
  <toggle-button
    :isset="archived"
    color="grey"
    issetText="Archive"
    issetIcon="archived"
    reverseText="Unarchive"
    reverseIcon="unarchive"
    @toggle="toggleArchive"
  />
</template>

<script>
export default {
  computed: {
    bookingId() {
      return this.$store.getters["FinanceReportStore/booking_id"];
    },
    archived() {
      return this.$store.getters["FinanceReportStore/report_archived"];
    },
  },
  methods: {
    toggleArchive(val) {
      this.$root.$loading.open("Updating...");
      this.$store
        .dispatch("FinanceReportStore/toggleArchiveReport", {
          booking_id: this.bookingId,
          archive: val,
        })
        .then(() => {
          this.$root.$loading.end();
          this.$root.$success("Updated Report");
        })
        .catch((err) => this.$root.$error(err));
    },
  },
};
</script>
