const base = [
  {
    title: "All",
    module: "ALL",
    alt: "all",
  },
  {
    title: "Hotels",
    module: "HOTEL",
    alt: "hotels",
    icon: "business",
  },
  {
    title: "Restaurants",
    module: "RESTAURANT",
    alt: "restaurants",
    icon: "local_dining",
  },
  {
    title: "Transportation",
    module: "TRANSPORT",
    alt: "transport",
    roomKind: "Driver",
    icon: "directions_bus",
  },
  {
    title: "Parks",
    module: "PARK",
    alt: "parks",
    icon: "nature_people",
  },
  {
    title: "Tour Guides",
    module: "TOUR_GUIDE",
    alt: "tour_guides",
    roomKind: "Tour Guide",
    icon: "emoji_people",
  },
  {
    title: "Excursions",
    module: "EXCURSION",
    alt: "excursions",
    icon: "terrain",
  },
];

export default {
  FULL: base,
  MOD: base.filter((v) => v.module !== "ALL").map((v) => v.module),
  DBKEY: base.filter((v) => v.module !== "ALL").map((v) => v.alt),
  MOD_DBKEY: base.reduce((obj, item) => {
    if (item.module === "ALL") return obj;
    obj[item.module] = item.alt;
    return obj;
  }, {}),
};
