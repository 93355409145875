<template>
  <section>
    <v-col class="ring pa-0">
      <elwrapper label="Rate" smlabel texttop>
        <SeasonalPricing
          v-if="seasonal"
          :env="env"
          :disabled="!editable"
          v-model="rate"
          @revert="toggleSeasonal(false)"
        />

        <div v-else>
          <div style="max-width: 300px">
            <cur-per-val :env="env" :dropdown="source" :default="defaultPer" v-model="rate" :disabled="!editable" />
          </div>
          <span v-show="editable" class="seasonal-click blue--text caption pr-3" @click="toggleSeasonal(true)"
            >Change to Seasonal</span
          >
        </div>
      </elwrapper>
      <div class="d-flex align-center" style="margin-left: 50px">
        <div class="d-flex align-center">
          <input type="radio" name="rateKind" value="GENERIC" v-model="rate_kind" :disabled="!editable" />
          <span class="caption mx-2">Generic</span>
        </div>
        <div class="d-flex align-center">
          <input type="radio" name="rateKind" value="CONTRACT" v-model="rate_kind" :disabled="!editable" />
          <span class="caption mx-2">Contracted</span>
        </div>
      </div>
    </v-col>
    <v-col class="ring pa-0" v-if="rate.type === 'PROOM'">
      <div v-for="(data, i) in perRoomData" :key="data.type + i">
        <v-row class="align-center">
          <elwrapper label="Rate" texttop style="max-width: 350px">
            <cur-per-val
              :env="env"
              :dropdown="perRoomTypeSource"
              :default="defaultPerRoom"
              v-model="perRoomData[i]"
              :disabled="!editable"
            />
          </elwrapper>
          <v-btn x-small icon class="select-clear" @click="() => removePerRoomDataRow(i)">
            <v-icon>clear</v-icon>
          </v-btn>
        </v-row>
      </div>
      <g-button label="Add a new rate" icon="add" @onclick="addPerRoomDataRow" />
    </v-col>
  </section>
</template>

<script>
import { ProductFields, ProductPerRoomTypes } from "@/config/fields/products";
import SeasonalPricing from "./_SeasonalPricing";
export default {
  props: {
    supplierName: String,
    customYear: String,
  },
  components: {
    SeasonalPricing,
  },
  // watch: {
  //   rate(value) {
  //     if (value.type === "PROOM" && this.perRoomData.length === 0) {
  //       this.addPerRoomDataRow();
  //     }
  //   },
  // },
  computed: {
    editable() {
      const currentYear = new Date().getFullYear();
      if (this.defaultEnv) {
        if (`${currentYear}` === this.customYear) return true;
        if (`${currentYear + 1}` === this.customYear) return true;
      }
      return false;
    },

    env() {
      return this.$store.getters["AccountStore/environment"];
    },
    source() {
      var productType = this.get("type");
      var supplierType = this.get("supplier_type");
      return ProductFields.rate_type.getSource(productType, supplierType);
    },
    rate: {
      get() {
        return this.$store.getters["ProductStore/rate"](this.customYear);
      },
      set(newValue) {
        return this.setByRate("rate", newValue);
      },
    },
    seasonal: {
      get() {
        return this.getByRate("seasonal");
      },
      set(newValue) {
        return this.setByRate("seasonal", newValue);
      },
    },
    rate_kind: {
      get() {
        return this.getByRate("meta").rate_kind;
      },
      set(newValue) {
        return this.$store.dispatch("ProductStore/setUpdateMeta", {
          key: "rate_kind",
          value: newValue,
          year: this.customYear,
        });
      },
    },
    defaultPer() {
      var productType = this.get("type");
      var supplierType = this.get("supplier_type");

      if (productType === "ROOM") return "PROOM";
      if (["RESTAURANT", "EXCURSION"].includes(supplierType)) return "PP";
      if (supplierType === "TRANSPORT") return "PG";
      return "";
    },
    defaultEnv() {
      return this.get("supplier_env") == this.$store.getters["AccountStore/environment"];
    },
    perRoomTypeSource() {
      return Object.values(ProductPerRoomTypes);
    },
    defaultPerRoom() {
      return ProductPerRoomTypes.SINGLE.value;
    },
    perRoomData: {
      get() {
        return this.getByRate("meta").per_room ?? [];
      },
      set(newValue) {
        return this.$store.dispatch("ProductStore/setUpdateMeta", {
          key: "per_room",
          value: newValue,
          year: this.customYear,
        });
      },
    },
  },
  methods: {
    get(val) {
      return this.$store.getters["ProductStore/" + val];
    },
    getByRate(val) {
      return this.$store.getters["ProductStore/" + val](this.customYear);
    },
    set(key, val) {
      return this.$store.dispatch("ProductStore/setUpdate", { key, val });
    },
    setByRate(key, val) {
      return this.$store.dispatch("ProductStore/setUpdateRate", { key, val, year: this.customYear });
    },
    toggleSeasonal(on) {
      // Turn it on here
      if (on) {
        this.rate = [
          {
            mstart: 1,
            mend: 12,
            rate: this.rate,
          },
        ];
        this.seasonal = true;
        return;
      }

      // Switch from seasonal view back to regular
      this.seasonal = false;
      if (this.rate.length === 0) {
        this.rate = {
          ...(this.defaultPer && { type: this.defaultPer }),
        };
        return;
      }

      // Existing seasonal price
      this.rate = this.rate[0].rate;
    },
    addPerRoomDataRow() {
      this.perRoomData = [...this.perRoomData, { type: ProductPerRoomTypes.SINGLE.value, value: "", cur: this.env }];
    },
    removePerRoomDataRow(index) {
      this.perRoomData = this.perRoomData.filter((_, i) => i !== index);
    },
  },
};
</script>

<style lang="scss">
.seasonal-click:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>
