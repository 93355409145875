<template>
  <div>
    <v-row>
      <v-col>
        <a-text-field widelabel label="Email" v-model="emailStart" />
      </v-col>
      <v-col cols="5">
        <f-dropdown v-model="source" :items="emailSource" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <elwrapper label="Password" widelabel texttop>
          <f-password v-model="password" />
        </elwrapper>
      </v-col>
      <v-col cols="5">
        <div class="d-flex align-center">
          <input class="mx-2" type="checkbox" :checked="ismain" @change="ismain = !ismain" />
          <span class="caption">Use this email to send mail</span>
        </div>
      </v-col>
    </v-row>

    <v-row justify="space-between">
      <g-button label="Cancel" icon="clear" @onclick="$emit('close')" />
      <g-button label="Submit" type="primary" icon="save" @onclick="addEmail" />
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    accountId: String,
    linkedEmailList: Array,
  },
  data() {
    return {
      emailSource: [
        {
          label: "@ supernature.travel",
          value: "SUPERNATURE",
        } /*,{
				label: '@ service.supernature.travel',
				value: 'INSPIRIT'
			}*/,
      ],

      emailStart: null,
      source: "SUPERNATURE",
      password: null,
      ismain: false,
    };
  },
  methods: {
    addEmail() {
      let preAdd = Promise.resolve();
      let currentMain = this.linkedEmailList.find((item) => item.ismain);
      if (this.ismain && currentMain) {
        preAdd = this.$store.dispatch("AccountStore/updateCustomEmail", {
          emailaccount_id: currentMain.id,
          data: {
            ismain: false,
          },
        });
      }

      preAdd
        .then(() => {
          return this.$store.dispatch("AccountStore/addCustomEmail", {
            account_id: this.accountId,
            source: this.source,
            data: {
              emailStart: this.emailStart,
              password: this.password,
              ismain: this.ismain,
            },
          });
        })
        .then((nuemail) => {
          this.$root.$success("Added Email");
          this.$emit("added", {
            email: nuemail,
            password: this.password,
            ismain: this.ismain,
          });
          this.$emit("close");
        })
        .catch((err) => this.$root.$error(err));
    },
  },
};
</script>
