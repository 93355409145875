module.exports = `
body {
	font-family:arial;
}

.cancelled {
	opacity: 0.5;
	text-decoration: line-through;
}

.roomlist .logo {
	display: flex;
	justify-content: center;
}

.roomlist .logo img {
	height: 120px
}


.roomlist .room-header {
	display: flex;
	justify-content: center;
	margin-bottom: 20px;
}

.roomlist .title {
	font-size: 20px;
    font-weight: 500;
    letter-spacing: .0125em!important;
    text-decoration: underline;
}

.roomlist .room-content {
  -webkit-column-width: 15em;
  -moz-column-width: 15em;
  column-width: 15em;
    padding: 10px 50px;
}

.roomlist table {
	width: 300px;
	border: 1px solid black;
	margin-bottom: 20px;
	break-inside: avoid-column;
	-webkit-column-break-inside: avoid;

	font-size: 12px;
}

.roomlist th {
	background: lightblue;
	border-bottom: 1px solid black;
	-webkit-print-color-adjust: exact; 
}
 
.roomlist th, 
.roomlist td {
	padding: 2px;
}


.roomlist tr td:first-child {
	width: 100px;
}

.roomlist tr {
	border: 1px solid black;
}

.roomlist .rtop {
	border-top: 1px solid black;
}
.roomlist .rleft {
	border-left: 1px solid black;
}

.roomlist .rright {
	border-right: 1px solid black;
}

.roomlist .rbottom {
	border-bottom: 1px solid black;
}


.roomlist .indexing {
	text-align: center;
}



.roomlist .caps {
	text-transform: uppercase;
}

.roomtotal {
	background: lightgrey;
	font-weight: bold;
	-webkit-print-color-adjust: exact; 
}

.roomtotal tr td:first-child {
	width: 100px;
}

.roomtotal td:nth-child(2) {
	text-align: center;
}

.roomtotal .red--text {
	color: red;
}
`;
