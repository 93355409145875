<template>
  <div class="enheader">
    <h3 class="headline">{{ label }}</h3>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
  },
};
</script>

<style lang="scss">
.enheader {
  display: flex;
  flex-direction: column;
  color: white;
  text-align: center;
}
</style>
