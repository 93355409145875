<template>
  <div>
    <Payment
      v-model="expense"
      :totalInvoice="supplierTotal"
      :remainingTotal="remainingTotal"
      :paidAmount="paidAmount"
    />

    <PaymentDateRow v-model="expenseDate" />

    <v-card-actions>
      <g-button @onclick="$emit('close')" label="Cancel" />
      <div class="flex-grow-1"></div>
      <g-button type="primary" @onclick="addExpense" label="Add" />
    </v-card-actions>
  </div>
</template>

<script>
import money from "@/services/money";
import format from "@/services/format";
import Payment from "@/modules/finances.report/components/paymentTypes/Payment";
import PaymentDateRow from "@/modules/finances.report/components/paymentTypes/_PaymentDateRow";
import ReportService from "@/modules/finances.report/service";
export default {
  props: {
    editSupplier: Object,
    editDeposit: Array,
  },
  components: {
    PaymentDateRow,
    Payment,
  },
  data() {
    return {
      expenseDate: format.convertDate(new Date()),
      expense: null,
    };
  },
  computed: {
    supplierTotal() {
      return ReportService.getProductListTotal(this.editSupplier.products, false, this.$store.getters);
    },
    paidAmount() {
      return this.editDeposit.reduce((total, item) => {
        let value = money.stripFormatting(item.amount && item.amount.value);
        return total + (value || 0);
      }, 0);
    },
    remainingTotal() {
      return this.paidAmount && this.supplierTotal ? this.supplierTotal - this.paidAmount : this.supplierTotal || 0;
    },
  },
  methods: {
    addExpense() {
      this.$store
        .dispatch("FinanceCompileStore/addExtraExpenses", {
          booking_id: this.$store.getters["FinanceReportStore/booking_id"],
          data: {
            supplier_id: this.editSupplier.supplier_id,
            type_as: this.editSupplier.type_as,
            expense_type: "DEPOSIT",
            label: "DEPOSIT",
            datestamp: this.expenseDate,
            amount: {
              value: this.expense,
            },
          },
        })
        .then((v) => {
          this.$emit("close");
          this.$root.$success("Added Expense");
        })
        .catch((err) => this.$root.$error(err));
    },
  },
};
</script>
