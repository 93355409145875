<template>
  <a-card class="d-flex product-info justify-space-between">
    <div class="d-flex">
      <div class="infocol">
        <div>
          <a-field :config="config.name" v-model="name" smlabel />
        </div>
        <div class="caption d-flex align-center">
          <a-field :config="config.pid" :value="pid" smlabel midtext />

          <span class="grey--text lighten-1 px-3">{{ groupOrFitLabel }}</span>
          <v-btn icon x-small @click="dialog = true">
            <v-icon x-small>edit</v-icon>
          </v-btn>
        </div>
        <elwrapper>
          <a-field :config="config.active" v-model="active" />
        </elwrapper>
      </div>

      <RateShow :customYear="customYear" />
    </div>

    <TotalShow :customYear="customYear" />

    <SwitchProductDialog
      v-model="dialog"
      :currentType="currentType"
      :groupCount="groupSize"
      @update="updateBaseProductInformation"
      @updateGF="updateGroupFit"
      @switch="updateProductType"
    />
  </a-card>
</template>

<script>
import { ProductFields } from "@/config/fields/products";
import RateShow from "./rate/RateShow";
import TotalShow from "./_TotalShow";

import SwitchProductDialog from "./switchProductType/SwitchProductDialog";
export default {
  props: {
    pid: String,
    customYear: String,
  },
  components: {
    RateShow,
    TotalShow,
    SwitchProductDialog,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    config() {
      return ProductFields;
    },
    name: {
      get() {
        return this.get("name");
      },
      set(newValue) {
        return this.set("name", newValue.trim());
      },
    },
    active: {
      get() {
        return this.get("active");
      },
      set(newValue) {
        return this.set("active", newValue);
      },
    },
    currentType() {
      return this.$store.getters["ProductStore/type"];
    },
    groupOrFitLabel() {
      const group_fit = this.get("group_fit");
      if (group_fit === "FIT") return group_fit;
      if (group_fit === "GROUP") return `Group (${this.getPerRate("group_size")})`;
      return "Group/FIT";
    },
    groupSize: {
      get() {
        return this.getPerRate("group_size");
      },
    },
  },
  methods: {
    get(val) {
      return this.$store.getters["ProductStore/" + val];
    },
    getPerRate(val) {
      return this.$store.getters["ProductStore/" + val](this.customYear);
    },
    set(key, val) {
      return this.$store.dispatch("ProductStore/setUpdate", { key, val });
    },
    updateGroupFit(data) {
      this.dialog = false;
      this.$root.$loading.open("Updating product");
      this.$store
        .dispatch("ProductStore/updateProductGroupFit", data)
        .then(() => {
          this.$root.$loading.end();
          this.$root.$success("Updated " + this.name);
        })
        .catch((err) => {
          this.$root.$loading.end();
          this.$root.$error(err);
        });
    },
    updateProductType(nuType) {
      // Close the dialog
      // Launch update page (need loading since current view affected)
      this.dialog = false;
      this.$root.$loading.open("Updating current product type");
      this.$store
        .dispatch("ProductStore/updateProductType", nuType)
        .then(() => {
          this.$root.$loading.end();
          this.$root.$success("Updated " + this.name);
        })
        .catch((err) => {
          this.$root.$loading.end();
          this.$root.$error(err);
        });
    },
    updateBaseProductInformation(data) {
      this.dialog = false;
      this.$store.dispatch("ProductStore/setUpdateMeta", { ...data, year: this.customYear });
      this.$store
        .dispatch("ProductStore/updateProduct")
        .then((_) => this.$store.dispatch("ProductStore/updateProductRateCurrentAndNextYear"))
        .then(() => {
          this.$root.$loading.end();
          this.$root.$success("Updated " + this.name);
        })
        .catch((err) => {
          this.$root.$loading.end();
          this.$root.$error(err);
        });
    },
  },
};
</script>

<style lang="scss">
.product-info {
  .infocol {
    width: 300px;
  }
}
</style>
