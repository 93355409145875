<template>
  <v-dialog v-model="dialog" width="300">
    <template v-slot:activator="{ on }">
      <v-btn icon small v-on="on">
        <v-icon small>edit</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="d-flex flex-column align-start">
        <div>Custom Commission:</div>
        <div class="subtitle-2">{{ supplierName }}</div>
      </v-card-title>
      <v-card-text class="black--text">
        <v-row>
          <v-col class="subtitle-2" cols="4" align-self="center"> Calculated </v-col>

          <v-col class="d-flex justify-end">
            {{ compiledCommissionTotal || "$ --" }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="subtitle-2" cols="4" align-self="center"> Custom </v-col>

          <v-col class="d-flex justify-end">
            <f-number :value="displayNumber" @input="(v) => (customValue = v)" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <g-button @onclick="dialog = false" label="Cancel" />
        <div class="flex-grow-1"></div>
        <g-button type="red" @onclick="remove" label="Reset" />
        <g-button type="primary" @onclick="update" label="Update" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import money from "@/services/money";
export default {
  props: {
    external: Boolean,
    bookingId: String,
    supplierId: String,
    supplierName: String,

    customCommissionId: String,
    customCommission: [String, Number],
    compiledCommissionTotal: [String, Number],
  },
  data() {
    return {
      dialog: false,
      customValue: null,
    };
  },
  computed: {
    displayNumber() {
      if (this.customValue === 0) return 0;
      return money.stripFormatting(this.customValue || this.compiledCommissionTotal);
    },
  },
  methods: {
    update() {
      this.$store
        .dispatch("FinanceCompileStore/setCustomCommission", {
          skipCommit: this.external,
          booking_id: this.bookingId,
          supplier_id: this.supplierId,
          type_as: "HOTEL",
          customCommissionId: this.customCommissionId,
          data: {
            custom_amount: this.displayNumber,
          },
          cache: {},
        })
        .then((custom) => {
          this.dialog = false;
          this.$emit("sync-commission", { type: "UPDATE", data: custom });
        });
    },
    remove() {
      this.$store
        .dispatch("FinanceCompileStore/removeCustomCommission", {
          skipCommit: this.external,
          custom_commission_id: this.customCommissionId,
        })
        .then((custom) => {
          this.dialog = false;
          this.$emit("sync-commission", { type: "DELETE", custom_commission_id: custom });
        });
    },
  },
};
</script>
