<template>
  <v-simple-table fixed-header>
    <thead>
      <tr>
        <th class="text-left">Fill Text</th>
        <th class="text-left">Sub</th>
        <th class="text-left" style="width: 550px">Description</th>
        <th class="text-left">Example</th>
        <th class="text-left">Example Result</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in items" :key="index">
        <td>{{ item.fill }}</td>
        <td>{{ item.sub }}</td>
        <td style="width: 550px">{{ item.description }}</td>
        <td v-html="item.example"></td>
        <td v-html="item.result"></td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    items: Array,
  },
};
</script>
