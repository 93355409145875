<template>
  <div class="px-5">
    <v-row v-if="showNotes">
      <v-col cols="1" v-if="editMode" class="pa-0">
        <v-btn icon x-small @click="$emit('input', !value)">
          <v-icon>{{ value ? "visibility_off" : "visibility" }}</v-icon>
        </v-btn>
      </v-col>

      <v-col
        v-if="!value || editMode"
        class="d-flex flex-column pa-0"
        :class="{ 'text--disabled': editMode && value }"
        v-html="adjustNotes"
      ></v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
    editMode: Boolean,
    technicalNotes: String,
  },
  computed: {
    showNotes() {
      return true;
    },
    adjustNotes() {
      return (this.technicalNotes || "").replace(/\r?\n/g, "<br />");
    },
  },
};
</script>
