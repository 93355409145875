<template>
  <v-row class="ma-3 caption">
    <v-col class="pa-0">{{ name }}</v-col>
    <v-col cols="3" class="pa-0">
      <PhoneDisplay :value="phoneNumber" :country="country" />
    </v-col>
    <v-col cols="5" class="pa-0">
      <f-textarea v-if="editMode" rows="3" :value="focusNotes" @input="updateNotes" />
      <span v-else>{{ focusNotes }}</span>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    editMode: Boolean,
    company: Object,
    contactMap: Object,
    notes: Object,
  },
  computed: {
    name() {
      return (this.contact && this.contact.meta && this.contact.meta.en_name) || this.company.name;
    },
    confirmNumbers() {
      const num = this.company.confirm_number;
      if (!num) return "";
      if (typeof num === "string") return num;
      return num.join(", ");
    },
    contact() {
      return this.contactMap[this.company.supplier_id];
    },
    phoneNumber() {
      if (!this.contact) return "";
      if (this.contact.supplier_type === "TOUR_GUIDE") return this.contact.primary_phone_number;
      return this.contact.phone_number;
    },
    country() {
      if (!this.contact) return "";
      return this.contact.country;
    },
    focusNotes() {
      return (this.notes || {})[this.company.supplier_id];
    },
  },
  methods: {
    updateNotes(v) {
      this.$emit("update", Object.assign(this.notes || {}, { [this.company.supplier_id]: v }));
    },
  },
};
</script>
