<template>
  <v-dialog :value="value" persistent max-width="350">
    <v-card>
      <v-card-title>Warning</v-card-title>
      <v-card-text class="black--text">
        <div v-html="message"></div>
      </v-card-text>
      <v-card-actions>
        <g-button label="OK" @onclick="save" />
        <g-button label="Open Report" @onclick="redirectToReport" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    bookingId: String,
    hasReconciledPayment: Boolean,
    isLastProduct: Boolean,
  },
  computed: {
    message() {
      let message = "";
      if (this.hasReconciledPayment) {
        message = "This supplier has reconciled payments, please mark these payments as non reconciled before removing";
      }
      if (this.isLastProduct) {
        if (message) {
          message += "<br><br>";
        }
        message += "You couldn't remove this product due to it's last product in this booking";
      }
      return message;
    },
  },
  methods: {
    save() {
      this.$emit("input", false);
    },
    redirectToReport() {
      this.$emit("input", false);
      this.$router.push("/finances/report/" + this.bookingId);
    },
  },
};
</script>
