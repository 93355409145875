import api from "./api";

const namespaced = true;

const state = {};

const mutations = {};

const actions = {
  getTechnical({ commit }, booking_id) {
    return api
      .getTechnical(booking_id)
      .then((v) => v.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  saveTechnical({ commit }, { booking_id, blob }) {
    return api
      .updateTechnical(booking_id, blob)
      .then((v) => v.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },

  getSupplierContactInformation({ commit }, supplierIdList) {
    return api
      .getSupplierContactInformation(supplierIdList)
      .then((v) => v.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
};

const getters = {};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
