export default {
  createEmailQuery,
  checkItemHas,
  checkDate,
};

function createEmailQuery(filter, contactMapping, accountEmailMapping) {
  // Extract emails + date from input form

  // Remove user emails (so only emails with specified user)
  const accountEmails = Object.keys(accountEmailMapping).map((key) => accountEmailMapping[key].email);
  const selectedUser = filter.user ? [filter.user.email] : [];

  // Remove contact emails (so only emails with specific contact)
  const contactEmails = contactMapping.filter((v) => v.email).map((v) => v.email);
  const selectedContact = !filter.contact ? [] : [contactMapping.find((v) => v.id === filter.contact).email];

  return {
    ...(filter.user && { customUserContacts: selectedUser }),
    ...(filter.contact && { customEmailContacts: selectedContact }),
    dates: filter.dates,
  };
}

function checkItemHas(item, searchVal, isObject, contactMapping) {
  if (item.type === "NOTE") return _handleNoteFilter(item, searchVal, typeof searchVal === "object");

  // Might be moot, since email on backend now
  // IF OBJECT, recursive search with name + email
  if (isObject) return checkItemHas(item, searchVal.name) || checkItemHas(item, searchVal.email);

  // Regular search
  if (_compare(item.from_mail, searchVal, contactMapping)) return true;
  if (_compare(item.to_mail, searchVal, contactMapping)) return true;
  if (_compare(item.cc, searchVal, contactMapping)) return true;
  return false;
}

function _handleNoteFilter(item, searchVal, isObject) {
  // If an object, selected the user/acoount input
  // Should have a user id to search by which corresponds to the created_by key
  if (isObject) return item.created_by === searchVal.id;

  // Otherwise search by name
  return item.creator_name.includes(searchVal);
}

function _compare(item, check, contactMapping) {
  return (
    _hasval(item.address, check) ||
    _hasval(item.name, check) ||
    (contactMapping[item.address] ? _hasval(contactMapping[item.address], check) : false)
  );
}

function _hasval(content, check) {
  return content.toLowerCase().includes(check.toLowerCase());
}

// DATE FILTER STUFF
function checkDate(item, dateInfo) {
  if (dateInfo.range) {
    if (_datesAreOnSameDay(item.date, dateInfo.from)) return true;
    if (_datesAreOnSameDay(item.date, dateInfo.to)) return true;

    return new Date(dateInfo.from).getTime() <= item.date && item.date <= new Date(dateInfo.to).getTime();
  }

  return _datesAreOnSameDay(item.date, dateInfo.from);
}

function _datesAreOnSameDay(f, s) {
  const first = new Date(f),
    second = new Date(s);
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  );
}
