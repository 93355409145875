<template>
  <div>
    <v-row>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" class="mr-1">date_range</v-icon>
        </template>
        <span>Working Product Year</span>
      </v-tooltip>
      <v-select dense hide-details :items="items" v-model="year" style="max-width: 80px" />
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    let START_YEAR = 2020;
    let ADVANCE = 2;
    let LENGTH = new Date().getFullYear() - START_YEAR + ADVANCE;

    return {
      items: [...Array(LENGTH).keys()].map((i) => `${2020 + i}`).reverse(),
    };
  },
  computed: {
    year: {
      get() {
        return this.$store.getters["workingYear"];
      },
      set(value) {
        this.$store.dispatch("setDefaultProductYear", value);
      },
    },
  },
};
</script>
