<template>
  <div>
    <v-row class="flex-nowrap">
      <v-col cols="8">
        <elwrapper label="Template">
          <div class="select-wrapper">
            <v-autocomplete
              dense
              outlined
              hide-details
              single-line
              item-text="label"
              item-value="value"
              :items="templateList"
              :value="customTemplateId"
              @input="updateTemplate"
            >
              <template v-slot:selection="item" class="text-truncate">
                <StatusSelectItem :item="item.item" />
              </template>
              <template v-slot:item="item">
                <StatusSelectItem :item="item.item" />
              </template>
            </v-autocomplete>
            <v-btn v-show="customTemplateId" @click="updateTemplate(null)" x-small icon class="select-clear">
              <v-icon>clear</v-icon>
            </v-btn>
          </div>
        </elwrapper>
      </v-col>

      <v-col cols="4">
        <a-select icon="language" smlabel :items="languageList" :value="calcLang" @input="updateLanguage" />
      </v-col>
    </v-row>
    <div v-if="noLanguage" class="red--text mx-5 caption">
      <v-icon small left color="red">cancel</v-icon>
      <span>Template does not have this language</span>
    </div>
  </div>
</template>

<script>
import StatusSelectItem from "./_StatusSelectItem";
import EmailTemplateService from "../service";
import STATUS_COLORS from "@/config/STATUS_COLORS";
import ENV from "@/config/ENV";
import languageList from "language-list";

const LANGUAGES = languageList();

export default {
  props: {
    limitTemplate: Number,
  },
  components: {
    StatusSelectItem,
  },
  data() {
    return {
      customTemplateId: "",
      customLanguage: "",

      noLanguage: false,
    };
  },
  computed: {
    signature() {
      return this.$store.getters["AccountStore/signature"];
    },
    templateList() {
      var t = this.$store.state.EmailTemplateStore.emailList;
      return t
        .filter((v) => {
          if (isNaN(this.limitTemplate)) return !v.parent_id;
          return this.limitTemplate === Number(v.status_set) && !v.parent_id;
        })
        .sort((first, second) => {
          return first.order - second.order;
        })
        .map((v) => {
          return {
            label: v.name,
            value: v.id,
            color: this.colors[v.status_set] && this.colors[v.status_set].color,
          };
        });
    },
    languageList() {
      // Extract all languages in use
      let lang = {};
      this.$store.state.EmailTemplateStore.emailList.forEach((v) => {
        if (lang[v.language]) return;
        lang[v.language] = {
          value: v.language,
          label: LANGUAGES.getLanguageName(v.language.toLowerCase()),
        };
      });

      return Object.values(lang);
    },
    colors() {
      let c = {};
      Object.values(STATUS_COLORS.COLORS).map((item) => {
        c[item.value] = {
          label: item.label,
          color: item.color,
          value: `${item.value}`, // requires string
        };
      });
      return c;
    },

    calcLang() {
      const env = this.$store.getters["AccountStore/environment"];
      const focus = ENV.find((x) => x.value === env);
      return this.customLanguage || focus.defaultLanguage_code || "";
    },
  },
  methods: {
    updateTemplate(id) {
      this.customTemplateId = id;
      if (!id) return;

      const t = this.$store.state.EmailTemplateStore.emailList;
      const found = t.find((v) => {
        return (v.id === id || v.parent_id === id) && v.language === this.calcLang;
      });

      // Display message template/language option doesn't exist
      this.noLanguage = !found;

      // Bubble
      if (found) {
        this.$emit("input", found);
      }
    },
    updateLanguage(v) {
      this.customLanguage = v;
      this.updateTemplate(this.customTemplateId);
    },
  },
  mounted() {
    this.$store.dispatch("EmailTemplateStore/getTemplates", true).catch((err) => this.$root.$error(err));
  },
};
</script>

<style lang="scss">
.select-wrapper {
  display: flex;
  align-items: center;

  .select-clear {
    margin-left: 10px;
  }
}
</style>
