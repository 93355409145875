// Index => days
import moment from "moment";
import MONTHS from "@/config/MONTHS";
const MONTH_MAP = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

export default {
  dateToObj,
  objToDate,

  formatDate,
  getWeekday,
  convertDate,
  isSameDay,
  addMultiDays,
  addDay,
  getNumberOfDays,
  listContains,

  compileDateListReadable,
  _chainString,

  formatTime,
};

function dateToObj(d) {
  // Converts date to year-month-day object
  // PARAMS
  // 	d		@{String} The date (Format MUST be: YYYY-MM-DD)
  // RETURNS @{Object}

  return {
    year: moment(d).year(),
    month: moment(d).month() + 1, // January will be 0, so add one
    day: moment(d).date(),
  };
}

function objToDate(obj) {
  // Converts year-month-day object
  // PARAMS
  // 	obj =>	@{Number} year
  // 			@{Number} month
  // 			@{Number} day
  // RETURN @{String} YYYY-MM-DD
  return convertDate(`${obj.year}-${obj.month}-${obj.day}`);
}

function formatDate(date, ignoreYear) {
  // Formats date to readable string
  // PARAMS
  // 	date 		@{String} Date string in YYYY-MM-DD
  // 	ignoreYear	@{Boolean} Whether to "skip" appending the year
  // RETURN @{String}
  if (!date) return null;

  // String format
  return ignoreYear ? moment(date).format("MMM D") : moment(date).format("MMM D, YYYY");
}

function getWeekday(date) {
  return moment(date).format("ddd");
}

function convertDate(date) {
  // Date Object to format we actually use...
  // PARAMS
  // 	date 	@{Any} Date
  // RETURN @{String}
  return moment(date).format("YYYY-MM-DD");
}

function isSameDay(a, b) {
  // Checks if two dates are the same
  // PARAMS
  // 	a 	@{String} Date as YYYY-MM-DD
  // 	b 	@{String} Date ditto
  // RETURN @{Boolean}
  return moment(a).format("L") === moment(b).format("L");
}

function addMultiDays(dateString, num) {
  // Adds multiple days to a date
  // PARAMS
  // 	dateString	@{String} Date as YYYY-MM-DD
  // 	num 		@{Number} How many day to add
  // RETURN @{String}

  return moment(dateString).add(num, "days").format("YYYY-MM-DD");
}

function addDay(dateString) {
  // Add one day to a date
  // PARAMS
  // 	dateString 	@{String} Date as YYYY-MM-DD
  // RETURN @{String}

  return moment(dateString).add(1, "days").format("YYYY-MM-DD");
}

function getNumberOfDays(from, to) {
  // Get number of days between the dates
  // EXCLUDES FIRST DAY, so: 31 - 1 = 30
  // Don't need to deal with time zones here, so use date object
  // PARAMS
  // 	from 	@{String} Date as YYYY-MM-DD
  // 	to 		@{String} Date as YYYY-MM-DD
  // RETURN @{Number}

  return moment(to).diff(moment(from), "days");
}

function listContains(list, date) {
  // Sometimes formatting is messed up
  // For example: 2020-1-10 in date, but 2020-01-10 in list
  // Fix that here
  for (var i = 0; i < list.length; i++) {
    if (isSameDay(list[i], date)) return true;
  }
  return false;
}

function compileDateListReadable(dateList, asNight) {
  // Converts day list into readable days with range
  // So [2012-05-01, 2012-05-02, 2012-05-03, 2012-05-05]
  // Becomes [[2012-05-01, 2012-05-02, 2012-05-03], [2012-05-05]]
  // Becomes May 1 to May 3, and May 5, 2012
  // PARAMS
  // 	dateList 	@{Array} SORTED list of dates
  //	asNight 	@{Boolean} Adds day at end
  // RETURNS 	@{String}
  if (!dateList || !dateList.length) return;
  if (dateList.length === 1 && !asNight) return formatDate(dateList[0]);
  if (dateList.length === 1 && asNight) {
    return `${formatDate(dateList[0], true)} to ${formatDate(addDay(dateList[0]), false)}`;
  }

  let current, isDayAfter;
  const list = dateList
    .reduce((arr, date) => {
      // Group dates by their ranges
      if (!arr.length) return [[date]];
      // Check if new date is day after previous
      current = arr[arr.length - 1];
      isDayAfter = isSameDay(addDay(current[current.length - 1]), date);
      if (isDayAfter) {
        // Push to last range
        current.push(date);
        return arr;
      }
      // Not the day after, add new
      return [...arr, [date]];
    }, [])
    .map((dateRange, index, orig) => {
      // Only display year if last
      let isLast = orig.length - 1 === index;
      let lastIndex = dateRange.length - 1;
      // Only ONE day (not range)
      if (dateRange.length === 1 && !asNight) return formatDate(dateRange[0], !isLast);
      if (asNight) {
        dateRange.push(addDay(dateRange[lastIndex]));
        lastIndex += 1;
      }

      // Range, only want first and last
      return `${formatDate(dateRange[0], true)} to ${formatDate(dateRange[lastIndex], !isLast)}`;
    }, "");

  return _chainString(list);
}

function _chainString(arr) {
  // converts array into chained string with: comma + oxford comma + and
  // PARAMS
  // 	arr @{Array}
  // RETURN @{String}
  if (!arr.length) return "";
  if (arr.length === 1) return arr[0];
  if (arr.length === 2) return arr.join(" and ");
  return `${arr.slice(0, arr.length - 1).join(", ")}, and ${arr[arr.length - 1]}`;
}

function formatTime(time) {
  // Formats military time to AM/PM
  // PARAMS
  // 	time 	@{String} Time
  // RETURNS @{String}
  let timeSplit = time.split(":");
  let hours = Number(timeSplit[0]);
  let minutes = Number(timeSplit[1]);
  let timeValue;

  if (hours > 0 && hours <= 12) {
    timeValue = "" + hours;
  } else if (hours > 12) {
    timeValue = "" + (hours - 12);
  } else if (hours == 0) {
    timeValue = "12";
  }

  timeValue += minutes < 10 ? ":0" + minutes : ":" + minutes; // get minutes
  timeValue += hours >= 12 ? " PM" : " AM"; // get AM/PM

  return timeValue;
}
