<template>
  <div class="d-flex">
    <v-autocomplete
      dense
      outlined
      hide-details
      item-text="label"
      item-value="value"
      :items="months"
      :disabled="disabled"
      :value="value"
      @input="(v) => $emit('input', v)"
    ></v-autocomplete>
    <v-btn v-show="value" icon x-small @click="$emit('input', null)">
      <v-icon small>clear</v-icon>
    </v-btn>
  </div>
</template>

<script>
import MONTHS from "@/config/MONTHS";
export default {
  props: {
    value: Number,
    disabled: Boolean,
  },
  data() {
    return {
      months: MONTHS,
    };
  },
};
</script>
