<template>
  <v-col>
    <v-row v-if="!altsuppliers.length" class="grey lighten-2 pa-3"> (No Alternates) </v-row>
    <v-row v-for="alt in altsuppliers" :key="alt.id">
      <v-col style="max-width: 90px">
        <g-button icon="swap_vert" label="Swap" @onclick="swapSupplierWithParent(alt.id)" />
        <!-- <v-btn small text @click="swapSupplierWithParent(parentBookingSupplierId)">
				<v-icon>swap_vert</v-icon>
				<span>Swap</span>
			</v-btn> -->
      </v-col>
      <v-col>
        <SupplierBlock
          style="border: 1px solid grey"
          class="px-2"
          :data="alt"
          :type="module"
          :date="focusDate"
          :bookingDay="bookingDay"
          :bookingSuppliers="suppliers"
          :bookingSupplierRoomList="bookingSupplierRoomList"
          :bookingMeta="bookingMeta"
          :pax="pax_num"
        />
      </v-col>
      <v-col cols="1">
        <v-btn small icon @click="removeBookingSupplier(alt.id)">
          <v-icon small>clear</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import format from "@/services/format";
import { SuppliersMenu } from "@/config/menus";
import { SupplierBlock } from "@/modules/bookings.days";
export default {
  props: {
    module: String,
    dateList: Array,
    parentBookingSupplierId: String,
  },
  components: {
    SupplierBlock,
  },
  data() {
    return {
      swappingInProgress: false,
    };
  },
  computed: {
    dbkey() {
      return SuppliersMenu.MOD_DBKEY[this.module];
    },
    focusDate() {
      return this.dateList ? this.dateList[0] : null;
    },
    altsuppliers() {
      if (!this.focusDate) return [];

      const suppliers = this.$store.getters["BookingDayStore/suppliers"];
      const daySupplierFlat = this.$store.getters["BookingDayStore/dayByDate"](this.focusDate);

      return daySupplierFlat[this.dbkey]
        .filter((bsid) => {
          // Get children of current supplier
          return this.suppliers[bsid].parent_bsid === this.parentBookingSupplierId;
        })
        .map((bsid) => {
          return this.suppliers[bsid];
        });
    },

    // ----------
    // DISPLAY STUFF
    // ----------
    bookingDay() {
      const dayList = this.$store.getters["BookingDayStore/days"];
      return dayList.find((d) => d.date === this.focusDate);
    },
    bookingMeta() {
      return this.$store.getters["BookingDayStore/data"];
    },
    suppliers() {
      return this.$store.getters["BookingDayStore/suppliers"];
    },
    bookingSupplierRoomList() {
      return this.$store.getters["BookingSupplierRoomStore/supplier_rooms"];
    },
    pax_num() {
      // Get CURRENT Pax
      return this.$store.getters["BookingStore/pax_num"];
    },
  },
  methods: {
    swapSupplierWithParent(bsid) {
      this.swappingInProgress = true;
      this.$root.$loading.open("Swapping supplier");
      this.$store
        .dispatch("BookingDayStore/swapParentAndChildSupplier", {
          bookingId: this.$store.getters["BookingStore/booking_id"],
          childid: bsid,
          parentid: this.parentBookingSupplierId,
        })
        .then((v) => {
          this.swappingInProgress = false;
          this.$root.$success("Suppliers switched");
          this.$root.$loading.end();
        })
        .catch((err) => {
          this.swappingInProgress = false;
          this.$root.$error(err);
        });
    },
    removeBookingSupplier(bsid) {
      this.$root.$loading.open("Removing supplier");
      this.$store
        .dispatch("BookingDayStore/removeAllOfBookingSupplier", {
          data: {
            bookingSupplierId: bsid,
            bookingId: this.$store.getters["BookingStore/booking_id"],
            supplierId: this.suppliers[bsid].supplier_id,
            dbkey: this.dbkey,
          },
        })
        .then((v) => {
          this.$emit("popnest", bsid);
          this.$root.$success("Removed Supplier");
          this.$root.$loading.end();
        })
        .catch((err) => {
          this.$root.$error(err);
        });
    },
  },
};
</script>
