<template>
  <a class="docdownload" target="_blank" :href="hostName + image.src" style="display: inline-block">
    <v-menu open-on-hover offset-y>
      <template v-slot:activator="{ on }">
        <div v-on="on">
          <a-card nopad class="imageview">
            <v-img
              :src="hostName + image.src"
              :lazy-src="hostName + image.src"
              aspect-ratio="1"
              height="50"
              width="50"
              class="grey lighten-2"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </a-card>
        </div>
      </template>

      <div class="pa-2 white img-hover">
        <img :src="hostName + image.src" style="max-width: 500px" />
      </div>
    </v-menu>
  </a>
</template>

<script>
export default {
  props: {
    image: Object,
  },
  computed: {
    hostName() {
      return this.$store.getters["host"];
    },
  },
};
</script>
