<template>
  <div>
    <a-hover-menu>
      <template v-slot:activator>
        <v-btn small icon :loading="loading" @click="toggleRoomingReceived">
          <v-icon v-if="roomingReceived" small color="green">fact_check</v-icon>
          <v-icon v-else small color="grey lighten-1">list_alt</v-icon>
        </v-btn>
      </template>

      <v-col class="mx-0 caption">
        <span v-if="roomingReceived">Rooming List Received</span>
        <span v-else>(Rooming List Not Recevied)</span>
      </v-col>
    </a-hover-menu>
  </div>
</template>

<script>
export default {
  props: {
    outside: Boolean,
    bookingSupplierId: String,
    roomingReceived: [Boolean, Number],
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    toggleRoomingReceived(e) {
      e.stopPropagation();
      this.loading = true;
      this.$store
        .dispatch("BookingDayStore/toggleRoomingReceived", {
          bookingSupplierId: this.bookingSupplierId,
          value: !this.roomingReceived,
          outside: this.outside,
        })
        .then((v) => {
          this.loading = false;
          if (this.outside) this.$emit("toggleRoomReceived");
        });
    },
  },
};
</script>
