<template>
  <tr
    class="database-row color-alt canclick"
    :class="{ 'primary--text blue lighten-4': hasAllContactsSelected }"
    @click="toggleSelect"
  >
    <td class="d-flex align-start pt-2" width="30">
      <v-icon small v-if="!supplier.contacts.length">clear</v-icon>
      <input v-else type="checkbox" :checked="hasAllContactsSelected" />
    </td>
    <td class="pt-1">
      <v-row class="d-flex justify-space-between">
        <v-col class="d-flex align-start justify-space-between" style="min-width: 400px; max-width: 400px">
          <div class="d-flex align-center">
            <a-truncate :text="supplier.name" :hoverText="supplier.name" />
            <HiddenContactHover v-if="supplier.hidden" :hidden="supplier.hidden" />
          </div>

          <div>
            <v-tooltip v-for="(bleep, index) in statusDisplay" :key="'bleep' + index" left>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <span v-if="bleep.isAlternate" class="caption pr-1">ALT/</span>

                  <v-icon x-small :class="bleep.color">brightness_1</v-icon>
                </div>
              </template>
              <span>{{ bleep.label }}</span>
            </v-tooltip>
          </div>
        </v-col>

        <v-col class="d-flex justify-end">
          <HotelDateRoomInfo
            v-if="showHotelInfo"
            :statusDisplay="statusDisplay"
            :bsid="supplier.bsid"
            :bookingSuppliers="bookingSuppliers"
            :bookingDays="bookingDays"
            :roomBreakdownData="roomBreakdownData"
            @ping="(v) => $emit('setEqualRooms', { supplier_id: supplier.supplier_id, value: v })"
          />

          <RestaurantInfo
            v-if="showRestaurantInfo"
            :statusDisplay="statusDisplay"
            :bsid="supplier.bsid"
            :bookingSuppliers="bookingSuppliers"
            :bookingDays="bookingDays"
            :productList="productOptions"
            :pax="pax"
            :supplierMeta="supplier.supplier_meta"
          />

          <ExcursionInfo
            v-if="showExcursion"
            :statusDisplay="statusDisplay"
            :bsid="supplier.bsid"
            :bookingSuppliers="bookingSuppliers"
            :pax="pax"
          />

          <OtherInfo v-if="showOther" :statusDisplay="statusDisplay" :productList="productOptions" />
        </v-col>

        <v-col style="max-width: 50px" class="d-flex justify-end">
          <v-btn x-small icon @click="hideProducts = !hideProducts">
            <v-icon x-small>{{ hideProducts ? "visibility_off" : "visibility" }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <div v-if="!hideProducts">
        <v-row justify="space-between">
          <span class="subtitle-2">Products: </span>
          <ToggleProductOptions
            :compileType="compileType"
            :hasMultipleProducts="hasMultipleProducts"
            @setProductOption="toggleProductOption"
          />
        </v-row>
        <ProductViewRow
          v-for="product in productOptions"
          :key="product.id"
          :product="product"
          :pax="pax"
          :ignoreProducts="ignoreProducts"
          @updateIgnoreProduct="(v) => $emit('updateIgnoreProduct', v)"
        />

        <span class="subtitle-2">Contacts: </span>
      </div>

      <div>
        <slot :contacts="supplier.contacts"></slot>
      </div>
    </td>
  </tr>
</template>

<script>
import Vue from "vue";
import { SuppliersMenu } from "@/config/menus";
import BookingEmailService from "../../service";
import { EmailTemplateService } from "@/modules/admin.email_template";
import ToggleProductOptions from "./_ToggleProductOptions";
import ProductViewRow from "./_ProductViewRow";
import HiddenContactHover from "./_HiddenContactHover";
import HotelDateRoomInfo from "./_HotelDateRoomInfo";
import RestaurantInfo from "./_RestaurantInfo";
import ExcursionInfo from "./_ExcursionInfo";
import OtherInfo from "./_OtherInfo";
import STATUS_COLORS from "@/config/STATUS_COLORS";

const COLORS = STATUS_COLORS.COLORS;

export default {
  props: {
    maptype: String,
    supplier: Object,
    selectedContacts: Object,
    bookingSuppliers: Object,
    bookingDays: Array,
    pax: [String, Number],

    roomBreakdownData: Object,

    compileType: String,

    ignoreProducts: Object,
  },
  components: {
    ToggleProductOptions,
    ProductViewRow,
    HotelDateRoomInfo,
    RestaurantInfo,
    ExcursionInfo,
    OtherInfo,
    HiddenContactHover,
  },
  data() {
    return {
      selectAll: false,
      loading: false,
      supplierList: [],

      hideProducts: true,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Name",
          value: "name",
        },
      ];
    },

    statusDisplay() {
      let temp = null;
      return this.supplier.bsid
        .map((bsid) => {
          temp = this.bookingSuppliers[bsid];
          return {
            bsid: bsid,
            status: temp.status,
            time: temp.time,
            product_names: Object.values(temp.products)
              .map((p) => p.name)
              .join(", "),
            isAlternate: this.bookingSuppliers[bsid].nest_type === "ALT",
            firstDay: this.bookingDays.find((item) => {
              return item[SuppliersMenu.MOD_DBKEY[temp.type_as]].find((id) => bsid === id);
            })?.date,
          };
        })
        .sort((a, b) => {
          if (a.firstDay > b.firstDay) return 1;
          if (a.firstDay < b.firstDay) return -1;
          return 0;
        })
        .map((bs) => {
          return { ...bs, ...Object.values(COLORS).find((item) => item.value === bs.status) };
        });
    },

    hasMultipleProducts() {
      if (this.supplier.bsid.length > 1) return true;
      if (this.productOptions.length > 1) return true;
      return false;
    },
    hasAllContactsSelected() {
      // No valid contacts, can't select
      if (!this.supplier.contacts.length) return false;

      let allSelected = true;
      this.supplier.contacts.forEach((contact) => {
        if (!this.selectedContacts) return (allSelected = false);
        if (!this.selectedContacts[contact.id]) allSelected = false;
      });
      return allSelected;
    },

    productOptions() {
      const vm = this;
      let bsRef,
        customProductName,
        customCount,
        dump = [];
      this.supplier.bsid.forEach((bsid) => {
        bsRef = this.bookingSuppliers[bsid];

        Object.values(bsRef.products).forEach((product) => {
          customProductName = bsRef.meta && bsRef.meta.custom_names && bsRef.meta.custom_names[product.id];
          customCount = bsRef.meta && bsRef.meta.count && bsRef.meta.count[product.id];

          dump.push({
            ref_id: `${bsid}_${product.id}`,
            product_id: product.id,
            product_type: product.product_type,
            name: customProductName || product.name,
            count: customCount || 1,
            dates: EmailTemplateService._compileDateListReadable(vm.getWhatDays(bsid), product.product_type === "ROOM"),
          });
        });
      });
      return dump;
    },

    showHotelInfo() {
      return this.maptype === "HOTEL" || this.supplier.hotelType;
    },
    showRestaurantInfo() {
      return (this.maptype === "RESTAURANT" || this.supplier.restaurantType) && !this.showHotelInfo;
    },
    showExcursion() {
      return this.maptype === "EXCURSION" || this.supplier.excursionType;
    },
    showOther() {
      return !this.showHotelInfo && !this.showRestaurantInfo && !this.showExcursion;
    },
  },
  methods: {
    toggleProductOption(groupping) {
      this.$emit("setGroupping", {
        supplier_id: this.supplier.supplier_id,
        supplier: this.supplier,
        groupping: groupping,
      });
    },

    toggleSelect(e) {
      e.stopPropagation();

      this.$emit("onclick", {
        supplier: this.supplier,
        allSelected: this.hasAllContactsSelected,
      });
    },

    getWhatDays(bookingSupplierId) {
      // What days supplier appears
      let dateList = [];
      this.bookingDays.forEach((item) => {
        SuppliersMenu.FULL.forEach((mod) => {
          // Only one module
          if (item[mod.alt] && item[mod.alt].includes(bookingSupplierId)) dateList.push(item.date);
        });
      });
      return dateList;
    },
  },
};
</script>
