<template>
  <a-card nopad>
    <v-row align="center" justify="space-between" class="px-1 flex-nowrap">
      <ConfirmField label="Group Name" :value="group_name" @input="(v) => updateGroupName(v)" />

      <a-group class="px-2 py-2" label="City" icon="location_city">
        <elwrapper label="Start" smlabel>
          <autocomplete-string-free-text :items="this.cities" v-model="where_start" />
        </elwrapper>
        <elwrapper label="End" smlabel>
          <autocomplete-string-free-text :items="this.cities" v-model="where_end" />
        </elwrapper>
      </a-group>

      <DateConfirmField
        :value="bookingDates"
        @revert="revertBookingDays"
        @update="updateBookingDays"
        @copy="copyBooking"
        @duplicate="duplicateBooking"
        :datewall="today"
      />
    </v-row>

    <BookingExistsDialog :recommend="recommendedName" v-model="dialog" />
  </a-card>
</template>

<script>
import ConfirmField from "./confirmChangeField/confirmField";
import DateConfirmField from "./confirmChangeField/DateConfirmField";
import BookingExistsDialog from "../dialogs/BookingExistsDialog";
import format from "@/services/format";
export default {
  props: {
    bookingId: String,
  },
  components: {
    ConfirmField,
    BookingExistsDialog,
    DateConfirmField,
  },
  data() {
    return {
      today: format.convertDate(new Date()),
      dialog: false,
      recommendedName: "",
      cities: [],
    };
  },
  computed: {
    client: {
      get() {
        var a = this.$store.getters["BookingStore/active"];
        return { id: a.client_id, name: a.client_name, invoiceType: a.client_invoice_type };
      },
    },
    group_name: {
      get() {
        return this.get("group_name");
      },
      set(newValue) {
        return this.set("group_name", newValue);
      },
    },
    where_start: {
      get() {
        return this.get("where_start");
      },
      set(newValue) {
        return this.set("where_start", newValue);
      },
    },
    where_end: {
      get() {
        return this.get("where_end");
      },
      set(newValue) {
        return this.set("where_end", newValue);
      },
    },
    from: {
      get() {
        return this.get("from");
      },
      set(newValue) {
        return this.set("from", newValue);
      },
    },
    to: {
      get() {
        return this.get("to");
      },
      set(newValue) {
        return this.set("to", newValue);
      },
    },

    bookingDates() {
      return {
        from: this.from,
        to: this.to,
      };
    },
  },
  methods: {
    get(val) {
      return this.$store.getters["BookingStore/active"][val];
    },
    set(key, val) {
      return this.$store.dispatch("BookingStore/update", { key, val });
    },
    updateGroupName(v) {
      // Trying to set group name to itself
      // Just skip everything and show update message
      if (this.group_name === v) {
        this.$root.$success("Updated Booking Client");

        return Promise.resolve();
      }

      // Need to confirm group name is allowed before saving
      return this.$store
        .dispatch("BookingStore/confirmCanAddBooking", {
          client_id: this.client.id,
          group_name: v,
        })
        .then((e) => {
          if (!e.exists) return this.update("group_name", v);

          // If exists PROMPT DIFFERENT ACTION
          this.dialog = true;
          this.recommendedName = e.recommend;
        })
        .catch((err) => this.$root.$error(err));
    },

    update(name, v) {
      this.$root.$loading.open("Updating booking " + name);
      return this.$store
        .dispatch("BookingStore/updateBookingMajor", { key: name, val: v })
        .then((x) => this._specialSaving(name, v))
        .then((x) => {
          this.$root.$success("Updated Booking Client");
          if (this.client.invoiceType === "FULL")
            this.$root.$warn(
              "Updated Booking Client, The final invoice has already been sent to the client. Please make sure to send the invoice again with the latest information."
            );
          this.$root.$loading.end();
        })
        .catch((err) => this.$root.$error(err));
    },

    _specialSaving(name, v) {
      if (["where_start", "where_end"].includes(name)) return this.__updateFlights(name, v);
      return Promise.resolve();
    },
    __updateFlights(name, v) {
      if (!(name == "where_start" || name === "where_end")) return Promise.resolve();

      // Update flights if necessary
      this.$store.dispatch("BookingFlightStore/update", {
        deep: name === "where_start" ? "arrival" : "departure",
        key: "airport",
        val: v,
      });
      return this.$store.dispatch("BookingFlightStore/saveFlights", this.bookingId);
    },

    updateBookingDays(dateInfo) {
      this.$root.$loading.open("Updating booking dates");

      Promise.all([this.$store.dispatch("BookingStore/updateBookingDates", dateInfo), this.updateFlight(dateInfo)])
        .then((v) => {
          // Update day range
          return this.$store.dispatch("BookingDayStore/getDays", this.bookingId);
        })
        .then((v) => {
          this.$root.$loading.end();
          this.$router.push({ name: "bookings" });
          this.$root.$success("Updating Booking Dates");
          if (this.client.invoiceType === "FULL")
            this.$root.$warn(
              "Updated Booking Dates, The final invoice has already been sent to the client. Please make sure to send the invoice again with the latest information."
            );
        })
        .catch((err) => {
          // console.log(err)
          this.$root.$error(err);
        });
    },
    updateFlight(dateInfo) {
      // // UPDATE FLight info
      this.$store.dispatch("BookingFlightStore/update", {
        deep: "arrival",
        key: "date",
        val: dateInfo.nuFrom,
      });
      this.$store.dispatch("BookingFlightStore/update", {
        deep: "departure",
        key: "date",
        val: dateInfo.nuTo,
      });

      return this.$store.dispatch("BookingFlightStore/saveFlights", this.bookingId);
    },

    revertBookingDays() {
      this.$root.$loading.open("Reverting booking dates");

      this.$store
        .dispatch("BookingStore/revertBookingDates")
        .then((oldDates) => {
          return Promise.all([
            this.updateFlight(oldDates),
            this.$store.dispatch("BookingDayStore/getDays", this.bookingId),
          ]);
        })
        .then((v) => {
          this.$root.$loading.end();
          this.$router.push({ name: "bookings" });
          this.$root.$success("Updating Booking Dates");
          if (this.client.invoiceType === "FULL")
            this.$root.$warn(
              "Updated Booking Dates, The final invoice has already been sent to the client. Please make sure to send the invoice again with the latest information."
            );
        })
        .catch((err) => {
          this.$root.$error(err);
        });
    },
    copyBooking(dateInfo) {
      const copyName = this.group_name;

      this.$root.$loading.open(`Copying ${this.group_name}...`);
      this.$store
        .dispatch("BookingStore/copyBooking", {
          booking_id: this.bookingId,
          dates: dateInfo,
        }) //.then(nuBookingId =>  this._switchToNewBooking(nuBookingId, copyName) )
        .then((v) => {
          this.$root.$loading.end();
          this.$root.$success(`Copied: ${copyName}`);
          this.$router.push({ name: "bookings" });
        })
        .catch((err) => this.$root.$error(err));
    },
    duplicateBooking(dateInfo) {
      const copyName = this.group_name;

      this.$root.$loading.open(`Copying ${this.group_name}...`);
      this.$store
        .dispatch("BookingStore/duplicateBooking", {
          booking_id: this.bookingId,
          dates: dateInfo,
        }) //.then(nuBookingId =>  this._switchToNewBooking(nuBookingId, copyName) )
        .then((v) => {
          this.$root.$loading.end();
          this.$root.$success(`Copied: ${copyName}`);
          this.$router.push({ name: "bookings" });
        })
        .catch((err) => this.$root.$error(err));
    },
    // -- CAUSING COPY & DUPLICATING ERRORS???
    // _switchToNewBooking(nuBookingId, copyName){
    // 	// Updating booking route
    // 	this.$router.push({
    // 		name: 'bookings_view',
    // 		params: {
    // 			booking_id: nuBookingId
    // 		}
    // 	})

    // 	return this.$store.dispatch('BookingStore/getOneBooking', nuBookingId)
    // 	.then(data => {
    // 		// Insert into other stores
    // 		this.$store.dispatch('BookingFlightStore/setFlights', data.flights || {})
    // 		this.$store.dispatch('BookingRoomStore/setRooms', data.rooms || [])
    // 		this.$roomingPing.$emit('sync')
    // 		this.$store.dispatch('BookingDayStore/setDays', {
    // 			days: data.days || [],
    // 			suppliers: data.suppliers || {}
    // 		})
    // 	}).then(() => {
    // 		this.$root.$success(`Copied: ${copyName}`)
    // 		this.$root.$loading.end();
    // 	})
    // }
    _querySuppliers() {
      return this.$store.dispatch("DatabaseStore/querySuppliers", { type: "ALL" }).then((data) => {
        this.cities = data.map((item) => item.city).filter((city) => city !== null && city !== undefined);
      });
    },
  },
  mounted() {
    this._querySuppliers();
  },
};
</script>
