<template>
  <v-toolbar id="supheader" dense flat>
    <v-toolbar-title>
      <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
    </v-toolbar-title>

    <template v-slot:extension>
      <ClientTabs :clientId="clientId" />
    </template>
  </v-toolbar>
</template>

<script>
import ClientTabs from "./_ClientTabs";

export default {
  components: {
    ClientTabs,
  },
  props: {
    clientId: String,
    name: String,
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: "Database",
          to: { name: "database" },
        },
        {
          text: `Clients`,
          to: { name: "database_view", params: { view: "clients", sub: "ALL" } },
        },
        {
          text: this.name,
        },
      ];
    },
  },
};
</script>
