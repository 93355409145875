<template>
  <div>
    <BookingEmailHeader />
    <NoEmailAlert />

    <v-container class="pa-0" fluid>
      <v-col style="height: 100%; overflow: auto">
        <router-view></router-view>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import { NoEmailAlert } from "@/modules/email";
import { BookingEmailHeader } from "@/modules/bookings.email";
export default {
  components: {
    BookingEmailHeader,
    NoEmailAlert,
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
};
</script>

<style lang="scss"></style>
