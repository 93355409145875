<template>
  <v-row class="hover-cyoa">
    <v-col class="pa-0">
      <slot></slot>
    </v-col>
    <v-col cols="1" align-self="center" class="clearbtn-wrapper">
      <v-btn icon x-small @click="$emit('clear')" class="clearbtn">
        <v-icon small>clear</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>

<style lang="scss">
.hover-cyoa {
  .clearbtn-wrapper {
    display: none;
  }

  &:hover .clearbtn-wrapper {
    display: block;
  }
}
</style>
