<template>
  <v-menu open-on-hover offset-y>
    <template v-slot:activator="{ on }">
      <v-btn text icon small v-on="on">
        <v-icon>arrow_drop_down_circle</v-icon>
      </v-btn>
    </template>

    <v-list class="py-0">
      <v-list-item v-for="(item, index) in menu" :key="index" :to="item.to" class="drop-item pt-1">
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>

      <v-divider></v-divider>
      <v-list-item class="drop-item" @click="$store.dispatch('logout')">
        <v-list-item-icon>
          <v-icon>exit_to_app</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Log out</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <div class="overline d-flex justify-center grey lighten-2">VERSION {{ appVersion }}</div>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      menu: [
        {
          title: "Account",
          icon: "account_circle",
          to: { name: "account" },
        },
      ],
    };
  },
  computed: {
    appVersion() {
      return this.$store.getters.appVersion;
    },
  },
};
</script>

<style lang="scss">
.drop-item {
  min-height: 35px !important;
  max-height: 35px !important;

  .v-list-item__icon {
    margin: 2px 0;
  }
}
</style>
