<template>
  <div class="d-flex align-start caption justify-space-between" style="width: 100%">
    <div class="d-flex">
      <div class="px-2" style="display: flex; align-items: start; min-width: 210px; max-width: 210px">
        <v-icon small left>event</v-icon>
        <div>
          <a-truncate v-for="days in restaurantDays" :key="days" :text="days" />
        </div>
      </div>
      <div class="px-2" style="display: flex; align-items: start">
        <v-icon small left>restaurant_menu</v-icon>
        <div>
          <a-truncate v-for="(p, index) in statusDisplay" :key="'prod' + index" :text="p.names" />
        </div>
      </div>
    </div>

    <div class="d-flex">
      <div class="px-2" style="display: flex; align-items: start; min-width: 80px; max-width: 80px">
        <v-icon small left>person</v-icon>
        <div>
          <div v-for="count in displayExtra" :key="count.date">{{ count.count }}</div>
        </div>
      </div>
      <div style="display: flex; min-width: 90px; max-width: 90px; align-items: start">
        <v-icon v-show="statusDisplay.length" small left>timer</v-icon>
        <div>
          <div v-for="(time, index) in statusDisplay" :key="'time' + index">{{ time.time }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import format from "@/services/format";
import service from "../../service";
export default {
  props: {
    bsid: Array,
    bookingSuppliers: Object,
    bookingDays: Array,

    pax: [String, Number],
    statusDisplay: Array,
    productList: Array,
    supplierMeta: Object,
  },
  computed: {
    relevantBookingSupplier() {
      return Object.keys(this.bookingSuppliers).reduce((obj, key) => {
        if (this.bsid.includes(key) && this.bookingSuppliers[key].type_as === "RESTAURANT") {
          obj[key] = this.bookingSuppliers[key];
        }
        return obj;
      }, {});
    },
    restaurantDays() {
      // Extract Dates
      let bsidDateMapping = this.bookingDays.reduce((obj, day) => {
        this.bsid.forEach((bsid) => {
          if (day.restaurants.includes(bsid)) {
            if (!obj[bsid]) obj[bsid] = [];
            obj[bsid].push(day.date);
          }
        });
        return obj;
      }, {});

      // Format dates
      Object.keys(bsidDateMapping).forEach((key) => {
        bsidDateMapping[key] = format.compileDateListReadable(bsidDateMapping[key], false);
      });

      // Stack based on blip order
      return this.statusDisplay.map((item) => {
        return bsidDateMapping[item.bsid] || format.formatDate(item.firstDay);
      });
    },
    timeList() {
      return Object.values(this.relevantBookingSupplier)
        .filter((v) => v.time)
        .map((v) => format.formatTime(v.time));
    },
    product() {
      return this.productList.map((prod) => prod.name);
    },

    displayExtra() {
      // Only show FIRST?
      return Object.values(this.relevantBookingSupplier)
        .map((v) => {
          const bsmeta = v.meta || {};
          const count = (bsmeta.count && (Object.values(bsmeta.count)[0] || this.pax)) || this.pax;
          const show = bsmeta && bsmeta.exclude_suppliers;
          const breakdownCount = service.getRestaurantSupplierCount(
            this.bookingDays,
            this.bookingSuppliers,
            [v.id],
            show
          );

          // Display FIRST entry
          const first = (breakdownCount && Object.values(breakdownCount)[0]) || 0;

          return {
            date: this.bookingDays.find((day) => {
              return day.restaurants.includes(v.id);
            }).date,
            count: count + (first ? ` + ${first}` : ""),
          };
        })
        .sort((a, b) => {
          if (a.date > b.date) return 1;
          if (a.date < b.date) return -1;
          return 0;
        });
    },
  },
};
</script>
