<template>
  <a-hover-menu class="overline mx-2">
    <template v-slot:activator>
      <v-btn small icon>
        <v-icon small>warning</v-icon>
      </v-btn>
    </template>

    <v-row class="mx-0 caption"> (Hiding {{ hidden }} contacts. No email.) </v-row>
  </a-hover-menu>
</template>

<script>
export default {
  props: {
    hidden: [String, Number],
  },
};
</script>
