<template>
  <div style="width: 100%">
    <div>
      <div v-if="!dependant || dependantValue" class="select-wrapper">
        <v-autocomplete
          dense
          outlined
          hide-details
          :return-object="returnObject"
          :chips="multiple"
          :multiple="multiple"
          item-text="label"
          item-value="value"
          :items="itemsList"
          :value="value"
          :style="style"
          :disabled="disabled"
          @keydown.native="update"
          @input="(v) => $emit('input', v)"
          @change="(v) => $emit('change', v)"
        ></v-autocomplete>
        <v-btn
          v-show="showClear"
          @click="$emit('input', initType)"
          x-small
          icon
          :disabled="disabled"
          class="select-clear"
        >
          <v-icon>clear</v-icon>
        </v-btn>
      </div>
      <span v-else class="font-italic caption"> ({{ dependantText }}) </span>
    </div>
    <div v-if="hint" class="overline">
      {{ hint }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dependant: Boolean,
    dependantValue: [String, Object],
    dependantText: String,

    multiple: Boolean,
    freeopt: Boolean,
    items: Array,
    value: {
      type: [String, Array, Number],
      //required: true
    },

    maxwidth: String,
    disabled: Boolean,
    returnObject: Boolean,

    hint: String,
    hideClear: Boolean,
  },
  data() {
    return {
      userinput: "",
    };
  },
  computed: {
    initType() {
      if (typeof this.value === "object") return [];
      if (typeof this.value === "number") return 0;
      return "";
    },
    itemsList() {
      if (this.freeopt && this.userinput) {
        // Adds the user's input as a valid option
        return [
          {
            label: this.userinput,
            value: this.userinput,
          },
          ...this.items,
        ];
      }

      return this.items;
    },
    showClear() {
      if (this.hideClear) return false;
      if (this.value === undefined || this.value === null) return false;
      return true;
    },
    style() {
      var total = "";
      if (this.maxwidth) total += `width:${this.maxwidth};margin-right:20px`;
      return total;
    },
  },
  methods: {
    update(e) {
      const value = document.getElementById(e.target.id).value;
      this.userinput = e.key === "Backspace" ? value.slice(0, -1) : value + e.key;
    },
  },
};
</script>

<style lang="scss">
.select-wrapper {
  display: flex;
  align-items: center;

  .select-clear {
    margin-left: 10px;
  }
}
</style>
