export default {
  accounts: "Accounts",

  booking_contracts: "Booking (Contracts)",
  booking_days: "Booking",
  booking_flights: "Booking (Flights)",
  booking_revert: "Revert Bookings",
  booking_rooms: "Booking (Rooming)",
  booking_suppliers: "Booking",
  booking_technical: "Booking (Technical)",
  bookings: "Bookings",

  client_contacts: "Client (Contacts)",
  client_images: "Client (Images)",
  client_invoices: "Invoice (Client)",
  client_payment_history: "Payment",
  clients: "Clients",

  email_templates: "Email Templates",
  finance_documents: "Report (Documents)",
  products: "Products",
  received_commisions: "Commissions",
  reminders: "Reminders",

  supplier_contacts: "Supplier (Contacts)",
  supplier_documents: "Supplier (Documents)",
  supplier_history: "Supplier (Notes)",
  supplier_images: "Supplier (Images)",
  supplier_invoices: "Invoice (Supplier)",
  suppliers: "Suppliers",
};
