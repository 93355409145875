<template>
  <v-row class="caption font-italic">
    <span>(</span>
    <currency-input
      ref="currency"
      style="width: 100%"
      :value="convertedValue"
      currency="USD"
      class="currency-input"
      :class="{ 'white--text': white }"
    />
    <span class="pl-1">{{ this.currencyText }})</span>
  </v-row>
</template>

<script>
import moneyService from "@/services/money";
export default {
  props: {
    number: [String, Number],
    env: String,
    white: Boolean,
  },
  computed: {
    supplierEnv() {
      return this.$store.getters["ProductStore/supplier_env"] || this.$store.getters["SupplierStore/env"];
    },
    convertedValue() {
      if (this.supplierEnv == "USA" && this.env != "USA") {
        return moneyService.getConvertToForeign(this.number, this.env, this.$store.getters);
      }
      return moneyService.getConvertedValue(this.number, this.supplierEnv, this.$store.getters);
    },
    currencyText() {
      if (this.supplierEnv == "USA" && this.env != "USA") {
        return moneyService.getCurrencySource(this.env)["currency"];
      }

      return "USD";
    },
  },
};
</script>

<style lang="scss">
.currency-input {
  text-align: right;
  max-width: 70px;
  pointer-events: none;
}
</style>
