<template>
  <div class="black--text">
    <div class="table py-5">
      <v-row>
        <v-col style="max-width: 60px"><!-- Spacer --></v-col>
        <v-col style="max-width: 110px">Date</v-col>
        <v-col style="max-width: 110px"><!-- Spacer --></v-col>

        <v-col> Supplier Rooms </v-col>
      </v-row>

      <v-row v-for="(day, index) in formatedDayList" :key="day.rawDate">
        <v-col style="max-width: 60px">
          <span>{{ day.day }}</span>
        </v-col>
        <v-col class="black--text font-weight-bold" style="max-width: 110px">
          <span>{{ day.date }}</span>
        </v-col>

        <v-col style="max-width: 110px">
          <v-btn text small @click="addSupplierRoom(day.rawDate)">
            <v-icon x-small>add</v-icon>
            <span>Add</span>
          </v-btn>
        </v-col>

        <RoomSelectBlock
          class="product-select-row mx-2"
          v-for="room in supplierRooms"
          :key="room.id"
          :mode="mode"
          :module="module"
          :disabled="disabled"
          :selectedBsid="selectedRow"
          :dateList="dateList"
          :highlightedRows="highlightedRows"
          @set="(v) => (highlightedRows = v)"
          @mark="(v) => (selectedRow = v)"
          :focusDate="day.rawDate"
          :focusRoomId="room.id"
          :supplierId="supplierId"
          @onedit="openEdit"
        />
      </v-row>
    </div>

    <SupplierNameDialog v-model="editNameDialog" :editingSupplierRoom="editingSupplierRoom" />

    <div>
      <v-row class="button-row" justify="space-between">
        <g-button label="Close" icon="clear" @onclick="$emit('close')" />

        <div>
          <g-button label="Back" icon="keyboard_arrow_left" @onclick="$emit('revert')" />
          <g-button type="primary" label="Next" @onclick="$emit('next')" />
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
import RoomSelectBlock from "./_RoomSelectBlock";
import { SupplierNameDialog } from "@/modules/bookings.supplier_rooms";
export default {
  props: {
    mode: String,
    disabled: Boolean,

    module: String,
    supplierId: String,
  },
  components: {
    RoomSelectBlock,
    SupplierNameDialog,
  },
  data() {
    return {
      editNameDialog: false,
      editingSupplierRoom: {},

      highlightedRows: {},
      selectedRow: null,
    };
  },
  computed: {
    dateList() {
      return this.$store.getters["BookingDayStore/whatDaysBySupplierId"](this.module, this.supplierId);
    },
    formatedDayList() {
      const list = this.$store.getters["BookingDayStore/dayLinkList"]();
      return list
        .filter((item, index) => {
          return this.dateList.includes(item.rawDate);
        })
        .map((day) => {
          return {
            ...day,

            srooms: this.daySupplierRoomRef[day.rawDate],
          };
        });
    },
    daySupplierRoomRef() {
      return this.$store.getters["BookingDayStore/daySupplierRoomRef"];
    },
    supplierRooms() {
      return this.$store.getters["BookingSupplierRoomStore/supplier_rooms"].filter(
        (sroom) => sroom.supplier_id === this.supplierId
      );
    },
  },
  methods: {
    addSupplierRoom(date) {
      const booking_id = this.$store.getters["BookingStore/booking_id"];
      let firstName, lastName;
      if (this.module === "TRANSPORT") {
        firstName = "Bus";
        lastName = `Driver ${this.supplierRooms.length + 1}`;
      } else if (this.module === "TOUR_GUIDE") {
        const focus = Object.values(this.$store.getters["BookingDayStore/suppliers"]).find(
          (s) => s.supplier_id === this.supplierId
        );
        const supplierName = _getNameDefault(focus.name);
        firstName = supplierName.first;
        lastName = supplierName.last;
      }

      this.$store
        .dispatch("BookingSupplierRoomStore/addSupplierRoom", {
          ...(this.mode === "BOOKING" && { booking_id }),
          supplierRoomData: {
            supplier_id: this.supplierId,
            type_as: this.module,
            first_name: firstName,
            last_name: lastName,
          },
        })
        .then((sroom) => {
          return this.$store.dispatch("BookingDayStore/addSupplierRoomToDate", {
            booking_id: booking_id,
            supplierRoomIdList: [sroom.id],
            dateList: [date],
          });
        })
        .then(() => {
          this.$roomingPing.$emit("sync");
          this.$root.$success("Added Supplier Room");
        })
        .catch((err) => {
          this.$root.$error(err);
        });
    },
    openEdit(sroom) {
      this.editNameDialog = true;
      this.editingSupplierRoom = sroom;
    },
  },
};

function _getNameDefault(name) {
  const cut = name.split(" ");
  const first = cut[0];
  cut.shift();
  const last = cut.join(" ");

  return {
    first,
    last,
  };
}
</script>
