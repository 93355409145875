import axios from "@/plugins/axios";

export default {
  getSupplierProducts(supplier_id) {
    return axios.get(`/suppliers/${supplier_id}/products`);
  },
  getProduct(id) {
    return axios.get(`/products/${id}`);
  },
  copyOneProduct(id) {
    return axios.post(`/products/${id}/copy`);
  },
  copyProducts(to_supplier_id, from_supplier_id) {
    return axios.post(`/suppliers/${to_supplier_id}/products/copy`, {
      copy_from: from_supplier_id,
    });
  },

  updateProduct(id, data, cache) {
    return axios.post(`/products/${id}`, { data, cache });
  },
  updateProductRate(id, year, data, cache) {
    return axios.post(`/products/${id}/rates/${year}`, { data, cache });
  },
  addProductYear(id) {
    return axios.post(`/products/${id}/year`);
  },
  addProductYearBySupplier(supplier_id) {
    return axios.post(`/suppliers/${supplier_id}/products/year`);
  },

  updateProductOrder(supplier_id, updatedProductList) {
    return axios.post(`/suppliers/${supplier_id}/products_order`, { data: updatedProductList });
  },
  deleteProduct(id) {
    return axios.delete(`/products/${id}`);
  },
  deleteProductList(idList) {
    return axios.delete("/products", { data: { idList } });
  },

  checkProductInBooking(product_id) {
    return axios.get("/booking_suppliers/products/" + product_id);
  },
};
