<template>
  <TotalTableRow :itemList="rows" :splitTotal="splitTotal" embedded />
</template>

<script>
import service from "../../../service";
import TotalTableRow from "./_TotalTableRow";
export default {
  props: {
    items: Array,
    height: [Number, String],
    showRecon: Array,
    showArchive: Array,
    splitTotal: Boolean,
  },
  components: {
    TotalTableRow,
  },
  data() {
    return {
      rows: [],
      rowCache: [],
      loading: true,
    };
  },
  watch: {
    items(items) {
      this.compileTotal(items);
    },
    showRecon() {
      this.setRows();
    },
    showArchive() {
      this.setRows();
    },
  },
  methods: {
    setRows() {
      this.rows = this.rowCache.filter((item) => {
        if (this.showArchive.includes("TO_BE_CANCELLED") && item.booking.status == 0) return true;

        if (this.showArchive.includes("CANCELLED") && item.booking.status == -1) return true;
        if (!this.showArchive.includes("CANCELLED") && item.booking.status == -1) return false;

        if (item.booking.report_archived && !this.showArchive.includes("ARCHIVED")) return false;
        if (!item.booking.report_archived && !this.showArchive.includes("ACTIVE")) return false;
        if (!("reconciliation" in item)) return true;
        if (item.reconciliation === 0 && this.showRecon.includes("REC_NONE")) return true;
        if (item.reconciliation > 0 && item.reconciliation < 1 && this.showRecon.includes("REC_PART")) return true;
        if (item.reconciliation === 1 && this.showRecon.includes("REC_ALL")) return true;
        return false;
      });
    },
    compileTotal(items) {
      this.rows = [];
      this.loading = true;

      this.rowCache = items.map((item) => {
        return Object.assign(
          {},
          item,
          service.compileOneBooking(item, this.$store.getters, (msg, data) => {
            console.log(msg, data);
          })
        );
      });
      this.setRows();
      // this.$store
      //   .dispatch("FinanceStore/getFinances", { page: 0, query: searchQuery, noInfinite: true })
      //   .then((rows) => {
      //     this.loading = false;

      //     // Add compiled price stuff
      //     this.rowCache = rows.map((item) => {
      //       return Object.assign(
      //         {},
      //         item,
      //         service.compileOneBooking(item, this.$store.getters, (msg, data) => {
      //           console.log(msg, data);
      //         })
      //       );
      //     });
      //     this.setRows();
      //   })
      //   .catch((err) => {
      //     this.loading = false;
      //     this.$root.$error(err);
      //   });
    },
  },
  mounted() {
    this.compileTotal(this.items);
  },
};
</script>
