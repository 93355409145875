<template>
  <v-dialog v-model="dialog" persistent max-width="350">
    <template v-slot:activator="{ on }">
      <v-hover v-slot:default="{ hover }">
        <v-btn v-if="!hover" color="grey" icon small>
          <v-icon small>delete</v-icon>
        </v-btn>

        <v-btn v-else color="red lighten-1" v-on="on" small>
          <v-icon left small>delete</v-icon>
          <span>Remove Options</span>
        </v-btn>
      </v-hover>
    </template>

    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <span class="subtitle-2">Remove Options</span>
        <v-btn icon small @click="dialog = false">
          <v-icon small>clear</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="pa-3 d-flex flex-column">
          <v-row v-if="allowDelete" class="flex-nowrap red--text" align="start">
            <v-icon left color="red">block</v-icon>
            <span>Suppliers have been contacted. Please cancel suppliers to re-enable cancel and delete.</span>
          </v-row>
          <v-row class="my-3" justify="center">
            <CancelDataDialog v-if="!isCancelled" :disabled="allowDelete" @cancel="(v) => $emit('cancel', v)" />
          </v-row>
          <v-row class="my-3" justify="center">
            <g-button
              label="Delete from Database"
              icon="delete"
              type="error"
              :disabled="allowDelete"
              @onclick="$emit('delete')"
            />
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CancelDataDialog from "./_CancelDataDialog";
export default {
  components: {
    CancelDataDialog,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    allowDelete() {
      const suppliers = this.$store.getters["BookingDayStore/suppliers"];
      const INQUIRY = 1;
      const anyContactedSupplier = Object.values(suppliers).find((v) => v.status > INQUIRY);

      return !!anyContactedSupplier;
    },
    isCancelled() {
      return this.$store.getters["BookingStore/isCancelled"];
    },
  },
};
</script>
