<template>
  <v-container class="pa-2" fluid>
    <v-row align="start" justify="center">
      <v-col>
        <d-header label="Admin" />

        <v-row>
          <v-col cols="2">
            <AdminMenu />
          </v-col>
          <v-col cols="10">
            <router-view></router-view>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { AdminMenu } from "@/modules/admin";
export default {
  components: {
    AdminMenu,
  },
};
</script>
