<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)" persistent max-width="600px">
    <v-card>
      <v-card-title> {{ pretitle }} {{ type === "HOTEL" ? "Nights" : "Days" }} </v-card-title>

      <v-card-text>
        <v-data-table
          disable-pagination
          hide-default-footer
          fixed-header
          :headers="headers"
          :items="dayList"
          show-select
          single-select
          class="database-table a-card"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="(item, index) in items"
                :key="item.id"
                class="database-row color-alt canclick"
                :class="{ 'primary--text grey lighten-2': selected[index], 'grey lighten-2': isHotelMorning(index) }"
                @click="onclick(index)"
              >
                <td>
                  <input
                    type="checkbox"
                    :checked="selected[index]"
                    :disabled="index === items.length - 1 && type === 'HOTEL'"
                  />
                </td>
                <td>
                  {{ item.day }} {{ item.date }}
                  <span v-if="isHotelMorning(index)" class="caption grey--text darken-1">(Checkout)</span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions class="d-flex justify-space-between">
        <g-button icon="clear" label="Cancel" @onclick="$emit('input', false)" />

        <g-button type="primary" label="Update" @onclick="handleUpdate" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import format from "@/services/format";
import Vue from "vue";
export default {
  props: {
    value: Boolean,

    type: String,
    pretitle: String,
  },
  data() {
    return {
      headers: [
        {
          text: "Day",
          value: "day",
        },
      ],

      selected: {},
      original: {},
    };
  },
  computed: {
    dayList() {
      return this.$store.getters["BookingDayStore/dayLinkList"]();
    },
  },
  methods: {
    onclick(index) {
      // Can't select last day for hotel :p
      if (index === this.dayList.length - 1 && this.type === "HOTEL") {
        Vue.set(this.selected, index - 1, 1);
        return;
      }

      if (this.selected[index]) {
        Vue.delete(this.selected, index);
      } else {
        Vue.set(this.selected, index, 1);
      }
    },
    isHotelMorning(index) {
      if (this.selected[index] || this.selected === 0) return false;
      if (this.selected[index - 1]) return true;
      return false;
    },

    handleUpdate() {
      const pack = Object.keys(this.selected).map((index) => {
        return this.dayList[index].rawDate;
      });
      this.$emit("update", pack);
      this.$emit("input", false);
    },
  },
};
</script>
