<template>
  <v-toolbar-items>
    <v-btn v-for="(item, index) in items" :key="index" :to="item.link" text>{{ item.title }}</v-btn>
  </v-toolbar-items>
</template>

<script>
import TABS from "@/config/TABS";

export default {
  watch: {
    $route(to, from) {
      if (to.meta.allAccess || this.$store.getters["isAdmin"]) return;
      if (!(this.customAccess.WRITE || []).includes(to.meta.tabAccess)) this.$router.push({ name: "home" });
    },
  },
  computed: {
    customAccess() {
      const env = this.$store.getters["AccountStore/environment"];
      const custom = this.$store.getters["AccountStore/custom_access"];

      return (custom && custom[env]) || {};
    },
    items() {
      if (this.$store.getters["isAdmin"]) {
        return Object.values(TABS);
      }

      return (this.customAccess || []).WRITE.map((key) => {
        return TABS[key];
      });
    },
  },
};
</script>

<style></style>
