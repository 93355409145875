<template>
  <v-text-field
    outlined
    hide-details
    dense
    type="number"
    :disabled="disabled"
    :prefix="prefix"
    :suffix="suffix"
    :min="min || 0"
    :max="max"
    style="max-width: 80px"
    :value="value"
    @input="(v) => $emit('input', v)"
    @focus="(e) => $emit('focus', e)"
    @blur="(e) => $emit('blur', e)"
    @keydown="(e) => $emit('keydown', e)"
  />
</template>

<script>
export default {
  props: {
    prefix: String,
    suffix: String,
    value: [Number, String],
    min: [Number, String],
    max: [Number, String],
    disabled: Boolean,
  },
};
</script>
