<template>
<v-dialog :value="value" @input="v => $emit('input', v)" width="400" persistent>
  <v-card>
    <v-card-title>
      Estimated Invoice
    </v-card-title>

    <v-card-text class="black--text">
      <v-row>
        <v-col class="subtitle-2" cols="3" align-self="center">
          Amount
        </v-col>

        <v-col class="d-flex justify-end">
          <div class="d-flex align-center pr-1">$</div>
          <f-number 
            :value="displayNumber"
            @input="v => invoiceValue = v" />
          <v-btn icon small @click="invoiceValue = 0">
            <v-icon small>clear</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <g-button @onclick="$emit('input', false)" label="Cancel" />
      <div class="flex-grow-1"></div>
      <g-button type="red" @onclick="remove" label="Remove" />
      <g-button
        type="primary"
        @onclick="addInvoice" label="Update" />
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    estInvoice: [Number, String]
  },
  data(){
    return {
      invoiceValue: null
    }
  },
  computed: {
    displayNumber(){
      return this.invoiceValue || this.estInvoice
    }
  },
  methods: {
    addInvoice(){
      this.$emit('update', this.displayNumber)
      this.$emit('input', false)
      this.invoiceValue = null
    },
    remove(){
      this.$emit('remove')
      this.$emit('input', false)
      this.invoiceValue = null      
    }
  }
}
</script>