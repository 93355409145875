<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)" persistent fullscreen>
    <v-card class="supplier-select-wrapper">
      <v-card-title>
        <DialogHeader
          :type="module"
          :name="supplier.name"
          :supplierId="supplier.id"
          :step="step"
          stepper
          @setStep="(v) => (step = v)"
        />
      </v-card-title>

      <v-card-text class="dialog-content">
        <SupplierSelectView v-show="step === 1" :module="module" @update="selectSupplier" @close="close" />

        <ProductSelectWrapper
          v-if="step === 2"
          mode="BOOKING"
          :module="module"
          :startDay="startDay"
          nested
          nestedType="ALT"
          :parentBookingSupplierId="parentBookingSupplierId"
          :supplier="supplier"
          :productFilter="productFilter"
          :dateList="dateList"
          lockDate
          @update="close"
          @revert="step = 1"
          @close="close"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import format from "@/services/format";
import DialogHeader from "../../_DialogHeader";

import SupplierSelectView from "../../step1_suppliers/SupplierSelectView";
import ProductSelectWrapper from "../../step2_products/ProductSelectWrapper";

export default {
  components: {
    DialogHeader,
    SupplierSelectView,
    ProductSelectWrapper,
  },
  props: {
    value: Boolean,

    nested: Boolean,
    booking: Boolean,
    quote: Boolean,

    module: String,
    dateList: Array,

    // NESTED STUFF
    parentBookingSupplierId: String,
  },
  data() {
    return {
      step: 1,

      // SUPPLIER
      supplier: {},
      productFilter: {},
      // PRODUCT
      dateToBsidMapping: {},
    };
  },
  watch: {
    toggleReset(v) {
      Object.assign(this, {
        step: 1,
        supplier: {},
        productFilter: {},
        dateToBsidMapping: {},
      });
    },
  },
  computed: {
    toggleReset() {
      return `${this.value}|${this.module}`;
    },
    editInputCheck() {
      return `${this.editSupplier}|${this.editProductFilter}`;
    },
    startDay() {
      return this.dateList[0];
    },
  },
  methods: {
    // ----------------
    // SELECT SUPPLIER
    // ----------------
    selectSupplier(data) {
      this.supplier = data.supplier;
      this.productFilter = data.productFilter;
      this.step = 2;

      this.autochooseSupplierData = data;
    },

    // ----------------
    // SELECT PRODUCT
    // ----------------
    selectProducts(dateToBsidMapping) {
      // Product stuff
      this.dateToBsidMapping = dateToBsidMapping;
    },

    // ----------------
    // SAVE / CANCEL
    // ----------------
    close() {
      this.$emit("input", false);
    },
  },
};
</script>
