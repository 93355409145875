<template>
  <v-dialog :value="value" width="450px" persistent>
    <v-card>
      <v-card-title>Export Details</v-card-title>
      <v-card-text class="black--text">
        <v-row align="center">
          <span>Number of PAX (Within the range of people) :</span>
          <f-number :value="pax" class="mx-2" @input="(v) => (pax = v)" @keydown="(e) => onKeyDown(e)" />
        </v-row>
      </v-card-text>
      <v-card-actions>
        <g-button label="Cancel" @onclick="close" />
        <div class="flex-grow-1"></div>
        <g-button label="Generate" @onclick="generate" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import quoteUtils from "@/modules/quotes/components/utils";
export default {
  props: {
    value: Boolean,
    peopleRanges: Array,
  },
  data() {
    return {
      pax: 1,
    };
  },
  watch: {
    peopleRanges: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.pax = quoteUtils.getLowestMinRange(newValue);
      },
    },
  },
  methods: {
    close() {
      this.$emit("input", false);
    },
    generate() {
      const isInRanges = quoteUtils.isInPeopleRangeByInput(this.peopleRanges, this.pax);
      if (!isInRanges) {
        this.$root.$error(
          "Invalid number of PAX. Please ensure that the number of PAX is within the specified range of people."
        );
        return;
      }
      this.$emit("input", false);
      this.$emit("generate", this.pax);
    },
    onKeyDown(e) {
      if (e.key === "." || e.key === "-" || e.key === "+") e.preventDefault();
    },
  },
};
</script>
