<template>
  <v-row class="caption font-weight-bold" justify="center" align="center" style="height: 100%">
    {{ formatedBilled }}
  </v-row>
</template>

<script>
import money from "@/services/money";
export default {
  props: {
    billed: [Number, String],
    forceZero: Boolean,
  },
  computed: {
    formatedBilled() {
      return this.billed || this.billed === 0 ? `$ ${money.format(this.billed || 0, { precision: 2 })}` : "$ --";
    },
  },
};
</script>
