<template>
  <v-col class="py-0">
    <v-row justify="space-between">
      <MarkFinishedButton />
      <ReportArchiveButton />
    </v-row>
    <ReportInfoRow />
    <InvoiceRow
      :billedTotal="costTotal"
      :estBilledTotal="estBilledTotal"
      :estCommissionTotal="estCommissionTotal"
      :receivedCommissionTotal="receivedCommissionTotal"
    />
    <ReportSupplierBreakdown
      :supplierInvoices="supplierInvoices"
      :supplierBreakdown="supplierBreakdown"
      :totalBlob="totalBlob"
      :totalCalc="totalCalc"
      :costTotal="costTotal"
      :commisionTotal="estCommissionTotal"
    />
  </v-col>
</template>

<script>
import {
  ReportInfoRow,
  ReportSupplierBreakdown,
  InvoiceRow,
  ReportService,
  ReportArchiveButton,
  MarkFinishedButton,
} from "@/modules/finances.report";
import { BookingRoomService } from "@/modules/bookings.rooms";
export default {
  components: {
    ReportInfoRow,
    InvoiceRow,
    ReportSupplierBreakdown,
    ReportArchiveButton,
    MarkFinishedButton,
  },
  computed: {
    compiledProductPrice() {
      return this.$store.getters["FinanceCompileStore/compiledProducts"];
    },

    supplierInvoices() {
      return this.$store.getters["FinanceCompileStore/supplier_invoices"];
    },
    supplierBreakdown() {
      return this.$store.getters["FinanceCompileStore/supplierBreakdown"];
    },
    totalBlob() {
      if (!this.supplierBreakdown) return 0;
      const products = ReportService.getProductListTotal(
        Object.values(this.compiledProductPrice),
        null,
        this.$store.getters
      );
      const porterage = ReportService.getPorterageTotal(this.supplierBreakdown, this.$store.getters);
      const parking = ReportService.getParkingTotal(this.supplierBreakdown, this.$store.getters);
      //const extra = ReportService.getExtraTotal(this.$store.getters['FinanceReportStore/extra_expenses'])

      return {
        COMBINED: products.COMBINED + porterage.COMBINED + parking.COMBINED,
        USA: products.USA + porterage.USA + parking.USA,
        CDA: products.CDA + porterage.CDA + parking.CDA,
      };
    },
    totalCalc() {
      return this.totalBlob.COMBINED;
    },
    // compiledCostTotal(){
    // 	return this.$store.getters['FinanceCompileStore/compiledCostTotal']
    // },

    // FOR MARGIN
    estBilledTotal() {
      if (!this.supplierBreakdown) return 0;
      return ReportService.getCompiledBilled({
        supplierBreakdownBlob: this.supplierBreakdown,
        supplierInvoiceBlob: {},
        compiledProductPriceBlob: this.compiledProductPrice,
        defaultToEst: true,
        storeGetter: this.$store,
      });
    },
    costTotal() {
      if (!this.supplierBreakdown) return this.totalCalc;
      return ReportService.getCompiledBilled({
        supplierBreakdownBlob: this.supplierBreakdown,
        supplierInvoiceBlob: this.supplierInvoices,
        compiledProductPriceBlob: this.compiledProductPrice,
        defaultToEst: true,
        extraCostList: this.$store.getters["FinanceCompileStore/extra_expenses"],
        storeGetter: this.$store,
      });
    },
    estCommissionTotal() {
      if (!this.supplierBreakdown) return 0;
      const custom = this.$store.getters["FinanceCompileStore/custom_commissions"];
      return ReportService.getCommission(this.supplierBreakdown, this.compiledProductPrice || {}, custom);
    },
    receivedCommissionTotal() {
      if (!this.supplierBreakdown) return 0;
      const custom = this.$store.getters["FinanceCompileStore/custom_commissions"];
      const receivedHistory = this.$store.getters["FinanceReportStore/commission_received_history"];
      return ReportService.getCommission(
        this.supplierBreakdown,
        this.compiledProductPrice || {},
        custom,
        receivedHistory
      );
    },
  },
};
</script>
