<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)" width="400px" persistent>
    <v-card>
      <v-card-title>
        <span class="caption"></span>
      </v-card-title>

      <v-card-text class="black--text">
        <div>{{ supplierName }}</div>
        <div class="subtitle-2">Rooming Information</div>
        <v-row v-for="(v, index) in nameList" :key="index" class="mx-0">
          <v-col cols="1" class="caption">
            <v-icon small left>hotel</v-icon>
          </v-col>
          <v-col>
            <a-text-field label="Last Name" widelabel v-model="v.last" />
            <a-text-field label="First Name" widelabel v-model="v.first" />
          </v-col>
        </v-row>
        <v-row justify="end">
          <g-button label="room" icon="add" @onclick="addName" />
        </v-row>
      </v-card-text>

      <v-card-actions>
        <g-button label="Cancel" @onclick="$emit('input', false)" />
        <div class="flex-grow-1"></div>

        <g-button type="primary" label="Update" @onclick="update" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    clickedSupplier: Object,
    updateFn: Function,
  },
  data() {
    return {
      nameList: [],
    };
  },
  watch: {
    value(v) {
      if (!v) return;
      this.nameList =
        (this.clickedSupplier && this.clickedSupplier.meta && this.clickedSupplier.meta.room_nameList) || [];
    },
  },
  computed: {
    supplierName() {
      return this.clickedSupplier && this.clickedSupplier.name;
    },
  },
  methods: {
    addName() {
      this.nameList.push({
        first: "",
        last: "",
      });
    },
    update() {
      const c = this.clickedSupplier;
      this.updateFn({
        bsid: c.id,
        supplier_id: c.supplier_id,
        type_as: c.type_as,
        bound: c.meta && c.meta.bound,
        metaMerge: {
          room_nameList: this.nameList,
        },
      }).then((_) => {
        this.$root.$success("Updated " + c.name);
        this.$emit("input", false);
      });
    },
  },
};
</script>
