<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)" width="800px" persistent scrollable>
    <v-card>
      <v-toolbar depressed flat>
        <span class="title">Client</span>
        <div class="flex-grow-1"></div>
      </v-toolbar>

      <v-card-text>
        <div>
          <DatabaseSearch type="clients" @search="updateQuery" />
          <DatabaseTable select-only dbConfig="clients" :query="query" @select="(v) => this.onSelected(v)" />
        </div>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <g-button label="Cancel" @onclick="$emit('input', false)" />
        <g-button type="primary" label="Select" @onclick="eject" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DatabaseSearch, DatabaseTable } from "@/modules/database";
export default {
  props: {
    value: Boolean,
  },
  components: {
    DatabaseSearch,
    DatabaseTable,
  },
  data() {
    return {
      query: {},
      nuval: "",
    };
  },
  computed: {},
  methods: {
    eject() {
      this.$emit("update", this.nuval);
      this.$emit("input", false);
    },
    updateQuery(query) {
      this.query = { ...query };
    },
    onSelected(v) {
      this.nuval = v;
      this.eject();
    },
  },
};
</script>
