import Vue from "vue";

Vue.directive("uppercase", {
  bind(el, _, vnode) {
    // Disable if not permitted
    if (_.expression && !_.value) return;

    el.addEventListener("keyup", (e) => {
      if (e.target.selectionStart === 0) return;

      const capped = e.target.value ? e.target.value[0].toUpperCase() + e.target.value.slice(1) : "";
      e.target.value = capped;
      vnode.componentInstance.$emit("input", capped);
    });
  },
});

Vue.directive("caps", {
  bind(el, _, vnode) {
    el.addEventListener("keyup", (e) => {
      e.target.value = e.target.value ? e.target.value.toUpperCase() : "";
      vnode.componentInstance.$emit("input", e.target.value);
    });
  },
});

Vue.directive("bullets", {
  bind(el, _, vnode) {
    el.addEventListener("keyup", (e) => {
      if (e.key !== "Enter") return;

      var value = e.target.value;
      var location = e.target.selectionStart;
      var output = value.substring(0, location) + "- " + value.substring(location);

      e.target.value = output;
      vnode.componentInstance.$emit("input", e.target.value);
      e.target.selectionEnd = location + 2;
    });
  },
});

Vue.directive("col-adjwidth", {
  inserted(el, _, vnode) {
    let columns = vnode.context.$el.querySelectorAll('[role="columnheader"]');

    // If number, get column
    // If text, get column with text title
    let setwidth;
    if (isNaN(_.expression)) {
      columns.forEach((col) => {
        if (col.innerText === _.expression.replace(/'/g, "")) {
          setwidth = col.clientWidth;
        }
      });
    } else {
      setwidth = columns[Number(_.expression)].clientWidth;
    }

    el.style.width = `${setwidth}px`;
    el.style["max-width"] = `${setwidth}px`;
    el.style["min-width"] = `${setwidth}px`;
  },
});
