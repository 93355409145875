<template>
  <a-card>
    <a-field :config="config[fieldName]" v-bullets v-model="extraInfo" />
  </a-card>
</template>

<script>
import info from "../config/formConfig";

export default {
  props: {
    supplierType: String,
    fieldName: String,
  },
  computed: {
    config() {
      return this.supplierType ? info[this.supplierType] || {} : {};
    },
    extraInfo: {
      get() {
        return this.get(this.fieldName);
      },
      set(newValue) {
        return this.set(this.fieldName, newValue);
      },
    },
  },
  methods: {
    get(val) {
      return this.$store.getters["SupplierStore/" + val];
    },
    set(key, val) {
      return this.$store.dispatch("SupplierStore/setUpdate", { [key]: val });
    },
  },
};
</script>
