<template>
  <span>{{ displayDate }}</span>
</template>

<script>
import format from "@/services/format";
export default {
  props: {
    date: String,
  },
  computed: {
    displayDate() {
      return format.formatDate(this.date);
    },
  },
};
</script>
