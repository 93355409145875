<template>
  <div class="px-5">
    <v-row v-if="showAddress">
      <!-- DEFAULT -- HIDDEN -->
      <v-col cols="1" v-if="editMode" class="pa-0">
        <v-btn icon x-small @click="$emit('input', !value)">
          <v-icon>{{ value ? "visibility" : "visibility_off" }}</v-icon>
        </v-btn>
      </v-col>

      <v-col v-if="value || editMode" class="d-flex flex-column pa-0" :class="{ 'text--disabled': editMode && !value }">
        <v-row>
          <v-col :cols="editMode ? 4 : 3" class="caption font-weight-bold mx-2 mx-2 pa-0" align-self="center"
            >Payment Type:
          </v-col>
          <v-col class="caption pa-0 text-capitalize" align-self="center">
            <span>{{ paymentType }}</span>
            <span v-if="showPricePerPerson" class="mx-4">({{ productList }})</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ProductService } from "@/modules/products";
export default {
  props: {
    supplier: Object,
    contactMap: Object,
    date: String,

    editMode: Boolean,
    value: Boolean, // HIde payment
  },
  computed: {
    showAddress() {
      return this.supplier && this.contactMap[this.supplier.supplier_id];
    },
    paymentType() {
      const meta = this.supplier.supplier_meta || {};
      return meta.payment_type && meta.payment_type.value;
    },

    showPricePerPerson() {
      if (!this.supplier.supplier_meta) return false;
      if (this.paymentType !== "cash") return false;
      // Only include if rate per person
      if (!this.productList.includes("Per Person")) return false;

      return true;
    },

    productList() {
      var t = [];
      if (this.supplier.products) {
        let name, price, refkey, count, groupFit;
        const vm = this;
        const meta = this.supplier.meta;
        Object.keys(this.supplier.products).forEach((key) => {
          price = ProductService.getPriceFromBookingSupplier(
            vm.supplier,
            vm.supplier.products[key],
            meta.custom_prices,
            this.$store.getters,
            ", ",
            this.date,
            this.supplier.meta
          );

          t.push({
            rate: price.rate,
            convert: price.convert,
            original: price.original,
          });
        });
      }
      return t.map((item) => item.rate).join(", ");
    },
  },
};
</script>
