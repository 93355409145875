<template>
  <div>
    <elwrapper label="Est PAX" midtext cuslabel="min-width:60px;max-width:60px">
      <v-text-field
        style="min-width: 50px"
        :disabled="disabled"
        :value="paxDisplay"
        outlined
        hide-details
        dense
        readonly
        @click="dialog = true"
      ></v-text-field>
    </elwrapper>
    <!-- <elwrappper label="Est PAX">

	</elwrapper> -->
    <v-dialog v-model="dialog" width="300px" persistent>
      <v-card>
        <v-toolbar depressed flat>
          <span class="title">Est PAX</span>
          <div class="flex-grow-1"></div>
        </v-toolbar>

        <v-card-text class="black--text">
          <v-row>
            <v-col>
              <v-text-field
                :value="paxDisplay"
                @input="(v) => (updateNumber = v)"
                outlined
                hide-details
                dense
                @click="dialog = true"
              ></v-text-field>
            </v-col>
            <v-col>
              <pcheck label="Sync PAX" v-model="syncPax" />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <div class="flex-grow-1"></div>
          <g-button label="Cancel" @onclick="clear" />
          <g-button type="primary" label="Update" @onclick="save" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { BookingRoomService } from "@/modules/bookings.rooms";

export default {
  props: {
    disabled: Boolean,
  },
  data() {
    return {
      dialog: false,
      syncPax: true,
      updateNumber: null,
    };
  },
  computed: {
    paxDisplay() {
      return (
        this.updateNumber ||
        this.$store.getters["BookingStore/data"]["est_pax_num"] ||
        BookingRoomService.getPaxCount(
          this.$store.getters["BookingStore/data"].room_num,
          this.$store.getters["BookingRoomStore/rooms"]
        )
      );
    },
  },
  methods: {
    clear() {
      this.updateNumber = null;
      this.dialog = false;
      this.syncPax = false;
    },
    save() {
      this.$store.dispatch("BookingStore/update", { deep: true, key: "est_pax_num", val: Number(this.paxDisplay) });

      if (this.syncPax) {
        this.$store.dispatch("BookingStore/update", { deep: true, key: "pax_num", val: Number(this.paxDisplay) });
      }

      this.$store
        .dispatch("BookingStore/updateBooking")
        .then((v) => {
          this.$root.$success("Saved booking pax");
          this.clear();
        })
        .catch((err) => this.$root.$error(err));
    },
  },
};
</script>
