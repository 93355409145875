import ClientWrapper from "./ClientWrapper";
import ClientInformation from "./ClientInformation";
import LogoView from "./logo/LogoView";

import contacts from "./contacts/_routes";
import history from "./history/_routes";

export default [
  {
    path: "/clients/:client_id",
    component: ClientWrapper,
    children: [
      {
        path: "",
        name: "client_info",
        component: ClientInformation,
        meta: {
          toolbar: "client",
          tabAccess: "DATABASE",
        },
      },
      {
        path: "logo",
        name: "client_logo",
        component: LogoView,
        meta: {
          toolbar: "client",
          tabAccess: "DATABASE",
        },
      },

      ...contacts,
      ...history,
    ],
  },
];
