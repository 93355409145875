import api from "./api";
import Vue from "vue";
import { SuppliersMenu } from "@/config/menus";

const EMAIL_LIMIT = 10;

export default {
  compileContactDropdownData,

  groupSupplierContacts,
  groupSupplierProducts,

  getBookingSupplierIdToUpdate,
  _getProductMeta,
  _getWhatDays,
  _getFlattenedDays,

  sendBulkEmail,
  _sendOneMail,

  createEmailBreak,
};

/*************
 * EMAIL SELECT
 **************/
function compileContactDropdownData(allContacts, primaryContactId) {
  let hiddenCount = 0;

  let displayName, displayPrimary, displayTitle;
  const foundContacts = allContacts
    .filter((item) => {
      // Contact needs to have email in order to show
      // Otherwise the email will remain hidden and user cannot select it
      if (item.email) return true;
      hiddenCount += 1;
      return false;
    })
    .map((item) => {
      displayName = _mailName(item.name);
      displayPrimary = item.id === primaryContactId ? "-- Primary" : "";
      displayTitle = item.title ? `(${item.title})` : "";
      return {
        label: `${displayName} ${displayTitle} ${displayPrimary}`,
        value: item.id,
        email: item.email,
        name: displayName,
        supplier_name: item.supplier_name,
        supplier_city: item.address ? item.address.city : "",
      };
    });

  return {
    emailList: foundContacts,
    hidden: hiddenCount,
  };
}

function _mailName(name) {
  if (!name) return "";

  // Gets the first name to mail
  const chopped = name.split(" ");
  return chopped[0];
}

/*************
 * EMAIL GROUPING BY CONTACT
 **************/
function groupSupplierContacts(selectedContacts, mapKey, singleCC) {
  // Each group is a single email that will be sent
  //	PARAMS
  // 	selectedContacts 	@{Object} of selected contacts
  //	mapKey				@{String} of what to use for unique mapping (eg. supplier_id, booking_supplier_id)
  //	singleCC			@{Array} of emails to CC, for SINGLE only (added to all groups....)

  // RETURNS @{Array} => @{Object}
  //			=> [mapKey]		@{String} Whatever the value of mapkey in original is
  //			=> contacts 	@{Array} from _flattenContacts

  // Re-group contacts here (usually specific supplier, or selected supplier)
  let supplierMapping = {},
    tempKey;
  Object.values(selectedContacts).forEach((contact) => {
    tempKey = contact[mapKey];
    if (!supplierMapping[tempKey]) {
      supplierMapping[tempKey] = {
        [mapKey]: tempKey,
        supplier_name: contact.supplier_name || contact.client_name,
        supplier_city: contact.supplier_city,
        contacts: [], // This will get replaced with object, array for now
      };
    }
    supplierMapping[tempKey].contacts.push(contact);
  });

  // Clean and merge contacts
  Object.values(supplierMapping).forEach((group) => {
    group.contacts = _flattenContacts(_cleanContacts(group.contacts));
    if (singleCC) {
      group.contacts.ccEmailList = singleCC;
    }
  });

  return Object.values(supplierMapping);
}

function _flattenContacts(contactList) {
  // Flattens/merges seperate emails, etc. as one email group
  // Otherwise, each email will be treated as seperate
  // PARAMS
  //	contactList 	@{Array} => @{Object}
  //			=> email 		@{String}
  //			=> name_list	@{Array} => Strings
  // RETURNS @{Object}
  //		=> emailList 	@{Array} => Strings, list of emails
  //		=> nameList 	@{Array} => Strings, contact names for template
  let flattenedContacts = {
    emailList: [],
    nameList: [],
  };

  contactList.forEach((item) => {
    flattenedContacts.emailList.push(item.email);
    flattenedContacts.nameList = flattenedContacts.nameList.concat(item.name_list);
  });

  return flattenedContacts;
}

function _cleanContacts(contactList) {
  /* GOAL
   * 1. If multiple contacts share the same email, merge their names here
   *		These should be treated as ONE contact total
   * 2. Ignore contacts without email
   *		Normally, contact emails should not make it this far
   *		However, these is an extra precaution
   * 3. Want FIRST NAME of contact only
   * 4. Trim to remove errors
   */

  // PARAMS
  //	contactList 	@{Array} of Objects
  // RETURNS @{Array} of objects

  let emailMapping = {};
  let fname;
  contactList.forEach((contact) => {
    if (!contact.email) return;

    // Ignore casing
    // Causes comparisons issues and emails don't distinguish anyway
    contact.email = contact.email.toLowerCase();

    if (!emailMapping[contact.email]) {
      emailMapping[contact.email] = {
        email: contact.email,
        name_list: [_getFirstName(contact.name)],
      };
      return;
    }

    // Merge here
    emailMapping[contact.email].name_list.push(_getFirstName(contact.name));
  });

  return Object.values(emailMapping);
}

function _getFirstName(name) {
  // PARAMS
  //	name 	@{String}
  // RETURNS @{String}
  if (!name) return "";
  const temp = name.trim().split(" ");
  return temp[0];
}

/*************
 * EMAIL GROUPING => PER PRODUCTS, SUPPLIERS, ETC
 **************/
function groupSupplierProducts(
  emailListData,
  maptype,
  bookingDays,
  bookingSuppliers,
  groupByRef,
  ignoreProductsRef,
  oldDates
) {
  // PARAMS
  // 	emailListData 	@{Array} Grouped email list
  // 	maptype 		@{String} Supplier type, if null -- ALL supplier types be grabbed
  // 	bookingDays 	@{Array} Booking day information with booking supplier mapping
  // 	bookingSuppliers @{Object} Booking Supplier reference
  // 	groupByRef 		@{Object} Mapping of how to group selected supplier's products (If null, assuming GROUP)
  // 	ignoreProductsRef 	@{Object} Mapping of what products to NOT include in email
  // 	oldDates 		@{Object} Old Date information of booking (if exists)
  let dump = [],
    bsWithSupplierId;
  const flattenedBookingDays = _getFlattenedDays(maptype, bookingDays);

  emailListData.forEach((item) => {
    bsWithSupplierId = Object.values(bookingSuppliers).filter((bs) => bs.supplier_id === item.supplier_id);

    if (groupByRef[item.supplier_id] === "SPLIT") {
      // Each product gets its own email
      bsWithSupplierId.forEach((bs) => {
        Object.values(bs.products).forEach((product) => {
          // Skip product if market for ignored
          if (ignoreProductsRef[`${bs.id}_${product.id}`]) return;
          dump.push({
            content: item,
            supplier_id: item.supplier_id,
            bookingSuppliers: [bs],
            bookingDays: flattenedBookingDays,
            oldDates,
            productData: [
              {
                product: product,
                booking_supplier_id: bs.id,
                bs_meta: _getProductMeta(
                  product.id,
                  bs.meta,
                  _getWhatDays(bs.id, maptype, bookingDays),
                  bookingSuppliers,
                  _getFlattenedDays("ALL", bookingDays)
                ),
                dates: _getWhatDays(bs.id, maptype, bookingDays),
                old_dates: _getWhatDays(bs.id, maptype, oldDates.old_days),
              },
            ],
          });
        });
      });

      return;
    }

    // Default: Group all products in this booking supplier to one email
    dump.push({
      content: item,
      supplier_id: item.supplier_id,
      bookingSuppliers: bsWithSupplierId,
      bookingDays: flattenedBookingDays,
      oldDates,
      productData: bsWithSupplierId.reduce((map, bs) => {
        let bsProducts = Object.values(bs.products).reduce((submap, product) => {
          if (ignoreProductsRef[`${bs.id}_${product.id}`]) return submap;

          return [
            ...submap,
            {
              product: product,
              booking_supplier_id: bs.id,
              bs_meta: _getProductMeta(
                product.id,
                bs.meta,
                _getWhatDays(bs.id, maptype, bookingDays),
                bookingSuppliers,
                _getFlattenedDays("ALL", bookingDays)
              ),
              dates: _getWhatDays(bs.id, maptype, bookingDays),
              old_dates: _getWhatDays(bs.id, maptype, oldDates.old_days),
            },
          ];
        }, []);

        return [...map, ...bsProducts];
      }, []),
    });
  });

  return dump;
}

function _getProductMeta(productId, bsMeta, dayList, bookingSuppliers, flattenedBookingDays) {
  // Get Extra suppliers
  let map = flattenedBookingDays
    .filter((d) => dayList.includes(d.date))
    .map((day) => {
      let adjust = 0;
      day.suppliers.forEach((bsid) => {
        if (["TOUR_GUIDE", "TRANSPORT"].includes(bookingSuppliers[bsid].type_as)) {
          if (bsMeta.exclude_suppliers && bsMeta.exclude_suppliers && bsMeta.exclude_suppliers[day.date][bsid]) return;
          let count = (bookingSuppliers[bsid].meta && bookingSuppliers[bsid].meta.count) || {};
          adjust += Object.values(count).reduce((t, c) => t + Number(c), 0) || 1;
        }
      });
      return adjust;
    });

  return {
    count: bsMeta.count && bsMeta.count[productId],
    adjust_count: Math.max(...map),
    custom_name: bsMeta.custom_names && bsMeta.custom_names[productId],
  };
}

function _getFlattenedDays(maptype, bookingDays) {
  if (!maptype || maptype === "ALL") {
    // Flatten ALL suppliers for these days
    let blob;
    return bookingDays.map((day) => {
      return {
        date: day.date,
        suppliers: SuppliersMenu.FULL.reduce((arr, x) => {
          if (x.module === "ALL") return arr;
          return [...arr, ...(day[x.alt] || [])];
        }, []),
      };
    });
  }

  return bookingDays.map((day) => {
    return {
      date: day.date,
      suppliers: day[SuppliersMenu.MOD_DBKEY[maptype]],
    };
  });
}

function _getWhatDays(bookingSupplierId, maptype, bookingDays) {
  // What days supplier appears
  let dateList = [];

  if (!maptype) {
    bookingDays.forEach((item) => {
      SuppliersMenu.FULL.forEach((x) => {
        if (x.module === "ALL") return;
        if (item[x.alt].includes(bookingSupplierId)) dateList.push(item.date);
      });
    });
  } else {
    const mod = SuppliersMenu.MOD_DBKEY[maptype];
    bookingDays.forEach((item) => {
      if (item[mod].includes(bookingSupplierId)) dateList.push(item.date);
    });
  }

  return dateList.sort((a, b) => {
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
  });
}

/*************
 * GET BOOKING SUPPLIER IDS TO
 **************/
function getBookingSupplierIdToUpdate(contacts, bookingSuppliers, ignoreProductsRef) {
  // Gets booking supplier ids from selected contacts
  // Booking Suppliers with ignored products are not ignored
  // PARAMS
  // 	contacts 	@{Object} Selected contact bulk
  // 	bookingSuppliers @{Object} Booking Supplier reference
  // 	ignoreProductsRef 	@{Object} Mapping of what products to NOT include in email
  // RETURN @{Array} List of booking_supplier_ids to update

  // Step 1, extract unique supplier ids
  const supplierIdList = Object.values(contacts).reduce((arr, con) => {
    if (arr.includes(con.supplier_id)) return arr;
    return [...arr, con.supplier_id];
  }, []);

  // Step 2, Get booking supplier ids
  // Get booking_supplier_id only
  return Object.values(bookingSuppliers)
    .filter((item) => {
      if (!supplierIdList.includes(item.supplier_id)) return false;

      // Keep only books where products are sent
      let keep = false;
      Object.values(item.products).forEach((product) => {
        if (!ignoreProductsRef[`${item.id}_${product.id}`]) keep = true;
      });

      return keep;
    })
    .map((item) => item.id);
}

/*************
 * SENDING MASS SELECT EMAIL
 **************/
// function groupMessageQuery(data){
// 	// Chops array into mail groups
// 	// PARAMS
// 	//	data 	@{Array}
// 	// RETURNS @{Array} => Array
// 	var size = EMAIL_LIMIT;
// 	var arrayOfArrays = [];
// 	for (var i=0; i<data.length; i+=size) {
// 	     arrayOfArrays.push(data.slice(i,i+size));
// 	}
// 	return arrayOfArrays;
// }

function sendBulkEmail({
  accountData,
  subject,
  attachments,
  messageData,

  updateMessageFn,
}) {
  // 1. Compile list of emails sending
  // 2. Upate whether each email send or fails to

  // PARAMS
  //	subject			@{String}
  //	attachments 	@{Array} of file objects
  //	messageData		@{Array} => Objects, emails to send (should have contacts + message, including cc)
  //	updateMessageFn	@{Function} Called when status updated message is available
  // RETURNS 	@{Promise}

  let emailQueries = messageData.map((messageBlob) => {
    return _sendOneMail({
      accountData,
      subject,
      attachments,
      messageBlob,
      updateMessageFn,
    });
  });
  return Promise.all(emailQueries);
  // return api.sendMassMail({
  // 		accountData,
  // 		subject,
  // 		attachments,
  // 		groupList: groupList[currentIndex]
  // 	}).then(() => {
  //       	// Pass back emails that have been sent
  //         var totalDone = Number(total) + groupList[currentIndex].length;
  //         onNext(totalDone, groupList[currentIndex]);

  //         // End recursive loop here
  //         if(groupList.length === currentIndex+1){
  //           return onEnd();
  //         }

  //         // continue recursive loop
  //         return _recurseSendEmails({
  //         	accountData,
  // 					subject,
  // 					attachments,
  //         	groupList,
  //         	index: currentIndex+1,
  //         	total: totalDone,
  //         	size
  //         }, onNext, onEnd, onError);
  //   })
  //   .catch(err => {
  //     onError(err);
  //   })
}

function _sendOneMail({ accountData, subject, attachments, messageBlob, updateMessageFn }) {
  return api
    .sendMassMail({
      accountData,
      subject,
      attachments,
      groupList: [messageBlob],
    })
    .then((v) => {
      Vue.set(messageBlob, "promise", 1);
    })
    .catch((err) => {
      Vue.set(messageBlob, "promise", -1);
      throw err;
    });
}

// function sendBulkEmail({
// 	accountData,
// 	subject,
// 	attachments,
// 	messageData,

// 	updateMessageFn
// 	}){
// 	// 1. Recursively send X emails at a time
// 	//		(Sending too many at once has caused crashing in past)
// 	//		User is notified how many emails have been sent
// 	// 2. User is notified how many emails have been sent as they are being sent
// 	// 3. Digest copy of the emails are then sent to the USER's email

// 	// PARAMS
// 	//	subject			@{String}
// 	//	attachments 	@{Array} of file objects
// 	//	messageData		@{Array} => Objects, emails to send (should have contacts + message, including cc)
// 	//	updateMessageFn	@{Function} Called when status updated message is available
// 	// RETURNS 	@{Promise}

// 	return _sendMail({
// 		accountData,
// 		subject,
// 		attachments,
// 		messageData,
// 		updateMessageFn
// 	})
// 	.catch(err => {
// 		throw err;
// 	});
// }

function _sendMail({ accountData, subject, attachments, messageData, updateMessageFn }) {
  // Launches resursive main sending
  // PARAMS
  //	subject			@{String}
  //	attachments 	@{Array} of file objects
  //	messageData		@{Array} of emails to send (should have contacts + message)
  //	updateMessageFn	@{Function} Called when status updated message is available
  // RETURNS 	@{Promise}

  let size = messageData.reduce((total, val) => (typeof val === "object" ? total + val.length : total + 1), 0);
  let contactedList = [];

  // Start message
  updateMessageFn(_getSendStatusMessage(0, size));

  return new Promise((resolve, reject) => {
    _recurseSendEmails(
      {
        accountData,
        subject,
        attachments,
        groupList: messageData,
        index: 0, // index to start
        total: 0, // total contacts sent
        size: size,
      },
      function (totalDone, sentEmailsCopy) {
        // On Next
        // Update messaging
        updateMessageFn(_getSendStatusMessage(totalDone, size));
        // Save supplier contact info (needed for displaying messages)
        contactedList = contactedList.concat(sentEmailsCopy);
      },
      function () {
        // On complete
        resolve(contactedList);
      },
      function (err) {
        // On error
        reject(err.data);
        throw err;
      }
    );
  });
}

function _getSendStatusMessage(number, size) {
  return `Sent ${number} of ${size}`;
}

function _recurseSendEmails(
  { accountData, subject, attachments, groupList, index, total, size },
  onNext,
  onEnd,
  onError
) {
  let currentIndex = index || 0;

  return api
    .sendMassMail({
      accountData,
      subject,
      attachments,
      groupList: groupList[currentIndex],
    })
    .then(() => {
      // Pass back emails that have been sent
      var totalDone = Number(total) + groupList[currentIndex].length;
      onNext(totalDone, groupList[currentIndex]);

      // End recursive loop here
      if (groupList.length === currentIndex + 1) {
        return onEnd();
      }

      // continue recursive loop
      return _recurseSendEmails(
        {
          accountData,
          subject,
          attachments,
          groupList,
          index: currentIndex + 1,
          total: totalDone,
          size,
        },
        onNext,
        onEnd,
        onError
      );
    })
    .catch((err) => {
      onError(err);
    });
}

/***********
 * EMAIL REPLY FUNCTIONS
 ***********/
function createEmailBreak(data) {
  const dateSent = new Date().toGMTString();
  const senders = data.to_mail.map((item) => `${item.name} (${item.address})`);

  return `<br>----------<br> On ${dateSent} ${senders.join(", ")} wrote:`;
}
