<template>
  <v-card flat>
    <v-form @submit.prevent="updateSearch">
      <v-card-text class="d-flex flex-wrap">
        <a-text-field class="s-field" widelabel label="Client Name" v-model="name" />
      </v-card-text>

      <v-card-actions>
        <g-button label="Clear" @onclick="clearSearch" />
        <v-spacer />
        <search-button />
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
export default {
  props: {
    isApplied: Boolean,
  },
  computed: {
    name: {
      get() {
        return this.$store.getters["DatabaseStore/clientSearch"].name;
      },
      set(nuval) {
        this.$store.dispatch("DatabaseStore/updateSearch", { tab: "client", data: { name: nuval } });
      },
    },
  },
  methods: {
    clearSearch() {
      this.name = "";

      if (this.isApplied) {
        // Active? Clear all search stuff
        this.$emit("search", {});
        this.$store.dispatch("DatabaseStore/applySearch", {
          dbconfig: "clients",
        });
      }
    },
    updateSearch() {
      let search = {
        ...(this.name && {
          multiple: true,
          list: [
            {
              columns: "name",
              value: this.name,
            },
          ],
        }),
      };

      this.$emit("search", search);
      this.$store.dispatch("DatabaseStore/applySearch", {
        tab: "client",
        dbconfig: "clients",
        data: search,
      });
    },
  },
};
</script>

<style>
.s-field {
  flex: 0 0 50%;
}
</style>
