<template>
  <div>
    <v-row :justify="admin ? 'space-between' : 'end'" class="mx-0 mb-1">
      <RemoveBookingDialog @cancel="cancelBooking" @delete="deleteBooking" />
      <WarningDialog v-model="warningDialog" :message="warningMessage" />

      <div class="d-flex align-center">
        <g-button
          v-if="pendUpdate"
          label="Finished Copy Update"
          class="mx-3"
          icon="check"
          type="green"
          @onclick="finishCopyUpdate"
        />
        <g-button
          v-if="pendCancel"
          label="Revert to Active"
          class="mx-3"
          icon="reply"
          type="black"
          @onclick="revertCopyCancel"
        />
        <g-button
          v-if="isCancelled"
          label="Revert to Active"
          class="mx-3"
          icon="reply"
          type="black"
          @onclick="revertRegularCancel"
        />

        <ArchiveButton />

        <a-hover-menu v-if="isCancelled">
          <template v-slot:activator>
            <v-btn small icon color="white">
              <v-icon small color="white">help_outline</v-icon>
            </v-btn>
          </template>

          <div style="width: 250px" class="caption">
            <v-row>
              <v-col cols="5">Cancelled By: </v-col>
              <v-col>{{ cancelBy }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="5">Date: </v-col>
              <v-col>{{ cancelDate }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="5">Reason:</v-col>
              <v-col>{{ cancelNotes }}</v-col>
            </v-row>
          </div>
        </a-hover-menu>

        <g-button class="mr-3" type="primary" label="Reporting" @onclick="redirectToReport" />
        <span v-if="isCancelled" class="white--text">CANCELLED -- saving disabled</span>
        <save-button v-else :onsave="saveBooking" />
      </div>
    </v-row>
  </div>
</template>

<script>
import RemoveBookingDialog from "./dialogs/RemoveBookingDialog";
import ArchiveButton from "./_ArchiveButton";
import WarningDialog from "./dialogs/WarningDialog";
import format from "@/services/format";
export default {
  props: {
    admin: Boolean,
  },
  components: {
    RemoveBookingDialog,
    ArchiveButton,
    WarningDialog,
  },
  data() {
    return {
      dialog: false,
      warningDialog: false,
      warningMessage: "",
    };
  },
  computed: {
    bookingId() {
      return this.$store.getters["BookingStore/booking_id"];
    },
    isCancelled() {
      return this.$store.getters["BookingStore/isCancelled"];
    },
    cancelBy() {
      const d = this.$store.getters["BookingStore/active"];
      return d && d.cancel_by_name;
    },
    cancelDate() {
      const d = this.$store.getters["BookingStore/active"];
      return d && d.cancel_date && format.formatDate(d.cancel_date);
    },
    cancelNotes() {
      const d = this.$store.getters["BookingStore/active"];
      return d && d.cancel_notes;
    },

    pendUpdate() {
      return this.$store.getters["BookingStore/isPendingPostCopyUpdate"];
    },
    pendCancel() {
      return this.$store.getters["BookingStore/isPendingPostCopyCancel"];
    },

    clientInvoiceType() {
      return this.$store.getters["BookingStore/active"].client_invoice_type;
    },
  },
  methods: {
    redirectToReport() {
      window.open(this.$router.resolve("/finances/report/" + this.bookingId).href, "_blank");
    },
    verifyCancelOrDelete(bookingId) {
      this.$root.$loading.open("Checking booking status");

      return this.$store.dispatch("FinanceReportStore/getOneReport", bookingId).then((data) => {
        const { payment_history, supplier_invoices, booking_suppliers } = data;
        let warningMessage = "";
        let shouldShowWarning = false;

        // Check for client invoice history
        if (payment_history && payment_history.length > 0) {
          warningMessage =
            "Some payments have been received from the client for this booking. Please remove these payments before proceeding";
          shouldShowWarning = true;
        }

        // Check for unreconciled supplier invoices
        if (supplier_invoices && booking_suppliers) {
          const reconciledSupplierIds = supplier_invoices
            .filter((invoice) => invoice.reconciled === 1)
            .map((item) => item.supplier_id);

          const suppliersArray = Object.values(booking_suppliers);
          const uniqueSuppliers = new Map();
          suppliersArray.forEach((entry) => {
            uniqueSuppliers.set(entry.supplier_id, entry);
          });

          const uniqueList = Array.from(uniqueSuppliers.values());
          const supplierPayments = uniqueList.filter((item) => reconciledSupplierIds.includes(item.supplier_id));

          if (supplierPayments.length > 0) {
            const supplierNames = supplierPayments.map((supplier) => supplier.name).join("\n");
            warningMessage =
              'Some payments have been made to suppliers for this booking. Please mark these lines as "Not reconciled" before proceeding\n\n' +
              supplierNames;
            shouldShowWarning = true;
          }
        }

        if (shouldShowWarning) {
          this.$store.dispatch("BookingStore/update", { key: "status", val: 0 });
          this._updateBooking();
          this.warningMessage = warningMessage;
          this.warningDialog = true;
          this.$root.$loading.end();
          return false;
        }

        this.$root.$loading.end();
        return true;
      });
    },
    cancelBooking(notes) {
      this.verifyCancelOrDelete(this.$store.getters["BookingStore/active"].id).then((noWarnings) => {
        if (noWarnings) {
          this.$store
            .dispatch("BookingStore/cancelBooking", {
              cancel_by: this.$store.getters["AccountStore/id"],
              cancel_date: format.convertDate(new Date()),
              notes: notes || "(None given)",
            })
            .then(() => {
              this.$root.$success("Successfully cancelled booking");
              this.$router.push({ name: "bookings" });
            })
            .catch((err) => this.$root.$error(err));
        }
      });
    },

    deleteBooking() {
      this.verifyCancelOrDelete(this.$store.getters["BookingStore/active"].id).then((noWarnings) => {
        if (noWarnings) {
          this.$store
            .dispatch("BookingStore/deleteBooking")
            .then(() => {
              this.$root.$success("Successfully deleted Booking");
              this.$router.push({ name: "bookings" });
            })
            .catch((err) => this.$root.$error(err));
        }
      });
    },
    saveBooking() {
      return this.$store
        .dispatch("BookingStore/updateBooking")
        .then((updateDays) => {
          if (updateDays) return this.$store.dispatch("BookingDayStore/getDays", this.bookingId);
        })
        .then((v) => this.$store.dispatch("BookingFlightStore/saveFlights", this.bookingId))
        .then((v) => {
          this.$root.$success("Updated Booking");
          if (this.clientInvoiceType === "FULL")
            this.$root.$warn(
              "Updated Booking, The final invoice has already been sent to the client. Please make sure to send the invoice again with the latest information."
            );
        })
        .catch((err) => this.$root.$error(err));
    },

    finishCopyUpdate() {
      this.$store.dispatch("BookingStore/update", { key: "pending_state", val: 0 });

      this._updateBooking();
    },
    revertCopyCancel() {
      this.$store.dispatch("BookingStore/update", { key: "status", val: 1 });
      this.$store.dispatch("BookingStore/update", { key: "pending_state", val: 0 });

      this._updateBooking();
    },
    revertRegularCancel() {
      this.$store.dispatch("BookingStore/update", { key: "status", val: 1 });
      this.$store.dispatch("BookingStore/update", { key: "cancel_by", val: null });
      this.$store.dispatch("BookingStore/update", { key: "cancel_notes", val: null });
      this.$store.dispatch("BookingStore/update", { key: "cancel_date", val: null });

      this._updateBooking();
    },

    _updateBooking() {
      this.$root.$loading.open("Updating...");
      this.$store
        .dispatch("BookingStore/updateBooking")
        .then((v) => {
          this.$root.$loading.end();
          this.$root.$success("Updated Booking");
          if (this.clientInvoiceType === "FULL")
            this.$root.$warn(
              "Updated Booking, The final invoice has already been sent to the client. Please make sure to send the invoice again with the latest information."
            );
        })
        .catch((err) => this.$root.$error(err));
    },
  },
  beforeDestroy() {
    if (this.isCancelled) return;
    if (this.$store.getters["BookingStore/hasChanges"]) {
      this.$store
        .dispatch("BookingStore/updateBooking")
        .then((v) => {
          this.$root.$success("Updated Booking");
          if (this.clientInvoiceType === "FULL")
            this.$root.$warn(
              "Updated Booking, The final invoice has already been sent to the client. Please make sure to send the invoice again with the latest information."
            );
        })
        .catch((err) => this.$root.$error(err));
    }
  },
};
</script>
