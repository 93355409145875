<template>
  <div>
    <BookingSaveHeader />

    <Notes />
  </div>
</template>

<script>
import { BookingSaveHeader, Notes } from "@/modules/bookings";
export default {
  components: {
    BookingSaveHeader,
    Notes,
  },
  computed: {},
};
</script>
