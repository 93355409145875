<template>
  <span>{{ code }} {{ phone }} {{ ext }}</span>
</template>

<script>
import PHONE_MASK from "@/config/PHONE_MASK";
import service from "./phoneService";

export default {
  props: {
    value: [String, Object],
    country: String,
  },
  computed: {
    mask() {
      return PHONE_MASK(this.country || "US") || PHONE_MASK("US");
    },
    phoneDisplay() {
      return service.phoneDisplay(this.value, this.mask[0], this.country) || {};
    },
    code() {
      return this.phoneDisplay.code ? `+${this.phoneDisplay.code}` : "";
    },
    phone() {
      if (typeof this.phoneDisplay === "object") return this.phoneDisplay.phone;
      var val = this.phoneDisplay.phone ? this.phoneDisplay.phone : typeof this.value === "string" ? this.value : "";
      return isNaN(val) ? val : service.format(val, this.mask[0]);
    },
    ext() {
      return this.phoneDisplay.ext ? `ext:${this.phoneDisplay.ext}` : "";
    },
  },
};
</script>
