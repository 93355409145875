<template>
  <div class="black--text">
    <div>
      <v-row class="my-2" justify="space-between">
        <span class="subtitle-2 grey--text"> Alternative/Backup Suppliers</span>
      </v-row>

      <div v-if="swappingInProgress" class="mb-3">
        <div class="text-center subtitle-1">Swapping Suppliers. Please wait...</div>
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </div>
      <v-row v-if="!formatedDayList.length" class="grey lighten-2 pa-3"> (Supplier has not been added yet) </v-row>
    </div>

    <div class="table">
      <v-row>
        <v-col style="max-width: 60px"><!-- Spacer --></v-col>
        <v-col style="max-width: 200px">Dates</v-col>
        <v-col style="max-width: 100px"><!-- Spacer --></v-col>
        <v-col>Alternate</v-col>
      </v-row>

      <v-row v-for="day in formatedDayList" :key="day.parent_bsid">
        <v-col style="max-width: 60px">
          <span>{{ day.day }}</span>
        </v-col>
        <v-col class="black--text font-weight-bold" style="max-width: 180px">
          <span>{{ day.readDateList }}</span>
        </v-col>

        <v-col style="max-width: 80px">
          <g-button label="Add" icon="add" @onclick="selectProduct(day.parent_bsid, day.dateList)" />
        </v-col>

        <AlternateBlock :module="module" :parentBookingSupplierId="day.parent_bsid" :dateList="day.dateList" />
      </v-row>
    </div>

    <v-row class="mx-0 pt-3 button-row">
      <g-button label="Close" icon="clear" @onclick="$emit('close')" />

      <v-spacer />

      <g-button label="Back" @onclick="$emit('revert')" />
      <g-button label="Finish" @onclick="$emit('close')" />
    </v-row>

    <AlternateSelectDialog
      v-model="dialog"
      :module="module"
      :parentBookingSupplierId="selected.parentBookingSupplierId"
      :dateList="selected.dateList"
    />
  </div>
</template>

<script>
import format from "@/services/format";
import AlternateBlock from "./_AlternateBlock";
import AlternateSelectDialog from "./dialog/AlternateSelectDialog";
export default {
  props: {
    // For Add Dialog
    mode: String,
    module: String,

    dateToBsidMapping: Object,
  },
  components: {
    AlternateBlock,
    AlternateSelectDialog,
  },
  data() {
    return {
      dialog: false,
      swappingInProgress: false,

      selected: {},
    };
  },
  computed: {
    formatedDayList() {
      const list = this.$store.getters["BookingDayStore/dayLinkList"]();
      return list
        .filter((date) => {
          return this.dateToBsidMapping[date.rawDate];
        })
        .reduce((arr, item) => {
          // Assuming ordered
          let current = this.dateToBsidMapping[item.rawDate];
          const focus = arr.find((v) => v.parent_bsid === current);

          if (focus) {
            focus.dateList.push(item.rawDate);
            focus.readDateList = this._formatDates(focus.dateList);
          } else {
            arr.push({
              dateList: [item.rawDate],
              readDateList: this._formatDates([item.rawDate]),
              parent_bsid: current,
            });
          }
          return arr;
        }, []);
    },
  },
  methods: {
    _formatDates(dateList) {
      return format.compileDateListReadable(dateList, this.module === "HOTEL");
    },

    selectProduct(parent, dateList) {
      this.selected = {
        parentBookingSupplierId: parent,
        dateList: dateList,
      };
      this.dialog = true;
    },
  },
};
</script>
