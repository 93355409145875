<template>
  <a-card nopad class="imageview">
    <v-img
      :src="hostName + image.src"
      :lazy-src="hostName + image.src"
      aspect-ratio="1"
      height="150"
      width="150"
      class="grey lighten-2"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height" align="center" justify="center">
          <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
        </v-row>
      </template>
    </v-img>

    <div v-if="!hidetext" class="overline text-center">
      <span>{{ image.user_name }}</span>
      <span class="mx-2">|</span>
      <span>{{ formatDate(image.uploaded_date) }}</span>
    </div>
    <div class="subtitle-2">
      {{ image.description }}
    </div>
  </a-card>
</template>

<script>
import dateFormat from "@/services/format";
export default {
  props: {
    image: Object,
    showChecks: Boolean,
    hidetext: Boolean,
  },
  computed: {
    hostName() {
      return this.$store.getters["host"];
    },
  },
  methods: {
    formatDate(date) {
      return date ? dateFormat.formatDate(dateFormat.convertDate(date)) : "(unknown)";
    },
  },
};
</script>
