<template>
  <tr
    class="database-row color-alt canclick"
    :class="{ 'primary--text blue lighten-4': hasAllContactsSelected }"
    @click="toggleSelect"
  >
    <td class="d-flex align-start pt-2" style="border-top: 3px ridge grey">
      <v-icon small v-if="noContacts">clear</v-icon>
      <input v-else type="checkbox" :checked="hasAllContactsSelected" />
    </td>
    <td style="border-top: 3px ridge grey; padding-right: 0 !important">
      <v-row
        class="booking-row-header px-0 grey darken-1 font-weight-bold elevation-1"
        align="center"
        style="height: 30px"
      >
        <v-col style="max-width: 140px">{{ booking.client_name }}</v-col>
        <v-col>{{ booking.group_name }}</v-col>
        <v-col class="font-weight-regular brh-hover" style="max-width: 50px">From: </v-col>
        <v-col class="brh-hover">{{ fromDate }}</v-col>
        <v-col class="font-weight-regular brh-hover" style="max-width: 30px">To: </v-col>
        <v-col class="brh-hover">{{ toDate }}</v-col>
        <v-col class="font-weight-regular brh-hover" style="max-width: 50px">Start: </v-col>
        <v-col class="brh-hover">{{ booking.where_start }}</v-col>
        <v-col class="font-weight-regular brh-hover" style="max-width: 50px">End: </v-col>
        <v-col class="brh-hover">{{ booking.where_end }}</v-col>
      </v-row>
      <slot :suppliers="suppliers" :rooms="rooms" :pax="pax"></slot>
    </td>
  </tr>
</template>

<script>
import format from "@/services/format";
import service from "../../service";
import { BookingRoomService } from "@/modules/bookings.rooms";
export default {
  props: {
    booking: Object,
    contactList: Array,
    searchRecord: Object,
    selectedContacts: Object,

    // Disables contact list + supplier extraction
    pluckProducts: Boolean,
    nosub: Boolean,
    checked: Boolean,
    supplierId: String,
  },
  computed: {
    fromDate() {
      return format.formatDate(this.booking.from);
    },
    toDate() {
      return format.formatDate(this.booking.to);
    },
    pax() {
      return this.booking.data && this.booking.data.pax_num;
      // return BookingRoomService.getPaxCount(this.rooms.bookingMainBreakdown,
      //     this.booking.rooming_data);
    },

    suppliers() {
      let maptype = (this.searchRecord || {}).supplier_type || null;
      if (maptype === "ALL") maptype = null;

      let supplierRef = service._pickFoundSuppliers(this.booking.booking_suppliers, maptype);
      // Filter out contacts for other bookings
      // (This is a mega list contains all contacts for everything)
      const filteredContacts = (this.contactList || []).filter((item) => supplierRef[item.supplier_id]);
      const compiledSuppliers = service._attachFirstDay(
        service._mergeContactsWithSuppliers(supplierRef, filteredContacts),
        this.booking.days,
        this.booking.booking_suppliers
      );

      if (this.pluckProducts) {
        // Get ONE Supplier
        return compiledSuppliers.find((item) => item.supplier_id === this.supplierId);
      }

      compiledSuppliers.sort((a, b) => a.name.localeCompare(b.name));
      compiledSuppliers.forEach((item) => {
        item.contacts.sort((a, b) => a.name.localeCompare(b.name));
      });

      return compiledSuppliers;
    },

    rooms() {
      return BookingRoomService.getRoomingListBreakdown({
        bookingMetaData: this.booking.data,
        bookingRoomingList: this.booking.rooming_data,
        bookingDays: this.booking.days,
        supplierRoomList: this.booking.supplier_rooms,
      });
    },

    noContacts() {
      // TO DO
      return false;
    },
    hasAllContactsSelected() {
      if (this.nosub) return this.checked;
      if (!this.selectedContacts) return false;

      let temp = true;
      Object.values(this.suppliers).forEach((sup) => {
        sup.contacts.forEach((contact) => {
          if (!this.selectedContacts[contact.id]) temp = false;
        });
      });
      return temp;
    },
  },
  methods: {
    toggleSelect() {
      this.$emit("onclick", {
        booking: this.booking,
        suppliers: this.suppliers,
        allSelected: this.hasAllContactsSelected,
      });
    },
  },
};
</script>

<style lang="scss">
.booking-row-header {
  padding: 0 16px !important;
  position: -webkit-sticky;
  position: sticky;
  color: white;
  top: 0;

  .brh-hover {
    opacity: 0;
  }

  &:hover {
    cursor: pointer;
    background: rgba(10, 10, 10, 0.3) !important;

    .brh-hover {
      opacity: 1;
    }
  }
}
</style>
