<template>
  <v-dialog :value="value" persistent max-width="350">
    <v-card>
      <v-card-title>Warning</v-card-title>
      <v-card-text class="black--text">
        <div v-html="formattedMessage"></div>
      </v-card-text>
      <v-card-actions>
        <g-button label="OK" @onclick="save" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    message: String,
  },
  computed: {
    formattedMessage() {
      return this.message.replace(/\n/g, "<br>");
    },
  },
  methods: {
    save() {
      this.$emit("input", false);
    },
  },
};
</script>
