<template>
  <div>
    <v-row>
      <v-col class="subtitle-2" align-self="center">
        {{ altlabel || "New Payment" }}
      </v-col>

      <v-col class="d-flex justify-end">
        <div class="d-flex align-center pr-1">$</div>
        <f-number :value="value" @input="(v) => $emit('input', v)" />
      </v-col>
    </v-row>

    <v-row class="caption font-italic" :class="balance.negative">
      <v-col align-self="center"> Remaining Balance </v-col>

      <v-col class="d-flex justify-end">
        {{ balance.format }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
import money from "@/services/money";
import format from "@/services/format";
export default {
  props: {
    altlabel: String,
    value: [Number, String],
    remainingTotal: [Number, String],
  },
  data() {
    return {
      invoiceValue: null,
    };
  },
  computed: {
    balance() {
      const bal = Number(this.remainingTotal) - (this.value || 0);

      return {
        negative: bal < 0 ? "red--text" : "",
        format: `$ ${money.format(bal, { precision: 2 })}`,
      };
    },
  },
};
</script>
