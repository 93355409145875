<template>
  <td class="indigo lighten-3 pa-2 overview-block" style="min-width: 150px; max-width: 150px; vertical-align: top">
    <div>Total in bookings : {{ totalInBooking }}</div>
    <div v-if="isGeneratedFromQuote">Total in quote : {{ totalInQuote }}</div>
    <div v-if="isGeneratedFromQuote" :class="getValueClass">Delta : {{ delta }}</div>
  </td>
</template>

<script>
import { ProductService } from "@/modules/products";
import moneyService from "@/services/money";
import DateFormat from "@/services/format";
export default {
  props: {
    typeAs: String,
  },
  computed: {
    totalInBooking() {
      const typeMap = {
        HOTEL: "hotels",
        RESTAURANT: "restaurants",
        PARK: "parks",
        EXCURSION: "excursions",
        TRANSPORT: "transport",
        TOUR_GUIDE: "tour_guides",
      };

      const key = typeMap[this.typeAs] || null;
      if (!key) return 0;

      const days = this.$store.getters["BookingDayStore/days"];
      const suppliers = this.$store.getters["BookingDayStore/suppliers"];
      const workingEnv = this.$store.getters["AccountStore/environment"];

      let total = 0;
      days.forEach((booking) => {
        const bookingSupplierIds = booking[key] || [];
        bookingSupplierIds.forEach((supplierId) => {
          const supplier = suppliers[supplierId];
          if (supplier) {
            Object.values(supplier.products).forEach((product) => {
              const { rate } = this.rateContent(supplier, product);
              const strippedRate = moneyService.stripFormatting(rate, workingEnv);
              total += strippedRate;
            });
          }
        });
      });

      return total;
    },
    totalInQuote() {
      if (!this.isGeneratedFromQuote) return 0;
      const bookingData = this.$store.getters["BookingStore/data"];
      const quoteTotals = bookingData.quote.total;
      return quoteTotals[this.typeAs] ?? 0;
    },
    delta() {
      if (!this.isGeneratedFromQuote) return 0;
      const delta = this.totalInBooking - this.totalInQuote;
      return delta.toFixed(2);
    },
    isGeneratedFromQuote() {
      const bookingData = this.$store.getters["BookingStore/data"];
      return bookingData.quote != null;
    },
    getValueClass() {
      return `font-weight-bold ${this.totalInBooking > this.totalInQuote ? "red-text" : "green-text"}`;
    },
  },
  methods: {
    // TODO: This method comes from _ProductPriceHover. Will check how it works later.
    rateContent(supplier, product) {
      let locked = supplier.locked_prices && supplier.locked_prices[product.id];
      const useDates = supplier.meta && supplier.meta.use_dates;
      let dateDefault = {
        from_month: DateFormat.dateToObj(this.date).month,
        from_day: DateFormat.dateToObj(this.date).day,
      };

      const customPrices = supplier.meta ? { ...supplier.meta.custom_prices } || {} : {};

      const price = ProductService.getPrice(
        product,
        supplier.supplier_meta,
        supplier.country,
        customPrices,
        this.$store.getters,
        true,
        useDates || dateDefault,
        supplier.meta
      );

      if (!product.seasonal) {
        return {
          rate: locked ? ProductService.formatPrice(locked.totalPrice) : price.totalPrice_print,
          convert:
            locked && locked.totalConvert
              ? ProductService.formatConvert(locked.totalConvert)
              : price.totalConvert_print,
          original:
            locked && locked.originalPrice ? ProductService.formatPrice(locked.originalPrice) : price.price_print,
        };
      }

      let result = "",
        convert = "",
        original = "",
        temp;

      price.forEach((p, index) => {
        locked = supplier.locked_prices && supplier.locked_prices[p.id] && supplier.locked_prices[p.id][index];
        temp = {
          rate: locked ? ProductService.formatPrice(locked.totalPrice) : p.totalPrice_print,
          convert:
            locked && locked.totalConvert ? ProductService.formatConvert(locked.totalConvert) : p.totalConvert_print,
          original: locked && locked.originalPrice ? ProductService.formatPrice(locked.originalPrice) : p.price_print,
        };

        convert += `${index >= 1 ? ";<br/>" : ""}${temp.convert}`;
        result += `${index >= 1 ? ";<br/>" : ""}${temp.rate}`;
        original += `${index >= 1 ? ";<br/>" : ""}${temp.original}`;
      });
      return { rate: result, convert, original };
    },
  },
};
</script>
