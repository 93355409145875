<template>
  <v-toolbar id="supheader" dense>
    <v-toolbar-title>
      <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
    </v-toolbar-title>

    <div class="flex-grow-1"></div>

    <span v-if="isCancelled" class="subtitle-2 grey darken-4 white--text px-2">
      <v-icon small left color="white">cancel</v-icon>
      <span>Cancelled</span>
    </span>

    <template v-slot:extension>
      <BookingTabs :bookingId="bookingId" />
    </template>
  </v-toolbar>
</template>

<script>
import BookingTabs from "./_BookingHeaderTabs";
export default {
  components: {
    BookingTabs,
  },
  computed: {
    active() {
      return this.$store.getters["BookingStore/active"];
    },
    bookingId() {
      return this.active.id || "0";
    },
    clientName() {
      return this.active.client_name || "(Client Deleted)";
    },
    clientId() {
      return this.active.client_id;
    },
    groupName() {
      const pendUpdate = this.$store.getters["BookingStore/isPendingPostCopyUpdate"];
      const pendCancel = this.$store.getters["BookingStore/isPendingPostCopyCancel"];
      const name = this.$store.getters["BookingStore/booking_name"]();
      if (pendUpdate) return `${name} (NEW DATES TO ADD)`;
      if (pendCancel) return `${name} (OLD DATES TO REMOVE)`;
      return name;
    },

    breadcrumbs() {
      return [
        {
          text: "Bookings",
          to: { name: "bookings" },
        },
        {
          text: this.clientName,
          to: { name: "client_info", params: { client_id: this.clientId } },
        },
        {
          text: this.groupName,
        },
      ];
    },
    isCancelled() {
      return this.$store.getters["BookingStore/isCancelled"];
    },
  },
};
</script>
