<template>
  <div class="supplier-report-row">
    <SupplierRowMainRow
      :nobuttons="nobuttons"
      :external="external"
      :showProducts="showProducts"
      :bookingId="bookingId"
      :supplier="supplier"
      :supplierType="supplierType"
      :supplierInvoice="supplierInvoice"
      :supplierDelta="supplierDelta"
      :commissionReceivedHistory="commissionReceivedHistory"
      :customCommissionList="customCommissionList"
      @edit="editSupplierDialog = true"
      @toggle-products="showProducts = !showProducts"
      @update="(v) => $emit('update', v)"
      @add-expense="expenseDialog = true"
      @sync-commission="(v) => $emit('sync-commission', v)"
      @add-commission-history="(v) => $emit('add-commission-history', v)"
      @toggle-commission-received="$emit('toggle-commission-received')"
    />

    <v-row v-show="showProducts">
      <v-col style="margin-right: 425px" class="py-0">
        <template v-for="(product, key) in supplierProducts">
          <template v-if="product.type_as === 'HOTEL' && product.perRoom.length > 0">
            <HotelPerRoomRow
              :key="'hotel' + key"
              class="product-row"
              :typeAs="supplier.type_as"
              :productType="product.product_type"
              :product="product"
              :supplier="supplier"
            />
          </template>
          <ProductRow
            v-else
            :key="key"
            class="product-row"
            :typeAs="supplier.type_as"
            :productType="product.product_type"
            :product="product"
            :supplier="supplier"
          />
        </template>
      </v-col>
    </v-row>

    <EmbedExpenseBlock
      style="margin-right: 150px"
      label="Embedded Expense"
      v-if="porterage || parking_bus || parking_vehicle"
      :showProducts="showProducts"
      :porterage="porterage"
      :parking_vehicle="parking_vehicle"
      :parking_bus="parking_bus"
      :country="supplier.country"
    />

    <ExtraExpenseBlock
      v-if="showDeposit"
      deltaconfig
      label="Deposit"
      :showProducts="showProducts"
      :extraExpenses="depositExpenses"
      @remove-extra="(extra) => removeExpense(extra)"
    />

    <ExtraExpenseBlock
      style="margin-right: 150px"
      v-if="showExpenses"
      label="Extra Expense"
      :showProducts="showProducts"
      :extraExpenses="extraExpenses"
      @remove-extra="(extra) => removeExpense(extra)"
    />

    <ExtraExpenseBlock
      style="margin-right: 150px"
      v-if="refundExpenses.length"
      label="Refund"
      :showProducts="showProducts"
      :extraExpenses="refundExpenses"
      @remove-extra="(extra) => removeExpense(extra)"
    />

    <!-- DIALOGS -->
    <SetSupplierCostDialog
      v-model="editSupplierDialog"
      :editSupplier="supplier"
      :supplierInvoice="supplierInvoice"
      @update="updateSupplier"
      @remove="removeCost"
    />
    <AddCustomExpenseDialog
      v-model="expenseDialog"
      :editSupplier="supplier"
      :editDeposit="depositExpenses"
      @set-cost="updateSupplier"
    />
    <ConfirmRemoveExpenseDialog
      v-model="removeExpenseDialog"
      :external="external"
      :supplierName="supplier.name"
      :removingExpense="pendingRemoveExtraExpense"
      @pop-expense="(v) => $emit('pop-expense', v)"
    />
  </div>
</template>

<script>
import ReportService from "../../service";
import money from "@/services/money";
import format from "@/services/format";

import SupplierRowMainRow from "./_SupplierRowMainRow";
import ProductRow from "./_ProductRow";
import HotelPerRoomRow from "./_HotelPerRoomRow";
import EmbedExpenseBlock from "./extraExpense/_EmbedExpenseBlock";
import ExtraExpenseBlock from "./extraExpense/_ExtraExpenseBlock";

import SetSupplierCostDialog from "./_SetSupplierCostDialog";
import AddCustomExpenseDialog from "./customExpense/_AddCustomExpenseDialog";
import ConfirmRemoveExpenseDialog from "./customExpense/_ConfirmRemoveExpenseDialog";
export default {
  props: {
    nobuttons: Boolean,
    external: Boolean,
    bookingId: String,
    supplier: Object,
    supplierType: String,

    supplierInvoice: Object,
    supplierDelta: Object,
    extraExpenses: Array,
    refundExpenses: Array,
    depositExpenses: Array,

    commissionReceivedHistory: Array,
    customCommissionList: Array,
  },
  components: {
    SupplierRowMainRow,
    ProductRow,
    HotelPerRoomRow,
    EmbedExpenseBlock,
    ExtraExpenseBlock,
    // DIALOGS
    SetSupplierCostDialog,
    AddCustomExpenseDialog,
    ConfirmRemoveExpenseDialog,
  },
  data() {
    return {
      showProducts: false,

      editSupplierDialog: false,
      expenseDialog: false,
      removeExpenseDialog: false,
      pendingRemoveExtraExpense: {},
    };
  },
  computed: {
    showDeposit() {
      if (!this.depositExpenses.length) return false;
      return this.customSupplierCost ? this.showProducts : true;
    },

    supplierProducts() {
      return this.supplier.products.filter((item) => item.product_type !== "HARDCODE");
    },
    porterage() {
      const list = this.supplier.products.filter((item) => {
        return item.id === "PORTERAGE";
      });
      return list ? list[0] : null;
    },
    parking_vehicle() {
      const list = this.supplier.products.filter((item) => item.id === "PARKING_VEHICLE");
      return list ? list[0] : null;
    },
    parking_bus() {
      const list = this.supplier.products.filter((item) => item.id === "PARKING_BUS");
      return list ? list[0] : null;
    },

    customSupplierCost() {
      return this.supplierInvoice && this.supplierInvoice.total_cost;
    },

    showExpenses() {
      return this.extraExpenses.length;
    },
  },
  methods: {
    removeExpense(extra, supplier) {
      this.removeExpenseDialog = true;
      this.pendingRemoveExtraExpense = extra;
    },
    updateSupplier({ supplier, customCost }) {
      this.editSupplierDialog = false;
      const tocreate = this.supplierInvoice;

      this.$store
        .dispatch("FinanceCompileStore/updateSupplierInvoice", {
          skipCommit: this.external,
          booking_id: this.bookingId,
          supplier_id: supplier.supplier_id,
          type_as: supplier.type_as,
          tocreate: !tocreate,
          data: {
            total_cost: customCost,
            reconciled: tocreate && tocreate.reconciled,
          },
          ...(tocreate && {
            cache: {
              total_cost: tocreate.total_cost,
              reconciled: tocreate.reconciled,
            },
          }),
        })
        .then((data) => {
          this.$emit("update", data);
        })
        .catch((err) => {
          this.$root.$error("Failed to save supplier");
        });
    },
    removeCost(supplier) {
      this.editSupplierDialog = false;
      this.$store
        .dispatch("FinanceCompileStore/removeSupplierInvoice", {
          skipCommit: this.external,
          supplier_invoice_id: this.supplierInvoice.id,
          supplier_id: supplier.supplier_id,
          type_as: supplier.type_as,
        })
        .then((supplier_invoice_id) => {
          this.$emit("pop-invoice", supplier_invoice_id);
        })
        .catch((err) => {
          this.$root.$error("Failed to save supplier");
        });
    },
  },
};
</script>

<style lang="scss">
.supplier-report-row {
  .subrow {
    min-height: 40px;
    display: flex;
  }

  .product-row:hover {
    background: lightgrey;
  }

  .report-btn {
    display: none;
  }

  &:hover {
    .report-btn {
      display: inline;
    }
  }
}
</style>
