<template>
  <div>
    <SupplierHeader :supplierId="supplierId" :supplierType="supplierType" :name="name" />
    <v-container fluid fill-height class="justify-center">
      <router-view></router-view>
    </v-container>
  </div>
</template>

<script>
import { SupplierHeader } from "@/modules/suppliers";

export default {
  components: {
    SupplierHeader,
  },
  data() {
    return {
      supplierId: this.$route.params.id,
    };
  },
  computed: {
    supplierType() {
      return this.$store.getters["SupplierStore/type"];
    },
    name() {
      return this.$store.state.SupplierStore.cached.name;
    },
  },
  mounted() {
    // Refreshing page
    // Wrong Environment for supplier, then redirect to database suppliers
    const cached = this.$store.state.SupplierStore.cached;
    const env = this.$store.getters["AccountStore/environment"];
    if (cached && cached.env !== env) {
      this.$router.push({
        name: "database_view",
        params: { view: "suppliers", sub: cached.supplier_type },
      });
      return;
    }

    this.supplierId = this.$route.params.id;
    this.$store
      .dispatch("SupplierStore/getSupplier", this.supplierId)
      .then(() => {
        this.$root.$loading.end();
      })
      .catch(this.$root.$error);
  },
};
</script>

<style></style>
