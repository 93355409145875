<template>
  <div class="px-5 mx-5">
    <v-alert type="warning" text dense v-if="hasCustomDate" class="mx-0 caption">
      <span>Using Price Date: </span>
      <span class="subtitle-2">{{ useDateFormat }}</span>
    </v-alert>

    <v-row class="subtitle-2 grey--text">
      <v-col cols="2">Product</v-col>
      <v-col cols="1" v-if="showCount" class="d-flex justify-space-between">
        <span>Count</span>
        <!-- LOCK TOGGLE -->
        <v-btn icon small v-if="allCountsLocked" @click="toggleOffCount(false)">
          <v-icon icon>lock</v-icon>
        </v-btn>
        <v-btn icon small v-else @click="toggleOffCount(true)">
          <v-icon icon>lock_open</v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <v-row>
          <v-col class="pa-0">Default</v-col>
          <v-col cols="3" class="pa-0">Use Price</v-col>
          <v-col cols="2"></v-col>
          <v-col class="pa-0">Total</v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider />

    <ProductList
      :showCount="showCount"
      :disabled="disabled"
      :type="type"
      :supplier="supplier"
      :products="products"
      :bookingSupplierMeta="metaconfig"
      :useDate="useDate"
      :bookingDayYear="bookingDayYear"
      :allCountsLocked="allCountsLocked"
    />
  </div>
</template>

<script>
import Vue from "vue";
import format from "@/services/format";
import ProductList from "./_ProductList";
import { ProductService } from "@/modules/products";

export default {
  props: {
    disabled: Boolean,
    contacted: Boolean,
    type: String,

    supplier: Object,
    products: Object,
    useDate: Object,
    metaconfig: Object,

    bookingDayYear: String,
  },
  components: {
    ProductList,
  },
  computed: {
    hasCustomDate() {
      return !!(this.metaconfig && this.metaconfig.use_dates);
    },
    showCount() {
      // Show expense
      const hasExpense = Object.values(this.products || {}).find((i) => i.product_type === "EXPENSE");
      if (hasExpense) return true;
      // Everything except Hotel and tour guides
      return !["HOTEL", "TOUR_GUIDE"].includes(this.type);
    },
    allCountsLocked() {
      return this.metaconfig && this.metaconfig.confirmed_offcount;
    },
    useDateFormat() {
      if (!this.useDate) return null;
      return format.formatDate(`0000-${this.useDate.from_month}-${this.useDate.from_day}`, true);
    },
    productPriceList() {
      let price,
        priceList = [],
        supplierMeta,
        country;

      Object.values(this.products).forEach((item) => {
        supplierMeta = this.supplier.supplier_meta || item.supplier_meta;
        country = this.supplier.country;

        price = ProductService.getPrice(
          item,
          supplierMeta,
          country,
          null,
          this.$store.getters,
          true,
          this.useDate,
          this.metaconfig
        );

        priceList.push({
          product_id: item.id,
          name: item.name,
          type: item.product_type,
          seasonal: item.seasonal,
          rate_kind: item.meta ? item.meta.rate_kind : "",
          supplier_meta: supplierMeta,
          rate: item.rate,
          price: price,
          meta: item.meta && typeof item.meta === "string" ? JSON.parse(item.meta) : item.meta,
        });
      });

      return priceList;
    },

    pax_num() {
      return this.$store.getters["BookingStore/pax_num"];
    },
  },
  methods: {
    toggleOffCount(value) {
      Vue.set(this.metaconfig, "confirmed_offcount", value);
    },
  },
  mounted() {
    if (!this.metaconfig.count) Vue.set(this.metaconfig, "count", {});

    // Don't set if editing

    // Set default count to PAX
    if (["RESTAURANT", "EXCURSION"].includes(this.type)) {
      let currentCount = this.pax_num;
      const products = Object.values(this.products);
      const bsCount = this.metaconfig.count;

      // ONE PRODUCT
      if (products.length === 1) {
        const p = products[0];
        // For "By Group" products
        if ((p.rate || {}).type === "PG") currentCount = 1;
        Vue.set(bsCount, p.product_id || p.id, this.metaconfig.count[p.product_id || p.id] || currentCount);
        return;
      }

      // MULTI
      // Total should be PAX, first gets pax minus extra
      // Extra products get 1
      const firstCount = Number(currentCount) - products.length + 1;
      products.forEach((p, index) => {
        if (!bsCount[p.product_id || p.id]) {
          Vue.set(
            bsCount,
            p.product_id || p.id,
            this.metaconfig.count[p.product_id || p.id] || (p.rate || {}).type === "PG" ? 1 : index ? 1 : firstCount
          );
        }
      });
    }
  },
};
</script>

<style lang="scss">
.custom-price-row {
  border-bottom: 1px solid lightgrey;

  &:focus-within {
    color: #1976d2;
  }
}
</style>
