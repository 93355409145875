import Account from "./Account";

export default [
  {
    name: "account",
    path: "/account",
    component: Account,
    meta: {
      allAccess: true,
    },
  },
];
