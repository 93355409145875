<template>
  <div>
    <div>
      <a-group v-if="lockDate" icon="event" label="Dates">
        {{ readableDateList }}
      </a-group>
      <SelectDaysHeader v-else :value="dateList" @input="updateDateList" :module="module" :startDay="startDay" />
    </div>

    <div class="table">
      <v-row>
        <v-col style="max-width: 60px"><!-- Spacer --></v-col>
        <v-col style="max-width: 110px">Date</v-col>
        <v-col style="max-width: 80px"><!-- Spacer --></v-col>
        <v-col>Product</v-col>
      </v-row>

      <v-row v-if="!formatedDayList.length" class="grey lighten-2 pa-2"> (No Dates Selected) </v-row>

      <v-row v-for="(day, index) in formatedDayList" :key="day.rawDate" class="product-select-row">
        <v-col style="max-width: 60px">
          <span>{{ day.day }}</span>
        </v-col>
        <v-col class="black--text font-weight-bold" style="max-width: 110px">
          <span>{{ day.date }}</span>
        </v-col>

        <v-col v-if="!replace" style="max-width: 80px">
          <v-btn text small @click="openAdd(day.rawDate)">
            <v-icon x-small>add</v-icon>
            <span>Add</span>
          </v-btn>
        </v-col>

        <ReplaceOptionRow
          v-if="replace"
          :mode="mode"
          :module="module"
          :disabled="disabled"
          :focusDate="day.rawDate"
          :originSupplierId="originSupplierId"
          :supplierId="supplier.id"
          @replace="openReplace"
        />
        <DayOptionRow
          v-else
          :mode="mode"
          :module="module"
          :disabled="disabled"
          :dateList="dateList"
          :selectedBsid="selectedRow"
          :highlightedRows="highlightedRows"
          @set="(v) => (highlightedRows = v)"
          @mark="(v) => (selectedRow = v)"
          :focusDate="day.rawDate"
          :supplierId="supplier.id"
          @edit="(bsid) => openAdd(day.rawDate, bsid)"
          @clear="(bs) => clearProduct(day.rawDate, bs)"
        />
      </v-row>
    </div>

    <ProductSelectDialog
      :mode="mode"
      v-model="productDialog"
      :module="module"
      :startDay="selectedDate || startDay"
      :supplier="supplier"
      :productFilter="productFilter"
      :editBsSupplierId="editBsSupplierId"
      :editProduct="editProduct"
      :editTime="editTime"
      :editConfig="editConfig"
      :replace="replace"
      :replaceBsSupplierId="replaceBsSupplierId"
      :replaceProduct="replaceProduct"
      :replaceBsMeta="replaceBsMeta"
      :nested="nested"
      :nestedType="nestedType"
      :parentBookingSupplierId="parentBookingSupplierId"
      @select="selectProduct"
    />

    <div class="ma-2">
      <v-row class="button-row" justify="space-between">
        <g-button label="Close" icon="clear" @onclick="$emit('close')" />

        <div>
          <g-button label="Back" icon="keyboard_arrow_left" @onclick="$emit('revert')" />
          <g-button type="primary" label="Next" @onclick="next" />
        </div>
      </v-row>
    </div>

    <RemoveSupplierDialog v-model="removeDialog" :bookingId="bookingId" :supplierInfo="removeSupplierInfo" />

    <NotReconciledDialog
      v-model="notReconciledDialog"
      :bookingId="notReconciledDialogBookingId"
      :hasReconciledPayment="notReconciledDialoghasReconciledPayment"
      :isLastProduct="notReconciledDialogIsLastProduct"
    />
  </div>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import moment from "moment";
import format from "@/services/format";
import { BookingDayService, RemoveSupplierDialog } from "@/modules/bookings.days";

import ReplaceOptionRow from "../options/ReplaceOptionRow";
import DayOptionRow from "../options/DayOptionRow";
import SelectDaysHeader from "./_SelectDaysHeader";
import ProductSelectDialog from "./dialog/ProductSelectDialog";
import NotReconciledDialog from "../NotReconciledDialog";
import { SuppliersMenu } from "@/config/menus";

export default {
  props: {
    mode: String,

    module: String,
    disabled: Boolean,
    startDay: [String, Date],

    supplier: Object,
    productFilter: Object,
    dateList: Array,
    lockDate: Boolean,

    // NESTED
    nested: Boolean,
    nestedType: String,
    parentBookingSupplierId: String,

    // REPLACE
    replace: Boolean,
    originSupplierId: String,
  },
  components: {
    ReplaceOptionRow,
    DayOptionRow,
    SelectDaysHeader,
    ProductSelectDialog,
    RemoveSupplierDialog,
    NotReconciledDialog,
  },
  data() {
    return {
      removeDialog: false,
      removeSupplierInfo: {},
      productDialog: false,
      selectedDate: null,

      highlightedRows: {},
      selectedRow: null,

      editBsSupplierId: null,
      editProduct: null,
      editTime: null,
      editConfig: null,

      replaceBsSupplierId: null,
      replaceProduct: null,
      replaceBsMeta: null,

      notReconciledDialog: false,
      notReconciledDialogBookingId: null,
      notReconciledDialoghasReconciledPayment: null,
      notReconciledDialogIsLastProduct: false,
    };
  },
  computed: {
    bookingId() {
      return this.$store.getters["BookingStore/booking_id"];
    },
    readableDateList() {
      return format.compileDateListReadable(this.dateList);
    },
    suppliers() {
      return this.$store.getters["BookingDayStore/suppliers"];
    },
    productMapping() {
      // stores date => bsid of SAVED
      const days = this.$store.getters["BookingDayStore/days"];
      return BookingDayService.whatProductMapping(this.module, days, this.suppliers, this.supplier.id);
    },
    formatedDayList() {
      const list = this.$store.getters["BookingDayStore/dayLinkList"]();
      return list
        .filter((item) => {
          return this.dateList.includes(item.rawDate);
        })
        .map((item) => {
          const bsid = this.productMapping[item.rawDate];
          return {
            ...item,
            ...this.suppliers[bsid],
          };
        });
    },
  },
  methods: {
    updateDateList(list) {
      this.$emit("setDateList", list);
      this.highlightedRows = {};
    },
    calculateBorders(day, baseindex) {
      const before = baseindex > 0 ? this.formatedDayList[baseindex - 1] : null;
      const after = baseindex === this.formatedDayList.length - 1 ? null : this.formatedDayList[baseindex + 1];

      if (!day.temp_ref_id) return null;
      if (before === null && after === null) return "border-all";
      if (before === null) return "border-top border-wall";
      if (after === null) return "border-bottom border-wall";
      if (before.temp_ref_id === day.temp_ref_id || after.temp_ref_id === day.temp_ref_id) return "border-wall ";
    },

    openAdd(date, bsid) {
      this.productDialog = true;
      this.selectedDate = date;

      if (bsid) {
        this.editBsSupplierId = bsid;

        //const tempid = this.productMapping[this.selectedDate]
        this.editProduct = this.suppliers[bsid].products;
        this.editTime = this.suppliers[bsid].time;
        this.editConfig = this.suppliers[bsid].meta;
      }
    },
    selectProduct(bsid) {
      Vue.set(this.highlightedRows, this.selectedDate, true);
      this.selectedRow = this.productMapping[this.selectedDate];

      this.editBsSupplierId = null;
      this.selectedDate = null;
    },

    openReplace({ date, replaceBsSupplierId, replaceProduct, replaceBsMeta }) {
      this.productDialog = true;
      this.selectedDate = date;
      this.replaceBsSupplierId = replaceBsSupplierId;
      this.replaceProduct = replaceProduct;
      this.replaceBsMeta = replaceBsMeta;
    },

    /*****
     * SAVE + REMOVE STUFF HERE
     * ****/
    next() {
      this.$emit("update", this.productMapping);
    },
    clearProduct(date, bs) {
      const bsid = bs.id;
      const bookingId = this.bookingId;
      const supplierId = bs.supplier_id;

      this.$store.dispatch("FinanceReportStore/getOneReport", bookingId).then((report) => {
        const reconciledSupplier = report.supplier_invoices.filter(
          (item) => item.supplier_id == supplierId && item.reconciled == 1
        );

        const bookingSuppliersArray = Object.values(report.booking_suppliers);
        const matchingSuppliers = bookingSuppliersArray.filter((item) => item.supplier_id == supplierId);

        let hasReconciledPayment = reconciledSupplier.length != 0;
        let isLastProduct = matchingSuppliers.length == 1;

        if (hasReconciledPayment && isLastProduct) {
          this.notReconciledDialog = true;
          this.notReconciledDialogBookingId = bookingId;
          this.notReconciledDialoghasReconciledPayment = hasReconciledPayment;
          this.notReconciledDialogIsLastProduct = isLastProduct;
        } else {
          const dayIndex = this.$store.getters["BookingDayStore/days"].findIndex((day) => day.date === date);
          const dbkey = SuppliersMenu.MOD_DBKEY[this.module];
          const contentIndex = this.$store.getters["BookingDayStore/dayIncludes"](dayIndex, dbkey, bsid);
          this.removeSupplierInfo = {
            supplierData: this.$store.getters["BookingDayStore/suppliers"][bsid],
            daynum: dayIndex,
            dbkey,
            typeAs: this.module,
            contentIndex,
          };
          this.removeDialog = true;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.product-select-row {
  &:hover {
    background: rgba(200, 200, 200, 0.5);
    cursor: pointer;
  }

  .highlight {
    background: lightblue;
  }

  .border-all {
    border: 1px solid black;
  }
  .border-wall {
    border-left: 1px solid black;
    border-right: 1px solid black;
  }
  .border-top {
    border-top: 1px solid black;
  }
  .border-bottom {
    border-bottom: 1px solid black;
  }
}
</style>
