<template>
  <div>
    <ClientSearch v-if="type === 'clients'" :dbConfig="type" @search="(v) => $emit('search', v)" />
    <MainSearch v-else :dbConfig="type" :mod="mod" @search="(v) => $emit('search', v)" />
  </div>
</template>

<script>
import ClientSearch from "./_ClientSearch";
import MainSearch from "./_MainSearch";

export default {
  props: {
    type: String,
    mod: String,
  },
  components: {
    ClientSearch,
    MainSearch,
  },
  data() {
    return {};
  },
};
</script>

<style>
.s-field {
  flex: 0 0 50%;
}
</style>
