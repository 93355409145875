import axios from "@/plugins/axios";

export default {
  getOneAccount(account_id) {
    return axios.get(`/account/${account_id}`);
  },
  getAccountList() {
    return axios.get("/account");
  },
  createAccount(data) {
    return axios.post("/create_account", data);
  },
  updateAccountRole(account_id, { role, env, email_password, custom_access }) {
    return axios.post(`/account/${account_id}/role_env`, {
      role,
      env,
      email_password,
      custom_access,
    });
  },
  deleteAccount(account_id) {
    return axios.delete(`/account/${account_id}`);
  },
  generatePassword(account_id, email) {
    return axios.post(`/account/${account_id}/password`, { email });
  },

  /******
   * LINKED EMAILS
   * ****/
  getAccountEmailList(account_id) {
    return axios.get(`/account_emails/accounts/${account_id}/ADMIN`);
  },
};
