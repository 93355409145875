<template>
  <a-card nopad class="caption mx-0 px-0">
    <v-row>
      <v-col class="pa-0" align-self="center" style="min-height: 30px; max-height: 30px">
        <v-row justify="end" align="center" style="min-height: 30px; max-height: 30px">
          <v-col
            class="flex align-center font-weight-bold"
            style="
              max-width: 140px;
              min-width: 140px;
              border: 1px solid lightgrey;
              text-align: center;
              min-height: 30px;
              max-height: 30px;
              line-height: 1.5;
            "
          >
            <v-row justify="center">Est. Margin</v-row>
          </v-col>
          <v-col
            class="flex align-center font-weight-bold"
            style="
              max-width: 140px;
              min-width: 140px;
              border: 1px solid lightgrey;
              text-align: center;
              min-height: 30px;
              max-height: 30px;
              line-height: 1.5;
            "
          >
            <v-row justify="center">Real Margin</v-row>
          </v-col>
          <v-col
            align-self="center"
            :class="marginColor"
            style="
              max-width: 140px;
              min-width: 140px;
              border: 1px solid lightgrey;
              min-height: 30px;
              max-height: 30px;
              text-align: center;
              line-height: 1.5;
            "
          >
            <v-row justify="center">(%)</v-row>
          </v-col>
          <v-col style="max-width: 150px; min-width: 150px"></v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="invoice-row" style="min-height: 30px">
      <v-col class="pa-0" style="min-height: 30px; max-height: 30px">
        <v-row justify="end" align="center" style="min-height: 30px; max-height: 30px">
          <v-col
            class="flex align-center"
            style="
              max-width: 140px;
              min-width: 140px;
              border: 1px solid lightgrey;
              text-align: center;
              min-height: 30px;
              max-height: 30px;
              line-height: 1.5;
            "
          >
            <span class="font-weight-bold">{{ estMarginDisplay.diff }}</span>
          </v-col>
          <v-col
            class="flex align-center"
            style="
              max-width: 140px;
              min-width: 140px;
              border: 1px solid lightgrey;
              text-align: center;
              min-height: 30px;
              max-height: 30px;
              line-height: 1.5;
            "
          >
            <span class="font-weight-bold">{{ marginDisplay.diff }}</span>
          </v-col>
          <v-col
            align-self="center"
            :class="marginColor"
            style="
              max-width: 140px;
              min-width: 140px;
              border: 1px solid lightgrey;
              min-height: 30px;
              max-height: 30px;
              text-align: center;
              line-height: 1.5;
            "
          >
            <span>{{ marginDisplay.per }}</span>
          </v-col>
          <v-col style="max-width: 150px; min-width: 150px"></v-col>
        </v-row>
      </v-col>
    </v-row>
  </a-card>
</template>

<script>
import money from "@/services/money";
import format from "@/services/format";
import ReportService from "../../service";
export default {
  props: {
    billedTotal: Number,
    estBilledTotal: Number,
    estCommissionTotal: Number,
    receivedCommissionTotal: Number,
    estInvoice: Number,
    totalInvoice: Number,
    partPaidInvoice: Number,
    fullClientInvoiceSet: Boolean,
  },
  computed: {
    estMarginDisplay() {
      // Invoice - Est cost
      // Use Total Invoice - Est cost when client invoice is "Full"
      const val = ReportService.getMargin(
        this.estInvoice,
        this.estBilledTotal || this.billedTotal,
        this.estCommissionTotal
      );

      return {
        val: val ? val.diff : 0,
        diff: this.fullClientInvoiceSet
          ? _formatCost(this.totalInvoice - this.estBilledTotal)
          : _formatCost(val ? val.diff : null),
      };
    },
    marginDisplay() {
      // Invoice - Est cost + comission
      // Use total of payment history if more than set invoice
      // Use Estimated invoice if client invoice isn't "Full"
      const useInvoice = this.fullClientInvoiceSet
        ? this.partPaidInvoice > this.totalInvoice
          ? this.partPaidInvoice
          : this.totalInvoice
        : this.estInvoice;
      const val = ReportService.getMargin(useInvoice, this.billedTotal, this.receivedCommissionTotal);
      return {
        val: val ? val.diff : 0,
        diff: _formatCost(val ? val.diff : null),
        per: val ? `(${Number(val.percentage).toFixed(2)} %)` : "",
      };
    },
    marginColor() {
      if (this.marginDisplay.val === 0) return "grey--text";
      if (this.marginDisplay.val < 0) return "red--text";
      return "green--text";
    },
  },
};

function _formatCost(v) {
  return v ? `$ ${money.format(v, { precision: 2 })}` : "$ --";
}
</script>
