import api from "./api";
import format from "@/services/format";
import money from "@/services/money";
import { BookingRoomService } from "@/modules/bookings.rooms";
import Vue from "vue";

const CLEAR_CACHE = "CLEAR_CACHE";
const SET_REPORT = "SET_REPORT";
const UPDATE_BOOKING = "UPDATE_BOOKING";
const UPDATE_CLIENT = "UPDATE_CLIENT";
const REMOVE_INVOICE = "REMOVE_INVOICE";
const UPDATE_HISTORY = "UPDATE_HISTORY";
const REMOVE_HISTORY = "REMOVE_HISTORY";

const ADD_COM_RECEIVED = "ADD_COM_RECEIVED";
const REMOVE_COM_RECEIVED = "REMOVE_COM_RECEIVED";

const ADD_EST_INVOICE = "ADD_EST_INVOICE";
const REMOVE_EST_INVOICE = "REMOVE_EST_INVOICE";

const namespaced = true;

const reportData = JSON.parse(localStorage.getItem("report_data") || "{}");

const state = {
  booking: reportData.booking || {},
  booking_suppliers: reportData.booking_suppliers || {},
  booking_rooms: reportData.booking_rooms || {},
  booking_days: reportData.booking_days || {},
  supplier_rooms: reportData.supplier_rooms || [],

  client_invoice_history: reportData.client_invoice_history || [],
  payment_history: reportData.payment_history || [],
  commission_received_history: reportData.commission_received_history || {},
  custom_commission: reportData.custom_commission || [],

  est_invoice: reportData.est_invoice || {},
};

const mutations = {
  CLEAR_CACHE(state) {
    Object.assign(state, {
      booking: {},
      booking_suppliers: {},
      booking_rooms: {},
      booking_days: {},
      supplier_rooms: [],
      client_invoice_history: [],
      payment_history: [],
      commission_received_history: [],
      custom_commission: [],

      est_invoice: {},
    });

    localStorage.setItem("report_data", JSON.stringify(state));
  },
  SET_REPORT(state, data) {
    state.booking = data.booking;
    state.booking_suppliers = data.booking_suppliers;
    state.booking_rooms = data.rooms;
    state.booking_days = data.days;
    state.supplier_rooms = data.supplier_rooms;
    state.client_invoice_history = data.client_invoice_history || [];
    state.payment_history = data.payment_history;
    state.commission_received_history = data.commission_received_history;
    // .reduce((obj, item) => {
    // 	obj[item.supplier_id] = item;
    // 	return obj;
    // }, {})

    state.est_invoice = data.est_invoice;

    localStorage.setItem("report_data", JSON.stringify(state));
  },

  UPDATE_BOOKING(state, data) {
    Object.keys(data).forEach((key) => {
      Vue.set(state.booking, key, data[key]);
    });
  },
  UPDATE_CLIENT(state, data) {
    state.client_invoice_history.push(data);
    state.client_invoice_history = state.client_invoice_history.sort((a, b) => {
      if (a.time_stamp < b.time_stamp) return 1;
      if (a.time_stamp > b.time_stamp) return -1;
      return 0;
    });
    localStorage.setItem("report_data", JSON.stringify(state));
  },
  REMOVE_INVOICE(state, client_invoice_id) {
    state.client_invoice_history = state.client_invoice_history.filter((p) => p.id !== client_invoice_id);
    localStorage.setItem("report_data", JSON.stringify(state));
  },
  UPDATE_HISTORY(state, data) {
    state.payment_history.push(data);
    localStorage.setItem("report_data", JSON.stringify(state));
  },
  REMOVE_HISTORY(state, payment_id) {
    state.payment_history = state.payment_history.filter((p) => p.id !== payment_id);
    localStorage.setItem("report_data", JSON.stringify(state));
  },

  ADD_COM_RECEIVED(state, data) {
    state.commission_received_history.push(data);
  },
  REMOVE_COM_RECEIVED(state, received_commission_id) {
    state.commission_received_history = state.commission_received_history.filter(
      (p) => p.id !== received_commission_id
    );
  },

  // Estimated Invoice
  ADD_EST_INVOICE(state, data) {
    state.est_invoice = {
      id: state.est_invoice ? state.est_invoice.id : data.id,
      time_stamp: new Date().getTime(),
      estimate_amount: data.estimate_amount,
    };
    localStorage.setItem("report_data", JSON.stringify(state));
  },
  REMOVE_EST_INVOICE(state) {
    state.est_invoice = null;
  },
};

const actions = {
  clear({ commit }) {
    commit(CLEAR_CACHE);
  },
  getOneReport({ commit }, booking_id) {
    commit(CLEAR_CACHE);
    return api
      .getOneReport(booking_id)
      .then((data) => {
        commit(SET_REPORT, data.data);
        return data.data;
      })
      .catch((err) => err.data);
  },

  updateClientInvoice({ commit }, { booking_id, invoice, tocreate }) {
    return api
      .setClientInvoice(booking_id, invoice, tocreate)
      .then((data) => {
        commit(UPDATE_CLIENT, data.data || invoice);
        return data.data;
      })
      .catch((err) => err.data);
  },
  removeClientInvoice({ commit }, client_invoice_id) {
    return api
      .removeClientInvoice(client_invoice_id)
      .then((data) => commit(REMOVE_INVOICE, client_invoice_id))
      .catch((err) => err.data);
  },
  addClientPayment({ commit }, { booking_id, data }) {
    return api
      .addClientPayment(booking_id, data)
      .then((data) => {
        commit(UPDATE_HISTORY, data.data || data);
        return data.data;
      })
      .catch((err) => err.data);
  },
  removeClientPayment({ commit }, payment_id) {
    return api
      .removeClientPayment(payment_id)
      .then((data) => commit(REMOVE_HISTORY, payment_id))
      .catch((err) => err.data);
  },

  addCommissionReceived({ commit }, { booking_id, supplier_id, data, skipCommit }) {
    return api
      .addCommissionReceived(booking_id, supplier_id, data)
      .then((data) => (skipCommit ? null : commit(ADD_COM_RECEIVED, data.data)))
      .catch((err) => err.data);
  },
  removeCommissionReceived({ commit }, { received_commission_id, skipCommit }) {
    return api
      .removeCommissionReceived(received_commission_id)
      .then((data) => (skipCommit ? null : commit(REMOVE_COM_RECEIVED, received_commission_id)))
      .catch((err) => err.data);
  },

  toggleFinished({ commit }, { booking_id, finished }) {
    return api
      .toggleFinished(booking_id, finished)
      .then((data) => commit(UPDATE_BOOKING, { report_finished: finished }))
      .catch((err) => err.data);
  },
  toggleArchiveReport({ commit }, { booking_id, archive }) {
    return api
      .toggleReportArchive(booking_id, archive)
      .then((data) => commit(UPDATE_BOOKING, { report_archived: archive }))
      .catch((err) => err.data);
  },

  // ESTIMATED INVOICE
  setEstimatedInvoice({ commit }, { booking_id, estimate_amount }) {
    return api
      .setEstimatedInvoice(booking_id, estimate_amount)
      .then((data) => commit(ADD_EST_INVOICE, data.data))
      .catch((err) => err.data);
  },
  removeEstInvoice({ commit }, est_id) {
    return api
      .removeEstimatedInvoice(est_id)
      .then((data) => commit(REMOVE_EST_INVOICE))
      .catch((err) => err.data);
  },
};

const getters = {
  booking_id: (state) => state.booking.id,
  booking_name: (state) => {
    const from = format.formatDate(state.booking.from || "", true);
    const to = format.formatDate(state.booking.to || "");
    return `${state.booking.group_name} (${from} to ${to})`;
  },
  client: (state) => state.booking.client_name,
  client_data: (state) => {
    return {
      name: state.booking.client_name,
      id: state.booking.client_id,
    };
  },
  group_name: (state) => state.booking.group_name,
  booking_dates: (state) => {
    return {
      from: format.formatDate(state.booking.from, true),
      to: format.formatDate(state.booking.to),
    };
  },
  booking_city: (state) => {
    return {
      start: state.booking.where_start,
      end: state.booking.where_end,
    };
  },
  booking_suppliers: (state) => state.booking_suppliers,
  booking_rooms: (state) => state.booking_rooms,
  bookingData: (state) => state.booking.data,

  booking_days: (state) => state.booking_days,

  client_invoice: (state) => {
    if (!state.client_invoice_history.length) return {};
    return state.client_invoice_history[0];
  },
  client_invoice_total: (state) => {
    if (!state.client_invoice_history.length) return 0;
    return state.client_invoice_history.reduce((total, item) => {
      return total + money._num(item.total_invoice);
    }, 0);
  },
  client_invoice_history: (state) => state.client_invoice_history || [],
  payment_history: (state) => state.payment_history || [],

  commission_received_history: (state) => {
    return state.commission_received_history;
  },
  commissionReceivedSupplierHistory: (state) => (supplier_id) => {
    return state.commission_received_history.filter((v) => v.supplier_id === supplier_id);
  },

  est_invoice: (state) => state.est_invoice || {},

  report_finished: (state) => {
    return state.booking.report_finished ? true : false;
  },
  report_archived: (state) => {
    return state.booking.report_archived ? true : false;
  },

  supplier_rooms: (state) => state.supplier_rooms || [],
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
