<template>
  <button-control-header
    :menu-items="menuItems"
    @add="uploadDialogOpen = true"
    :onsave="onsave"
    :ondelete="confirmDelete"
    @setmode="toggleMode"
  >
    <upload-dialog
      v-model="uploadDialogOpen"
      :isimg="isimg"
      :upload-in-progress="uploadInProgress"
      @upload="onUpload"
    />
  </button-control-header>
</template>

<script>
export default {
  props: {
    isimg: Boolean,
    uploadfn: {
      type: Function,
      required: true,
    },

    onsave: Function,
    confirmDelete: Function,

    prependMenu: Array,
  },
  data() {
    return {
      uploadDialogOpen: false,
      uploadInProgress: false,

      items: [
        {
          title: "Delete",
          showMode: "DELETE",
        },
      ],
    };
  },
  computed: {
    menuItems() {
      if (this.prependMenu) return [...this.prependMenu, ...this.items];
      return this.items;
    },
  },
  methods: {
    onUpload(fileList) {
      this.uploadInProgress = true;

      let vm = this;
      this.uploadfn(fileList)
        .then((v) => {
          vm.uploadInProgress = false;
          vm.uploadDialogOpen = false;
        })
        .catch((err) => {
          vm.uploadInProgress = false;
        });
    },

    toggleMode(modeName) {
      var vm = this;
      var modefn = {
        DEFAULT: () => vm.$emit("clear"),
        DELETE: () => vm.$emit("toggleDelete"),
      };

      if (modefn[modeName]) modefn[modeName]();
    },
  },
};
</script>
