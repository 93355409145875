<template>
  <v-row class="grey lighten-3 caption">
    <elwrapper v-if="clientName" label="Client" midtext> {{ clientName }} </elwrapper>
    <elwrapper v-if="groupName" label="Group" midtext> {{ groupName }} </elwrapper>
    <elwrapper v-if="supplierType" label="Type" midtext> {{ supplierType }} </elwrapper>
    <elwrapper v-if="dateDisplay" icon="event" midtext smlabel>
      <span>{{ dateDisplay.from }}</span>
      <span class="mx-3 font-weight-bold">to</span>
      <span>{{ dateDisplay.to }}</span>
    </elwrapper>
    <elwrapper v-if="status" label="Status" midtext>
      <v-icon small :class="status.color">brightness_1</v-icon>
      <span class="pl-2 text-truncate">{{ status.label }}</span>
    </elwrapper>
  </v-row>
</template>

<script>
import format from "@/services/format";
import STATUS_COLORS from "@/config/STATUS_COLORS";
export default {
  props: {
    searchRecord: Object,
  },
  computed: {
    clientName() {
      if (!this.searchRecord) return null;
      return this.searchRecord.client && this.searchRecord.client.name;
    },
    groupName() {
      if (!this.searchRecord) return null;
      return this.searchRecord.group_name;
    },
    supplierType() {
      if (!this.searchRecord) return null;
      return this.searchRecord.supplier_type;
    },

    dateDisplay() {
      if (!this.searchRecord || !this.searchRecord.from) return null;
      return {
        from: this.searchRecord.from && format.formatDate(this.searchRecord.from, true),
        to: this.searchRecord.to && format.formatDate(this.searchRecord.to),
      };
    },

    status() {
      if (!this.searchRecord || !("status" in this.searchRecord)) return null;
      const options = [
        {
          label: "Any status",
          value: 100,
        },
        ...STATUS_COLORS.dotColors(this.searchRecord.supplier_type || "ALL").filter((item) => item),
      ];

      const found = options.find((item) => item.value === this.searchRecord.status);

      return {
        color: found.color,
        label: found.label,
      };
    },
  },
};
</script>
