<template>
  <div class="booking-wrapper">
    <BookingHeader :bookingId="bookingId" :name="client" />

    <v-container class="pa-0" fluid fill-height>
      <NavigationDrawer config="BOOKING" :hookId="bookingId" />
      <v-col
        class="scroll-wrapper"
        style="height: 100%; overflow: auto; padding: 0 !important"
        v-on:scroll="scrollFunction"
      >
        <router-view></router-view>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import { NavigationDrawer } from "@/modules/bsq.wrappers";
import { BookingHeader } from "@/modules/bookings";
export default {
  components: {
    BookingHeader,
    NavigationDrawer,
  },
  data() {
    return {};
  },
  computed: {
    client() {
      return this.$store.getters["BookingStore/active"].client;
    },
    bookingId() {
      return this.$store.getters["BookingStore/active"].id || "0";
    },
  },
  methods: {
    scrollFunction(e) {
      if (this.$store.getters["bookingScrollOn"]) {
        this.$store.dispatch("toggleBookingScroll", false);
        document.querySelector(".scroll-wrapper").scrollTop = this.$store.getters["bookingAllDaysScroll"];
        return;
      }
      if (this.$route.name === "bookings_days") this.$store.dispatch("setBookingPageScrollPos", e.srcElement.scrollTop);
    },
  },
  beforeRouteUpdate(to, from, next) {
    // react to route changes...
    // don't forget to call next()
    document.querySelector(".scroll-wrapper").scrollTop = 0;
    next();
  },
  mounted() {
    // Refreshing page
    // Wrong Environment for supplier, then redirect to database suppliers
    const cached = this.$store.state.BookingStore.cache;
    const env = this.$store.getters["AccountStore/environment"];
    if (cached && cached.env !== env) {
      this.$router.push({
        name: "bookings",
      });
      return;
    }

    var bookingId = this.$route.params.booking_id;

    this.$store
      .dispatch("BookingStore/getOneBooking", bookingId)
      .then((data) => {
        // Insert into other stores
        this.$store.dispatch("BookingFlightStore/setFlights", data.flights || {});
        this.$store.dispatch("BookingRoomStore/setRooms", data.rooms || []);
        this.$store.dispatch("BookingSupplierRoomStore/setSupplierRooms", data.supplier_rooms || []);
        this.$store.dispatch("BookingDayStore/setDays", {
          days: data.days || [],
          suppliers: data.suppliers || {},
        });

        this.$roomingPing.$emit("sync");
      })
      .catch((err) => this.$root.$error(err));

    // Contracts
    this.$store
      .dispatch("BookingContractStore/getDocumentList", bookingId)
      .then((list) => {
        this.$store.dispatch("BookingStore/setContract", list);
      })
      .catch((err) => this.$root.$error);

    this.$store
      .dispatch("BookingInvoiceStore/getDocumentList", bookingId)
      .then((list) => {
        this.$store.dispatch("BookingStore/setInvoice", list);
      })
      .catch((err) => this.$root.$error);

    this.$store
      .dispatch("ReminderStore/getBookingReminders", bookingId)
      .then((mapping) => {
        this.$store.dispatch("BookingReminderStore/setReminders", mapping);
      })
      .catch((err) => this.$root.$error(err));

    // Reset scroll position
    // This will trigger "scroll" above, and then sync it
    // Doesn't actually work correctly first time
    document.querySelector(".scroll-wrapper").scrollTop = this.$store.getters["bookingAllDaysScroll"];
  },
};
</script>

<style lang="scss">
.booking-wrapper {
  height: calc(100% - 48px);

  .booking-title {
    padding: 2px 5px 0;
    background: lightgrey;
    font-weight: 500;
    font-size: 12px;
  }
}
</style>
