<template>
  <div style="height: 100%">
    <v-card class="sign-wrapper grey darken-2">
      <v-row align="center" justify="space-between" class="mx-0 px-2 grey darken-3 pdf-controls">
        <div class="white--text caption d-flex">
          <span class="mr-3">Show:</span>
          <SizeSelect v-model="clientLogoSize" />
          <pcheck label="Client Logo" v-if="clientLogo" v-model="showClientLogo" />
          <pcheck label="Group Name" v-model="showGroupName" />
          <SizeSelect v-model="ourLogoSize" />
          <pcheck label="Supernature Logo" v-model="showInspirit" />
        </div>
        <g-button label="Print" icon="print" @onclick="printDocument" />
      </v-row>

      <div id="printWrapper" class="pdf">
        <div v-if="showClientLogo && clientLogo">
          <img id="clientLogo" :src="hostName + clientLogo" :style="{ 'max-height': clientLogoSize + 'px' }" />
        </div>
        <div v-if="showGroupName" class="airport-text">
          {{ groupName }}
        </div>
        <div v-if="showInspirit">
          <img
            id="inspiritLogo"
            src="@/assets/img/Logo.png"
            class="logo"
            :style="{ 'max-height': ourLogoSize + 'px' }"
          />
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { PrintService } from "@/modules/media.print";
import SizeSelect from "./_SizeSelect";

export default {
  components: {
    SizeSelect,
  },
  data() {
    return {
      showClientLogo: true,
      showGroupName: true,
      showInspirit: true,

      loading: false,

      clientLogo: "",
      clientLogoSize: 150,
      ourLogoSize: 120,
    };
  },
  computed: {
    hostName() {
      return this.$store.getters["host"];
    },
    groupName() {
      const sign = this.$store.getters["BookingStore/data"].airport_sign;
      const group = this.$store.getters["BookingStore/active"].group_name;
      return sign || group;
    },
  },
  methods: {
    printDocument() {
      // const vm = this;
      const title = `${this.groupName}_Sign`;
      const domElement = document.getElementById("printWrapper");
      const styles = `
				body {
					font-family:arial;
				}
				.pdf {
					display: flex;
					flex-direction: column;
					justify-content: center;
					height: 100%;
					background: white;
				    margin: 0 auto;
				}

				.pdf div {
			    	padding: 5px;
			    	margin: 0 auto;
				}

				img {
					max-height: ${this.clientLogoSize}px;
				}

				img.logo {
					max-height: ${this.ourLogoSize}px;
				}

				.pdf div.airport-text {
					margin: 20px auto;
					text-align: center;
					font-size: 100pt;
					font-weight: bold;
					border: 1px solid #000;
					width: 90%
				}
				`;

      // Get and inject images here
      // this.$root.$loading.open('Compiling PDF...')
      Promise.all([PrintService.getImageUri("clientLogo"), PrintService.getImageUri("inspiritLogo")]).then(
        (imgData) => {
          var sourceHTML = domElement.outerHTML;

          // Replace Inspirit Logo with base64 (if present)
          if (imgData[1]) {
            const nuImg = `<img src="${imgData[1]}" class="logo">`;
            sourceHTML = sourceHTML.replace(/<img id="inspiritLogo".*>/gm, nuImg);
          }

          PrintService.extractWordDocumentContent(title, sourceHTML, styles, {
            postOpen: true,
            config: {
              landscape: true,
            },
          });
        }
      );
    },
  },
  mounted() {
    const clientId = this.$store.getters["BookingStore/active"].client_id;
    this.loading = true;
    this.$store
      .dispatch("ClientImageStore/getImageList", clientId)
      .then((v) => {
        const logo = v.find((x) => x.isdefault);
        this.clientLogo = logo && logo.src;
        this.loading = false;
      })
      .catch(this.$root.$error);
  },
};
</script>

<style lang="scss">
.sign-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .pdf-controls {
    width: 100%;
    height: 35px;
    max-height: 35px;
    z-index: 100;
  }

  .pdf {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 500px;
    width: 780px;
    overflow: auto;
    background: white;
    margin: 0 auto;

    div {
      padding: 5px;
      margin: 0 auto;
    }

    img {
      max-height: 150px;
    }

    img.logo {
      max-height: 120px;
    }

    .airport-text {
      margin: 20px auto;
      text-align: center;
      font-size: 5.5em;
      font-weight: bold;
      border: 1px solid #000;
      width: 90%;
    }
  }
}
</style>
