<template>
  <a-card nopad>
    <v-tabs v-model="tab" class="elevation-0" :grow="true">
      <v-tabs-slider></v-tabs-slider>
      <v-tab v-for="item in tabs" :key="item.key">
        <span>{{ item.text }}</span>
        <v-icon small right color="primary" v-if="item.key === active">gps_fixed</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item key="general">
        <GeneralSearch :dbConfig="dbConfig" :isApplied="active === 'general'" @search="(v) => $emit('search', v)" />
      </v-tab-item>
      <v-tab-item key="client">
        <ByClientSearch :isApplied="active === 'client'" @search="(v) => $emit('search', v)" />
      </v-tab-item>
    </v-tabs-items>
  </a-card>
</template>

<script>
import ByClientSearch from "./search/_ByClientSearch";
import GeneralSearch from "./search/_GeneralSearch";

export default {
  props: {
    dbConfig: String,
  },
  components: {
    ByClientSearch,
    GeneralSearch,
  },
  data() {
    return {
      tab: 0,
      tabs: [
        {
          text: "General",
          key: "general",
        },
        {
          text: "By Client",
          key: "client",
        },
      ],
    };
  },

  computed: {
    active() {
      const saved = this.$store.getters["DatabaseStore/activeTab"](this.dbConfig);
      return saved.tab;
    },
  },
  mounted() {
    // Get saved tab (if exists)
    const savedTab = this.$store.getters["DatabaseStore/activeTab"](this.dbConfig);
    const index = this.tabs.findIndex((item) => savedTab.tab === item.key);
    this.tab = index;

    // Query existing search
    if (!savedTab.data) return;
    this.$emit("search", savedTab.data);
  },
};
</script>
