<template>
  <v-row align="center" class="nowrap">
    <v-switch dense hide-details class="ma-0" :input-value="displayValue" @change="eject" @input="eject"></v-switch>
    <div class="py-0 subtitle-2">
      <div v-if="label">{{ displayText }}</div>
    </div>
  </v-row>
</template>

<script>
export default {
  props: {
    label: String,
    offLabel: String,
    icon: String,
    value: [Number, Boolean],
  },
  computed: {
    displayValue() {
      return !!this.value;
    },
    displayText() {
      if (this.offLabel && !this.displayValue) return this.offLabel;
      return this.label;
    },
  },
  methods: {
    eject(v) {
      this.$emit("input", v ? 1 : 0);
    },
  },
};
</script>
