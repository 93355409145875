import axios from "@/plugins/axios";

export default {
  getClientList(searchQuery) {
    return axios.get("/clients/types/search", {
      params: { ...searchQuery },
    });
  },
  getClientCount(searchQuery) {
    return axios.get("/clients/types/count", {
      params: { ...searchQuery },
    });
  },
  getOneClient(client_id) {
    return axios.get(`/clients/${client_id}`);
  },
  addClient(data) {
    return axios.post("/clients", data);
  },
  updateClient(client_id, data, cache) {
    return axios.post(`/clients/${client_id}`, { data, cache });
  },
  deleteClient(client_id) {
    return axios.delete(`/clients/${client_id}`);
  },
};
