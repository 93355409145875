import Database from "./Database";
import DatabaseView from "./DatabaseView";
import SupplierCreate from "./SupplierCreate";
import SupplierMassAdd from "./SupplierMassAdd";
import ProductsNew from "./ProductsNew";
import ClientsCreate from "./ClientsCreate";

export default [
  {
    path: "/database",
    name: "database",
    component: Database,
    meta: {
      tabAccess: "DATABASE",
    },
  },
  {
    path: "/database/:view/:sub",
    name: "database_view",
    component: DatabaseView,
    meta: {
      showProductYear: true,
      tabAccess: "DATABASE",
    },
  },
  {
    path: "/suppliers_mass_add",
    name: "suppliers_mass_add",
    component: SupplierMassAdd,
    meta: {
      tabAccess: "DATABASE",
    },
  },
  {
    path: "/suppliers_new",
    name: "suppliers_new",
    component: SupplierCreate,
    meta: {
      tabAccess: "DATABASE",
    },
  },
  {
    path: "/products_new",
    name: "products_new",
    component: ProductsNew,
    meta: {
      showProductYear: true,
      tabAccess: "DATABASE",
    },
  },
  {
    path: "/client_new",
    name: "clients_new",
    component: ClientsCreate,
    meta: {
      tabAccess: "DATABASE",
    },
  },
];
