import Vue from "vue";

import ACard from "./ACard";
import AGroup from "./AGroup";
import AHoverMenu from "./AHoverMenu";
import ATruncate from "./ATruncate";
import DNoDisplay from "./DNoDisplay";
import DHeader from "./DHeader";

Vue.component("a-card", ACard);
Vue.component("a-group", AGroup);
Vue.component("a-hover-menu", AHoverMenu);
Vue.component("a-truncate", ATruncate);
Vue.component("d-no-display", DNoDisplay);
Vue.component("d-header", DHeader);
