<template>
  <div class="booking-table">
    <div class="caption white text-right px-5" v-if="!completed">
      <span>* Scroll for more</span>
    </div>

    <BookingGroupedTable v-if="grouped" :query="query" :height="height" @setComplete="(v) => (completed = v)" />

    <BookingMainTable v-else :query="query" :height="height" @setComplete="(v) => (completed = v)" />
  </div>
</template>

<script>
import BookingGroupedTable from "./tables/_BookingGroupedTable";
import BookingMainTable from "./tables/_BookingMainTable";
export default {
  props: {
    grouped: Boolean,
    query: Object,
    height: String,
  },
  components: {
    BookingGroupedTable,
    BookingMainTable,
  },
  data() {
    return {
      completed: false,
    };
  },
  computed: {},
  mounted() {
    //this.queryDatabase(null, true);
  },
};
</script>

<style lang="scss">
.booking-table {
  .theme--light.v-data-table thead tr th {
    color: black;
  }
}

.database-table {
  .database-row {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
