import format from "@/services/format";
import { ProductService } from "@/modules/products";

export default {
  extractText,
};

function extractText(key, before, after, parentKey) {
  // Skip these
  if (["booking_id", "lastSave"].includes(key)) return [];

  let b = before[key];
  let a = after[key];

  if (b === a) return [];

  // Make sure not comparing objects and strings
  if (typeof b === "object" && a && typeof a === "string") a = JSON.parse(a);
  if (typeof a === "object" && b && typeof b === "string") b = JSON.parse(b);

  // SPECIAL -- PRICE
  if (["occupancy_rate", "parking", "porterage", "rate_history", "resort_fee"].includes(key)) {
    let first = b.type === "TEXT" ? b.value : b.value ? ProductService.formatPrice(b) : "--";
    let last = a.type === "TEXT" ? a.value : a.value ? ProductService.formatPrice(a) : "--";

    return [`${formatKey(key)} from ${first} to ${last}`];
  }

  // Primary contact
  if (key === "primary_contact_id") return ["primary contact"];

  // DATES
  if (["date", "from", "to"].includes(key)) {
    let origin = ["arrival", "departure"].includes(parentKey) ? `${parentKey} ` : "";
    return [`${origin}${key} from ${format.formatDate(b)} to ${format.formatDate(a)}`];
  }

  // Using objects, then extract recursively
  if (typeof (b || a) === "object") {
    return Object.keys(b || a).reduce((arr, subkey) => {
      return [...arr, ...extractText(subkey, b, a, key)];
    }, []);
  }

  return [`${formatKey(key)} from ${b} to ${a}`];
}

function formatKey(key) {
  return (
    {
      env: "environment",
      occupancy_rate: "occupancy",
      pax_num: "PAX",
      pid: "id",
      rate_history: "rate",
      supplier_type: "type",
      zip_code: "zip code",

      // ROOMING
      SINGLE_DK: "Single rooms",
      DOUBLE_QK: "Double rooms",
      DOUBLE_DOUBLE: "Double (Twin) rooms",
      TRIPLE: "Triple rooms",
      QUAD: "Quad rooms",
      "SUPPLIER.TOUR_GUIDE": "Tour Guide rooms",
      "SUPPLIER.TRANSPORT": "Bus Driver rooms",
    }[key] || key
  );
}
