<template>
  <div>
    <div>{{ count }}{{ extraDisplay }}</div>
  </div>
</template>

<script>
export default {
  props: {
    count: [String, Number],
    supcount: Object,
  },
  components: {},
  computed: {
    extraDisplay() {
      const tour_guides = this.supcount.tour_guides || 0;
      const transport = this.supcount.transport || 0;

      const count = (this.count && tour_guides + transport) || 0;
      return count ? `+${count}` : "";
    },
  },
  methods: {},
};
</script>
