<template>
  <v-row class="a-field">
    <v-col :class="{ smlabel: smlabel, widelabel }" :align-self="align" class="label py-0" :style="cuslabel">
      <div class="label-wrapper-label">
        <v-icon v-if="preicon" small left>{{ preicon }}</v-icon>
        <v-icon v-if="icon" small left>{{ icon }}</v-icon>
        <span class="subtitle-2">{{ label }}</span>
      </div>
    </v-col>
    <v-col :align-self="midtext ? 'center' : 'start'" class="py-0">
      <slot></slot>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    label: String,
    icon: String,
    preicon: String,
    texttop: Boolean,
    midtext: Boolean,
    smlabel: Boolean,
    widelabel: Boolean,

    align: String,
    cuslabel: String,
  },
};
</script>
