<template>
  <div class="seasonal-row">
    <div class="seasonal-row-date">{{ getDate(rate.mstart, rate.dstart) }}</div>
    <div class="pl-1 pr-2 subtitle-2">to</div>
    <div class="seasonal-row-date">{{ getDate(rate.mend, rate.dend) }}</div>
    <div class="pl-1 pr-2 subtitle-2">at</div>
    <TotalCalc
      :env="env"
      :rate="rate.rate"
      :tax="tax"
      :taxInclusive="taxInclusive"
      :occupancy="occupancy"
      :resortFee="resortFee"
      :extraList="extraList"
    />
  </div>
</template>

<script>
import TotalCalc from "../_TotalCalc";
import MONTHS from "@/config/MONTHS";
export default {
  props: {
    env: String,
    rate: Object,
    tax: [Number, Object, String],
    taxInclusive: [Number, Boolean],
    occupancy: [Number, Object],
    resortFee: [Number, Object],
    extraList: [Array], // => Objects
  },
  components: {
    TotalCalc,
  },
  computed: {},
  methods: {
    getDate(m, d) {
      if (!m) return "";
      return `${MONTHS[m - 1].short}${d ? " / " + d : ""}`;
    },
  },
};
</script>

<style lang="scss">
.seasonal-row {
  display: flex;
  flex-wrap: nowrap;

  .seasonal-row-date {
    width: 60px;
  }
}
</style>
