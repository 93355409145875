<template>
  <v-container class="pa-0" fluid>
    <NoEmailAlert />
    <v-col style="height: 100%; overflow: auto; max-width: 100%; width: 100%; min-width: 100%">
      <v-row justify="space-between">
        <a-card nopad>
          <g-button :disabled="emailView === 0" label="1 - Select" class="mx-1" @onclick="emailView = 0" />
          <g-button :disabled="emailView === 1" label="2 - Compose" @onclick="emailView = 1" />
          <g-button :disabled="emailView === 2" label="3 - Preview" @onclick="emailView = 2" />
        </a-card>

        <a-card nopad v-if="emailView !== 0">
          <EmailSendButton
            :disabled="!canSend"
            :subject="formData.subject"
            :attachments="formData.attachments"
            :compileMailFn="compileMailFn"
            :postSend="_updateBookingSupplierStatuses"
            :onFinishFn="returnTo"
          />
        </a-card>
      </v-row>

      <v-row v-show="emailView === 0">
        <SupplierBookingSelect style="flex: 1" @setBookingRef="setBookingRef" v-model="selectedBookings" />
      </v-row>
      <v-row v-show="emailView === 1">
        <a-card style="flex: 1">
          <EmailWrapper
            :supplierId="supplierId"
            mailType="MASS"
            selectedDate="ALL"
            @setcontacts="(v) => (selectedContacts = v)"
            v-model="formData"
          />
        </a-card>
      </v-row>
      <v-row v-if="emailView === 2">
        <a-card style="flex: 1">
          <PreviewEmailWrapper
            :subject="formData.subject"
            :attachments="formData.attachments"
            :compileMailFn="compileMailFn"
          />
        </a-card>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import { PreviewEmailWrapper, EmailTemplateService } from "@/modules/admin.email_template";
import { ProductService } from "@/modules/products";
import { EmailService, EmailSendButton, NoEmailAlert } from "@/modules/email";
import { BookingRoomService } from "@/modules/bookings.rooms";
import { EmailWrapper, BookingEmailService } from "@/modules/bookings.email";
import { SupplierBookingSelect } from "@/modules/bookings.supplier_email";
export default {
  components: {
    PreviewEmailWrapper,
    SupplierBookingSelect,
    EmailWrapper,
    EmailSendButton,
    NoEmailAlert,
  },
  data() {
    return {
      formData: {
        attachments: [],
      },
      selectedBookings: {
        // booking id
      },
      selectedContacts: [],

      emailView: 0,
    };
  },
  computed: {
    supplierId() {
      const savedTab = this.$store.getters["BookingEmailStore/activeTab"];
      const record = this.$store.getters["BookingEmailStore/" + savedTab + "Search"];
      return record ? record.supplier_id : null;
    },
    canSend() {
      return this.selectedContacts.length;
    },
  },
  methods: {
    returnTo() {
      this.$router.push({
        name: "bookings",
      });
    },

    setBookingRef(bookingList) {
      this.bookingRef = bookingList.reduce((map, booking) => {
        map[booking.id] = booking;
        return map;
      }, {});
    },

    roomInfo(booking) {
      // Main Page
      const mainBreakDown = (booking.data && booking.data.room_num) || {};
      const custom = booking.rooming_data || [];

      let mainTotal = 0;
      Object.values(mainBreakDown).forEach((value) => {
        if (value) mainTotal += Number(value);
      });

      let roomingListTotal = 0;
      custom.forEach((item) => (roomingListTotal += Number(item.pax)));

      let roomBreakdown = {};
      custom.forEach((item) => {
        if (!roomBreakdown[item.type]) {
          roomBreakdown[item.type] = 1;
        } else {
          roomBreakdown[item.type] += 1;
        }
      });

      const hasRoomingList = roomBreakdown && Object.values(roomBreakdown).length;

      return {
        total: roomingListTotal || mainTotal,
        breakdown: hasRoomingList ? roomBreakdown : mainBreakDown,
      };
    },

    compileMailFn() {
      const contacts = [...this.selectedContacts];
      let message = this.formData.message || "";
      let subject = this.formData.subject || "";

      contacts.forEach((selectedSupplier) => {
        // ONLY REPLACING CONTACTS
        message = EmailTemplateService.replaceContact(
          selectedSupplier.contacts.nameList,
          message,
          this.formData.language
        );
        message = EmailTemplateService.replaceSupplier(message, selectedSupplier);
        message = EmailTemplateService.bookingFillLoop(
          message,
          Object.values(this.selectedBookings),
          this.supplierId,
          false,
          subject
        );
        subject = EmailTemplateService.bookingFillLoop(
          subject,
          Object.values(this.selectedBookings),
          this.supplierId,
          false,
          subject
        );

        selectedSupplier.message = message;
        selectedSupplier.customSubject = subject;
      });

      return Promise.resolve(contacts);
    },

    _updateBookingSupplierStatuses() {
      const ss = this.selectedBookings;
      let bookingSupplierIdList,
        updateQuery = [];

      // Object.keys(ss.contacts).forEach(bookingId => {
      // 	let booking = this.bookingRef[bookingId];
      // 	bookingSupplierIdList = EmailService.getBookingSupplierIdToUpdate(ss.contacts[bookingId], booking.booking_suppliers, ss.ignoreProducts[bookingId] || {});

      // 	updateQuery.push(
      // 		BookingEmailService.updateBookingSupplierStatus({
      // 			booking_id: bookingId,
      // 			bookingSupplierIdList,
      // 			status: this.formData.status,
      // 			lockedPriceMapping: this.getLockedPriceMapping(bookingSupplierIdList, booking.booking_suppliers)
      // 		})
      // 	)
      // })

      return Promise.all(updateQuery).catch((err) => {
        throw err;
      });
    },

    getLockedPriceMapping(bookingSupplierIdList, bookingSuppliers) {
      let endMap = {};
      bookingSupplierIdList.forEach((id) => {
        endMap[id] = BookingEmailService.getOneLockedPricing(bookingSuppliers[id], this.$store.getters);
      });

      return endMap;
    },
  },
  mounted() {
    this.$store.dispatch("EmailTemplateStore/getTemplates", true).catch((err) => this.$root.$error(err));
  },
};
</script>
