<template>
  <v-dialog :value="value" persistent max-width="500">
    <v-card>
      <v-card-title class="subtitle-2">Change Product Information</v-card-title>
      <v-card-text>
        <v-row class="my-4" justify="space-between">
          <div>
            <a-select widelabel label="Group or Fit" :items="groupFitOptions" v-model="groupFitValue" />
            <elwrapper v-if="showGFResetGroupCount" widelabel label="Min Group Size">
              <f-number v-model="groupSize" />
            </elwrapper>
          </div>
          <g-button type="primary" label="Update" @onclick="updateGF" />
        </v-row>

        <v-row class="my-4" v-if="groupOrFIT === 'GROUP'">
          <elwrapper widelabel label="Min Group Size">
            <f-number v-model="groupSize" />
          </elwrapper>
          <g-button type="primary" label="Update" @onclick="$emit('update', { key: 'group_size', value: groupSize })" />
        </v-row>

        <v-row class="my-4" justify="space-between">
          <div>
            <a-select widelabel label="Product Type" :items="productTypes" v-model="productType" />
          </div>
          <g-button type="primary" label="Update" @onclick="$emit('switch', productType)" />
        </v-row>
      </v-card-text>
      <v-card-actions>
        <g-button label="Cancel" @onclick="$emit('input', false)" />
        <div class="flex-grow-1"></div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ProductMenu } from "@/config/menus";
export default {
  props: {
    value: Boolean,
    currentType: String,
    groupCount: [Number, String],
  },
  data() {
    return {
      groupFitValue: "",
      groupSize: "",
      productType: "",

      groupFitOptions: [
        {
          label: "Group",
          value: "GROUP",
        },
        {
          label: "FIT",
          value: "FIT",
        },
        {
          label: "Group/FIT",
          value: "GROUP_FIT",
        },
      ],
    };
  },
  watch: {
    groupOrFIT(val) {
      this.groupFitValue = val;
    },
  },
  computed: {
    productId() {
      return this.$store.getters["ProductStore/id"];
    },
    groupOrFIT() {
      const current = this.$store.getters["ProductStore/group_fit"];
      return current;
    },
    showGFResetGroupCount() {
      if (this.groupOrFIT === "GROUP") return false;
      return this.groupFitValue === "GROUP";
    },
    productTypes() {
      const supplierTypes = this.$store.getters["ProductStore/supplier_types"];

      let list = [];
      let vm = this;

      if (!supplierTypes && !supplierTypes.length) return list;

      ProductMenu.forEach((item) => {
        if (!item.restrictTo || vm.contains(item.restrictTo, supplierTypes)) {
          list.push({
            label: item.title,
            value: item.module,
          });
        }
      });

      return list;
    },
  },
  methods: {
    contains(restrictTo, options) {
      return restrictTo.filter((item) => {
        return options.indexOf(item) > -1;
      }).length;
    },
    updateGF() {
      this.$emit("updateGF", {
        group_fit: this.groupFitValue,
        group_size: this.groupSize,
      });
    },
  },
  mounted() {
    this.groupSize = this.groupCount;
    this.productType = this.currentType;
  },
};
</script>
