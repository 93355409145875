<template>
  <v-dialog :value="value" width="1500px" persistent>
    <v-card style="padding: 0px">
      <v-card-title>Suppliers Setting</v-card-title>
      <v-card-text class="black--text">
        <v-row>
          <v-col align="left"><h3>Date</h3></v-col>
          <v-col align="left" v-for="(column, index) in convertedData.columns" :key="index">
            <div>
              <h3>{{ column.name }}</h3>
            </div>
          </v-col>
        </v-row>
        <div v-for="(day, dayIndex) in convertedData.days" :key="dayIndex">
          <v-row>
            <v-col align-self="center"
              ><strong>{{ day.header }}</strong></v-col
            >
            <v-col align-self="center" v-for="(include, includeIndex) in day.includes" :key="includeIndex">
              <div class="d-flex align-center">
                <input type="checkbox" :checked="include" @input="updateIncludeByDay(dayIndex, includeIndex)" />
                <span class="caption px-3">Include this day</span>
              </div>
            </v-col>
          </v-row>
          <v-row
            v-for="(variableCost, variableCostIndex) in day.variableCosts"
            :key="variableCostIndex"
            :class="{ 'grey lighten-3': variableCostIndex % 2 === 0 }"
          >
            <v-col align-self="center">
              <div class="mx-3">{{ variableCost.name }}</div>
            </v-col>
            <v-col align-self="center" v-for="(include, includeIndex) in variableCost.includes" :key="includeIndex">
              <div class="d-flex align-center mx-3">
                <input
                  type="checkbox"
                  :checked="include"
                  :disabled="isDisabled(dayIndex, includeIndex)"
                  @input="updateIncludeByVariableCost(dayIndex, variableCostIndex, includeIndex)"
                />
                <span class="caption px-3">Include</span>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions>
        <g-button label="Cancel" @onclick="close" />
        <div class="flex-grow-1"></div>
        <g-button label="Save" @onclick="save" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    data: Object,
  },
  data() {
    return {
      convertedData: null,
    };
  },
  methods: {
    isDisabled(dayIndex, includeIndex) {
      return !this.convertedData.days[dayIndex].includes[includeIndex];
    },
    updateIncludeByDay(dayIndex, includeIndex) {
      const newValue = !this.convertedData.days[dayIndex].includes[includeIndex];
      this.convertedData.days[dayIndex].includes.splice(includeIndex, 1, newValue);
      if (!newValue) {
        this.convertedData.days[dayIndex].variableCosts.forEach((variableCost) => {
          variableCost.includes[includeIndex] = false;
        });
      }
    },
    updateIncludeByVariableCost(dayIndex, variableCostIndex, includeIndex) {
      const newValue = !this.convertedData.days[dayIndex].variableCosts[variableCostIndex].includes[includeIndex];
      this.convertedData.days[dayIndex].variableCosts[variableCostIndex].includes.splice(includeIndex, 1, newValue);
    },
    convertData(data) {
      const columns = data.fixedCosts
        .filter((cost) => cost.productType === "TOUR" || cost.productType === "TRANS")
        .map((cost) => ({
          uuid: cost.uuid,
          name: `${cost.supplier?.label ?? ""} / ${cost.product?.label ?? ""}`,
        }));
      const days = data.variableCosts.map((variableCost, index) => {
        const includes = columns.map((column) =>
          data.fixedCosts.some((fixedCost) => fixedCost.uuid === column.uuid && fixedCost.days?.includes(index))
        );
        const variableCosts = variableCost.items
          .filter((cost) => cost.productType === "ROOM" || cost.productType === "MEAL")
          .map((item) => ({
            uuid: item.uuid,
            name: `${item.supplier?.label ?? ""} / ${item.product?.label ?? ""}`,
            includes: columns.map((column) => (item.fixedCostUUIDs ?? []).includes(column.uuid)),
          }));

        var day = index;
        var header = "Day " + (index + 1);
        const filteredItems = variableCost.items.filter(
          (item) => item.productType === "ROOM" || item.productType === "MEAL"
        );
        const uniqueSupplierCities = new Set();
        filteredItems.forEach((item) => uniqueSupplierCities.add(item.supplierCity));
        header += " : " + Array.from(uniqueSupplierCities).join(" / ");

        return { day: day, header: header, includes, variableCosts };
      });

      this.convertedData = { columns, days };
    },
    close() {
      this.convertData(this.data);
      this.$emit("input", false);
    },
    save() {
      const fixedCostsMap = {};
      const dayMap = {};
      this.data.fixedCosts.forEach((fixedCost) => {
        dayMap[fixedCost.uuid] = fixedCost.productType === "TRANS" || fixedCost.productType === "TOUR" ? [] : [0];
      });
      this.convertedData.days.forEach((day) => {
        day.includes.forEach((included, index) => {
          if (included) {
            dayMap[this.convertedData.columns[index].uuid].push(day.day);
          }
        });
        day.variableCosts.forEach((variableCost) => {
          const variableCostUUID = variableCost.uuid;
          const fixedCostUUIDs = variableCost.includes
            .map((included, index) => (included ? this.convertedData.columns[index].uuid : null))
            .filter((uuid) => uuid !== null);
          fixedCostsMap[variableCostUUID] = fixedCostUUIDs;
        });
      });
      this.data.variableCosts.forEach((variableCost) => {
        variableCost.items.forEach((item) => {
          item.fixedCostUUIDs = fixedCostsMap[item.uuid];
        });
      });
      this.data.fixedCosts.forEach((fixedCost) => {
        fixedCost.days = dayMap[fixedCost.uuid];
      });
      this.convertData(this.data);
      this.$emit("input", false);
      this.$emit("update", this.data);
    },
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.convertData(newValue);
      },
    },
  },
};
</script>
