<template>
  <v-row class="contact-row" :class="{ 'primary--text blue lighten-4': selected }" @click="toggleSelect">
    <v-col style="max-width: 30px">
      <input type="checkbox" :checked="selected" />
    </v-col>
    <v-col>
      <span>{{ contact.name }}</span>
      <span class="mx-2">({{ contact.email }})</span>
      <span class="mx-2">{{ contact.title ? `(${contact.title})` : "" }}</span>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    selectedContacts: Object,
    contact: Object,
  },
  computed: {
    selected() {
      if (!this.selectedContacts) return false;
      return this.selectedContacts[this.contact.id];
    },
  },
  methods: {
    toggleSelect(e) {
      e.stopPropagation();

      this.$emit("onclick", this.contact);
    },
  },
};
</script>
