import FinanceHome from "./FinanceHome";
import FinanceReportPage from "./FinanceReportPage";
import InformationView from "./InformationView";
import DocumentsView from "./DocumentsView";

export default [
  {
    path: "/finances",
    name: "finances",
    component: FinanceHome,
    meta: {
      tabAccess: "REPORTING",
    },
  },
  {
    path: "/finances/report/:booking_id",
    component: FinanceReportPage,
    children: [
      {
        path: "",
        name: "finance_report",
        component: InformationView,
        meta: {
          tabAccess: "REPORTING",
        },
      },
      {
        path: "documents",
        name: "finance_documents",
        component: DocumentsView,
        meta: {
          tabAccess: "REPORTING",
        },
      },
    ],
  },
];
