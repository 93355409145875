import api from "./api";
import format from "@/services/format";

const SET_ROOMS = "SET_ROOMS";
const ADD_ROOM = "ADD_ROOM";
const UPDATE_ROOM = "UPDATE_ROOM";
const DELETE_ROOM = "DELETE_ROOM";

const namespaced = true;

const state = {
  supplier_rooms: JSON.parse(localStorage.getItem("booking_supplier_rooms") || "[]"),
};

const mutations = {
  SET_ROOMS(state, data) {
    state.supplier_rooms = data;
    localStorage.setItem("booking_supplier_rooms", JSON.stringify(data || []));
  },
  ADD_ROOM(state, data) {
    state.supplier_rooms.push(data);
    localStorage.setItem("booking_supplier_rooms", JSON.stringify(state.supplier_rooms || []));
  },
  UPDATE_ROOM(state, { supplierRoomId, data }) {
    const updating = state.supplier_rooms.find((sroom) => sroom.id === supplierRoomId);
    Object.assign(updating, data);
  },
  DELETE_ROOM(state, booking_supplier_id) {
    state.supplier_rooms = state.supplier_rooms.filter((item) => {
      return item.id !== booking_supplier_id;
    });
    localStorage.setItem("booking_supplier_rooms", JSON.stringify(state.supplier_rooms || []));
  },
};

const actions = {
  setSupplierRooms({ commit }, data) {
    commit(SET_ROOMS, data);
  },
  addSupplierRoom({ commit }, { booking_id, supplierRoomData }) {
    return api
      .addSupplierRoom(booking_id, supplierRoomData)
      .then((data) => {
        commit(ADD_ROOM, data.data);
        return data.data;
      })
      .catch((err) => {
        throw err;
      });
  },
  updateSupplierRoom({ commit }, { supplierRoomId, data }) {
    return api
      .updateSupplierRoom(supplierRoomId, data)
      .then((_) => {
        commit(UPDATE_ROOM, { supplierRoomId, data });
      })
      .catch((err) => {
        throw err;
      });
  },
  removeSupplierRoom({ commit }, supplier_room_id) {
    return api
      .removeSupplierRoom(supplier_room_id)
      .then(() => {
        commit(DELETE_ROOM, supplier_room_id);
      })
      .catch((err) => {
        throw err;
      });
  },
};

const getters = {
  supplier_rooms: (state) => state.supplier_rooms,
  supplierRoomsRef: (state) => {
    return state.supplier_rooms.reduce((obj, item) => {
      obj[item.id] = item;
      return obj;
    }, {});
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
