import axios from "@/plugins/axios";

export default {
  getOneReport(booking_id) {
    return axios.get(`/finances/report/${booking_id}`);
  },

  toggleFinished(booking_id, finished) {
    return axios.post(`/bookings/${booking_id}`, {
      booking: {
        report_finished: finished ? 1 : 0,
      },
      cache: {
        report_finished: finished ? 0 : 1,
      },
    });
  },
  toggleReportArchive(booking_id, archive) {
    return axios.post(`/bookings/${booking_id}`, {
      booking: {
        report_archived: archive ? 1 : 0,
      },
      cache: {
        report_archived: archive ? 0 : 1,
      },
    });
  },

  setClientInvoice(booking_id, invoice, tocreate, cache) {
    return axios.post(`/finances/report/${booking_id}/client_invoice`, {
      invoice,
      tocreate,
      cache: cache || {},
    });
  },
  removeClientInvoice(client_invoice_id) {
    return axios.delete(`/client_invoices/${client_invoice_id}`);
  },
  setSupplierReconcilation(
    booking_id,
    supplier_id,
    type_as,
    data,
    tocreate,
    cache
  ) {
    return axios.post(
      `/finances/report/${booking_id}/supplier/${type_as}/${supplier_id}`,
      { data, tocreate, cache: cache || {} }
    );
  },
  removeSupplierInvoice(supplier_invoice_id) {
    return axios.delete(`/supplier_invoices/${supplier_invoice_id}`);
  },
  addClientPayment(booking_id, data) {
    return axios.post(`/finances/report/${booking_id}/payment_history`, data);
  },
  removeClientPayment(payment_id) {
    return axios.delete(`/finances/client_payment/${payment_id}`);
  },

  addCommissionReceived(booking_id, supplier_id, data) {
    return axios.post(`/finances/report/${booking_id}/commission/${supplier_id}`, data);
  },
  removeCommissionReceived(received_commission_id) {
    return axios.delete(`/finances/report/received_commissions/${received_commission_id}`);
  },
  //CUSTOM COMMISSIONS
  setCustomCommission(booking_id, supplier_id, type_as, data, tocreate, cache) {
    return axios.post(`/finances/report/${booking_id}/custom_commission/${type_as}/${supplier_id}`, {
      data,
      tocreate,
      cache: cache || {},
    });
  },
  removeCustomCommission(custom_commission_id) {
    return axios.delete(`/custom_commission/${custom_commission_id}`);
  },

  // EXTRA EXPENSES
  addExtraExpenses(booking_id, data) {
    return axios.post(`/finances/${booking_id}/extra_expenses`, data);
  },
  updateExtraExpense(expense_id, data) {
    return axios.post(`/extra_expenses/${expense_id}`, data);
  },
  removeExtraExpenses(expense_id) {
    return axios.delete(`/extra_expenses/${expense_id}`);
  },
  addProductExpense(booking_id, data) {
    return axios.post(`/finances/${booking_id}/product_expenses`, data);
  },
  removeCustomProduct(expense_id) {
    return axios.delete(`/product_expenses/${expense_id}`);
  },
  addDepositExpense(booking_id, data) {
    return axios.post(`/finances/${booking_id}/deposit_expenses`, data);
  },
  removeDepositExpense(expense_id) {
    return axios.delete(`/deposit_expenses/${expense_id}`);
  },

  // ESTIMATED INVOICE
  // Add or update estimated invoice
  setEstimatedInvoice(booking_id, estimate_amount) {
    return axios.post(`/finances/report/${booking_id}/estimated_invoice`, {
      estimate_amount,
    });
  },
  removeEstimatedInvoice(est_id){ 
    return axios.delete(`/estimated_invoices/${est_id}`)
  }
};
