<template>
  <v-row class="caption font-weight-bold" :class="textColor" justify="center" align="center" style="height: 100%">
    <div class="d-flex align-center" style="position: absolute; left: -5px; top: 5px">
      <CommissionReceivedDialog
        :external="external"
        :bookingId="bookingId"
        :supplierId="supplierId"
        :commission="commission"
        :commissionTotal="commissionTotal"
        :customCommissionAmount="customCommissionAmount"
        :commissionReceivedHistory="commissionReceivedHistory"
        @toggle-commission-received="$emit('toggle-commission-received')"
        @add-row="(v) => $emit('add-commission-history', v)"
      />
    </div>

    <CommissionDisplay
      :commission="commission"
      :commissionTotal="commissionTotal"
      :customCommissionAmount="customCommissionAmount"
    />

    <div class="d-flex align-center" style="position: absolute; right: -5px; top: 5px">
      <CommissionCustomDialog
        :external="external"
        :bookingId="bookingId"
        :supplierId="supplierId"
        :supplierName="supplierName"
        :customCommissionId="customCommissionId"
        :customCommission="customCommissionAmount"
        :compiledCommissionTotal="commissionTotal"
        @sync-commission="(v) => $emit('sync-commission', v)"
      />
    </div>
  </v-row>
</template>

<script>
import money from "@/services/money";
import CommissionReceivedDialog from "./commissions/CommissionReceivedDialog";
import CommissionCustomDialog from "./commissions/CommissionCustomDialog";
import CommissionDisplay from "./commissions/CommissionDisplay";
export default {
  props: {
    external: Boolean,
    bookingId: String,
    supplierId: String,
    supplierName: String,
    typeAs: String,
    // Commission
    commission: [Number, Boolean],
    commissionTotal: String,
    commissionReceivedHistory: Array,
    customCommissionList: Array,
  },
  components: {
    CommissionCustomDialog,
    CommissionReceivedDialog,
    CommissionDisplay,
  },
  computed: {
    customCommission() {
      let num = (this.customCommissionList || []).find(
        (item) => item.supplier_id === this.supplierId && item.type_as === this.typeAs
      );
      return num ? num : {};
    },
    customCommissionId() {
      return this.customCommission.id || null;
    },
    customCommissionAmount() {
      return this.customCommission.custom_amount || null;
    },
    textColor() {
      const fullCommissionReceived = (this.commissionReceivedHistory || []).find((v) => v.received_type === "FULL");
      const onlyPartReceived = (this.commissionReceivedHistory || []).find((v) => v.received_type === "PARTIAL");
      return fullCommissionReceived ? "blue--text" : onlyPartReceived ? "orange--text" : "";
    },
  },
};
</script>
