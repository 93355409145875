<template>
  <div>
    <v-toolbar dense flat>
      <v-toolbar-title>
        <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
      </v-toolbar-title>
    </v-toolbar>

    <v-container fluid class="justify-center">
      <CreateProductForm :goback="returnTo" :supplier="supplier">
        <SupplierSelectField v-model="supplier" />
      </CreateProductForm>
    </v-container>
  </div>
</template>

<script>
import { SupplierSelectField } from "@/modules/database";
import { CreateProductForm } from "@/modules/products";
export default {
  components: {
    CreateProductForm,
    SupplierSelectField,
  },
  data() {
    return {
      supplier: {},

      breadcrumbs: [
        {
          text: "Database",
          to: { name: "database" },
        },
        {
          text: "Products",
          to: { name: "database_view", params: { view: "products" } },
        },
        {
          text: "New",
        },
      ],

      returnTo: {
        name: "database_view",
        params: {
          view: "products",
          sub: "ROOM",
        },
      },
    };
  },
};
</script>
