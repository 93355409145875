<template>
  <a-card nopad>
    <a-field :config="config" v-bullets v-model="notes" />
  </a-card>
</template>

<script>
export default {
  data() {
    return {
      config: {
        label: "Notes",
        inputType: "TEXTAREA",
        rows: 5,
        bullets: true,
      },
    };
  },
  computed: {
    notes: {
      get() {
        return this.get("notes");
      },
      set(newValue) {
        return this.set("notes", newValue);
      },
    },
  },
  methods: {
    get(val) {
      return this.$store.getters["QuoteStore/" + val];
    },
    set(key, val) {
      return this.$store.dispatch("QuoteStore/update", { key, val });
    },
  },
};
</script>
