import Vue from "vue";

import FDate from "./FDate";
import FDropdown from "./FDropdown";
import FNumber from "./FNumber";
import FPercent from "./FPercent";
import FText from "./FText";
import FTextarea from "./FTextarea";
import FTime from "./FTime";

Vue.component("f-date", FDate);
Vue.component("f-dropdown", FDropdown);
Vue.component("f-number", FNumber);
Vue.component("f-percent", FPercent);
Vue.component("f-text", FText);
Vue.component("f-textarea", FTextarea);
Vue.component("f-time", FTime);
