<template>
  <v-tooltip top :disabled="!disabled">
    <template v-slot:activator="{ on, attrs }">
      <v-row class="nowrap" align="start" no-gutters v-on="on" v-bind="attrs">
        <v-col cols="5" class="pa-0">
          <e-number
            class="pa-0"
            :disabled="disabled"
            :env="env"
            :value="value"
            :type="value.type"
            @input="ejectNumber"
          />
        </v-col>
        <v-col cols="1" align="center" class="subtitle-2" style="margin-top: 6px">
          <span>/</span>
        </v-col>
        <v-col cols="6" class="pa-0">
          <v-autocomplete
            dense
            outlined
            hide-details
            item-text="label"
            item-value="value"
            :disabled="disabled"
            :items="dropdown"
            :value="value.type"
            @input="(v) => ejectType(v, value)"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </template>
    <span>Editing rates is disabled outside of the supplier's default environment</span>
  </v-tooltip>
</template>

<script>
import ENumber from "../special/ENumber";
export default {
  props: {
    env: String,
    dropdown: Array,
    value: Object,
    default: String,
    disabled: Boolean,
  },
  components: {
    ENumber,
  },
  watch: {
    default(v) {
      this.setDefault(v);
    },
  },
  methods: {
    setDefault(v) {
      if (!this.value.type) {
        this.ejectType(v);
      }
    },
    ejectNumber(v) {
      this.$emit("input", v);
    },
    ejectType(v) {
      this.$emit("input", {
        ...this.value,
        type: v,
      });
    },
  },
  mounted() {
    this.setDefault(this.default);
  },
};
</script>
