<template>
  <div class="caption" :class="{ 'grey--text': focusRate.usingOldDate }">
    <v-tooltip v-if="env !== 'USA'" top left>
      <template v-slot:activator="{ on }">
        <span v-on="on">{{ priceBreakdown }}</span>
      </template>
      <span v-html="convertedPrice"></span>
    </v-tooltip>

    <span v-else v-html="priceBreakdown"></span>

    <span v-if="focusRate.usingOldDate" class="overline ml-3">({{ focusRate.rate_year }})</span>

    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-icon v-show="!confirmed" small v-on="on">report_problem</v-icon>
      </template>
      <span>Rate not confirmed for {{ focusYear }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import money from "@/services/money";
import { ProductService } from "@/modules/products";
export default {
  props: {
    product: Object,
    env: String,
    focusYear: String,
  },
  computed: {
    appliedSeasonRate() {
      return this.$store.getters["DatabaseStore/productPriceDate"];
    },

    priceBreakdown() {
      let prices = this.price;

      if (!prices) return "";
      if (!prices.length && this.focusRate.seasonal) return "(Seasonal)";
      if (prices && !this.focusRate.seasonal) return ProductService.formatPrice(prices);

      let result = "";
      prices.forEach((p, index) => {
        result += `${index >= 1 ? ";<br/>" : ""}${ProductService.formatPrice(p)} (${p.dateStart} to ${p.dateEnd})`;
      });
      return result;
    },

    convertedPrice() {
      let prices = this.price;

      if (!prices) return "";
      if (!prices.length && this.focusRate.seasonal) return "(Seasonal)";

      if (prices && !this.focusRate.seasonal) {
        return ProductService.formatPrice({
          cur: "$",
          value: money.getConvertedValue(prices.value, this.product.country, this.$store.getters),
          type: prices.type,
        });
      }

      let result = "",
        temp;
      prices.forEach((p, index) => {
        temp = ProductService.formatPrice({
          cur: "$",
          value: money.getConvertedValue(p.value, this.product.country, this.$store.getters),
          type: p.type,
        });
        result += `${index >= 1 ? ";<br/>" : ""}${temp} (${p.dateStart} to ${p.dateEnd})`;
      });
      return result;
    },

    focusRate() {
      if (!this.product.rate_history.length) return { rate: {}, meta: {} };

      let behind = false;
      let selectedYearRate = this.product.rate_history.find((item) => item.rate_year === this.focusYear);

      if (!selectedYearRate) {
        // There isn't a rate applied for current year
        behind = true;
        selectedYearRate = this.product.rate_history[0];
      }

      return {
        usingOldDate: behind,
        ...(selectedYearRate || {}),
        ...this.product,
      };
    },

    price() {
      let item = this.focusRate;

      if (!item.rate) return "";

      let meta = item.supplier_meta || {};
      let country = item.country;

      if (!item.seasonal) {
        return ProductService.fullCalc(item, meta, country, {});
      }

      // Seasonal pricing mess
      let parse = item.rate || [];
      let pricesInRange = ProductService.getSeasonalRange(parse, this.appliedSeasonRate);

      let rate, dateStart, dateEnd;
      return pricesInRange.map((prod, index) => {
        let temp = { ...item };
        temp.rate = prod.rate;
        return {
          ...ProductService.fullCalc(temp, meta, country, {}),
          dateStart: ProductService.getDate(prod.mstart, prod.dstart),
          dateEnd: ProductService.getDate(prod.mend, prod.dend),
        };
      });
    },
    confirmed() {
      if (this.focusRate.usingOldDate) return true;
      return this.focusRate && this.focusRate.confirmed;
    },
  },
};
</script>
