<template>
  <elwrapper label="Signature" texttop>
    <div v-html="signature"></div>
  </elwrapper>
</template>

<script>
export default {
  computed: {
    signature() {
      return this.$store.getters["AccountStore/signature"];
    },
  },
};
</script>
