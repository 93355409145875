<template>
  <span>{{ displayList }}</span>
</template>

<script>
import format from "@/services/format";
export default {
  props: {
    list: Array,
  },
  computed: {
    displayList() {
      return format.compileDateListReadable(this.list || []);
    },
  },
};
</script>
