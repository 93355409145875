<template>
  <FDropdown :items="displayYearOptions" :value="value" @input="(v) => $emit('input', v)" />
</template>

<script>
import FDropdown from "../items/FDropdown";

let START_YEAR = 2020; // Earliest date of products
let ADVANCE = 3; // Three years into future
let LENGTH = new Date().getFullYear() - START_YEAR + ADVANCE;
export default {
  props: {
    value: String,
  },
  components: {
    FDropdown,
  },
  computed: {
    displayYearOptions() {
      return [...Array(LENGTH).keys()].map((i) => `${START_YEAR + i}`).reverse();
    },
  },
};
</script>
