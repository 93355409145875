<template>
  <v-dialog v-model="dialog" width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-btn small :loading="syncing" @click="dialog = true">
        <v-icon left>arrow_circle_up</v-icon>
        <span>Apply to all current matches</span>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5 grey lighten-2"> Are you certain? </v-card-title>

      <v-card-text class="black--text">
        This will overwrite any current values for everything that matches. It cannot be undone.
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false"> No, close </v-btn>
        <v-btn color="primary" @click="$emit('apply')"> Yes, update </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    syncing: Boolean,
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
