<template>
  <a-group icon="event" label="Apply">
    <elwrapper :label="range ? 'From' : 'On'" smlabel midtext>
      <span class="subtitle-2 primary--text">{{ startFormatted }}</span>
    </elwrapper>

    <div v-if="range" class="d-flex align-center">
      <a-date :datewall="startDay" :datewallEnd="lastDay" maxwidth label="To" :value="value" @input="setEndDay" />

      <span class="caption mx-1">/</span>

      <a-select maxwidth="100px" :label="dayLabel" :value="dayCount" :items="maxDays" @input="updateTo" />
    </div>

    <div class="d-flex align-center mr-3">
      <input type="checkbox" v-model="range" @change="setDate" />
      <span class="caption ml-2">Range</span>
    </div>
  </a-group>
</template>

<script>
import format from "@/services/format";
import moment from "moment";
export default {
  props: {
    startDay: [String, Date],
    value: [String, Date],

    type: String,
    refDateShift: Number,
  },
  data() {
    return {
      range: false,
      dayCount: 1,
    };
  },
  computed: {
    dayLabel() {
      return this.type === "HOTEL" ? "# of Nights" : "# of Days";
    },
    startFormatted() {
      return format.formatDate(this.startDay);
    },
    maxDays() {
      const t = format.getNumberOfDays(this.startDay, this.lastDay) || 0;
      // Don't include last day for hotels
      return [...Array(this.type === "HOTEL" ? t : t + 1).keys()].map((x) => {
        const d = moment(this.startDay).add(x, "days").format("MMM-DD");
        return {
          label: this.type === "HOTEL" ? x + 1 : `${x + 1} (${d})`,
          value: x + 1,
        };
      });
    },
    lastDay() {
      return this.$store.getters["BookingDayStore/lastDay"].date;
    },
  },
  methods: {
    setDate(v) {
      if (this.range) {
        this.eject(this.startDay);
        return;
      }

      this.eject(null);
    },
    eject(endDay) {
      this.$emit("input", endDay);
    },
    setEndDay(v) {
      this.eject(v);
      const days = format.getNumberOfDays(this.startDay, v);
      this.dayCount = this.type === "HOTEL" ? days : days + 1;
    },
    updateTo(day) {
      this.$emit("input", format.addMultiDays(this.startDay, day - 1));
    },
  },
  mounted() {
    if (this.refDateShift > 1) {
      this.range = true;
      this.dayCount = this.refDateShift;
      this.updateTo(this.refDateShift);
    }
  },
};
</script>
