import SupplierImageApi from "./SupplierImageApi";
import ProductImageApi from "./ProductImageApi";
import ClientImageApi from "./ClientImageApi";
import SignatureImageApi from "./SignatureImageApi";

export default function (sourceApi) {
  const apiRef = {
    signature: SignatureImageApi,
    supplier: SupplierImageApi,
    product: ProductImageApi,
    client: ClientImageApi,
  };
  const api = apiRef[sourceApi];

  // Currently configured for suppliers
  const namespaced = true;

  const actions = {
    getImage(v, id) {
      return api
        .getImage(id)
        .then((data) => data.data)
        .catch((err) => {
          if (err) throw err.data;
        });
    },
    getImageList(v, ref_id) {
      return api
        .getImageList(ref_id)
        .then((data) => data.data)
        .catch((err) => {
          if (err) throw err.data;
        });
    },

    addImage(v, { ref_id, description, file }) {
      return api
        .addImage(ref_id, { description, file })
        .then((data) => data.data)
        .catch((err) => {
          if (err) throw err.data;
        });
    },
    updateImage(v, { id, description, description_cache, dependent_id }) {
      return api
        .updateImage(id, {
          description,
          description_cache,
          dependent_id,
        })
        .then((data) => data.data)
        .catch((err) => {
          if (err) throw err.data;
        });
    },
    saveDefaultImages(v, imageList) {
      return api
        .saveDefaultImages(imageList)
        .then((data) => {
          return data.data;
        })
        .catch((err) => {
          if (err) throw err.data;
        });
    },
    deleteImage(v, id) {
      return api
        .deleteImage(id)
        .then((data) => data.data)
        .catch((err) => {
          if (err) throw err.data;
        });
    },
    deleteImageList(v, idList) {
      return api
        .deleteImageList(idList)
        .then((data) => {
          data.data;
        })
        .catch((err) => {
          if (err) throw err.data;
        });
    },
  };

  return {
    namespaced,
    actions,
  };
}
