<template>
  <td class="pa-0 overview-block" style="min-width: 150px; max-width: 150px; vertical-align: top">
    <v-col class="pa-0" style="height: 100%">
      <div
        v-for="supplier in supplierList"
        :key="supplier.id"
        align-self="top"
        class="pa-2 text-truncate"
        :class="supplier.color"
        @click="$emit('edit', supplier.id)"
      >
        <OverviewSupplierBlock :supplier="supplier" :typeAs="typeAs" :date="date" />
      </div>

      <v-btn v-if="!supplierList.length" block text @click="$emit('add')">
        <v-icon color="grey" x-small>add</v-icon>
      </v-btn>
    </v-col>
  </td>
</template>

<script>
import OverviewSupplierBlock from "./_OverviewSupplierBlock";
export default {
  props: {
    suppliers: Array,
    date: String,
    typeAs: String,
  },
  components: {
    OverviewSupplierBlock,
  },
  computed: {
    supplierList() {
      return this.suppliers.map((item) => {
        return {
          id: item.id,
          color: item.color,
          time: item.time,
          name: item.name,
        };
      });
    },
  },
};
</script>
