<template>
  <div>
    <button-control-header
      :menuItems="menuItems"
      @add="$router.push({ name: 'admin_add_template' })"
      @setmode="setmode"
      :onsave="saveReorderEmailTemplates"
    >
      <template v-slot:mid>
        <span class="title white--text">Email Templates</span>
      </template>
    </button-control-header>

    <draggable v-model="emailList" handle=".handle">
      <div v-for="template in emailList" :key="template.id" class="d-flex">
        <v-col v-if="showDrag" class="handle d-flex align-center" style="max-width: 50px">
          <v-icon class="white--text">drag_indicator</v-icon>
        </v-col>

        <v-col class="pa-0">
          <a-card
            nopad
            class="d-flex template justify-space-between align-center"
            @click.native="$router.push({ name: 'admin_edit_template', params: { template_id: template.id } })"
          >
            <div class="pl-2" :class="getColor(template.status_set)">
              <v-icon :class="getColor(template.status_set)">brightness_1</v-icon>
              <span class="subtitle-2 pl-2">{{ template.name }}</span>
            </div>
            <v-chip-group column active-class="primary--text" class="pa-0 ma-0">
              <v-chip v-for="tag in template.supplier_types" :key="tag" small>
                {{ tag }}
              </v-chip>
            </v-chip-group>
          </a-card>
        </v-col>
      </div>
    </draggable>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import STATUS_COLORS from "@/config/STATUS_COLORS";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      menuItems: [
        {
          title: "Reorder",
          showMode: "SAVE",
        },
      ],
      showDrag: false,
    };
  },
  computed: {
    emailList: {
      get() {
        return this.$store.getters["EmailTemplateStore/emailList"]
          .filter((item) => {
            return !item.parent_id;
          })
          .sort((first, second) => {
            return first.order - second.order;
          });
      },
      set(nuval) {
        this.$store.dispatch("EmailTemplateStore/adjustTemplateOrder", nuval);
      },
    },
  },
  methods: {
    setmode(mode) {
      this.showDrag = mode === "SAVE";
    },
    getColor(status) {
      const color = Object.values(STATUS_COLORS.COLORS).find((item) => `${item.value}` === `${status}`);
      return color ? color.color : "";
    },

    // REORDER STUFF
    saveReorderEmailTemplates() {
      this.$root.$loading.open("Saving order of templates...");
      return this.$store
        .dispatch("EmailTemplateStore/reorderTemplates", this.emailList)
        .then(() => {
          this.$root.$loading.end();
          this.$root.$success("Template Order Updated");
        })
        .catch(this.$root.$error);
    },
  },
  mounted() {
    this.$store.dispatch("EmailTemplateStore/getTemplates", false).catch((err) => this.$root.$error(err));
  },
};
</script>

<style lang="scss">
.template {
  &:hover {
    background: lightgray;
    cursor: pointer;
  }
}
</style>
