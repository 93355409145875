<template>
  <div class="caption">
    <v-row>
      <v-col cols="3">
        <a-truncate :text="productName" :hoverText="productName" />
      </v-col>
      <v-col style="min-width: 200px; max-width: 200px">
        <HotelPriceHover
          v-if="showPriceBreakdown"
          :text="productRate"
          :taxInclusive="product.tax_inclusive"
          :tax="productTax"
          :supplierMeta="supplier.supplier_meta"
          :meta="product.product_meta"
          :original="product.original.productRate"
        />
        <div v-else>{{ productRate }}</div>
      </v-col>
      <v-col>
        <span v-if="showProductTotal">x {{ product.perTotal }}</span>
      </v-col>
      <v-col style="min-width: 90px; max-width: 90px">
        <!-- v-if="showProductDays" -->
        <span>x {{ product.dayCount || 0 }} {{ dayTextDisplay }}</span>
      </v-col>
      <v-col class="d-flex justify-center" style="min-width: 140px; max-width: 140px; border: 1px solid lightgrey">
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ compiledTotal }}</span>
          </template>
          <div>
            <div>{{ displayTotalBreakdown }}</div>
            <div v-if="focBreakdown" class="pl-3">{{ focBreakdown }}</div>
          </div>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row v-for="(sub, index) in product.subpricing" :key="index">
      <v-col cols="3">
        <span class="pl-3">+ {{ sub.displayName }}</span>
      </v-col>
      <v-col style="min-width: 200px; max-width: 200px"><!-- SPACER --></v-col>
      <v-col>
        <span v-if="showProductTotal">x {{ sub.perTotal }}</span>
      </v-col>
      <v-col style="min-width: 90px; max-width: 90px">
        <span>x {{ sub.count || 0 }} {{ dayTextDisplay }}</span>
      </v-col>
      <v-col
        class="d-flex justify-center"
        style="min-width: 140px; max-width: 140px; border: 1px solid lightgrey"
      ></v-col>
    </v-row>
  </div>
</template>

<script>
import { ProductService } from "@/modules/products";
import { HotelPriceHover } from "@/modules/bookings.days";
import ReportService from "../../service";
import money from "@/services/money";
export default {
  props: {
    typeAs: String,
    productType: String,
    product: Object,
    supplier: Object,
  },
  components: {
    HotelPriceHover,
  },
  computed: {
    productName() {
      return this.product.name;
    },
    productRate() {
      return money.convertFormat(
        this.product.productRate,
        this.supplier.supplier_env,
        this.$store.getters["AccountStore/environment"]
      );
    },
    displayTotalBreakdown() {
      const total = this.product.totalCountOfItems;
      const foc = this.product.howManyFOC || 0;
      let rate = this.product.original?.productRate || this.productRate;
      let note = "";
      if (this.$store.getters["AccountStore/environment"] != this.supplier.supplier_env) {
        note = "(Converted)";
        if (this.supplier.supplier_env === "USA") {
          const preSlash = rate.indexOf(" / ");
          rate = rate.substring(0, preSlash) + " USD" + rate.substring(preSlash);
        }
      }
      return `= ${total - foc} * ${rate} ${note}`;
    },
    focBreakdown() {
      let total = this.product.totalCountOfItems;
      let foc = this.product.howManyFOC;
      return this.product.hasfoc ? `(${total} items - ${foc} foc)` : false;
    },

    // HOTEL SPECIFIC STUFF
    showPriceBreakdown() {
      return ["HOTEL"].includes(this.typeAs) && ["ROOM"].includes(this.product.product_type);
    },
    productTax() {
      const tax = this.supplier.supplier_meta && this.supplier.supplier_meta.tax;
      const customTax = this.product.product_meta ? this.product.product_meta.custom_tax : 0;
      return customTax || tax || 0;
    },

    // ALL PRODUCTS
    dayTextDisplay() {
      const pl = this.product.dayCount === 1;
      if (this.product.type_as === "HOTEL") return pl ? "Night" : "Nights";
      if (this.product.type_as === "RESTAURANT") return pl ? "Meal" : "Meals";
      return pl ? "Day" : "Days";
    },

    showProductTotal() {
      return this.product.perTotal;
    },
    // showProductDays(){
    // 	return !['TRANSPORT'].includes(this.typeAs)
    // },

    compiledTotal() {
      if (!this.product.compiledTotal) return "$ --";
      return `$ ${money.format(this.product.compiledTotal, { precision: 2 })}`;
    },
  },
};
</script>
