import TYPES from "./types";

export default {
  pid: {
    label: "ID",
    description: "Product ID",
    inputType: "NO_EDIT",
  },
  name: {
    label: "Name",
    description: "Product name",
    inputType: "TEXT",
  },
  supplier_id: {
    label: "Supplier ID",
    description: "Supplier ID of Product",
    inputType: "NO_EDIT",
  },

  active: {
    label: "Active",
    offLabel: "Inactive",
    inputType: "BOOLEAN",
    searchHint: 'Active search: Please enter "Active" or "Inactive"',
  },

  rate: {
    label: "Rate",
    inputType: "CUSTOM_RATE",
  },
  rate_type: {
    label: "/",
    description: "Rate Type",
    inputType: "DROPDOWN",
    getSource(productType, supplierType) {
      if (productType === "ROOM") {
        return [TYPES.PROOM, TYPES.PP, TYPES.PG];
      }

      if (supplierType === "TRANSPORT" || supplierType === "TOUR_GUIDE") {
        return [TYPES.PG, TYPES.PP, TYPES.PD];
      }

      return [TYPES.PG, TYPES.PP, TYPES.PD];
    },
  },
};
