<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)" width="650" persistent>
    <v-card>
      <v-card-title>
        <v-icon left color="warning">warning</v-icon>
        <span class="subtitle-2">Are you sure you want to change the date range?</span>
      </v-card-title>

      <v-card-text>
        <div class="warning pa-2 white--text">Dates</div>
        <div style="border: 1px solid lightgrey">
          <v-row>
            <v-col cols="2">
              <elwrapper label="Current"></elwrapper>
            </v-col>
            <v-col>
              <elwrapper label="From" smlabel>
                <span>{{ displayFrom }}</span>
              </elwrapper>
            </v-col>
            <v-col>
              <elwrapper label="To" smlabel>
                <span>{{ displayTo }}</span>
              </elwrapper>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2">
              <elwrapper label="New"></elwrapper>
            </v-col>
            <v-col>
              <elwrapper label="From" smlabel>
                <f-date v-model="nuFrom" :datewall="datewall" @input="setTo" />
              </elwrapper>
            </v-col>
            <v-col>
              <elwrapper label="To" smlabel>
                <f-date v-model="nuTo" :datewall="nuFrom || datewall" />
              </elwrapper>
            </v-col>
          </v-row>

          <v-row class="black--text" justify="end" align="center">
            <input type="checkbox" v-model="copyReminder" />
            <span class="mx-2 caption">Copy Reminders</span>
            <span class="mx-2 caption grey--text">(Note: Reminder dates will not change)</span>
          </v-row>

          <!-- <v-row class="mx-0 py-3">
		    	<elwrapper label="Current Suppliers" widelabel>
		    		<div class="d-flex flex-row">
				    	<div v-for="r in radioGroup" :key="r.value" class="caption d-flex align-center">
							<input type="radio" name="pick" v-model="pick" :value="r.value">
							<span class="px-2">{{r.label}}</span>			
						</div>

						<v-menu open-on-hover bottom right max-width="300px">
		                  <template v-slot:activator="{ on }">
		                    <v-btn small icon v-on="on">
		                      <v-icon small color="grey lighten-1">help_outline</v-icon>
		                    </v-btn>
		                  </template>

		                  <div class="black--text white pa-3">
		                    <div v-for="r in radioGroup" :key="r.value" class="caption">
		                    	<span class="font-weight-bold pr-3">{{r.label}}</span>
								<span>{{r.helper}}</span>			
							</div>
		                  </div>
		                </v-menu>
					</div>
				</elwrapper>
		    </v-row> -->
        </div>

        <v-alert dense text type="warning" class="caption"> (Suppliers will not be notified of date changes) </v-alert>
      </v-card-text>

      <v-card-actions class="justify-space-between">
        <g-button label="Cancel" @onclick="$emit('input', false)" />
        <g-button type="black" :disabled="!restoreAvailable" icon="restore" label="Revert" @onclick="eject('revert')" />
        <g-button type="warning" :disabled="disabled" icon="update" label="Change" @onclick="eject('update')" />
        <g-button type="primary" :disabled="disabled" icon="file_copy" label="Copy & Cancel" @onclick="eject('copy')" />
        <g-button
          type="primary"
          :disabled="disabled"
          icon="control_point_duplicate"
          label="Duplicate"
          @onclick="eject('duplicate')"
        />
      </v-card-actions>
    </v-card>

    <DifferentDateRangeWarningDialog
      v-model="confirm"
      :previousDayCount="previousDayCount"
      :newDayCount="newDayCount"
      @next="next"
    />
  </v-dialog>
</template>

<script>
import format from "@/services/format";
import DifferentDateRangeWarningDialog from "./_DifferentDateRangeWarningDialog";
export default {
  props: {
    value: Boolean, // dialog open/close
    currentValue: Object, // from, to

    datewall: [String, Date],
  },
  components: {
    DifferentDateRangeWarningDialog,
  },
  data() {
    return {
      nuFrom: "",
      nuTo: "",

      confirm: false,
      previousDayCount: 0,
      newDayCount: 0,
      pendingContinue: "",

      copyReminder: false,
      restoreAvailable: false,

      // radioGroup: [{
      // 	value: 'SHIFT',
      // 	label: 'Shift',
      // 	helper: 'Day 1 => Day 1 (Suppliers on Day 1 for the current dates become suppliers for Day 1 of the new date range, and so on)'
      // }, {
      // 	value: 'MATCH',
      // 	label: 'Match dates',
      // 	helper: 'March 1 => March 1 (Suppliers will be matched to dates they are already assigned. So if Day 1 is March 1st in the old dates, but Day 7 in the new dates, the suppliers will remain on March 1st.)'
      // }, {
      // 	value: 'IGNORE',
      // 	label: 'Remove/Drop',
      // 	helper: 'All suppliers will be cleared. New dates will be empty.'
      // }],
      pick: "SHIFT",
    };
  },
  watch: {
    value(v) {
      if (!v) return;

      // Check if restore point available to time stuff
      this.restoreAvailable = false;
      this.$store.dispatch("BookingStore/checkRestorePoint").then((has) => (this.restoreAvailable = has));
    },
  },
  computed: {
    displayFrom() {
      if (!this.currentValue || !this.currentValue.from) return null;
      return format.formatDate(this.currentValue.from);
    },
    displayTo() {
      if (!this.currentValue || !this.currentValue.to) return null;
      return format.formatDate(this.currentValue.to);
    },
    disabled() {
      return !(this.nuFrom && this.nuTo);
    },
  },
  methods: {
    setTo(v) {
      const daysBetween = format.getNumberOfDays(this.currentValue.from, this.currentValue.to);
      this.nuTo = format.addMultiDays(v, daysBetween);
    },
    eject(type) {
      if (type === "revert") {
        this.$emit("revert");
        this.$emit("input", false);
        return;
      }

      this.pendingContinue = type;

      const daysBetween = format.getNumberOfDays(this.currentValue.from, this.currentValue.to);
      const nuDaysBetween = format.getNumberOfDays(this.nuFrom, this.nuTo);

      if (daysBetween === nuDaysBetween) {
        // Same Day Range, don't need to confirm
        this.next();
        return;
      }

      // Different day range
      this.previousDayCount = daysBetween;
      this.newDayCount = nuDaysBetween;
      this.confirm = true;
    },
    next() {
      this.confirm = false;
      if (this.pendingContinue === "update") return this.ejectUpdate();
      if (this.pendingContinue === "copy") return this.ejectCopy();
      if (this.pendingContinue === "duplicate") return this.ejectDuplicate();
    },

    ejectUpdate() {
      this.$emit("update", {
        nuFrom: this.nuFrom,
        nuTo: this.nuTo,
        keepType: this.pick,
      });
      this.$emit("input", false);
    },
    ejectCopy() {
      this.$emit("copy", {
        nuFrom: this.nuFrom,
        nuTo: this.nuTo,
        copyReminder: this.copyReminder,
      });
      this.$emit("input", false);
    },
    ejectDuplicate() {
      this.$emit("duplicate", {
        nuFrom: this.nuFrom,
        nuTo: this.nuTo,
        copyReminder: this.copyReminder,
      });
      this.$emit("input", false);
    },
  },
};
</script>
