export default {
  parking: {
    label: "Parking",
    inputType: "CYOA",
    choosePath: {
      free: "TEXT",
      $: "COST",
      "not offered": "TEXT",
    },
    restrictEnv: ["JPN"],
  },
  payment_type: {
    label: "Payment",
    inputType: "CYOA",
    choosePath: {
      wire: "TEXT",
      "credit card": "TEXT",
      cash: "TEXT",
      check: "TEXT",
    },
  },
};
