<template>
  <v-row class="a-field">
    <v-col align-self="center" class="label smlabel">
      <span class="subtitle-2">{{ label }}</span>
    </v-col>
    <v-col align-self="center" class="py-0">
      <FDate
        :datewall="datewall"
        :datewallEnd="datewallEnd"
        :maxwidth="maxwidth"
        :value="value"
        :type="type"
        @input="(v) => $emit('input', v)"
      />
    </v-col>
  </v-row>
</template>

<script>
import FDate from "../items/FDate";
export default {
  props: {
    label: String,
    value: String,
    maxwidth: Boolean,
    type: String,
    datewall: [String, Date],
    datewallEnd: [String, Date],
  },
  components: {
    FDate,
  },
  data() {
    return {
      date: "",
      menu: false,
    };
  },
};
</script>
