<template>
  <div>
    <d-header label="New Product" />
    <create-header label="Product" :goBackTo="goback" :disabled="canCreate" :oncreate="create" />

    <v-col>
      <v-row align="stretch">
        <v-col cols="5" class="pa-0">
          <a-card>
            <slot></slot>
          </a-card>
        </v-col>

        <v-col cols="1" class="d-flex justify-center align-start px-0 py-5">
          <v-icon class="white--text">double_arrow</v-icon>
        </v-col>

        <v-col cols="6" class="pa-0">
          <a-card v-if="!groupOrFIT" class="d-flex justify-center">
            <g-button type="black" class="mx-2" @onclick="groupOrFIT = 'GROUP'" label="Group" />
            <span class="caption">or</span>
            <g-button type="black" class="mx-2" @onclick="groupOrFIT = 'FIT'" label="FIT" />
            <span class="caption">or</span>
            <g-button type="black" class="mx-2" @onclick="groupOrFIT = 'GROUP_FIT'" label="Group/FIT" />
          </a-card>

          <a-card v-else>
            <p class="caption text-center">{{ groupingLabel }} Product</p>

            <a-text-field widelabel label="Name" v-model="productName" />

            <elwrapper widelabel label="Min Group Size" v-if="groupOrFIT === 'GROUP'">
              <f-number v-model="groupSize" />
            </elwrapper>

            <a-select
              widelabel
              dependant
              :dependantValue="supplier.supplier_type"
              dependantText="Input supplier id"
              label="Product Type"
              :items="productTypes"
              v-model="productType"
            />
          </a-card>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import { ProductMenu } from "@/config/menus";
import moment from "moment";

export default {
  props: {
    goback: Object,
    supplier: Object,
  },
  data() {
    return {
      productName: "",
      productType: "",
      groupOrFIT: null, // GROUP or FIT
      groupSize: 10,
    };
  },
  computed: {
    groupingLabel() {
      const map = {
        FIT: "FIT",
        GROUP: "GROUP",
        GROUP_FIT: "GROUP/FIT",
      };
      return map[this.groupOrFIT];
    },
    canCreate() {
      return !(this.supplier.id && this.productName && this.productType);
    },
    productTypes() {
      let list = [];
      let vm = this;

      if (!vm.supplier.supplier_type) return list;

      const allOptions = [vm.supplier.supplier_type, ...vm.supplier.search_types];

      ProductMenu.forEach((item) => {
        if (!item.restrictTo || vm.contains(item.restrictTo, allOptions)) {
          list.push({
            label: item.title,
            value: item.module,
          });
        }
      });

      return list;
    },
  },
  methods: {
    contains(restrictTo, options) {
      return restrictTo.filter((item) => {
        return options.indexOf(item) > -1;
      }).length;
    },
    compareProductSupplierType(productType, supplierList) {
      const existingSearchTypes = [this.supplier.supplier_type, ...this.supplier.search_types];

      return this.productType === productType && !this.contains(supplierList, existingSearchTypes);
    },
    create() {
      let productId;

      return this.$store
        .dispatch("DatabaseStore/createProduct", {
          supplier_id: this.supplier.id,
          supplier_pid: this.supplier.pid,
          product: {
            name: this.productName,
            product_type: this.productType,
            group_fit: this.groupOrFIT,
            rate_year: this.rateYearList,
            meta: {
              ...(this.groupOrFIT === "GROUP" && { group_size: this.groupSize }),
            },
          },
        })
        .then((product) => {
          productId = product.id;

          // If adding product type associated with certain suppliers
          // (eg. Adding "MEAL" to "HOTEL" supplier)
          // Auto-assign to supplier as a searchable type if not already added
          let saveBlock = {
            id: this.supplier.id,
            search_types: this.supplier.search_types,
          };

          if (this.compareProductSupplierType("MEAL", ["RESTAURANT"])) {
            saveBlock.search_types.push("RESTAURANT");
          } else if (this.compareProductSupplierType("EXCURSION", ["EXCURSION"])) {
            saveBlock.search_types.push("EXCURSION");
          }

          return this.$store.dispatch("SupplierStore/updateSupplier", saveBlock);
        })
        .then(() => {
          this.$root.$success(`Created "${this.productName}" for ${this.supplier.name}`);
          this.$router.push({
            name: "product_page",
            params: {
              id: productId,
            },
          });
        })
        .catch((err) => this.$root.$error(err));
    },
  },
};
</script>
