import ReminderHome from "./ReminderHome";

export default [
  {
    path: "/reminders",
    name: "reminders",
    component: ReminderHome,
    meta: {
      tabAccess: "REMINDER",
    },
  },
];
