import axios from "@/plugins/axios";

export default {
  sendMail(data) {
    return axios.post("/email", data);
  },
  sendMassMail(data) {
    return axios.post("/mass_email", data);
  },
  sendNotificationMail(data) {
    return axios.post("/notification_email", data);
  },

  getAccountEmails() {
    return axios.get("/account_emails");
  },
};
