<template>
  <v-card flat width="150px">
    <v-list dense nav>
      <v-list-item v-for="item in menuItems" :key="item.title" :to="item.to" class="py-2">
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      menuItems: [
        {
          title: "Account List",
          to: { name: "admin" },
        },
        {
          title: "Email Templates",
          to: { name: "admin_email_templates" },
        },
        {
          title: "Text Config",
          to: { name: "admin_textconfig" },
        },
        {
          title: "Defaults",
          to: { name: "admin_defaults" },
        },
        {
          title: "Lists",
          to: { name: "admin_lists" },
        },
        {
          title: "History",
          to: { name: "admin_history" },
        },
      ],
      //   , {
      // 	title: 'Upload',
      // 	to: { name: 'admin_upload' }
      // }]
    };
  },
};
</script>
