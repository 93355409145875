<template>
  <div>
    <ReportHeaderRow :name="reportName" />
    <v-container fluid fill-height class="justify-center">
      <router-view></router-view>
    </v-container>
  </div>
</template>

<script>
import { ReportHeaderRow } from "@/modules/finances.report";
export default {
  components: {
    ReportHeaderRow,
  },
  computed: {
    reportName() {
      return this.$store.getters["FinanceReportStore/booking_name"];
    },
  },
  mounted() {
    const bookingId = this.$route.params.booking_id;
    this.$store.dispatch("FinanceReportStore/getOneReport", bookingId).then((data) => {
      this.$store.dispatch("FinanceCompileStore/setReportingCalculations", {
        data,
        storeGetter: this.$store.getters,
      });
    });
  },
  beforeDestroy() {
    // Clear this to avoid calculating errors pulling infor from cache
    // Some stuff doesn't auto-sync in production because I don't know
    // So have to trigger manually and this causes other bugs
    this.$store.dispatch("FinanceReportStore/clear");
  },
};
</script>
