import { ProductService } from "@/modules/products";
import { BookingDayService } from "@/modules/bookings.days";
import SUPMENU from "@/config/menus/suppliersMenu";
import money from "@/services/money";
import format from "@/services/format";
import TotalBlobClass from "./helpers/TotalBlobClass";

export default {
  // Calculating totals
  getMargin,
  getProductListTotal,
  getPorterageTotal,
  getParkingTotal,
  getExtraTotal,
  getCommission,
  getCompiledBilled,
  extractDeltas,
};

/****************
 * CALCULATING SETS
 * ************/
function getMargin(invoiceTotal, costTotal, commissionTotal) {
  /***
   * Calculates Margin
   * PARAMS
   *  invoiceTotal    @{String/Number} Total Invoice of client
   *  costTotal       @{String/Number} Total cost of supplier/booking
   *  commissionTotal @{String/Number} Total of commission
   * RETURN
   *  Object =>
   *      diff        @{Number}
   *      percentage  @{Number}
   *      positive    @{Boolean}
   * ********/
  const invoiceAdj = money.stripFormatting(invoiceTotal);
  const costAdj = money.stripFormatting(costTotal);
  const commissionAdj = money.stripFormatting(commissionTotal);

  // Don't subtract cost if invoice isn't set yet
  const diff = money._num((invoiceTotal ? invoiceAdj - costAdj : 0) + commissionAdj);
  const per = invoiceTotal ? (diff / invoiceAdj) * 100 : 0;

  return {
    diff,
    percentage: per,
    positive: diff >= 0,
  };
}

function getProductListTotal(supplierProductList, combinedOnly, storeGetter) {
  /*
   *  Compiles total of products
   *  PARAMS
   *   => supplierProductList
   *   => combinedOnly
   *   => storeGetter
   * RETURN
   *   @Object => TotalBlobClass export
   */
  const env = storeGetter ? storeGetter["AccountStore/environment"] : null;
  const totals = new TotalBlobClass(env);

  const addedPorterage = [];

  supplierProductList.forEach((product) => {
    if (product.id === "PORTERAGE") {
      if (addedPorterage.includes(product.supplier_id)) return;
      addedPorterage.push(product.supplier_id);
    }
    totals.addToCombined(product.compiledTotal);
    if (!env) return;
    if (!product.supplier_env) return totals.addToCurrentEnv(product.compiledTotal);
    // Handle suppliers shared from different env
    totals.addToMatchingEnvWithConversion(product.supplier_env, product.compiledTotal, storeGetter);
  });

  const compiled = totals.export();
  return combinedOnly ? compiled.COMBINED : compiled;
}

function getPorterageTotal(bookingSupplierBlob, storeGetter) {
  /***
   * Calculates total porterage
   * PARAMS
   *  bookingSupplierBlob    @{Object} Compiled suppliers sorted by type
   *  storeGetter            @{Store} Vue store
   * RETURN
   *  @Object => TotalBlobClass export
   * ********/
  const hotels = bookingSupplierBlob.HOTEL;
  const env = storeGetter ? storeGetter["AccountStore/environment"] : null;
  const totals = new TotalBlobClass(env);

  // Porterage is only for hotels
  if (!hotels) return totals.export();

  Object.values(hotels).forEach((hotel) => {
    if (!hotel.porterage) return;
    // Add to combined env total + respective hotel env total
    totals.addToCombined(hotel.porterage.compiledTotal);
    totals.addToMatchingEnvWithConversion(hotel.supplier_env, hotel.porterage.compiledTotal, storeGetter);
  });

  return totals.export();
}

function getParkingTotal(bookingSupplierBlob, storeGetter) {
  /***
   * Calculates total parking
   * PARAMS
   *  bookingSupplierBlob    @{Object} Compiled suppliers sorted by type
   *  storeGetter            @{Store} Vue store
   * RETURN
   *  @Object => TotalBlobClass export
   * ********/

  const hotels = bookingSupplierBlob.HOTEL;
  const env = storeGetter ? storeGetter["AccountStore/environment"] : null;
  const totals = new TotalBlobClass(env);

  // Parking is only for hotels
  if (!hotels) return totals.export();

  Object.values(hotels).forEach((hotel) => {
    const vehicle = (hotel.parking_vehicle && hotel.parking_vehicle.compiledTotal) || 0;
    const bus = (hotel.parking_bus && hotel.parking_bus.compiledTotal) || 0;

    // Add to combined env total + respective hotel env total
    totals.addToCombined(vehicle + bus);
    totals.addToMatchingEnvWithConversion(hotel.supplier_env, vehicle + bus, storeGetter);
  });

  return totals.export();
}

function getExtraTotal(extraExpensesList, skipDeposit, split, storeGetter) {
  /***
   * Calculates total of extra expenses added on the report page
   * PARAMS
   *  extraExpensesList   @{Array} Compiled suppliers sorted by type
   *  skipDeposit         @{Boolean} Whether to include DEPOSIT expenses
   *  storeGetter         @{Store} Vue store
   * RETURN
   *
   *  @Number => Compile
   *  OR
   *  @Object => TotalBlobClass export
   * ********/

  const env = storeGetter ? storeGetter["AccountStore/environment"] : null;
  const totals = new TotalBlobClass(env);

  const extraTotal = extraExpensesList.reduce((total, item) => {
    if (item.expense_type === "DEPOSIT" && skipDeposit) return total;
    if (!item.amount || !item.amount.value) return total;
    if (item.expense_type === "REFUND") return total - money.stripFormatting(item.amount.value);
    return total + money.stripFormatting(item.amount.value);
  }, 0);

  if (!split) return extraTotal;

  totals.addToCombined(extraTotal);
  totals.addToCurrentEnv(extraTotal);

  return totals.export();
}

function getCommission(supplierBreakdownBlob, compiledProductPriceBlob, customCommission, receivedHistory) {
  /***
   * Calculates total of commisions
   * PARAMS
   *  supplierBreakdownBlob       @{Object} Compiled suppliers grouped/merged into respetive supplier/type
   *  compiledProductPriceBlob    @{Object} Compiled product price
   *  customCommission            @{Array} List of custom commission
   *  receivedHistory             @{Array} OPTIONAL -- Received Commission Hisotry
   * RETURN
   *  @Number => Total commission
   * ********/
  let totalMaster = 0,
    total;
  Object.keys(supplierBreakdownBlob).forEach((key) => {
    if (!Object.values(supplierBreakdownBlob[key]).length) return;
    totalMaster += Object.values(supplierBreakdownBlob[key]).reduce((total, supplier) => {
      // Only HOTEL has commissions
      if (supplier.type_as !== "HOTEL") return total;

      // Has received commission
      let received = (receivedHistory || []).filter((v) => supplier.supplier_id === v.supplier_id);
      if (received.length) {
        return (
          total +
          received.reduce((t, c) => {
            return t + money._num(c.total_received);
          }, 0)
        );
      }

      // Get custom commision
      let custom = customCommission.find((v) => supplier.supplier_id === v.supplier_id);
      if (custom) {
        return total + money._num(custom.custom_amount);
      }

      // Calculate commission
      let commission =
        supplier.meta && supplier.meta.has_custom_commission
          ? { value: supplier.meta.custom_commission_value }
          : supplier.supplier_meta && supplier.supplier_meta.commission;
      if (!commission || commission.value === "no") return total || 0;

      // COMMISSION IS %
      // Based on original rate of products
      supplier.products.forEach((product) => {
        // Hotel only commmission on rooms
        if (supplier.type_as === "HOTEL" && product.product_type !== "ROOM") return;
        total += (money._num(product.original.compiledTotal) * money._num(commission.value)) / 100;
      });

      return total || 0;
    }, 0);
  });

  return money._num(totalMaster);
}

function getCompiledBilled({
  supplierBreakdownBlob,
  supplierInvoiceBlob,
  compiledProductPriceBlob,
  defaultToEst,
  extraCostList,
  split,
  storeGetter,
}) {
  /***
   * Calculates total of commisions
   * PARAMS
   *  supplierBreakdownBlob       @{Object} Compiled suppliers grouped/merged into respetive supplier/type
   *  supplierInvoiceBlob         @{Object}
   *  compiledProductPriceBlob    @{Object} Compiled product price
   *  defaultToEst                @{Boolean} Whether to add compiled price to total if no supplier invoice
   *  extraCostList               @{Array} List of extra cost items added by user
   *  split                       @{Boolean} WHether to return one value or split as object
   *  storeGetter                 @{Store} Vue store, mostly for currceny converstion
   * RETURN
   *  @Number => Total billed
   *  OR
   *  @Object => TotalBlobClass export
   * ********/

  const env = storeGetter ? storeGetter["AccountStore/environment"] : null;
  const totals = new TotalBlobClass(env);

  Object.keys(supplierBreakdownBlob).forEach((key) => {
    // For each supplier
    Object.values(supplierBreakdownBlob[key]).forEach((supplier) => {
      const tempItem = supplierInvoiceBlob[supplier.supplier_id + key];

      // Created a supplier invoice, and it isn't undefined/null
      // This should be added to the total cost/billed
      if (tempItem && typeof tempItem.total_cost === "number" && !isNaN(tempItem.total_cost)) {
        totals.addToCombined(tempItem.total_cost);
        totals.addToMatchingEnvWithConversion(supplier.supplier_env, tempItem.total_cost, storeGetter);
        return;
      }

      // No supplier invoice has been created
      // Here, is the option to add the cost of the supplier compiled by the application
      if (defaultToEst) {
        let productTotal = Object.values(compiledProductPriceBlob || {})
          .filter(
            (s) =>
              s.supplier_id === supplier.supplier_id &&
              (s.type_as === supplier.type_as || (s.type_as === "RESTAURANT" && supplier.type_as === "HOTEL"))
          )
          .reduce((subtotal, product) => {
            subtotal += product.compiledTotal || 0;
            return subtotal;
          }, 0);
        // Add any deposits
        // let deposits = getExtraTotal(
        //   (extraCostList || []).filter((v) => {
        //     if (v.expense_type !== "DEPOSIT") return false;
        //     return v.supplier_id === supplier.supplier_id;
        //   })
        // );

        totals.addToCombined(productTotal);
        totals.addToMatchingEnvWithConversion(supplier.supplier_env, productTotal, storeGetter);
      }
    });
  });

  // Extra stuff (NON-DEPOSITS)
  const extraTotalBlob = getExtraTotal(extraCostList || [], true, true, storeGetter);
  totals.merge(extraTotalBlob);

  return split ? totals.export() : totals.export().COMBINED;
}

function extractDeltas(supplierBreakdown, supplierInvoices) {
  let deltaref = {};
  let typeKeys = ["HOTEL", "RESTAURANT", "TRANSPORT", "PARK", "TOUR_GUIDE", "EXCURSION"];
  typeKeys.forEach((typeAs) => {
    Object.values(supplierBreakdown[typeAs]).forEach((supplier) => {
      // No delta if cost not set
      if (!supplierInvoices[supplier.supplier_id + typeAs]) return;

      let total = getProductListTotal(supplier.products, true);
      let invoice = supplierInvoices[supplier.supplier_id + typeAs].total_cost;
      deltaref[supplier.supplier_id + typeAs] = (invoice || 0) - total;
    });
  });

  return deltaref;
}
