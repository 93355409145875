<template>
  <div>
    <v-row class="mx-0 mb-3" justify="space-between">
      <span class="title white--text">Default Values</span>
      <add-button @click="$router.push({ name: 'admin_add_default' })" />
    </v-row>

    <v-data-table
      disable-pagination
      hide-default-footer
      :headers="headers"
      :items="defaultList"
      :loading="loading"
      loading-text="Fetching..."
      class="database-table"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <DefaultItemRow
            v-for="item in items"
            :key="item.id"
            class="database-row"
            :item="item"
            @click.native="goToPage(item)"
          />
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { DefaultApi, DefaultItemRow } from "@/modules/admin.defaults";
export default {
  components: {
    DefaultItemRow,
  },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: "Environment",
          value: "env",
        },
        {
          text: "Country",
          value: "country",
        },
        {
          text: "Subjurisdiction",
          value: "subjur",
        },
        {
          text: "City",
          value: "city",
        },
        {
          text: "Subcity",
          value: "subcity",
        },
        {
          text: "Database",
          value: "database",
        },
        {
          text: "Type",
          value: "databaseType",
        },
        {
          text: "Field",
          value: "field",
        },
        {
          text: "Value",
          value: "value",
        },
      ],
      defaultList: [],
    };
  },
  methods: {
    goToPage(item) {
      this.$router.push({
        name: "admin_edit_default",
        params: {
          default_id: item.id,
        },
      });
    },
  },
  mounted() {
    this.loading = true;
    DefaultApi.getDefaults()
      .then((query) => {
        this.defaultList = query.data;
        this.loading = false;
      })
      .catch((err) => {
        this.$root.$error(err);
        this.loading = false;
      });
  },
};
</script>
