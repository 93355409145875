<template>
  <v-data-table
    disable-pagination
    hide-default-footer
    :headers="headers"
    :items="displayRows"
    :height="height"
    :loading="loading"
    loading-text="Fetching..."
    :custom-sort.sync="customSort"
    class="database-table"
  >
    <template v-slot:body="{ items }">
      <tbody>
        <AllFinanceTotalWrapperRow
          v-show="showTotal"
          :items="items"
          :showRecon="showRecon"
          :showArchive="showArchive"
          :splitTotal="splitTotal"
          style="box-shadow: inset 0px -1px black"
        />

        <FinanceTableRow
          v-for="item in items"
          :key="item.booking_id"
          :item="item"
          :splitTotal="splitTotal"
          class="database-row"
          @click.native="goToPage(item)"
        />
      </tbody>

      <infinite-loading
        slot="append"
        spinner="waveDots"
        :identifier="identifyScrollRef"
        @infinite="infiniteHandler"
        force-use-infinite-wrapper=".v-data-table__wrapper"
      >
        <div slot="no-more">
          <div class="overline ma-2">(End)</div>
        </div>
        <div slot="no-results"></div>
      </infinite-loading>
    </template>
  </v-data-table>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import service from "../../service";
import FinanceTableRow from "./general/_FinanceTableRow";
import AllFinanceTotalWrapperRow from "./general/_AllFinanceTotalWrapperRow";

export default {
  props: {
    query: Object,
    height: [Number, String],
    showRecon: Array,
    showArchive: Array,
    showTotal: Boolean,
    splitTotal: Boolean,
  },
  components: {
    InfiniteLoading,
    FinanceTableRow,
    AllFinanceTotalWrapperRow,
  },
  data() {
    return {
      rows: [],
      loading: true,
      page: 0,

      firstRender: false, // Infinite component does first query
      identifyScrollRef: new Date().getTime(),
      completed: false,
    };
  },
  watch: {
    query(searchQuery) {
      if (this.queryActive) return;

      if (this.firstRender) {
        this.resetScrolling();
        this.$emit("setComplete", false);
        this.identifyScrollRef = new Date().getTime();
      }
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Client",
          value: "client_name",
        },
        {
          text: "Group Name",
          value: "group_name",
        },
        {
          text: "Total Invoice",
          value: "total_invoice",
          align: "center",
          width: 120,
        },
        {
          text: this.splitTotal ? "Total Cost (USD / CAD)" : "Total Cost",
          value: "total_billed",
          align: "center",
          width: 180,
        },
        // {
        //   text: '(Δ)',
        //   value: 'total_billed_delta',
        //   align: 'center',
        //   width: 110
        // },
        {
          text: "Margin",
          value: "margin_val",
          align: "center",
          width: 130,
        },
        {
          text: "(%)",
          value: "margin_per",
          align: "center",
          width: 100,
        },
        {
          text: "Reconciliation",
          value: "reconciliation",
          align: "center",
          width: 125,
        },
        {
          text: "Invoice Paid",
          value: "invoice",
          align: "center",
          width: 120,
        },
      ];
    },

    displayRows() {
      const filterRow = this.rows.filter((item) => {
        if (this.showArchive.includes("TO_BE_CANCELLED") && item.booking.status == 0) return true;

        if (this.showArchive.includes("CANCELLED") && item.booking.status == -1) return true;
        if (!this.showArchive.includes("CANCELLED") && item.booking.status == -1) return false;

        if (!this.showArchive.includes("ARCHIVED") && item.booking.report_archived) return false;
        if (!this.showArchive.includes("ACTIVE") && !item.booking.report_archived) return false;
        if (!("reconciliation" in item)) return true;
        if (item.reconciliation === 0 && this.showRecon.includes("REC_NONE")) return true;
        if (item.reconciliation > 0 && item.reconciliation < 1 && this.showRecon.includes("REC_PART")) return true;
        if (item.reconciliation >= 1 && this.showRecon.includes("REC_ALL")) return true;
        return false;
      });

      this.$store.dispatch("FinanceStore/saveResult", filterRow);
      this.$store.dispatch("FinanceStore/saveFinanceTableHeader", this.headers);
      return filterRow;
    },
  },
  methods: {
    goToPage(item) {
      this.$router.push({
        name: "finance_report",
        params: {
          booking_id: item.booking_id,
        },
      });
    },
    customSort(items, sortVal, isDesc) {
      const sortBy = sortVal ? (typeof sortVal === "string" ? sortVal : sortVal[0]) : null;
      const temp = items.sort((a, b) => {
        let aval = a.booking[sortBy],
          bval = b.booking[sortBy];
        if (!isDesc[0]) {
          return aval < bval ? -1 : 1;
        } else {
          return bval < aval ? -1 : 1;
        }
      });
      return [...items];
    },

    resetScrolling() {
      this.page = 0;
      this.rows = [];
      this.queryActive = true;
    },
    queryDatabase(searchQuery) {
      this.loading = true;

      return this.$store
        .dispatch("FinanceStore/getFinances", { page: this.page, query: searchQuery })
        .then((rows) => {
          this.loading = false;

          // First set of data
          if (!this.rows.length && rows.length) {
            this.rows = rows;
            return { rows: [], continue: true };
          }
          return { rows, continue: false };
        })
        .catch((err) => {
          this.loading = false;
          this.$root.$error(err);
        });
    },

    infiniteHandler($state) {
      this.queryDatabase(this.query)
        .then((data) => {
          this.firstRender = true;
          this.queryActive = false;

          if (data.rows.length || data.continue) {
            this.page += 1;
            this.rows.push(...data.rows);
            $state.loaded();

            // If less than 50, assume complete
            if (data.rows.length === 50 || data.continue) return;
          }

          this.completed = true;
          this.$emit("setComplete", true);
          $state.complete();
        })
        .then((_) => {
          // Add compiled price stuff
          this.rows = this.rows.map((item) => {
            return Object.assign(
              {},
              item,
              service.compileOneBooking(item, this.$store.getters, (msg, data) => {
                console.log(msg, data);
              })
            );
          });
        })
        .catch((err) => {
          console.log(err);
          $state.error();
        });
    },
  },
};
</script>
