<template>
  <a-card>
    <v-row justify="space-between">
      <a-select label="Room Type" :items="roomList" v-model="selectedRoom" @input="setDefaultRoomCount" />
      <elwrapper label="Occupant #">
        <f-number v-model="roomCount" />
      </elwrapper>
      <g-button
        type="primary"
        icon="add"
        label="Add Room"
        :disabled="cancelled || !selectedRoom"
        @onclick="$emit('add', { room: selectedRoom, pax: roomCount })"
      />
    </v-row>
  </a-card>
</template>

<script>
import BOOKING_ROOMS from "@/config/BOOKING_ROOMS";
export default {
  props: {
    cancelled: Boolean,
  },
  data() {
    return {
      selectedRoom: "",
      roomCount: null,
      roomList: BOOKING_ROOMS.filter((item) => !item.ignore),
    };
  },
  methods: {
    setDefaultRoomCount(roomValue) {
      var room = BOOKING_ROOMS.find((item) => item.value === roomValue);
      this.roomCount = room.pax;
    },
  },
};
</script>
