<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)" width="400px" persistent>
    <v-card>
      <v-toolbar depressed flat>
        <span class="title">Project Manager</span>
        <div class="flex-grow-1"></div>
      </v-toolbar>

      <v-card-text>
        <div v-if="accountList.length === 0">
          <span class="caption px-3">No Users found</span>
        </div>

        <v-data-table
          v-else
          disable-pagination
          hide-default-footer
          fixed-header
          :headers="headers"
          :items="accountList"
          show-select
          single-select
          class="database-table a-card"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="item in items"
                :key="item.id"
                class="database-row color-alt canclick"
                :class="{ 'primary--text grey lighten-2': selected === item.id }"
                @click="selected = item.id"
              >
                <td>
                  <input type="checkbox" :checked="selected === item.id" />
                </td>
                <td>{{ item.name }}</td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <g-button label="Cancel" @onclick="$emit('input', false)" />
        <g-button type="primary" label="Select" @onclick="eject" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    accountList: Array,
  },
  data() {
    return {
      headers: [
        {
          text: "Name",
          value: "name",
        },
      ],
      selected: "",
    };
  },
  methods: {
    eject() {
      this.$emit("update", this.selected);
      this.$emit("input", false);
    },
  },
};
</script>
