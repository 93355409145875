import Vue from "vue";
import Vuetify from "vuetify";
// import plugin
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
// Vuetify's CSS styles
import "vuetify/dist/vuetify.min.css";
// don't forget to import CSS styles
import "tiptap-vuetify/dist/main.css";

// Vuetify Object (as described in the Vuetify 2 documentation)
const vuetify = new Vuetify();

// use this package's plugin
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: "md",
});
