<template>
  <archive-toggle-button :archived="archived" :archiveLocked="archiveLocked" @toggle="toggleArchive" />
</template>

<script>
export default {
  computed: {
    archived() {
      return this.$store.getters["BookingStore/isArchived"];
    },
    archiveLocked() {
      return this.$store.getters["BookingStore/isArchiveLock"];
    },
  },
  methods: {
    toggleArchive(val) {
      this.$store.dispatch("BookingStore/update", { key: "archived", val: val });
      this.$root.$loading.open("Updating...");
      this.$store
        .dispatch("BookingStore/updateBooking")
        .then((v) => {
          this.$root.$loading.end();
          this.$root.$success("Updated Booking");
        })
        .catch((err) => this.$root.$error(err));
    },
  },
};
</script>
