import ContactView from "./ContactView";
import ContactNew from "./ContactNew";
import ContactEdit from "./ContactEdit";

export default [
  {
    path: "contacts",
    name: "suppliers_contacts",
    component: ContactView,
    meta: {
      toolbar: "supplier",
      tabAccess: "DATABASE",
    },
  },
  {
    path: "contacts_new",
    name: "suppliers_contacts_new",
    component: ContactNew,
    meta: {
      toolbar: "supplier",
      tabAccess: "DATABASE",
    },
  },
  {
    path: "contacts/:contact_id",
    name: "suppliers_contacts_edit",
    component: ContactEdit,
    meta: {
      toolbar: "supplier",
      tabAccess: "DATABASE",
    },
  },
];
