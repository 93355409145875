<template>
  <a-card nopad>
    <div class="booking-title d-flex justify-space-between">
      <span>Tour Guide</span>
    </div>

    <div v-if="tourGuideList.length === 0" class="px-1">
      <span class="caption">(No guide confirmed yet)</span>
    </div>

    <div v-else v-for="guide in tourGuideList" :key="guide.id">
      <v-row align="start" class="px-1 caption">
        <v-col cols="3">
          <a-field :config="config.name" v-model="guide.name" midtext />
        </v-col>
        <v-col cols="3">
          <elwrapper v-if="!guide.confirmed" class="caption" midtext>(Not confirmed)</elwrapper>
          <!-- <a-field :config="config.phone_number" v-model="guide.phone_number" /> -->
        </v-col>

        <v-col cols="6">
          <v-row v-for="(d, index) in guide.dates" :key="index" class="pa-0" style="max-height: 25px">
            <v-col cols="6" class="pa-0">
              <a-field v-if="d.on" :config="config.on" v-model="d.from" midtext />
              <elwrapper v-else label="On" midtext>
                {{ d.displayDate }}
              </elwrapper>
            </v-col>

            <v-col cols="6" class="pa-0" align-self="center">
              <a-truncate italic :text="'** ' + d.productNames" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-divider />
    </div>
  </a-card>
</template>

<script>
import infoUtils from "./utils";
import format from "@/services/format";
import { BookingFields } from "@/config/fields/bookings";
export default {
  data() {
    return {
      config: BookingFields.tour_guide,
    };
  },
  computed: {
    tourGuideList() {
      return infoUtils.mergeConfirmedSuppliersForOverview(this.$store.getters, "TOUR_GUIDE", "tour_guides");
    },
  },
  methods: {
    openDialog() {},
  },
};
</script>
