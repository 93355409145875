<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)" width="600px" persistent>
    <v-card>
      <v-card-text class="mt-5 pt-5">
        <a-select
          label="Extra Options"
          multiple
          widelabel
          :items="selectOptions"
          :value="selectValues"
          @change="updateProduct"
        />
        <ExtraDialogRow
          v-for="item in displayExtraItems"
          :key="item.key"
          :config="item.config"
          :data="item.data"
          :country="country"
          @update="(v) => updateData(item, v)"
          @remove="removeItem(item.key)"
        />
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <g-button @onclick="$emit('input', false)" label="Cancel" />
        <g-button type="primary" @onclick="save" label="Save" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import EXTRA_OPTIONS from "@/config/products/EXTRA_OPTIONS";
import { ProductFields } from "@/config/fields/products";
import ExtraDialogRow from "./_ExtraDialogRow";
export default {
  props: {
    value: Boolean,
    country: String,
    productMeta: Object,

    customYear: String,
  },
  components: {
    ExtraDialogRow,
  },
  data() {
    return {
      selectOptions: Object.values(EXTRA_OPTIONS),
    };
  },
  computed: {
    selectValues() {
      // Only get options if not already selected
      return Object.values(EXTRA_OPTIONS).filter((item) => this.productMeta[item.value]);
    },
    displayExtraItems() {
      return Object.keys(this.productMeta)
        .filter((key) => EXTRA_OPTIONS[key])
        .map((key) => {
          return {
            key,
            config: EXTRA_OPTIONS[key],
            data: this.productMeta[key],
          };
        });
    },
  },
  methods: {
    save() {
      // Extra Dialog directly modifies the product meta
      // Just need to save
      this.$emit("input", false);
      const name = this.$store.getters["ProductStore/name"];
      this.$store
        .dispatch("ProductStore/updateProductRate", this.customYear)
        .then(() => {
          this.$root.$success(`Updated ${name}`);
        })
        .catch((err) => this.$root.$error(err));
    },

    updateData(item, content) {
      Vue.set(item.data, content.key, content.value);
    },
    removeItem(key) {
      Vue.delete(this.productMeta, key);
    },
    updateProduct(selected) {
      Object.values(EXTRA_OPTIONS).forEach((item) => {
        // If not in selected, but in meta REMOVE
        if (!selected.includes(item.value) && this.productMeta[item.value]) {
          Vue.delete(this.productMeta, item.value);
        }

        // If not selected, add
        if (selected.includes(item.value) && !this.productMeta[item.value]) {
          Vue.set(this.productMeta, item.value, {
            astype: EXTRA_OPTIONS[item.value].defaultType,
            value: null,
          });
        }
      });
    },
  },
};
</script>
