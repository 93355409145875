import SupplierDocumentApi from "./api";
import BookingContractApi from "./BookingContractApi";
import BookingInvoiceApi from "./BookingInvoiceApi";
import FinanceDocumentApi from "./FinanceDocumentApi";

const SET_WINDOW = "SET_WINDOW";
const SET_IMAGE_WINDOW = "SET_IMAGE_WINDOW";

export default function (sourceApi) {
  const apiRef = {
    supplier: SupplierDocumentApi,
    booking_contracts: BookingContractApi,
    booking_invoices: BookingInvoiceApi,
    finance: FinanceDocumentApi,
  };
  const api = apiRef[sourceApi];

  // Currently configured for suppliers

  const namespaced = true;

  const state = {
    docviewer: {},
    imgviewer: {
      supplier: {},
      products: {},
    },
  };

  const mutations = {
    SET_WINDOW(state, data) {
      state.docviewer[data.name] = data.window;
    },
    SET_IMAGE_WINDOW(state, data) {
      state.imgviewer[data.type][data.name] = data.window;
    },
  };

  const actions = {
    setWindow({ commit }, data) {
      commit(SET_WINDOW, data);
    },
    setImageWindow({ commit }, data) {
      commit(SET_IMAGE_WINDOW, data);
    },

    getDocument(v, id) {
      return api
        .getDocument(id)
        .then((data) => data.data)
        .catch((err) => {
          if (err) throw err.data;
        });
    },
    getDocumentList(v, supplier_id) {
      return api
        .getDocumentList(supplier_id)
        .then((data) => data.data)
        .catch((err) => {
          if (err) throw err.data;
        });
    },

    addDocument(v, { origin_id, name, file }) {
      return api
        .addDocument(origin_id, { name, file })
        .then((data) => data.data)
        .catch((err) => {
          if (err) throw err.data;
        });
    },
    updateDocument(v, { id, display_name, old_name, dependent_id }) {
      return api
        .updateDocument(id, {
          display_name,
          old_name,
          dependent_id,
        })
        .then((data) => data.data)
        .catch((err) => {
          if (err) throw err.data;
        });
    },
    deleteDocument(v, id) {
      return api
        .deleteDocument(id)
        .then((data) => data.data)
        .catch((err) => {
          if (err) throw err.data;
        });
    },
    deleteDocumentList(v, idList) {
      return api
        .deleteDocumentList(idList)
        .then((data) => data.data)
        .catch((err) => {
          if (err) throw err.data;
        });
    },
  };

  return {
    namespaced,
    state,
    mutations,
    actions,
  };
}
