import axios from "@/plugins/axios";

export default {
  getBookingContacts(supplierIdList) {
    return axios.post("/booking_email/contacts", { supplierIdList });
  },
  getMassBookingContacts(query) {
    return axios.get("/booking_contacts", {
      params: { query },
    });
  },
  updateBookingSupplierStatus({ booking_id, bookingSupplierIdList, status, lockedPriceMapping }) {
    return axios.post(`/bookings/${booking_id}/days/suppliers`, { bookingSupplierIdList, status, lockedPriceMapping });
  },
};
