import axios from "@/plugins/axios";

export default {
  getDefaults() {
    return axios.get("/defaults");
  },
  getOneDefault(default_id) {
    return axios.get(`/defaults/${default_id}`);
  },
  addDefault(data) {
    return axios.post("/defaults", { data });
  },
  updateDefault(default_id, data) {
    return axios.post(`/defaults/${default_id}`, { data });
  },
  applyDefault(default_id) {
    return axios.post(`/defaults/${default_id}/sync`);
  },
  removeDefault(default_id) {
    return axios.delete(`/defaults/${default_id}`);
  },
};
