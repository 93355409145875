<template>
  <tr>
    <td style="border: 1px solid lightgrey">{{ item.booking.client_name }}</td>
    <td class="font-weight-bold" style="border: 1px solid lightgrey">
      <GroupNameHoverCell :booking="item.booking" />
    </td>
    <td class="excel font-weight-bold">
      {{ totalInvoice }}
    </td>
    <td class="excel">
      <span style="margin-right: -7px">{{ totalBilled }}</span>
    </td>
    <!-- <td class="excel">
  	<span :class="deltaCss" style="margin-left:-7px">
  		({{ formatCost(item.total_billed_delta) }})
	  </span>
  </td> -->
    <td class="excel font-weight-bold">
      <span style="margin-right: -7px">{{ margin.diff }}</span>
    </td>
    <td :class="marginCss" class="excel">
      <span style="margin-left: -7px">{{ margin.per }}</span>
    </td>
    <td class="excel">
      <span>{{ totalRecognition }}</span>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon v-show="pendingCommission" v-on="on" small color="grey lighten-1 mx-1">monetization_on</v-icon>
        </template>
        <span>{{ pendingCommission }} unreceived commission{{ pendingCommission > 1 ? "s" : "" }}</span>
      </v-tooltip>
    </td>
    <td class="excel">
      <span class="caption" :class="invoicePaidDisplay.color">{{ invoicePaidDisplay.value }}</span>
    </td>
  </tr>
</template>

<script>
import GroupNameHoverCell from "./rowContent/_GroupNameHoverCell";
import { ReportService } from "@/modules/finances.report";
import money from "@/services/money";
export default {
  props: {
    item: Object,
    splitTotal: Boolean,
  },
  components: {
    GroupNameHoverCell,
  },
  computed: {
    totalInvoice() {
      return _formatCost(this.item.total_invoice);
    },
    totalBilled() {
      if (!this.item.total_billed) return "";
      // if (this.item.total_billed.USA === 0 && this.item.total_billed.CDA == 0) {
      //     return _formatCost(null)
      // }

      return _formatCost(
        this.splitTotal
          ? `${this.item.total_billed.USA} / ${this.item.total_billed.CDA}`
          : this.item.total_billed.COMBINED
      );
    },
    totalCost() {
      return this.item.total_cost.COMBINED;
    },
    billedDelta() {
      return this.item.total_billed_delta;
    },
    deltaCss() {
      if (!this.totalCost) return "grey--text";
      if (this.billedDelta < 0) return "green--text";
      return "red--text";
    },

    margin() {
      const val = this.item.margin;
      return {
        has: val && val.diff,
        diff: _formatCost(val ? val.diff : null),
        per: val && val.percentage ? `(${Number(val.percentage).toFixed(2)} %)` : "",
        positive: val ? val.positive : null,
      };
    },
    marginCss() {
      if (!this.margin.has) return "grey--text";
      if (this.margin.positive) return "green--text";
      return "red--text";
    },

    invoicePaid() {
      return this.item.invoice_paid;
    },

    paidDeposit() {
      if (!this.item.client_invoice_history.length) return false;
      if (this.item.client_invoice_history[0].invoice_type === "DEPOSIT") return true;
      let v = JSON.parse(JSON.stringify(this.item.payment_history));
      let top = v.sort((a, b) => {
        if (a.paid_date < b.paid_date) return 1;
        if (a.paid_date > b.paid_date) return -1;
        return 0;
      });

      if (top.length == 0) return false;
      return top[0].isdeposit === 1;
    },

    invoicePaidDisplay() {
      const isDeposit = this.paidDeposit;
      return {
        color: isDeposit
          ? "primary--text"
          : this.invoicePaid
          ? this.invoicePaid > this.item.total_invoice
            ? "primary--text"
            : this.invoicePaid === this.item.total_invoice
            ? "green--text"
            : "warning--text"
          : "",
        value: _formatCost(this.invoicePaid),
      };
    },

    // Reconciliation compile
    totalRecognition() {
      const num = this.item.reconciliation || 0;
      return `${Number(num * 100).toFixed(0)} %`;
    },
    pendingCommission() {
      return this.item.commission_not_received;
    },
  },
};

function _formatCost(v) {
  return v ? `$ ${money.format(v, { precision: 2 })}` : "$ --";
}
</script>
