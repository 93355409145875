<template>
  <div>
    <v-row class="pa-0 subrow">
      <v-col class="d-flex align-center subtitle-2" align-self="center"> + {{ label }} </v-col>

      <v-col
        style="max-width: 140px; min-width: 140px; border: 1px solid lightgrey"
        v-if="porterage || parking_bus || parking_vehicle"
      >
        <Total :total="supplierMetaTotal" :country="country" />
      </v-col>

      <v-col style="max-width: 140px; min-width: 140px; position: relative; border: 1px solid lightgrey">
        <!-- BLANK -->
      </v-col>

      <v-col style="max-width: 140px; min-width: 140px; border: 1px solid lightgrey">
        <!-- BLANK -->
      </v-col>
    </v-row>

    <v-row v-show="showProducts">
      <v-col style="margin-right: 275px" class="py-0">
        <PorterageRow v-if="porterage" :porterage="porterage" />
        <ParkingRow v-if="parking_vehicle" types="Vehicles" :parking="parking_vehicle" />
        <ParkingRow v-if="parking_bus" types="Buses" :parking="parking_bus" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PorterageRow from "./_PorterageRow";
import ParkingRow from "./_ParkingRow";

import Total from "@/modules/finances.report/components/subitems/_Total";
export default {
  props: {
    label: String,
    showProducts: Boolean,
    porterage: Object,
    parking_vehicle: Object,
    parking_bus: Object,

    country: String,
  },
  components: {
    ParkingRow,
    PorterageRow,
    Total,
  },
  computed: {
    supplierMetaTotal() {
      return (
        (this.porterage ? this.porterage.compiledTotal : 0) +
        (this.parking_vehicle ? this.parking_vehicle.compiledTotal : 0) +
        (this.parking_bus ? this.parking_bus.compiledTotal : 0)
      );
    },
  },
};
</script>
