<template>
  <v-col>
    <button-control-header
      static-mode="SAVE"
      @clear="$router.push({ name: 'suppliers_contacts' })"
      :onsave="saveContact"
    />

    <ContactEditForm
      v-model="contactForm"
      :isprimary="isPrimary"
      :country="country"
      @setPrimary="(v) => (isPrimary = v)"
    />
  </v-col>
</template>

<script>
import { ContactEditForm } from "@/modules/contacts";

export default {
  components: {
    ContactEditForm,
  },
  data() {
    var vm = this;
    return {
      isPrimary: false,
      contactForm: {
        name: "",
        title: "",
        phone_number: "",
        cell_number: "",
        email: "",
        notes: "",
        meta: {},
      },
    };
  },
  computed: {
    supplierId() {
      return this.$store.getters["SupplierStore/id"];
    },
    country() {
      return this.$store.getters["SupplierStore/country"];
    },
  },
  methods: {
    setPrimary(id) {
      if (this.isPrimary)
        return this.$store
          .dispatch("SupplierContactStore/setPrimaryContact", {
            supplier_id: this.supplierId,
            contact_id: id,
            old_primary: this.cachedPrimaryContactId,
          })
          .then((v) => {
            this.$store.dispatch("SupplierStore/changePrimary", id);
          });

      return new Promise((resolve, rej) => resolve());
    },
    saveContact() {
      return this.$store
        .dispatch("SupplierContactStore/addContact", {
          supplier_id: this.supplierId,
          contact: this.contactForm,
        })
        .then((newContact) => {
          this.$store.dispatch("SupplierStore/insertContact", newContact);
          return this.setPrimary(newContact.id);
        })
        .then((v) => {
          this.$root.$success("Created contact: " + this.contactForm.name);
          this.$router.push({ name: "suppliers_contacts" });
        })
        .catch((err) => this.$root.$error(err));
    },
  },
  mounted() {
    // If there is no primary contact
    // Default to setting this as the primary contact
    var currentContact = this.$store.getters["SupplierStore/primary_contact"];
    this.cachedPrimaryContactId = currentContact ? currentContact.id : null;
    if (!currentContact.id) this.isPrimary = true;
  },
};
</script>
