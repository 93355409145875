<template>
  <div>
    <v-toolbar dense flat>
      <v-toolbar-title>
        <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
      </v-toolbar-title>
    </v-toolbar>

    <v-container fluid class="justify-center">
      <d-header label="New Supplier" />
      <create-header label="Supplier" :goBackTo="goback" :disabled="!canCreate" :oncreate="create" />

      <CreateSupplierForm v-model="supplier" />

      <NewFormPrimaryAdd :country="supplier.country" v-model="primary_contact" />
    </v-container>
  </div>
</template>

<script>
import { CreateSupplierForm } from "@/modules/suppliers";
import { NewFormPrimaryAdd } from "@/modules/contacts";
import ENV from "@/config/ENV";

export default {
  components: {
    CreateSupplierForm,
    NewFormPrimaryAdd,
  },
  data() {
    return {
      supplier: {
        name: "",
        type: "",
        country: "",
        region: "",
        op_area: "",
        subjur: "",
        city: "",
        subcity: "",
      },
      primary_contact: {},
    };
  },
  computed: {
    querytype() {
      return this.$route.query.type;
    },
    supback() {
      if (this.supplier.type) return { sub: this.supplier.type };
      if (this.querytype) return { sub: this.querytype };
      return null;
    },
    breadcrumbs() {
      return [
        {
          text: "Database",
          to: { name: "database" },
        },
        {
          text: "Suppliers",
          to: {
            name: "database_view",
            params: {
              view: "suppliers",
              ...this.supback,
            },
          },
        },
        {
          text: "New",
        },
      ];
    },
    goback() {
      return {
        name: "database_view",
        params: {
          view: "suppliers",
          ...this.supback,
        },
      };
    },
    canCreate() {
      var c = this.supplier.name && this.supplier.type;
      // One type is specified, need country + region
      if (this.supplier.type)
        c = this.supplier.country && (this.supplier.region || this.supplier.type === "TOUR_GUIDE");
      if (c && this.supplier.region !== "NAT") {
        // National doesn't need state or city
        c = this.supplier.subjur && this.supplier.city;
      }

      return c;
    },
  },
  methods: {
    getPaymentMeta() {
      // US
      // 		Hotel/Transport => Credit Cards
      // 		Restaurant/Tour Guide => Check
      // 		Else => Cash
      //	JPN
      // 		Hotel => Credit Card
      // 		Else => Cash
      const paymentTypeMapping = {
        JP: {
          HOTEL: "credit card",
          ELSE: "cash",
        },
        DEFAULT: {
          HOTEL: "credit card",
          TRANSPORT: "credit card",
          RESTAURANT: "check",
          TOUR_GUIDE: "check",
          ELSE: "check",
        },
      };

      let opt = paymentTypeMapping[this.supplier.country];
      if (!opt) {
        opt = paymentTypeMapping.DEFAULT;
      }

      return opt[this.supplier.type] || opt.ELSE;
    },
    create() {
      // Add default payment types
      this.supplier.meta = {
        payment_type: {
          type: "TEXT",
          value: this.getPaymentMeta(),
        },
      };

      return this.$store
        .dispatch("DatabaseStore/createSupplier", {
          type: this.supplier.type,
          supplier: this.supplier,
          primary_contact: this.primary_contact,
        })
        .then((nuid) => this.$router.push({ name: "suppliers_info", params: { id: nuid } }))
        .catch((err) => this.$root.$error(err));
    },
  },
  mounted() {
    const env = this.$store.getters["AccountStore/environment"];
    const countryMap = ENV.find((x) => x.value === env);

    var q = this.$route.query.type;
    this.supplier.type = q;
    this.supplier.country = countryMap.defaultCountry;
    this.primary_contact.phone_number = { code: countryMap.areaCode };
  },
};
</script>
