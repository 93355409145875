export default {
  // TOP INFORMATION
  client: {
    label: "Client",
    inputType: "TEXT",
  },
  pax_num: {
    label: "# of PAX",
    inputType: "NUMBER",
  },
  room_num: {
    label: "# of Rooms",
    inputType: "NUMBER",
  },
  agency: {
    label: "Client",
    inputType: "TEXT",
  },
  destination: {
    label: "Destination",
    inputType: "TEXT",
  },
  airport_sign: {
    label: "Airport Sign name",
    inputType: "TEXT",
  },
  project_manager: {
    label: "Project Manager",
    inputType: "TEXT",
  },

  notes: {
    label: "Notes",
    inputType: "TEXTAREA",
    rows: 5,
    bullets: true,
  },
  invoice_notes: {
    label: "Invoice Notes",
    inputType: "TEXTAREA",
    rows: 5,
    bullets: true,
  },

  // FLIGHT INFO
  flight: {
    date: {
      label: "Date",
      inputType: "DATE",
    },
    airport: {
      label: "Airport",
      inputType: "TEXT",
    },
    flight_num: {
      label: "Flight #",
      inputType: "TEXT",
    },
    time: {
      label: "Time",
      inputType: "TIME",
    },
  },

  tour_guide: {
    name: {
      label: "Tour Guide",
      inputType: "NO_EDIT",
    },
    phone_number: {
      label: "Cell phone",
      inputType: "NO_EDIT",
    },
    confirmation_num: {
      label: "Confirmation #",
      inputType: "NO_EDIT",
    },
    on: {
      label: "On",
      inputType: "NO_EDIT",
    },
    from: {
      label: "From",
      inputType: "NO_EDIT",
    },
    to: {
      label: "To",
      inputType: "NO_EDIT",
    },
  },
  motor_coach: {
    name: {
      label: "Motorcoach",
      inputType: "NO_EDIT",
    },
    confirmation_num: {
      label: "Confirmation #",
      inputType: "NO_EDIT",
    },
    on: {
      label: "On",
      inputType: "NO_EDIT",
    },
    from: {
      label: "From",
      inputType: "NO_EDIT",
    },
    to: {
      label: "To",
      inputType: "NO_EDIT",
    },
  },
};
