<template>
  <a-card nopad hover @click.native="$emit('goto', contact.id)" :class="{ selected: primary }">
    <v-row>
      <v-col class="px-4" align-self="center">
        <span class="subtitle-2">{{ displayName }}</span>
        <span class="ml-3 caption" v-if="contact.title">({{ contact.title }})</span>
        <single-tag v-if="primary" text="Primary" />
      </v-col>

      <v-col class="pa-0" cols="3" align-self="center">
        <elwrapper icon="phone" smlabel midtext>
          <PhoneDisplay :value="contact.phone_number" class="caption" />
        </elwrapper>
      </v-col>

      <v-col class="pa-0" cols="3" align-self="center">
        <elwrapper icon="mail" smlabel class="caption" midtext>{{ email }}</elwrapper>
      </v-col>
    </v-row>
  </a-card>
</template>

<script>
export default {
  props: {
    contact: {
      type: Object,
      required: true,
    },
    primary: Boolean,
  },
  computed: {
    displayName() {
      const par = this.contact.meta && this.contact.meta.en_name;
      return (this.contact.name || "(No name)") + (par ? ` / ${par}` : "");
    },
    email() {
      if (!this.contact.email) return;
      return this.contact.email.charAt(0).toUpperCase() + this.contact.email.substring(1);
    },
  },
};
</script>
