import Contracts from "./Contracts";

export default [
  {
    path: "contracts",
    name: "booking_contracts",
    component: Contracts,
    meta: {
      toolbar: "booking",
      tabAccess: "BOOKING",
    },
  },
];
