<template>
  <v-combobox
    outlined
    hide-details
    dense
    :disabled="disabled"
    :value="value"
    :items="items"
    append-icon=""
    @input="onInput"
  ></v-combobox>
</template>

<script>
export default {
  props: {
    value: String,
    disabled: Boolean,
    items: Array,
  },
  methods: {
    onInput(v) {
      this.$emit("input", v);
    },
  },
};
</script>
