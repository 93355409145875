import Vue from "vue";
import api from "./api";
import format from "@/services/format";
import moment from "moment";
import { SuppliersMenu } from "@/config/menus";
import { ProductService } from "@/modules/products";
import { BookingRoomService } from "@/modules/bookings.rooms";
import service from "./service";

const CONFIRM = 4;

const SET_DAYS = "SET_DAYS";
const UPDATE_DAY = "UPDATE_DAY";

const FOCUS_DAY = "FOCUS_DAY";
const TRANSFER_SAVE = "TRANSFER_SAVE";

const ADD_SUPPLIER = "ADD_SUPPLIER";
const INSERT_SUPPLIER_TO_DAYS = "INSERT_SUPPLIER_TO_DAYS";
const REMOVE_SUPPLIER = "REMOVE_SUPPLIER";
const REMOVE_ALL_SUPPLIER = "REMOVE_ALL_SUPPLIER";
const UPDATE_SUPPLIER = "UPDATE_SUPPLIER";
const UPDATE_SUPPLIER_ALT = "UPDATE_SUPPLIER_ALT";
const UPDATE_SUPPLIER_DAYS = "UPDATE_SUPPLIER_DAYS";

const ADD_SUPPLIER_ROOM_TO_DATE = "ADD_SUPPLIER_ROOM_TO_DATE";
const REMOVE_SUPPLIER_ROOM_BY_DATE = "REMOVE_SUPPLIER_ROOM_BY_DATE";

const namespaced = true;

const state = {
  days: _getBooking(),
  suppliers: _getSupplierMapping(),

  daySaveQueue: _getClean(),
};

function _getBooking() {
  return JSON.parse(localStorage.getItem("booking_days") || "[]");
}

function _getSupplierMapping() {
  return JSON.parse(localStorage.getItem("booking_suppliers") || "{}");
}

function _getClean() {
  return {
    days: {},
    suppliers: {},
  };
}

const mutations = {
  SET_DAYS(state, data) {
    state.days = data.days || [];
    state.suppliers = data.suppliers || {};
    state.daySaveQueue = _getClean();

    localStorage.setItem("booking_days", JSON.stringify(state.days));
    localStorage.setItem("booking_suppliers", JSON.stringify(state.suppliers));
  },
  UPDATE_DAY(state, { day_number, key, val }) {
    Vue.set(state.days[day_number], key, val);
    state.daySaveQueue.days[day_number] = true;
  },

  // One day
  FOCUS_DAY(state, dayIndex) {
    // Use cached day as reference
    var day = _getBooking()[dayIndex];
    state.days[dayIndex] = day;
  },

  // SAVE
  TRANSFER_SAVE(state) {
    localStorage.setItem("booking_days", JSON.stringify(state.days));
    localStorage.setItem("booking_suppliers", JSON.stringify(state.suppliers));

    state.daySaveQueue = _getClean();
  },

  // Store management
  INSERT_SUPPLIER_TO_DAYS(state, { bookingSupplierId, dateList, type_as }) {
    const dbkey = SuppliersMenu.MOD_DBKEY[type_as];

    dateList.forEach((date) => {
      const day = state.days.find((day) => day.date === date);
      const dayIndex = state.days.findIndex((day) => day.date === date);
      if (!day[dbkey]) day[dbkey] = [];

      // Don't add same bs twice
      if (!day[dbkey].includes(bookingSupplierId)) day[dbkey].push(bookingSupplierId);

      // Mark what days to save
      state.daySaveQueue.days[dayIndex] = true;
    });
  },
  ADD_SUPPLIER(state, { bookingSupplierId, content }) {
    // Add new supplier
    // Supplier MUST be created before inserting into here
    content.id = bookingSupplierId;
    Vue.set(state.suppliers, bookingSupplierId, content);
  },
  UPDATE_SUPPLIER(state, { bookingSupplierId, content }) {
    state.suppliers[bookingSupplierId] = Object.assign(state.suppliers[bookingSupplierId], content);

    state.daySaveQueue.supplier = {
      key: bookingSupplierId,
      task: "UPDATE",
    };
  },
  UPDATE_SUPPLIER_ALT(state, { bookingSupplierId, content }) {
    // Clear locked prices (if set)
    const CONFIRM_STATUS = 4;
    if (Number.isInteger(content.status) && content.status < CONFIRM_STATUS) {
      content.locked_prices = null;
    }

    let key = bookingSupplierId;
    Vue.set(state.suppliers, key, Object.assign(state.suppliers[key], content));
  },
  UPDATE_SUPPLIER_DAYS(state, { bookingSupplierId, dayKeep, dbkey }) {
    let exists;
    state.days.forEach((item, index) => {
      exists = item[dbkey].includes(bookingSupplierId);
      if (dayKeep[index] && !exists) {
        // Not added to this day, add it here
        item[dbkey].push(bookingSupplierId);
        return;
      }

      if (!dayKeep[index] && exists) {
        // Remove from day
        item[dbkey] = item[dbkey].filter((id) => id !== bookingSupplierId);
      }
    });
  },
  REMOVE_SUPPLIER(state, { dayIndex, dbkey, contentIndex, supplier_rooms }) {
    const day = state.days[dayIndex];
    const removed = day[dbkey].splice(contentIndex, 1);
    const removedId = removed[0];

    // Mark to save
    state.daySaveQueue.days[dayIndex] = true;

    // Remove supplier rooms if supplier no longer on focused day
    if (["transport", "tour_guides"].includes(dbkey)) {
      const supplierId = state.suppliers[removedId].supplier_id;
      const remainingSuppliers = day[dbkey].filter((bsid) => state.suppliers[bsid].supplier_id === supplierId);
      if (!remainingSuppliers.length) {
        day.supplier_rooms = day.supplier_rooms.filter((sroomid) => {
          return supplier_rooms.find((v) => v.id === sroomid).supplier_id !== supplierId;
        });
      }
    }

    // Check if supplier doesn't exists in others
    var clearFromDB = true;
    state.days.forEach((d) => {
      if (d[dbkey].includes(removedId)) clearFromDB = false;
    });
    // Delete from database if removed from all days
    if (clearFromDB) {
      delete state.suppliers[removedId];
    }
    state.daySaveQueue.supplier = {
      key: removedId,
      task: clearFromDB ? "DELETE" : "KEEP",
    };
  },
  REMOVE_ALL_SUPPLIER(state, { bookingSupplierId, dbkey, supplier_rooms }) {
    const supplierId = state.suppliers[bookingSupplierId].supplier_id;
    state.days.forEach((d, index) => {
      d[dbkey] = d[dbkey].filter((id) => id !== bookingSupplierId);
      state.daySaveQueue.days[index] = true;

      // Remove supplier rooms if supplier no longer on focused day
      if (["transport", "tour_guides"].includes(dbkey)) {
        let remainingSuppliers = d[dbkey].filter((bsid) => state.suppliers[bsid].supplier_id === supplierId);
        if (!remainingSuppliers.length) {
          d.supplier_rooms = d.supplier_rooms.filter((sroomid) => {
            return supplier_rooms.find((v) => v.id === sroomid).supplier_id !== supplierId;
          });
        }
      }
    });
    delete state.suppliers[bookingSupplierId];
    state.daySaveQueue.supplier = {
      key: bookingSupplierId,
      task: "DELETE",
    };
  },

  /*************
   * SUPPLIER ROOM
   * *********/
  ADD_SUPPLIER_ROOM_TO_DATE(state, { date, bookingSupplierRoomId }) {
    const dayIndex = state.days.findIndex((v) => v.date === date);
    const day = state.days.find((v) => v.date === date);
    day.supplier_rooms.push(bookingSupplierRoomId);

    state.daySaveQueue.days[dayIndex] = true;
  },
  REMOVE_SUPPLIER_ROOM_BY_DATE(state, { date, bookingSupplierRoomId }) {
    const dayIndex = state.days.findIndex((v) => v.date === date);
    const day = state.days.find((v) => v.date === date);
    day.supplier_rooms = day.supplier_rooms.filter((v) => v !== bookingSupplierRoomId);

    state.daySaveQueue.days[dayIndex] = true;
  },
};

const actions = {
  setDays({ commit }, rooms) {
    commit(SET_DAYS, rooms);
  },
  update({ commit }, { booking_id, day_number, key, val }) {
    commit(UPDATE_DAY, { day_number, key, val });
    const daysToSave = Object.keys(state.daySaveQueue.days).map((i) => state.days[i]);
    return api.updateBookingDays(booking_id, daysToSave);
  },
  focus({ commit }, dayIndex) {
    commit(FOCUS_DAY, dayIndex);
  },

  getDays({ commit }, booking_id) {
    return api
      .getAllBookingDays(booking_id)
      .then((results) => commit(SET_DAYS, results.data))
      .catch((err) => {
        if (err) throw err.data;
      });
  },

  createBookingSupplier({ commit, state }, { booking_id, type_as, dateList, content, product_year }) {
    let createdBSID;

    return api
      .createBookingSupplier(booking_id, type_as, content, product_year)
      .then((v) => {
        createdBSID = v.data;

        commit(INSERT_SUPPLIER_TO_DAYS, {
          bookingSupplierId: v.data,
          type_as,
          dateList,
          content,
        });
        commit(ADD_SUPPLIER, {
          bookingSupplierId: v.data,
          content,
        });

        const daysToSave = Object.keys(state.daySaveQueue.days).map((key) => state.days[key]);
        return api.updateBookingDays(booking_id, daysToSave);
      })
      .then((v) => commit(TRANSFER_SAVE))
      .then((v) => createdBSID)
      .catch((err) => {
        if (err) throw err.data;
      });
  },

  replaceBookingSupplier({ commit, state }, { booking_id, replaceBsSupplierId, content }) {
    const bookingId = booking_id;
    const bookingSupplierId = replaceBsSupplierId;
    let cache = JSON.parse(JSON.stringify(state.suppliers[bookingSupplierId]));
    const supplierId = cache.supplier_id;
    commit(UPDATE_SUPPLIER, { content, bookingSupplierId });

    // Strip cache of keys not updatin
    Object.keys(cache).forEach((key) => {
      if (!(key in content)) delete cache[key];
    });

    return api
      .updateBookingDaySupplier(bookingSupplierId, { data: content, cache, bookingId, supplierId })
      .then((v) => commit(TRANSFER_SAVE))
      .catch((err) => {
        if (err) throw err.data;
      });
  },

  /************
   * ASSSIGN ONE BOOKING DAY
   * *********/
  assignBookingSupplierToDay({ commit }, { booking_id, bookingSupplierId, dateList, type_as }) {
    commit(INSERT_SUPPLIER_TO_DAYS, {
      bookingSupplierId,
      dateList,
      type_as,
    });

    const daysToSave = Object.keys(state.daySaveQueue.days).map((key) => state.days[key]);
    return api.updateBookingDays(booking_id, daysToSave);
  },

  updateBookingSupplier({ commit, state }, { bookingSupplierId, bookingId, data }) {
    let cache = JSON.parse(JSON.stringify(state.suppliers[bookingSupplierId]));
    const supplierId = cache.supplier_id;
    commit(UPDATE_SUPPLIER, { ...data, bookingSupplierId });

    // Strip cache of keys not updatin
    Object.keys(cache).forEach((key) => {
      if (!(key in data.content)) delete cache[key];
    });

    return api
      .updateBookingDaySupplier(bookingSupplierId, { data: data.content, cache, bookingId, supplierId })
      .then((v) => commit(TRANSFER_SAVE))
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  updateBookingSupplierDays({ commit, state }, { booking_id, bookingSupplierId, dayKeep, historyBlob, dbkey }) {
    commit(UPDATE_SUPPLIER_DAYS, { bookingSupplierId, dayKeep, dbkey });
    const daysToSave = state.days;

    return api
      .updateBookingDays(booking_id, daysToSave, historyBlob)
      .then((v) => commit(TRANSFER_SAVE))
      .catch((err) => {
        if (err) throw err.data;
      });
  },

  /************
   * REMOVE SUPPLIER STUFF
   * *********/
  removeBookingSupplier({ commit, state }, { data, supplier_rooms }) {
    commit(REMOVE_SUPPLIER, { ...data, supplier_rooms });

    const daysToSave = Object.keys(state.daySaveQueue.days).map((key) => state.days[key]);
    const supplierRef = state.daySaveQueue.supplier;

    return api
      .removeBookingSupplier(supplierRef.key, {
        days: daysToSave,
        bookingId: data.bookingId,
        supplierId: data.supplierId,
        isdelete: supplierRef.task === "DELETE",
        removedDate: state.days[data.dayIndex].date,
      })
      .then((v) => commit(TRANSFER_SAVE))
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  removeAllOfBookingSupplier({ commit, state }, { data, supplier_rooms }) {
    commit(REMOVE_ALL_SUPPLIER, { ...data, supplier_rooms });

    const daysToSave = Object.keys(state.daySaveQueue.days).map((key) => state.days[key]);
    const supplierRef = state.daySaveQueue.supplier;

    return api
      .removeBookingSupplier(supplierRef.key, {
        days: daysToSave,
        bookingId: data.bookingId,
        supplierId: data.supplierId,
        isdelete: supplierRef.task === "DELETE",
      })
      .then((v) => commit(TRANSFER_SAVE))
      .catch((err) => {
        if (err) throw err.data;
      });
  },

  swapParentAndChildSupplier({ commit, state }, { bookingId, childid, parentid }) {
    // For Nested type ALT ONLY
    let promiseChain = [];

    //Assign nested to child
    const nestedBookingSupplierIds = Object.values(state.suppliers)
      .filter((sup) => sup.parent_bsid === parentid)
      .map((sup) => sup.id);
    nestedBookingSupplierIds.forEach((bsid) => {
      if (bsid === childid) return;
      promiseChain.push(
        actions.updateBookingSupplier(
          { commit, state },
          {
            bookingSupplierId: bsid,
            bookingId,
            data: {
              content: {
                parent_bsid: childid,
              },
            },
          }
        )
      );
    });

    // Assign parent to child
    promiseChain.push(
      actions.updateBookingSupplier(
        { commit, state },
        {
          bookingSupplierId: parentid,
          bookingId,
          data: {
            content: {
              parent_bsid: childid,
              nest_type: "ALT",
            },
          },
        }
      )
    );

    // // Make child parent (ie. strip child status)
    promiseChain.push(
      actions.updateBookingSupplier(
        { commit, state },
        {
          bookingSupplierId: childid,
          bookingId,
          data: {
            content: {
              parent_bsid: null,
              nest_type: null,
            },
          },
        }
      )
    );

    // Save
    return Promise.all(promiseChain);
  },

  updateOneSupplierStatus(
    { commit, state },
    {
      bookingSupplierId,
      bookingSuppliers,
      supplierId,
      bookingId,
      applyAll,
      data,
      overwrite,
      mode,

      // FUNCTIONS
      getLockedPricesFn,
      getConfirmedRoomDataFn,
      getConfirmedCountFn,
    }
  ) {
    // Apply only ONE
    if (!applyAll) {
      const supplier = bookingSuppliers[bookingSupplierId];
      return _updateBookingDateSupplier(commit, bookingSupplierId, mode, {
        supplierId,
        bookingId,
        data: _packageBookingSupplierData({
          multi: false,
          data,
          item: supplier,
          getLockedPricesFn,
          getConfirmedRoomDataFn,
          getConfirmedCountFn,
        }),
        cache: _packageCache(supplier, overwrite),
        mode,
      }).catch((err) => {
        if (err) throw err.data;
      });
    }

    // Apply to ALL cases of this supplier
    let queryPromise = [];
    Object.values(bookingSuppliers)
      .filter((item) => item.supplier_id === supplierId)
      .forEach((item) => {
        queryPromise.push(
          _updateBookingDateSupplier(commit, item.id, mode, {
            supplierId,
            bookingId,
            data: _packageBookingSupplierData({
              multi: true,
              data,
              item,
              getLockedPricesFn,
              getConfirmedRoomDataFn,
              getConfirmedCountFn,
            }),
            cache: _packageCache(item, overwrite),
          })
        );
      });

    return queryPromise
      .reduce(function (prev, curr) {
        return prev.then(curr);
      }, Promise.resolve())
      .catch((err) => {
        if (err) throw err.data;
      });
  },

  updateOneSupplierReminder({ commit, state }, { bookingId, bookingSupplierId, supplierId, cache, data }) {
    return api.updateBookingDaySupplier(bookingSupplierId, { bookingId, supplierId, cache, data });
  },

  /*************
   * SUPPLIER ROOM
   * *********/
  addSupplierRoomToDate({ commit }, { booking_id, supplierRoomIdList, dateList }) {
    supplierRoomIdList.forEach((id) => {
      dateList.forEach((date) => {
        commit(ADD_SUPPLIER_ROOM_TO_DATE, {
          bookingSupplierRoomId: id,
          date,
        });
      });
    });

    const daysToSave = Object.keys(state.daySaveQueue.days).map((key) => state.days[key]);
    return api.updateBookingDays(booking_id, daysToSave);
  },
  removeSupplierRoomDate({ commit }, { booking_id, supplierRoomIdList, dateList }) {
    supplierRoomIdList.forEach((id) => {
      dateList.forEach((date) => {
        commit(REMOVE_SUPPLIER_ROOM_BY_DATE, {
          bookingSupplierRoomId: id,
          date,
        });
      });
    });

    const daysToSave = Object.keys(state.daySaveQueue.days).map((key) => state.days[key]);
    return api.updateBookingDays(booking_id, daysToSave);
  },

  /*************
   * MISCELLANOUS
   * *********/
  toggleRoomingReceived({ commit }, { bookingSupplierId, value, outside }) {
    return api.updateRoomingReceived(bookingSupplierId, value).then((v) => {
      if (outside) return;
      commit(UPDATE_SUPPLIER, {
        bookingSupplierId,
        content: {
          rooming_received: !!value,
        },
      });
    });
  },
};

function _packageBookingSupplierData({
  multi,
  data,
  item,

  // FUNCTIONS
  getLockedPricesFn,
  getConfirmedRoomDataFn,
  getConfirmedCountFn,
}) {
  // Adjust meta stuff
  // Copy only bound applicable keys (otherwise will overwrite EVERYTHING)
  const ALLOWED_COPY = ["bound", "room_nameList", "confirm_number"];
  const fixedMeta = !multi
    ? data.meta // UPDATING SINGLE
    : !data.meta
    ? item.meta
    : Object.assign({}, item.meta, _pick(data.meta, ALLOWED_COPY));

  return {
    status: data.status,
    meta: {
      ...fixedMeta,
      ...getConfirmedRoomDataFn(item, fixedMeta),
      ...getConfirmedCountFn(item, fixedMeta),
    },
    ...getLockedPricesFn(item),
  };
}

function _packageCache(item, overwrite) {
  return {
    status: item.status,
    meta: item.meta,
    ...(!overwrite && item.locked_prices && { locked_prices: item.locked_prices }),
  };
}

function _updateBookingDateSupplier(commit, bookingSupplierId, mode, data) {
  return api.updateBookingDaySupplier(bookingSupplierId, data).then((v) => {
    if (mode === "OUT") return data.data;
    commit(UPDATE_SUPPLIER_ALT, {
      bookingSupplierId,
      content: data.data,
    });
    commit(TRANSFER_SAVE);
  });
}

function _pick(obj, arr) {
  // Reduces an object to only th selected keys
  let o = {};
  arr.forEach((key) => {
    if (key in obj) o[key] = obj[key];
  });
  return o;
}

const getters = {
  days: (state) => state.days,
  day: (state) => (dayIndex) => {
    return state.days[dayIndex] || {};
  },
  dayByDate: (state) => (date) => {
    return state.days.find((d) => d.date === date) || {};
  },
  daySupplier: (state) => (dayIndex, dbkey) => {
    return state.days[dayIndex][dbkey].map((bookingSupplierId) => {
      return state.suppliers[bookingSupplierId] || {};
    });
  },
  dayIncludes: (state) => (dayIndex, dbkey, bookingSupplierId) => {
    return state.days[dayIndex][dbkey].indexOf(bookingSupplierId);
  },
  day_name: (state) => (dayIndex) => {
    let day = state.days[dayIndex] || {};
    return {
      day: `Day ${dayIndex + 1}`,
      date: `${format.formatDate(day.date)}`,
      weekday: format.getWeekday(day.date),
      city: day.city,
    };
  },
  dayCity: (state) => (dayIndex) => {
    return state.days[dayIndex].city;
  },

  // Supplier stuff
  suppliers: (state) => state.suppliers,
  oneSupplier: (state) => (bookingSupplierId) => {
    return state.suppliers[bookingSupplierId] || {};
  },
  whatDays: (state) => (typeUse, bookingSupplierId) => {
    // What days supplier appears
    return service.whatDays(typeUse, bookingSupplierId, state.days);
  },
  whatDaysBySupplierId: (state) => (typeUse, supplierId) => {
    return service.whatDaysBySupplierId(typeUse, supplierId, state.days, state.suppliers);
  },
  firstDaySupplier: (state) => (mod, bookingSupplierId) => {
    // First day a supplier appears
    // Assuming days are sorted
    return state.days.find((x) => x[mod].includes(bookingSupplierId));
  },
  allDaysSupplier: (state) => (mod, bookingSupplierId) => {
    const dateList = service.whatDays(mod, bookingSupplierId, state.days);
    return format.compileDateListReadable(dateList);
  },
  lastDaySupplier: (state) => (mod, bookingSupplierId) => {
    // Last day a supplier appears
    return state.days
      .slice()
      .reverse()
      .find((x) => x[mod].includes(bookingSupplierId));
  },
  confirmedSuppliers: (state) => (mod) => {
    return Object.values(state.suppliers).filter((item) => {
      if (item.status >= CONFIRM) {
        if (!mod) return item;
        if (mod && item.type_as === mod) return item;
      }
    });
  },

  allSuppliersOnDay: (state) => (date, maptype, supplierId, isBsid) => {
    const mod = SuppliersMenu.MOD_DBKEY[maptype];
    const day = state.days.find((v) => v.date === date);
    if (!day[mod]) return [];
    return day[mod]
      .filter((bsid) => state.suppliers[bsid].supplier_id === supplierId)
      .map((bsid) => state.suppliers[bsid]);
  },
  daySupplierFlat: (state) => (mod) => {
    return service.flattenBookingDays(state.days, mod);
  },
  dayCount: (state) => {
    return state.days ? state.days.length : 0;
  },
  dayLinkList: (state) => (booking_id) => {
    return state.days.map((day, dayIndex) => ({
      dayIndex,
      day: `Day ${dayIndex + 1}`,
      weekday: format.getWeekday(day.date),
      date: `${format.formatDate(day.date)}`,
      rawDate: day.date,
      to: { name: "booking_day", params: { booking_id: booking_id, day: dayIndex + 1 } },
    }));
  },

  firstDay: (state) => state.days[0],
  lastDay: (state) => {
    return state.days[state.days.length - 1];
  },

  daySupplierRoomRef: (state) => {
    return state.days.reduce((obj, item) => {
      obj[item.date] = item.supplier_rooms;
      return obj;
    }, {});
  },

  hasChanges: (state) => {
    let cached_days = localStorage.getItem("booking_days");
    let cached_suppliers = localStorage.getItem("booking_suppliers");
    let current_days = JSON.stringify(state.days);
    let current_suppliers = JSON.stringify(state.suppliers);

    return cached_days !== current_days || cached_suppliers !== current_suppliers;
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
