<template>
  <div>
    <a-card @click.native="dialog = true" class="hover caption">
      <v-row>
        <v-col class="py-0 caption">
          <elwrapper :label="config.contact_name.label" midtext>
            {{ contactName }}
          </elwrapper>
        </v-col>
        <v-col class="py-0 caption">
          <elwrapper :label="config.contact_title.label" midtext>
            {{ title }}
          </elwrapper>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="py-0 caption">
          <elwrapper icon="phone_in_talk" preicon="person" midtext>
            <PhoneDisplay :value="phone_number" :country="country" />
          </elwrapper>
        </v-col>
        <v-col class="py-0">
          <elwrapper icon="phone" preicon="business" midtext>
            <PhoneDisplay :value="business_number" :country="country" />
          </elwrapper>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="py-0 caption text-truncate">
          <elwrapper icon="phone_in_talk" preicon="smartphone" midtext>
            <PhoneDisplay :value="cell_number" :country="country" />
          </elwrapper>
        </v-col>
        <v-col class="py-0 caption">
          <elwrapper icon="print" midtext>
            <PhoneDisplay :value="fax_number" :country="country" />
          </elwrapper>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="py-0 caption text-truncate">
          <elwrapper :icon="config.email.icon" midtext>
            {{ email }}
          </elwrapper>
        </v-col>
        <v-col class="py-0 caption text-truncate">
          <elwrapper :icon="config.website.icon" midtext>
            <plink :link="website" style="max-width: 90%" />
          </elwrapper>
        </v-col>
      </v-row>
    </a-card>

    <ContactInfoEditDialog v-model="dialog" :supplierType="supplierType" />
  </div>
</template>

<script>
import info from "../config/formConfig";
import ContactInfoEditDialog from "./_ContactInfoEditDialog";

export default {
  props: {
    supplierType: String,
  },
  components: {
    ContactInfoEditDialog,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    config() {
      const use = {
        contact_name: {},
        contact_title: {},
        email: {},
        website: {},
      };
      return this.supplierType ? info[this.supplierType] || use : use;
    },
    contactName() {
      var primaryContact = this.get("primary_contact");
      if (!primaryContact) return "(None)";

      const par = primaryContact.meta && primaryContact.meta.en_name;
      return (primaryContact.name || "(None)") + (par ? ` / ${par}` : "");
    },
    title() {
      var primaryContact = this.get("primary_contact");
      return primaryContact ? primaryContact.title : undefined;
    },
    phone_number() {
      var primaryContact = this.get("primary_contact");
      return primaryContact ? primaryContact.phone_number : undefined;
    },
    cell_number() {
      var primaryContact = this.get("primary_contact");
      return primaryContact ? primaryContact.cell_number : undefined;
    },
    country() {
      return this.get("country");
    },
    fax_number: {
      get() {
        return this.get("fax_number");
      },
      set(newValue) {
        return this.set("fax_number", newValue);
      },
    },
    business_number: {
      get() {
        return this.get("business_number");
      },
      set(newValue) {
        return this.set("business_number", newValue);
      },
    },
    email() {
      var primaryContact = this.get("primary_contact");

      return primaryContact && primaryContact.email
        ? primaryContact.email.charAt(0).toUpperCase() + primaryContact.email.substring(1)
        : undefined;
    },
    website: {
      get() {
        return this.get("website");
      },
      set(newValue) {
        return this.set("website", newValue);
      },
    },
  },
  methods: {
    get(val) {
      return this.$store.getters["SupplierStore/" + val];
    },
    set(key, val) {
      return this.$store.dispatch("SupplierStore/setUpdate", { [key]: val });
    },
  },
};
</script>
