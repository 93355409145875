import countriesList from "countries-list";
import Products from "./products/dbFields";
import { SupplierFields, SupplierHotelMeta, SupplierTourMeta } from "./suppliers";

const world = countriesList.countries;

const activeSource = [
  {
    label: "Active",
    value: 1,
  },
  {
    label: "Inactive",
    value: 0,
  },
];

const favoriteSource = [
  { label: "Non-favorite", value: 0 },
  { label: "Semi-favorite", value: 1 },
  { label: "Favorite", value: 2 },
  { label: "Reference", value: 3 },
];

const yearList = (function () {
  let startYear = 2020;
  let endYear = new Date().getFullYear() + 1;
  let dump = [];
  for (var i = endYear; i >= startYear; i--) {
    dump.push(`${i}`);
  }
  return dump;
})();

export default {
  suppliers: (mod) => {
    const extra = {
      HOTEL: {
        "meta.hotel_class": {
          label: "Hotel Class",
          multiple: true,
          source: SupplierHotelMeta.hotel_class.source,
        },
        "meta.parking": {
          label: "Parking",
          source: [
            {
              label: "Free",
              value: "free",
            },
            {
              label: "Vehicle ($)",
              value: "per_vehicle",
            },
            {
              label: "Bus ($)",
              value: "per_bus",
            },
            {
              label: "Both ($)",
              value: "both",
            },
            {
              label: "Not Offered",
              value: "not_offered",
            },
          ],
        },
        "meta.breakfast_included": {
          label: "Breakfast",
          source: [
            {
              label: "Included",
              value: "included",
            },
            {
              label: "Product ($)",
              value: "product",
            },
            {
              label: "Not Offered",
              value: "not_offered",
            },
          ],
        },
      },
      RESTAURANT: {
        "meta.style": {
          label: "Style",
          source: true,
          optionListSource: "RESTAURANT_STYLE",
        },
      },
      TOUR_GUIDE: {
        op_area: {
          label: SupplierFields.op_area.label,
          source: true,
          optionListSource: "OPERATING_AREA",
        },
        "meta.language": {
          label: "Languages",
          source: SupplierTourMeta.language.source,
        },
      },
    };

    return {
      pid: SupplierFields.pid,
      name: SupplierFields.name,
      active: {
        label: SupplierFields.active.label,
        source: activeSource,
      },
      search_env: SupplierFields.search_env,
      subjur: {
        label: "State/Pref/Prov",
        inputType: "TEXT",
      },
      city: {
        label: "City",
        source: "cities",
      },
      subcity: {
        label: "Subcity",
        source: "subCities",
      },
      contact_name: {
        label: "Contact Name",
        inputType: "TEXT",
      },
      email: {
        label: "Email",
        inputType: "TEXT",
      },
      favorite: {
        label: SupplierFields.favorite.label,
        source: favoriteSource,
      },
      confirmed: {
        label: "Confirmed (Products)",
        multiple: true,
        source: yearList,
      },
      not_confirmed: {
        label: "Not Confirmed (Products)",
        multiple: true,
        source: yearList,
      },
      ...(mod && extra[mod]),
    };
  },
  products: (mod) => {
    const perSuppliers = {
      HOTEL: {
        "meta.hotel_class": {
          label: "Hotel Class",
          source: SupplierHotelMeta.hotel_class.source,
        },
      },
      RESTAURANT: {
        "meta.style": {
          label: "Style",
          source: true,
          optionListSource: "RESTAURANT_STYLE",
        },
      },
      TOUR_GUIDE: {
        op_area: {
          label: SupplierFields.op_area.label,
          source: true,
          optionListSource: "OPERATING_AREA",
        },
        "meta.language": {
          label: "Languages",
          source: SupplierTourMeta.language.source,
        },
      },
    };

    const extra = {
      ROOM: perSuppliers.HOTEL,
      MEAL: { ...perSuppliers.HOTEL, ...perSuppliers.RESTAURANT },
      TOUR: perSuppliers.TOUR_GUIDE,
      EXCURSION: perSuppliers.EXCURSION,
    };

    return {
      pid: Products.pid,
      name: Products.name,
      supplier_id: Products.supplier_id,
      active: {
        label: SupplierFields.active.label,
        source: activeSource,
      },
      confirmed: {
        label: "Confirmed Rate",
        multiple: true,
        source: yearList,
      },
      not_confirmed: {
        label: "Unconfirmed Rate",
        multiple: true,
        source: yearList,
      },
      subjur: {
        label: "State/Pref/Prov",
        inputType: "TEXT",
      },
      city: {
        label: "City",
        source: "cities",
      },
      subcity: {
        label: "Subcity",
        source: "subCities",
      },
      ...(mod && extra[mod]),
    };
  },
  clients: (mod) => {
    return {
      name: SupplierFields.name,
      country: {
        label: "Country",
        source: Object.keys(world).map((key) => {
          return { label: world[key].name, value: key };
        }),
      },
      subjur: {
        label: "State/Pref/Prov",
        inputType: "TEXT",
      },
      city: SupplierFields.city,
      zip: SupplierFields.zip,
      category: SupplierFields.category,
      type: SupplierFields.type,
    };
  },
};
