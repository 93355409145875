import BookingSupplierEmailView from "./BookingSupplierEmailView";

export default [
  {
    path: "/booking_supplier_email",
    name: "booking_supplier_email",
    component: BookingSupplierEmailView,
    meta: {
      tabAccess: "BOOKING",
    },
  },
];
