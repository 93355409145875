<template>
  <div class="database-table a-card">
    <virtual-scroll-wrapper
      :headers="headers"
      :list="filterList"
      emptyMessage="No Suppliers Found"
      :count="count"
      :colspan="headers.length + (selectOnly ? 1 : 0)"
      :loading="loading"
      :height="height"
      :selectOnly="selectOnly"
    >
      <template v-slot:default="{ item, colwidth }">
        <tr
          class="database-row color-alt canclick"
          :class="{ 'primary--text grey lighten-2': selected[item.id] }"
          @click="onclick(item)"
        >
          <div v-if="selectOnly">
            <v-icon v-if="selectInto" small>keyboard_arrow_right</v-icon>
            <input v-else-if="selectOnly" type="checkbox" :checked="selected[item.id]" />
          </div>
          <td>
            <FavoriteStar
              :id="item.id"
              :selected="item.favorite"
              type="supplier"
              @update="(v) => (item.favorite = v)"
            />
          </td>
          <td v-col-adjwidth="'Name'">
            <SupplierNameColumn :supplier="item" :supplierMeta="item.meta" />
          </td>
          <td v-if="showTranslation" class="caption" v-col-adjwidth="'English'">
            <a-truncate :text="item.meta ? item.meta.en_name : ''" />
          </td>
          <td class="caption" v-col-adjwidth="'Location'">
            <a-truncate :text="getLocation(item)" />
          </td>
          <td class="caption" v-col-adjwidth="'City'">
            <a-truncate :text="getCity(item)" />
          </td>
          <td
            v-if="showHotelClass"
            class="caption"
            v-col-adjwidth="'Hotel Class'"
            v-html="getHotelClass(item.meta)"
          ></td>
          <td v-if="showRestaurantStyle" v-col-adjwidth="'Style'" class="caption">
            {{ item.meta ? item.meta.style : "" }}
          </td>
          <td>
            <ProductCount :count="item.product_count" />
          </td>
        </tr>
      </template>
    </virtual-scroll-wrapper>
  </div>
</template>

<script>
import Vue from "vue";
import SupplierNameColumn from "./subcontent/_SupplierNameColumn";
import FavoriteStar from "./subcontent/_FavoriteStar";
import ProductCount from "./subcontent/_ProductCount";
export default {
  props: {
    dbSource: String,
    query: Object,
    selectOnly: Boolean,
    selectInto: Boolean,
    selectOne: Boolean,
    height: String,

    hideList: Array,
  },
  components: {
    SupplierNameColumn,
    FavoriteStar,
    ProductCount,
  },
  data() {
    return {
      selected: {},
      rows: [],
      loading: true,

      page: 0,
      queryActive: false, // Avoid dbSource + query change clash
      killswitch: false, // If user changes query will fetch is running

      count: 0,
    };
  },
  watch: {
    dbSource(v) {
      this.launchQueryLoop(this.query);
    },
    query(searchQuery) {
      this.launchQueryLoop(searchQuery);
    },
  },
  computed: {
    headers() {
      const env = this.$store.getters["AccountStore/environment"] !== "USA";
      const hotel = this.dbSource === "HOTEL" || this.dbSource === "ALL";
      const restaurant = this.dbSource === "RESTAURANT" || this.dbSource === "ALL";
      return Object.values({
        favorite: {
          text: "",
          value: "favorite",
          width: 40,
        },
        name: {
          text: "Name",
          value: "name",
        },
        ...(env && { env: { text: "English", value: "meta.en_name" } }),
        subjur: {
          text: "Location",
          value: "subjur",
          width: 100,
        },
        city: {
          text: "City",
          value: "city",
        },
        ...(hotel && { hotel_class: { text: "Hotel Class", value: "meta.hotel_class", width: 110 } }),
        ...(restaurant && { style: { text: "Style", value: "meta.style", width: 150 } }),
        product_count: {
          text: "",
          value: "product_count",
          width: 50,
        },
      });
    },
    showHotelClass() {
      return this.dbSource === "HOTEL" || this.dbSource === "ALL";
    },
    showRestaurantStyle() {
      return this.dbSource === "RESTAURANT" || this.dbSource === "ALL";
    },

    filterList() {
      let filtered;
      if (this.hideList && this.hideList.length > 0) {
        filtered = this.rows.filter((item) => {
          return !this.hideList.includes(item.id);
        });
      } else {
        filtered = this.rows;
      }

      return filtered || [];
    },
    showTranslation() {
      return this.$store.getters["AccountStore/environment"] !== "USA";
    },
  },
  methods: {
    onclick(item) {
      if (this.selectOnly && this.selectInto) {
        this.$emit("gointo", item);
        return;
      }

      if (this.selectOnly && this.selectOne) {
        if (this.selected[item.id]) {
          this.selected = {};
        } else {
          this.selected = { [item.id]: item };
        }

        this.$emit("select", this.selected);
        return;
      }

      if (this.selectOnly) {
        if (this.selected[item.id]) {
          Vue.delete(this.selected, item.id);
        } else {
          Vue.set(this.selected, item.id, item);
        }
        this.$emit("select", this.selected);
        return;
      }
      this.goToPage(item);
    },
    getLocation(item) {
      if (item.subjur) {
        const upper = item.subjur.charAt(0).toUpperCase() + item.subjur.substring(1);
        return `${upper} / ${item.country}`;
      }
      return item.country;
    },
    getCity(item) {
      if (item.subcity) return `${item.city} (${item.subcity})`;
      return item.city;
    },
    getHotelClass(meta) {
      if (!meta || !meta.hotel_class) return "";

      let stars = "";
      for (var i = 0; i < Number(meta.hotel_class); i++) {
        stars += "*";
      }
      return `<span style="display:inline-block;width:30px;">(${meta.hotel_class})</span><span>${stars}</span>`;
    },
    goToPage(item) {
      this.$root.$loading.open("Loading");
      this.$router.push({
        name: "suppliers_info",
        params: { id: item.id },
      });
    },

    // DISPLAY DATA
    launchQueryLoop() {
      if (!this.dbSource) return;
      if (this.queryActive) {
        this.killswitch = true;
        return;
      }

      this.queryActive = true;
      this.rows = [];
      this.page = 0;
      this.selected = {};
      this.loading = true;

      // Get total (doesn't need to be in sync)
      // this.getDataCount(this.query);

      // Launch looping promise
      //this._queryLoop(this.query)
      this._tempQuery(this.query)
        .then((data) => {
          if (data === 1) return; // Interrupted
          this.queryActive = false;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$root.$error(err);
        });
    },
    _tempQuery(searchQuery) {
      return this.$store
        .dispatch("DatabaseStore/querySuppliers", {
          type: this.dbSource,
          query: searchQuery,
          page: this.page,
        })
        .then((data) => {
          this.rows = data.sort((a, b) => {
            if (a.favorite > b.favorite) return -1;
            if (a.favorite < b.favorite) return 1;
            return 0;
          });
          this.count = data.length;
        });
    },
    _queryLoop(searchQuery) {
      return new Promise((resolve, reject) => {
        if (this.killswitch) {
          this.killswitch = false;
          this.queryActive = false;
          this.launchQueryLoop();
          return resolve(1);
        }

        this._queryDatabase(this.query)
          .then((data) => {
            if (!data.rows.length) return resolve();
            this.rows.push(...data.rows);

            if (!data.continue) return resolve();

            this.page += 1;
            return this._queryLoop(this.query).then(resolve);
          })
          .catch((err) => reject(err));
      });
    },
    _queryDatabase(searchQuery) {
      return this.$store
        .dispatch("DatabaseStore/querySuppliers", {
          type: this.dbSource,
          query: searchQuery,
          page: this.page,
        })
        .then((rows) => {
          if (!rows.length) return { rows: [], continue: false };

          // MAX 50 query at a time
          // Less than 50, then don't need to query again
          return { rows, continue: rows.length === 50 };
        })
        .catch((err) => {
          this.$root.$error(err);
        });
    },

    // GET FULL SUPPLIER COUNT
    getDataCount(searchQuery) {
      this.$store
        .dispatch("DatabaseStore/getSupplierCount", {
          type: this.dbSource,
          query: searchQuery,
          page: this.page,
        })
        .then((count) => {
          this.count = count;
        })
        .catch((err) => {
          this.$root.$error(err);
        });
    },
  },
  mounted() {
    this.launchQueryLoop(this.query);
  },
};
</script>

<style lang="scss">
.loading-block {
  position: absolute;
  right: 30px;
  padding: 5px;
}
.scroller {
  height: 100%;
}

.user {
  height: 32%;
  padding: 0 12px;
  display: flex;
  align-items: center;
}
</style>
