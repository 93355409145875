<template>
  <v-dialog v-model="dialog" persistent max-width="350">
    <template v-slot:activator="{ on }">
      <v-btn small depressed block v-on="on">
        <v-icon small left>add</v-icon>
        <label>Add</label>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="subtitle-2">Add Language</v-card-title>
      <div class="px-5 mb-3">
        <div>
          <pcheck
            v-for="r in recommend"
            :key="r.value"
            :label="r.label"
            :value="!!selected.value"
            @input="(v) => toggleCheck(v, r)"
          />
        </div>

        <v-divider class="my-3" />

        <a-select returnObject label="Custom" :items="languages" v-model="selected" />
      </div>

      <v-card-actions>
        <v-btn text depressed small @click="dialog = false">Cancel</v-btn>
        <div class="flex-grow-1"></div>
        <g-button
          type="primary"
          label="Add"
          icon="add"
          @onclick="
            $emit('add', selected);
            dialog = false;
          "
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import languageList from "language-list";
import ENV from "@/config/ENV";

const LANGUAGES = languageList();

export default {
  props: {
    value: Boolean,
    exists: Array,
  },
  data() {
    return {
      dialog: false,
      selected: "",
    };
  },
  computed: {
    languages() {
      return LANGUAGES.getData().map((item) => {
        return {
          label: item.language,
          value: item.code.toUpperCase(),
        };
      });
    },
    recommend() {
      return ENV.filter((item) => {
        return !this.exists.includes(item.defaultLanguage_code);
      }).map((item) => {
        return {
          label: item.defaultLanguage_label,
          value: item.defaultLanguage_code,
        };
      });
    },
  },
  methods: {
    toggleCheck(v, lang) {
      this.selected = v ? lang : null;
    },
  },
};
</script>
