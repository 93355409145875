<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)" width="400px" persistent>
    <v-card>
      <v-card-title>
        <span class="caption"></span>
      </v-card-title>

      <v-card-text class="black--text">
        <div v-if="showConfirm">
          <elwrapper label="Confirmation #" widelabel>
            <v-row v-for="(val, i) in number" :key="i">
              <f-text :value="val" @input="(v) => (number[i] = v)"></f-text>
            </v-row>
          </elwrapper>
          <v-row justify="end">
            <g-button label="confirm #" icon="add" @onclick="addConfirmation" />
          </v-row>
        </div>
      </v-card-text>

      <v-card-actions>
        <g-button label="Cancel" @onclick="$emit('input', false)" />
        <div class="flex-grow-1"></div>

        <g-button type="primary" label="Update" @onclick="update" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,

    type: String,
    count: Object,

    roomingName: String,
    showConfirm: Boolean,
    massOption: Boolean,
  },
  data() {
    return {
      number: [null],
      applyAll: false,
    };
  },
  watch: {
    value(value) {
      if (!value) return;

      let num = 1;
      if (this.count && Object.values(this.count)[0]) {
        num = Number(Object.values(this.count)[0]);
      }
    },
  },
  methods: {
    getNameDefault() {
      const cut = this.roomingName.split(" ");
      const first = cut[0];
      cut.shift();
      const last = cut.join(" ");

      return {
        first,
        last,
      };
    },
    addConfirmation() {
      this.number.push(null);
    },
    update() {
      this.$emit("setConfirm", {
        data: {
          ...(this.number.length && { confirm_number: this.number }),
        },
        applyAll: this.applyAll,
      });

      this.first = "";
      this.last = "";
      this.number = [null];
      this.applyAll = false;
    },
  },
};
</script>
