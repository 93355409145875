<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <v-btn small icon v-on="on">
        <v-icon v-if="empty" small color="grey lighten-1">mdi-cart-outline</v-icon>
        <v-icon v-else small color="blue-grey lighten-1">shopping_cart</v-icon>
      </v-btn>
    </template>
    <span>{{ empty ? "No Products" : "Products: " + count }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    count: Number,
  },
  computed: {
    empty() {
      return this.count === 0;
    },
  },
};
</script>
