import Email from "./Email";
import ReplyEmail from "./ReplyEmail";

export default [
  {
    path: "/email",
    name: "email",
    component: Email,
  },
  {
    path: "/email/reply",
    name: "email_reply",
    component: ReplyEmail,
  },
  {
    path: "/inbox",
    name: "inbox",
  },
];
