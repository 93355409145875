<template>
  <div>
    <v-text-field
      outlined
      hide-details
      dense
      :disabled="disabled"
      v-currency="config"
      :value="number"
      @input="update"
    />
    <EConvert v-if="showConvert" :env="env" :number="number" />
  </div>
</template>

<script>
import moneyService from "@/services/money";
import EConvert from "./EConvert";

export default {
  props: {
    disabled: Boolean,
    env: String,
    type: String,
    value: Object, // { type, value, cur }
  },
  components: {
    EConvert,
  },
  data() {
    return {
      temp: 0,
    };
  },
  computed: {
    number() {
      if (!this.value) return undefined;
      if (!this.value.value) return "";
      return moneyService.stripFormatting(this.value.value, this.supplierEnv);
    },

    // Conversion stuff
    source() {
      return moneyService.getCurrencySource(this.env);
    },
    config() {
      return {
        currency: this.source.currency,
        locale: "en-US",
        prefix: this.source.currency_symbol,
      };
    },
    supplierEnv() {
      return this.$store.getters["SupplierStore/env"];
    },
    showConvert() {
      if (this.env == "USA") {
        return this.env != this.supplierEnv;
      } else {
        return this.number;
      }
    },
  },
  methods: {
    update(v) {
      this.$emit("input", {
        type: this.type || "COST",
        value: v,
        cur: this.source.currency,
        ...(this.value && this.value.text && { text: this.value.text }),
      });
    },
  },
  mounted() {
    this.$store.dispatch("CurrencyExchangeStore/fetchRates");
  },
};
</script>
