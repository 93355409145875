<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)" persistent fullscreen>
    <v-card class="supplier-select-wrapper">
      <v-alert v-if="showAlert" type="warning" text dense class="ma-0">
        <span>{{ showAlert }}</span>
      </v-alert>

      <v-card-title class="pa-2">
        <span class="title">{{ title || "Suppliers" }}</span>
      </v-card-title>

      <v-card-text class="dialog-content">
        <div>
          <v-row justify="space-between" class="ma-2 top-row">
            <g-button
              :label="showSearch ? 'hide' : 'show search'"
              :icon="showSearch ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
              @onclick="toggleSearch"
            />
          </v-row>
          <DatabaseSearch v-show="showSearch" :mod="supplierType" type="suppliers" @search="updateQuery" />
          <DatabaseTable
            select-only
            class="table"
            dbConfig="suppliers"
            :dbSource="type"
            :query="query"
            :selectOne="selectOne"
            ref="selectSupplierRef"
            :height="dbHeight"
            @select="(v) => (nuval = v)"
          />
        </div>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <g-button label="Cancel" @onclick="$emit('input', false)" />
        <g-button type="primary" label="Select" @onclick="eject" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DatabaseSearch from "../DatabaseSearch";
import DatabaseTable from "../DatabaseTable";
export default {
  props: {
    title: String,
    supplierType: String,
    selectOne: Boolean,
    value: Boolean,
    showAlert: String,
  },
  components: {
    DatabaseSearch,
    DatabaseTable,
  },
  data() {
    return {
      query: {},
      nuval: "",

      dbHeight: "0px",
      showSearch: true,
    };
  },
  watch: {
    value() {
      this.adjustTable();
    },
  },
  computed: {
    type() {
      return this.supplierType || "ALL";
    },
  },
  methods: {
    eject() {
      this.$emit("update", Object.values(this.nuval)[0]);
      this.$emit("input", false);
    },
    updateQuery(query) {
      this.query = { ...query };
    },

    adjustTable() {
      this.$nextTick().then(function (res) {
        let OFFSET = 50;
        if (res.showSearch) OFFSET += 175;
        if (!res.$refs.selectSupplierRef) return;
        res.dbHeight = res.$refs.selectSupplierRef.$el.clientHeight - OFFSET + "px";
      });
    },
    toggleSearch() {
      this.showSearch = !this.showSearch;
      this.adjustTable();
    },
  },
  mounted() {
    this.adjustTable();
  },
};
</script>
