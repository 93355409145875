<template>
  <a-card nopad>
    <v-tabs v-model="tab" class="elevation-0" :grow="true">
      <v-tabs-slider></v-tabs-slider>
      <v-tab v-for="item in tabs" :key="item.key">
        <span>{{ item.text }}</span>
        <v-icon small right color="primary" v-if="item.key === active">gps_fixed</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item key="general">
        <GeneralSearch
          :mod="mod"
          :dbConfig="dbConfig"
          :isApplied="active === 'general'"
          @search="(v) => $emit('search', v)"
        />
      </v-tab-item>

      <v-tab-item key="location">
        <LocationSearch
          :mod="mod"
          :dbConfig="dbConfig"
          :isApplied="active === 'location'"
          @search="(v) => $emit('search', v)"
        />
      </v-tab-item>

      <v-tab-item v-if="showProduct" key="product">
        <ProductSearch :dbConfig="dbConfig" :isApplied="active === 'product'" @search="(v) => $emit('search', v)" />
      </v-tab-item>

      <v-tab-item v-if="showSupplier" key="supplier">
        <SupplierSearch :dbConfig="dbConfig" :isApplied="active === 'supplier'" @search="(v) => $emit('search', v)" />
      </v-tab-item>
    </v-tabs-items>
  </a-card>
</template>

<script>
import LocationSearch from "./search/_LocationSearch";
import GeneralSearch from "./search/_GeneralSearch";
import ProductSearch from "./search/_ProductSearch";
import SupplierSearch from "./search/_SupplierSearch";

export default {
  components: {
    LocationSearch,
    ProductSearch,
    GeneralSearch,
    SupplierSearch,
  },
  props: {
    mod: String,
    dbConfig: String,
  },
  data() {
    return {
      tab: "location",
    };
  },
  computed: {
    active() {
      const saved = this.$store.getters["DatabaseStore/activeTab"](this.dbConfig);
      return saved.tab;
    },
    tabs() {
      return [
        {
          text: "General",
          key: "general",
        },
        {
          text: "By Location",
          key: "location",
        },
        {
          text: this.dbConfig === "suppliers" ? "By Product" : "By Supplier",
          key: this.dbConfig === "suppliers" ? "product" : "supplier",
        },
      ];
    },

    showProduct() {
      return this.dbConfig === "suppliers";
    },
    showSupplier() {
      return this.dbConfig === "products";
    },
  },
  watch: {
    mod(v) {
      this.setSavedSearch();
    },
  },
  methods: {
    setSavedSearch() {
      // Get saved tab (if exists)
      const savedTab = this.$store.getters["DatabaseStore/activeTab"](this.dbConfig);
      const index = this.tabs.findIndex((item) => savedTab.tab === item.key);
      this.tab = index;

      // Query existing search
      if (!savedTab.data) return;
      this.$emit("search", savedTab.data);
    },
  },
  mounted() {
    this.setSavedSearch();
  },
};
</script>
