<template>
  <div class="d-flex full-height">
    <v-row class="a-field">
      <v-col class="label py-0">
        <div class="label-wrapper-label">
          <span class="subtitle-2">Parking</span>
        </div>
      </v-col>
      <v-col class="py-0 d-flex" align-self="center">
        <cyoa-clear v-if="showValue" class="caption" @clear="clearField">
          <div v-if="value.per_vehicle">{{ value.per_vehicle.value }} / Vehicle</div>
          <div v-if="value.per_bus">{{ value.per_bus.value }} / Bus</div>
          <div v-if="value.type">{{ value }}</div>
        </cyoa-clear>
        <cyoa
          v-else
          :choosePath="config.choosePath"
          :value="value"
          :country="country"
          :config="config.config"
          :cols="config.cols"
          @input="(v) => $emit('input', v)"
        />
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" width="400" persistent>
      <v-card class="caption">
        <div class="subtitle-2 pa-2">Parking Cost</div>
        <v-row class="mx-3">
          <v-col> Per Vehicle </v-col>
          <v-col>
            <enumber v-model="perVechicle" :country="country" />
          </v-col>
          <v-col> / Day </v-col>
        </v-row>
        <v-row class="mx-3">
          <v-col> Per Bus </v-col>
          <v-col>
            <enumber v-model="perBus" :country="country" />
          </v-col>
          <v-col> / Day </v-col>
        </v-row>

        <v-card-actions>
          <div class="flex-grow-1"></div>
          <g-button
            @onclick="
              $emit('input', null);
              dialog = false;
            "
            label="Cancel"
          />
          <g-button type="primary" @onclick="setValue" label="Set" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    config: Object,
    country: [String, Object],
    value: Object,
  },
  data() {
    return {
      dialog: false,
      costInput: null,
      costPerType: null,

      holdLoop: false,

      perVechicle: null,
      perBus: null,
    };
  },
  watch: {
    value(v) {
      if (v && v.type === "COST" && !this.holdLoop) {
        this.dialog = true;
      }
      this.holdLoop = false;
    },
  },
  computed: {
    showValue() {
      if (!this.value) return false;
      if (this.value.per_vehicle || this.value.per_bus) return true;
      return false;
    },
  },
  methods: {
    clearField() {
      this.$emit("input", null);
    },
    setValue() {
      this.$emit("input", {
        per_vehicle: this.perVechicle,
        per_bus: this.perBus,
      });
      this.holdLoop = true;
      this.dialog = false;
    },
  },
};
</script>
