<template>
  <div class="d-flex">
    <div class="flex-grow-1"></div>

    <ExportButton />
  </div>
</template>

<script>
import ExportButton from "./_ExportButton";

export default {
  components: {
    ExportButton,
  },
  computed: {},
};
</script>
