<template>
  <div class="t-footer font-weight-bold text-center">
    <div v-if="editMode">
      <RichTextEditor maxheight="short" :value="footerShow" @input="(v) => $emit('updateFooter', v)" />
    </div>
    <div v-else v-html="footerShow" />
  </div>
</template>

<script>
const DEFAULT_ATTENTION = `
		Tour Director to SCAN and EMAIL feedback survey back to Supernature as soon as possible.<br/>
		EMAIL : MARC.BORREMANS@SUPERNATURE.TRAVEL`;

export default {
  props: {
    editMode: Boolean,
    footer: String,
  },
  computed: {
    // ATTENTION BLOCK
    footerShow() {
      return this.footer || this.$store.getters["TextConfigStore/TECHNICAL_FOOTER"];
    },
  },
};
</script>

<style lang="scss">
.technical-wrapper {
  .t-footer {
    padding: 10px 0;
    margin: 10px 0;
    border-top: 3px solid black;
    border-bottom: 3px solid black;
  }
}
</style>
