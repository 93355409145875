<template>
  <v-row class="mx-0 flex-nowrap">
    <v-col cols="10" class="caption" align-self="center">
      <a-truncate caption :text="product.name" />
    </v-col>

    <v-col cols="1" v-if="hasBreakfast">
      <Breakfast />
    </v-col>

    <v-col cols="1" v-if="notes">
      <Notes :notes="notes" />
    </v-col>

    <v-col cols="1" v-if="needsBuffer">
      <!-- EMPTY BUFFER -->
    </v-col>
  </v-row>
</template>

<script>
import Breakfast from "./_Breakfast";
import Notes from "./_Notes";

export default {
  props: {
    product: Object,
    notes: String,
    supplierMeta: Object,
  },
  components: {
    Breakfast,
    Notes,
  },
  computed: {
    mainColCount() {
      let col = 12;
      if (this.needsBuffer) {
        col -= 2;
      } else {
        if (this.notes) col -= 1;
        if (this.hasBreakfast) col - 1;
      }
      return col;
    },
    hasBreakfast() {
      if (this.product.product_type !== "ROOM") return false;

      const smeta = this.supplierMeta || this.product.supplier_meta;

      if (!smeta) return false;
      if (!smeta.breakfast) return false;
      return smeta.breakfast.value === "included";
    },
    needsBuffer() {
      return this.hasBreakfast && !this.notes;
    },
  },
};
</script>
