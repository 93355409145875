import _ from "lodash";

export default {
  getSupplierRoomFlatList,
  getSupplierRoomsByDate,
};

function getSupplierRoomFlatList({ dayList, bookingSupplierRooms, list }) {
  return (dayList.length ? dayList : [])
    .reduce((arr, day) => {
      return _.uniq([...arr, ...(day.supplier_rooms || [])]);
    }, [])
    .map((sroomid) => {
      return {
        ...(list ? (bookingSupplierRooms || []).find((sroom) => sroom.id === sroomid) : bookingSupplierRooms[sroomid]),
        roomDateRange: _getRoomDateRange(dayList, sroomid),
      };
    });
}

function _getRoomDateRange(dayList, sroomid) {
  return dayList.filter((day) => (day.supplier_rooms || []).includes(sroomid)).map((day) => day.date);
}

function getSupplierRoomsByDate({ date, dayList, bookingSupplierRooms }) {
  const day = dayList.find((day) => day.date === date);
  return dayList.map((sroomid) => {
    return bookingSupplierRooms[sroomid];
  });
}
