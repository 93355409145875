<template>
  <span>
    <v-tooltip top left v-if="hasRoomingList">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" small>night_shelter</v-icon>
      </template>
      Has rooming list
    </v-tooltip>
  </span>
</template>

<script>
export default {
  props: {
    roomingList: String,
  },
  computed: {
    hasRoomingList() {
      if (!this.roomingList) return false;
      if (this.roomingList === "[]") return false;

      try {
        JSON.parse(this.roomingList);
        return true;
      } catch (e) {
        return false;
      }
    },
  },
};
</script>
