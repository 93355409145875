export default [
  {
    label: "Single (1 queen or 1 king)",
    alt_label: "SINGLE Occupancy (1 pax - 1 bed)",
    print: "Single",
    value: "SINGLE_DK",
    pax: 1,
  },
  {
    label: "Double (1 queen or 1 king)",
    alt_label: "DOUBLE Occupancy (2 pax - 1 bed)",
    value: "DOUBLE_QK",
    print: "Double",
    pax: 2,
  },
  {
    label: "Twin (2 beds)",
    alt_label: "TWIN Occupancy (2 pax - 2 beds)",
    value: "DOUBLE_DOUBLE",
    print: "Twin",
    pax: 2,
  },
  {
    label: "Triple (2 queen or 2 king)",
    alt_label: "TRIPLE Occupancy (3 pax - 2 beds)",
    value: "TRIPLE",
    print: "Triple",
    pax: 3,
  },
  {
    label: "Quad (2 queen or 2 king)",
    alt_label: "QUADRUPLE Occupancy (4 pax - 2 beds)",
    print: "Quad",
    value: "QUAD",
    pax: 4,
  },
  {
    label: "Tour Guide",
    alt_label: "SUPPLIER",
    print: "Tour Guide",
    value: "SUPPLIER.TOUR_GUIDE",
    pax: 1,
    ignore: true,
  },
  {
    label: "Bus Driver",
    alt_label: "SUPPLIER",
    print: "Bus Driver",
    value: "SUPPLIER.TRANSPORT",
    pax: 1,
    ignore: true,
  },
];
