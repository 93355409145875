<template>
  <div>
    <a-hover-menu>
      <template v-slot:activator>
        <v-icon small left>hotel</v-icon>
      </template>

      <div>
        <div>
          <!-- <span class="subtitle-2">{{first}} {{last}}</span> -->
          <span class="caption">({{ roomLabel }})</span>
        </div>
        <div>
          <span class="caption font-weight-bold">Rooming Days</span>
        </div>
        <div v-for="date in dateList" :key="date">
          <span class="caption">{{ date }}</span>
        </div>
      </div>
    </a-hover-menu>
  </div>
</template>

<script>
import format from "@/services/format";
import SupplierMenu from "@/config/menus/suppliersMenu";
export default {
  props: {
    first: String,
    last: String,
    roomType: String,
    roomDateRange: Array,
  },
  computed: {
    dateList() {
      // Per NIGHTS (So obviously don't include last day)
      if (this.roomDateRange.length >= this.$store.getters["BookingDayStore/dayCount"] - 1) {
        return ["All Days"];
      }
      // ASSUMING ALREADY SORTED
      return this.roomDateRange
        .filter((d) => d !== "ALL")
        .sort((a, b) => {
          if (a > b) return 1;
          if (a < b) return -1;
          return 0;
        })
        .map((day) => {
          return format.formatDate(day);
        });
    },
    roomLabel() {
      return SupplierMenu.FULL.find((x) => x.module === this.roomType).roomKind;
    },
  },
};
</script>
