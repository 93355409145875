<template>
  <v-row justify="center">
    <CheckboxWrapper
      v-for="(image, i) in imageList"
      :key="image.id"
      :value="image.id"
      :checked="!!image.isdefault"
      :show-check="showChecks"
      @toggle="(v) => $emit('toggle', v)"
    >
      <a class="docdownload" target="_blank" :href="getLink(image)">
        <v-menu open-on-hover offset-y>
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <ImageBlob :hidetext="hidetext" :image="image" />
            </div>
          </template>

          <div class="pa-2 white img-hover">
            <v-row v-if="!noedit" class="mx-0 px-3" align="center">
              <v-btn icon depressed @click="$emit('edit', image)">
                <v-icon>edit</v-icon>
              </v-btn>
              <span class="subtitle-2 mx-3">{{ image.description }}</span>
            </v-row>
            <img :src="hostName + image.src" style="max-width: 500px" />
          </div>
        </v-menu>
      </a>
    </CheckboxWrapper>
  </v-row>
</template>

<script>
import dateFormat from "@/services/format";
import ImageBlob from "./_ImageBlob";
export default {
  props: {
    noedit: Boolean,
    hidetext: Boolean,
    imageList: Array,
    showChecks: Boolean,
  },
  components: {
    ImageBlob,
  },
  data() {
    return {
      positionY: 100,
    };
  },
  computed: {
    hostName() {
      return this.$store.getters["host"];
    },
  },
  methods: {
    // VIEW
    getLink(doc) {
      return this.$store.getters["host"] + doc.src;
    },
  },
};
</script>

<style lang="scss">
.docdownload {
  .img-hover img {
    max-width: 300px;
  }
}
</style>
