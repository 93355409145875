<template>
  <v-card flat>
    <v-form @submit.prevent="updateSearch">
      <v-card-text>
        <a-text-field widelabel label="Supplier Name/ID" v-model="supplierInfo" />
      </v-card-text>

      <v-card-actions>
        <g-button label="Clear" @onclick="clearSearch" />
        <v-spacer />
        <search-button />
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
export default {
  props: {
    isApplied: Boolean,
    dbConfig: String,
  },
  computed: {
    supplierInfo: {
      get() {
        return this.$store.getters["DatabaseStore/supplierSearch"];
      },
      set(nuval) {
        this.$store.dispatch("DatabaseStore/updateSearch", { tab: "supplier", data: nuval });
      },
    },
  },
  methods: {
    clearSearch() {
      this.$store.dispatch("DatabaseStore/updateSearch", { tab: "supplier", data: "" });

      if (this.isApplied) {
        this.$emit("search", {});
        this.$store.dispatch("DatabaseStore/applySearch", {
          dbconfig: this.dbConfig,
        });
      }
    },
    updateSearch() {
      const search = {
        suppliers: true,
        multiple: true,
        list: [
          {
            value: this.supplierInfo,
            columns: ["pid", "name"],
          },
        ],
      };

      this.$emit("search", search);
      this.$store.dispatch("DatabaseStore/applySearch", {
        tab: "supplier",
        dbconfig: this.dbConfig,
        data: search,
      });
    },
  },
};
</script>
