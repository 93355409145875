<template>
  <div>
    <span v-if="!customCommissionAmount">+ {{ commission }}%</span>
    <span v-if="!customCommissionAmount" class="pl-1">({{ commissionTotal }})</span>

    <span v-if="customCommissionAmount">+ (C) ${{ customCommissionAmount }}</span>
  </div>
</template>

<script>
export default {
  props: {
    commission: [Number, Boolean],
    commissionTotal: String,
    customCommissionAmount: [Number, String],
  },
};
</script>
