export default [
  // {
  //   label: 'Japan',
  //   value: 'JPN',
  //   areaCode: 81,
  //   defaultCountry: 'JP',

  //   defaultLanguage_label: 'Japanese',
  //   defaultLanguage_code: 'JA'
  // },
  {
    label: "USA",
    value: "USA",
    areaCode: 1,
    defaultCountry: "US",

    defaultLanguage_label: "English",
    defaultLanguage_code: "EN",
  },
  {
    label: "Canada",
    value: "CDA",
    areaCode: 1,
    defaultCountry: "CA",

    defaultLanguage_label: "French",
    defaultLanguage_code: "FR",
  },
];
