const HEADER_COLOR = "FFC79D74";
const HEADER_BACKGROUND = "FFe5e5e5";

const DEFAULT_CONFIG = {
  fill: { type: "pattern", pattern: "solid", fgColor: { argb: HEADER_BACKGROUND } },
  border: {
    top: { style: "thin" },
    left: { style: "thin" },
    bottom: { style: "thin" },
    right: { style: "thin" },
  },
  font: {
    bold: true,
    size: 12,
    name: "Calibri",
    color: {
      argb: HEADER_COLOR,
    },
  },
  alignment: {
    wrapText: true,
    vertical: "middle",
    horizontal: "center",
  },
};

export default {
  HEADER_COLOR,
  DEFAULT_CONFIG,

  CLIENT: {
    value: "Client",
    width: 36,
  },
  GROUP_NAME: {
    value: "Group Name",
    width: 36,
  },

  DATE: {
    value: "Date",
    width: 20,
  },
  DATE_START: {
    value: "Date Start",
    width: 20,
  },
  DATE_END: {
    value: "Date End",
    width: 20,
  },
  CITY: {
    value: "City",
    width: 24,
  },
  CITY_START: {
    value: "City Start",
    width: 24,
  },
  CITY_END: {
    value: "City End",
    width: 24,
  },
  SUPPLIER_NAME: {
    value: "Supplier Name",
    width: 36,
  },
  GUIDE_NAME: {
    value: "Guide Name",
    width: 36,
  },
  PRODUCTS: {
    value: "Product",
    width: 48,
  },

  ADDRESS: {
    value: "Address",
    width: 36,
  },
  PHONE_NUMBER: {
    value: "Phone Number",
    width: 36,
  },
  WEBSITE: {
    value: "Website",
    width: 36,
  },

  HOTEL_NIGHT: {
    value: "Number of nights",
    width: 20,
  },

  TIME: {
    value: "Time",
    width: 20,
  },
  MENU: {
    value: "Menu",
    width: 64,
  },

  PAX: {
    value: "Pax",
    width: 20,
  },

  NOTES: {
    value: "Notes",
    width: 24,
  },

  SOCIAL_RESPONSIBILITIES: {
    value: "Social Engagements",
    width: 36,
  },

  TOUR_GUIDE: {
    value: "Tour Guide",
    width: 36,
  },
};
