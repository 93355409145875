import DatabaseSupplierEmailView from "./DatabaseSupplierEmailView";

export default [
  {
    path: "/database_email/:supplier_type",
    name: "database_email",
    component: DatabaseSupplierEmailView,
    meta: {
      isclient: false,
      tabAccess: "DATABASE",
    },
  },
  {
    path: "/database_client_email",
    name: "database_client_email",
    component: DatabaseSupplierEmailView,
    meta: {
      isclient: true,
      tabAccess: "DATABASE",
    },
  },
];
