<template>
  <div>
    <ProductHeader
      :supplier-id="supplierId"
      :supplier-type="supplierType"
      :supplier-name="supplierName"
      :productId="productId"
      :productName="productName"
      :productType="productType"
    />
    <v-container fluid fill-height class="justify-center">
      <router-view></router-view>
    </v-container>
  </div>
</template>

<script>
import { ProductHeader } from "@/modules/products";

export default {
  components: {
    ProductHeader,
  },
  data() {
    return {
      productId: "",
    };
  },
  computed: {
    productName() {
      return this.$store.getters["ProductStore/name"];
    },
    productType() {
      return this.$store.getters["ProductStore/type"];
    },

    supplierId() {
      return this.$store.getters["ProductStore/supplier_id"];
    },
    supplierType() {
      return this.$store.getters["ProductStore/supplier_type"];
    },
    supplierName() {
      return this.$store.getters["ProductStore/supplier_name"];
    },
  },
  mounted() {
    // Refreshing page
    // Wrong Environment for supplier, then redirect to database suppliers
    const cached = this.$store.state.ProductStore.cached;
    const env = this.$store.getters["AccountStore/environment"];
    if (cached && cached.env && cached.env !== env) {
      this.$router.push({
        name: "database_view",
        params: { view: "products", sub: cached.product_type },
      });
      return;
    }

    this.productId = this.$route.params.id;
    this.$store.dispatch("ProductStore/getProduct", this.productId).catch((err) => this.$root.$error(err));
  },
};
</script>
