import CountryDependentConfig from "@/config/countryDependentConfig";
import ENV from "@/config/ENV";

export default {
  _num,
  format,
  stripFormatting,
  getCurrencySource,
  getConvertedValue,
  getConvertToForeign,
  convertFormat,
};

function format(v, m, skipZeros) {
  // Converts number to "formated" kind
  // PARAMS
  // 	v 			@{String/Number} Value
  // 	m 			@{Object} Configuation
  // 	 	=> precision 	@{Number} How many zeros
  // skipZeros 	@{Boolean}
  // RETURN 	@{String}
  const num = `${v}`.split(".");
  const first = num[0].replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // No decimal exists OR no decimal allowed
  if (!m || !m.precision) return first;
  if (!num[1]) {
    return skipZeros ? first : `${first || 0}.${_zeroCount(m.precision)}`;
  }

  let end = num[1].substring(0, m.precision);
  end += _zeroCount(m.precision - end.length);

  return skipZeros ? first : `${first || 0}.${end}`;
}

function stripFormatting(v, ce, p) {
  // Converts strings to float
  // v 	@{Any}
  // RETURN @{Float}
  if (!v || v === "undefined" || v === "null" || !`${v}`.trim()) return 0;
  if (typeof v === "string" && v.includes("null")) return 0;
  if (typeof v !== "string" && isNaN(v)) return 0;
  const source = getCurrencySource(ce);
  let strippedString = `${v}`.match(new RegExp(/[0-9.]/, "gi"));

  let res = "";
  if (strippedString !== null) res = strippedString.filter((val) => val).join("");

  return _num(res, p || source.money.precision);
}

function _num(x, p) {
  let power = 10 ** (p || 2);
  return Math.round(+(x || 0) * power) / power;
}

function _insertCommas(value) {
  // Inserts commas into string
  // PARAMS
  // 	value 	@{String}	Number to add commas to
  // RETURN @{String}
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function _zeroCount(count) {
  // Creates string of zeros
  // PARAMS
  //	count 	@{Number} How many zeros
  // RETURN @{String}
  if (!count) return "";
  return new Array(count).fill("0").join("");
}

function getCurrencySource(countryOrEnv) {
  if (!countryOrEnv) return CountryDependentConfig["US"];
  let country =
    countryOrEnv.length === 2 ? countryOrEnv : (ENV.find((v) => v.value === countryOrEnv) || {}).defaultCountry;
  const source = CountryDependentConfig[country] || CountryDependentConfig.US;
  return source;
}

function getConvertedValue(rate, countryOrEnv, storeGetter) {
  // Converts (foreign) currency to US currency using provided conversion rate function
  // PARAMS
  // 	rate 		@{String/Number} Rate to convert
  // 	country 	@{String} Country OR environment of origin
  // 	storeGetter	@{StoreObject} Contains the conversion rate function
  // RETURN @{Float}

  // Allow environment to be used
  let source = getCurrencySource(countryOrEnv);
  const us = CountryDependentConfig.US;
  const convert = `${source.currency}_${us.currency}`;

  const number = stripFormatting(rate);
  const calc = storeGetter["CurrencyExchangeStore/" + convert];
  if (!calc) return 0;
  return _num(calc(number));
}

function getConvertToForeign(rate, countryOrEnv, storeGetter) {
  // Converts US currency to foreign using provided conversion rate function
  // PARAMS
  // 	rate 		@{String/Number} Rate to convert
  // 	country 	@{String} Country OR environment of origin
  // 	storeGetter	@{StoreObject} Contains the conversion rate function
  // RETURN @{Float}

  // Allow environment to be used
  let source = getCurrencySource(countryOrEnv);
  const us = CountryDependentConfig.US;
  const convert = `${us.currency}_${source.currency}`;
  const number = stripFormatting(rate);
  const calc = storeGetter["CurrencyExchangeStore/" + convert];
  if (!calc) return 0;
  return _num(calc(number));
}

/**
 * Converts a formatted rate string currency. E.g CAD 72.38 -> $ 72.38
 * Converts prefix
 * @param formatted the string to convert
 * @param oldCountry the country to convert from
 * @param newCountry the country to convert to
 */
function convertFormat(formatted, oldCountry, newCountry) {
  const oldSource = getCurrencySource(oldCountry);
  const newSource = getCurrencySource(newCountry);

  return formatted
    .replaceAll(oldSource.currency_symbol, newSource.currency_symbol)
    .replaceAll(oldSource.currency, newSource.currency == "USD" ? newSource.prefix : newSource.currency);
}
