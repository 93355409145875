<template>
  <v-col>
    <button-control-header
      static-mode="SAVE"
      @clear="$router.push({ name: 'suppliers_history' })"
      :onsave="createNote"
    />

    <a-card>
      <elwrapper label="Created By">{{ name }}</elwrapper>

      <elwrapper label="Note" texttop>
        <RichTextEditor maxheight="300px" v-model="text" />
      </elwrapper>
    </a-card>
  </v-col>
</template>

<script>
export default {
  data() {
    return {
      text: "",
    };
  },
  computed: {
    supplierId() {
      return this.$store.getters["SupplierStore/id"];
    },
    name() {
      return this.$store.getters["AccountStore/name"];
    },
  },
  methods: {
    createNote() {
      return this.$store
        .dispatch("SupplierHistoryStore/addNote", {
          supplier_id: this.supplierId,
          note: this.text,
        })
        .then((v) => {
          this.$root.$success("New note saved");
          this.$router.push({ name: "suppliers_history" });
        })
        .catch((err) => this.$root.$error(err));
    },
  },
};
</script>
