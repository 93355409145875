import PhoneService from "@/components/form/phones/phoneService";
import PHONE_MASK from "@/config/PHONE_MASK";
import moment from "moment";

function isNullObject(obj) {
  if (typeof obj === "string" && obj === "null") return false;
  if (typeof obj === "string") obj = JSON.parse(obj);
  const values = Object.values(obj);
  const unique = values.filter((v, i, a) => a.indexOf(v) === i);
  return (unique.length === 1 && unique.at(0) === "") || obj === null;
}

const DEFAULT_CONFIG = {
  border: {
    top: { style: "thin" },
    left: { style: "thin" },
    bottom: { style: "thin" },
    right: { style: "thin" },
  },
  font: {
    name: "Calibri",
  },
  alignment: {
    wrapText: true,
    vertical: "middle",
    horizontal: "center",
  },
};

export default {
  DEFAULT_CONFIG,
  DEFAULT: (value) => {
    return {
      value,
    };
  },
  DATE: (day) => {
    return {
      value: moment(new Date(day.date || day))
        .utc()
        .format("D MMM YYYY"),
    };
  },
  CITY: (sup) => {
    return {
      value: `${sup.city || ""}${(sup.subcity && " (" + sup.subcity + ")") || ""}`,
    };
  },
  CITY_FROM_DAY: (day) => {
    return {
      value: day.city,
    };
  },
  SUPPLIER_NAME: (sup) => {
    return { value: sup.name };
  },
  ADDRESS: (sup) => {
    let address = `${[sup.address, sup.subcity, sup.city, sup.subjar || ""].filter((i) => i).join(", ")}`;
    return { value: address };
  },
  PHONE_NUMBER: (sup) => {
    const primaryPhoneNumber =
      sup.primary_phone_number !== "" && sup.primary_phone_number !== null && !isNullObject(sup.primary_phone_number)
        ? sup.primary_phone_number
        : null;
    const businessPhoneNumber =
      sup.business_number !== "" && sup.business_number !== null && !isNullObject(sup.business_number)
        ? sup.business_number
        : null;

    let ptem = PhoneService.phoneDisplay(
      businessPhoneNumber ?? primaryPhoneNumber,
      PHONE_MASK(sup.country || "US")[0],
      "US"
    );

    const phone = [ptem.code ? `+${ptem.code}` : "", ptem.phone, ptem.ext ? `ext: ${ptem.ext}` : ""].join(" ").trim();

    return { value: phone.includes("null") ? "" : phone };
  },
  WEBSITE: (sup) => {
    const website = sup.website || "";
    return { value: website };
  },

  HOTEL_NIGHT: (nights) => {
    return { value: nights ?? "(Not Applicable)" };
  },

  PRODUCTS: (sup) => {
    return {
      value: Object.values(sup.products)
        .map((p) => p.name)
        .join(","),
    };
  },

  TIME: (sup) => {
    return { value: sup.time };
  },
  MENU: (sup) => {
    const menuNotes = Object.values(sup.products).map((p) => p.menu_notes);

    return {
      value: menuNotes.join("\n").replaceAll("-", "*"),
    };
  },
  PAX: (pax) => {
    return { value: pax };
  },

  NOTES: (booking) => {
    return { value: (booking.notes || "").replaceAll("-", "*") };
  },

  SOCIAL_RESPONSIBILITIES: (sup) => {
    return { value: (sup.supplier_social_responsibilities || "").replaceAll("-", "*") };
  },
};
