<template>
  <a-card>
    <elwrapper label="Social">
      <div v-if="editable">
        <v-text-field
          outlined
          hide-details
          dense
          v-mask="'###-##-####'"
          masked
          :value="social_security"
          @input="(v) => (social_security = v)"
        />
      </div>
      <div v-else>
        <span>{{ social_security }}</span>
      </div>
    </elwrapper>
  </a-card>
</template>

<script>
import { mask } from "vue-the-mask";

export default {
  directives: {
    mask,
  },
  computed: {
    isAdmin() {
      return this.$store.getters["isAdmin"];
    },
    editable() {
      if (this.isAdmin) return true;
      if (this.social_security.includes("*")) return false;
      return true;
    },
    social_security: {
      get() {
        return this.$store.getters["SupplierStore/social_security"];
      },
      set(value) {
        this.$store.dispatch("SupplierStore/setUpdate", { social: value });
      },
    },
  },
  methods: {},
};
</script>
