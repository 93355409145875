<template>
  <v-text-field
    :value="value"
    @input="(v) => $emit('input', v)"
    outlined
    hide-details
    dense
    :append-icon="showPassword ? 'visibility' : 'visibility_off'"
    :type="showPassword ? 'text' : 'password'"
    @click:append="showPassword = !showPassword"
    :loading="loading"
  />
</template>

<script>
export default {
  props: {
    value: String,
    loading: Boolean,
  },
  data() {
    return {
      showPassword: false,
    };
  },
};
</script>
