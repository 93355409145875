<template>
  <div>
    <v-row :justify="admin ? 'space-between' : 'end'">
      <delete-button
        v-if="admin"
        quiet
        headline="Delete Supplier?"
        text="This will ALSO delete all products, contacts, documents, etc. associated with this supplier's id."
        :disabled="supplierInBooking"
        disabledText="Supplier has been used in booking. Cannot delete."
        :ondelete="ondelete"
      />

      <save-button :disabled="!canSave" :onsave="onsave" />
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    admin: Boolean,
    onsave: Function,
    ondelete: Function,
    canSave: Boolean,

    supplierId: String,
  },
  data() {
    return {
      supplierInBooking: false,
    };
  },
  mounted() {
    this.$store
      .dispatch("SupplierStore/checkSupplierInBooking", this.supplierId)
      .then((val) => {
        this.supplierInBooking = !!val.count;
      })
      .catch((err) => this.$root.$error(err));
  },
};
</script>
