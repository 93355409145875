import HistoryView from "./HistoryView";

export default [
  {
    path: "history",
    name: "client_history",
    component: HistoryView,
    meta: {
      toolbar: "client",
      tabAccess: "DATABASE",
    },
  },
];
