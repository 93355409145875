<template>
  <v-row :class="rowClass" align="center">
    <v-col class="subtitle-2">
      {{ supplierName }}
    </v-col>
    <v-col>
      <v-chip v-for="email in emailList" :key="email.id">{{ email.displayText }}</v-chip>
    </v-col>
    <v-col cols="2">
      <span v-if="PASS">
        Sent
        <v-icon color="green">check</v-icon>
      </span>
      <span v-else-if="ERROR">
        Failed
        <v-icon color="red darken-2">clear</v-icon>
      </span>

      <v-progress-circular v-else size="20" width="3" color="grey" indeterminate />
    </v-col>
    <g-button
      xsmall
      icon="refresh"
      label="Re-send"
      @onclick="$emit('send-one-mail')"
      class="mr-2"
      :disabled="!ERROR"
      :loading="ERROR && loading"
    />
  </v-row>
</template>

<script>
export default {
  props: {
    supplierName: String,
    contacts: Object,
    promise: Number,
    loading: Boolean,
  },
  computed: {
    emailList() {
      return this.contacts.emailList.map((item) => {
        return {
          id: this.supplierName + item,
          displayText: item,
        };
      });
    },
    rowClass() {
      if (this.PASS) return "green lighten-2";
      if (this.ERROR) return "red lighten-4";
      return "";
    },
    PASS() {
      return this.promise === 1;
    },
    ERROR() {
      return this.promise === -1;
    },
  },
};
</script>
