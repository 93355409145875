<template>
  <div style="height: 300px">
    <DocumentsHeader
      isimg
      :prependMenu="prependMenu"
      :uploadfn="addImage"
      @clear="clearHeader"
      :onsave="saveDefaultImages"
      @toggleDelete="setDeleteMode"
      :confirmDelete="deleteDocuments"
    />
    <!-- <elwrapper label="Width">
    <f-number :value="width" @input="v => $emit('updateWidth', v)" />
  </elwrapper> -->
    <ImageGroup noedit hidetext :showChecks="showChecks" :imageList="imageList" @toggle="selectImage" />
  </div>
</template>

<script>
import { ImageGroup } from "@/modules/media.pictures";
import { DocumentsHeader } from "@/modules/media.documents";
export default {
  props: {
    width: Number,
    editEmailId: String,
    imageList: Array,
  },
  components: {
    DocumentsHeader,
    ImageGroup,
  },
  data() {
    const vm = this;

    return {
      prependMenu: [
        {
          title: "Select Signature",
          showMode: "SAVE",
          onClick: vm.setDefaultMode,
        },
      ],
      showChecks: false,
      mode: "",

      defaultImageChanges: {},
      deleteItems: [],
    };
  },
  methods: {
    clearHeader() {
      this.showChecks = false;
      this.defaultImageChanges = {};
      this.deleteItems = [];
    },

    /***************
     * ADD IMAGE
     * *************/
    addImage(submissionList) {
      return Promise.all(
        submissionList.map((submission) => {
          return this.$store.dispatch("SignatureImageStore/addImage", {
            ref_id: this.editEmailId,
            // description: submission.description,
            file: submission.upload,
          });
        })
      )
        .then((imgBulk) => {
          this.$root.$success("Uploaded image");

          const list = [...this.imageList];
          imgBulk.forEach((img) => {
            list.push(img);
          });
          this.$emit("update", list);
        })
        .catch((err) => this.$root.$error(err));
    },

    // SELECT
    selectImage(selected) {
      if (this.mode === "DELETE") this.selectFileDelete(selected);
      else if (this.mode === "SELECT_DEFAULT") this.selectDefaultImage(selected);
    },

    /***************
     * REMOVE IMAGE
     * *************/
    setDeleteMode() {
      this.showChecks = !this.showChecks;
      this.mode = "DELETE";
    },
    selectFileDelete({ check, value }) {
      // Add to delete list group if checked
      // Remove from list group if not checked
      if (check) {
        this.deleteItems.push(value);
      } else {
        var index = this.deleteItems.findIndex((x) => x.id === value);
        this.deleteItems.splice(index, 1);
      }
    },
    deleteDocuments() {
      return this.$store
        .dispatch("SignatureImageStore/deleteImageList", this.deleteItems)
        .then((v) => {
          this.$root.$success("Deleted images");
          var vm = this;
          const list = this.imageList.filter(function (item) {
            return vm.deleteItems.indexOf(item.id) === -1;
          });
          this.$emit("update", list);
          this.clearHeader();
        })
        .catch((err) => this.$root.$error(err));
    },

    /***************
     * DEFAULT IMAGE
     * *************/
    setDefaultMode() {
      this.mode = "SELECT_DEFAULT";
      this.showChecks = true;
    },
    selectDefaultImage({ check, value }) {
      let list = JSON.parse(JSON.stringify(this.imageList));

      list.forEach((image) => {
        if (check) {
          // Adding check
          image.isdefault = image.id === value;
          this.defaultImageChanges[image.id] = image;
        } else {
          // Removing check
          image.isdefault = false;
          this.defaultImageChanges[image.id] = image;
        }
      });
    },
    saveDefaultImages() {
      return this.$store
        .dispatch("SignatureImageStore/saveDefaultImages", this.defaultImageChanges)
        .then((v) => {
          this.$root.$success("Updated defaults");
          this.$emit("update", Object.values(this.defaultImageChanges));
          this.clearHeader();
        })
        .catch((err) => this.$root.$error(err));
    },
  },
};
</script>
