<template>
  <div>
    <a-card @click.native="dialog = true" class="hover caption">
      <v-row>
        <v-col class="py-0 caption">
          <elwrapper label="Name" midtext> {{ primary.name || "(None)" }} </elwrapper>
        </v-col>
        <v-col class="py-0 caption">
          <elwrapper label="Title" midtext> {{ primary.title }} </elwrapper>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="py-0 caption">
          <elwrapper icon="phone" midtext>
            <PhoneDisplay :value="primary.phone_number" />
          </elwrapper>
        </v-col>
        <v-col class="py-0 caption">
          <elwrapper icon="mail" midtext> {{ email }} </elwrapper>
        </v-col>
      </v-row>
    </a-card>

    <ContactInfoEditDialog v-model="dialog" />
  </div>
</template>

<script>
import ContactInfoEditDialog from "./_ContactInfoEditDialog";
export default {
  components: {
    ContactInfoEditDialog,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    primary() {
      return this.$store.getters["ClientStore/primary_contact"];
    },
    email() {
      if (!this.primary.email) return;
      return this.primary.email.charAt(0).toUpperCase() + this.primary.email.substring(1);
    },
  },
};
</script>
