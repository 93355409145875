<template>
  <v-col class="pa-0" style="border-bottom: 1px solid rgb(200, 200, 200)">
    <ProductClickableCol
      asrooming
      :mode="mode"
      :module="module"
      v-if="!supplierRoom"
      :selectedBsid="selectedBsid"
      :dateList="dateList"
      :highlightedRows="highlightedRows"
      :rawDate="focusDate"
      :supplierId="supplierId"
    >
      <v-row style="height: 28px">
        <!-- SPACER -->
      </v-row>
    </ProductClickableCol>

    <ProductClickableCol
      v-else
      asrooming
      :mode="mode"
      :module="module"
      :selectedBsid="selectedBsid"
      :dateList="dateList"
      :highlightedRows="highlightedRows"
      :supplierId="supplierId"
      :rawDate="focusDate"
      :focusBsid="focusRoomId"
      @set="(v) => $emit('set', v)"
      @mark="(v) => $emit('mark', v)"
    >
      <v-row class="pa-0 black--text">
        <v-col class="pa-0" align-self="center">
          <span>{{ supplierRoom.first_name }} {{ supplierRoom.last_name }}</span>
        </v-col>

        <v-col cols="1">
          <v-btn x-small icon class="mx-1 cyoa-btn" color="grey lighten-1" @click="$emit('onedit', supplierRoom)">
            <v-icon>edit</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="1">
          <v-btn x-small icon class="mx-1 cyoa-btn" color="grey lighten-1" @click="removeRoom">
            <v-icon>clear</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </ProductClickableCol>
  </v-col>
</template>

<script>
import format from "@/services/format";
import ProductClickableCol from "../options/_ProductClickableCol";
export default {
  props: {
    mode: String,
    module: String,
    disabled: Boolean,

    // Copy stuff
    selectedBsid: String,
    dateList: Array,
    highlightedRows: Object,

    // Regular stuff
    focusDate: String,
    focusRoomId: String,
    supplierId: String,
  },
  components: {
    ProductClickableCol,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    daySupplierRoomRef() {
      return this.$store.getters["BookingDayStore/daySupplierRoomRef"];
    },
    supplierRoom() {
      if (!this.daySupplierRoomRef[this.focusDate].includes(this.focusRoomId)) return false;

      const supplier = this.$store.getters["BookingSupplierRoomStore/supplierRoomsRef"][this.focusRoomId];
      return supplier;
    },
  },
  methods: {
    passUpAdded(bsid) {
      // The booking supplier id of what was just added
      this.$emit("addnest", bsid);
    },

    removeRoom(sroomId) {
      this.$store
        .dispatch("BookingDayStore/removeSupplierRoomDate", {
          booking_id: this.$store.getters["BookingStore/booking_id"],
          supplierRoomIdList: [this.focusRoomId],
          dateList: [this.focusDate],
        })
        .then((v) => {
          // Check if any left
          const remainingDays = Object.values(this.daySupplierRoomRef).filter((arr) => {
            return arr.includes(this.focusRoomId);
          });

          if (remainingDays.length) return;
          // Remove Room if no longer added to days
          return this.$store.dispatch("BookingSupplierRoomStore/removeSupplierRoom", this.focusRoomId);
        })
        .then((v) => {
          this.$root.$success("Removed Supplier Room");
          this.$root.$loading.end();
        })
        .catch((err) => this.$root.$error(err));
    },
  },
};
</script>
