<template>
  <v-card flat>
    <v-form @submit.prevent="updateSearch">
      <v-row class="mx-0 flex-nowrap">
        <ClientAutocomplete v-model="client" />
        <a-select
          label="Group Name"
          :items="groupList"
          v-model="group_name"
          @change="(v) => $emit('updateSupplierList', v.name)"
        />

        <a-group icon="event">
          <a-date label="From" v-model="from" />
          <a-date label="To" v-model="to" />
        </a-group>
      </v-row>

      <ShowStatusOptions v-model="status" @input="updateSearch" />

      <div class="d-flex mt-3">
        <g-button label="Clear" @onclick="clearSearch" />
        <v-spacer />
        <search-button />
      </div>
    </v-form>
  </v-card>
</template>

<script>
import { ClientAutocomplete } from "@/modules/clients";
import ShowStatusOptions from "./_ShowStatusOptions";
export default {
  props: {
    groupListBlob: Object,
  },
  components: {
    ClientAutocomplete,
    ShowStatusOptions,
  },
  computed: {
    client: {
      get() {
        return this.get("client") || "";
      },
      set(nuval) {
        this.set("client", nuval);
      },
    },
    group_name: {
      get() {
        return this.get("group_name") || "";
      },
      set(nuval) {
        this.set("group_name", nuval);
      },
    },
    from: {
      get() {
        return this.get("from") || "";
      },
      set(nuval) {
        this.set("from", nuval);
      },
    },
    to: {
      get() {
        return this.get("to") || "";
      },
      set(nuval) {
        this.set("to", nuval);
      },
    },
    status: {
      get() {
        return this.get("status") || "1";
      },
      set(nuval) {
        this.set("status", nuval);
      },
    },
    groupList() {
      return Object.keys(this.groupListBlob[this.status] || {}).sort((a, b) => {
        if (a < b) return 1;
        if (a > b) return -1;
        return 0;
      });
    },
  },
  methods: {
    get(val) {
      return this.$store.getters["BookingEmailStore/generalSearch"][val];
    },
    set(key, nuval) {
      let data = this.$store.getters["BookingEmailStore/generalSearch"];
      Object.assign(data, { [key]: nuval });
      this.$store.dispatch("BookingEmailStore/updateSearch", { tab: "general", data });
    },

    bumpSearch() {
      const searchData = {
        ...(this.client && { client: this.client }),
        ...(this.group_name && { group_name: this.group_name }),
        ...(this.from && { from: this.from }),
        ...(this.to && { to: this.to }),
        ...(this.status !== 1 && { status: this.status }),
      };

      this.$emit("search", searchData);

      return searchData;
    },
    clearSearch() {
      this.client = "";
      this.group_name = "";
      this.from = "";
      this.to = "";
      this.status = 1;
      this.bumpSearch();
      this.$store.dispatch("BookingEmailStore/resetSearch");
    },
    updateSearch() {
      const searchData = this.bumpSearch();
      this.$store.dispatch("BookingEmailStore/applySearch", {
        tab: "general",
        data: searchData,
      });
    },
  },
};
</script>

<style lang="scss"></style>
