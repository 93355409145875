import CountryDependentConfig from "@/config/countryDependentConfig";

export default (supplier_type, country, meta) => {
  const every = {
    // Add default payment types
    payment_type: {
      type: "TEXT",
      value: _getPaymentMeta(supplier_type, country),
    },
  };

  if (supplier_type === "HOTEL") {
    return {
      ...every,
      hotel_class: meta.hotel_class || null,
      tax: _perToString(meta.tax),
      breakfast: _getBreakfast((meta.breakfast || "").toString().toLowerCase(), "included"),
      parking: _getParking(meta),
      comissionable: _toBoolean(meta.commisionable, "yes"),
      porterage: _getPorterage((meta.porterage || "").toString().toLowerCase()),
      foc: _getfoc(meta.foc, "no"),
    };
  }

  if (supplier_type === "RESTAURANT") {
    return {
      ...every,
      style: meta.style || null,
      tax: _perToString(meta.tax),
      inclusive: _toBoolean(meta.inclusive, "yes"),
      foc: _getfoc(meta.foc, "no"),
    };
  }

  if (supplier_type === "EXCURSION") {
    return {
      ...every,
      tax: _perToString(meta.tax),
      inclusive: _toBoolean(meta.inclusive, "yes"),
      foc: _getfoc(meta.foc, "no"),
    };
  }

  return every;
};

// EXTRACT HELPERS -------

function _getRegion(value) {
  if (!value) return null;
  if (value.toLowerCase() === "nationwide") return "NAT";
  return value[0];
}

function _getBreakfast(value, compare) {
  if (!value) return null;
  if (value === "product") return { type: "TEXT", value: "product" };
  // If multiple breakfast types
  if (value.includes(",")) {
    // By default, values will be strings if there is a comma
    // So don't worry about type checking here
    const allvalues = value.split(",");
    let list = [];
    let temp;
    allvalues.forEach((item) => {
      if (item.trim().toLowerCase() === compare) return list.push({ type: "TEXT", value: value.toLowerCase() });
      // Assuming format: Label $NUM (eg: American $15)
      temp = item.split("$");
      list.push({
        value: parseInt(item[1].trim()),
        label: item[0].trim(),
      });
    });

    return {
      type: "CLIST",
      value: list,
      cur: "$",
    };
  }

  // Regular return
  return _getcyoa(value, compare);
}

function _getParking(meta) {
  const FREE = "free";
  const NOT_OFFERED = "not offered";
  const OPTIONAL = "optional";
  const car = (meta.car_parking || "").toString().trim().toLowerCase();
  const bus = (meta.bus_parking || "").toString().trim().toLowerCase();

  if (!car && !bus) return null;
  if (car === FREE && bus === FREE) return { type: "TEXT", value: FREE };
  if ((car === NOT_OFFERED && bus === NOT_OFFERED) || (car === NOT_OFFERED && !bus))
    return { type: "TEXT", value: NOT_OFFERED };

  let temp = {
    per_bus: null,
    per_vehicle: null,
  };
  if (car === OPTIONAL) temp.per_vehicle = __cost();
  if (bus === OPTIONAL) temp.per_bus = __cost();

  return temp;
}

function __cost(country) {
  return {
    cur: CountryDependentConfig[country].money.currency,
    type: "COST",
    value: null,
  };
}

function _getPorterage(value) {
  if (!value) return null;
  return {
    text: value,
    type: "COST_2",
  };
}

function _toBoolean(value, opt) {
  if (!value) return null;
  return value.toLowerCase() === "yes";
}

function _perToString(v) {
  if (!v) return null;
  if (typeof v === "string") return parseInt(v.replace("%", "")) / 100;
  return parseFloat(v * 100);
}

function _getcyoa(value, compare) {
  if (!value) return null;
  if (typeof value === "string" && value.trim().toLowerCase() === compare)
    return { type: "TEXT", value: value.toLowerCase().trim() };
  return {
    type: "COST",
    value: value,
    cur: "$",
  };
}

function _getfoc(value, compare) {
  // This MUST be a string
  if (!value) return null;
  if (value.toLowerCase() === compare) return { type: "TEXT", value: value.toLowerCase() };

  let ref = value.split("/");
  return {
    value: parseInt(ref[0]),
    per: parseInt(ref[1]),
    type: "RATE",
  };
}

function _getPaymentMeta(supplier_type, country) {
  // US
  // 		Hotel/Transport => Credit Cards
  // 		Restaurant/Tour Guide => Check
  // 		Else => Cash
  //	JPN
  // 		Hotel => Credit Card
  // 		Else => Cash
  const paymentTypeMapping = {
    JP: {
      HOTEL: "credit card",
      ELSE: "cash",
    },
    DEFAULT: {
      HOTEL: "credit card",
      TRANSPORT: "credit card",
      RESTAURANT: "check",
      TOUR_GUIDE: "check",
      ELSE: "check",
    },
  };

  let opt = paymentTypeMapping[country];
  if (!opt) {
    opt = paymentTypeMapping.DEFAULT;
  }

  return opt[supplier_type] || opt.ELSE;
}
