<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)" width="500" persistent>
    <v-card>
      <v-card-title>
        <v-icon left color="warning">warning</v-icon>
        <span class="subtitle-2">Are you sure you want to change this?</span>
      </v-card-title>

      <v-card-text>
        <div class="warning pa-2 white--text">{{ displayLabel }}</div>
        <div style="border: 1px solid lightgrey">
          <elwrapper label="Current" widelabel>
            <span>{{ displayValue }}</span>
          </elwrapper>

          <elwrapper v-if="type === 'DATE'" label="New" widelabel>
            <f-date v-model="nuval" :datewall="datewall" />
          </elwrapper>
          <ClientField v-else-if="type === 'CLIENT'" v-model="nuval" widelabel />
          <a-text-field v-else widelabel label="New" v-model="nuval" v-caps />
        </div>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <g-button label="Cancel" @onclick="$emit('input', false)" />
        <g-button type="warning" label="Yes, change" @onclick="eject" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ClientField } from "@/modules/clients";
export default {
  props: {
    value: Boolean, // dialog open/close
    label: String,
    currentValue: [String, Object],
    type: String,

    datewall: [String, Date],
  },
  components: {
    ClientField,
  },
  data() {
    return {
      nuval: "",
    };
  },
  computed: {
    displayLabel() {
      if (this.type === "DATE") return "Date " + this.label;
      return this.label;
    },
    displayValue() {
      if (this.type === "CLIENT" && this.currentValue) return this.currentValue.name || "(Client Deleted)";
      return this.currentValue;
    },
  },
  methods: {
    eject() {
      this.$emit("update", this.nuval);
      this.$emit("input", false);
    },
  },
};
</script>
