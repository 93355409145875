<template>
  <v-alert v-if="!emailLinked" dense type="warning">
    <span
      >Email not linked. You cannot send mail.
      <router-link :to="{ name: 'account' }">Please set you password here.</router-link>
    </span>
  </v-alert>
</template>

<script>
export default {
  computed: {
    emailLinked() {
      return this.$store.getters["AccountStore/emailCreds"];
    },
  },
};
</script>
