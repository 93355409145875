import AirportSign from "./AirportSign";

export default [
  {
    path: "sign",
    name: "booking_sign",
    component: AirportSign,
    meta: {
      toolbar: "booking",
      tabAccess: "BOOKING",
    },
  },
];
