import api from "./api";

const STORE_EMAILS = "STORE_EMAILS";
const STORE_ACCOUNTS = "STORE_ACCOUNTS";

const namespaced = true;

const state = {
  currentSupplier: null,
  emailList: null,

  accountMap: {},
};

const mutations = {
  STORE_EMAILS(state, { supplierId, emails }) {
    state.currentSupplier = supplierId;
    state.emailList = emails;
  },
  STORE_ACCOUNTS(state, data) {
    state.accountMap = data;
  },
};

const actions = {
  getSupplierEmailHistory({ commit }, { supplierId, query }) {
    return api
      .getEmailArchives(supplierId, query)
      .then((res) => {
        commit(STORE_EMAILS, {
          supplierId,
          emails: res.data,
        });
      })
      .catch((err) => {
        commit(STORE_EMAILS, {
          supplierId: 1,
          emails: [],
        });

        if (err) throw err.data;
      });
  },

  storeAccounts({ commit }, data) {
    commit(STORE_ACCOUNTS, data);
  },

  getHistory({ commit }, supplier_id) {
    return api
      .getHistory(supplier_id)
      .then((res) => {
        return res.data.map((item) => {
          item.type = "NOTE";
          item.date = new Date(item.timestamp).getTime();
          return item;
        });
      })
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  addNote({ commit }, { supplier_id, note }) {
    return api
      .addNote(supplier_id, note)
      .then((res) => res.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  getNote({ commit }, note_id) {
    return api
      .getNote(note_id)
      .then((res) => res.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  updateNote({ commit }, { note_id, note }) {
    return api
      .updateNote(note_id, note)
      .then((res) => res.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  deleteNote({ commit }, note_id) {
    return api
      .deleteNote(note_id)
      .then((res) => res.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
};

const getters = {
  account_ref: (state) => state.accountMap || {},
  savedSupplierId: (state) => state.currentSupplier,
  emailList: (state) => state.emailList || [],
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
