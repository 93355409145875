<template>
  <a-card nopad class="indigo lighten-3">
    <v-row justify="space-between" class="booking-title grey darken-1 white--text">
      <router-link :to="link" class="daylink">
        <span class="caption">{{ dayTitle.day }}:</span>
        <span class="mx-2 subtitle-2">{{ dayTitle.date }}</span>
      </router-link>
    </v-row>
    <DayInfoBlockSection
      v-for="(config, index) in dayConfig"
      :key="index"
      :dayNum="dayNum"
      :config="config"
      :date="dayData.date"
      :dayData="dayData[config.dbkey]"
      :showCancelled="showCancelled"
      @altstatus="(v) => $emit('altstatus', v)"
      @editRoomName="(v) => $emit('editRoomName', v)"
      @editConfirm="(v) => $emit('editConfirm', v)"
    />
  </a-card>
</template>

<script>
import DayInfoBlockSection from "./_DayInfoBlockSection";
import SUPMENU from "@/config/menus/suppliersMenu";

export default {
  props: {
    dayNum: Number,
    dayCount: Number,
    showCancelled: Boolean,
  },
  components: {
    DayInfoBlockSection,
  },
  data() {
    const vm = this;
    return {
      dayConfig: SUPMENU.FULL.map((item) => {
        return {
          type: item.module,
          title: item.title,
          dbkey: item.alt,
          icon: item.icon,
        };
      }).filter((item) => {
        if (vm.dayNum === vm.dayCount - 1) {
          return !["ALL", "HOTEL"].includes(item.type);
        }
        return item.type !== "ALL";
      }),
    };
  },
  computed: {
    dayTitle() {
      return this.$store.getters["BookingDayStore/day_name"](this.dayNum);
    },
    dayData() {
      return this.$store.getters["BookingDayStore/day"](this.dayNum);
    },
    link() {
      return {
        name: "booking_day",
        params: {
          booking_id: this.$store.getters["BookingStore/booking_id"],
          day: this.dayNum + 1,
        },
      };
    },

    hiddenCount() {
      let supidlist = [];
      let temp = this.$store.getters["BookingDayStore/suppliers"];

      SUPMENU.FULL.forEach((v) => {
        if (v.module === "ALL") return;
        supidlist.push(...this.dayData[v.alt]);
      });

      return supidlist.filter((v) => {
        return temp[v].status === -1;
      }).length;
    },
  },
};
</script>

<style lang="scss">
.daylink {
  text-decoration: none;

  span {
    color: white;
  }

  &:hover {
    opacity: 0.6;
  }
}
</style>
