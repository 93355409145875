export default {
  hotel_class: {
    cols: 4,
    label: "Class",
    inputType: "DROPDOWN",
    hideClear: true,
    source: (function () {
      var v = [];
      for (var i = 1; i <= 5; i++) {
        v.push({
          label: `(${i}) ${"*".repeat(i)}`,
          value: i,
        });

        if (i < 5) {
          v.push({
            label: `(${i + 0.5}) ${"*".repeat(i)}`,
            value: i + 0.5,
          });
        }
      }
      return v;
    })(),
  },
  breakfast: {
    label: "Breakfast",
    inputType: "CYOA",
    cols: 8,
    config: {
      included: {
        editText: true,
        editType: "DROPDOWN",
        getOptionListSource: "BREAKFAST",
      },
    },
    choosePath: {
      included: "TEXT",
      product: "TEXT",
    },
  },

  tax: {
    cols: 4,
    label: "Tax",
    inputType: "PERCENT",
    searchType: "RANGE",
  },
  occupancy_rate: {
    cols: 4,
    label: "Occupancy",
    inputType: "COST",
  },
  resort_fee: {
    cols: 4,
    label: "Resort Fee",
    inputType: "COST",
  },
  parking: {
    cols: 4,
    label: "Parking",
    inputType: "CYOA",
    choosePath: {
      free: "TEXT",
      $: "COST",
      "not offered": "TEXT",
    },
  },

  commission: {
    cols: 4,
    label: "Commission",
    inputType: "CYOA",
    choosePath: {
      yes: "PERCENT",
      no: "TEXT",
    },
  },
  porterage: {
    cols: 4,
    label: "Porterage",
    inputType: "CYOA",
    choosePath: {
      mandatory: "COST_2",
      optional: "COST_2",
      "not offered": "TEXT",
    },
  },
  cancellation: {
    cols: 4,
    label: "Cancellation",
    sideLabel: " Days",
    inputType: "NUMBER",
  },

  foc: {
    cols: 4,
    label: "FOC",
    inputType: "CYOA",
    choosePath: {
      yes: "RATE",
      no: "TEXT",
    },
  },
  payment_type: {
    cols: 4,
    label: "Payment",
    inputType: "CYOA",
    choosePath: {
      wire: "TEXT",
      "credit card": "TEXT",
      cash: "TEXT",
      check: "TEXT",
    },
  },
};
