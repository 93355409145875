<template>
  <v-dialog :value="value" persistent max-width="400">
    <v-card>
      <v-card-title class="d-flex flex-column align-start">
        <div class="subtitle-2">{{ title }} Reminder</div>
        <div v-if="mode === 'COPY'" class="caption">{{ copyTitle }}</div>
      </v-card-title>

      <v-card-text>
        <a-select
          label="Supplier"
          :items="bookingSuppliers"
          :disabled="disableSupplierChange"
          :value="displaySupplierId"
          @input="(v) => (supplierId = v)"
        />
        <elwrapper label="Date">
          <f-date :value="displayDate" @input="(v) => (date = v)" :datewall="today" />
        </elwrapper>
        <elwrapper label="Note">
          <f-textarea :value="displayNote" @input="(v) => (note = v)" />
        </elwrapper>
      </v-card-text>

      <v-card-actions>
        <g-button label="Done" @onclick="$emit('input', false)" />
        <div class="flex-grow-1"></div>
        <g-button :label="title" type="primary" :loading="loading" @onclick="saveReminder" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import format from "@/services/format";
const ADD = "ADD";
const EDIT = "EDIT";
const COPY = "COPY";
export default {
  props: {
    value: Boolean,
    mode: String,
    focusDate: String,
    sourceReminder: Object,
  },
  data() {
    return {
      supplierId: null,
      date: "",
      note: "",
      loading: false,

      today: format.convertDate(new Date()),
    };
  },
  computed: {
    // CONFIG
    title() {
      const o = {
        EDIT: "Edit",
        ADD: "Add",
        COPY: "Copy",
      };
      return o[this.mode];
    },
    bookingId() {
      return this.$store.getters["BookingStore/booking_id"];
    },
    bookingSuppliers() {
      const bsBlob = this.$store.getters["BookingDayStore/suppliers"];
      const display = {};
      Object.values(bsBlob).forEach((item) => {
        display[item.supplier_id] = {
          value: item.supplier_id,
          label: `${item.name} ${item.parent_bsid ? "(ALT)" : ""}`,
        };
      });

      return [
        {
          value: "GENERAL",
          label: "None (General Reminder)",
        },
        ...Object.values(display),
      ];
    },
    disableSupplierChange() {
      return this.mode === EDIT;
    },
    copyTitle() {
      if (!this.sourceReminder) return null;
      const name = this.getSupplierName(this.sourceReminder.supplier_id);
      const date = this.sourceReminder.date;
      return `${name} (${format.formatDate(date)})`;
    },

    // FORM ITEMS
    displaySupplierId() {
      if (this.mode === ADD) return this.supplierId;
      return this.supplierId || this.sourceReminder.supplier_id;
    },
    displayDate() {
      if (this.mode === ADD) this.date || this.focusDate;
      return this.date || this.sourceReminder.date || this.focusDate;
    },
    displayNote() {
      if (this.mode == ADD) this.note;
      return this.note || this.sourceReminder.note;
    },
  },
  methods: {
    getSupplierName(id) {
      return this.bookingSuppliers.find((item) => id === item.value).label;
    },

    saveReminder() {
      const o = {
        ADD: this._addReminder,
        EDIT: this._editReminder,
        COPY: this._addReminder,
      };

      this.loading = true;
      o[this.mode]()
        .then((_) => {
          this.$emit("input", false);
          this.loading = false;
          this.supplierId = null;
          this.date = "";
          this.note = "";
        })
        .catch((err) => this.$root.$error(err));
    },
    _addReminder() {
      const name = this.getSupplierName(this.displaySupplierId);
      return this.$store
        .dispatch("ReminderStore/addReminder", {
          bookingId: this.bookingId,
          data: {
            supplier_id: this.displaySupplierId,
            date: this.displayDate,
            created_by: this.$store.getters["AccountStore/id"],
            note: this.displayNote,
          },
        })
        .then((data) => {
          this.$emit("added", { ...data, name });
          this.$root.$success("Added reminder for: " + name);
        });
    },
    _editReminder() {
      return this.$store
        .dispatch("ReminderStore/updateReminder", {
          reminder_id: this.sourceReminder.id,
          data: {
            supplier_id: this.displaySupplierId,
            date: this.displayDate,
            note: this.displayNote,
          },
          cache: this.sourceReminder,
        })
        .then((data) => {
          this.$emit("update", {
            old: this.sourceReminder,
            nu: data,
          });
        });
    },
  },
};
</script>
