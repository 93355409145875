import axios from "@/plugins/axios";

export default {
  login(creds) {
    return axios.post("/login", creds);
  },

  forgotPassword(email) {
    return axios.post("/forgot_password", email);
  },

  logout() {
    return axios.post("/logout");
  },
};
