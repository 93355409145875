<template>
  <div>
    <elwrapper label="Client" midtext
      ><v-autocomplete
        dense
        outlined
        hide-details
        item-value="id"
        item-text="name"
        :items="rows"
        :value="value"
        @input="this.handleInput"
      ></v-autocomplete
    ></elwrapper>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rows: [],
    };
  },
  props: {
    value: [Object, String],
  },
  methods: {
    handleInput(value) {
      const row = this.rows.find((it) => it.id === value);
      this.$emit("input", row);
    },
    queryDatabase() {
      this.$store
        .dispatch("ClientStore/getClientList", {})
        .then((rows) => {
          this.rows = rows;
        })
        .catch((err) => {});
    },
  },
  mounted() {
    this.queryDatabase();
  },
};
</script>
