<template>
  <v-btn depressed small color="primary" :disabled="disabled" :block="block" :loading="loading" @click="save">
    <v-icon small left v-once>{{ icon || "save" }}</v-icon>
    <span v-once>{{ label || "Save" }}</span>
  </v-btn>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    block: Boolean,
    icon: String,
    label: String,
    onsave: Function, // Must return a promise
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    save() {
      if (this.disabled) return;

      this.loading = true;
      this.onsave()
        .then((v) => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          throw err;
        });
    },
    keysave(event) {
      // CLICK Ctrl+S
      if (!(event.which == 115 && event.ctrlKey) && !(event.which == 19)) return true;
      event.preventDefault();
      this.save();
      return false;
    },
  },
  mounted() {
    window.addEventListener("keypress", this.keysave);
  },
  beforeDestroy() {
    window.removeEventListener("keypress", this.keysave);
  },
};
</script>
