<template>
  <div>
    <v-row class="mx-2" justify="space-between">
      <span class="subtitle-2">CC</span>
      <span class="caption"> Total selected: {{ Object.keys(value.admins).length }} </span>
    </v-row>

    <div v-if="!adminList.length">
      <span class="caption px-3">No Admin</span>
    </div>

    <v-data-table
      v-else
      disable-pagination
      hide-default-footer
      fixed-header
      :headers="headers"
      :items="adminList"
      :loading="loading"
      loading-text="Fetching admins"
      height="500px"
      show-select
      single-select
      class="database-table a-card nested-rows"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr
            class="database-row"
            :class="{ 'primary--text grey lighten-2': adminSelectAll }"
            @click="toggleAdminSelectAll"
          >
            <td>
              <input type="checkbox" :checked="adminSelectAll" />
            </td>
            <td>SELECT ALL</td>
          </tr>
          <tr
            v-for="item in items"
            :key="item.id"
            class="database-row color-alt canclick"
            :class="{ 'primary--text blue lighten-4': hasAdminSelected(item) }"
            @click="(e) => onclickAdmin(e, item)"
          >
            <td>
              <input type="checkbox" :checked="value.admins[item.id]" />
            </td>
            <td>
              <div>
                <span>{{ item.name }}</span>
                <span class="mx-2">({{ item.email }})</span>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  props: {
    value: Object,
    adminList: Array,
  },
  data() {
    return {
      adminSelectAll: false,
      loading: false,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Name",
          value: "name",
        },
      ];
    },
  },
  methods: {
    toggleAdminSelectAll() {
      const updatedValue = { ...this.value };
      if (this.adminSelectAll) {
        updatedValue.admins = {};
      } else {
        updatedValue.admins = Object.fromEntries(this.adminList.map((admin) => [admin.id, admin]));
      }
      this.$emit("input", updatedValue);
      this.adminSelectAll = !this.adminSelectAll;
    },
    hasAdminSelected(item) {
      return Object.keys(this.value.admins).includes(item.id);
    },
    onclickAdmin(e, item) {
      if (this.value.admins[item.id]) {
        Vue.delete(this.value.admins, item.id);
        this.adminSelectAll = false;
      } else {
        Vue.set(this.value.admins, item.id, item);
        this.adminSelectAll = Object.keys(this.value.admins).length == this.adminList.length;
      }
    },
  },
};
</script>
