<template>
  <v-col>
    <v-row justify="space-between" class="ma-3">
      <delete-button :ondelete="deleteNote" />

      <div>
        <g-button label="Return" @onclick="$router.push({ name: 'suppliers_history' })" />
        <save-button :onsave="updateNote" />
      </div>
    </v-row>

    <a-card>
      <elwrapper label="Created By">{{ noteInfo.created_by }}</elwrapper>

      <elwrapper label="Note" texttop>
        <RichTextEditor maxheight="300px" v-model="noteInfo.content" />
      </elwrapper>
    </a-card>
  </v-col>
</template>

<script>
export default {
  data() {
    return {
      noteInfo: {},
    };
  },
  computed: {
    noteId() {
      return this.$route.params.note_id;
    },
  },
  methods: {
    deleteNote() {
      return this.$store
        .dispatch("SupplierHistoryStore/deleteNote", this.noteId)
        .then((data) => {
          this.$router.push({ name: "suppliers_history" });
          this.$root.$success("Deleted Note");
        })
        .catch((err) => this.$root.$error(err));
    },
    updateNote() {
      return this.$store
        .dispatch("SupplierHistoryStore/updateNote", {
          note_id: this.noteId,
          note: {
            content: this.noteInfo.content,
            supplier_id: this.noteInfo.supplier_id,
          },
        })
        .then((v) => {
          this.$root.$success("Note updated");
          this.$router.push({ name: "suppliers_history" });
        })
        .catch((err) => this.$root.$error(err));
    },
  },
  mounted() {
    const noteId = this.$route.params.note_id;
    this.$store
      .dispatch("SupplierHistoryStore/getNote", noteId)
      .then((data) => {
        this.noteInfo = data;
      })
      .catch((err) => this.$root.$error(err));
  },
};
</script>
