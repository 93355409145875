<template>
  <div class="d-flex flex-row pl-5 total-show">
    <div class="extra">
      <div class="subtitle-2 mx-1">
        <div class="d-flex align-center" v-if="showTax">
          <span class="mx-2">+</span>
          <elwrapper label="Tax" class="ring" smlabel midtext>
            <div>{{ tax }}%</div>
          </elwrapper>
        </div>

        <div class="d-flex align-center" v-if="showCustom">
          <span class="mx-2">+</span>
          <elwrapper label="Custom Tax" class="ring" midtext>
            <f-percent v-model="custom_tax" />
          </elwrapper>
        </div>

        <div class="d-flex align-center">
          <input
            class="mx-2"
            type="checkbox"
            :checked="tax_inclusive"
            @change="toggleTaxInclusive"
            :disabled="!editable"
          />
          <span class="caption">Tax Inclusive</span>
        </div>
        <div class="d-flex align-center">
          <input class="mx-2" type="checkbox" :checked="showCustom" @change="toggleCustomTax" :disabled="!editable" />
          <span class="caption">Custom Tax</span>
        </div>

        <g-button xsmall label="Extra" icon="add" @onclick="extraDialog = true" :disabled="!editable" />
      </div>

      <div class="subtitle-2 mx-1" v-if="anyExtra">
        <div class="d-flex align-center" v-if="showOccupancy && !customOccupancyIsVisible">
          <span class="mx-2">+</span>
          <elwrapper label="Occupancy" class="ring" midtext>
            {{ tempFormat(occupancy.cur) }}{{ occupancy.value }}
          </elwrapper>
        </div>

        <div class="d-flex align-center" v-if="showResortFee">
          <span class="mx-2">+</span>
          <elwrapper label="Resort Fee" class="ring" midtext>
            {{ tempFormat(resort_fee.cur) }}{{ resort_fee.value }}
          </elwrapper>
        </div>

        <!-- Custom Occupancy in Japanese Environment -->
        <CustomOverWrite
          label="Occupancy"
          :country="country"
          :show="showCustomOccupancy"
          v-model="custom_occupancy"
          @toggleOff="tax_inclusive = false"
        />

        <div class="d-flex align-center" v-for="(extra, index) in extraList" :key="index">
          <span class="mx-2">+</span>
          <elwrapper :label="extraRef[extra.key].label" class="ring" midtext>
            <div v-if="extra.astype === 'COST'">
              {{ extra.value && tempFormat(extra.value.cur) }} {{ extra.value && extra.value.value }}
            </div>
            <div v-if="extra.astype === 'PERCENT'">{{ extra.value }} %</div>
          </elwrapper>
        </div>
      </div>
    </div>

    <div class="subtitle-2 mx-1">
      <div class="d-flex align-center">
        <TotalBlock
          :isSeasonal="!!isSeasonal"
          :rateInfo="rateInfo"
          :country="country"
          :tax="custom_tax || tax"
          :taxInclusive="tax_inclusive"
          :occupancy="totalAppliedOccupancy"
          :resortFee="totalAppliedResortFee"
          :extraList="extraList"
        />
      </div>
      <ConfirmRate :customYear="customYear" v-model="confirmedRate" />
    </div>

    <ExtraDialog v-model="extraDialog" :country="country" :productMeta="productMeta" :customYear="customYear" />
  </div>
</template>

<script>
import CURRENCY_SYMBOLS from "@/config/CURRENCY_SYMBOLS";
import EXTRA_OPTIONS from "@/config/products/EXTRA_OPTIONS";
import TotalBlock from "./TotalBlock";
import ExtraDialog from "./extraDialog/ExtraDialog";
import ConfirmRate from "./confirmRate/ConfirmRate";
import CustomOverWrite from "./customOverwrite/CustomOverWrite";
export default {
  props: {
    customYear: String,
  },
  components: {
    TotalBlock,
    ExtraDialog,
    ConfirmRate,
    CustomOverWrite,
  },
  data() {
    return {
      extraDialog: false,
      extraRef: EXTRA_OPTIONS,
    };
  },
  computed: {
    editable() {
      const currentYear = new Date().getFullYear();
      if (`${currentYear}` === this.customYear) return true;
      if (`${currentYear + 1}` === this.customYear) return true;
      return false;
    },

    isSeasonal() {
      return this.$store.getters["ProductStore/seasonal"](this.customYear);
    },
    country() {
      return this.$store.getters["AccountStore/environment"];
    },
    rateInfo() {
      return this.$store.getters["ProductStore/rate"](this.customYear) || {};
    },

    confirmedRate: {
      get() {
        return this.$store.getters["ProductStore/confirmed"](this.customYear);
      },
      set(v) {
        this.setByRate("confirmed", v);
      },
    },

    showTax() {
      return this.tax && !this.tax_inclusive && !this.custom_tax;
    },
    tax() {
      return this.$store.getters["ProductStore/supplier_meta"].tax;
    },
    productMeta() {
      return this.$store.getters["ProductStore/meta"](this.customYear);
    },
    custom_tax: {
      get() {
        return this.$store.getters["ProductStore/custom_tax"](this.customYear);
      },
      set(val) {
        this.setMeta("custom_tax", val);
      },
    },
    custom_occupancy: {
      get() {
        const meta = this.$store.getters["ProductStore/meta"](this.customYear);
        return meta ? meta.custom_occupancy : false;
      },
      set(val) {
        this.setMeta("custom_occupancy", val);
      },
    },
    showCustom() {
      // Hide with FALSE BOOLEAN
      // Null/empty string must still show
      return this.custom_tax !== false;
    },
    customOccupancyIsVisible() {
      if (!this.showCustomOccupancy) return false;
      return this.custom_occupancy !== false;
    },
    tax_inclusive: {
      get() {
        return this.$store.getters["ProductStore/tax_inclusive"](this.customYear);
      },
      set(val) {
        this.setByRate("tax_inclusive", val);
      },
    },
    occupancy() {
      var t = this.$store.getters["ProductStore/supplier_meta"].occupancy_rate;
      return t ? t : {};
    },
    resort_fee() {
      var t = this.$store.getters["ProductStore/supplier_meta"].resort_fee;
      return t ? t : {};
    },
    anyExtra() {
      if (this.showOccupancy || this.showCustomOccupancy) return true;
      if (this.extraList.length) return true;
      return false;
    },

    extraList() {
      const meta = this.$store.getters["ProductStore/meta"](this.customYear);
      return Object.keys(meta)
        .filter((key) => key.includes("extra_"))
        .map((key) => {
          return {
            key,
            ...meta[key],
          };
        });
    },

    showOccupancy() {
      // Occupancy only applied to rooms
      const productType = this.$store.getters["ProductStore/type"];
      if (productType !== "ROOM") return false;

      return this.occupancy.value && !this.tax_inclusive;
    },
    showCustomOccupancy() {
      const env = this.$store.getters["AccountStore/environment"];
      if (env !== "JPN") return false;
      const productType = this.$store.getters["ProductStore/type"];
      if (productType !== "ROOM") return false;

      return true;
    },
    showResortFee() {
      const productType = this.$store.getters["ProductStore/type"];
      if (productType !== "ROOM") return false;

      return this.resort_fee.value && !this.tax_inclusive;
    },

    totalAppliedOccupancy() {
      // Must either have occupancy or custom
      if (!(this.showOccupancy || this.customOccupancyIsVisible)) return null;

      // Japanese Environment has custom occupancy option
      // Switch here
      if (this.$store.getters["AccountStore/environment"] === "JPN") {
        if (this.customOccupancyIsVisible) return this.custom_occupancy;
        return this.custom_occupancy || this.occupancy;
      }

      return this.occupancy;
    },
    totalAppliedResortFee() {
      // Must either have occupancy or custom
      if (!this.showResortFee) return null;
      return this.resort_fee;
    },
  },
  methods: {
    tempFormat(cur) {
      return CURRENCY_SYMBOLS[cur] ? "" : cur;
    },
    set(key, val) {
      return this.$store.dispatch("ProductStore/setUpdate", { key, val });
    },
    setByRate(key, val) {
      return this.$store.dispatch("ProductStore/setUpdateRate", { key, val, year: this.customYear });
    },
    setMeta(key, value) {
      return this.$store.dispatch("ProductStore/setUpdateMeta", { key, value, year: this.customYear });
    },
    toggleTaxInclusive() {
      if (!this.tax_inclusive) {
        this.tax_inclusive = 1;
        this.custom_tax = false;
        this.custom_occupancy = false;
        return;
      }
      this.tax_inclusive = 0;
    },
    toggleCustomTax() {
      if (!this.custom_tax) {
        this.custom_tax = "0";
        this.tax_inclusive = false;
        return;
      }
      this.custom_tax = false;
    },
  },
};
</script>

<style lang="scss">
.total-show {
  flex: 1;

  .extra {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-end;
  }
}
</style>
