<template>
  <SupplierRowBlock
    :bookingId="bookingId"
    :supplierType="supplierType"
    :supplier="supplier"
    :supplierInvoice="supplierInvoice"
    :supplierDelta="supplierDelta"
    :extraExpenses="extraExpenses"
    :refundExpenses="refundExpenses"
    :depositExpenses="depositExpenses"
    :commissionReceivedHistory="commissionReceivedSupplierHistory"
    :customCommissionList="customCommissionList"
  />
</template>

<script>
import SupplierRowBlock from "./SupplierRowBlock";
import format from "@/services/format";

export default {
  props: {
    supplier: Object,
    supplierType: String,
  },
  components: {
    SupplierRowBlock,
  },
  computed: {
    bookingId() {
      return this.$store.getters["FinanceReportStore/booking_id"];
    },
    supplierInvoice() {
      return this.$store.getters["FinanceCompileStore/supplier_invoices"][
        this.supplier.supplier_id + this.supplier.type_as
      ];
    },

    supplierDelta() {
      const custom = this.$store.getters["FinanceCompileStore/supplierDelta"](
        this.supplier.supplier_id,
        this.supplier.type_as
      );
      if (custom === null || custom === undefined) return { diff: null, positive: true };
      return {
        diff: custom,
        positive: custom >= 0,
      };
    },

    extraExpenses() {
      return this.$store.getters["FinanceCompileStore/extra_expenses"]
        .filter((v) => v.supplier_id === this.supplier.supplier_id && v.expense_type === "DEFAULT")
        .filter((v) => v.type_as === this.supplierType);
    },
    refundExpenses() {
      return this.$store.getters["FinanceCompileStore/extra_expenses"]
        .filter((v) => v.supplier_id === this.supplier.supplier_id && v.expense_type === "REFUND")
        .filter((v) => v.type_as === this.supplierType);
    },
    depositExpenses() {
      return this.$store.getters["FinanceCompileStore/extra_expenses"]
        .filter((v) => v.supplier_id === this.supplier.supplier_id && v.expense_type === "DEPOSIT")
        .filter((v) => v.type_as === this.supplierType)
        .sort((a, b) => {
          if (a.datestamp > b.datestamp) return 1;
          if (a.datestamp < b.datestamp) return -1;
          return 0;
        })
        .map((v) => {
          return {
            ...v,
            label: `${v.label} - ${format.formatDate(v.datestamp, true)}`,
          };
        });
    },

    // COMMISSION
    commissionReceivedSupplierHistory() {
      return this.$store.getters["FinanceReportStore/commissionReceivedSupplierHistory"](this.supplier.supplier_id);
    },
    customCommissionList() {
      return this.$store.getters["FinanceCompileStore/custom_commissions"];
    },
  },
};
</script>
