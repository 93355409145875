<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)" persistent fullscreen>
    <v-card class="supplier-select-wrapper">
      <v-card-title>
        <DialogHeader float :type="module" :name="supplier.name" :supplierId="supplier.id" />
      </v-card-title>

      <v-card-text class="dialog-content">
        <SupplierSelectView v-show="step === 1" :module="module" @update="selectSupplier" @close="close" />

        <ProductSelectWrapper
          v-if="step === 2"
          lockDate
          replace
          :mode="mode"
          :module="module"
          :originSupplierId="supplierToReplace.supplier_id"
          :originSupplier="supplierToReplace"
          :supplier="supplier"
          :productFilter="productFilter"
          :dateList="dateList"
          @update="selectProducts"
          @revert="step = 1"
          @close="close"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogHeader from "./_DialogHeader";
import { BookingDayService } from "@/modules/bookings.days";

import SupplierSelectView from "./step1_suppliers/SupplierSelectView";
import ProductSelectWrapper from "./step2_products/ProductSelectWrapper";
export default {
  props: {
    value: Boolean,
    mode: String,
    module: String,

    supplierToReplace: Object,
  },
  components: {
    DialogHeader,
    SupplierSelectView,
    ProductSelectWrapper,
  },
  data() {
    return {
      step: 0,
      supplier: {},
      productFilter: {},
      dateList: [],
      dateToBsidMapping: {},
    };
  },
  watch: {
    toggleReset(v) {
      this.init();
    },
  },
  computed: {
    toggleReset() {
      return `${this.value}|${this.module}`;
    },
  },
  methods: {
    // ----------------
    // INIT
    // ----------------
    init() {
      Object.assign(this, {
        step: 1,
        supplier: {},
        productFilter: {},
        dateList: [],
        dateToBsidMapping: {},
      });

      // Get dates
      this.dateList = this.$store.getters["BookingDayStore/whatDaysBySupplierId"](
        this.module,
        this.supplierToReplace.supplier_id
      );

      // Set Product for extra stuff
      const days = this.$store.getters["BookingDayStore/days"];
      const suppliers = this.$store.getters["BookingDayStore/suppliers"];
      this.dateToBsidMapping = BookingDayService.whatProductMapping(
        this.module,
        days,
        suppliers,
        this.supplierToReplace.id
      );
    },

    selectSupplier(data) {
      this.supplier = data.supplier;
      this.productFilter = data.productFilter;
      this.step = 2;

      // Datelist if FIXED
    },
    selectProducts() {
      this.$emit("input", false);
    },
    close() {
      this.$emit("input", false);
    },
  },
};
</script>
