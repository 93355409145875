<template>
  <a-card class="px-5">
    <v-row>
      <v-col class="py-0">
        <a-select
          label="Environment*"
          :items="environments"
          :value="value.env"
          @input="(v) => update(v, 'env', ['country', 'subjur', 'city', 'subcity'])"
        />
      </v-col>
      <v-col class="py-0">
        <a-select
          label="Database*"
          :items="databaseList"
          :value="value.db"
          @input="(v) => update(v, 'db', ['databaseType', 'field', 'value'])"
        />
      </v-col>
      <v-col class="py-0">
        <a-select
          v-if="value.db === 'suppliers'"
          label="Type*"
          :items="supplierTypeOptions"
          :value="value.databaseType"
          @input="(v) => update(v, 'databaseType', ['field', 'value'])"
        />
        <a-select
          v-else-if="value.db === 'products'"
          label="Type*"
          :items="productTypeOptions"
          :value="value.databaseType"
          @input="(v) => update(v, 'databaseType', ['field', 'value'])"
        />
        <v-row v-else>
          <elwrapper label="Type*" midtext widelabel>
            <span class="caption">(Select Database)</span>
          </elwrapper>
        </v-row>
      </v-col>
      <v-col><!-- SPACER --></v-col>
    </v-row>

    <v-row v-if="!value.env">
      <v-col>
        <elwrapper label="Location" midtext widelabel>
          <span class="caption">(Select Environment)</span>
        </elwrapper>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col class="py-0">
        <a-field
          :env="value.env"
          :src="value.databaseType"
          :config="SupplierFields.country"
          :value="value.country"
          @input="(v) => update(v, 'country')"
        />
      </v-col>
      <v-col>
        <a-field
          :config="SupplierFields.subjur"
          :country="value.country"
          :dependantValue="value.country"
          :value="value.subjur"
          @input="(v) => update(v, 'subjur')"
        />
      </v-col>
      <v-col class="py-0">
        <a-select label="City" :items="cityList" :value="value.city" @input="(v) => update(v, 'city')" />
      </v-col>
      <v-col class="py-0">
        <a-select label="Subcity" :items="subcityList" :value="value.subcity" @input="(v) => update(v, 'subcity')" />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <a-select
          v-if="value.databaseType"
          label="Field*"
          :items="availableMetaOptions"
          :value="value.field"
          @input="(v) => update(v, 'field', ['value'])"
        />
        <v-row v-else>
          <elwrapper label="Field*" midtext widelabel>
            <span class="caption">(Select Type)</span>
          </elwrapper>
        </v-row>
      </v-col>
      <v-col class="pa-0 ring blue lighten-4">
        <a-field
          v-if="value.field && value.db === 'suppliers'"
          clearable
          :config="selectedMeta"
          :country="value.country"
          :value="value.value"
          @input="(v) => update(v, 'value')"
          class="ml-1"
        />
        <ExtraDialogRow
          v-else-if="value.field && value.db === 'products'"
          :country="value.country"
          :config="selectedMeta"
          :data="value.value"
          @update="(v) => updateProduct(v)"
        />
        <div v-else class="caption">(Select Field)</div>
      </v-col>
    </v-row>
  </a-card>
</template>

<script>
import ENV from "@/config/ENV";
import PLACES from "@/config/fields/PLACES";
import EXTRA_OPTIONS from "@/config/products/EXTRA_OPTIONS";
import { SuppliersMenu } from "@/config/menus";
import { ProductMenu } from "@/config/menus";
import { SupplierFields } from "@/config/fields/suppliers";
import { formConfig } from "@/modules/suppliers.information";
import { DatabaseApi } from "@/modules/database";

import { ExtraDialogRow } from "@/modules/products";
export default {
  props: {
    value: Object,
  },
  components: {
    ExtraDialogRow,
  },
  data() {
    return {
      databaseList: [
        {
          label: "Suppliers",
          value: "suppliers",
        },
        {
          label: "Products",
          value: "products",
        },
      ],
      environments: ENV,
      SupplierFields,

      cityList: [],
      subcityList: [],
    };
  },
  computed: {
    supplierTypeOptions() {
      var list = [];
      SuppliersMenu.FULL.forEach((item) => {
        if (item.module === "ALL") return;

        list.push({
          label: item.title,
          value: item.module,
        });
      });
      return list;
    },
    productTypeOptions() {
      let list = [];
      let vm = this;

      ProductMenu.forEach((item) => {
        list.push({
          label: item.title,
          value: item.module,
        });
      });

      return list;
    },
    availableMeta() {
      if (this.value.db === "suppliers") return (formConfig[this.value.databaseType] || {}).meta || {};
      if (this.value.db === "products") return EXTRA_OPTIONS;

      return {};
    },
    availableMetaOptions() {
      return Object.keys(this.availableMeta).map((key) => {
        return {
          label: this.availableMeta[key].label,
          value: key,
        };
      });
    },
    selectedMeta() {
      return this.availableMeta[this.value.field];
    },
  },
  methods: {
    update(value, key, resetValues) {
      let nuSelect = { ...this.value };
      nuSelect[key] = value;

      (resetValues || []).forEach((v) => {
        nuSelect[v] = null;

        // Product requires specific preset vaule
        if (this.value.db === "products" && key === "field") {
          nuSelect[v] = {
            astype: "COST",
            value: null,
          };
        }
      });

      // Sync City OPtions
      if (key === "env") {
        this.getCityList(value);
      }

      this.$emit("input", nuSelect);
    },
    updateProduct(data) {
      let nuSelect = { ...this.value };
      nuSelect.value[data.key] = data.value;
      this.$emit("input", nuSelect);
    },
    getCityList(env) {
      if (!env) return;

      Promise.all([DatabaseApi.getSupplierItemList(env, "city"), DatabaseApi.getSupplierItemList(env, "subcity")])
        .then((data) => {
          this.cityList = data[0].data.map((item) => {
            return { value: item, label: item };
          });
          this.subcityList = data[1].data.map((item) => {
            return { value: item, label: item };
          });
        })
        .catch((err) => this.$root.$error(err));
    },
  },
};
</script>
