<template>
  <div>
    <QuoteHeader :quoteId="quoteId" />
    <v-container fluid>
      <router-view></router-view>
    </v-container>
  </div>
</template>

<script>
import { QuoteHeader } from "@/modules/quotes";
export default {
  components: {
    QuoteHeader,
  },
  computed: {
    quoteId() {
      return this.$store.getters["QuoteStore/quote_id"];
    },
  },
  mounted() {
    var quoteId = this.$route.params.quote_id;
    this.$store
      .dispatch("QuoteStore/getOneQuote", quoteId)
      .then((data) => {
        // TODO: Will check if the code below is needed.
        // this.$store.dispatch("QuoteDayStore/setDays", {
        //   days: data.days || [],
        //   suppliers: data.suppliers || {},
        // });
      })
      .catch((err) => {
        this.$root.$error(err);
      });
  },
};
</script>

<style lang="scss">
.booking-wrapper {
  height: calc(100% - 48px);

  .booking-title {
    padding: 2px 5px 0;
    background: lightgrey;
    font-weight: 500;
    font-size: 12px;
  }
}
</style>
