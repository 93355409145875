<template>
  <span class="mx-3 pa-2 overline grey lighten-1" v-once>{{ text }}</span>
</template>

<script>
export default {
  props: {
    text: String,
  },
};
</script>
