<template>
  <v-tabs v-model="tab" dense>
    <v-tabs-slider></v-tabs-slider>
    <v-tab v-for="(v, i) in tabs" :key="i" :to="v.to">
      {{ v.text }}
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  data() {
    return {
      tab: 0,
      tabs: [
        {
          text: "Information",
          to: { name: "product_page" },
        },
      ],
      // }, {
      //   text: 'Pictures',
      //   to: { name: 'product_pictures' }
      // }]
    };
  },
};
</script>

<style></style>
