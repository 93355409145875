<template>
  <v-row class="caption">
    <v-col>
      {{ label }}
    </v-col>
    <v-col
      class="d-flex justify-center"
      style="min-width: 140px; max-width: 140px; border: 1px solid lightgrey; position: relative"
    >
      {{ displayTotal }}

      <div class="d-flex align-center" style="position: absolute; right: 0; top: 0px">
        <v-btn icon small @click="$emit('remove')" class="report-btn">
          <v-icon small>close</v-icon>
        </v-btn>
      </div>
    </v-col>

    <v-col v-if="deltaconfig" style="max-width: 140px; min-width: 140px"></v-col>

    <v-col
      v-if="deltaconfig"
      class="pa-0 d-flex justify-center"
      align-self="center"
      style="max-width: 150px; min-width: 150px"
    >
      <btndrop :value="isReconciled" onLabel="Reconciled" offLabel="Not Reconciled" :onchange="updateReconciled" />
    </v-col>
  </v-row>
</template>

<script>
import money from "@/services/money";
export default {
  props: {
    deltaconfig: Boolean,
    extraid: String,
    label: String,
    amount: Object,
    reconciled: [Number, Boolean],
  },
  computed: {
    displayTotal() {
      let v = this.amount && this.amount.value;
      return v ? `$ ${money.format(v, { precision: 2 })}` : "";
    },
    delta() {
      return {
        diff: this.displayTotal,
        positive: true,
      };
    },

    isReconciled() {
      return !!this.reconciled;
    },
  },
  methods: {
    updateReconciled(v) {
      return this.$store.dispatch("FinanceCompileStore/updateExtraExpense", {
        expense_id: this.extraid,
        data: {
          reconciled: !this.isReconciled,
        },
      });
    },
  },
};
</script>
