<template>
  <v-toolbar id="supheader" dense flat>
    <v-toolbar-title class="d-flex justify-space-between" style="width: 100%">
      <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
    </v-toolbar-title>

    <template v-slot:extension>
      <ReportTabs :bookingId="bookingId" />
    </template>
  </v-toolbar>
</template>

<script>
import ReportTabs from "./_ReportTabs";
export default {
  props: {
    name: String,
  },
  components: {
    ReportTabs,
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: "Reports",
          to: { name: "finances" },
        },
        {
          text: this.name,
        },
      ];
    },
    bookingId() {
      return this.$store.getters["FinanceReportStore/booking_id"];
    },
  },
};
</script>
