<template>
  <div>
    <v-row class="grey lighten-2 mt-2 subtitle-2">
      <v-col cols="5" align-self="center"> Total Target </v-col>
      <v-col class="d-flex justify-end">
        <CommissionDisplay
          :commission="commission"
          :commissionTotal="commissionTotal"
          :customCommissionAmount="customCommissionAmount"
        />
        <v-btn x-small icon class="mx-2 report-btn" @click="showHistory = !showHistory">
          <v-icon small>{{ showHistory ? "visibility" : "visibility_off" }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <CommissionReceivedHistory v-if="showHistory" :external="external" :history="commissionReceivedHistory" />

    <v-row>
      <v-col class="subtitle-2" align-self="center"> Received </v-col>

      <v-col class="d-flex justify-end">
        <div class="d-flex align-center pr-1">{{ formatedReceivedTotal }}</div>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="subtitle-2" align-self="center"> Not Received </v-col>

      <v-col class="d-flex justify-end">
        <div class="d-flex align-center pr-1">{{ formatedRemainingTotal }}</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import money from "@/services/money";
import format from "@/services/format";
import CommissionDisplay from "./CommissionDisplay";
import CommissionReceivedHistory from "./_CommissionReceivedHistory";
export default {
  props: {
    external: Boolean,
    commission: [Number, Boolean],
    commissionTotal: String,
    customCommissionAmount: [Number, String],

    // History
    commisionReceivedTotal: Number,
    remainingTotal: Number,
    commissionReceivedHistory: Array,
  },
  components: {
    CommissionDisplay,
    CommissionReceivedHistory,
  },
  data() {
    return {
      showHistory: false,
    };
  },
  computed: {
    formatedRemainingTotal() {
      return this.remainingTotal ? `$ ${money.format(this.remainingTotal, { precision: 2 })}` : "$ --";
    },
    formatedReceivedTotal() {
      return this.commisionReceivedTotal ? `$ ${money.format(this.commisionReceivedTotal, { precision: 2 })}` : "$ --";
    },
    // formatedTotal(){
    //   return this.totalInvoice? `$ ${money.format(this.totalInvoice, { precision: 2 })}` : '$ --'
    // }
  },
};
</script>
