<template>
  <a-card nopad class="row">
    <v-col class="pa-2" style="min-width: 40px; max-width: 40px">
      <v-icon small>{{ modicon }}</v-icon>
    </v-col>
    <v-col class="pa-0">
      <SupplierRowBlock
        nobuttons
        external
        :bookingId="bookingId"
        :supplier="supplier"
        :supplierType="typeAs"
        :supplierInvoice="supplierInvoice"
        :supplierDelta="extractSupplierDelta"
        :extraExpenses="extraExpenses"
        :refundExpenses="refundExpenses"
        :depositExpenses="depositExpenses"
        :commissionReceivedHistory="commissionReceivedHistory"
        :customCommissionList="customCommissionList"
        @update="(v) => $emit('update', v)"
        @pop-expense="(v) => $emit('pop-expense', v)"
        @pop-invoice="(v) => $emit('pop-invoice', v)"
        @sync-commission="(v) => $emit('sync-commission', v)"
        @toggle-commission-received="$emit('toggle-commission-received')"
      />
    </v-col>
  </a-card>
</template>

<script>
import SUPMENU from "@/config/menus/suppliersMenu";
import format from "@/services/format";
import { SupplierRowBlock } from "@/modules/finances.report";
export default {
  props: {
    typeAs: String,
    bookingId: String,
    supplier: Object,

    bookingExtraExpenses: Array,
    supplierDelta: [Object, Number],
    supplierInvoice: [Object],

    commissionReceivedHistory: Array,
    customCommissionList: Array,
  },
  components: {
    SupplierRowBlock,
  },
  computed: {
    modicon() {
      let mod = SUPMENU.MOD_DBKEY;
      return mod[this.typeAs] || {};
    },

    extractSupplierDelta() {
      const custom = this.supplierDelta;
      if (custom === null || custom === undefined) return { diff: null, positive: true };
      return {
        diff: custom,
        positive: custom >= 0,
      };
    },

    extraExpenses() {
      return (this.bookingExtraExpenses || [])
        .filter((v) => v.supplier_id === this.supplier.supplier_id && v.expense_type === "DEFAULT")
        .filter((v) => v.type_as === this.supplierType);
    },
    refundExpenses() {
      return (this.bookingExtraExpenses || [])
        .filter((v) => v.supplier_id === this.supplier.supplier_id && v.expense_type === "REFUND")
        .filter((v) => v.type_as === this.supplierType);
    },
    depositExpenses() {
      return (this.bookingExtraExpenses || [])
        .filter((v) => v.supplier_id === this.supplier.supplier_id && v.expense_type === "DEPOSIT")
        .filter((v) => v.type_as === this.supplierType)
        .sort((a, b) => {
          if (a.datestamp > b.datestamp) return 1;
          if (a.datestamp < b.datestamp) return -1;
          return 0;
        })
        .map((v) => {
          return {
            ...v,
            label: `${v.label} - ${format.formatDate(v.datestamp, true)}`,
          };
        });
    },
  },
};
</script>
