<template>
  <tr :class="{ 'grey lighten-1': embedded }">
    <td :colspan="embedded ? 2 : 1">
      <span class="subtitle-2">TOTAL</span>
      <span class="px-2">({{ itemList.length }} bookings)</span>
    </td>

    <td class="excel">
      {{ formatCost(totalInvoice) }}
    </td>
    <td class="excel">
      <span style="margin-right: -7px">{{ formatCost(totalBilled) }}</span>
    </td>
    <!-- <td class="excel"></td> -->
    <td class="excel font-weight-bold">
      <span style="margin-right: -7px">{{ margin.diff }}</span>
    </td>
    <td :class="marginCss" class="excel">
      <span style="margin-left: -7px">{{ margin.per }}</span>
    </td>
    <td class="excel"></td>
    <td class="excel">
      <span class="caption" :class="invoicePaidDisplay.color">{{ invoicePaidDisplay.value }}</span>
    </td>
  </tr>
</template>

<script>
import { ReportService } from "@/modules/finances.report";
import money from "@/services/money";
export default {
  props: {
    itemList: Array,
    embedded: Boolean,
    splitTotal: Boolean,
  },
  computed: {
    totalInvoice() {
      const cost = {
        COMBINED: 0,
        USA: 0,
        CDA: 0,
      };

      this.itemList.forEach((item) => {
        if (item.total_invoice) {
          Object.keys(cost).forEach((key) => {
            cost[key] += item.total_invoice;
          });
        }
      });

      return cost;
    },
    totalCost() {
      const cost = {
        COMBINED: 0,
        USA: 0,
        CDA: 0,
      };

      this.itemList.forEach((item) => {
        Object.keys(item.total_cost).forEach((key) => {
          cost[key] += item.total_cost[key];
        });
      });

      return cost;
    },
    totalBilled() {
      const cost = {
        COMBINED: 0,
        USA: 0,
        CDA: 0,
      };

      this.itemList.forEach((item) => {
        Object.keys(item.total_billed).forEach((key) => {
          cost[key] += item.total_billed[key];
        });
      });

      return cost;
    },
    billedDelta() {
      const cost = {
        COMBINED: 0,
        USA: 0,
        CDA: 0,
      };

      this.itemList.forEach((item) => {
        Object.keys(item.total_billed_delta).forEach((key) => {
          cost[key] += item.total_billed_delta[key];
        });
      });

      return cost;
    },
    deltaCss() {
      if (!this.totalCost) return "grey--text";
      if (this.billedDelta < 0) return "green--text";
      return "red--text";
    },

    margin() {
      const val = this.itemList.reduce((total, i) => total + (i.margin_val || 0), 0);
      const per = this.itemList.reduce((total, i) => total + (i.margin_per || 0), 0);

      const average = this.itemList.length ? per / this.itemList.length : 0;

      return {
        has: true,
        diff: val !== 0 ? `$ ${money.format(val, { precision: 2 })}` : "$ --",
        per: val ? `(${Number(average).toFixed(2)} %)` : "",
        positive: val > 0,
      };
      // const commission = this.itemList.reduce((total, i) =>  total + (i.commission || 0), 0)
      // const val = this.itemList.reduce((total, i) =>  total + (i.margin_val || 0), 0)
      // const costTotal = this.totalCost + this.billedDelta;
      // const diff = Number(this.totalInvoice) - Number(costTotal) + Number(commission || 0)

      // // Percentage
      // const per = costTotal? diff / costTotal * 100 : 0

      // return {
      // 	has: true,
      // 	diff: this.formatCost(diff),
      // 	per: val? `(${Number(per).toFixed(2)} %)` : '',
      // 	positive: val > 0
      // }
    },
    marginCss() {
      if (!this.margin.has) return "grey--text";
      if (this.margin.positive) return "green--text";
      return "red--text";
    },

    invoicePaid() {
      return this.itemList.reduce((total, i) => total + i.invoice_paid, 0);
    },

    invoicePaidDisplay() {
      return {
        color: this.invoicePaid
          ? this.invoicePaid === this.totalCost
            ? "green--text"
            : this.invoicePaid > this.totalCost
              ? "primary--text"
              : "warning--text"
          : "",
        value: this.invoicePaid ? `$ ${money.format(this.invoicePaid, { precision: 2 })}` : "$ --",
      };
    },

    // Reconciliation compile
    totalRecognition() {
      const top = this.itemList.reduce((total, i) => total + (i.recon_total || 0), 0);
      const bottom = this.itemList.reduce((total, i) => total + (i.supplier_total || 0), 0);
      if (!bottom) return "0 %";
      return `${Number((top / bottom) * 100).toFixed(0)} %`;
    },
  },
  methods: {
    /**
     * @param cost the cost object
     */
    formatCost(cost) {
      if (this.splitTotal) {
        return cost.USA !== 0 || cost.CDA !== 0 ? `$ ${cost.USA} / ${cost.CDA}` : "$ --";
      }
      return cost.COMBINED !== 0 ? `$ ${money.format(cost.COMBINED, { precision: 2 })}` : "$ --";
    },
  },
};
</script>
