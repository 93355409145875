var COLORS = {
  grey: {
    label: "(No Status)",
    value: 0,
    color: "grey--text lighten-5",
    bgcolor: "grey lighten-5",
  },
  light_blue: {
    label: "Inquiry",
    value: 1,
    color: "blue--text lighten-2",
    bgcolor: "blue lighten-2",
  },
  blue: {
    label: "Contract Request",
    value: 2,
    color: "indigo--text darken-1",
    bgcolor: "indigo darken-1",
  },
  purple: {
    label: "Signed, waiting for counter signature",
    value: 3,
    color: "purple--text",
    bgcolor: "purple",
  },
  green: {
    label: "Confirmed",
    value: 4,
    color: "green--text",
    bgcolor: "green",
  },
  yellow: {
    label: "Rooming List Sent",
    value: 5,
    color: "yellow--text",
    bgcolor: "yellow",
  },
  yellow_alt: {
    label: "Re-confirmed",
    value: 5,
    color: "yellow--text",
    bgcolor: "yellow",
  },
  yellow_alt2: {
    label: "Technical Sent",
    value: 5,
    color: "yellow--text",
    bgcolor: "yellow",
  },
  black: {
    label: "Cancelled",
    value: -1,
    color: "black--text",
    bgcolor: "black",
  },
  red: {
    label: "Impossible",
    value: -2,
    color: "red--text",
    bgcolor: "red",
  },
};

export default {
  COLORS,
  dotColors(type) {
    var base = [COLORS.grey];
    let last;

    if (["HOTEL"].includes(type)) {
      last = COLORS.yellow;
    }
    if (["RESTAURANT", "PARK", "EXCURSION"].includes(type)) {
      last = COLORS.yellow_alt;
    }
    if (["TRANSPORT", "TOUR_GUIDE"].includes(type)) {
      last = COLORS.yellow_alt2;
    }

    return [...base, COLORS.light_blue, COLORS.blue, COLORS.purple, COLORS.green, last];
  },
};
