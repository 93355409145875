import api from "./api";
import Vue from "vue";

const SET_MASS_RETURN = "SET_MASS_RETURN";

const namespaced = true;

const state = {
  fetching: false,
};

const mutations = {
  SET_MASS_RETURN(state, { bookings, contacts }) {
    state.bookings = bookings;
    state.contacts = contacts;
  },
};

const actions = {
  getSupplierBookings({ commit }, query) {
    return api
      .getSupplierBookingAndContacts(query.supplier_id, query)
      .then((data) => {
        commit(SET_MASS_RETURN, data.data);
        return data.data;
      })
      .catch((err) => {
        if (err) throw err.data;
      });
  },
};

const getters = {
  // Api Data
  bookings: (state) => state.bookings,
  contacts: (state) => state.contacts,
};

export default {
  namespaced,
  state,
  mutations,
  getters,
  actions,
};
