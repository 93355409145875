<template>
  <div class="d-flex">
    <div v-if="showConvert">
      <v-menu open-on-hover top left>
        <template v-slot:activator="{ on }">
          <div v-on="on">
            {{ formatedTotal }}
          </div>
        </template>

        <div class="hover-menu font-italic">
          {{ convertedTotal }}
        </div>
      </v-menu>
    </div>

    <div v-else>
      <span>{{ formatedTotal }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showConvert: Boolean,
    formatedTotal: String,
    convertedTotal: String,
  },
};
</script>
