export default {
  tax: {
    cols: 4,
    label: "Tax",
    inputType: "PERCENT",
    searchType: "RANGE",
    restrictEnv: ["JPN"],
  },
  trans_type: {
    cols: 4,
    hideClear: true,
    label: "Type",
    inputType: "DROPDOWN",
    source: [
      {
        label: "Bus",
        value: "BUS",
      },
      {
        label: "Train",
        value: "TRAIN",
      },
    ],
  },
  payment_type: {
    label: "Payment",
    inputType: "CYOA",
    choosePath: {
      wire: "TEXT",
      "credit card": "TEXT",
      cash: "TEXT",
      check: "TEXT",
    },
  },
};
