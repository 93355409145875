<template>
  <v-toolbar id="supheader" dense flat>
    <v-toolbar-title>
      <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
    </v-toolbar-title>
    <template v-slot:extension>
      <QuoteTabs :quoteId="quoteId" />
    </template>
  </v-toolbar>
</template>

<script>
import QuoteTabs from "./QuoteTabs";
export default {
  components: {
    QuoteTabs,
  },
  props: {
    quoteId: String,
  },
  computed: {
    breadcrumbs() {
      const quote = this.$store.getters["QuoteStore/active"];
      return [
        {
          text: "Quotes",
          to: { name: "quotes" },
        },
        {
          text: `${quote.title} - ${quote.client_name} (${quote.city_start} to ${quote.city_end})`,
        },
      ];
    },
  },
};
</script>
