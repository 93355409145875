import Vue from "vue";

import "./buttons";
import "./display";
import "./form";
import "./wrappers";

import ButtonControlHeader from "./ButtonControlHeader";
import CreateButtonHeader from "./CreateButtonHeader";
import SingleTag from "./SingleTag";
import SnackbarAlert from "./SnackbarAlert";

import UploadDialog from "./media/UploadDialog";
import EditDocumentDialog from "./media/EditDocumentDialog";
import EditImageDialog from "./media/EditImageDialog";

import VirtualScrollWrapper from "./VirtualScrollWrapper";
import AutocompleteFreeText from "./AutocompleteFreeText";
import AutocompleteStringFreeText from "./AutocompleteStringFreeText";

Vue.component("button-control-header", ButtonControlHeader);
Vue.component("create-header", CreateButtonHeader);
Vue.component("single-tag", SingleTag);
Vue.component("snackbar-alert", SnackbarAlert);

Vue.component("upload-dialog", UploadDialog);
Vue.component("edit-document-dialog", EditDocumentDialog);
Vue.component("edit-image-dialog", EditImageDialog);

Vue.component("virtual-scroll-wrapper", VirtualScrollWrapper);

// TODO: Will refactor this later
Vue.component("autocomplete-free-text", AutocompleteFreeText);
Vue.component("autocomplete-string-free-text", AutocompleteStringFreeText);
