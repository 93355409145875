<template>
  <v-col>
    <v-row justify="space-between">
      <delete-button :headline="deleteHeadline" :text="deleteText" :ondelete="confirmDelete" />

      <div>
        <g-button label="Return" @onclick="$router.push({ name: 'clients_contacts' })" />
        <save-button :onsave="updateContact" />
      </div>
    </v-row>

    <v-divider class="my-2" />
    <ContactEditForm
      v-model="contactForm"
      :country="country"
      :isprimary="isPrimary"
      @setPrimary="(v) => (isPrimary = v)"
    />
  </v-col>
</template>

<script>
import { ContactEditForm } from "@/modules/contacts";

export default {
  components: {
    ContactEditForm,
  },
  data() {
    var vm = this;
    return {
      contactId: "",
      isPrimary: null,
      contactForm: {
        name: "",
        title: "",
        phone_number: "",
        cell_number: "",
        email: "",
        notes: "",
        meta: {},
      },
    };
  },
  computed: {
    clientId() {
      return this.$store.getters["ClientStore/client_id"];
    },
    country() {
      return this.$store.getters["ClientStore/country"];
    },
    deleteHeadline() {
      return `Delete: ${this.contactForm.name} ?`;
    },
    deleteText() {
      return this.isPrimary
        ? `This is a PRIMARY CONTACT. If you remove this, there will be no primary contact anymore and it must be reset.`
        : null;
    },
  },
  methods: {
    setPrimary(clearPrimary) {
      if (this.isPrimary) {
        return this.$store
          .dispatch("ClientContactStore/setPrimaryContact", {
            client_id: this.clientId,
            contact_id: clearPrimary ? null : this.contactId,
            old_primary: this.cachedPrimaryContactId,
          })
          .then((v) => {
            this.$store.dispatch("ClientStore/changePrimary", clearPrimary ? null : this.contactId);
            return new Promise((resolve, rej) => resolve());
          })
          .catch((err) => {
            throw err;
          });
      }

      return new Promise((resolve, rej) => resolve());
    },
    updateContact() {
      return this.$store
        .dispatch("ClientContactStore/updateContact", {
          id: this.contactId,
          contact: this.contactForm,
          original: this.contactFormOriginal,
        })
        .then((v) => this.setPrimary(false))
        .then((v) => {
          this.$store.dispatch("ClientStore/updateContact", this.contactForm);
          this.$root.$success("Updated contact: " + this.contactForm.name);
          this.$router.push({ name: "clients_contacts" });
        })
        .catch((err) => this.$root.$error);
    },

    confirmDelete() {
      return (
        this.$store
          .dispatch("ClientContactStore/deleteContactList", [this.contactId])
          // If primary contact, want to remove that too
          .then((v) => this.setPrimary(true))
          .then((v) => {
            this.deleteGroup = [];
            this.$store.dispatch("ClientStore/removeContacts", [this.contactId]);
            this.$root.$success("Deleted contacts");
            this.$router.push({ name: "clients_contacts" });
          })
          .catch((err) => this.$store.$error(err))
      );
    },
  },
  mounted() {
    this.contactId = this.$route.params.contact_id;

    // Get current primary contact for client
    var primary = this.$store.getters["ClientStore/primary_contact"];
    this.cachedPrimaryContactId = primary.id;
    this.isPrimary = this.contactId === primary.id;

    // Get contact (copy to not mutate original)
    var contactList = this.$store.getters["ClientStore/all_contacts"];
    this.contactForm = { ...contactList.find((x) => this.contactId === x.id) };
    this.contactFormOriginal = JSON.stringify(this.contactForm);
    if (!this.contactForm.meta) this.contactForm.meta = {};
  },
};
</script>
