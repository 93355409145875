import api from "./api";
import axios from "axios";
import isDev from "@/services/isDevProcess";

const LOGIN = "LOGIN";
const LOGOUT = "LOGOUT";
const SET_LOADING = "SET_LOADING";
const CACHE_CREDS = "CACHE_CREDS";
const SET_AUTOLOGIN = "SET_AUTOLOGIN";

const DEV_URL = "https://localhost:3000/api";
const PROD_URL = "https://service.supernature.travel/api";

const state = {
  packageVersion: process.env.PACKAGE_VERSION || "0",
  website: "https://service.supernature.travel",
  host: isDev() ? DEV_URL : PROD_URL,
  // Hard coded for new windows -- subject to bugs
  localhost: isDev() ? "http://localhost:8080" : "app://./index.html",
  isLoggedIn: localStorage.getItem("token"),
  role: localStorage.getItem("role"),
  loading: false,

  cachedLoginCreds: localStorage.getItem("cachedLoginCreds"),
};

function getCachedCreds() {
  return JSON.parse(localStorage.getItem("cachedLoginCreds") || "{}");
}

const mutations = {
  [SET_LOADING](state, toggle) {
    state.loading = toggle;
  },
  [LOGIN](state, account) {
    state.isLoggedIn = account.token;
    state.role = account.role;

    localStorage.setItem("token", account.token || "");
    localStorage.setItem("role", account.role || "");
  },
  [SET_AUTOLOGIN](state, value) {
    const cached = getCachedCreds();
    cached.autolog = value;
    localStorage.setItem("cachedLoginCreds", JSON.stringify(cached));
  },
  [CACHE_CREDS](state, creds) {
    localStorage.setItem(
      "cachedLoginCreds",
      JSON.stringify({
        ...creds,
        autolog: true,
      })
    );
  },
  [LOGOUT](state, maintainSesson) {
    state.isLoggedIn = null;
    localStorage.removeItem("token");
    localStorage.removeItem("role");

    // Keep existing creds, but require manual login
    const cached = getCachedCreds();
    cached.autolog = !!maintainSesson;
    localStorage.setItem("cachedLoginCreds", JSON.stringify(cached));
  },
};

const actions = {
  login({ commit, state }, creds) {
    commit(SET_LOADING, true);
    return api
      .login(creds)
      .then((data) => {
        commit(LOGIN, data.data);
        commit(CACHE_CREDS, creds);
        commit(SET_LOADING, false);
        return data.data;
      })
      .catch((err) => {
        commit(SET_LOADING, false);
        commit(SET_AUTOLOGIN, false);
        if (err) throw err.data;
      });
  },
  autoLogin({ dispatch, state, commit }) {
    const cached = getCachedCreds();

    if (!cached.autolog) return Promise.resolve();

    return dispatch("login", cached).catch((err) => {
      commit(SET_AUTOLOGIN, false);
      if (err) throw err.data;
    });
  },
  forgot_password({ commit }, email) {
    return api.forgotPassword(email);
  },
  logout({ commit }) {
    commit(SET_LOADING, true);
    return api
      .logout()
      .then((data) => {
        commit(LOGOUT);
        commit(SET_LOADING, false);
      })
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  redirectLogout({ commit }) {
    commit(LOGOUT, true);
  },

  loading({ commit }, value) {
    commit(SET_LOADING, value);
  },
};

const getters = {
  appVersion: (state) => state.packageVersion,
  website: (state) => state.website,
  host: (state) => state.host + "/",
  localhost: (state) => state.localhost,
  isLoggedIn: (state) => state.isLoggedIn,
  role: (state) => state.role,
  isAccounting: (state) => state.role === "ACCOUNTING",
  isAdmin: (state) => state.role === "ADMIN",
  loading: (state) => state.loading,
};

export default {
  // CORE MODULE, NO NAMESPACING
  state,
  mutations,
  actions,
  getters,
};
