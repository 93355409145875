<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)" width="500px" persistent>
    <v-card>
      <v-toolbar depressed flat>
        <div class="d-flex flex-column">
          <span class="subtitle-2">{{ title }}</span>
          <span class="caption">Required Replacements</span>
        </div>
        <div class="flex-grow-1"></div>
      </v-toolbar>

      <v-card-text>
        <div v-for="(f, index) in formContent" :key="index" class="d-flex justify-center align-start">
          <a-text-field :label="f" v-model="inputData[index]" widelabel />
        </div>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <g-button label="Cancel" @onclick="$emit('input', false)" />
        <g-button type="primary" label="Insert" :disabled="!isvalid" @onclick="eject" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EmailService from "../service";

export default {
  name: "TemplateDialog",
  props: {
    value: Boolean,
    title: String,
    floatingText: String,
    subject: String,
  },
  data() {
    return {
      inputData: [],
    };
  },
  watch: {
    formContent(v) {
      this.inputData = new Array(v.length);
    },
  },
  computed: {
    formContent() {
      var replaceFields = EmailService.getReplaceContent(this.floatingText);
      return replaceFields;
    },
    isvalid() {
      // Every field MUST be defined
      return this.inputData.every((item) => !!item);
    },
  },
  methods: {
    eject() {
      const resultText = EmailService.fillReplaceContent(this.floatingText, this.inputData);
      this.$emit("set", resultText);
      if (this.subject && this.subject !== "") {
        const resultSubject = EmailService.fillReplaceContent(this.subject, this.inputData);
        this.$emit("setSubject", resultSubject);
      }
      this.$emit("input", false);
    },
  },
};
</script>
