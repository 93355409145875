export default [
  {
    label: "Accounts",
    value: "accounts",
  },
  {
    label: "Bookings",
    value: "bookings",
  },
  {
    label: "Clients",
    value: "clients",
  },
  {
    label: "Email Templates",
    value: "email_templates",
  },
  {
    label: "Invoices",
    value: "invoices",
  },
  {
    label: "Reminders",
    value: "reminders",
  },
  {
    label: "Reporting",
    value: "reporting",
  },
  {
    label: "Suppliers",
    value: "suppliers",
  },
  {
    label: "Products",
    value: "products",
  },
  {
    label: "Payments",
    value: "payments",
  },
];
