<template>
  <v-btn icon small class="report-btn" @click="setEmail">
    <v-icon small color="blue">send</v-icon>
  </v-btn>
</template>

<script>
import { EmailService } from "@/modules/email";
export default {
  props: {
    supplier: Object,
  },
  computed: {
    supplierName() {
      const name = this.supplier.name;
      const type = this.supplier.type_as;
      return `${name} (${type})`;
    },
    supplierId() {
      return this.supplier.supplier_id;
    },
  },
  methods: {
    setEmail() {
      // Set email form content
      this.$store.dispatch("EmailStore/pendMail", {
        //contact: this.contact,
        returnTo: this.$route.path,
        config: {
          title: this.supplierName,
          supplierId: this.supplierId,
        },
      });
      // Switch to new page
      this.$router.push({
        name: "email",
      });
    },
  },
};
</script>
