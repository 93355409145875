<template>
  <div>
    <v-row v-for="product in productList" :key="product.name" style="flex-wrap: nowrap">
      <v-col cols="5" align-self="center">
        <a-truncate :text="product.name" :hoverText="product.hoverName" />
      </v-col>
      <v-col cols="3" align-self="center">
        <HotelPriceHover
          v-if="showPriceBreakdown"
          :text="product.rate"
          :taxInclusive="product.tax_inclusive"
          :tax="product.tax"
          :supplierMeta="supplierMeta"
          :meta="product.meta"
          :bookingSupplierMeta="data.meta"
          :original="product.original"
          :showConvert="showConverstion"
          :convert="product.convert"
          :quotePrice="product.quotePrice"
        />
        <OtherPriceHover v-else :original="product.rate" :text="product.rate" :quotePrice="product.quotePrice" />
      </v-col>
      <v-col cols="2" align-self="center" v-if="typeAs === 'HOTEL'">
        <HotelPorterageHover :porterage="supplierMeta.porterage" />
      </v-col>
      <v-col class="pt-1" align-self="center" v-if="product.showCount">
        <ConfirmCount
          :outside="outside"
          :typeAs="typeAs"
          :status="data.status"
          :date="date"
          :bookingSupplierMeta="data.meta"
          :productId="product.id"
          :productCount="product.count"
          :pax="pax"
          :supcount="supcount"
          @synccount="syncCount"
          @confirm="confirmCount"
        >
          <RestaurantCountHover v-if="typeAs === 'RESTAURANT'" :count="product.count" :supcount="supcount" />
          <ExcursionCustomCountHover v-else-if="showExtraExcursion" :count="product.count" :supcount="supcount" />
          <span class="caption" v-else>{{ product.count }}</span>
        </ConfirmCount>
      </v-col>

      <slot></slot>
    </v-row>
  </div>
</template>

<script>
import { ProductService } from "@/modules/products";
import DateFormat from "@/services/format";
import HotelPriceHover from "./_HotelPriceHover";
import OtherPriceHover from "./_OtherPriceHover";
import HotelPorterageHover from "./_HotelPorterageHover.vue";
import RestaurantCountHover from "./_RestaurantCountHover";
import ExcursionCustomCountHover from "./_ExcursionCustomCountHover";
import ConfirmCount from "./_ConfirmCount";
export default {
  props: {
    outside: Boolean,

    typeAs: String,
    data: Object, // Supplier + product information
    date: String,
    confirmed: Boolean,
    pax: Number,
    altPrices: Object, // Mapping for products with custom pricing
    supcount: [Array, Object],
    showExtraExcursion: Boolean,
  },
  components: {
    HotelPriceHover,
    HotelPorterageHover,
    RestaurantCountHover,
    ExcursionCustomCountHover,
    ConfirmCount,
    OtherPriceHover,
  },
  computed: {
    productList() {
      var t = [];
      if (this.data.products) {
        let name, price, refkey, count, groupFit, product, quotePrice;
        const vm = this;
        Object.keys(this.data.products).forEach((key) => {
          product = vm.data.products[key];
          price = this.rateContent(vm.data.products[key]);
          count = this.data.meta && this.data.meta.count && this.data.meta.count[key];
          name = this.data.meta && this.data.meta.custom_names && this.data.meta.custom_names[key];
          groupFit = vm.data.products[key].group_fit;
          quotePrice = this.data.meta && this.data.meta.quote_price && this.data.meta.quote_price[key];

          t.push({
            id: product.id,
            name: `${name || vm.data.products[key].name}`,
            hoverName: `${name || vm.data.products[key].name} (${groupFit})`,
            rate: price.rate,
            convert: price.convert,
            count: count || 1,
            original: price.original,

            tax_inclusive: product.tax_inclusive,
            tax: (product.meta && product.meta.custom_tax) || (vm.data.supplier_meta && vm.data.supplier_meta.tax),
            meta: product.meta,

            showCount: (() => {
              if (vm.data.products[key].product_type === "EXPENSE") return true;
              return !["HOTEL", "TOUR_GUIDE"].includes(this.typeAs);
            })(),

            quotePrice: quotePrice != null ? ProductService.formatPrice(quotePrice) : null,
          });
        });
      }
      return t;
    },
    productListLabel() {
      return this.productList.length ? this.productList.map((x) => x.name).join(", ") : "";
    },
    showConverstion() {
      return this.$store.getters["AccountStore/environment"] !== "USA";
    },
    showCount() {
      return !["HOTEL", "TOUR_GUIDE"].includes(this.typeAs);
    },
    showPriceBreakdown() {
      return ["HOTEL"].includes(this.typeAs);
    },
    supplierMeta() {
      return this.data.supplier_meta || {};
    },
    showConfirmCount() {
      return ["EXCURSION"].includes(this.typeAs);
    },
    bookingData() {
      return this.$store.getters["BookingStore/data"];
    },
  },
  methods: {
    rateContent(product) {
      let locked = this.data.locked_prices && this.data.locked_prices[product.id];
      const useDates = this.data.meta && this.data.meta.use_dates;
      let dateDefault = {
        from_month: DateFormat.dateToObj(this.date).month,
        from_day: DateFormat.dateToObj(this.date).day,
      };
      const price = ProductService.getPrice(
        product,
        this.data.supplier_meta,
        this.data.country,
        this.altPrices,
        this.$store.getters,
        true,
        useDates || dateDefault,
        this.data.meta
      );

      if (!product.seasonal) {
        return {
          rate: locked ? ProductService.formatPrice(locked.totalPrice) : price.totalPrice_print,
          convert:
            locked && locked.totalConvert
              ? ProductService.formatConvert(locked.totalConvert)
              : price.totalConvert_print,
          original:
            locked && locked.originalPrice ? ProductService.formatPrice(locked.originalPrice) : price.price_print,
        };
      }

      let result = "",
        convert = "",
        original = "",
        temp;
      price.forEach((p, index) => {
        locked = this.data.locked_prices && this.data.locked_prices[p.id] && this.data.locked_prices[p.id][index];
        temp = {
          rate: locked ? ProductService.formatPrice(locked.totalPrice) : p.totalPrice_print,
          convert:
            locked && locked.totalConvert ? ProductService.formatConvert(locked.totalConvert) : p.totalConvert_print,
          original: locked && locked.originalPrice ? ProductService.formatPrice(locked.originalPrice) : p.price_print,
        };

        convert += `${index >= 1 ? ";<br/>" : ""}${temp.convert}`;
        result += `${index >= 1 ? ";<br/>" : ""}${temp.rate}`;
        original += `${index >= 1 ? ";<br/>" : ""}${temp.original}`;
      });
      return { rate: result, convert, original };
    },

    syncCount({ product_id, date, confirmCount }) {
      let nuCountMapping = { ...((this.data.meta && this.data.meta.confirmed_count_total) || {}) };
      if (!nuCountMapping[product_id]) nuCountMapping = { [product_id]: { [date]: confirmCount } };

      nuCountMapping[product_id][date] = confirmCount;

      this.$emit("syncmeta", { key: "confirmed_count_total", count: nuCountMapping });
    },
    confirmCount() {
      this.$emit("syncmeta", { key: "confirmed_offcount", value: true });
    },
  },
};
</script>
