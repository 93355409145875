<template>
  <div>
    <v-row class="pa-0 subrow">
      <v-col class="d-flex align-center subtitle-2" align-self="center"> + {{ label }} </v-col>

      <v-col
        style="max-width: 140px; min-width: 140px; position: relative; border: 1px solid lightgrey"
        :style="styleCss"
      >
        <Billed :billed="extraExpenseTotal" />
      </v-col>

      <v-col v-if="!deltaconfig" style="max-width: 140px; min-width: 140px; border: 1px solid lightgrey">
        <Delta hasSetCost :delta="delta.diff" :positive="delta.positive" />
      </v-col>
    </v-row>

    <v-row v-show="showProducts">
      <v-col class="pa-0 ml-1" style="margin-right: 140px">
        <ExtraExpenseRow
          :deltaconfig="deltaconfig"
          v-for="extra in extraExpenses"
          :key="extra.id"
          :extraid="extra.id"
          :label="extra.label"
          :amount="extra.amount"
          :reconciled="extra.reconciled"
          @remove="$emit('remove-extra', extra)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ReportService from "@/modules/finances.report/service";
import ExtraExpenseRow from "./_ExtraExpenseRow";

import Delta from "@/modules/finances.report/components/subitems/_Delta";
import Billed from "@/modules/finances.report/components/subitems/_Billed";
export default {
  props: {
    label: String,
    showProducts: Boolean,
    extraExpenses: Array,
    deltaconfig: Boolean,

    country: String,
  },
  components: {
    ExtraExpenseRow,
    Delta,
    Billed,
  },
  computed: {
    extraExpenseTotal() {
      return ReportService.getExtraTotal(this.extraExpenses);
    },
    delta() {
      return {
        diff: this.extraExpenseTotal,
        positive: this.extraExpenseTotal > 0,
      };
    },
    styleCss() {
      return this.deltaconfig ? { "margin-right": "290px" } : {};
    },
  },
};
</script>
