import axios from "@/plugins/axios";

export default {
  getBookings(page, query, extra) {
    return axios.get("/bookings", {
      params: { page, query, ...extra },
    });
  },
  getBookingGroupList() {
    return axios.get("/booking_groups");
  },
  getSupplierListForBookings(o) {
    return axios.get(`/booking_groups/${o.group_name || "ALL"}/${o.supplier_type || "ALL"}`);
  },
  getOneBooking(booking_id) {
    return axios.get(`/bookings/${booking_id}`);
  },

  confirmCanAddBooking(data) {
    return axios.post("/bookings_confirm", data);
  },
  addBooking(data) {
    return axios.post("/bookings", data);
  },
  copyBooking(booking_id, data) {
    return axios.post(`/bookings/${booking_id}/copy`, data);
  },
  duplicateBooking(booking_id, data) {
    return axios.post(`/bookings/${booking_id}/duplicate`, data);
  },
  updateBooking(booking_id, data) {
    return axios.post(`/bookings/${booking_id}`, data);
  },
  updateBookingDates(booking_id, data) {
    return axios.post(`/bookings/${booking_id}/day_dates`, data);
  },
  checkHasBookingDates(booking_id) {
    return axios.get(`/bookings/${booking_id}/day_dates`);
  },
  revertBookingDates(booking_id) {
    return axios.delete(`/bookings/${booking_id}/day_dates`);
  },
  deleteBooking(booking_id) {
    return axios.delete(`/bookings/${booking_id}`);
  },
  cancelBooking(booking_id, cancelData) {
    return axios.post(`/booking_archives/${booking_id}`, cancelData);
  },
};
