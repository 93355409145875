<template>
  <div class="day">
    <div class="d-flex">
      <div class="font-weight-bold caps" style="width: 120px">
        <span class="underline">Day {{ dayIndex + 1 }}</span>
        <span class="pl-2">({{ weekday }})</span>
      </div>
      <div class="font-weight-bold" style="width: 250px">
        {{ displayDateCity }}
      </div>

      <div v-if="noBusGuideLine" class="font-weight-900 blue--text">{{ noBusGuideLine }}</div>
      <div v-if="busService" class="font-weight-900 blue--text">(BUS SERVICE FOR AIRPORT TRANSFER ONLY)</div>
    </div>
    <table>
      <tr v-for="(item, index) in value" :key="item.mockid">
        <td v-if="editMode" class="t-ctrls">
          <div v-if="index !== 0">
            <v-btn icon small @click="moveUp(item, index)">
              <v-icon>arrow_upward</v-icon>
            </v-btn>
          </div>
          <div v-if="index !== value.length - 1">
            <v-btn icon small @click="moveDown(item, index)">
              <v-icon>arrow_downward</v-icon>
            </v-btn>
          </div>
          <div>
            <v-btn icon small @click="insert(index)">
              <v-icon>post_add</v-icon>
            </v-btn>
          </div>
        </td>
        <td class="time">
          <span v-if="item.mid"><!-- SHOW NOTHING --></span>
          <span v-else-if="editMode">
            <f-time v-model="item.time" @input="item.modtime = true" />
          </span>
          <span v-else>{{ getTime(item.time) }}</span>
        </td>
        <td>
          <div v-if="item.fixed" v-html="item.fixed"></div>
          <div>
            <f-textarea v-if="editMode" v-model="item.float" @input="item.mod = true" />
            <div v-else v-html="(item.float || '').replace(/\r?\n/g, '<br />')"></div>
          </div>
          <div>
            <ProductTechnicalNotes
              :editMode="editMode"
              v-model="item.hide_technical_notes"
              :technicalNotes="item.technical_notes"
            />
          </div>

          <Confirmation
            :editMode="editMode"
            v-model="item.hide_confirm"
            :bookingSupplierMeta="getSupplier(item.mockid).meta"
          />
          <AddressPhone
            :editMode="editMode"
            v-model="item.hide_address"
            :supplierId="getSupplier(item.mockid).supplier_id"
            :contactMap="contactMap"
          />
          <PaymentType
            :editMode="editMode"
            v-model="item.hide_payment"
            :date="date"
            :supplier="getSupplier(item.mockid)"
            :contactMap="contactMap"
          />

          <ProductMenu :editMode="editMode" v-model="item.hide_menu" :menu="item.menu_notes" />
        </td>
        <td v-if="editMode" class="t-ctrls">
          <div class="d-flex flex-column">
            <v-btn icon small text disabled v-if="item.lock">
              <v-icon>lock</v-icon>
            </v-btn>
            <v-btn icon small @click="remove(index)" v-else>
              <v-icon>clear</v-icon>
            </v-btn>

            <v-btn icon small v-if="item.mod && item.source !== 'CUSTOM'" @click="revertToDefault(item)">
              <v-icon>history</v-icon>
            </v-btn>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import format from "@/services/format";
import PaymentType from "./_PaymentType";
import AddressPhone from "./_AddressPhone";
import Confirmation from "./_Confirmation";
import ProductTechnicalNotes from "./_ProductTechnicalNotes";
import ProductMenu from "./_ProductMenu";
export default {
  props: {
    date: String,
    city: String,
    dayIndex: Number,
    editMode: Boolean,
    value: Array,

    noBus: Boolean,
    busService: Boolean,
    noGuide: Boolean,
    contactMap: Object,
    compiledDayDefault: Array,
  },
  components: {
    Confirmation,
    AddressPhone,
    PaymentType,
    ProductTechnicalNotes,
    ProductMenu,
  },
  data() {
    return {
      itinerary: [],
    };
  },
  computed: {
    displayDateCity() {
      return `${format.formatDate(this.date)} ${this.city ? " -- " + this.city : ""}`;
    },
    noBusGuideLine() {
      if (this.noBus && this.noGuide) return "(NO BUS SERVICE OR TOUR GUIDE)";
      if (this.noBus) return "(NO BUS SERVICE)";
      if (this.noGuide) return "(NO TOUR GUIDE)";
      return false;
    },
    weekday() {
      return format.getWeekday(this.date);
    },
  },
  methods: {
    // Converts
    getTime(time) {
      if (!time) return;

      var t = time.split(":");
      var hour = Number(t[0]);

      return hour === 0
        ? `12:${t[1]} AM`
        : hour === 12
        ? `12:${t[1]} PM`
        : hour < 12
        ? `${hour}:${t[1]} AM`
        : `${hour - 12}:${t[1]} PM`;
    },
    getSupplier(mockid) {
      // mockid => booking_supplier_id => supplier_id
      // If mockid is the booking supplier's id, get the actual id
      // Otherwise, the supplier id is defined
      const supplier = this.$store.getters["BookingDayStore/suppliers"][`${mockid}`.replace("checkin", "")];
      return supplier ? supplier : {};
    },
    revertToDefault(item) {
      const original = this.compiledDayDefault.find((d) => d.mockid === item.mockid);
      // Revert to what was compiled by software
      item.time = original.time;
      item.float = original.float;
    },

    moveUp(item, index) {
      this.$emit("action", {
        command: "UP",
        date: this.date,
        index,
      });
    },
    moveDown(item, index) {
      this.$emit("action", {
        command: "DOWN",
        date: this.date,
        index,
      });
    },
    insert(index) {
      this.$emit("action", {
        command: "ADD",
        date: this.date,
        index,
        item: {
          mockid: new Date().getTime(),
          time: "",
          float: "",
          source: "CUSTOM",
        },
      });
    },
    remove(index) {
      this.$emit("action", {
        command: "DELETE",
        date: this.date,
        index,
      });
    },
  },
};
</script>

<style lang="scss">
.technical-wrapper {
  .day {
    margin: 10px auto;
    width: 100%;

    table {
      width: 100%;

      .t-ctrls {
        opacity: 0;
      }

      tr {
        margin-top: 10px;

        &:hover .t-ctrls {
          opacity: 1;
        }
      }

      td.time {
        width: 120px;
      }

      td {
        vertical-align: top;
        padding-top: 10px;
      }
    }
  }
}
</style>
