<template>
  <div>
    <elwrapper label="Client" :smlabel="!widelabel && !reglabel" :widelabel="widelabel">
      <v-text-field :value="value.name" outlined hide-details dense readonly @click="dialog = true"></v-text-field>
    </elwrapper>

    <ClientDialog v-model="dialog" @update="(v) => $emit('input', v)" />
  </div>
</template>

<script>
import ClientDialog from "./_ClientDialog";
export default {
  components: {
    ClientDialog,
  },
  props: {
    value: [Object, String],
    widelabel: Boolean,
    reglabel: Boolean,
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
