<template>
  <v-container class="pa-2" fluid>
    <v-row align="start" justify="center">
      <v-col cols="8">
        <d-header label="Databases" />

        <a-card v-for="card in cards" :key="card.title" @click.native="$router.push(card.link)" class="db-card">
          <div class="d-flex flex-row">
            <v-icon small class="mr-3">{{ card.icon }}</v-icon>
            <div class="subtitle-1">{{ card.title }}</div>
          </div>
        </a-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    cards: [
      {
        icon: "home_work",
        title: "Suppliers",
        link: { name: "database_view", params: { view: "suppliers", sub: "ALL" } },
      },
      {
        icon: "home_work",
        title: "Products",
        link: { name: "database_view", params: { view: "products", sub: "ROOM" } },
      },
      {
        icon: "home_work",
        title: "Clients",
        link: { name: "database_view", params: { view: "clients", sub: "ALL" } },
      },
    ],
  }),
};
</script>

<style lang="scss">
.db-card {
  &:hover {
    background: lightgrey;
  }
}
</style>
