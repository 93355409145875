<template>
  <div class="d-flex flex-row">
    <a-hover-menu v-if="prevBleepColor">
      <template v-slot:activator>
        <div>
          <v-icon x-small :class="prevBleepColor.color">brightness_1</v-icon>
          <v-icon :small="smstatus" :class="bleep.color">brightness_1</v-icon>
        </div>
      </template>

      <div class="caption">
        <div>(Date changed. Supplier must be contacted.)</div>
        <v-row> Previous: </v-row>
        <v-row>
          <v-icon small left color="white">event</v-icon>
          <span>{{ oldDatesDisplay }}</span>
        </v-row>
        <v-row v-if="oldConfirmation">
          <v-icon small left color="white">confirmation_number</v-icon>
          <span>{{ oldConfirmation.join(", ") }}</span>
        </v-row>
      </div>
    </a-hover-menu>

    <v-tooltip v-else left>
      <template v-slot:activator="{ on }">
        <div v-on="on">
          <v-icon :small="smstatus" :class="bleep.color + (smstatus ? ' px-1' : '')">
            {{ syncToggle ? "cancel" : "brightness_1" }}
          </v-icon>
        </div>
      </template>
      <span>{{ bleep.label }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import format from "@/services/format";
import STATUS_COLORS from "@/config/STATUS_COLORS";

const COLORS = STATUS_COLORS.COLORS;

export default {
  props: {
    status: Number,
    smstatus: Boolean,
    prevstatus: Number,
    prevdata: Object,
    prevdays: Array,
    showPrevStatus: Boolean,
    syncToggle: Boolean,
  },
  computed: {
    bleep() {
      let found = Object.values(COLORS).find((item) => item.value === this.status);
      return found;
    },
    prevBleepColor() {
      if (!this.showPrevStatus) return false;
      if (this.prevstatus === null) return { color: "white--text" };
      let found = Object.values(COLORS).find((item) => item.value === this.prevstatus);
      return found;
    },
    oldConfirmation() {
      if (!this.prevdata) return null;
      if (this.prevdata && this.prevdata.meta && this.prevdata.meta.confirm_number)
        return this.prevdata.meta.confirm_number;
      return null;
    },
    oldDatesDisplay() {
      if (!this.prevdays || this.prevdays.length === 0) return null;
      return format.compileDateListReadable(this.prevdays);
    },
  },
};
</script>
