<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <template v-slot:activator="{ on }">
      <nav-button icon="get_app" label="Export" @click="dialog = true" />
    </template>

    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <span class="subtitle-2">Export Booking</span>
      </v-card-title>

      <v-card-text>
        <v-data-table
          disable-pagination
          hide-default-footer
          fixed-header
          :headers="headers"
          :items="exportOptions"
          show-select
          single-select
          class="database-table a-card"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="item in items"
                :key="item.value"
                class="database-row color-alt canclick"
                :class="{
                  'primary--text grey lighten-2': selected[item.value],
                }"
                @click="onclick(item.value)"
              >
                <td>
                  <input type="checkbox" :checked="selected[item.value]" />
                </td>
                <td>{{ item.text }}</td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <g-button label="Cancel" @onclick="dialog = false" />
        <div class="flex-grow-1"></div>
        <g-button label="Export" icon="get_app" type="black" :disabled="loading" @onclick="exportBooking" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import format from "@/services/format";
import { SuppliersMenu } from "@/config/menus";
import { HEADER_COLS, SUPPLIER_COLS, ExportService } from "@/modules/exports";

import PhoneService from "@/components/form/phones/phoneService";
import PHONE_MASK from "@/config/PHONE_MASK";

export default {
  props: {
    disabled: Boolean,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      selected: {},
      headers: [
        {
          text: "Suppliers",
          value: "suppliers",
        },
      ],
      exportOptions: SuppliersMenu.FULL.map((item) => {
        return {
          text: item.title,
          value: item.alt,
        };
      }),
    };
  },
  methods: {
    onclick(val) {
      Vue.set(this.selected, val, !this.selected[val]);

      if (val === "all") {
        this.exportOptions.forEach((item) => {
          Vue.set(this.selected, item.value, this.selected.all);
        });
      } else {
        Vue.set(this.selected, "all", false);
      }
    },
    exportBooking() {
      this.loading = true;
      let dbkeys = [];
      Object.keys(this.selected).forEach((item) => {
        if (item === "all" || !this.selected[item]) return;
        dbkeys.push(item);
      });

      const booking = this.$store.getters["BookingStore/active"];

      /****
       * HEADER STUFF
       * ****/
      const listTypeTitle = [
        `List of ${dbkeys.map((v) => SuppliersMenu.FULL.find((s) => s.alt === v).title).join(", ")}`,
      ];

      const sheetTitle = [
        `Group: ${booking.group_name} - ${format.formatDate(booking.from)} to ${format.formatDate(booking.to)}`,
      ];

      const config = [
        { row: 2, options: { height: 40 } },
        { row: 2, cell: 1, options: { font: { name: "Calibri", size: 20 } } },
        { row: 3, options: { font: { name: "Calibri", color: { argb: HEADER_COLS.HEADER_COLOR }, bold: true } } },
      ];

      const header = [[], listTypeTitle, sheetTitle, []];

      /****
       * CREATE COLUMNS
       * ****/
      let base = [HEADER_COLS.DATE, HEADER_COLS.CITY, HEADER_COLS.SUPPLIER_NAME];

      // Export includes Hotels -- ADD KEYS
      if (dbkeys.includes("hotels")) {
        base = [HEADER_COLS.DATE, HEADER_COLS.HOTEL_NIGHT, HEADER_COLS.CITY, HEADER_COLS.SUPPLIER_NAME];
        base.push(HEADER_COLS.ADDRESS, HEADER_COLS.PHONE_NUMBER, HEADER_COLS.WEBSITE);
      }

      // Exporting NOT JUST hotels
      if (!dbkeys.includes("hotels") || (dbkeys.includes("hotels") && dbkeys.length > 1)) {
        base.push(HEADER_COLS.PRODUCTS);
      }

      if (dbkeys.includes("excursions")) {
        base.push(HEADER_COLS.TIME, HEADER_COLS.PAX);
      }

      // Export Includes Restaurants -- ADD KEYS
      if (dbkeys.includes("restaurants")) {
        base.push(HEADER_COLS.MENU);
      }

      if (dbkeys.includes("excursions") || dbkeys.includes("restaurants")) {
        if (!base.includes(HEADER_COLS.ADDRESS)) {
          base.push(HEADER_COLS.ADDRESS);
        }
        if (!base.includes(HEADER_COLS.PHONE_NUMBER)) {
          base.push(HEADER_COLS.PHONE_NUMBER);
        }
      }

      // If exporting ONLY Tour Guide, the headers are different
      if (dbkeys.length === 1 && dbkeys.includes("tour_guides")) {
        // OVERWRITE HEADERS
        base = [
          HEADER_COLS.DATE_START,
          HEADER_COLS.CITY_START,
          HEADER_COLS.DATE_END,
          HEADER_COLS.CITY_END,
          HEADER_COLS.GUIDE_NAME,
          HEADER_COLS.PHONE_NUMBER,
          HEADER_COLS.PAX,
        ];
      }

      // Ending + Extra Columns
      // base.push(HEADER_COLS.NOTES);
      base.push(HEADER_COLS.SOCIAL_RESPONSIBILITIES);

      /****
       * COMPILE ROWS AND STUFF
       * ****/
      const dataRows = this._computeSupplierListRows(dbkeys).reduce((arr, item) => [...arr, ...item], []);

      if (!dataRows[0]) {
        // No Suppliers found
        this.$root.$error(`No ${dbkeys.join(",")} found`);
        this.loading = false;
        return;
      }

      const socialResponsibilites = dataRows.filter((row) => row.at(-1) !== "");
      if (socialResponsibilites.length === 0) {
        base.pop();
        dataRows.forEach((row) => row.pop());
      }

      const columns = base.map((col) => col.value);
      const rows = [...header, columns, ...dataRows];

      base.forEach((col, i) => {
        config.push({ column: i + 1, options: { width: col.width } });
        config.push({ row: header.length + 1, cell: i + 1, options: HEADER_COLS.DEFAULT_CONFIG });
      });

      const websiteCol = base.lastIndexOf(HEADER_COLS.WEBSITE);

      dataRows.forEach((row, i) =>
        row.forEach((col, j) => {
          let options = SUPPLIER_COLS.DEFAULT_CONFIG;

          if (j === websiteCol && typeof col === "string" && col.trim() !== "")
            options = { ...options, value: { text: col, hyperlink: col } };

          config.push({ row: header.length + 2 + i, cell: j + 1, options });
        })
      );

      const fileName = `${booking.client_name}_${booking.group_name}-${new Date().getTime()}`;

      ExportService({ fileName, rows, config })
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    _computeSupplierListRows(dbkeys) {
      const booking = this.$store.getters["BookingStore/active"];
      const dayInfo = this.$store.getters["BookingDayStore/days"];
      const supplierMapping = this.$store.getters["BookingDayStore/suppliers"];

      let addedMapping = {};

      let hotelConsecutiveNights = {};

      if (dbkeys.includes("hotels")) {
        let currentDay = 0;
        dayInfo.forEach(({ hotels }, i) => {
          let changed = false;
          if (!hotels || hotels.length == 0) {
            currentDay = i;
            return;
          }
          if (i == 0) {
            hotelConsecutiveNights[currentDay] = hotels
              .map((hotel) => {
                const hotelId = supplierMapping[hotel].supplier_id;
                return { [hotelId]: 1 };
              })
              .reduce(
                (previous, current) => ({
                  ...previous,
                  ...current,
                }),
                {}
              );
            return;
          }

          hotels.forEach((hotel, j) => {
            const hotelId = supplierMapping[hotel].supplier_id;

            // added in the same day
            if (currentDay === i && hotelId in hotelConsecutiveNights[currentDay]) return;

            if (currentDay in hotelConsecutiveNights && hotelId in hotelConsecutiveNights[currentDay]) {
              hotelConsecutiveNights[currentDay][hotelId] += 1;
              changed = true;
            } else {
              if (i in hotelConsecutiveNights)
                hotelConsecutiveNights[i] = { ...hotelConsecutiveNights[i], [hotelId]: 1 };
              else {
                hotelConsecutiveNights[i] = { [hotelId]: 1 };
                currentDay = i;
              }
            }
          });

          if (!changed) currentDay = i;
        });
      }

      let addedHotel = {};

      return dayInfo.map((day, dayIndex) => {
        let o = [],
          sup;

        SuppliersMenu.FULL.forEach((config) => {
          if (!day[config.alt]) return;
          if (!dbkeys.includes(config.alt)) return;

          day[config.alt].forEach((id, index) => {
            if (!dbkeys.includes("hotels") && addedMapping[id]) return;

            sup = supplierMapping[id];

            // Compile Date, City, and Supplier name (for EVERY SUPPLIER)
            // Repeating flow in above function to ensude proper order of things
            let base = [SUPPLIER_COLS.DATE(day), SUPPLIER_COLS.CITY(sup), SUPPLIER_COLS.SUPPLIER_NAME(sup)];

            // Export includes Hotels -- ADD KEYS
            if (dbkeys.includes("hotels")) {
              const hotelIdKey = `${sup.supplier_id}`;

              if (sup.type_as == "HOTEL" && addedHotel[hotelIdKey]) return;

              base = [
                SUPPLIER_COLS.DATE(day),
                SUPPLIER_COLS.HOTEL_NIGHT(hotelConsecutiveNights?.[dayIndex]?.[sup.supplier_id] ?? "-"),
                SUPPLIER_COLS.CITY(sup),
                SUPPLIER_COLS.SUPPLIER_NAME(sup),
              ];
              base.push(SUPPLIER_COLS.ADDRESS(sup), SUPPLIER_COLS.PHONE_NUMBER(sup), SUPPLIER_COLS.WEBSITE(sup));

              if (sup.type_as == "HOTEL") addedHotel[hotelIdKey] = true;
            }

            // Exporting NOT JUST hotels
            if (!dbkeys.includes("hotels") || (dbkeys.includes("hotels") && dbkeys.length > 1)) {
              base.push(SUPPLIER_COLS.PRODUCTS(sup));
            }

            if (dbkeys.includes("excursions")) {
              base.push(SUPPLIER_COLS.TIME(sup), SUPPLIER_COLS.PAX(this.$store.getters["BookingStore/pax_num"]));
            }

            // Export Includes Restaurants -- ADD KEYS
            if (dbkeys.includes("restaurants")) {
              base.push(SUPPLIER_COLS.MENU(sup));
            }

            if (!dbkeys.includes("hotels") && (dbkeys.includes("excursions") || dbkeys.includes("restaurants"))) {
              base.push(SUPPLIER_COLS.ADDRESS(sup), SUPPLIER_COLS.PHONE_NUMBER(sup));
            }

            if (dbkeys.length === 1 && dbkeys.includes("tour_guides")) {
              let firstDay = this.$store.getters["BookingDayStore/firstDaySupplier"](config.alt, id);
              let lastDay = this.$store.getters["BookingDayStore/lastDaySupplier"](config.alt, id);
              // OVERWRITE BASE
              base = [
                SUPPLIER_COLS.DATE(firstDay),
                SUPPLIER_COLS.CITY(sup),
                SUPPLIER_COLS.DATE(lastDay),
                SUPPLIER_COLS.CITY(sup),
                SUPPLIER_COLS.SUPPLIER_NAME(sup),
                SUPPLIER_COLS.PHONE_NUMBER(sup),
                SUPPLIER_COLS.PAX(this.$store.getters["BookingStore/pax_num"]),
              ];
            }

            base.push(SUPPLIER_COLS.SOCIAL_RESPONSIBILITIES(sup));

            // Ending + Extra Columns
            // base.push(SUPPLIER_COLS.NOTES(booking));
            const result = base.map((current) => current.value);

            o.push(result);
            addedMapping[id] = true;
          });
        });

        return o;
      });
    },
  },
};
</script>
