export default {
  format,
  phoneDisplay,
};

function format(value, pattern) {
  if (!pattern) return value;
  if (!value) return value;

  var i = 0,
    v = value.toString();
  return pattern.replace(/#/g, (_) => v[i++]);
}

function phoneDisplay(value, pattern, country) {
  if (!value) return {};
  if (typeof value === "object") {
    // Clean phone number if still number format
    if (value.phone && !isNaN(value.phone)) value.phone = format(value.phone, pattern);

    return value;
  }
  if (value.startsWith("{") && value.endsWith("}")) return JSON.parse(value);

  let val = {};
  let extract = `${value}`;

  // START of number
  let foundCode = extract.match(/\+\S*/g);
  if (foundCode) {
    val.code = foundCode[0].replace(/\+/g, "");
    extract = extract.replace(foundCode[0], "");
  } else if (country && country === "US") {
    val.code = "1";
  }

  // END of number
  let foundExtention = extract.match(/e\D*\d*$/g);
  if (foundExtention) {
    // Strip letters
    val.ext = foundExtention[0].replace(/\D/g, "");
    extract = extract.replace(foundExtention[0], "");
  }

  val.phone = isNaN(extract) ? extract || null : format(extract, pattern);

  return val;
}
