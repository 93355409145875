<template>
  <v-dialog :value="value" persistent max-width="500">
    <v-card>
      <div class="title pa-5">{{ dayTitle.day }}: {{ dayTitle.date }}</div>

      <div class="mx-5">
        <a-text-field label="City" v-model="updateCity" />
      </div>

      <v-card-actions>
        <g-button label="Cancel" @onclick="$emit('input', false)" />
        <div class="flex-grow-1"></div>
        <g-button type="primary" label="Update" @onclick="update" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    editCityDay: Object,
  },
  data() {
    return {
      updateCity: null,
    };
  },
  watch: {
    value() {
      this.updateCity = this.$store.getters["BookingDayStore/dayCity"](this.dayIndex);
    },
  },
  computed: {
    dayIndex() {
      return (this.editCityDay || {}).day - 1 || 0;
    },
    dayTitle() {
      return this.$store.getters["BookingDayStore/day_name"](this.dayIndex) || { day: "Day ?", date: "" };
    },
    // displayCityName(){
    // 	return this.updateCity || this.$store.getters['BookingDayStore/dayCity'](this.dayIndex)
    // }
  },
  methods: {
    update() {
      this.$store
        .dispatch("BookingDayStore/update", {
          booking_id: this.$store.getters["BookingStore/booking_id"],
          day_number: this.dayIndex,
          key: "city",
          val: this.updateCity,
        })
        .then(() => {
          this.$emit("input", false);
          this.$root.$success("Updated city");
        })
        .catch((err) => this.$root.$error(err));
    },
  },
};
</script>
