<template>
  <v-row class="px-2">
    <v-col class="pa-0">
      <div class="pr-5 py-2">
        <TotalWrapper
          :showConvert="showConverstion"
          :formatedTotal="price.price_print"
          :convertedTotal="price.priceConvert_print"
        />
        <div v-if="showKind" class="overline">* {{ product.meta.rate_kind }}</div>
      </div>

      <v-row justify="start" align="center" v-if="usingOldDate">
        <v-icon small left color="warning">warning</v-icon>
        <span class="caption warning--text">Rate from: {{ product.rate_year }}</span>
      </v-row>
    </v-col>

    <v-col cols="4" class="pa-0">
      <PriceConfirm
        :env="env"
        :supplierType="type"
        :productType="product.product_type"
        :saved="internalCustom"
        :disabled="disabled"
        sync
        @set="setCustom"
      />
    </v-col>

    <v-col class="px-2 py-2" cols="3">
      <v-row v-if="taxShow" class="pa-0" justify="space-between">
        <span class="subtitle-2">+ Tax</span>
        <span>{{ taxShow }} %</span>
      </v-row>
      <v-row v-if="taxInclusiveShow" class="pa-0" justify="space-between">
        <span class="subtitle-2">* Tax Inclusive</span>
      </v-row>
      <v-row v-if="customTaxShow" class="pa-0" justify="space-between">
        <span class="subtitle-2">+ Custom Tax</span>
        <span>{{ customTaxShow }} %</span>
      </v-row>
      <v-row v-if="occupancyShow" class="pa-0" justify="space-between">
        <span class="subtitle-2">+ Occupancy</span>
        <TotalWrapper
          :showConvert="showConverstion"
          :formatedTotal="occupancyShow.print"
          :convertedTotal="occupancyShow.convert"
        />
      </v-row>
      <v-row v-if="customOccupancyShow" class="pa-0" justify="space-between">
        <span class="subtitle-2">+ Custom Occupancy</span>
        <TotalWrapper
          :showConvert="showConverstion"
          :formatedTotal="customOccupancyShow.print"
          :convertedTotal="customOccupancyShow.convert"
        />
      </v-row>
      <v-row v-if="resortFeeShow" class="pa-0" justify="space-between">
        <span class="subtitle-2">+ Resort Fee</span>
        <TotalWrapper
          :showConvert="showConverstion"
          :formatedTotal="resortFeeShow.print"
          :convertedTotal="resortFeeShow.convert"
        />
      </v-row>
      <v-row>
        <v-col class="pa-0">
          <v-row justify="space-between" v-for="(extra, index) in extraList" :key="index" class="pa-0">
            <span class="subtitle-2">+ {{ extraRef[extra.key].label }}</span>
            <span v-if="extra.astype === 'COST'">{{ extra.value.cur }} {{ extra.value.value }}</span>
            <span v-if="extra.astype === 'PERCENT'">{{ extra.value }} %</span>
          </v-row>
        </v-col>
      </v-row>
    </v-col>

    <v-col class="subtitle-2">
      <TotalWrapper
        v-if="internalCustom"
        class="px-2 py-1 ring"
        :showConvert="showConverstion"
        :formatedTotal="internalCustom.totalPrice_print"
        :convertedTotal="internalCustom.totalConvert_print"
      />

      <TotalWrapper
        v-else
        class="ring grey lighten-3 px-2 py-1"
        :showConvert="showConverstion"
        :formatedTotal="price.totalPrice_print"
        :convertedTotal="price.totalConvert_print"
      />
    </v-col>
  </v-row>
</template>

<script>
import Vue from "vue";
import money from "@/services/money";
import { ProductService } from "@/modules/products";
import EXTRA_OPTIONS from "@/config/products/EXTRA_OPTIONS";
import PriceConfirm from "./pricerow/_PriceConfirm";
import TotalWrapper from "./pricerow/_TotalWrapper";
export default {
  props: {
    type: String, // Supplier type
    country: String, // Country
    disabled: Boolean,

    // Product specific stuff
    showKind: Boolean,
    product: Object,

    price: Object,
    supplierMeta: Object,
    custom: Object,

    count: [Number, String],
    bookingSupplierMeta: Object,
    bookingDayYear: String,
  },
  components: {
    PriceConfirm,
    TotalWrapper,
  },
  data() {
    return {
      internalPrice: {},
      internalCustom: null,

      extraRef: EXTRA_OPTIONS,
    };
  },
  watch: {
    custom(v) {
      this.internalPrice = v;
    },
  },
  computed: {
    usingOldDate() {
      return `${this.product.rate_year}` !== this.bookingDayYear;
    },

    env() {
      return this.$store.getters["AccountStore/environment"];
    },
    showConverstion() {
      return this.$store.getters["AccountStore/environment"] !== "USA";
    },

    taxShow() {
      if (this.taxInclusiveShow || this.customTaxShow) return;

      return this.supplierMeta && this.supplierMeta.tax;
    },
    taxInclusiveShow() {
      return this.product.tax_inclusive;
    },
    customTaxShow() {
      if (this.taxInclusiveShow) return null;
      if (this.product.meta && this.product.meta.custom_tax) {
        return this.product.meta.custom_tax;
      }
      return null;
    },
    occupancyShow() {
      if (!this.supplierMeta) return null;
      if (this.product.meta && this.product.meta.custom_occupancy) return null;
      if (this.product.product_type !== "ROOM") return null;

      const occupancy = this.supplierMeta.occupancy_rate;
      if (!occupancy || Number(occupancy.value) === 0) return null;
      if (occupancy) {
        let print = `${occupancy.cur === "USD" ? "" : occupancy.cur}${occupancy.value}`;
        let convert = money.getConvertedValue(occupancy.value, this.country, this.$store.getters);
        return {
          print: print,
          convert: ProductService.formatConvert(convert),
        };
      }

      return null;
    },
    customOccupancyShow() {
      if (this.$store.getters["AccountStore/environment"] !== "JPN") return null;
      if (this.product.product_type !== "ROOM") return null;

      const occupancy = this.product.meta && this.product.meta.custom_occupancy;
      if (occupancy) {
        let print = `${occupancy.cur}${occupancy.value}`;
        let convert = money.getConvertedValue(occupancy.value, this.country, this.$store.getters);
        return {
          print: print,
          convert: ProductService.formatConvert(convert),
        };
      }

      return null;
    },
    resortFeeShow() {
      if (this.product.product_type !== "ROOM") return null;

      let resort_fee = null;
      if (this.bookingSupplierMeta.has_custom_resortfee) {
        resort_fee = this.bookingSupplierMeta.custom_resortfee_value;
      } else if (!this.supplierMeta || this.extraList.find((i) => i.key === "extra_resort_fee")) {
        return null;
      } else {
        resort_fee = this.supplierMeta.resort_fee;
      }

      if (resort_fee && Number(resort_fee.value) === 0) {
        return null;
      } else if (resort_fee) {
        let print = `${resort_fee.value}`;
        let convert = money.getConvertedValue(resort_fee.value, this.country, this.$store.getters);
        return {
          print: print,
          convert: ProductService.formatConvert(convert),
        };
      }

      return null;
    },

    extraList() {
      const meta = this.product.meta;
      if (!meta) return [];

      return Object.keys(meta)
        .filter((v) => v.includes("extra_"))
        .map((key) => {
          return {
            key,
            ...meta[key],
          };
        });
    },

    productCount() {
      return this.count || 1;
    },
  },
  methods: {
    setCustom(value, skipEmit) {
      const product = this.product;
      const productId = product.id || product.product_id;
      // Revert
      if (!value) {
        this.internalCustom = null;
        if (!skipEmit) this.$emit("clear");
        return;
      }

      const price = ProductService.packagePrice(
        { ...product },
        value,
        this.supplierMeta,
        this.country,
        false,
        this.$store.getters,
        true,
        this.bookingSupplierMeta
      );

      this.internalCustom = price;
      if (!skipEmit) this.$emit("set", price.price);
    },
  },
  mounted() {
    this.internalPrice = this.price;
    if (this.custom) {
      this.setCustom(this.custom, true);
    }
  },
};
</script>

<style lang="scss">
.custom-price-row {
  &:focus-within {
    color: #1976d2;
  }
}
</style>
