import excel from "simple-excel-to-json";

export default {
  extractData,
  message,
};

function extractData(filePath) {
  // Extract the data from the file
  const excelParser = new excel.XlsParser();
  let parsed = excelParser.parseXls2Json(filePath)[0];

  // Strip and group it
  let MAX_QUERY = 30; // Add this many per query
  let masterGroup = [];
  let tempGroup = [];
  let sizeCount = 0;
  parsed.forEach((item) => {
    // Assuming every supplier has a name
    if (item.NAME) {
      sizeCount += 1;
      tempGroup.push(item);
    }

    // When reach the maximum, add to the maing group
    // Reset for new group
    if (tempGroup.length === MAX_QUERY) {
      masterGroup.push(tempGroup);
      tempGroup = [];
    }
  });

  // Add any stragglers
  if (tempGroup.length > 0) {
    masterGroup.push(tempGroup);
  }

  return {
    data: masterGroup,
    size: sizeCount,
  };
}

function message(number, size) {
  return `Uploaded ${number} of ${size}`;
}
