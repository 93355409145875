<template>
  <g-button class="mx-2" label="Export" icon="get_app" :loading="loading" @onclick="exportData" />
</template>

<script>
import _ from "lodash";
import moment from "moment";
import format from "@/services/format";
import SUPMENU from "@/config/menus/suppliersMenu";
import { HEADER_COLS, BOOKING_COLS, SUPPLIER_COLS, ExportService } from "@/modules/exports";
import PhoneService from "@/components/form/phones/phoneService";
import PHONE_MASK from "@/config/PHONE_MASK";
import { BookingRoomService } from "@/modules/bookings.rooms";
export default {
  props: {
    searchQuery: Object,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    disabled() {
      return this.searchQuery.byStatus;
    },
    flatten() {
      if (
        this.searchQuery &&
        this.searchQuery.supplier_type &&
        ["TOUR_GUIDE", "TRANSPORT"].includes(this.searchQuery.supplier_type)
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    exportData() {
      const byStatus = this.searchQuery.byStatus;

      this.loading = true;
      this.$store
        .dispatch("BookingStore/getBookings", {
          page: 0,
          query: this.searchQuery,
          extra: {
            ignoreInfinite: true,
            attachRooms: true,
            limit: false,
          },
        })
        .then((rows) => {
          this.loading = false;

          /****
           * HEADER STUFF
           * ****/
          const listTypeTitle = ["List of Bookings"];

          const config = [
            { row: 2, options: { height: 40 } },
            { row: 2, cell: 1, options: { font: { name: "Calibri", size: 20 } } },
          ];

          const extractedData = byStatus ? this._getBookingByStatusExport(rows) : this._getBaseBookingExport(rows);

          const header = [[], listTypeTitle, []];
          const columns = extractedData.base.map((col) => col.value);
          const data = [...header, columns, ...extractedData.csvRows];

          extractedData.base.forEach((col, i) => {
            config.push({ column: i + 1, options: { width: col.width } });
            config.push({ row: header.length + 1, cell: i + 1, options: HEADER_COLS.DEFAULT_CONFIG });
          });

          extractedData.csvRows.forEach((row, i) =>
            row.map((col, j) =>
              config.push({ row: header.length + 2 + i, cell: j + 1, options: BOOKING_COLS.DEFAULT_CONFIG })
            )
          );

          const fileName = `bookings-${new Date().getTime()}.xlsx`;

          return ExportService({ fileName: fileName, rows: data, config: config });
        })
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$root.$error(err);
        });
    },

    // MAIN BOOKING EXPORT
    _getBaseBookingExport(rows) {
      return {
        base: [
          HEADER_COLS.CLIENT,
          HEADER_COLS.GROUP_NAME,
          HEADER_COLS.CITY_START,
          HEADER_COLS.CITY_END,
          HEADER_COLS.DATE_START,
          HEADER_COLS.DATE_END,
          HEADER_COLS.PAX,
        ],
        csvRows: rows.map((item) => this._getBookingRow(item)),
      };
    },
    _getBookingRow(item) {
      if (typeof item.data === "string") item.data = item.data ? JSON.parse(item.data) : {};

      const pax = item.data && item.data.pax_num;
      //BookingRoomService.getPaxCount(item.data && item.data.room_num, JSON.parse(item.rooming_data || '[]'))

      return [
        BOOKING_COLS.DEFAULT_STRING(item.client_name),
        BOOKING_COLS.DEFAULT_STRING(item.group_name),
        BOOKING_COLS.DEFAULT_STRING(item.where_start),
        BOOKING_COLS.DEFAULT_STRING(item.where_end),
        BOOKING_COLS.DEFAULT_STRING(item.from),
        BOOKING_COLS.DEFAULT_STRING(item.to),
        BOOKING_COLS.PAX(pax || 0),
      ].map((current) => current.value);
    },

    // Exact same functions as in Booking Table
    // But need to caculate it here
    // TO DO? Merge into service
    _getBookingByStatusExport(rows) {
      // Make by status row
      let dump = [],
        trailStart;
      rows.forEach((booking) => {
        trailStart = [
          // BOOKING_COLS.DEFAULT_STRING(booking.client_name),
          BOOKING_COLS.DEFAULT_STRING(booking.group_name),
          // BOOKING_COLS.DEFAULT_STRING(booking.where_start),
          // BOOKING_COLS.DEFAULT_STRING(booking.where_end),
        ];

        let days = this._getFilteredDays(booking);
        let rowExtract = this._computeSupplierListRows(days, booking.booking_suppliers)
          .reduce((arr, item) => [...arr, ...item], [])
          .map((item) => [...trailStart, ...item]);

        // if (!this.flatten && rowExtract.length) {
        //   // Set notes of the booking to be the FIRST line only
        //   let temp = rowExtract[0];
        //   temp[temp.length - 1] = (booking.notes || "").replaceAll("-", ";").replace(/(\r\n|\n|\r)/gm, "");
        // }

        const rowExtractValue = rowExtract.map((row) =>
          row.map((current) =>
            current.value && current.value !== null ? current.value : typeof current === "string" ? current : ""
          )
        );
        dump = dump.concat(rowExtractValue);
      });

      let base = [
        // HEADER_COLS.CLIENT,
        HEADER_COLS.GROUP_NAME,
        // HEADER_COLS.CITY_START,
        // HEADER_COLS.CITY_END,
      ];
      let headers = this.flatten
        ? [
            ...base,
            HEADER_COLS.DATE_START,
            HEADER_COLS.DATE_END,
            // HEADER_COLS.SUPPLIER_NAME,
            HEADER_COLS.PRODUCTS,
          ]
        : [
            ...base,
            HEADER_COLS.DATE,
            HEADER_COLS.TIME,
            HEADER_COLS.PAX,
            // HEADER_COLS.CITY,
            // HEADER_COLS.SUPPLIER_NAME,
            HEADER_COLS.PRODUCTS,
            // HEADER_COLS.NOTES,
          ];

      if (this.searchQuery.supplier_type === "HOTEL") {
        headers.push(HEADER_COLS.ADDRESS, HEADER_COLS.PHONE_NUMBER, HEADER_COLS.WEBSITE);
      }

      if (this.searchQuery.supplier_type === "TOUR_GUIDE") {
        headers.push(HEADER_COLS.TOUR_GUIDE);
      }

      if (["RESTAURANT", "EXCURSION"].includes(this.searchQuery.supplier_type)) {
        headers.push(HEADER_COLS.ADDRESS, HEADER_COLS.PHONE_NUMBER);
      }

      return {
        base: headers,
        csvRows: dump,
      };
    },
    _getFilteredDays(rowData) {
      let dayList = rowData.days
        .map((day, index) => {
          // Need day index (otherwise will be filted out)
          return {
            dayIndex: index,
            ...day,
          };
        })
        .filter((dayInfo) => {
          // Only show days where suppliers exist
          let hasSupplier = false;
          SUPMENU.DBKEY.forEach((item) => {
            dayInfo[item].forEach((bsid) => {
              if (rowData.booking_suppliers[bsid]) hasSupplier = true;
            });
          });
          return hasSupplier;
        });

      // Flatten and group blobs
      if (this.flatten) {
        let semigroupded = {};
        let ref = {
          TOUR_GUIDE: "tour_guides",
          TRANSPORT: "transport",
        };

        dayList.forEach((day) => {
          // Right now, focus on tourguides
          day[ref[this.searchQuery.supplier_type]].forEach((bsid) => {
            if (!semigroupded[bsid]) {
              semigroupded[bsid] = {
                type: ref[this.searchQuery.supplier_type],
                startDay: day.date, // ASSUMING IN ORDER
                startIndex: day.dayIndex,
                dateList: [],
                dayIndexList: [],
              };
            }

            semigroupded[bsid].dateList.push(day.date);
            semigroupded[bsid].dayIndexList.push(day.dayIndex);
          });
        });

        // Merge booking supplier key
        dayList = Object.keys(semigroupded).map((bsid) => {
          return {
            ...semigroupded[bsid],
            [semigroupded[bsid].type]: [bsid],
          };
        });
      }

      return dayList;
    },
    _computeSupplierListRows(dayInfo, supplierMapping) {
      return dayInfo.map((day) => {
        let o = [],
          sup;
        SUPMENU.FULL.forEach((config) => {
          if (!day[config.alt]) return;
          if (this.searchQuery.supplier_type !== "ALL" && this.searchQuery.supplier_type !== config.module) return;

          day[config.alt].forEach((id) => {
            let nested = [];

            sup = supplierMapping[id];
            if (this.flatten) {
              Object.values(sup.products).forEach((product) => {
                nested.push(
                  SUPPLIER_COLS.DATE(day.dateList[0]),
                  SUPPLIER_COLS.DATE(day.dateList[day.dateList.length - 1]),
                  // SUPPLIER_COLS.SUPPLIER_NAME(sup),
                  SUPPLIER_COLS.DEFAULT(product.name)
                );
                if (this.searchQuery.supplier_type == "TOUR_GUIDE") {
                  nested.push(SUPPLIER_COLS.DEFAULT(sup.name));
                }
              });
            } else {
              const pax = sup.meta.count && sup.meta.count !== null ? Object.values(sup.meta.count).at(0) : undefined;
              // console.log(Object.values(sup.products).map((p) => p.name));
              nested.push(
                this.flatten
                  ? SUPPLIER_COLS.DEFAULT(format.compileDateListReadable(day.dateList))
                  : SUPPLIER_COLS.DATE(day),
                SUPPLIER_COLS.TIME(sup),
                SUPPLIER_COLS.PAX(pax),
                // SUPPLIER_COLS.CITY(sup),
                // SUPPLIER_COLSSUPPLIER_NAME(sup),
                SUPPLIER_COLS.PRODUCTS(sup)
                // SUPPLIER_COLS.DEFAULT(null)
              );
            }

            // Hotel Extra stuff
            if (this.searchQuery.supplier_type === "HOTEL") {
              const website = sup.website || "";
              const address = `${[sup.address, sup.subcity, sup.city, sup.subjar, sup.country]
                .filter((i) => i)
                .join(",")} ${sup.zip_code || ""}`;

              let ptem = PhoneService.phoneDisplay(sup.primary_phone_number, PHONE_MASK(sup.country || "US")[0], "US");
              const phone = [ptem.code ? `+${ptem.code}` : "", ptem.phone, ptem.ext ? `ext: ${ptem.ext}` : ""]
                .join(" ")
                .trim();

              nested.push(SUPPLIER_COLS.ADDRESS(sup), SUPPLIER_COLS.PHONE_NUMBER(sup), SUPPLIER_COLS.WEBSITE(sup));
            }

            if (["RESTAURANT", "EXCURSION"].includes(this.searchQuery.supplier_type)) {
              nested.push(SUPPLIER_COLS.ADDRESS(sup), SUPPLIER_COLS.PHONE_NUMBER(sup));
            }

            if (nested.length === 0) return;

            o.push(nested);
          });
        });

        return o;
      });
    },
  },
};
</script>
