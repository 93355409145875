<template>
  <div>
    <v-btn text icon x-small @click="toggle" :loading="loading">
      <img :src="imageSource" style="height: 24px; width: 24px" />
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    selected: Number,
    type: String,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    imageSource() {
      switch (this.selected) {
        case 3:
          return require("@/assets/img/reference.png");
        case 2:
          return require("@/assets/img/favorite.png");
        case 1:
          return require("@/assets/img/semi_favorite.png");
        default:
          return require("@/assets/img/non_favorite.png");
      }
    },
  },
  methods: {
    toggle(e) {
      e.stopPropagation();

      this.loading = true;
      var newValue = this.selected + 1;
      if (newValue > 3) newValue = 0;

      if (this.type == "supplier") {
        this.$store
          .dispatch("SupplierStore/updateSupplierById", {
            supplierId: this.id,
            data: {
              favorite: newValue,
            },
            cache: {
              favorite: this.selected,
            },
          })
          .then((v) => {
            this.loading = false;
            this.$emit("update", newValue);
          })
          .catch((err) => this.$root.$error(err));
      } else if (this.type == "quote") {
        this.$store
          .dispatch("QuoteStore/updateQuoteById", {
            quote_id: this.id,
            data: { quote: { favorite: newValue } },
          })
          .then((v) => {
            this.loading = false;
            this.$emit("update", newValue);
          })
          .catch((err) => this.$root.$error(err));
      } else {
        // Do nothing
      }
    },
  },
};
</script>
