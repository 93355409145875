<template>
  <v-menu open-on-hover offset-y top offset-overflow style="box-shadow: none">
    <template v-slot:activator="{ on }">
      <div v-on="on">
        <slot name="activator"></slot>
      </div>
    </template>

    <div class="hover-menu">
      <slot></slot>
    </div>
  </v-menu>
</template>

<script>
export default {
  props: {
    icon: Boolean,
  },
};
</script>

<style lang="scss">
.hover-menu {
  background: rgba(97, 97, 97, 0.8);
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  display: block;
  padding: 5px 16px;
  text-transform: none;
  width: auto;
  opacity: 1;

  &:hover {
    cursor: pointer;
  }
}
</style>
