<template>
  <v-form class="white">
    <v-row>
      <v-col>
        <a-select
          widelabel
          label="Supplier Type *"
          :items="supplierTypes"
          :value="value.supplier_type"
          @input="(v) => $emit('input', { cell: 'supplier_type', value: v })"
        />
      </v-col>
      <v-col>
        <a-field
          req
          :env="env"
          :src="value.supplier_type"
          :config="config.country"
          :value="value.country"
          @input="(v) => $emit('input', { cell: 'country', value: v })"
        />
      </v-col>
      <v-col>
        <a-field
          class="s-field"
          v-if="value.type === 'TOUR_GUIDE'"
          :config="config.op_area"
          v-caps
          req
          :value="value.op_area"
          @input="(v) => $emit('input', { cell: 'op_area', value: v })"
        />
        <a-field
          class="s-field"
          v-else
          req
          :config="config.region"
          :country="value.country"
          :dependantValue="value.country"
          :value="value.region"
          @input="(v) => $emit('input', { cell: 'region', value: v })"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { SupplierFields } from "@/config/fields/suppliers";
import { SuppliersMenu } from "@/config/menus";

export default {
  props: {
    value: Object,
  },
  data() {
    return {
      config: SupplierFields,
    };
  },
  computed: {
    env() {
      return this.$store.getters["AccountStore/environment"];
    },
    validRegion() {
      return (this.value.region && this.value.region !== "NAT") || this.value.type === "TOUR_GUIDE";
    },
    supplierTypes() {
      var list = [];
      SuppliersMenu.FULL.forEach((item) => {
        if (item.module === "ALL") return;

        list.push({
          label: item.title,
          value: item.module,
        });
      });
      return list;
    },
  },
};
</script>
