export default {
  CLIENT_PAID: [
    {
      label: "ALL",
      value: null,
    },
    {
      label: "Paid",
      value: "PAID",
    },
    {
      label: "Part Paid",
      value: "PART",
    },
    {
      label: "Not Paid",
      value: "NOT_PAID",
    },
  ],

  CHARGE: [
    {
      label: "ALL",
      value: null,
    },
    {
      label: "Reconciled",
      value: 1,
    },
    {
      label: "Not Reconciled",
      value: 0,
    },
  ],

  STATUS: [
    {
      label: "None",
      value: "REC_NONE",
    },
    {
      label: "Part",
      value: "REC_PART",
    },
    {
      label: "100%",
      value: "REC_ALL",
    },
  ],

  RECONCILED: [
    {
      label: "Reconciled",
      value: 1,
    },
    {
      label: "Not Reconciled",
      value: 0,
    },
  ],
};
