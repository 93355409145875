<template>
  <a-group icon="alarm" :label="label">
    <v-row class="a-field">
      <v-col align-self="center" class="label smlabel">
        <span class="subtitle-2">{{ range || rangeAlways ? "From" : "On" }}</span>
      </v-col>
      <v-col align-self="center" class="py-0">
        <FTime
          :disabled="disabled"
          :class="{ 'f-date-style': maxwidth }"
          :value="value.from"
          @input="(v) => set('from', v)"
        />
      </v-col>
    </v-row>

    <v-row class="a-field" v-if="range || rangeAlways">
      <v-col align-self="center" class="label smlabel">
        <span class="subtitle-2">To</span>
      </v-col>
      <v-col align-self="center" class="py-0">
        <FTime
          :disabled="disabled"
          :class="{ 'f-date-style': maxwidth }"
          :value="value.to"
          @input="(v) => set('to', v)"
        />
      </v-col>
    </v-row>

    <div class="d-flex align-center mr-3" v-if="!rangeAlways">
      <input :disabled="disabled" type="checkbox" v-model="range" @change="setDate" />
      <span class="caption ml-2">Range</span>
    </div>
  </a-group>
</template>

<script>
import FTime from "../items/FTime";
export default {
  components: {
    FTime,
  },
  props: {
    value: Object,
    label: String,

    disabled: Boolean,
    maxwidth: Boolean,
    rangeAlways: Boolean,
  },
  data() {
    return {
      range: false,
      until: "",
    };
  },
  methods: {
    set(key, val) {
      let t = this.value ? { ...this.value } : {};
      this.$emit("input", {
        from: key === "from" ? val : t.from,
        to: this.range || this.rangeAlways ? (key === "to" ? val : t.to) : null,
        range: this.range || this.rangeAlways,
      });
    },
    setDate() {
      let t = this.value ? { ...this.value } : {};
      if (this.range || this.rangeAlways) t.to = this.value.from;
      if (!this.range) t.to = null;
      this.$emit("input", t);
    },
  },
};
</script>

<style lang="scss">
.f-date-style {
  max-width: 120px;
}
</style>
