<template>
  <div class="caption">
    <ol>
      <li>Log into <a href="https://service.supernature.travel/cpanel" target="_blank">cpanel</a></li>
      <li>Go to (or search): Email Accounts</li>
      <li>Click: + Create</li>
      <li>Create Account</li>
    </ol>
    <v-row justify="end" class="mx-0">
      <g-button type="primary" label="Done" @onclick="$emit('next')" />
    </v-row>
  </div>
</template>

<script>
export default {};
</script>
