<template>
  <a-card nopad>
    <v-row v-for="(supplier, key) in supplierListRows" :key="key">
      <v-col style="max-width: 160px" align-self="center">
        <a-truncate v-if="!supplier.hideDate" :text="dayTitle" />
      </v-col>
      <v-col style="max-width: 60px" align-self="center">
        <v-icon small>{{ supplier.icon }}</v-icon>
        <span v-if="supplier.data.nest_type === 'ALT'" class="caption pl-1">/ALT</span>
      </v-col>

      <v-col>
        <SupplierBlock
          class="color-alt"
          :data="supplier.data"
          :type="supplier.type"
          :date="filteredDate"
          :bookingDay="allDaysExtractForOneDay"
          :bookingSuppliers="booking.all_booking_suppliers"
          :bookingSupplierRoomList="booking.supplier_rooms"
          :pax="pax_num"
          :roomBreakdownData="roomBreakdownData"
          status
          outside
          @altstatus="(data) => $emit('altstatus', { supplier: supplier.data, type: supplier.type })"
          @toggleRoomReceived="(v) => (supplier.data.rooming_received = !supplier.data.rooming_received)"
          @editRoomName="(v) => $emit('editRoomName', supplier.data)"
          @editConfirm="$emit('editConfirm', supplier.data)"
        />
      </v-col>
    </v-row>
  </a-card>
</template>

<script>
import format from "@/services/format";
import { SupplierBlock } from "@/modules/bookings.days";
import SUPMENU from "@/config/menus/suppliersMenu";
import { BookingRoomService } from "@/modules/bookings.rooms";

export default {
  props: {
    dayIndex: Number,
    dayInfo: Object,
    booking: Object,
    supplierMapping: Object,
    allSuppliers: Object,
    query: Object,

    flatten: Boolean,
  },
  components: {
    SupplierBlock,
  },
  data() {
    return {
      dayConfig: null,
      supplierListRows: {},
      roomBreakdownData: {},
    };
  },
  computed: {
    dayTitle() {
      if (this.flatten) {
        return `${format.compileDateListReadable(this.dayInfo.dateList)}`;
      }
      return `Day ${this.dayIndex + 1} (${format.formatDate(this.dayInfo.date)})`;
    },
    allDaysExtractForOneDay() {
      return this.booking.all_days.find((i) => i.date === this.filteredDate);
    },
    pax_num() {
      return this.booking.data && Number(this.booking.data.pax_num || 0);
      // return BookingRoomService.getPaxCount(this.booking.data && this.booking.data.room_num,
      //    		this.booking.rooming_data);
    },
    filteredDate() {
      return this.flatten ? this.dayInfo.dateList[0] : this.dayInfo.date;
    },
  },
  methods: {
    includeInDisplay(supid) {
      let sup = this.supplierMapping[supid];
      if (this.query.supplier_type && this.query.supplier_type === "HOTEL") {
        // Query returns suppliers with rooms assigned
        // But these aren't hotels, so hide them here
        if (["TRANSPORT", "TOUR_GUIDE", "RESTAURANT"].includes(sup.type_as)) return false;
      }
      return true;
    },
    supplierExists(dayKeyRow) {
      let found = false;
      dayKeyRow.forEach((id) => {
        if (!this.includeInDisplay(id)) return;
        if (this.supplierMapping[id]) found = true;
      });
      return found;
    },
    hasData(data) {
      return data ? data.length > 0 : false;
    },

    computeSupplierListRows() {
      let o = {},
        mockkey,
        sup;
      this.dayConfig.forEach((config, index) => {
        this.dayInfo[config.dbkey].forEach((id) => {
          if (!this.includeInDisplay(id)) return;

          mockkey = index + id;
          o[mockkey] = {
            hideDate: index > 0,
            title: config.title,
            type: config.type,
            icon: config.icon,
            data: this.supplierMapping[id],
          };
        });
      });

      return o;
    },
    _syncRooming() {
      this.roomBreakdownData = BookingRoomService.getRoomingListBreakdown({
        bookingMetaData: this.booking.data,
        bookingRoomingList: this.booking.rooming_data,
        bookingDays: this.booking.days,
        supplierRoomList: this.booking.supplier_rooms,
      });
    },
  },
  mounted() {
    this.dayConfig = SUPMENU.FULL.map((item) => {
      return {
        type: item.module,
        icon: item.icon,
        title: item.title,
        dbkey: item.alt,
      };
    }).filter((item) => {
      if (item.type === "ALL") return false;
      if (!this.dayInfo[item.dbkey]) return false;
      // Don't show if no suppliers
      if (!this.dayInfo[item.dbkey].length) return false;
      // Don't show if selected suppliers not in mapping
      return this.supplierExists(this.dayInfo[item.dbkey]);
    });

    this.supplierListRows = this.computeSupplierListRows();

    this._syncRooming();
    this.$roomingPing.$on("sync", this._syncRooming);
  },
  beforeDestroy() {
    this.$roomingPing.$off("sync", this._syncRooming);
  },
};
</script>
