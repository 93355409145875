import ProductWrapper from "./Products";

import ProductView from "./ProductView";
import PictureView from "./PictureView";

export default [
  {
    path: "/products/:id",
    component: ProductWrapper,
    children: [
      {
        path: "",
        name: "product_page",
        component: ProductView,
        meta: {
          showProductYear: true,
          tabAccess: "DATABASE",
        },
      },
      {
        path: "pictures",
        name: "product_pictures",
        component: PictureView,
        meta: {
          showProductYear: true,
          tabAccess: "DATABASE",
        },
      },
    ],
  },
];
