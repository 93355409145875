<template>
  <v-dialog :value="value" persistent max-width="500">
    <v-card>
      <v-card-title class="subtitle-2">Change Base Supplier Info</v-card-title>
      <v-card-text>
        <!-- 
    	<v-row class="my-4">
    		<elwrapper label="ID" widelabel>
    			{{supplierPid}}
    		</elwrapper>
    		<g-button type="primary" label="Reset" @onclick="$emit('resetId')" />
    	</v-row> -->

        <v-row class="my-4">
          <a-select widelabel label="Supplier Type" :items="items" v-model="supplierType" />
          <g-button type="primary" label="Update" @onclick="$emit('switch', supplierType)" />
        </v-row>
      </v-card-text>
      <v-card-actions>
        <g-button label="Cancel" @onclick="$emit('input', false)" />
        <div class="flex-grow-1"></div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { SuppliersMenu } from "@/config/menus";
export default {
  props: {
    value: Boolean,
    supplierPid: String,
    currentType: String,
  },
  data() {
    return {
      supplierType: "",
    };
  },
  computed: {
    items() {
      var list = [];
      SuppliersMenu.FULL.forEach((item) => {
        if (item.module === "ALL") return;

        list.push({
          label: item.title,
          value: item.module,
        });
      });
      return list;
    },
  },
  mounted() {
    this.supplierType = this.currentType;
  },
};
</script>
