import HistoryView from "./HistoryView";

export default [
  {
    path: "/app_history",
    name: "admin_history",
    component: HistoryView,
    meta: {
      tabAccess: "ADMIN",
    },
  },
];
