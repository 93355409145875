import DefaultView from "./DefaultView";

export default [
  {
    path: "/defaults",
    name: "admin_defaults",
    component: DefaultView,
    meta: {
      tabAccess: "ADMIN",
    },
  },
  {
    path: "/defaults_add",
    name: "admin_add_default",
    component: () => import("./NewDefaultView"),
    meta: {
      tabAccess: "ADMIN",
    },
  },
  {
    path: "/defaults/:default_id",
    name: "admin_edit_default",
    component: () => import("./EditDefaultView"),
    meta: {
      tabAccess: "ADMIN",
    },
  },
];
