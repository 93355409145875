<template>
  <a-card nopad>
    <div class="booking-title d-flex justify-space-between">
      <span>{{ labelRef }}</span>
      <span>({{ group.pax }} pax)</span>
    </div>
    <div class="mx-3 px-2">
      <div class="roomfieldlabels subtitle-2">
        <span class="fixed">First name</span>
        <span class="fixed">Last name</span>
        <span class="fixed"></span>
        <span>Requests</span>
      </div>
    </div>
    <div v-for="(g, index) in group.rooms" :key="index" class="roomrow mx-3 px-2">
      <div class="rtitle">
        <RoomIncludeToggle v-model="g.meta.cancelled" />
        <span class="subtitle-2" :class="{ cancelled: g.meta.cancelled }">{{ "Room " + (index + 1) }}</span>
      </div>
      <div class="namelist" :class="{ cancelled: g.meta.cancelled }">
        <div v-for="(name, j) in g.nameList" :key="j">
          <f-text class="namefield" v-model="name.first" v-uppercase />
          <f-text class="namefield" v-model="name.last" v-uppercase />

          <AboutRoomOccupant v-model="name.about" />
        </div>
      </div>
      <div :class="{ cancelled: g.meta.cancelled }">
        <f-textarea rows="1" v-model="g.meta.request" />
      </div>
      <div class="rdelete">
        <v-btn icon small @click="$emit('popRoom', g.index)">
          <v-icon small>clear</v-icon>
        </v-btn>
      </div>
    </div>
  </a-card>
</template>

<script>
import BOOKING_ROOMS from "@/config/BOOKING_ROOMS";
import AboutRoomOccupant from "./_AboutRoomOccupant";
import RoomIncludeToggle from "./_RoomIncludeToggle";
export default {
  components: {
    AboutRoomOccupant,
    RoomIncludeToggle,
  },
  props: {
    group: Object,
  },
  computed: {
    labelRef() {
      const type = BOOKING_ROOMS.find((item) => item.value === this.group.type);
      return type.label;
    },
  },
  methods: {
    setRequest(group, value) {
      group.meta.request = value;
    },
  },
};
</script>

<style lang="scss">
.roomfieldlabels {
  display: flex;
  margin-left: 110px;
  margin-right: 30px;

  > span.fixed {
    width: 150px;
  }
}

.roomrow {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid lightgrey;

  /* must match header label margin*/
  .rtitle {
    width: 100px;
    min-width: 100px;
  }
  .rdelete {
    width: 30px;
  }

  .namelist {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 450px;

    > div {
      display: flex;
      flex-direction: row;
    }

    .namefield {
      width: 150px;
    }
  }

  .cancelled {
    opacity: 0.5;
    text-decoration: line-through;
  }
}
</style>
