<template>
  <a-card>
    <v-col>
      <h3>Margin</h3>
      <v-row justify="space-between" align="center">
        <div>
          <!-- Fixed Margin -->
          <div>
            <input
              type="radio"
              value="fixed"
              v-model="data.margin.status"
              @input="(e) => onStatusChanged(e.target.value)"
            />
            <span class="px-2">Apply a fixed margin</span>
            <elwrapper label="In percent" midtext
              ><v-row
                ><f-number
                  v-model="data.margin.percentFixed"
                  smlabel
                  :disabled="data.margin.status != 'fixed'"
                  @input="(v) => onPercentFixedInput(v)" /></v-row
            ></elwrapper>
          </div>

          <!-- Dynamic Margin -->
          <div>
            <input
              type="radio"
              value="dynamic"
              v-model="data.margin.status"
              @input="(e) => onStatusChanged(e.target.value)"
            />
            <span class="px-2">Depending on the number of PAX</span>

            <!-- Iterate over peopleRanges -->
            <QuotePax
              v-for="(peopleRange, index) in data.peopleRanges"
              v-model="data.margin.percentDynamic[index]"
              :key="index"
              :label="`${peopleRange.min}-${peopleRange.max} PAX`"
              :unit="'% margin'"
              :disabled="data.margin.status != 'dynamic'"
              @input="(v) => onPercentDynamicInput(index, v)"
            />
          </div>
        </div>
        <div>
          <v-row>
            <!-- Per PAX and Per Group -->
            <div class="px-2">
              <QuotePax
                v-for="(peopleRange, index) in data.peopleRanges"
                :value="data.margin.perPax[index]"
                :key="index"
                :label="`${peopleRange.min}-${peopleRange.max} PAX`"
                :disabled="true"
                :unit="'per PAX'"
              />
            </div>
            <div>
              <QuotePax
                v-for="(peopleRange, index) in data.peopleRanges"
                v-model="data.margin.perGroup[index]"
                :key="index"
                :label="`${peopleRange.min}-${peopleRange.max} PAX`"
                :unit="'per Group'"
                @input="(v) => onPerGroupInput(index, v)"
              />
            </div>
          </v-row>
        </div>
      </v-row>
    </v-col>
  </a-card>
</template>

<script>
import Vue from "vue";
import QuotePax from "./QuotePax";
import quoteUtils from "@/modules/quotes/components/utils";
export default {
  components: {
    QuotePax,
  },
  props: {
    value: Object,
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    onStatusChanged(v) {
      this.data.margin.status = v;
      this.updateMargins();
    },
    onPercentFixedInput(v) {
      this.updateMargins();
    },
    onPercentDynamicInput(index, v) {
      Vue.set(this.data.margin.perGroup, index, this.getPerGroupByMinimumOfRange(index));
      Vue.set(this.data.margin.perPax, index, this.getPerPaxByMinimumOfRange(index));
    },
    onPerGroupInput(index, v) {
      const { margin } = this.data;
      const { status, perGroup, perPax } = margin;
      const perGroupValue = perGroup[index];

      if (status === "fixed") {
        margin.percentFixed = this.calculatePercentByPerPax(index, perGroupValue);
        perPax.forEach((_, i) => {
          Vue.set(perPax, i, this.getPerPaxByMinimumOfRange(i));
          if (i !== index) {
            Vue.set(perGroup, i, this.getPerGroupByMinimumOfRange(i));
          }
        });
      } else if (status === "dynamic") {
        margin.percentDynamic[index] = this.calculatePercentByPerPax(index, perGroupValue);
        Vue.set(perPax, index, this.getPerPaxByMinimumOfRange(index));
      }
    },
    calculatePerPaxByPercent(index, percent) {
      const totalCost = this.getTotalCostByPax();
      return (totalCost[index] * percent) / 100 + totalCost[index];
    },
    calculatePercentByPerPax(index, value) {
      const totalCost = this.getTotalCostByPax();
      const totalCostWithNumber = totalCost[index] * this.data.peopleRanges[index].min;
      return this.roundToTwoDecimals(((Number(value) + totalCostWithNumber) / totalCostWithNumber - 1) * 100);
    },
    getPerPaxByMinimumOfRange(index) {
      const percent =
        this.data.margin.status === "fixed" ? this.data.margin.percentFixed : this.data.margin.percentDynamic[index];
      const perPax = this.calculatePerPaxByPercent(index, percent);
      return this.roundToTwoDecimals(perPax);
    },
    getPerGroupByMinimumOfRange(index) {
      const percent =
        this.data.margin.status === "fixed" ? this.data.margin.percentFixed : this.data.margin.percentDynamic[index];
      const totalCost = this.getTotalCostByPax();
      const minimum = this.data.peopleRanges[index].min;
      return this.roundToTwoDecimals(totalCost[index] * minimum * (percent / 100));
    },
    getTotalCostByPax() {
      return quoteUtils.getTotalCostByPax(this.data.variableCosts, this.data.fixedCosts, this.data.peopleRanges);
    },
    updateMargins() {
      for (let i = 0; i < this.data.peopleRanges.length; i++) {
        Vue.set(this.data.margin.perGroup, i, this.getPerGroupByMinimumOfRange(i));
        Vue.set(this.data.margin.perPax, i, this.getPerPaxByMinimumOfRange(i));
      }
    },
    roundToTwoDecimals(number) {
      return Number(number.toFixed(2));
    },
  },
  watch: {
    "value.variableCosts": {
      handler() {
        this.updateMargins();
      },
      deep: true,
    },
    "value.fixedCosts": {
      handler() {
        this.updateMargins();
      },
      deep: true,
    },
    "value.peopleRanges": {
      handler() {
        this.updateMargins();
      },
      deep: true,
    },
  },
};
</script>
