<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)" width="400px" persistent>
    <v-card>
      <v-card-title>
        <span class="caption">Supplier Name (for Rooming)</span>
      </v-card-title>

      <v-card-text>
        <v-row class="mx-0">
          <v-col cols="1" class="caption">
            <v-icon small left>hotel</v-icon>
          </v-col>
          <v-col>
            <a-text-field label="Last Name" widelabel v-model="lastName" />
            <a-text-field label="First Name" widelabel v-model="firstName" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <g-button label="Cancel" @onclick="$emit('input', false)" />
        <div class="flex-grow-1"></div>

        <g-button type="primary" label="Update" :loading="loading" @onclick="update" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    editingSupplierRoom: Object,
  },
  data() {
    return {
      loading: false,
      firstName: "",
      lastName: "",
    };
  },
  watch: {
    value(value) {
      if (!value) return;

      this.firstName = this.editingSupplierRoom.first_name;
      this.lastName = this.editingSupplierRoom.last_name;
    },
  },
  methods: {
    update() {
      this.loading = true;
      this.$store
        .dispatch("BookingSupplierRoomStore/updateSupplierRoom", {
          supplierRoomId: this.editingSupplierRoom.id,
          data: {
            first_name: this.firstName,
            last_name: this.lastName,
          },
        })
        .then(() => {
          this.loading = false;
          this.$emit("input", false);
        })
        .catch((err) => {
          this.loading = false;
          this.$root.$error(err);
        });
    },
  },
};
</script>
