<template>
  <div class="d-flex">
    <div class="font-weight-bold caps" style="width: 120px">
      <span class="underline">Day {{ dayIndex + 1 }}</span>
      <span class="pl-2">({{ weekday }})</span>
    </div>
    <div class="font-weight-bold" style="width: 250px">
      {{ displayDateCity }}
    </div>
  </div>
</template>

<script>
import format from "@/services/format";
export default {
  props: {
    date: String,
    city: String,
    dayIndex: Number,
  },
  computed: {
    displayDateCity() {
      return `${format.formatDate(this.date)} ${this.city ? " -- " + this.city : ""}`;
    },
    weekday() {
      return format.getWeekday(this.date);
    },
  },
};
</script>
