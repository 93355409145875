<template>
  <v-container class="pa-0" fluid>
    <NoEmailAlert />
    <v-col style="height: 100%; overflow: auto; max-width: 100%; width: 100%; min-width: 100%">
      <v-row justify="space-between">
        <a-card nopad>
          <g-button :disabled="emailView === 0" label="1 - Select" class="mx-1" @onclick="emailView = 0" />
          <g-button :disabled="emailView === 1" label="2 - Compose" @onclick="emailView = 1" />
          <g-button :disabled="emailView === 2" label="3 - Preview" @onclick="emailView = 2" />
        </a-card>

        <a-card nopad v-if="emailView !== 0">
          <EmailSendButton
            :disabled="!canSend"
            :subject="formData.subject"
            :attachments="formData.attachments"
            :compileMailFn="compileMailFn"
            :postSend="_updateBookingSupplierStatuses"
            :onFinishFn="returnTo"
          />
        </a-card>
      </v-row>

      <v-row v-show="emailView === 0">
        <SelectBookingSuppliersToContact style="flex: 1" @setBookingRef="setBookingRef" v-model="selectedContent" />
      </v-row>
      <v-row v-show="emailView === 1">
        <a-card style="flex: 1">
          <EmailWrapper hideButtons mailType="MASS" selectedDate="ALL" v-model="formData" />
        </a-card>
      </v-row>
      <v-row v-if="emailView === 2">
        <a-card style="flex: 1">
          <PreviewEmailWrapper
            :subject="formData.subject"
            :attachments="formData.attachments"
            :compileMailFn="compileMailFn"
          />
        </a-card>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import { PreviewEmailWrapper, EmailTemplateService } from "@/modules/admin.email_template";
import { ProductService } from "@/modules/products";
import { EmailService, EmailSendButton, NoEmailAlert } from "@/modules/email";
import { BookingRoomService } from "@/modules/bookings.rooms";
import { SelectBookingSuppliersToContact, EmailWrapper, BookingEmailService } from "@/modules/bookings.email";
export default {
  components: {
    PreviewEmailWrapper,
    SelectBookingSuppliersToContact,
    EmailWrapper,
    EmailSendButton,
    NoEmailAlert,
  },
  data() {
    return {
      formData: {
        attachments: [],
      },
      supplierType: "",
      selectedContent: {
        contacts: {},
        ignoreProducts: {}, // Keys include booking_supplier_id + product_id
        groupBy: {}, // [supplier_id]: groupping type (SPLIT, GROUP)
        admins: {},
      },

      bookingRef: {},

      emailView: 0,
    };
  },
  computed: {
    canSend() {
      const c = this.selectedContent.contacts;
      if (!Object.keys(c).length) return false;

      // Leftover booking key may be selected without contact
      return Object.keys(c).reduce((arr, key) => {
        return [...arr, ...Object.keys(c[key])];
      }, []).length;
    },
    roomClearMapping() {
      if (Number(this.formData.status || 0) !== 5) return true;

      const selectedContacts = this.selectedContent.contacts;
      let match = true;
      Object.keys(selectedContacts).forEach((bookingId) => {
        let booking = this.bookingRef[bookingId];
        let roomingListTotal = this.roomInfo(booking).total;

        let supplierIdList = Object.keys(
          Object.values(selectedContacts[bookingId]).reduce((map, i) => {
            map[i.supplier_id] = 1;
            return map;
          }, {})
        ).map((supid) => {
          return Object.values(booking.booking_suppliers)
            .filter((bs) => bs.supplier_id === supid)
            .reduce((m, bs) => {
              // Only need to know if at least one is false
              if (!m) return m;
              if (!bs.meta.custom_room_num) return m;
              // If custom room number, and total count is NOT equal to rooming list
              // Return false, doesn't pass the test
              return (
                Object.values(bs.meta.custom_room_num).reduce((total, num) => {
                  total += Number(num);
                  return total;
                }, 0) === roomingListTotal
              );
            }, true);
        });

        if (supplierIdList.includes(false)) match = false;
      });

      return match;
    },
  },
  methods: {
    returnTo() {
      // this.$router.push({
      //   name: "bookings",
      // });
    },

    setBookingRef(bookingList) {
      this.bookingRef = bookingList.reduce((map, booking) => {
        map[booking.id] = booking;
        return map;
      }, {});
    },

    roomInfo(booking) {
      // Main Page
      const mainBreakDown = (booking.data && booking.data.room_num) || {};
      const custom = booking.rooming_data || [];

      let mainTotal = 0;
      Object.values(mainBreakDown).forEach((value) => {
        if (value) mainTotal += Number(value);
      });

      let roomingListTotal = 0;
      custom.forEach((item) => (roomingListTotal += Number(item.pax)));

      let roomBreakdown = {};
      custom.forEach((item) => {
        if (!roomBreakdown[item.type]) {
          roomBreakdown[item.type] = 1;
        } else {
          roomBreakdown[item.type] += 1;
        }
      });

      const hasRoomingList = roomBreakdown && Object.values(roomBreakdown).length;

      return {
        total: roomingListTotal || mainTotal,
        breakdown: hasRoomingList ? roomBreakdown : mainBreakDown,
      };
    },

    compileMailFn() {
      const ss = this.selectedContent;

      let bookingBlob = [];
      let message, subject;
      const bookings = this.$store.getters["BookingEmailStore/bookings"];
      const cc = Object.values(ss.admins).map((e) => e.email);

      let groupedSupplierContacts, booking, temp;
      Object.keys(ss.contacts).forEach((bookingId) => {
        if (!ss.contacts[bookingId]) return;
        if (!Object.values(ss.contacts[bookingId]).length) return;

        booking = bookings.find((item) => item.id === bookingId);
        const rooming = this.roomInfo(booking);
        message = EmailTemplateService.bookingBasicFill(this.formData.message || "", {
          group_name: booking.group_name,
          pax: booking.data && booking.data.pax_num,
          // BookingRoomService.getPaxCount(booking.data && booking.data.room_num || {},
          //	booking.rooming_data || [])
        });
        subject = EmailTemplateService.bookingBasicFill(
          this.formData.subject || "",
          {
            group_name: booking.group_name,
            pax: booking.data && booking.data.pax_num,
            // BookingRoomService.getPaxCount(booking.data && booking.data.room_num || {},
            // 	booking.rooming_data || [])
          },
          true
        );

        temp = BookingEmailService.compileOneBookingEmail({
          contacts: ss.contacts[bookingId],
          subject,
          message,
          bookingDays: booking.days,
          supplierType: "",
          bookingSuppliers: booking.booking_suppliers,
          groupBy: ss.groupBy[bookingId] || {},
          ignoreProducts: ss.ignoreProducts[bookingId] || {},
          oldDates: {
            old_start: booking.prev_from,
            old_end: booking.prev_to,
            old_days: booking.revert_status_blob ? booking.revert_status_blob.oldDayRows : [],
            old_suppliers: booking.revert_status_blob ? booking.revert_status_blob.suppliers : {},
          },

          bookingRoomData: BookingRoomService.getRoomingListBreakdown({
            bookingMetaData: booking.data,
            bookingRoomingList: booking.rooming_data,
            bookingDays: booking.days,
            supplierRoomList: booking.supplier_rooms,
          }),
          language: this.formData.language,
          cc: cc,
        });

        // Add Group Name to subject
        temp.forEach((item) => {
          if (!this.formData || !this.formData.subject) return;
          item.customSubject = (item.customSubject || this.formData.subject).replace("#", "# " + booking.group_name);
        });

        bookingBlob = bookingBlob.concat(temp);
      });

      return Promise.resolve(bookingBlob);
    },

    _updateBookingSupplierStatuses(mail) {
      if (this.formData.status === undefined || this.formData.status === null) return Promise.resolve();

      const ss = this.selectedContent;
      let bookingSupplierIdList,
        updateQuery = [];

      Object.keys(ss.contacts).forEach((bookingId) => {
        let booking = this.bookingRef[bookingId];
        // bookingSupplierIdList = EmailService.getBookingSupplierIdToUpdate(
        //   ss.contacts[bookingId],
        //   booking.booking_suppliers,
        //   ss.ignoreProducts[bookingId] || {}
        // );
        bookingSupplierIdList = Object.values(booking.booking_suppliers)
          .filter((supplier) => supplier.supplier_id === mail.supplier_id)
          .map((supplier) => supplier.id);

        updateQuery.push(
          BookingEmailService.updateBookingSupplierStatus({
            booking_id: bookingId,
            bookingSupplierIdList,
            status: this.formData.status,
            lockedPriceMapping: this.getLockedPriceMapping(bookingSupplierIdList, booking.booking_suppliers),
          })
        );
      });

      return Promise.all(updateQuery).catch((err) => {
        throw err;
      });
    },

    getLockedPriceMapping(bookingSupplierIdList, bookingSuppliers) {
      let endMap = {};
      bookingSupplierIdList.forEach((id) => {
        endMap[id] = BookingEmailService.getOneLockedPricing(bookingSuppliers[id], this.$store.getters);
      });

      return endMap;
    },
  },
  mounted() {
    this.$store.dispatch("EmailTemplateStore/getTemplates", true).catch((err) => this.$root.$error(err));
  },
};
</script>
