<template>
  <v-card flat>
    <v-form @submit.prevent="updateSearch">
      <v-card-text>
        <v-row justify="center">
          <v-col class="pa-0 ma-0">
            <a-text-field widelabel label="Product Name/ID" v-model="name_id" />
          </v-col>

          <v-col cols="3" class="pa-0 ma-0">
            <a-text-field label="City" smlabel v-model="city" />
          </v-col>

          <v-col class="pa-0 ma-0">
            <a-select widelabel label="Products Offered" :items="productTypes" v-model="offered" />
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col class="pa-0 ma-0">
            <a-select widelabel label="Confirmed Years" multiple :items="yearOptions" v-model="confirmed" />
          </v-col>

          <v-col class="pa-0 ma-0">
            <a-select widelabel label="Not Confirmed" multiple :items="yearOptions" v-model="not_confirmed" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <g-button label="Clear" @onclick="clearSearch" />
        <v-spacer />
        <search-button />
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import SEARCH from "@/config/fields/SEARCH";
import { ProductMenu } from "@/config/menus";
export default {
  props: {
    isApplied: Boolean,
    dbConfig: String,
  },
  computed: {
    name_id: {
      get() {
        return this.get("name_id") || "";
      },
      set(nuval) {
        this.set("name_id", nuval);
      },
    },
    offered: {
      get() {
        return this.get("offered") || "";
      },
      set(nuval) {
        this.set("offered", nuval);
      },
    },
    city: {
      get() {
        return this.get("city") || "";
      },
      set(nuval) {
        this.set("city", nuval);
      },
    },
    confirmed: {
      get() {
        return this.get("confirmed") || [];
      },
      set(nuval) {
        this.set("confirmed", nuval);
      },
    },
    not_confirmed: {
      get() {
        return this.get("not_confirmed") || [];
      },
      set(nuval) {
        this.set("not_confirmed", nuval);
      },
    },

    yearOptions() {
      return SEARCH.products("ALL").confirmed.source;
    },

    productTypes() {
      return ProductMenu.map((item) => {
        return {
          label: item.title,
          value: item.module,
        };
      });
    },
  },
  methods: {
    get(val) {
      return this.$store.getters["DatabaseStore/productSearch"][val];
    },
    set(key, nuval) {
      let data = this.$store.getters["DatabaseStore/productSearch"];
      Object.assign(data, { [key]: nuval });
      this.$store.dispatch("DatabaseStore/updateSearch", { tab: "product", data });
    },

    clearSearch() {
      this.$store.dispatch("DatabaseStore/updateSearch", { tab: "product", data: {} });

      if (this.isApplied) {
        this.$emit("search", {});
        this.$store.dispatch("DatabaseStore/applySearch", {
          dbconfig: this.dbConfig,
        });
      }
    },
    updateSearch() {
      const search = {
        products: true,
        multiple: true,
        list: [
          {
            value: this.name_id,
            columns: ["pid", "name"],
          },
          {
            value: this.offered,
            columns: ["product_type"],
          },
          {
            value: this.confirmed,
            columns: ["confirmed"],
          },
          {
            value: this.not_confirmed,
            columns: ["not_confirmed"],
          },
          {
            value: this.city,
            columns: ["city"],
          },
        ],
      };

      this.$emit("search", search);
      this.$store.dispatch("DatabaseStore/applySearch", {
        tab: "product",
        dbconfig: this.dbConfig,
        data: search,
      });
    },
  },
};
</script>
