<template>
  <a-card v-show="configMeta.length">
    <v-row wrap>
      <v-col class="py-0" v-for="meta in configMeta" :cols="meta.cols || 6" :key="meta.key">
        <ParkingDialogWrapper
          v-if="meta.key === 'parking'"
          :config="meta"
          :value="metaValues[meta.key]"
          :country="country"
          @input="(v) => updateField(meta.key, v)"
        />
        <a-field
          v-else
          :config="meta"
          :value="metaValues[meta.key]"
          :country="country"
          :env="env"
          clearable
          @input="(v) => updateField(meta.key, v)"
        />
      </v-col>
    </v-row>
  </a-card>
</template>

<script>
import info from "../config/formConfig";
import ParkingDialogWrapper from "./_ParkingDialogWrapper";

export default {
  props: {
    supplierType: String,
  },
  components: {
    ParkingDialogWrapper,
  },
  computed: {
    env() {
      return this.$store.getters["AccountStore/environment"];
    },
    configMeta() {
      const env = this.$store.getters["AccountStore/environment"];
      const config = this.supplierType ? info[this.supplierType] || {} : {};
      if (!config.meta) return false;

      // Filter out any meta not for this environment
      return Object.keys(config.meta)
        .filter((key) => {
          // No restriction, then return
          if (!config.meta[key].restrictEnv) return true;
          return config.meta[key].restrictEnv.includes(env);
        })
        .map((key) => {
          return {
            key,
            ...config.meta[key],
          };
        });
    },
    country() {
      return this.$store.getters["SupplierStore/country"];
    },
    metaValues() {
      return this.$store.getters["SupplierStore/meta"];
    },
  },
  methods: {
    updateField(key, value) {
      return this.$store.dispatch("SupplierStore/setUpdateMeta", { key, value });
    },
  },
};
</script>
