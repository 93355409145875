import api from "./api";

const namespaced = true;

const state = {};

const mutations = {};

const actions = {
  getContactList(v, id) {
    return api
      .getContactList(id)
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  getContact(v, id) {
    return api
      .getContact(id)
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },

  addContact({ commit }, { supplier_id, contact }) {
    return api
      .addContact(supplier_id, contact)
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  updateContact({ commit }, { id, contact, original }) {
    return api
      .updateContact(id, contact, JSON.parse(original))
      .then((data) => contact)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  copyContacts({ commit }, { from_supplier_id, to_supplier_id }) {
    return api
      .copyContacts(to_supplier_id, from_supplier_id)
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  deleteContactList({ commit }, idList) {
    return (
      api
        .deleteContactList(idList)
        //.then(data => commit(DELETE_CONTACT_LIST, idList))
        .catch((err) => {
          if (err) throw err.data;
        })
    );
  },

  setPrimaryContact(v, { supplier_id, contact_id, old_primary }) {
    return api
      .setPrimaryContact(supplier_id, contact_id, old_primary)
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
};

const getters = {};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
