<template>
  <div class="cyoa">
    <v-row v-if="fieldShow === 'ASK'" justify="start">
      <OptionPop :choosePath="choosePath" @select="selectOption" />
    </v-row>

    <ClearCyoaBtn v-else-if="fieldShow === 'PERCENT'" @clear="clearField">
      <FPercent :value="value.value" @input="updatePercentage" />
    </ClearCyoaBtn>

    <ClearCyoaBtn v-else-if="fieldShow === 'TEXT_COMMENT'" justify="center" @clear="clearField">
      <div class="d-flex">
        <FText :value="value.text" @input="updateTextComment" />

        <span class="caption pl-1">*{{ value.value }}</span>
      </div>
    </ClearCyoaBtn>

    <ClearCyoaBtn v-else-if="fieldShow === 'TEXT_SELECT'" @clear="clearField">
      <div class="d-flex">
        <FDropdown :items="selectItems" :value="value.text" hideClear @input="updateTextComment" />

        <span class="caption pl-1">*{{ value.value }}</span>
      </div>
    </ClearCyoaBtn>

    <ClearCyoaBtn
      v-else-if="fieldShow === 'TEXT'"
      @clear="clearField"
      justify="center"
      align="center"
      style="flex-wrap: nowrap"
    >
      <div class="d-flex align-center" style="height: 100%">
        <div class="cyoa-btn">{{ value.value }}</div>
      </div>
    </ClearCyoaBtn>

    <ClearCyoaBtn v-else-if="fieldShow === 'COST'" @clear="clearField">
      <ENumber :value="value" :env="env" @input="(v) => $emit('input', v)" />
    </ClearCyoaBtn>

    <ClearCyoaBtn v-else-if="fieldShow === 'COST_2'" @clear="clearField">
      <div class="d-flex">
        <ENumber type="COST_2" :value="value" :env="env" @input="(v) => $emit('input', v)" />

        <span class="caption pl-1">*{{ sideLabel }}</span>
      </div>
    </ClearCyoaBtn>

    <ClearCyoaBtn v-else-if="fieldShow === 'RATE'" @clear="clearField">
      <NumPerNum :value="value" @input="(v) => $emit('input', v)" />
    </ClearCyoaBtn>
  </div>
</template>

<script>
import FDropdown from "../items/FDropdown";
import FPercent from "../items/FPercent";
import FText from "../items/FText";
import ENumber from "../special/ENumber";
import NumPerNum from "./NumPerNum";
import OptionPop from "./cyoa/_OptionPop";
import ClearCyoaBtn from "./cyoa/_clearCyoaButton";

export default {
  props: {
    choosePath: Object,
    country: String,
    env: String,
    config: Object,
    value: Object, // { type, value, label, cur }
    cols: Number,
  },
  components: {
    FDropdown,
    FPercent,
    ENumber,
    NumPerNum,
    FText,
    OptionPop,
    ClearCyoaBtn,
  },
  computed: {
    fieldShow() {
      if (this.value === undefined || this.value === null) return "ASK";

      if (this.config) {
        if (this.value.type === "TEXT") {
          let c = this.config[this.value.value];
          if (!c) return "TEXT";
          if (c.editText && c.editType === "DROPDOWN") return "TEXT_SELECT";
          return c.editText ? "TEXT_COMMENT" : "TEXT";
        }
      }

      return this.value.type;
    },
    selectItems() {
      let c = this.config[this.value.value];
      if (!c) return [];
      if (c.getSource) return c.getSource(this.env, this.country);
      if (c.getOptionListSource) return this.$store.getters["ListStore/" + c.getOptionListSource](this.env || "USA");
      return c.source;
    },

    sideLabel() {
      const label = this.value && (this.value.text || this.value.value);
      if (this.cols && this.cols <= 4) return label.substring(0, 3);
      return label;
    },
  },
  methods: {
    clearField() {
      this.$emit("input", null);
    },
    selectOption({ type, text }) {
      this.$emit("input", {
        type: type,
        ...(type === "COST_2" && { text: text }),
        ...(type === "TEXT" && { value: text }),
        ...(type === "COST" && { cur: text }),
      });
    },
    updateTextComment(text) {
      this.$emit("input", Object.assign(this.value, { text }));
    },
    updatePercentage(value) {
      this.$emit("input", Object.assign(this.value, { value }));
    },
  },
};
</script>

<style lang="scss">
.cyoa {
  min-height: 34px;
  display: flex;
  width: 100%;

  .clearbtn {
    opacity: 0;
  }

  &:hover .clearbtn {
    opacity: 1;
  }

  .cyoa-btn {
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.675em;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    background: lightgray;
    line-height: 20px;
    border: 1px solid rgba(0, 0, 0, 0.38);
    border-radius: 4px;
    padding: 6px 7px;
    max-height: 32px;
    min-height: 32px;
  }
}
</style>
