<template>
  <div>
    <QuoteSaveHeader class="pa-1" />
    <QuoteBaseForm />

    <Notes />
  </div>
</template>

<script>
import { QuoteBaseForm, QuoteSaveHeader, Notes } from "@/modules/quotes";
export default {
  components: {
    QuoteSaveHeader,
    QuoteBaseForm,
    Notes,
  },
  computed: {
    isAdmin() {
      return this.$store.getters["isAdmin"];
    },
    quoteId() {
      return this.$store.getters["QuoteStore/quote_id"];
    },
  },
};
</script>
