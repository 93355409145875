<template>
  <div>
    <v-row justify="center" class="grey lighten-2 caption">
      <v-col cols="10"> Showing Compiled Message: {{ focusEmailIndex + 1 }}/{{ messageDataList.length }} </v-col>
    </v-row>

    <v-row>
      <v-col cols="1" @click="back" :class="{ hover: canGoBack }">
        <v-icon v-show="canGoBack">arrow_back_ios</v-icon>
      </v-col>

      <v-col cols="10">
        <elwrapper label="From" midtext>
          {{ from }}
        </elwrapper>
        <elwrapper label="To" midtext>
          <v-chip v-for="(email, index) in focusEmailContacts" :key="index">{{ email }}</v-chip>
        </elwrapper>
        <elwrapper label="Subject" midtext>
          <span>{{ focusSubject }}</span>
          <span v-if="!focusSubject" class="red--text caption">(No subject)</span>
        </elwrapper>
        <v-divider class="my-2" />
        <div v-html="focusEmailMessage"></div>

        <div v-if="focusAttachments" class="grey lighten-2 pa-2">
          <v-icon left>attach_file</v-icon>
          <v-chip
            color="primary"
            v-for="(file, index) in focusAttachments"
            :key="file.lastModified"
            class="mx-1"
            @click="openAttachment(file.path)"
          >
            <span>{{ file.filename }}</span>
          </v-chip>
        </div>
      </v-col>

      <v-col cols="1" @click="forward" :class="{ hover: canGoForward }">
        <v-icon v-show="canGoForward">arrow_forward_ios</v-icon>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import { ipcRenderer } from 'electron'
import service from "../../service";
export default {
  props: {
    subject: String,
    attachments: Array,
    compileMailFn: Function, // MUST RETURN PROMISE
  },
  data() {
    return {
      dialog: false,
      loading: false,

      messageDataList: [],
      focusEmailIndex: 0,
    };
  },
  watch: {
    dialog(v) {
      if (v) {
        this.compiledTemplate();
      } else {
        this.focusEmailIndex = 0;
      }
    },
  },
  computed: {
    from() {
      const email = this.$store.getters["AccountStore/emailCreds"];
      return email ? email.email : "";
    },
    focusSubject() {
      if (!this.messageDataList.length) return "";
      return this.messageDataList[this.focusEmailIndex].customSubject || this.subject;
    },
    focusEmailContacts() {
      if (!this.messageDataList.length) return "";
      return this.messageDataList[this.focusEmailIndex].contacts.emailList;
    },
    focusEmailMessage() {
      if (!this.messageDataList.length) return "(No selected contacts)";
      return this.messageDataList[this.focusEmailIndex].message;
    },
    focusAttachments() {
      if (!this.messageDataList.length) return null;
      return this.messageDataList[this.focusEmailIndex].attachments || this.attachments;
    },

    canGoBack() {
      return this.focusEmailIndex > 0;
    },
    canGoForward() {
      return this.focusEmailIndex < this.messageDataList.length - 1;
    },
  },
  methods: {
    back() {
      if (this.focusEmailIndex === 0) return;
      this.focusEmailIndex -= 1;
    },
    forward() {
      if (this.focusEmailIndex === this.messageDataList.length - 1) return;
      this.focusEmailIndex += 1;
    },
    compiledTemplate() {
      this.loading = true;

      this.compileMailFn().then((messageData) => {
        // ATTACH SIGNATURE
        messageData.forEach((item) => {
          item.message += this.$store.getters["AccountStore/signature"];
        });
        this.loading = false;
        this.messageDataList = messageData;
      });
    },
    openAttachment(filepath) {
      this.$root.$success("Opening file...");
      // console.log('TO DO')
      // ipcRenderer.send('open-doc', {
      //   filepath,
      //   cb: this.$store.dispatch('addTab')
      // })
    },
  },
  mounted() {
    this.compiledTemplate();
  },
};
</script>
