export default {
  name: {
    label: "Name",
    inputType: "TEXT",
  },
  en_label: {
    label: "(English)",
    inputType: "TEXT",
  },
  title: {
    label: "Title",
    inputType: "TEXT",
  },
  phone_number: {
    label: "Phone #",
    inputType: "PHONE",
  },
  cell_number: {
    label: "Cell #",
    inputType: "PHONE",
  },
  email: {
    label: "Email",
    inputType: "TEXT",
  },
  notes: {
    label: "Notes",
    inputType: "TEXTAREA",
  },
};
