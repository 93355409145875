<template>
  <div class="d-flex">
    <div class="flex-grow-1"></div>

    <EmailClientButton />
  </div>
</template>

<script>
import EmailClientButton from "./_EmailClientButton";

export default {
  components: {
    EmailClientButton,
  },
  computed: {},
};
</script>
