<template>
  <nav-button icon="mail" label="Email" @click="setEmail" />
</template>

<script>
import { EmailService } from "@/modules/email";
export default {
  computed: {
    clientName() {
      return this.$store.getters["ClientStore/active"].name;
    },
    contact() {
      return this.$store.getters["ClientStore/primary_contact"];
    },
  },
  methods: {
    setEmail() {
      // Set email form content
      this.$store.dispatch("EmailStore/pendMail", {
        contact: this.contact,
        returnTo: this.$route.path,
        config: {
          title: this.clientName,
          clientId: this.$store.getters["ClientStore/client_id"],
          client: true,
        },
      });
      // Switch to new page
      this.$router.push({
        name: "email",
      });
    },
  },
};
</script>
