<template>
  <v-col class="pa-0">
    <CreateProductForm :goback="returnTo" :supplier="supplier">
      <SupplierSelectField disabled v-model="supplier" />
    </CreateProductForm>
  </v-col>
</template>

<script>
import { SupplierSelectField } from "@/modules/database";
import { CreateProductForm } from "@/modules/products";
export default {
  components: {
    CreateProductForm,
    SupplierSelectField,
  },
  data() {
    return {
      supplier: {},
    };
  },
  computed: {
    supplierId() {
      return this.$store.getters["SupplierStore/id"];
    },
    returnTo() {
      return {
        name: "suppliers_products",
        params: { id: this.supplierId },
      };
    },
  },
  mounted() {
    this.supplier = {
      id: this.$store.getters["SupplierStore/id"],
      name: this.$store.getters["SupplierStore/name"],
      supplier_type: this.$store.getters["SupplierStore/type"],
      search_types: this.$store.getters["SupplierStore/search_types"],
      pid: this.$store.getters["SupplierStore/pid"],
    };
  },
};
</script>
