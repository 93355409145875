<template>
  <div class="d-flex justify-center align-start ring mx-3 px-2 caption">
    <div style="height: 34px; display: flex; align-content: center">
      <v-icon v-if="flight.flight_type === 'INTERNATIONAL'" small class="mr-3">public</v-icon>
      <v-icon v-else small class="mr-3">flight</v-icon>
    </div>
    <div style="width: 150px">
      <elwrapper label="Date" midtext smlabel class="caption">{{ date }}</elwrapper>
    </div>

    <div style="width: 200px" v-if="flight.basic">
      <elwrapper label="Airport" midtext>{{ flight.airport }}</elwrapper>
    </div>
    <div style="width: 200px" v-else>
      <elwrapper label="Departing" midtext>{{ flight.depart_airport }}</elwrapper>
      <elwrapper label="Arriving" midtext>{{ flight.arrive_airport }}</elwrapper>
    </div>

    <div style="width: 150px" v-if="flight.basic">
      <elwrapper label="Time" midtext>{{ flight.time }}</elwrapper>
    </div>
    <div style="width: 150px" v-else>
      <elwrapper label="Departure" midtext>{{ flight.depart_time }}</elwrapper>
      <elwrapper label="Arrival" midtext>{{ flight.arrive_time }}</elwrapper>
    </div>

    <elwrapper label="Flight #" midtext>{{ flight.flight_num }}</elwrapper>
  </div>
</template>

<script>
import format from "@/services/format";
export default {
  props: {
    flight: Object,
  },
  computed: {
    date() {
      return format.formatDate(this.flight.date);
    },
  },
};
</script>
