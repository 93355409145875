export default {
  OPERATING_AREA: {
    label: "Tour Guide Operating Area Options",
  },
  BREAKFAST: {
    label: "Breakfast Options",
  },
  RESTAURANT_STYLE: {
    label: "Restaurant Styles",
  },
};
