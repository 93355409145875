<template>
  <div>
    <a-hover-menu>
      <template v-slot:activator>
        <v-btn small icon @click="openEdit">
          <v-icon small>confirmation_number</v-icon>
        </v-btn>
      </template>

      <v-row class="mx-0 caption">
        <span class="font-weight-bold mr-5">Confirmation #</span>
        <span>{{ confirm }}</span>
      </v-row>
    </a-hover-menu>
  </div>
</template>

<script>
export default {
  props: {
    confirm: String,
  },
  methods: {
    openEdit(e) {
      e.stopPropagation();
      this.$emit("onclick");
    },
  },
};
</script>
