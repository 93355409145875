<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)" width="400" persistent>
    <v-card>
      <v-card-title> Custom Expense </v-card-title>
      <v-card-text class="black--text">
        <v-row>
          <v-col class="subtitle-2" align-self="center"> Supplier </v-col>
          <v-col class="d-flex">
            <a-truncate :text="editSupplier.name" :hoverText="editSupplier.name" />
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-btn-toggle v-model="expenseType" mandatory>
            <v-btn value="EXPENSE" small> Expense </v-btn>
            <v-btn value="REFUND" small> Refund </v-btn>
            <v-btn value="DEPOSIT" small> Deposit </v-btn>
            <v-btn value="BALANCE" small> Balance </v-btn>
            <!-- <v-btn value="PRODUCT" small>
            Product
          </v-btn> -->
          </v-btn-toggle>
        </v-row>

        <ExpenseForm v-if="expenseType === 'EXPENSE'" :editSupplier="editSupplier" @close="close" />

        <RefundForm v-if="expenseType === 'REFUND'" :editSupplier="editSupplier" @close="close" />

        <DepositForm
          v-if="expenseType === 'DEPOSIT'"
          :editSupplier="editSupplier"
          :editDeposit="editDeposit"
          @close="close"
        />

        <BalanceForm
          v-if="expenseType === 'BALANCE'"
          :editSupplier="editSupplier"
          :editDeposit="editDeposit"
          @set-cost="(v) => $emit('set-cost', v)"
          @close="close"
        />

        <!-- <ProductForm
        v-if="expenseType === 'PRODUCT'"
        :editSupplier="editSupplier"
        @close="close" 
        /> -->
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ExpenseForm from "./expenses/_ExpenseForm";
import RefundForm from "./expenses/_RefundForm";
import DepositForm from "./expenses/_DepositForm";
import BalanceForm from "./expenses/_BalanceForm";
// import ProductForm from './expenses/ProductForm'
import format from "@/services/format";
export default {
  props: {
    value: Boolean,
    editSupplier: Object,
    editDeposit: Array,
  },
  components: {
    ExpenseForm,
    RefundForm,
    DepositForm,
    BalanceForm,
    // ProductForm
  },
  data() {
    return {
      expenseType: "EXPENSE",
      expenseName: null,
      expense: null,
    };
  },
  methods: {
    close() {
      this.expenseType = "EXPENSE";
      this.expenseName = null;
      this.expense = null;
      this.$emit("input", false);
    },
  },
};
</script>
