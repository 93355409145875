import html2canvas from "html2canvas";

export default {
  extractWordDocumentContent(title, bodyHTML, styles, config) {
    var landscapeView = `<style type="text/css" media="print">
			  @page { size: landscape; }
			</style>`;
    var header = `
			<head><meta charset='utf-8'>
				<title>${title}</title>
				<style type="text/css">${styles}</style>
				${config.landscape ? landscapeView : ""}
			</head><body>`;
    var footer = `</body>`;
    var sourceHTML = header + bodyHTML + footer;

    var printWin = window.open("", "", "left=0,top=0,toolbar=0,scrollbars=0,status =0");
    printWin.document.write(sourceHTML);

    // Allow images time to render
    setTimeout(() => {
      // printWin.document.close();
      printWin.focus();
      printWin.print();
      printWin.close();
    }, 1000);
  },
  getImageUri(elementId) {
    return new Promise((resolve, reject) => {
      const img = document.getElementById(elementId);
      if (!img) return resolve(null);

      html2canvas(img, {}).then((canvas) => {
        const dataurl = canvas.toDataURL("image/png", 1.0);
        resolve(dataurl);
      });
    });
  },
};
