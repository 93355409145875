import BookingsHome from "./BookingsHome";
import BookingsNew from "./BookingsNew";
import BookingsEditWrapper from "./BookingsEditWrapper";

import MainInfo from "./subview/MainInfo";
import Overview from "./subview/Overview";
import AllDays from "./subview/AllDays";
import Rooms from "./rooms/Rooms";
import Notes from "./subview/Notes";
import Day from "./subview/Day";
import Appreciation from "./appreciation/Appreciation";

import AirportSign from "./airport_sign/_routes";
import Contracts from "./contracts/_routes";
import Invoices from "./invoices/_routes";
import Technical from "./technical/_routes";
import Reminder from "./reminders/_routes";

export default [
  {
    path: "/bookings",
    name: "bookings",
    component: BookingsHome,
    meta: {
      tabAccess: "BOOKING",
    },
  },
  {
    path: "/booking_new",
    name: "bookings_new",
    component: BookingsNew,
    meta: {
      tabAccess: "BOOKING",
    },
  },
  {
    path: "/bookings_view/:booking_id",
    component: BookingsEditWrapper,
    meta: {
      toolbar: "booking",
      tabAccess: "BOOKING",
    },
    children: [
      {
        path: "",
        name: "bookings_view",
        component: MainInfo,
        meta: {
          toolbar: "booking",
          tabAccess: "BOOKING",
        },
      },
      {
        path: "overview",
        name: "bookings_overview",
        component: Overview,
        meta: {
          toolbar: "booking",
          tabAccess: "BOOKING",
        },
      },
      {
        path: "rooms",
        name: "bookings_rooms",
        component: Rooms,
        meta: {
          toolbar: "booking",
          tabAccess: "BOOKING",
        },
      },
      {
        path: "days",
        name: "bookings_days",
        component: AllDays,
        meta: {
          toolbar: "booking",
          tabAccess: "BOOKING",
        },
      },
      {
        path: "notes",
        name: "booking_notes",
        component: Notes,
        meta: {
          toolbar: "booking",
          tabAccess: "BOOKING",
        },
      },
      {
        path: "day/:day",
        name: "booking_day",
        component: Day,
        meta: {
          toolbar: "booking",
          tabAccess: "BOOKING",
        },
      },
      {
        path: "appreciation",
        name: "booking_appreciation",
        component: Appreciation,
        meta: {
          toolbar: "booking",
          tabAccess: "BOOKING",
        },
      },
      ...Contracts,
      ...AirportSign,
      ...Technical,
      ...Reminder,
      ...Invoices,
    ],
  },
];
