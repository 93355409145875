<template>
  <v-btn
    depressed
    :small="!xsmall"
    :x-small="xsmall"
    :disabled="disabled"
    :outlined="!!type"
    :color="type"
    :loading="loading"
    style="background: white"
    @click="$emit('onclick')"
  >
    <v-icon v-if="icon" small left>{{ icon }}</v-icon>
    <span>{{ label }}</span>
  </v-btn>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    label: String,
    icon: String,
    type: String,
    xsmall: Boolean,
    loading: Boolean,
  },
};
</script>
