<template>
  <v-row>
    <v-col cols="6">
      <CalendarView
        mode="BOOKING"
        addButton
        :dateStart="dateStart"
        :focusDate="focusDate"
        :reminderMapping="reminderMapping"
        @add="openAdd"
        @onfocus="(v) => (focusDate = v)"
        @select="(v) => (reminderList = v)"
      />

      <div v-if="reminderList.length">
        <a-card nopad>
          <v-row class="booking-title grey darken-1 white--text">
            <v-col>REMINDERS</v-col>
          </v-row>
          <ReminderItem
            v-for="reminder in reminderList"
            :key="reminder.id"
            :accountList="accountList"
            mode="BOOKING"
            :supplierData="reminder"
            @edit="openEdit"
            @copy="openCopy"
            @remove="removeReminder"
            @oncomplete="(v) => markComplete(reminder.id, v)"
            class="color-alt hover"
          />
        </a-card>
      </div>
      <div v-else class="white--text">(No reminders on this day)</div>
    </v-col>

    <v-col>
      <UpcomingReminders
        mode="BOOKING"
        title="All Reminders"
        :upcomingMapping="reminderMapping"
        @onfocus="(v) => (focusDate = v.date)"
      />
    </v-col>

    <AddReminderDialog
      v-model="addDialog"
      :focusDate="focusDate"
      :mode="dialogMode"
      :sourceReminder="sourceReminder"
      @added="addedReminder"
      @update="updateReminder"
    />
  </v-row>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import { CalendarView, UpcomingReminders, ReminderItem } from "@/modules/reminders";
import { AddReminderDialog } from "@/modules/bookings.reminders";
import { SuppliersMenu } from "@/config/menus";
export default {
  components: {
    CalendarView,
    UpcomingReminders,
    ReminderItem,
    AddReminderDialog,
  },
  data() {
    return {
      accountList: [],
      addDialog: false,
      dialogMode: "ADD",
      sourceReminder: {},
      focusDate: null,
    };
  },
  computed: {
    bookingId() {
      return this.$store.getters["BookingStore/booking_id"];
    },
    dateStart() {
      return this.$store.getters["BookingStore/active"].from;
    },
    dateEnd() {
      return this.$store.getters["BookingStore/active"].to;
    },
    reminderMapping() {
      return this.$store.getters["BookingReminderStore/reminderMapping"];
    },

    reminderList() {
      const list = this.reminderMapping[this.focusDate];
      if (!list) return [];

      const DBFIELDS = SuppliersMenu.MOD;
      const suppliers = Object.values(this.$store.getters["BookingDayStore/suppliers"]);
      let found, typeAs;
      return list
        .map((sup) => {
          found = suppliers.find((item) => item.supplier_id === sup.supplier_id);
          typeAs =
            (found && (found.supplier_type === "HOTEL" && found.type_as === "RESTAURANT" ? "HOTEL" : found.type_as)) ||
            "GENERAL";
          return {
            ...sup,
            type_as: typeAs,
            name: (found && found.name) || "General Reminder",
          };
        })
        .sort((a, b) => {
          // Sort then by type
          const aIndex = DBFIELDS.indexOf(a.type_as);
          const bIndex = DBFIELDS.indexOf(b.type_as);
          if (aIndex < bIndex) return -1;
          if (aIndex > bIndex) return 1;
          return 0;
        });
    },
  },
  methods: {
    openAdd() {
      this.addDialog = true;
      this.dialogMode = "ADD";
    },
    openEdit(v) {
      this.addDialog = true;
      this.dialogMode = "EDIT";
      this.sourceReminder = v;
    },
    openCopy(v) {
      this.addDialog = true;
      this.dialogMode = "COPY";
      this.sourceReminder = v;
    },

    // POST + DB ACTION
    addedReminder(reminder) {
      if (!this.reminderMapping[reminder.date]) Vue.set(this.reminderMapping, reminder.date, []);
      this.reminderMapping[reminder.date].push(reminder);
    },
    updateReminder({ nu, old }) {
      // Different date, pop and move
      if (nu.date !== old.date) {
        this.reminderMapping[old.date] = this.reminderMapping[old.date].filter((item) => item.id !== old.id);
        this.addedReminder({
          id: old.id,
          name: old.name,
          ...nu,
        });
      }
      // Different other (notes), set here
      const map = this.reminderMapping[nu.date].find((item) => item.id === old.id);
      Vue.set(map, "note", nu.note);
    },
    removeReminder(reminder) {
      this.$store
        .dispatch("ReminderStore/removeReminder", {
          bookingId: this.bookingId,
          reminderId: reminder.id,
        })
        .then((_) => {
          this.reminderMapping[reminder.date] = this.reminderMapping[reminder.date].filter((v) => v.id !== reminder.id);
          if (!this.reminderMapping[reminder.date].length) Vue.delete(this.reminderMapping, reminder.date);
          this.$root.$success("Removed reminder");
        })
        .catch((err) => this.$root.$error(err));
    },
    markComplete(id, data) {
      const source = this.reminderMapping[this.focusDate].find((item) => item.id === id);
      Vue.set(source, "done_by", data.done_by);
      Vue.set(source, "done_date", data.done_date);
    },
  },
  mounted() {
    this.$store
      .dispatch("AdminStore/getAccountList")
      .then((list) => {
        this.accountList = list;
      })
      .catch((err) => this.$root.$error(err));

    // Default focus on date wih overdue reminder
    if (this.reminderMapping) {
      const soonDate = moment().add(7, "days").format("YYYY-MM-DD");
      const firstOverdue = Object.values(this.reminderMapping).find((itemList) => {
        return itemList.find((item) => {
          return item.date < soonDate && !item.done_date;
        });
      });

      if (firstOverdue) {
        this.focusDate = firstOverdue[0].date;
      }
    }
  },
};
</script>
