<template>
  <v-card flat>
    <v-form @submit.prevent="updateSearch">
      <v-row class="mx-0 flex-nowrap">
        <v-col>
          <a-select label="User" freeopt smlabel v-model="user" :items="accountContacts" />
        </v-col>

        <v-col>
          <SupplierSelectField v-model="supplier" />
        </v-col>
        <v-col>
          <a-select label="Database" freeopt v-model="database" :items="databaseItems" />
        </v-col>
      </v-row>

      <v-row class="mx-2">
        <elwrapper label="Dates" smlabel>
          <date-range v-model="dates" />
        </elwrapper>

        <!-- <elwrapper label="Time" smlabel>
				<time-range v-model="times" :disabled="disableTime" />
			</elwrapper> -->
      </v-row>

      <v-row justify="end">
        <div>
          <g-button label="Clear" @onclick="clear" />
          <search-button class="mx-5" />
        </div>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import { SupplierSelectField } from "@/modules/database";
import DB from "../config/DB";
import format from "@/services/format";
import moment from "moment";
export default {
  props: {
    value: Object,
  },
  components: {
    SupplierSelectField,
  },
  data() {
    return {
      user: null,
      database: null,
      supplier: null,
      dates: {
        range: false,
      },
      times: {
        range: false,
      },

      datewallEnd: format.convertDate(new Date()),
      userAccountOptions: [],
      databaseItems: DB,
    };
  },
  computed: {
    accountContacts() {
      return Object.values(this.userAccountOptions).map((v) => {
        return {
          label: v.name,
          value: v.id,
        };
      });
    },
    disableTime() {
      if (this.dates.range) return !(this.dates.from || this.dates.to);
      return !this.dates.from;
    },
  },
  methods: {
    clear() {
      this.user = null;
      this.supplier = null;
      this.database = null;
      this.dates = {
        range: false,
      };
      this.times = {
        range: false,
      };
    },
    updateSearch() {
      let dateExtract =
        this.dates.from && !this.dates.to
          ? { date_on: this.dates.from }
          : this.dates.to && !this.dates.from
          ? { date_on: this.dates.to }
          : this.dates.from && this.dates.to
          ? { date_from: this.dates.from, date_to: this.dates.to }
          : null;

      let timeExtract =
        this.times.from && this.times.to
          ? { time_on: this.times.from }
          : this.times.to && !this.time.from
          ? { time_on: this.times.to }
          : this.times.from && this.times.to
          ? { time_from: this.times.from, time_to: this.times.to }
          : null;

      this.$emit("input", {
        ...(this.user && { user_id: this.user }),
        ...(this.supplier_id && { supplier_id: this.supplier.id }),
        ...(this.database && { database: this.database }),
        ...dateExtract,
        ...timeExtract,
      });
    },
  },
  mounted() {
    this.$store
      .dispatch("EmailStore/getAccountEmails")
      .then((v) => (this.userAccountOptions = v))
      .catch((err) => this.$root.$error(err));
  },
};
</script>
