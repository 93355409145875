<template>
  <div>
    <v-row justify="space-between" class="ma-1">
      <div class="d-flex flex-row">
        <ImportRooms />
        <g-button icon="cloud_download" label="Export List (PDF)" @onclick="dialog = true" />
        <ExportRooms />

        <!-- 
            Have to manually set background-color because bg-white doens't 
            work for some reason
            -->
        <div class="rounded pl-2 ml-2 overflow-hidden" style="height: 28px; background-color: white">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search by name"
            single-line
            hide-details
            class="ma-0 pa-0"
          ></v-text-field>
        </div>
      </div>
      <span v-if="isCancelled" class="white--text">CANCELLED -- Save disabled</span>
      <div v-else>
        <v-row>
          <v-alert dense text class="pa-0 my-1 white--text">
            <pcheck label="Sync PAX" v-model="syncPax" />
          </v-alert>
          <save-button :onsave="saveRooms" />
        </v-row>
      </div>

      <SyncSuppliersDialog v-model="syncDialog" :specialList="specialRoomList" />
    </v-row>

    <AddRoom class="mx-0" :cancelled="isCancelled" @add="addRoom" />

    <div class="d-flex">
      <v-col class="room-wrapper pa-0 mr-2">
        <RoomGroup v-for="(r, key) in sortedRoomList" :key="r.key" :group="r" @popRoom="popRoom" />
      </v-col>
      <div style="width: 320px">
        <RoomSummary :roomList="roomList" :specialList="specialRoomList" />

        <SpecialRoomGroup :specialList="specialRoomList" />
      </div>
    </div>

    <PrintWrapper v-model="dialog" :css="printStyle">
      <template v-slot:header>
        <a-select class="white--text" widelabel label="Rooming List Date" :items="dateList" v-model="selectedDate" />
        <g-button label="Print" icon="print" class="mx-5" @onclick="printRoomingList" />
      </template>
      <RoomingListPrint id="printWrapper" :date="selectedDate" :specialList="specialRoomList" />
    </PrintWrapper>
  </div>
</template>

<script>
import format from "@/services/format";
import {
  AddRoom,
  RoomGroup,
  RoomSummary,
  SpecialRoomGroup,
  BookingRoomService,
  RoomingListPrint,
  RoomingListCss,
  RoomPrintStyle,
  ImportRooms,
  ExportRooms,
  SyncSuppliersDialog,
} from "@/modules/bookings.rooms";
import { PrintWrapper, PrintService } from "@/modules/media.print";

export default {
  components: {
    AddRoom,
    RoomGroup,
    SpecialRoomGroup,
    RoomSummary,
    ImportRooms,
    PrintWrapper,
    RoomingListPrint,
    ExportRooms,
    SyncSuppliersDialog,
  },
  data() {
    return {
      dialog: false,
      syncDialog: false,
      selectedDate: "ALL",
      syncPax: true,
      search: "",
    };
  },
  computed: {
    bookingId() {
      return this.$store.getters["BookingStore/active"].id;
    },
    roomList() {
      const rooms = this.$store.getters["BookingRoomStore/rooms"];
      if (/\S/.test(this.search)) {
        const lowerSearch = this.search.toLowerCase();
        return rooms.filter((room) => {
          for (let i = 0; i < room.nameList.length; i++) {
            const fullName = `${room.nameList[i].first} ${room.nameList[i].last}`.toLowerCase();
            if (fullName.includes(lowerSearch)) {
              return true;
            }
          }
        });
      }

      return rooms;
    },
    sortedRoomList() {
      return BookingRoomService.sortRoomList(this.roomList, [], null);
    },
    printStyle() {
      return RoomingListCss;
    },
    isCancelled() {
      return this.$store.getters["BookingStore/isCancelled"];
    },

    specialRoomList() {
      const roomList = this.$store.getters["BookingSupplierRoomStore/supplier_rooms"];
      const supplierData = Object.values(this.$store.getters["BookingDayStore/suppliers"]);
      const allowedRoomList = [];
      const disallowedRoomList = [];
      roomList.forEach((room) => {
        // all data is the same, so get only one
        const data = supplierData.find((supplier) => room.supplier_id === supplier.supplier_id);

        // if room status is not cancel, show the room
        if (data && data.status !== -1) allowedRoomList.push(room);
        else disallowedRoomList.push(room);
      });

      const dayData = [...this.$store.getters["BookingDayStore/days"]];
      const updatedDayData = [];

      // remove supplier room id
      dayData.forEach((day) => {
        const supplier_rooms = day.supplier_rooms;
        const filteredRoom = [];
        supplier_rooms.forEach((supplierRoom) => {
          let isDisallow = false;
          disallowedRoomList.forEach((disallowedRoom) => {
            if (supplierRoom === disallowedRoom.id) {
              isDisallow = true;
              return;
            }
          });

          if (!isDisallow) filteredRoom.push(supplierRoom);
        });

        day.supplier_rooms = filteredRoom;
        updatedDayData.push(day);
      });

      // return BookingRoomService.getExtraRoomList(
      //   this.$store.getters["BookingDayStore/days"],
      //   this.$store.getters["BookingSupplierRoomStore/supplier_rooms"]
      // );
      return BookingRoomService.getExtraRoomList(updatedDayData, allowedRoomList);
    },
    dateList() {
      const base = {
        label: "ALL",
        value: "ALL",
      };
      const mapping = this.$store.getters["BookingDayStore/days"].map((item) => {
        return {
          label: format.formatDate(item.date),
          value: item.date,
        };
      });

      return [base, ...mapping];
    },

    groupName() {
      return this.$store.getters["BookingStore/active"].group_name;
    },

    clientInvoiceType() {
      return this.$store.getters["BookingStore/active"].client_invoice_type;
    },
  },
  methods: {
    addRoom({ room, pax }) {
      this.$store.dispatch("BookingRoomStore/addRoom", {
        type: room,
        pax: pax,
        nameList: new Array(parseInt(pax)).fill().map((u) => ({ first: "", last: "" })),
        meta: {},
      });
    },
    popRoom(index) {
      this.$store.dispatch("BookingRoomStore/popRoom", index);
    },
    saveRooms(autosave) {
      let query = [this.$store.dispatch("BookingRoomStore/saveRooms", this.bookingId)];

      if (this.syncPax && !autosave) {
        // Save the rooming list PAX number to the main booking
        const paxNum = this.$store.getters["BookingRoomStore/totalPax"];
        this.$store.dispatch("BookingStore/update", { deep: true, key: "pax_num", val: paxNum });
        query.push(this.$store.dispatch("BookingStore/updateBooking"));
      }

      return Promise.all(query)
        .then((v) => {
          this.$root.$success("Saved booking rooming list");
          this.$roomingPing.$emit("sync");

          if (this.clientInvoiceType === "FULL")
            this.$root.$warn(
              "Saved booking rooming list, The final invoice has already been sent to the client. Please make sure to send the invoice again with the latest information."
            );

          // Open dialog to prompt user to sync confirmed suppliers with new room/pax
          this.syncDialog = true;
        })
        .catch((err) => this.$root.$error(err));
    },

    // PRINTING
    printRoomingList() {
      // const vm = this;
      const title = `${this.groupName}_RoomingList`;
      const domElement = document.getElementById("printWrapper");
      const styles = RoomPrintStyle;

      // Get and inject images here
      Promise.all([PrintService.getImageUri("inspiritLogo")]).then((imgData) => {
        var sourceHTML = domElement.outerHTML;

        // Replace Inspirit Logo with base64 (if present)
        if (imgData[0]) {
          const nuImg = `<img src="${imgData[0]}" class="logo"/>`;
          sourceHTML = sourceHTML.replace(/(?<=<span id="inspiritLogo">)(.*?)(?=<\/span>)/gm, nuImg);
        }

        PrintService.extractWordDocumentContent(title, sourceHTML, styles, {
          width: 850,
          postOpen: true,
          config: {
            landscape: false,
            right: 25,
            left: 25,
          },
        });
      });
    },
  },
  beforeDestroy() {
    if (this.isCancelled) return;
    if (this.$store.getters["BookingRoomStore/hasChanges"]) {
      this.saveRooms(true);
    }
  },
};
</script>

<style lang="scss">
.room-wrapper {
  max-height: 450px;
  overflow: auto;
}
</style>
