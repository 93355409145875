<template>
  <a-card nopad class="mx-2">
    <v-tabs v-model="tab" class="elevation-0" :grow="true">
      <v-tabs-slider></v-tabs-slider>
      <v-tab v-for="item in tabs" :key="item.key" @click="active = item.key">
        <span>{{ item.text }}</span>
        <v-icon small right color="primary" v-if="item.key === active">gps_fixed</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item key="general" class="mx-2">
        <GeneralSearch
          :value="value"
          :regionList="regionList"
          :groupList="groupList"
          :supplierListBlob="supplierListBlob"
          :showRecon="showRecon"
          :showArchive="showArchive"
          @showTotal="(v) => $emit('showTotal', v)"
          @splitTotal="(v) => $emit('splitTotal', v)"
          @updateRecShow="(v) => $emit('updateRecShow', v)"
          @updateArchive="(v) => $emit('updateArchive', v)"
          @set-supplierlist="setSupplierList"
          @input="(v) => $emit('input', { query: v, view: 'GENERAL' })"
        />
      </v-tab-item>
      <v-tab-item key="supplier" class="mx-2">
        <SupplierSearch
          :value="value"
          :groupList="groupList"
          :supplierListBlob="supplierListBlob"
          :showRecon="showRecon"
          :showArchive="showArchive"
          @showTotal="(v) => $emit('showTotal', v)"
          @splitTotal="(v) => $emit('splitTotal', v)"
          @updateRecShow="(v) => $emit('updateRecShow', v)"
          @updateArchive="(v) => $emit('updateArchive', v)"
          @set-supplierlist="setSupplierList"
          @input="(v) => $emit('input', { query: v, view: 'SUPPLIER' })"
        />
      </v-tab-item>
    </v-tabs-items>
  </a-card>
</template>

<script>
import Vue from "vue";
import ENV from "@/config/ENV";
import PLACES from "@/config/fields/PLACES";
import GeneralSearch from "./search/_GeneralSearch";
import SupplierSearch from "./search/_SupplierSearch";
export default {
  props: {
    value: Object,
    showRecon: Array,
    showArchive: Array,
  },
  components: {
    GeneralSearch,
    SupplierSearch,
  },
  data() {
    return {
      tab: 0,
      tabs: [
        {
          text: "General",
          key: "general",
        },
        {
          text: "Supplier",
          key: "supplier",
        },
      ],

      // DATA
      regionDumpBlob: {},
      supplierListBlob: [],
      groupListBlob: [],
    };
  },
  computed: {
    active: {
      get() {
        const saved = this.$store.getters["FinanceStore/activeTab"];
        return saved || "general";
      },
      set() {
        // Not set here
      },
    },
    env() {
      return this.$store.getters["AccountStore/environment"];
    },
    country() {
      return ENV.find((item) => item.value === this.env).defaultCountry;
    },
    regionList() {
      const list = PLACES.REGIONS[this.country].REGIONS;
      return list.filter((v) => {
        return this.regionDumpBlob[v.value];
      });
    },
    groupList() {
      return this.groupListBlob
        .filter((item) => {
          if (!this.client) return true;
          return this.client.id === item.client_id;
        })
        .map((item) => {
          return item.group_name;
        })
        .sort((a, b) => {
          if (a > b) return 1;
          if (b > a) return -1;
          return 0;
        });
    },
  },
  methods: {
    setSavedSearch() {
      if (!this.active) return;

      // Get saved tab (if exists)
      const savedTab = this.$store.getters[`FinanceStore/${this.active}Search`] || {};
      const index = this.tabs.findIndex((item) => this.active === item.key);
      this.tab = index;

      // Query existing search
      this.$emit("input", { query: savedTab, view: this.active.toUpperCase() });
    },
    setSupplierList(options) {
      this.$store.dispatch("BookingStore/getSupplierListForBookings", options || {}).then((supplierListBlob) => {
        this.supplierListBlob = supplierListBlob
          .map((v) => {
            Vue.set(this.regionDumpBlob, v.region, 1);
            return {
              sortName: v.name,
              label: `${v.name} (${v.city || "Nationwide"}${v.subcity ? " / " + v.subcity : ""})`,
              value: v.supplier_id,
              bookingStatusList: v.bookingStatusList,
            };
          })
          .sort((a, b) => {
            if (a.sortName > b.sortName) return 1;
            if (a.sortName < b.sortName) return -1;
            return 0;
          });
      });
    },
  },
  mounted() {
    this.$store.dispatch("FinanceStore/getGroupListWithClients").then((groupListBlob) => {
      this.groupListBlob = groupListBlob || [];
    });

    this.setSupplierList();
    // this.setSavedSearch();
  },
};
</script>
