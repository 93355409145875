<template>
  <v-col>
    <v-row :justify="admin ? 'space-between' : 'end'" class="mx-0 mb-1">
      <delete-button v-if="admin" quiet headline="Delete Booking?" :ondelete="deleteClient" />

      <save-button :onsave="saveClient" />
    </v-row>

    <v-row>
      <v-col cols="6">
        <ClientInfo />
        <ClientAddressInfo />
      </v-col>

      <v-col cols="6">
        <PrimaryContactInfo />
        <ClientCategory />
        <ClientType />
      </v-col>
      <v-col><ClientDepositPercent /></v-col>
    </v-row>

    <BookingTable
      v-if="Object.values(query.client).length > 1"
      :grouped="false"
      :query="query"
      class="mx-2 table"
      ref="editDBwrapper"
      :height="dbHeight"
    />
  </v-col>
</template>

<script>
import {
  ClientInfo,
  ClientAddressInfo,
  PrimaryContactInfo,
  ClientCategory,
  ClientType,
  ClientDepositPercent,
} from "@/modules/clients";
import { BookingTable } from "@/modules/bookings";

export default {
  components: {
    ClientInfo,
    ClientAddressInfo,
    PrimaryContactInfo,
    ClientCategory,
    ClientType,
    ClientDepositPercent,
    BookingTable,
  },
  data() {
    return {
      dbHeight: "400px",
    };
  },
  computed: {
    admin() {
      return this.$store.getters["isAdmin"];
    },
    query() {
      return { client: this.$store.getters["ClientStore/active"] };
    },
  },
  methods: {
    deleteClient() {
      return this.$store
        .dispatch("ClientStore/deleteClient")
        .then((v) => {
          this.$root.$success("Deleted Client");
          this.$router.push({
            name: "database_view",
            params: {
              view: "clients",
            },
          });
        })
        .catch((err) => this.$root.$error(err));
    },
    saveClient() {
      return this.$store
        .dispatch("ClientStore/updateClient")
        .then((v) => this.$root.$success("Updated Client"))
        .catch((err) => this.$root.$error(err));
    },
    getClientQueryDB() {
      return { client: this.$store.getters["ClientStore/active"] };
    },
  },
  beforeDestroy() {
    // Save on tab change
    if (this.$store.getters["ClientStore/hasChanges"]) {
      this.saveClient();
    }
  },
  mounted() {
    this.$nextTick().then(function (res) {
      if (!res.$refs.editDBwrapper) return;
      res.dbHeight = res.$refs.editDBwrapper.$el.clientHeight + "px";
    });
  },
};
</script>
