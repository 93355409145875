<template>
  <div class="calendar">
    <g-button v-if="addButton" class="add-button" label="Add" icon="add" type="primary" @onclick="$emit('add')" />

    <div class="calendar-header">
      <v-btn icon @click="subtractMonth">
        <v-icon>keyboard_arrow_left</v-icon>
      </v-btn>
      <h4>{{ month + " - " + year }}</h4>
      <v-btn icon @click="addMonth">
        <v-icon>keyboard_arrow_right</v-icon>
      </v-btn>
    </div>

    <v-row class="weekdays">
      <v-col v-for="(day, index) in days" :key="`date${index}`">{{ day }}</v-col>
    </v-row>
    <v-row class="dates">
      <v-col v-for="blank in firstDayOfMonth" class="prev-date" :key="`prev${blank}`">
        <span>{{ blank }}</span>
      </v-col>
      <v-col
        v-for="date in daysInMonth"
        :key="`curr${date.day}`"
        class="cal-hover d-flex"
        :class="{'current-day': date == initialDate &amp;&amp; month == initialMonth && year == initialYear, 'focus-date': date.full === focusDate}"
        @click="focusOnDate(date.day)"
      >
        <span>{{ date.day }}</span>
        <div v-if="date.count" class="mx-2 caption white--text font-weight-bold">
          <div v-if="date.count.warn" class="px-2 red">{{ date.count.warn }}</div>
          <div v-if="date.count.soon" class="px-2 warning">{{ date.count.soon }}</div>
          <div v-if="date.count.leftover" class="px-2 grey">{{ date.count.leftover }}</div>
          <div v-if="date.count.done" class="px-2 green">{{ date.count.done }}</div>
        </div>
      </v-col>
      <v-col v-for="blank in lastDaysOfMonth" class="prev-date" :key="`after${blank}`">
        <span>{{ blank }}</span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    mode: String,
    addButton: Boolean,
    reminderMapping: Object,
    dateStart: String,
    focusDate: String,
  },
  data() {
    return {
      today: moment(),
      dateContext: this.mode === "BOOKING" ? moment(this.dateStart) : moment(),
      days: ["S", "M", "T", "W", "T", "F", "S"],
    };
  },
  watch: {
    focusDate(v) {
      this.dateContext = moment(v);
      this.updateFirstAndLast();
    },
  },
  computed: {
    year: function () {
      var t = this;
      return t.dateContext.format("Y");
    },
    month: function () {
      var t = this;
      return t.dateContext.format("MMMM");
    },
    daysInMonth: function () {
      var t = this;
      return Array.from({ length: t.dateContext.daysInMonth() }, (_, i) => i + 1).map((num) => {
        return {
          day: num,
          full: moment().year(this.year).month(this.month).date(num).format("YYYY-MM-DD"),
          count: this.getCount(num),
        };
      });
    },

    currentDate() {
      return this.focusDate || this.dateContext.get("date");
    },

    firstDayOfMonth: function () {
      var t = this;
      var first = moment().year(this.year).month(this.month).startOf("month");
      var last = moment().year(this.year).month(this.month).subtract(1, "months").endOf("month").get("date");
      var weekOffset = first.weekday();

      return Array.from({ length: weekOffset }, (_, i) => {
        return last - i;
      }).reverse();
    },
    lastDaysOfMonth() {
      var t = this;
      var last = moment().year(this.year).month(this.month).endOf("month");
      return Array.from({ length: 6 - last.weekday() }, (_, i) => i + 1);
    },

    initialDate: function () {
      var t = this;
      return this.mode === "BOOKING" ? moment(this.dateStart).get("date") : t.today.get("date");
    },
    initialMonth: function () {
      var t = this;
      return this.mode === "BOOKING" ? moment(this.dateStart).format("MMMM") : t.today.format("MMMM");
    },
    initialYear: function () {
      var t = this;
      return this.mode === "BOOKING" ? moment(this.dateStart).format("Y") : t.today.format("Y");
    },
  },
  methods: {
    addMonth: function () {
      var t = this;
      t.dateContext = moment(t.dateContext).add(1, "month");
      this.updateFirstAndLast();
    },
    subtractMonth: function () {
      var t = this;
      t.dateContext = moment(t.dateContext).subtract(1, "month");
      this.updateFirstAndLast();
    },

    updateFirstAndLast() {
      this.$emit("updateDates", {
        dateStart: this.dateContext.startOf("month").format("YYYY-MM-DD"),
        dateEnd: this.dateContext.endOf("month").format("YYYY-MM-DD"),
      });
    },

    focusOnDate(day) {
      const focus = moment().year(this.year).month(this.month).date(day);
      this.$emit("onfocus", focus.format("YYYY-MM-DD"));
    },

    getCount(day) {
      const focus = moment().year(this.year).month(this.month).date(day).format("YYYY-MM-DD");
      const list = this.reminderMapping[focus];
      if (!list || !list.length) return null;
      let o = { done: 0, warn: 0, leftover: 0, soon: 0 };
      list.forEach((item) => {
        if (item.done_date) {
          o.done += 1;
        } else if (moment(focus).diff(moment(item.data), "hours") <= 72) {
          o.warn += 1;
        } else if (moment(focus).diff(moment(item.data), "days") <= 14) {
          o.soon += 1;
        } else {
          o.leftover += 1;
        }
      });
      return o;
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.calendar {
  background: white;
  position: relative;

  .calendar-header {
    display: flex;
    align-items: center;
    justify-content: center;

    h4 {
      width: 150px;
      text-align: center;
    }
  }

  .add-button {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  .cal-hover:hover {
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 0 5px grey;
  }

  .weekdays .col,
  .dates .col {
    width: 14%;
    min-width: 14%;
  }

  .prev-date {
    background: lightgrey;
    opacity: 0.5;
  }

  .focus-date {
    background: lightblue;
    color: blue;
    font-weight: bold;
  }

  .current-day {
    background: lightblue;
    color: blue;
    font-weight: bold;
  }
}
</style>
