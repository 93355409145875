<template>
  <div>
    <v-data-table
      disable-pagination
      hide-default-footer
      :headers="headers"
      :items="displayData"
      :height="height"
      class="database-table overview"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.date" class="database-row">
            <td class="pa-2 overview-block" style="min-width: 60px; max-width: 60px; vertical-align: top">
              <span>{{ item.day }} ({{ item.weekday }})</span>
            </td>
            <td class="pa-2 overview-block" style="min-width: 60px; max-width: 60px; vertical-align: top">
              <span>{{ item.displayDate }}</span>
            </td>
            <td
              class="pa-2 overview-block"
              @click="openCityDialog(item)"
              style="min-width: 80px; max-width: 80px; vertical-align: top"
            >
              <v-hover v-slot="{ hover }">
                <v-row align="center" class="ma-0 pa-0 flex-nowrap">
                  <v-icon v-if="hover" x-small>edit</v-icon>
                  <a-truncate :text="item.city" :hoverText="item.city" />
                </v-row>
              </v-hover>
            </td>
            <OverviewRow
              :suppliers="item.hotels"
              :date="item.date"
              typeAs="HOTEL"
              @add="openDialog('HOTEL', item)"
              @edit="(v) => editSupplier(v, item)"
            />
            <OverviewRow
              :suppliers="item.restaurants"
              :date="item.date"
              typeAs="RESTAURANT"
              @add="openDialog('RESTAURANT', item)"
              @edit="(v) => editSupplier(v, item)"
            />
            <OverviewRow
              :suppliers="item.parks"
              :date="item.date"
              typeAs="PARK"
              @add="openDialog('PARK', item)"
              @edit="(v) => editSupplier(v, item)"
            />
            <OverviewRow
              :suppliers="item.excursions"
              :date="item.date"
              typeAs="EXCURSION"
              @add="openDialog('EXCURSION', item)"
              @edit="(v) => editSupplier(v, item)"
            />
            <OverviewRow
              :suppliers="item.transport"
              :date="item.date"
              typeAs="TRANSPORT"
              @add="openDialog('TRANSPORT', item)"
              @edit="(v) => editSupplier(v, item)"
            />
            <OverviewRow
              :suppliers="item.tour_guides"
              :date="item.date"
              typeAs="TOUR_GUIDE"
              @add="openDialog('TOUR_GUIDE', item)"
              @edit="(v) => editSupplier(v, item)"
            />
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <OverviewTotalPrice :typeAs="'HOTEL'" />
            <OverviewTotalPrice :typeAs="'RESTAURANT'" />
            <OverviewTotalPrice :typeAs="'PARK'" />
            <OverviewTotalPrice :typeAs="'EXCURSION'" />
            <OverviewTotalPrice :typeAs="'TRANSPORT'" />
            <OverviewTotalPrice :typeAs="'TOUR_GUIDE'" />
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <CityDialog v-model="cityDialog" :editCityDay="editCityDay" />

    <SelectSupplierDialog
      v-model="selectDialog"
      mode="BOOKING"
      :startDay="selectDay"
      :module="selectModule"
      @addalt="openAlternate"
    />

    <SelectSupplierDialog
      v-model="editDialog"
      edit
      mode="BOOKING"
      :startDay="selectDay"
      :module="selectModule"
      :editBookingSupplier="editSupplierInfo"
      @replace="openReplaceDialog"
      @addalt="openAlternate"
    />

    <SelectAltSupplierDialog
      v-model="altdialog"
      :supplierInfo="editSupplierInfo"
      :editType="selectModule"
      :dayIndex="selectDayIndex"
      :lastDay="selectLastDay"
      :bookingDayYear="bookingDayYear"
    />

    <ReplaceSupplierDialog
      v-model="replaceDialog"
      mode="BOOKING"
      :module="replaceType"
      :supplierToReplace="supplierToReplace"
    />
  </div>
</template>

<script>
import STATUS_COLORS from "@/config/STATUS_COLORS";
import SUPMENU from "@/config/menus/suppliersMenu";
import format from "@/services/format";
import CityDialog from "./CityDialog";
import OverviewRow from "./_OverviewRow";
import OverviewTotalPrice from "./_OverviewTotalPrice";
import { SelectSupplierDialog, SelectAltSupplierDialog, ReplaceSupplierDialog } from "@/modules/bookings.select_dialog";
export default {
  props: {
    height: [Number, String],
  },
  components: {
    OverviewRow,
    CityDialog,
    SelectSupplierDialog,
    SelectAltSupplierDialog,
    ReplaceSupplierDialog,
    OverviewTotalPrice,
  },
  data() {
    return {
      headers: [
        {
          text: "Day",
          value: "day",
        },
        {
          text: "Date",
          value: "date",
        },
        {
          text: "City",
          value: "city",
        },
        {
          text: "Hotels",
          value: "hotes",
        },
        {
          text: "Restaurants",
          value: "restaurants",
        },
        {
          text: "Parks",
          value: "parks",
        },
        {
          text: "Excursions",
          value: "excursions",
        },
        {
          text: "Transportation",
          value: "transport",
        },
        {
          text: "Tour Guides",
          value: "tour_guides",
        },
      ],

      cityDialog: false,
      editCityDay: null,

      selectDialog: false,
      selectDay: null,
      selectModule: null,
      selectDayIndex: 0,
      selectLastDay: null,
      bookingDayYear: null,

      editDialog: false,
      altdialog: false,
      editSupplierInfo: {},

      replaceDialog: false,
      replaceType: null,
      supplierToReplace: {},
    };
  },
  computed: {
    displayData() {
      const days = this.$store.getters["BookingDayStore/days"];
      const suppliers = this.$store.getters["BookingDayStore/suppliers"];

      return days.map((day, index) => {
        return {
          day: index + 1,
          date: day.date,
          weekday: format.getWeekday(day.date),
          displayDate: format.formatDate(day.date, true),
          city: _extractCity(index === 0 ? {} : days[index - 1], day, suppliers),
          hotels: _extractSupplierNames(day.hotels, suppliers),
          restaurants: _extractSupplierNames(day.restaurants, suppliers),
          transport: _extractSupplierNames(day.transport, suppliers),
          parks: _extractSupplierNames(day.parks, suppliers),
          tour_guides: _extractSupplierNames(day.tour_guides, suppliers),
          excursions: _extractSupplierNames(day.excursions, suppliers),
        };
      });
    },
    isCancelled() {
      return this.$store.getters["BookingStore/isCancelled"];
    },
  },
  methods: {
    openCityDialog(day) {
      this.editCityDay = day;
      this.cityDialog = true;
    },

    // EDITING DIALOGS
    openDialog(mod, dateInfo) {
      if (this.isCancelled) return;
      this.selectDialog = true;
      this.selectModule = mod;
      this.selectDay = dateInfo.date;
      this.selectDayIndex = dateInfo.day - 1;
    },
    editSupplier(bsid, dateInfo) {
      if (this.isCancelled) return;
      const supplierInfo = this.$store.getters["BookingDayStore/oneSupplier"](bsid);

      this.editDialog = true;
      this.editSupplierInfo = supplierInfo;
      this.selectModule = supplierInfo.type_as;
      this.selectDay = dateInfo.date;
      this.selectDayIndex = dateInfo.day - 1;
    },
    openAlternate(bsid) {
      const bookingSupplier = this.$store.getters["BookingDayStore/oneSupplier"](bsid);

      this.editSupplierInfo = bookingSupplier;
      this.altdialog = true;
    },
    openReplaceDialog(supplierInfo) {
      this.supplierToReplace = supplierInfo;
      this.replaceType = supplierInfo.type_as;
      this.editDialog = false;
      this.replaceDialog = true;
    },
    getSuppliersBySupplierType(type) {
      return this.displayData.flatMap((day) => day[type]);
    },
  },
};

function _extractCity(prevDay, curDay, suppliers) {
  let prevDayBsid = __getNonNested(prevDay.hotels || [], suppliers)[0];
  let curDayBsid = __getNonNested(curDay.hotels || [], suppliers)[0];

  let fromCity = prevDay.city || (suppliers[prevDayBsid] || {}).city;
  let toCity = curDay.city || (suppliers[curDayBsid] || {}).city;

  return (fromCity ? `${fromCity || "?"} to ` : "") + (toCity || "");
}

function _extractSupplierNames(arr, suppliers) {
  if (!arr) return "";
  return __getNonNested(arr, suppliers)
    .map((supid) => {
      const color = (Object.values(STATUS_COLORS.COLORS).find((c) => c.value === suppliers[supid].status) || {})
        .bgcolor;

      const sup = suppliers[supid];
      return {
        id: supid,
        color,
        name: sup.name,
        sort_time: sup.time ? sup.time : null,
        time: sup.time ? format.formatTime(sup.time) : null,
      };
    })
    .sort((a, b) => {
      if (a.sort_time > b.sort_time) return 1;
      if (a.sort_time < b.sort_time) return -1;
      return 0;
    });
}

function __getNonNested(arr, suppliers) {
  return arr.filter((supid) => {
    let sup = suppliers[supid];
    if (sup.status < 0) return false;
    if (sup.parent_bsid && !["BREAKFAST", "LUNCH", "DINNER"].includes(sup.nest_type)) return false;
    return true;
  });
}
</script>

<style lang="scss">
.overview-block {
  font-size: 10px !important;
}
</style>
