import api from "./api";
import Vue from "vue";

const namespaced = true;

const SET_CONFIG = "SET_CONFIG";
const SET_ALL_CONFIG = "SET_ALL_CONFIG";

const state = {
  TECHNICAL_WARN_HEADER: null,
  TECHNICAL_ATTENTION: null,
  TECHNICAL_FOOTER: null,
};

const mutations = {
  SET_ALL_CONFIG(state, list) {
    list.forEach((item) => {
      state[item.refkey] = item.text;
    });
  },
  SET_CONFIG(state, data) {
    Vue.set(state, data.refkey.data.text);
  },
};

const actions = {
  getTextDefaultConfiguration({ commit }, env) {
    return api
      .getTextDefaultConfiguration(env)
      .then((data) => {
        commit(SET_ALL_CONFIG, data.data);
        // data.data.forEach(item => {
        // 	commit(SET_CONFIG, item)
        // })
      })
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  getOneTextDefault({ commit }, { env, refkey }) {
    return api
      .getOneTextDefault(env, refkey)
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  updateTextDefaultConfiguration({ commit }, { curenv, env, refkey, text }) {
    return api
      .updateTextDefaultConfiguration(env, refkey, text)
      .then((data) => {
        if (env === curenv) {
          commit(SET_CONFIG, data);
        }
      })
      .catch((err) => {
        if (err) throw err.data;
      });
  },
};

const getters = {
  TECHNICAL_WARN_HEADER: (state) => state.TECHNICAL_WARN_HEADER,
  TECHNICAL_ATTENTION: (state) => state.TECHNICAL_ATTENTION,
  TECHNICAL_FOOTER: (state) => state.TECHNICAL_FOOTER,
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
