<template>
  <v-card class="booking-group-datatable height">
    <v-row v-if="!rows.length" class="mx-0 px-3">
      <span v-if="query.status === null" class="caption">(Waiting for search input)</span>
      <span v-else class="caption">(No data found)</span>
    </v-row>

    <div :style="groupedTableStyle">
      <div v-for="row in rows" :key="row.id" class="booking-search-container">
        <v-row
          @click="goToPage(row)"
          class="booking-row-header px-0 grey darken-1 font-weight-bold caption elevation-1"
        >
          <v-col style="max-width: 140px">{{ row.client_name }}</v-col>
          <v-col>{{ row.group_name }}</v-col>
          <v-col class="font-weight-regular brh-hover" style="max-width: 50px">From: </v-col>
          <v-col class="brh-hover">{{ getDate(row.from) }}</v-col>
          <v-col class="font-weight-regular brh-hover" style="max-width: 30px">To: </v-col>
          <v-col class="brh-hover">{{ getDate(row.to) }}</v-col>
          <v-col class="font-weight-regular brh-hover">({{ getNightCount(row.from, row.to) }} nights)</v-col>
          <v-col class="font-weight-regular brh-hover" style="max-width: 50px">Start: </v-col>
          <v-col class="brh-hover">{{ row.where_start }}</v-col>
          <v-col class="font-weight-regular brh-hover" style="max-width: 50px">End: </v-col>
          <v-col class="brh-hover">{{ row.where_end }}</v-col>
        </v-row>

        <BookingGroupedRow
          v-for="day in getFilteredDays(row)"
          :key="day.id"
          :dayIndex="flatten ? day.dayIndexList[0] : day.dayIndex"
          :dayInfo="day"
          :booking="row"
          :supplierMapping="row.booking_suppliers"
          :allSuppliers="row.all_booking_suppliers"
          :query="query"
          :flatten="flatten"
          @altstatus="(v) => openChangeStatus({ booking: row, ...v })"
          class="caption"
        />

        <v-divider />
      </div>

      <infinite-loading spinner="waveDots" :identifier="identifyScrollRef" @infinite="infiniteHandler">
        <div slot="no-more">
          <div class="overline ma-2">(End)</div>
        </div>
        <div slot="no-results"></div>
      </infinite-loading>
    </div>

    <StatusDialog
      mode="OUT"
      v-model="statusDialog"
      :focusBookingSupplier="supplierDialogData"
      :type="statusType"
      :booking="selectedBooking"
      :bookingSuppliers="selectedBookingSuppliers"
      :flattenDays="selectedBookingFlattenDays"
      :dayinfo="selectedDayInfo"
      @update="updateSelectedStatus"
    />
  </v-card>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import SUPMENU from "@/config/menus/suppliersMenu";
import format from "@/services/format";
import InfiniteLoading from "vue-infinite-loading";
import BookingGroupedRow from "./_BookingGroupedRow";
import { StatusDialog, EditSupplierRoomNameDialog, EditConfirmNumberDialog } from "@/modules/bookings.days";
export default {
  props: {
    query: Object,
    height: String,
  },
  components: {
    InfiniteLoading,
    BookingGroupedRow,
    StatusDialog,
  },
  data() {
    return {
      headers: [
        {
          text: "Client",
          value: "client_name",
        },
        {
          text: "Group Name",
          value: "group_name",
        },
        {
          text: "Date From",
          value: "from",
        },
        {
          text: "Date To",
          value: "to",
        },
        {
          text: "City Start",
          value: "where_start",
        },
        {
          text: "City End",
          value: "where_end",
        },
      ],

      rows: [],
      loading: true,
      page: 0,

      firstRender: false, // Infinite component does first query
      identifyScrollRef: new Date().getTime(),

      statusDialog: false,
      supplierDialogData: {},
      selectedBooking: {},
      selectedBookingSuppliers: {},
      selectedBookingFlattenDays: [],
      selectedDayInfo: [],
      statusType: "",
    };
  },
  computed: {
    groupedTableStyle() {
      return {
        height: this.height,
        overflow: "auto",
      };
    },
    flatten() {
      if (this.query && this.query.supplier_type && ["TOUR_GUIDE", "TRANSPORT"].includes(this.query.supplier_type)) {
        return true;
      }
      return false;
    },
  },
  watch: {
    query(searchQuery) {
      if (this.queryActive) return;

      if (this.firstRender) {
        this.resetScrolling();
        this.identifyScrollRef = new Date().getTime();
      }
    },
  },
  methods: {
    goToPage(item) {
      let firstDay = item.days.findIndex((d) => {
        return SUPMENU.FULL.map((val) => val.alt).find((key) => d[key] && d[key].length);
      });
      this.$router.push({
        name: "booking_day",
        params: {
          booking_id: item.id,
          day: firstDay + 1,
        },
      });
    },
    getDate(date) {
      return format.formatDate(date);
    },
    getNightCount(from, to) {
      return format.getNumberOfDays(from, to);
    },
    includeInDisplay(bs) {
      if (this.query.supplier_type && this.query.supplier_type === "HOTEL") {
        // Query returns suppliers with rooms assigned
        // But these aren't hotels, so hide them here
        if (["TRANSPORT", "TOUR_GUIDE", "RESTAURANT"].includes(bs.type_as)) return false;
      }
      return true;
    },
    getFilteredDays(rowData) {
      let dayList = rowData.days
        .map((day, index) => {
          // Need day index (otherwise will be filted out)
          return {
            dayIndex: index,
            ...day,
          };
        })
        .filter((dayInfo) => {
          // Only show days where suppliers exist
          let hasSupplier = false;
          SUPMENU.FULL.forEach((item) => {
            if (!dayInfo[item.alt]) return;
            dayInfo[item.alt].forEach((bsid) => {
              // Skip showing tour guides, transport, restaurant if showing hotels only
              if (!this.includeInDisplay(rowData.booking_suppliers[bsid])) {
                return;
              }
              // Regular filter out
              if (rowData.booking_suppliers[bsid]) hasSupplier = true;
            });
          });
          return hasSupplier;
        });

      // Flatten and group blobs
      if (this.flatten) {
        let semigroupded = {};
        let ref = {
          TOUR_GUIDE: "tour_guides",
          TRANSPORT: "transport",
        };

        dayList.forEach((day) => {
          // Right now, focus on tourguides
          day[ref[this.query.supplier_type]].forEach((bsid) => {
            if (!semigroupded[bsid]) {
              semigroupded[bsid] = {
                type: ref[this.query.supplier_type],
                startDay: day.date, // ASSUMING IN ORDER
                startIndex: day.dayIndex,
                dateList: [],
                dayIndexList: [],
              };
            }

            semigroupded[bsid].dateList.push(day.date);
            semigroupded[bsid].dayIndexList.push(day.dayIndex);
          });
        });

        // Merge booking supplier key
        dayList = Object.keys(semigroupded).map((bsid) => {
          return {
            ...semigroupded[bsid],
            [semigroupded[bsid].type]: [bsid],
          };
        });
      }

      return dayList;
    },

    resetScrolling() {
      this.page = 0;
      this.rows = [];
      this.queryActive = true;
      this.$emit("setComplete", false);
    },
    queryDatabase(searchQuery) {
      this.loading = true;

      return this.$store
        .dispatch("BookingStore/getBookings", { page: this.page, query: searchQuery })
        .then((rows) => {
          this.loading = false;

          // First set of data
          if (!this.rows.length && rows.length) {
            this.rows = rows.filter((item) => item.days);
            return { rows: [], continue: true };
          }
          return { rows: rows.filter((item) => item.days), continue: false };
        })
        .catch((err) => {
          this.loading = false;
          this.$root.$error(err);
        });
    },

    infiniteHandler($state) {
      if (this.query.status === null) {
        this.rows = [];
        this.firstRender = true;
        this.queryActive = false;
        $state.complete();
        this.$emit("setComplete", true);
        return;
      }

      this.queryDatabase(this.query)
        .then((data) => {
          this.firstRender = true;
          this.queryActive = false;

          if (data.rows.length || data.continue) {
            this.page += 1;
            this.rows.push(...data.rows);
            $state.loaded();

            // If less than 50, assume complete
            if (data.rows.length === 50 || data.continue) return;
          }

          this.$emit("setComplete", true);
          $state.complete();
        })
        .catch((err) => {
          $state.error();
        });
    },

    openChangeStatus({ booking, supplier, type }) {
      // Supplier specific
      this.supplierDialogData = supplier;
      this.statusType = type;

      // Booking stuff
      this.selectedBooking = booking;
      this.selectedBookingFlattenDays = booking.days.map((day) => {
        return {
          date: day.date,
          suppliers: SUPMENU.FULL.reduce((arr, x) => {
            if (x.alt === "ALL" || !day[x.alt]) return arr;
            return [...arr, ...day[x.alt]];
          }, []),
        };
      });
      this.selectedBookingSuppliers = booking.booking_suppliers;
      if (!this.statusType) {
        this.selectedDayInfo = [];
      } else {
        const mod = SUPMENU.FULL.find((v) => v.module === this.statusType);
        this.selectedDayInfo = booking.days.map((item) => {
          return {
            date: item.date,
            suppliers: item[mod.alt],
          };
        });
      }

      this.statusDialog = true;
    },
    updateSelectedStatus(data) {
      Vue.set(this.supplierDialogData, "status", data.status);
      Vue.set(this.supplierDialogData, "meta", data.meta);
    },
  },
};
</script>

<style lang="scss">
.booking-group-datatable {
  .booking-group-datatable-header {
    height: 48px;

    .brh-wrapper {
      height: 100%;
    }

    .brh-col {
      padding: 0 16px !important;
      align-items: center;
      display: flex;
    }
  }

  .booking-search-container {
    display: flex;
    flex-direction: column;

    .booking-row-header {
      padding: 0 16px !important;
      position: -webkit-sticky;
      position: sticky;
      color: white;
      top: 0;
      z-index: 5;

      .brh-hover {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;
        background: rgba(10, 10, 10, 0.3) !important;
        color: black;

        .brh-hover {
          opacity: 1;
        }
      }
    }
  }
}
</style>
