<template>
  <v-toolbar dense flat>
    <v-toolbar-title>
      <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
    </v-toolbar-title>
  </v-toolbar>
</template>

<script>
export default {
  props: {
    activelabel: String,
  },
  computed: {
    querytype() {
      return this.$route.query.type;
    },
    breadcrumbs() {
      return [
        {
          text: "Database",
          to: { name: "database" },
        },
        {
          text: "Suppliers",
          to: {
            name: "database_view",
            params: {
              view: "suppliers",
              ...(this.querytype && { sub: this.querytype }),
            },
          },
        },
        {
          text: this.activelabel,
        },
      ];
    },
  },
};
</script>
