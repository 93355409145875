import examples from "libphonenumber-js/examples.mobile.json";
import { getExampleNumber, getExtPrefix } from "libphonenumber-js";

const FIXED = {
  FR: {
    example: "5 61 73 08 45",
    prefix: "ext.",
  },
  JP: {
    example: ["90 123-4567", "12 3456-7890"],
    // 940-12-3456
    // 92-123-4567
    // 80-1234-5678
    prefix: "ext.",
  },
};

export default function (country) {
  let formatted, prefix;

  if (FIXED[country]) {
    const d = FIXED[country];
    formatted = d.example;
    prefix = d.prefix;
  } else {
    // Randomly generate phone number
    const temp = getExampleNumber(country, examples);
    // Extract mask
    formatted = temp.formatNational();
    // Extension prefix
    prefix = getExtPrefix(country);
  }

  // Calling Code (if not US)
  // + formatted template
  // + extension option
  let base = typeof formatted === "string" ? [formatted] : formatted;

  // Else assuming array
  return base.map((number) => {
    return number.replace(/[0-9]/g, "#");
  });
}
