<template>
  <v-tabs v-model="tab" dense>
    <v-tabs-slider></v-tabs-slider>
    <v-tab v-for="(v, i) in tabs" :key="i" :to="v.to">
      {{ v.text }}
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  props: {
    bookingId: String,
  },
  data() {
    return {
      tab: 0,
    };
  },
  computed: {
    tabs() {
      if (!this.bookingId) return [];
      return [
        {
          text: "Information",
          to: { name: "finance_report", params: { booking_id: this.bookingId } },
        },
        {
          text: "Documents",
          to: { name: "finance_documents", params: { booking_id: this.bookingId } },
        },
      ];
    },
  },
};
</script>

<style></style>
