<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)" width="400" persistent>
    <v-card>
      <v-card-title> Set Client Invoice </v-card-title>
      <v-card-text class="black--text">
        <v-row justify="center">
          <v-btn-toggle v-model="invoiceType" mandatory>
            <v-btn value="FULL" small> Full </v-btn>
            <v-btn value="PARTIAL" small> Partial </v-btn>
            <v-btn value="DEPOSIT" small> Deposit </v-btn>
          </v-btn-toggle>
        </v-row>

        <v-row>
          <v-col class="subtitle-2" cols="3" align-self="center"> Set Type </v-col>

          <v-col class="d-flex justify-end">
            <span v-if="invoiceType === 'DEPOSIT'" class="primary white--text pa-1">Deposit Only</span>
            <span v-else-if="invoiceType === 'PARTIAL'" class="warning white--text pa-1">Partial Invoice</span>
            <span v-else class="green white--text pa-1">Full Invoice</span>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="subtitle-2" cols="3" align-self="center"> Amount </v-col>

          <v-col class="d-flex justify-end">
            <div class="d-flex align-center pr-1">$</div>
            <f-number :value="displayNumber" @input="(v) => (invoiceValue = v)" />
            <v-btn icon small @click="invoiceValue = 0">
              <v-icon small>clear</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row class="grey lighten-2 mt-2 subtitle-2">
          <v-col cols="4" align-self="center"> New Total </v-col>
          <v-col class="d-flex justify-end"> $ {{ invoiceTotal }} </v-col>
        </v-row>

        <v-row>
          <v-col class="subtitle-2" cols="3" align-self="center"> Date Sent </v-col>
          <v-col class="d-flex justify-end">
            <f-date :value="dateSent" :datewallEnd="endDate" @input="(v) => (invoiceDate = v)" />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="subtitle-2" cols="3" align-self="start"> Notes </v-col>
          <v-col class="d-flex justify-end">
            <f-textarea v-model="notes" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <g-button @onclick="$emit('input', false)" label="Cancel" />
        <div class="flex-grow-1"></div>
        <g-button type="primary" :disabled="!displayNumber" @onclick="addInvoice" label="Update" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import money from "@/services/money";
import format from "@/services/format";
export default {
  props: {
    value: Boolean,
    totalInvoice: [Number, String],
    invoiceSentDate: String,
  },
  components: {},
  data() {
    return {
      invoiceType: "FULL",
      endDate: format.addDay(format.convertDate(new Date())),

      invoiceValue: null,
      invoiceDate: format.convertDate(new Date()),

      notes: null,
    };
  },
  computed: {
    displayNumber() {
      return this.invoiceValue; //|| this.totalInvoice
    },
    dateSent() {
      return this.invoiceDate || this.invoiceSentDate || "";
    },
    invoiceTotal() {
      const all = this.$store.getters["FinanceReportStore/client_invoice_total"];
      return money.format(all + money._num(this.invoiceValue || 0), { precision: 2 });
    },
  },
  methods: {
    addInvoice() {
      this.$emit("update", {
        invoiceValue: this.displayNumber,
        invoiceDate: this.dateSent,
        invoiceType: this.invoiceType,
        notes: this.notes,
      });

      this.invoiceValue = null;
      this.invoiceDate = format.convertDate(new Date());
      this.notes = null;
    },
  },
};
</script>
