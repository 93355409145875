<template>
  <table>
    <tr>
      <td>
        <span v-if="item && item.mid"><!-- SHOW NOTHING --></span>
        <span v-else>{{ time }}</span>
      </td>
      <td>
        <div v-html="text"></div>
      </td>
    </tr>
  </table>
</template>

<script>
import format from "@/services/format";
export default {
  props: {
    item: Object,
  },
  computed: {
    time() {
      if (!this.item || !this.item.time) return;

      var t = this.item.time.split(":");
      var hour = Number(t[0]);

      return hour === 0
        ? `12:${t[1]} AM`
        : hour === 12
        ? `12:${t[1]} PM`
        : hour < 12
        ? `${hour}:${t[1]} AM`
        : `${hour - 12}:${t[1]} PM`;
    },
    text() {
      if (!this.item) return "";
      if (this.item.fixed) return this.item.fixed;
      return (this.item.float || "").replace(/\r?\n/g, "<br />");
    },
  },
};
</script>
