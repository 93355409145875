<template>
  <div>
    <div class="d-flex align-center" v-if="isVisible">
      <span class="mx-2">+</span>
      <elwrapper :label="displayLabel" widelabel class="ring" midtext>
        <enumber style="max-width: 100px" :country="country" :value="value" @input="(v) => $emit('input', v)" />
      </elwrapper>
    </div>
    <div class="d-flex align-center" v-if="show">
      <input class="mx-2" type="checkbox" :checked="isVisible" @change="toggle" />
      <span class="caption">{{ displayLabel }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    country: String,
    show: Boolean,
    value: [Object, Boolean],
  },
  computed: {
    displayLabel() {
      return `Custom ${this.label}`;
    },
    isVisible() {
      if (!this.show) return false;
      return this.value !== false;
    },
  },
  methods: {
    toggle() {
      if (this.value === false) {
        this.$emit("input", null);
        this.$emit("toggleOff");
        return;
      }

      this.$emit("input", false);
    },
  },
};
</script>
