<template>
  <div style="max-width: 80px" class="d-flex">
    <v-autocomplete
      :disabled="!this.month || disabled"
      dense
      outlined
      hide-details
      :items="days"
      :value="value"
      @input="(v) => $emit('input', v)"
    ></v-autocomplete>
    <v-btn v-show="value && month" icon x-small @click="$emit('input', null)">
      <v-icon small>clear</v-icon>
    </v-btn>
  </div>
</template>

<script>
import MONTHS from "@/config/MONTHS";
export default {
  props: {
    month: Number,
    value: Number,
    disabled: Boolean,
  },
  computed: {
    days() {
      if (!this.month) return [];
      const t = MONTHS[this.month - 1].days;
      return [...Array(t).keys()].map((x) => (x += 1));
    },
  },
};
</script>
