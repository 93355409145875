<template>
  <div>
    <a-card nopad>
      <v-row class="grey lighten-2 caption font-weight-bold" justify="end">
        <v-col style="max-width: 140px; min-width: 140px; border: 1px solid lightgrey">
          <v-row justify="center">Commission</v-row>
          <v-divider class="py-1" />
          <div>
            <Billed :billed="commisionTotal" />
          </div>
        </v-col>

        <v-col
          class="green darken-4 white--text"
          style="max-width: 140px; min-width: 140px; border: 1px solid lightgrey"
        >
          <v-row justify="center">Est. Budget</v-row>
          <v-divider dark class="py-1" />
          <div style="position: relative">
            <Total :total="totalCalc" :country="country" />
            <v-btn
              icon
              small
              @click="expandEstTotal = !expandEstTotal"
              style="position: absolute; right: -5px; top: -5px"
            >
              <v-icon small>{{ expandEstTotal ? "unfold_less" : "unfold_more" }}</v-icon>
            </v-btn>
          </div>
          <div v-show="expandEstTotal">
            <ExpandedTotal :totalBlob="totalBlob" />
          </div>
        </v-col>

        <v-col
          class="green darken-4 white--text"
          style="max-width: 140px; min-width: 140px; border: 1px solid lightgrey"
        >
          <v-row justify="center">Real Cost</v-row>
          <v-divider dark class="py-1" />
          <div>
            <Billed :billed="costTotal" />
          </div>
        </v-col>

        <v-col style="max-width: 140px; min-width: 140px; border: 1px solid lightgrey">
          <v-row justify="center">Δ Delta</v-row>
          <v-divider class="py-1" />
          <div>
            <Delta hasSetCost :delta="supplierDelta.diff" :positive="supplierDelta.positive" />
          </div>
        </v-col>

        <v-col style="max-width: 150px; min-width: 150px; border: 1px solid lightgrey">
          <v-row justify="center">Reconciled</v-row>
          <v-divider class="py-1" />
          <v-row justify="center">{{ totalRecognition }}</v-row>
        </v-col>
      </v-row>
    </a-card>

    <a-card nopad v-for="(group, supplierType) in supplierBreakdown" :key="supplierType">
      <v-row class="booking-title d-flex grey darken-1 white--text">
        <v-col>
          <v-icon small color="white">{{ supplierConfigMap[supplierType].icon }}</v-icon>
          <span class="caption px-3">{{ supplierConfigMap[supplierType].title }}</span>
        </v-col>
        <v-col style="max-width: 140px; min-width: 140px">
          <Billed class="rsb-hover" :billed="getTotalOfSupplierType(group)" />
        </v-col>
        <v-col style="max-width: 140px; min-width: 140px">
          <Billed class="rsb-hover" :billed="getCostTotalOfSupplierType(supplierType, group)" />
        </v-col>
        <v-col style="max-width: 290px; min-width: 290px">
          <!-- SPACER -->
        </v-col>
      </v-row>

      <SupplierRowWrapper
        v-for="supplier in sortSupplierGroup(group)"
        :key="supplier.supplier_id"
        class="pl-5"
        :supplier="supplier"
        :supplierType="supplierType"
        :supplierInvoice="supplierInvoices[supplier.supplier_id]"
      />
    </a-card>

    <ArchivePrompt v-model="archiveDialog" :bookingId="bookingId" />
  </div>
</template>

<script>
import SUPMENU from "@/config/menus/suppliersMenu";
import SupplierRowWrapper from "./supplierRow/_SupplierRowWrapper";
import ArchivePrompt from "./dialogs/_ArchivePrompt";
import money from "@/services/money";
import { EmailService } from "@/modules/email";

import Delta from "./subitems/_Delta";
import Billed from "./subitems/_Billed";
import Total from "./subitems/_Total";
import ExpandedTotal from "./subitems/_ExpandedTotal";

import _ from "lodash";

export default {
  props: {
    country: String,
    supplierInvoices: Object,
    supplierBreakdown: Object,
    totalCalc: Number,
    totalBlob: Object,
    costTotal: Number,
    commisionTotal: Number,
  },
  components: {
    SupplierRowWrapper,
    ArchivePrompt,

    Delta,
    Billed,
    Total,
    ExpandedTotal,
  },
  data() {
    return {
      supplierDialog: false,
      archiveDialog: false,

      expandEstTotal: false,

      stashedDeltaList: {},
    };
  },
  watch: {
    totalRecognition(v) {
      if (v === "100 %" && !this.archived) {
        this.archiveDialog = true;
      }
    },
  },
  computed: {
    bookingId() {
      return this.$store.getters["FinanceReportStore/booking_id"];
    },
    archived() {
      return this.$store.getters["FinanceReportStore/report_archived"];
    },
    supplierConfigMap() {
      let blob = {};
      this.loopMenuConfig((item) => {
        blob[item.module] = item;
      });
      return blob;
    },

    compiledBilled() {
      return this.$store.getters["FinanceCompileStore/compiledBilled"];
    },

    // Reconciliation compile
    totalRecognition() {
      const booking_suppliers = this.$store.getters["FinanceReportStore/booking_suppliers"];

      const supplier_flatten = Object.values(booking_suppliers).reduce((obj, item) => {
        if (item.type_as === "HOTEL" || item.type_as === "RESTAURANT") {
          // Hotel and restautants of same supplier are combined
          // Other suppliers are seperated, so assume them the same here
          obj[item.supplier_id + "LUMP"] = 1;
        } else {
          obj[item.supplier_id + item.type_as] = 1;
        }
        return obj;
      }, {});
      const supplier_invoices = Object.values(this.supplierInvoices).filter((item) => {
        return item.reconciled;
      });

      const num = supplier_invoices.length / Object.keys(supplier_flatten).length;

      return `${Number(num * 100).toFixed(0)} %`;
    },

    supplierDelta() {
      // const diff = this.$store.getters["FinanceCompileStore/compiledDelta"];
      const diff = this.totalCalc - this.costTotal;
      return {
        diff,
        positive: !(diff >= 0),
      };
    },
  },
  methods: {
    sortSupplierGroup(group) {
      return _.orderBy(group, "name");
    },
    loopMenuConfig(cb) {
      SUPMENU.FULL.forEach((item) => {
        if (item.module === "ALL") return;
        cb(item);
      });
    },
    getTotalOfSupplierType(group) {
      const suppliers = this.sortSupplierGroup(group);
      const total = suppliers.reduce((total, bs) => {
        let addedPorterage = false;
        const productTotal =
          total +
          (bs.products || []).reduce((pr, item) => {
            if (item.id === "PORTERAGE") {
              if (addedPorterage) return pr;
              addedPorterage = true;
            }
            return pr + item.compiledTotal;
          }, 0);
        addedPorterage = false;
        return productTotal;
      }, 0);
      return total;
    },
    getCostTotalOfSupplierType(type_as, group) {
      // real cost column + est budget column when real cost is $ --
      // + extra expense
      const invoices = this.supplierInvoices;
      const suppliers = this.sortSupplierGroup(group);
      const extraExpenses = this.$store.getters["FinanceCompileStore/extra_expenses"];

      const total = suppliers.reduce((total, bs) => {
        const cost = invoices[bs.supplier_id + type_as]
          ? invoices[bs.supplier_id + type_as].total_cost
          : bs.products.reduce((pr, item) => pr + item.compiledTotal, 0);
        const expense = extraExpenses
          .filter((v) => v.supplier_id === bs.supplier_id && v.expense_type === "DEFAULT")
          .filter((v) => v.type_as === type_as)
          .reduce(
            (total, expense) =>
              total + (expense.amount?.value != null ? money.stripFormatting(expense.amount.value) : 0),
            0
          );
        return total + cost + expense;
      }, 0);
      // const total = Object.values(group).reduce((total, bs) => {
      // 	let cost = invoice[bs.supplier_id+bs.type_as]
      // 	return total + (cost? Number(cost.total_cost) : 0)
      // }, 0)
      // return total
      // const total = this.$store.getters["FinanceCompileStore/compiledOneTypeCostTotal"](type_as);
      // return Number(total) !== 0 ? total : this.getTotalOfSupplierType(group);
      return total;
    },
  },
};
</script>

<style lang="scss">
.booking-title {
  padding: 2px 5px 0;
  background: lightgrey;
  font-weight: 500;
  font-size: 12px;

  .rsb-hover {
    opacity: 0;
  }

  &:hover {
    .rsb-hover {
      opacity: 1;
    }
  }
}
</style>
