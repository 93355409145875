<template>
  <div class="history-card">
    <div class="history-card-content">
      <v-row v-if="type === 'NOTE'" class="mx-0">
        <div class="pt-5" v-html="content.content"></div>
      </v-row>

      <div v-else v-html="displayMessage"></div>
    </div>

    <div class="d-flex flex-row align-start grey lighten-2 pa-2" v-if="hasAttachments">
      <v-icon left>attachment</v-icon>
      <div v-for="(file, index) in attachmentList" :key="index">
        <ImageAttachment v-if="file.img" :image="file" />
        <a v-else :href="getLink(file)" class="mx-1" @click="(e) => openPdf(e, doc)">
          <v-chip color="grey">
            <span>{{ file.filename || file.src }}</span>
          </v-chip>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ImageAttachment from "./_ImageAttachment";
export default {
  props: {
    type: String,
    content: Object,
  },
  components: {
    ImageAttachment,
  },
  computed: {
    displayMessage() {
      return this.content.html || this.content.message;
    },
    hasAttachments() {
      return this.content.attachment;
    },
    attachmentList() {
      return this.content.attach_data || [];
    },
  },
  methods: {
    getLink(file) {
      return this.$store.getters["host"] + file.src;
    },
    openPdf(event, doc) {
      if (doc.mimetype.includes("pdf")) {
        event.preventDefault();
        this.$store.dispatch("openPdf", { ...doc, link: this.getLink(doc) });
      }
    },
    isimg(file) {
      if (file.src.endsWith(".jpg") || file.src.endsWith(".png") || file.src.endsWith(".gif")) return "_blank";
      return "";
    },
  },
};
</script>

<style lang="scss">
.history-card {
  .history-card-content {
    max-height: 300px;
    overflow: auto;
  }
}
</style>
