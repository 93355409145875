<template>
  <a-group icon="event" :label="label">
    <elwrapper :label="range ? 'From' : 'On'" style="width: 280px" smlabel>
      <div class="d-flex flex-row">
        <MonthPicker
          :value="value.from_month"
          :disabled="disabled"
          @input="(v) => passUp('from_month', v)"
          style="max-width: 150px"
        />
        <DayPicker
          :month="value.from_month"
          :disabled="disabled"
          :value="value.from_day"
          @input="(v) => passUp('from_day', v)"
        />
      </div>
    </elwrapper>

    <elwrapper v-if="range" label="To" style="width: 280px" smlabel>
      <div class="d-flex flex-row">
        <MonthPicker
          :value="value.to_month"
          :disabled="disabled"
          @input="(v) => passUp('to_month', v)"
          style="max-width: 150px"
        />
        <DayPicker
          :month="value.to_month"
          :disabled="disabled"
          :value="value.to_day"
          @input="(v) => passUp('to_day', v)"
        />
      </div>
    </elwrapper>

    <div class="d-flex align-center mr-3" v-if="!hideRange">
      <input :disabled="disabled" type="checkbox" v-model="range" @change="setDate" />
      <span class="caption ml-2">Range</span>
    </div>

    <v-btn x-small icon @click="clear">
      <v-icon>clear</v-icon>
    </v-btn>
  </a-group>
</template>

<script>
import MonthPicker from "./MonthPicker";
import DayPicker from "./DayPicker";
export default {
  props: {
    value: Object,
    label: String,
    disabled: Boolean,
    hideRange: Boolean,
  },
  components: {
    MonthPicker,
    DayPicker,
  },
  data() {
    return {
      range: false,
    };
  },
  methods: {
    clear() {
      let t = this.value ? { ...this.value } : {};
      t.from_month = null;
      t.from_day = null;
      this.$emit("input", t);

      this.range = false;
      this.setDate();
      this.$emit("update");
    },
    setDate() {
      let t = this.value ? { ...this.value } : {};
      if (this.range) {
        t.to_day = t.from_day;
        t.to_month = t.from_month;
      } else {
        t.to_day = null;
        t.to_month = null;
      }
      this.$emit("input", t);
      this.$emit("update");
    },
    passUp(key, value) {
      let t = this.value ? { ...this.value } : {};
      t[key] = value;
      this.$emit("input", t);
      this.$emit("update");
    },
  },
  mounted() {
    this.$emit("update");
  },
};
</script>
