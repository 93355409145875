<template>
  <v-dialog :value="value" persistent max-width="800">
    <v-card>
      <v-card-title class="subtitle-2">Edit Primary Contact Information</v-card-title>
      <v-card-text>
        <v-alert v-if="notContact" type="warning" text dense>
          <span>No primary contact is set. Only certain fields can be changed.</span>
        </v-alert>

        <v-row>
          <v-col class="py-0 caption">
            <a-field
              :config="config.contact_name"
              :disabled="notContact"
              v-uppercase="!showTranslation"
              v-model="contact.name"
            />
          </v-col>
          <v-col class="py-0 caption">
            <a-field
              :config="config.contact_title"
              :disabled="notContact"
              v-uppercase="!showTranslation"
              v-model="contact.title"
            />
          </v-col>
        </v-row>

        <v-row v-if="showTranslation">
          <v-col class="py-0">
            <a-field :config="config.en_name" :disabled="notContact" v-uppercase v-model="contact.meta.en_name" />
          </v-col>
          <v-col class="py-0">
            <a-field :config="config.en_name" :disabled="notContact" v-uppercase v-model="contact.meta.en_title" />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="py-0 caption">
            <elwrapper icon="phone_in_talk" preicon="person">
              <FPhone :country="country" v-model="contact.phone_number" :disabled="notContact" />
            </elwrapper>
          </v-col>

          <v-col class="py-0 caption">
            <elwrapper icon="phone" preicon="business">
              <FPhone :country="country" v-model="supplier.business_number" />
            </elwrapper>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="py-0 caption">
            <elwrapper icon="phone_in_talk" preicon="smartphone" midtext>
              <FPhone v-model="contact.cell_number" :disabled="notContact" :country="country" />
            </elwrapper>
          </v-col>
          <v-col class="py-0 caption">
            <a-field :config="config.fax_number" :country="country" v-model="supplier.fax_number" />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="py-0 caption">
            <a-field :config="config.email" v-uppercase v-model="contact.email" :disabled="notContact" />
          </v-col>
          <v-col class="py-0 caption">
            <a-field :config="config.website" v-model="supplier.website" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <g-button label="Cancel" @onclick="$emit('input', false)" />
        <div class="flex-grow-1"></div>
        <g-button type="primary" label="Update" @onclick="updateContact" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { SupplierFields } from "@/config/fields/suppliers";

export default {
  props: {
    value: Boolean,
    supplierType: String,
  },
  data() {
    return {
      contact: {
        meta: {},
      },
      contactCache: {},
      supplier: {},
    };
  },
  computed: {
    config() {
      return SupplierFields;
    },
    country() {
      return this.get("country");
    },
    showTranslation() {
      return this.$store.getters["AccountStore/environment"] !== "USA";
    },
    notContact() {
      return !this.contact.id;
    },
  },
  watch: {
    value(v) {
      if (v) this.setEditContent();
    },
  },
  methods: {
    get(val) {
      return this.$store.getters["SupplierStore/" + val];
    },
    setEditContent() {
      const primaryContact = this.get("primary_contact");
      this.contact = { ...primaryContact };
      this.contactCache = JSON.stringify(this.contact);
      if (!this.contact.meta) this.contact.meta = {};
      this.supplier = {
        business_number: this.get("business_number"),
        fax_number: this.get("fax_number"),
        website: this.get("website"),
      };
    },
    updateContact() {
      // Update supplier information
      this.$store.dispatch("SupplierStore/setUpdate", this.supplier);
      const supplierSavePromise = this.$store.dispatch("SupplierStore/updateSupplier");

      // Update contact
      this.$store.dispatch("SupplierStore/updateContact", this.contact);
      const contactPromise = this.$store.dispatch("SupplierContactStore/updateContact", {
        id: this.contact.id,
        contact: this.contact,
        original: this.contactCache,
      });

      // Promise and notification stuff
      this.$emit("input", false);
      this.$root.$loading.open("Updating Primary Contact");
      Promise.all([supplierSavePromise, contactPromise])
        .then((v) => {
          this.$root.$loading.end();
          this.$root.$success("Updated Contact Information");
        })
        .catch(this.$root.$error);
    },
  },
};
</script>
