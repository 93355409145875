import Vue from "vue";
import api from "./api";
import format from "@/services/format";

const SET_FLIGHTS = "SET_FLIGHTS";
const UPDATE_FLIGHTS = "UPDATE_FLIGHTS";
const TRANSFER_FLIGHTS = "TRANSFER_FLIGHTS";

const namespaced = true;

const cached = _getFlights();
const state = {
  lastSave: JSON.stringify(cached),

  arrival: cached.arrival,
  departure: cached.departure,
  flight_list: cached.flight_list,
};

function _getFlights() {
  return JSON.parse(localStorage.getItem("booking_flights") || "{}");
}

const mutations = {
  SET_FLIGHTS(state, data) {
    Object.assign(state, data || {});
    state.lastSave = JSON.stringify({
      arrival: state.arrival,
      departure: state.departure,
      flight_list: state.flight_list,
    });
    localStorage.setItem("booking_flights", JSON.stringify(state));
  },
  UPDATE_FLIGHTS(state, { deep, key, val }) {
    if (!deep) return Vue.set(state, key, val);
    // Nested objects
    if (!state[deep]) state[deep] = {};
    Vue.set(state[deep], key, val);
    localStorage.setItem("booking_flights", JSON.stringify(state));
  },
  TRANSFER_FLIGHTS(state) {
    localStorage.setItem("booking_flights", JSON.stringify(state));
  },
};

const actions = {
  setFlights({ commit }, flights) {
    commit(SET_FLIGHTS, flights);
  },
  update({ commit }, { deep, key, val }) {
    commit(UPDATE_FLIGHTS, { deep, key, val });
  },

  saveFlights({ commit, state }, booking_id) {
    const curr = JSON.stringify({
      arrival: state.arrival,
      departure: state.departure,
      flight_list: state.flight_list,
    });
    // Only update if changes
    if (curr === state.lastSave) return Promise.resolve();

    return api
      .updateFlights(
        booking_id,
        {
          arrival: state.arrival,
          departure: state.departure,
          flight_list: state.flight_list,
        },
        JSON.parse(state.lastSave)
      )
      .then((v) => {
        commit(TRANSFER_FLIGHTS);
        return true;
      })
      .catch((err) => {
        if (err) throw err.data;
      });
  },
};

const getters = {
  arrival: (state) => state.arrival || {},
  departure: (state) => state.departure || {},
  flight_list: (state) => state.flight_list || [],
  flightOnDay: (state) => (day) => {
    const arrival = format.isSameDay(state.arrival.date, day) ? [{ basic: true, ...state.arrival }] : [];
    const depart = format.isSameDay(state.departure.date, day) ? [{ basic: true, ...state.departure }] : [];
    const custom = state.flight_list ? state.flight_list.filter((item) => format.isSameDay(item.date, day)) : [];

    return [...arrival, ...custom, ...depart];
  },

  flattenFlights: (state) => {
    let flightList = [];

    if (state.arrival) {
      let arrival = { ...state.arrival };
      arrival.depart = false;
      flightList.push(arrival);
    }

    if (state.departure) {
      let departure = { ...state.departure };
      departure.depart = true;
      flightList.push(departure);
    }

    let temp = state.flight_list || [];
    temp.forEach((item) => {
      flightList.push(
        {
          date: item.date,
          time: item.arrive_time,
          airport: item.arrive_airport,
          flight_num: item.flight_num,
          depart: false,
        },
        {
          date: item.date,
          time: item.depart_time,
          airport: item.depart_airport,
          flight_num: item.flight_num,
          depart: true,
        }
      );
    });

    return flightList;
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
