<template>
  <a-card v-show="config.meta">
    <a-field
      noflex
      :config="config.meta.language"
      :value="metaValues.language"
      @input="(v) => updateField('language', v)"
    />

    <v-row wrap class="px-0">
      <v-col class="pa-0" cols="6">
        <a-field :config="config.meta.dob" v-model="metaValues.dob" />
      </v-col>
    </v-row>
  </a-card>
</template>

<script>
import info from "../config/formConfig";

export default {
  computed: {
    config() {
      return info.TOUR_GUIDE;
    },
    metaValues() {
      return this.$store.getters["SupplierStore/meta"];
    },
  },
  methods: {
    updateField(key, value) {
      return this.$store.dispatch("SupplierStore/setUpdateMeta", { key, value });
    },
  },
};
</script>
