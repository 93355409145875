import api from "./api";

const namespaced = true;

const state = {};

const mutations = {};

const actions = {
  getMassReminders({ commit }, { dateStart, dateEnd }) {
    return api
      .getMassReminders(dateStart, dateEnd)
      .then((res) => res.data)
      .catch((err) => err.data);
  },
  getOverdueReminders({ commit }, data) {
    return api
      .getOverdueReminders(data)
      .then((res) => res.data)
      .catch((err) => err.data);
  },

  getBookingReminders({ commit }, bookingId) {
    return api
      .getBookingReminders(bookingId)
      .then((res) => res.data)
      .catch((err) => err.data);
  },

  addReminder({ commit }, { bookingId, data }) {
    return api
      .addReminder(bookingId, data)
      .then((res) => res.data)
      .catch((err) => err.data);
  },
  updateReminder({ commit }, { reminder_id, data, cache }) {
    return api
      .updateReminder(reminder_id, data, cache)
      .then((_) => data)
      .catch((err) => err.data);
  },
  markComplete({ commit }, { reminder_id, done_by, done_date }) {
    const data = {
      done_by,
      done_date,
    };
    return api
      .updateReminder(reminder_id, data, null, "Marked complete")
      .then((_) => data)
      .catch((err) => err.data);
  },
  removeReminder({ commit }, { reminderId }) {
    return api
      .removeReminder(reminderId)
      .then((res) => res.data)
      .catch((err) => err.data);
  },
};

const getters = {};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
