const namespaced = true;

const SET_PENDING = "SET_PENDING";

const state = {};

const mutations = {
  SET_PENDING(state, data) {
    Object.assign(state, data);
  },
};

const actions = {
  setSelectSupplierData({ commit }, data) {
    commit(SET_PENDING, data);
  },
};

const getters = {};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
