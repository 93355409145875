<template>
  <div>
    <div class="ml-4 d-flex">
      <pcheck label="Use custom date" v-model="useCustomDate" />
      <month-range
        label="Pricing Dates"
        hideRange
        :disabled="!useCustomDate"
        :value="dateRange"
        @update="updateSeasonalPrice"
        class="ml-4"
        style="flex: 1"
      />

      <div class="mx-3">
        <span>Using Product Year:</span>
        <g-button :label="showYear"></g-button>
      </div>
    </div>

    <DatabaseTable
      select-only
      :select-into="selectInto"
      class="table ma-2"
      dbConfig="products"
      dbSource="ALL"
      :query="productFilter"
      height="300px"
      useOrderSort
      allowRefresh
      :focusYear="showYear"
      :postFetchFn="postFetchFn"
      :disableFn="disableProductSelect"
      :selectedList="selectedItems"
      @select="updateSelected"
    />

    <v-row class="ma-2 button-row" justify="space-between">
      <g-button label="Cancel" icon="clear" @onclick="$emit('cancel')" />
      <v-spacer />

      <g-button type="primary" label="Next" @onclick="select" />
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import DateFormat from "@/services/format";
import { DatabaseTable } from "@/modules/database";

export default {
  props: {
    module: String,
    startDay: [String, Date],
    dateList: Array,
    productFilter: Object,

    editProduct: Object,

    selectInto: Boolean,
  },
  components: {
    DatabaseTable,
  },
  data() {
    return {
      dbHeight: "0px",
      useCustomDate: false,

      // Product
      selectedItems: {},
    };
  },
  computed: {
    dateRange() {
      // Get specific day user is on
      const use = DateFormat.dateToObj(this.startDay || this.dataList[0]);
      return {
        from_month: use.month,
        from_day: use.day,
      };
    },

    showYear() {
      const year = new Date(this.startDay || this.dataList[0]).getFullYear();
      return year + "";
    },
  },
  methods: {
    // SELECT PRODUCT
    updateSelected(input) {
      if (input.add) {
        Vue.set(this.selectedItems, input.add.id, input.add);
      } else if (input.remove) {
        Vue.delete(this.selectedItems, input.remove);
      }
    },
    select() {
      // Merge rate of focused year into selected products
      const selectedCopy = { ...this.selectedItems };
      let temp;
      Object.values(selectedCopy).forEach((item) => {
        temp = item.rate_history.find((item) => item.rate_year === this.showYear) || item.rate_history[0];
        if (!temp) temp = { rate: {}, meta: {} };
        // Remove unused keys
        temp = { ...temp };
        delete temp.id;
        delete temp.product_id;

        Object.assign(item, temp);
      });

      this.$emit("select", {
        products: selectedCopy,
        config: {
          ...(this.useCustomDate && { use_dates: this.dateRange }),
        },
      });
    },

    postFetchFn(rows) {
      if (!rows || !rows.length) return;

      rows.forEach((item) => {
        if (this.editProduct[item.id]) this.selectedItems[item.id] = item;
      });
    },

    disableProductSelect(product) {
      if (this.module === "ALL") return false;
      if (product.product_type === "EXPENSE") return false;
      const prodType = product.product_type;

      const mapping = {
        HOTEL: "ROOM",
        RESTAURANT: "MEAL",
        TRANSPORT: "TRANS",
        PARK: "EXCURSION",
        TOUR_GUIDE: "TOUR",
        EXCURSION: "EXCURSION",
      };

      return mapping[this.module] !== prodType;
    },

    updateSeasonalPrice(v) {
      this.$store.dispatch("DatabaseStore/applySeasonPriceRange", this.dateRange);
    },
  },
};
</script>
