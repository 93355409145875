<template>
  <v-col>
    <button-control-header
      :menuItems="menuItems"
      @add="$router.push({ name: 'suppliers_products_new' })"
      @clear="clearHeader"
      @setmode="setmode"
      :onsave="saveReorderProducts"
      :ondelete="deleteProducts"
    >
      <template v-slot:withadd>
        <div class="d-flex">
          <ProductYearDropWithDialog
            :showYear="focusYear"
            @setYearDisplay="(v) => (focusYear = v)"
            :yearOptions="rateYearList"
            :addNextYear="addNextYear"
            :missingYearRates="missingYearRates"
            class="display-inline"
          />

          <g-button class="mx-2" label="Import" icon="import_export" @onclick="importDialog = true" />
        </div>
      </template>

      <template v-slot:default>
        <SupplierSelectDialog
          v-model="importDialog"
          :supplierType="supplierType"
          select-one
          title="Copy products from..."
          @update="copyProductsFromSupplier"
        />
      </template>
    </button-control-header>

    <div v-if="productList.length > 0">
      <draggable v-model="filteredProductList" handle=".handle">
        <div v-for="(product, i) in filteredProductList" :key="product.id" class="d-flex">
          <v-col v-if="showDrag" class="handle d-flex align-center" style="max-width: 50px">
            <v-icon class="white--text">drag_indicator</v-icon>
          </v-col>

          <v-col>
            <CheckboxWrapper
              :value="product.id"
              :show-check="showChecks"
              :block="product.inBooking"
              blockMessage="Product used in bookings. Cannot delete."
              @toggle="selectProduct"
            >
              <ProductContent @click.native="goTo(product.id)" :product="product" :focusYear="focusYear" />
            </CheckboxWrapper>
          </v-col>
        </div>
      </draggable>

      <d-no-display v-if="filteredProductList.length === 0" label="No products match search" />
    </div>

    <d-no-display v-else label="(No products yet)" />
  </v-col>
</template>

<script>
import draggable from "vuedraggable";
import { SupplierSelectDialog } from "@/modules/database";
import { ProductYearDropWithDialog } from "@/modules/products";
import { ProductMenu } from "@/config/menus";
import { ProductContent } from "@/modules/products";
export default {
  components: {
    draggable,
    SupplierSelectDialog,
    ProductContent,
    ProductYearDropWithDialog,
  },
  data() {
    return {
      menuItems: [
        {
          title: "Reorder",
          showMode: "SAVE",
        },
        {
          title: "Delete",
          showMode: "DELETE",
        },
      ],

      importDialog: false,

      productList: [],
      filteredProductList: [],

      showDrag: false,

      showChecks: false,
      checkedItems: [],
    };
  },
  computed: {
    supplierType() {
      // Only allow imports of the same suppleir type
      return this.$store.getters["SupplierStore/type"];
    },

    focusYear: {
      get() {
        return this.$store.getters["workingYear"];
      },
      set(v) {
        this.$store.dispatch("setDefaultProductYear", v);
      },
    },

    rateYearList() {
      let years = (this.productList || [])
        .reduce((arr, product) => {
          // Extract UNIQUE year
          product.rate_history.forEach((r) => {
            if (!arr.includes(r.rate_year)) arr.push(r.rate_year);
          });
          return arr;
        }, [])
        .sort((a, b) => {
          if (a < b) return 1;
          if (a > b) return -1;
          return 0;
        });

      return years;
    },
    missingYearRates() {
      // Check if there are any products who don't have NEXT year
      let missing = false;
      this.productList.forEach((product) => {
        if (!product.rate_history.map((r) => r.rate_year).includes(`${new Date().getFullYear() + 1}`)) missing = true;
      });
      return missing;
    },
  },
  methods: {
    setDefaultFiltered(productList) {
      // SORT based on order, then priority in product types
      let result = productList
        .filter((item) => item.order !== null)
        .sort((first, second) => {
          return first.order - second.order;
        });

      let noOrderAssigned = productList.filter((item) => item.order === null);

      return [...result, ...noOrderAssigned];
    },

    setmode(mode) {
      this.showChecks = mode === "DELETE";
      this.showDrag = mode === "SAVE";
    },
    goTo(id) {
      this.$router.push({
        name: "product_page",
        params: {
          id: id,
        },
      });
    },

    // IMPORT/COPY PRODUCTS
    copyProductsFromSupplier(supplierSource) {
      this.$root.$loading.open(`Copying products from ${supplierSource.name}`);
      this.$store
        .dispatch("ProductStore/copyProducts", {
          from_supplier_id: supplierSource.id,
          to_supplier_id: this.$route.params.id,
        })
        .then((v) => this.$store.dispatch("ProductStore/getSupplierProductList", this.$route.params.id))
        .then((data) => {
          this.$root.$loading.end();
          this.productList = data;
          this.filteredProductList = this.setDefaultFiltered(data);
        })
        .catch(this.$root.$error);
    },

    // REORDER STUFF
    saveReorderProducts() {
      this.$root.$loading.open("Saving order of products...");
      return this.$store
        .dispatch("ProductStore/reorderProducts", {
          supplierId: this.$route.params.id,
          updatedProductList: this.filteredProductList,
        })
        .then(() => {
          this.$root.$loading.end();
          this.$root.$success("Product Order Updated");
        })
        .catch(this.$root.$error);
    },

    // DELETE STUFF
    clearHeader() {
      this.showChecks = false;
      this.checkedItems = [];
    },
    selectProduct({ check, value }) {
      // Add to delete list group if checked
      // Remove from list group if not checked
      if (check) {
        this.checkedItems.push(value);
      } else {
        var index = this.checkedItems.findIndex((x) => x.id === value);
        this.checkedItems.splice(index, 1);
      }
    },
    deleteProducts() {
      return this.$store
        .dispatch("ProductStore/deleteProductList", this.checkedItems)
        .then(() => {
          // Remove products
          this.productList = this.productList.filter((item) => {
            return !this.checkedItems.includes(item.id);
          });
          this.filteredProductList = this.setDefaultFiltered(this.productList);

          this.$root.$success("Deleted products");
          this.clearHeader();
        })
        .catch((err) => this.$root.$error(err));
    },

    addNextYear(nextYear) {
      return this.$store.dispatch("ProductStore/addProductYearBySupplier", this.$route.params.id).then((nuRateList) => {
        // Insert at top of rate history in current product list
        let product;
        nuRateList.forEach((rate) => {
          product = this.productList.find((p) => p.id === rate.product_id);
          product.rate_history = [rate, ...product.rate_history];
        });
      });
    },
  },
  mounted() {
    // Clear prouct cache
    const env = this.$store.getters["AccountStore/environment"];
    this.$store.dispatch("ProductStore/setProduct", { env });

    // Load products
    var supplierId = this.$route.params.id;
    this.$store
      .dispatch("ProductStore/getSupplierProductList", supplierId)
      .then((data) => {
        this.productList = data;
        this.filteredProductList = this.setDefaultFiltered(data);
      })
      .catch((err) => {
        this.$root.$error(err);
      });
  },
};
</script>

<style lang="scss">
.handle {
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
</style>
