import format from "@/services/format";

const CONFIRMED = 0;

export default {
  mergeConfirmedSuppliersForOverview,
};

function mergeConfirmedSuppliersForOverview(getterStore, type_as, dbkey) {
  let supplierRef = {};

  // Get Confirmed Suppliers
  let first, all, last, customProductNames, productCount;
  const s = getterStore["BookingDayStore/suppliers"];
  Object.values(s)
    .filter((item) => {
      return item.type_as === type_as; //&& item.status >= CONFIRMED
    })
    .forEach((sup) => {
      first = getterStore["BookingDayStore/firstDaySupplier"](dbkey, sup.id);
      all = getterStore["BookingDayStore/allDaysSupplier"](dbkey, sup.id);
      last = getterStore["BookingDayStore/lastDaySupplier"](dbkey, sup.id);

      customProductNames = (sup.meta && sup.meta.custom_names) || {};
      productCount = (sup.meta && sup.meta.count) || {};

      const products = Object.values(sup.products).map((item) => {
        let name = customProductNames[item.id] || item.name;
        let count = productCount[item.id] ? " x" + productCount[item.id] : "";
        return `${name}${count}`;
      });

      // Group by supplier
      // Note, if seperate confirmation numbers, they will be merged
      if (!supplierRef[sup.supplier_id]) {
        supplierRef[sup.supplier_id] = {
          name: (sup.supplier_meta && sup.supplier_meta.en_name) || sup.name,
          confirmed: sup.status >= CONFIRMED,
          confirm_number: sup.meta.confirm_number,
          sortDate: format.convertDate(first.date),
          dates: [
            {
              on: first.date === last.date,
              sortDate: format.convertDate(first.date),
              from: format.formatDate(first.date),
              to: format.formatDate(last.date),
              displayDate: all,
              productNames: products.join(", "),
            },
          ],
        };
        return;
      }

      // Same supplier, but already added
      // Add time (then sort)
      supplierRef[sup.supplier_id].sortDate = format.convertDate(first.date);
      supplierRef[sup.supplier_id].dates.push({
        on: first.date === last.date,
        sortDate: format.convertDate(first.date),
        from: format.formatDate(first.date),
        to: format.formatDate(last.date),
        displayDate: all,
        productNames: products.join(", "),
      });

      supplierRef[sup.supplier_id].dates.sort((a, b) => {
        if (a.sortDate < b.sortDate) return -1;
        if (a.sortDate > b.sortDate) return 1;
        return 0;
      });
    });

  return Object.values(supplierRef).sort((a, b) => {
    if (a.sortDate < b.sortDate) return -1;
    if (a.sortDate > b.sortDate) return 1;
    return 0;
  });
}
