<template>
  <div class="mx-5">
    <v-row v-if="mode === 'DELETE'" justify="space-between">
      <g-button @onclick="clear" label="Cancel" />
      <DeleteButton headline="Last Confirm: Delete?" :ondelete="confirmDelete" />
    </v-row>

    <v-row v-else-if="mode === 'SAVE'" justify="space-between">
      <g-button @onclick="clear" label="Cancel" />
      <SaveButton :onsave="save" />
    </v-row>

    <v-row v-else justify="space-between">
      <DotMenu :items="controlMenuItems" />

      <slot name="mid"></slot>

      <div class="d-flex align-center">
        <slot name="withadd"></slot>
        <AddButton @click="add" />
      </div>
    </v-row>

    <!-- Dialogs should go here -->
    <slot></slot>

    <v-divider class="mt-1 mb-2" />
  </div>
</template>

<script>
import DeleteButton from "./buttons/DeleteButton";
import SaveButton from "./buttons/SaveButton";
import AddButton from "./buttons/AddButton";
import DotMenu from "./buttons/DotMenu";

const DELETE = "DELETE";
const SAVE = "SAVE";
const ADD = "ADD";

export default {
  props: {
    menuItems: Array,
    // If don't want this to change
    staticMode: String,

    ondelete: Function, // Promise
    onsave: Function, // Returns promise
  },
  components: {
    DeleteButton,
    SaveButton,
    AddButton,
    DotMenu,
  },
  data() {
    return {
      mode: "",
    };
  },
  computed: {
    controlMenuItems() {
      var list = [];
      var vm = this;

      if (!this.menuItems) return list;

      this.menuItems.forEach((item) => {
        list.push({
          title: item.title,
          onClick: () => vm.handleClick(item),
        });
      });
      return list;
    },
  },
  methods: {
    handleClick(item) {
      if (item.showMode) this.setMode(item.showMode);
      if (item.onClick) item.onClick();
    },
    add() {
      this.$emit("add");
    },
    save() {
      return this.onsave()
        .then((v) => this.clear())
        .catch((err) => {
          throw err;
        });
    },
    confirmDelete() {
      return this.ondelete()
        .then((v) => this.clear())
        .catch((err) => {
          throw err;
        });
    },
    clear() {
      this.$emit("clear");
      if (this.staticMode) return;
      this.setMode("DEFAULT");
    },
    setMode(mode) {
      if (this.staticMode) return;
      this.mode = mode;
      this.$emit("setmode", mode);
    },
  },
  mounted() {
    if (this.staticMode) this.mode = this.staticMode;
  },
};
</script>
