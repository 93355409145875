<template>
  <div class="engroup">
    <span class="caption">x {{ label }} x</span>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
  },
};
</script>

<style lang="scss">
.engroup {
  display: flex;
  justify-content: center;
  padding: 5px;
  background: rgba(200, 250, 250, 0.5);

  > span {
    display: flex;
    align-items: center;
  }
}
</style>
