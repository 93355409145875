<template>
  <v-form class="py-3" v-model="valid">
    <!-- NO SYSTEM EMAIL IS SET UP. CANNOT SEND PASSWORD RESETS -->

    <!-- <v-text-field 
    v-model="email" 
    label="Email" 
    prepend-icon="mail" 
    :rules="[v => !!v || 'Invalid email']"
    required></v-text-field> -->
    <v-row> Please contact admin to reset your password. </v-row>

    <v-row justify="end">
      <v-btn text x-small @click="$emit('login')">Login</v-btn>
    </v-row>

    <!-- <v-btn class="ma-4" color="primary" :disabled="!valid" @click="submit">Send new password</v-btn> -->
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      valid: true,
      email: null,
    };
  },
  methods: {
    submit() {
      this.$store
        .dispatch("forgot_password", {
          email: this.email,
        })
        .then(() => this.$root.$success("Password changed. Please check your email."))
        .catch((err) => this.$root.$error(err));
    },
  },
};
</script>

<style></style>
