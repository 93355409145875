import axios from "@/plugins/axios";

export default {
  getTextDefaultConfiguration(env) {
    return axios.get(`/text_configs/${env}`);
  },
  getOneTextDefault(env, key) {
    return axios.get(`/text_configs/${env}/${key}`);
  },
  updateTextDefaultConfiguration(env, key, text) {
    return axios.post(`/text_configs/${env}/${key}`, { text });
  },
};
