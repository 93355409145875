import api from "./api";

const namespaced = true;

const actions = {
  getHistory({ commit }, query) {
    return api
      .getHistory(query)
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
};

export default {
  namespaced,
  actions,
};
