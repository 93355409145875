<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)" width="400px" persistent>
    <v-card>
      <v-card-title>
        <v-icon left color="warning">warning</v-icon>
        <span>No Time Set</span>
      </v-card-title>

      <v-card-actions>
        <g-button label="Cancel" @onclick="$emit('input', false)" />
        <div class="flex-grow-1"></div>

        <g-button type="primary" label="Continue without Time" @onclick="$emit('next')" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,

    showRoom: Boolean,
    showConfirm: Boolean,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
