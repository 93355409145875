<template>
  <div class="a-card" :class="{ 'pa-2 ma-2': !nopad, 'a-card-hover': hover }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    nopad: Boolean,
    hover: Boolean,
  },
};
</script>

<style lang="scss">
.a-card {
  border: 3px groove #eee;
  background: white;

  &.a-card-hover:hover {
    background: lightgray;
    cursor: pointer;
  }
}
</style>
