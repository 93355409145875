<template>
  <v-card flat>
    <v-form @submit.prevent="updateSearch">
      <v-card-text class="d-flex flex-wrap">
        <a-field class="s-field" :config="config.country" :src="mod" :env="env" v-model="country" />
        <a-field
          class="s-field"
          :config="config.subjur"
          :country="country"
          :dependantValue="country"
          v-model="subjur"
        />

        <a-field
          class="s-field"
          v-if="mod === 'TOUR_GUIDE'"
          :config="config.op_area"
          v-caps
          widelabel
          v-model="op_area"
        />
        <a-field
          class="s-field"
          v-else
          :config="config.region"
          v-model="region"
          :country="country"
          :dependantValue="country"
        />

        <a-field class="s-field" :config="config.city" v-model="city" />
      </v-card-text>

      <v-card-actions>
        <g-button label="Clear" @onclick="clearSearch" />
        <v-spacer />
        <search-button />
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import ENV from "@/config/ENV";
import locationSearchConfig from "../../config/locationSearchConfig";

export default {
  props: {
    isApplied: Boolean,
    mod: String,
    dbConfig: String,
  },
  data() {
    return {
      config: locationSearchConfig,
    };
  },
  computed: {
    env() {
      return this.$store.getters["AccountStore/environment"];
    },
    country: {
      get() {
        return this.get("country") || "";
      },
      set(nuval) {
        this.set("country", nuval);
      },
    },
    subjur: {
      get() {
        return this.get("subjur") || "";
      },
      set(nuval) {
        this.set("subjur", nuval);
      },
    },
    city: {
      get() {
        return this.get("city") || "";
      },
      set(nuval) {
        this.set("city", nuval);
      },
    },
    region: {
      get() {
        return this.get("region") || "";
      },
      set(nuval) {
        this.set("region", nuval);
      },
    },
    op_area: {
      get() {
        return this.get("op_area") || "";
      },
      set(nuval) {
        this.set("op_area", nuval);
      },
    },

    isProduct() {
      return this.dbConfig === "products";
    },
    sourceSearch() {
      return this.isProduct ? "productLocationSearch" : "locationSearch";
    },
  },
  methods: {
    get(val) {
      return this.$store.getters[`DatabaseStore/${this.sourceSearch}`][val];
    },
    set(key, nuval) {
      let data = this.$store.getters[`DatabaseStore/${this.sourceSearch}`];
      Object.assign(data, { [key]: nuval });
      this.$store.dispatch("DatabaseStore/updateSearch", {
        tab: this.isProduct ? "productLocation" : "location",
        data,
      });
    },

    clearSearch() {
      this.$store.dispatch("DatabaseStore/updateSearch", {
        tab: this.isProduct ? "productLocation" : "location",
        data: {},
      });

      if (this.isApplied) {
        this.$emit("search", {
          country: ENV.find((item) => item.value === this.env).defaultCountry,
        });
        this.$store.dispatch("DatabaseStore/applySearch", {
          dbconfig: this.dbConfig,
        });
      }
    },
    updateSearch() {
      let data = this.$store.getters[`DatabaseStore/${this.sourceSearch}`];
      let search = {
        ...(data.country && { country: data.country }),
        ...(data.subjur && { subjur: data.subjur }),
        ...(data.city && { city: data.city }),
        ...(data.region && this.mod !== "TOUR_GUIDE" && { region: data.region }),
        ...(data.op_area && this.mod === "TOUR_GUIDE" && { op_area: data.op_area }),
      };
      this.$emit("search", search);
      this.$store.dispatch("DatabaseStore/applySearch", {
        tab: this.isProduct ? "productLocation" : "location",
        dbconfig: this.dbConfig,
        data: search,
      });
    },
  },
  mounted() {
    if (!this.isApplied && !this.country) {
      this.country = ENV.find((item) => item.value === this.env).defaultCountry;
    }
  },
};
</script>
