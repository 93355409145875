<template>
  <v-combobox
    outlined
    hide-details
    dense
    :disabled="disabled"
    :value="value"
    :items="items"
    item-text="label"
    item-value="value"
    :return-object="true"
    append-icon=""
    @input="(v) => onInput(v)"
  ></v-combobox>
</template>

<script>
export default {
  props: {
    value: Object,
    disabled: Boolean,
    items: Array,
  },
  methods: {
    onInput(v) {
      const value = v === null ? { label: "", value: null } : typeof v === "string" ? { label: v, value: null } : v;
      this.$emit("input", value);
    },
  },
};
</script>
