<template>
  <v-btn :disabled="disabled" icon small @click="$emit('onclick')">
    <v-icon small>{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    icon: String,
  },
};
</script>
