<template>
  <elwrapper label="Set Access" widelabel>
    <div class="grey lighten-3" style="border: 1px solid grey; border-radius: 5px">
      <v-row v-for="(row, env) in value" :key="env">
        <v-col cols="3">
          <elwrapper smlabel label="Env">
            <v-col>{{ getLabel(env) }}</v-col>
          </elwrapper>
        </v-col>
        <v-col>
          <a-select
            smlabel
            multiple
            label="EDIT"
            :items="TABS"
            :value="row.WRITE"
            @input="(v) => alterTags('WRITE', env, v)"
          />
          <!-- <a-select 
					smlabel 
					multiple
					label="VIEW" 
					:items="TABS"
					:value="row.READ"
					@input="v => alterTags('READ', key, v)" /> -->
        </v-col>
        <v-col cols="1" align-self="center">
          <v-btn icon text small @click="removeItem(env)">
            <v-icon small>clear</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <a-select smlabel label="Env" :items="environments" v-model="nuenv" />
        </v-col>
        <v-col cols="1">
          <v-btn text small :disabled="!nuenv" @click="addItem">
            <v-icon small>add</v-icon>
            <span>Add</span>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </elwrapper>
</template>

<script>
import ENV from "@/config/ENV";
import TABS from "@/config/TABS";

export default {
  props: {
    value: Object,
  },
  data() {
    return {
      TABS: Object.values(TABS)
        .filter((v) => {
          return v.value !== "ADMIN";
        })
        .map((v) => {
          return {
            label: v.title,
            value: v.value,
          };
        }),
      environments: ENV,

      nuenv: null,
    };
  },
  methods: {
    getLabel(key) {
      return (ENV.find((v) => v.value === key) || {}).label;
    },
    alterTags(type, env, tabs) {
      let copy = JSON.parse(JSON.stringify(this.value));
      copy[env][type] = tabs;
      this.$emit("input", copy);
    },
    addItem() {
      this.$emit("input", {
        ...this.value,
        [this.nuenv]: {
          WRITE: [],
        },
      });
      this.nuenv = null;
    },
    removeItem(key) {
      let temp = { ...this.value };
      delete temp[key];
      this.$emit("input", temp);
    },
  },
};
</script>
