<template>
  <v-row class="pa-0 subrow">
    <v-col class="d-flex align-center subtitle-2" align-self="center">
      <a @click="toSupplier">{{ supplier.name }}</a>
    </v-col>

    <v-col class="d-flex" style="max-width: 105px; min-width: 105px">
      <EmailSupplierButton v-show="!nobuttons" :supplier="supplier" />
      <v-btn small icon v-show="!nobuttons" @click="$emit('add-expense')" class="report-btn">
        <v-icon small>add</v-icon>
      </v-btn>
      <v-btn small icon @click="$emit('toggle-products')" class="report-btn">
        <v-icon small>{{ showProducts ? "visibility" : "visibility_off" }}</v-icon>
      </v-btn>
    </v-col>

    <v-col
      style="max-width: 140px; min-width: 140px; position: relative"
      :style="commission ? 'border: 1px solid lightgrey' : ''"
    >
      <Commission
        v-if="commission"
        :external="external"
        :bookingId="bookingId"
        :supplierId="supplier.supplier_id"
        :supplierName="supplier.name"
        :typeAs="supplier.type_as"
        :commission="commission"
        :commissionTotal="commissionTotal"
        :commissionReceivedHistory="commissionReceivedHistory"
        :customCommissionList="customCommissionList"
        @sync-commission="(v) => $emit('sync-commission', v)"
        @add-commission-history="(v) => $emit('add-commission-history', v)"
        @toggle-commission-received="$emit('toggle-commission-received')"
      />
    </v-col>

    <v-col style="max-width: 140px; min-width: 140px; border: 1px solid lightgrey">
      <Total :total="supplierTotal" :country="supplier.country" />
    </v-col>

    <v-col style="max-width: 140px; min-width: 140px; position: relative; border: 1px solid lightgrey">
      <Billed :forceZero="hasSupplierInvoice" :billed="customSupplierCost" />

      <div class="d-flex align-center" style="position: absolute; right: 0; top: 5px">
        <v-btn icon small @click="$emit('edit', supplier)" class="report-btn">
          <v-icon small>edit</v-icon>
        </v-btn>
      </div>
    </v-col>

    <v-col style="max-width: 140px; min-width: 140px; border: 1px solid lightgrey">
      <Delta :hasSetCost="hasSetCost" :delta="supplierDelta.diff" :positive="supplierDelta.positive" />
    </v-col>

    <v-col class="pa-0 d-flex justify-center" align-self="center" style="max-width: 150px; min-width: 150px">
      <ReconciledButton
        :external="external"
        :bookingId="bookingId"
        :supplierId="supplier.supplier_id"
        :typeAs="supplier.type_as"
        :supplierInvoice="supplierInvoice"
        @update="(v) => $emit('update', v)"
      />
    </v-col>
  </v-row>
</template>

<script>
import ReportService from "../../service";
import money from "@/services/money";
import format from "@/services/format";

import { EmailSupplierButton } from "@/modules/finances.email";

import Delta from "../subitems/_Delta";
import Billed from "../subitems/_Billed";
import Total from "../subitems/_Total";
import Commission from "../subitems/_Commission";
import ReconciledButton from "../subitems/_ReconciledButton";
export default {
  props: {
    nobuttons: Boolean,
    external: Boolean,
    showProducts: Boolean,

    bookingId: String,
    supplier: Object,
    supplierType: String,
    supplierInvoice: Object,
    supplierDelta: Object,

    commissionReceivedHistory: Array,
    customCommissionList: Array,
  },
  components: {
    EmailSupplierButton,
    ReconciledButton,

    Delta,
    Billed,
    Total,
    Commission,
  },
  computed: {
    supplierTotal() {
      let strippedExpense = this.supplier.products.filter((item) => {
        return item.id !== "PARKING_BUS" && item.id !== "PARKING_VEHICLE" && item.id !== "PORTERAGE";
      });
      return ReportService.getProductListTotal(strippedExpense, true, this.$store.getters);
    },

    hasSupplierInvoice() {
      return !!this.supplierInvoice;
    },
    customSupplierCost() {
      return this.supplierInvoice && this.supplierInvoice.total_cost;
    },
    hasSetCost() {
      return this.customSupplierCost !== null && this.customSupplierCost !== undefined;
    },

    // COMISSION STUFF
    commission() {
      if (this.supplier.meta && this.supplier.meta.has_custom_commission) {
        return Number(this.supplier.meta.custom_commission_value);
      }
      if (
        this.supplier.supplier_meta &&
        this.supplier.supplier_meta.commission &&
        this.supplier.supplier_meta.commission.type === "PERCENT"
      ) {
        return Number(this.supplier.supplier_meta.commission.value);
      }
      return false;
    },
    commissionTotal() {
      if (!this.commission) return "$--";
      const s = this.supplier;
      let cTotal = 0;
      s.products.forEach((product) => {
        // Hotel only commmission on rooms
        if (s.type_as === "HOTEL" && product.product_type !== "ROOM") return;
        cTotal += (Number(product.original.compiledTotal || 0) * Number(this.commission)) / 100;
      });

      return this.formatCost(cTotal);
    },
  },
  methods: {
    toSupplier() {
      this.$router.push({
        name: "suppliers_info",
        params: {
          id: this.supplier.supplier_id,
        },
      });
    },
    formatCost(v) {
      return v ? `$ ${money.format(v, { precision: 2 })}` : "$ --";
    },
  },
};
</script>
