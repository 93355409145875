<template>
  <v-snackbar dense top :value="value" :color="color" :timeout="timeout" @input="(v) => $emit('input', v)">
    <v-row class="pa-0 ma-0" align="center" justify="space-between">
      <span>{{ text }}</span>
      <v-btn icon @click="$emit('input', false)">
        <v-icon>clear</v-icon>
      </v-btn>
    </v-row>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    value: Boolean,
    color: String,
    text: [String, Object],
  },
  data() {
    return {
      timeout: 7500,
    };
  },
};
</script>

<style>
.v-snack__wrapper {
  /* max-height: 40px; */
  max-width: 1280px;
}
</style>
