<template>
  <div>
    <h3 class="title white--text">New Template</h3>
    <v-row justify="space-between" class="ma-3">
      <g-button label="Cancel" @onclick="$router.push({ name: 'admin_email_templates' })" />
      <div class="d-flex">
        <save-button :onsave="createTemplate" />
        <QuestionHover />
      </div>
    </v-row>

    <a-card class="px-5">
      <div>
        <a-text-field label="Name" v-model="name" />
        <elwrapper label="Suppliers">
          <f-dropdown multiple :items="supplierItems" v-model="supplier_types" />
        </elwrapper>
        <EmailStatusDropdown v-model="status_set" />
      </div>

      <v-divider class="mt-2" />

      <v-row>
        <v-col cols="2" class="ring grey lighten-2 subtitle-2">
          <div class="py-1 px-3 white">
            <span>English</span>
          </div>

          <v-divider />

          <v-btn class="mt-5" small depressed block disabled>
            <v-icon small left>add</v-icon>
            <label>Add</label>
          </v-btn>
        </v-col>

        <v-col>
          <a-text-field label="Subject" v-model="subject" />
          <RichTextEditor v-model="text" />
        </v-col>
      </v-row>
    </a-card>
  </div>
</template>

<script>
import SUPMENU from "@/config/menus/suppliersMenu";
import { EmailStatusDropdown, QuestionHover } from "@/modules/admin.email_template";
export default {
  components: {
    EmailStatusDropdown,
    QuestionHover,
  },
  data() {
    return {
      supplierItems: SUPMENU.FULL.map((item) => {
        return {
          label: item.title,
          value: item.module,
        };
      }),

      name: "",
      subject: "",
      status_set: "",
      supplier_types: [],
      text: "",
    };
  },
  methods: {
    createTemplate() {
      return this.$store
        .dispatch("EmailTemplateStore/createTemplate", {
          name: this.name,
          subject: this.subject,
          status_set: this.status_set,
          text: this.text,
          supplier_types: typeof this.supplier_types === "string" ? [this.supplier_types] : this.supplier_types,
          language: "EN", // DEFAULT (PARENT) ALWAYS ENGLISH
        })
        .then((v) => {
          this.$root.$success("Created Template: " + this.name);
          this.$router.push({ name: "admin_email_templates" });
        })
        .catch((err) => this.$root.$error(err));
    },
  },
};
</script>
