<template>
  <div class="full-height top-tabs">
    <v-system-bar height="25" app v-if="showTabs">
      <v-tabs v-model="tab" class="software-tab-wrapper" dense>
        <v-tab class="software-tab"> Main </v-tab>

        <v-tab v-for="(n, index) in tablist" :key="index" class="software-tab">
          <span>{{ n.title }}</span>
          <v-btn icon x-small @click="removeTab(index)">
            <v-icon>clear</v-icon>
          </v-btn>
        </v-tab>
      </v-tabs>
    </v-system-bar>

    <v-tabs-items v-model="tab" class="full-height" style="background: #1f305c">
      <v-tab-item class="full-height" style="overflow: auto" v-show="tab === 0">
        <!-- ROOT -->
        <section :style="adjustHeight">
          <slot></slot>
        </section>
      </v-tab-item>

      <v-tab-item v-for="(item, index) in tablist" :key="item.path">
        <section :style="adjustHeight">
          <ReplyEmail v-if="item.type === 'EMAIL'" astab :dataStamp="item.dataStamp" @close="removeTab(index)" />
          <iframe
            v-else-if="item.type === 'PDF'"
            :src="item.link"
            style="position: absolute; height: 95%; width: 100%; border: none"
          />
        </section>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
// import { ipcRenderer } from 'electron'
import ReplyEmail from "@/views/email/ReplyEmail";
export default {
  components: {
    ReplyEmail,
  },
  data() {
    return {};
  },
  computed: {
    tab: {
      get() {
        return this.$store.getters["activeWindowTab"];
      },
      set(n) {
        this.$store.dispatch("setFocusTab", n);
      },
    },
    showTabs() {
      return this.tablist.length > 0;
    },
    adjustHeight() {
      if (this.showTabs) return "margin-top:30px";
      return "";
    },
    tablist() {
      return this.$store.getters["tablist"];
    },
  },
  methods: {
    removeTab(index) {
      this.$store.dispatch("removeTab", index);
    },
  },
  mounted() {
    // console.log('TO DO')
    // ipcRenderer.on('open-doctab', (event, viewPath) => {
    //      this.$store.dispatch('addTab', { external: true, path: viewPath })
    //    })
  },
};
</script>

<style lang="scss">
.top-tabs {
  background: #1f305c;

  .software-tab-wrapper {
    .v-tabs-bar {
      height: 25px;
    }
    .software-tab {
    }
  }
}
</style>
