<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)" persistent max-width="600px">
    <v-card>
      <v-toolbar depressed flat>
        <span class="headline">Edit Document Name</span>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col class="preview" cols="6">
            <span>{{ editData.display_name || editData.name }}</span>
          </v-col>
          <v-col cols="6">
            <a-text-field label="Label" smlabel :value="displayFilename" @input="(v) => (fileName = v)" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <g-button @onclick="$emit('input', false)" label="Cancel" />
        <g-button type="primary" @onclick="upload" label="Update" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    editData: Object,
  },
  data() {
    return {
      fileName: "",
    };
  },
  computed: {
    displayFilename() {
      return this.fileName || this.editData.display_name;
    },
    hostName() {
      return this.$store.getters["host"];
    },
  },
  methods: {
    upload() {
      this.$emit("update", {
        id: this.editData.id,
        display_name: this.displayFilename,
      });
    },
  },
};
</script>
