<template>
  <v-tabs v-model="tab" dense>
    <v-tabs-slider></v-tabs-slider>
    <v-tab v-for="(v, i) in tabs" :key="i" :to="v.to">
      {{ v.text }}
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  props: {
    clientId: String,
  },
  data() {
    return {
      tab: 0,
    };
  },
  computed: {
    tabs() {
      if (!this.clientId) return [];
      return [
        {
          text: "Information",
          to: { name: "client_info", params: { client_id: this.clientId } },
        },
        {
          text: "Contacts",
          to: { name: "clients_contacts", params: { client_id: this.clientId } },
        },
        {
          text: "Logo",
          to: { name: "client_logo" },
        },
        {
          text: "History",
          to: { name: "client_history", params: { client_id: this.clientId } },
        },
      ];
    },
  },
};
</script>

<style></style>
