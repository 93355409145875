import axios from "@/plugins/axios";

export default {
  getTechnical(booking_id) {
    return axios.get(`/bookings/${booking_id}/technical`);
  },
  updateTechnical(booking_id, blob) {
    return axios.post(`/bookings/${booking_id}/technical`, { blob });
  },

  getSupplierContactInformation(supplierIdList) {
    return axios.post("/bookings_technical/contacts", { suppliers: supplierIdList });
  },
};
