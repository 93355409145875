<template>
  <v-row class="mx-3" :class="{ 'cyan lighten-5': !ignoreThisProduct }">
    <v-col style="max-width: 60px" align-self="center" class="d-flex justify-space-between">
      <v-btn x-small icon @click="(e) => update(e, false)">
        <v-icon small :color="!ignoreThisProduct ? 'blue' : ''">check</v-icon>
      </v-btn>
      <v-btn x-small icon @click="(e) => update(e, true)">
        <v-icon small :color="ignoreThisProduct ? 'red' : ''">clear</v-icon>
      </v-btn>
    </v-col>
    <v-col align-self="center">
      <span class="caption mx-2">{{ product.name }}</span>
    </v-col>
    <v-col style="max-width: 60px" align-self="center">
      <span v-show="showCount" class="overline mx-2">x {{ product.count }}</span>
    </v-col>
    <v-col style="max-width: 60px" align-self="center">
      <span v-show="showPax" class="caption mr-1">
        <v-icon x-small left>person</v-icon>
        {{ pax }}
      </span>
    </v-col>
    <v-col style="max-width: 160px" align-self="center">
      <span class="caption">{{ product.dates }}</span>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    product: Object,
    pax: [Number, String],
    ignoreProducts: Object,
  },
  computed: {
    ignoreThisProduct() {
      if (!this.ignoreProducts) return false;
      return this.ignoreProducts[this.product.ref_id];
    },
    showCount() {
      if (["ROOM"].includes(this.product.product_type)) return false;
      return true;
    },
    showPax() {
      if (["ROOM", "MEAL", "TOUR_GUIDE"].includes(this.product.product_type)) return false;
      return true;
    },
  },
  methods: {
    update(e, toIgnore) {
      e.stopPropagation();
      this.$emit("updateIgnoreProduct", { product: this.product, toIgnore });
    },
  },
};
</script>
