<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)">
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <span class="subtitle-2">Filler Text Guide</span>
        <div class="caption">* Note: Fill text is case insensitive.</div>
      </v-card-title>

      <v-card-text style="max-height: 500px; overflow: auto">
        <div class="black--text grey lighten-2 pa-2">
          <span class="subtitle-2">On Select</span>
          <span class="caption mx-3">(Automatically filled in when the template is selected)</span>
        </div>

        <HelperTable :items="templateContent" />

        <div class="black--text grey lighten-2 pa-2">
          <span class="subtitle-2">Per Supplier</span>
          <span class="caption mx-3">(Product independent)</span>
        </div>

        <HelperTable :items="guideContent" />

        <div class="black--text grey lighten-2 pa-2">
          <span class="subtitle-2">Per Booking "Product Group"</span>
          <span class="caption mx-3">(See next section for multiple products in one email)</span>
        </div>

        <HelperTable :items="productContent" />

        <div class="black--text grey lighten-2 pa-2">
          <span class="subtitle-2">Multiple products</span>
          <span class="caption mx-3">(Recommended)</span>
        </div>

        <v-simple-table fixed-header>
          <thead>
            <tr>
              <th class="text-left">Start</th>
              <th class="text-left"></th>
              <th class="text-left">End</th>
              <th class="text-left">Description</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in productLoop" :key="index">
              <td>{{ item.start }}</td>
              <td>...</td>
              <td>{{ item.end }}</td>
              <td>{{ item.description }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-simple-table fixed-header>
          <thead>
            <tr>
              <th class="text-left">Examples</th>
              <th class="text-left">Results</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in productExamples" :key="index">
              <td v-html="item.example"></td>
              <td v-html="item.result"></td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>

      <v-card-actions class="d-flex justify-end">
        <g-button type="primary" label="OK" @onclick="$emit('input', false)" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import HelperTable from "./_HelperTable";
const exampleSignature = `
			<div>Sincerely,</div>
			<div><b>My Awesome Name</b></div>
			<div>example@supernature.travel</div>
		`;
export default {
  props: {
    value: Boolean,
  },
  components: {
    HelperTable,
  },
  data() {
    return {
      templateContent: [
        {
          fill: "GROUP",
          sub: "NAME",
          example: "for group <b>${GROUP.NAME}</b>",
          result: "for group <b>SMITH</b>",
          description: "Name of booking group",
        },
        {
          fill: "ROOMS",
          sub: "TOTAL",
          example: "price of <b>${ROOMS.TOTAL}</b>",
          result: "price of 10 rooms",
          description: "Total number of rooms",
        },
        {
          fill: "",
          sub: "BREAKDOWN",
          example: "the following rooms: <br/> <b>${ROOMS.BREAKDOWN}</b>",
          result: "the following rooms: <br/> <b>7 Singles </br> 3 Doubles </br> 4 Twins</b>",
          description:
            "Breakdown of rooms. Pulls from Rooming list; if rooming list not defined, then gets from main booking page",
        },
        {
          fill: "REPLACE [helper text here]",
          example: "Do you have special rates for <b>${REPLACE Holiday/s}</b>?",
          result: "Do you have special rates for <b>Christmas and New Year's</b>?",
          description:
            "You will be asked to manually input a value. The helper text is the message that will display when you are asked to manually input a value.",
        },
      ],
      guideContent: [
        {
          fill: "SUPPLIER",
          sub: "NAME",
          example: "Dear <b>${SUPPLIER.NAME}</b>",
          result: "Dear <b>Hotel Saddleback</b>",
          description: "Inserts the supplier's name",
        },
        {
          fill: "SUPPLIER",
          sub: "CITY",
          example: "in <b>${SUPPLIER.CITY}</b>",
          result: "in <b>Boston</b>",
          description: "Inserts the supplier's name",
        },
        {
          fill: "CONTACT",
          sub: "NAME",
          example: "Dear <b>${CONTACT.NAME}</b>",
          result: "Dear <b>Mr. Smith</b>",
          description: "Inserts the contact's name",
        },
      ],
      productContent: [
        {
          fill: "PRODUCT",
          sub: "NAME",
          example: "we would like <b>${PRODUCT.NAME}</b>",
          result: "we would like <b>Lunch Menu</b>",
          description:
            'Name of the product. Custom name will be used if specified. If multiple products selected for this specific supplier, they will be grouped with a comma (eg. Adult Tickets, Child Tickets). Refer to "Multiple Products" for an alternative way to handle this.',
        },
        {
          fill: "",
          sub: "COUNT",
          example: "<b>${PRODUCT.COUNT}</b> tickets",
          result: "<b>3</b> tickets",
          description: "Count of the products",
        },
        {
          fill: "DATE",
          sub: "START",
          example: "from <b>${DATE.START}</b>",
          result: "from <b>March 1, 2030</b>",
          description: "First day supplier/product group appears in the booking",
        },
        {
          fill: "",
          sub: "END",
          example: "until <b>${DATE.END}</b>",
          result: "until March 1, 2030",
          description: "Last day a supplier/product group appears in the booking",
        },
        {
          fill: "",
          sub: "LIST",
          example: "for dates: <b>${DATE.LIST}</b>",
          result: "for dates: <b>March 1 to March 3, and March 5, 2020</b>",
          description: "Lists all days a supplier appears. Dates in sequence will be abridged.",
        },
        {
          fill: "",
          sub: "DAYS",
          example: "for <b>${DATE.DAYS}</b>",
          result: "for <b>7 days</b>",
          description: "How many days a supplier appears in booking.",
        },
        {
          fill: "",
          sub: "NIGHTS",
          example: "for <b>${DATE.NIGHTS}</b>",
          result: "for <b>7 nights</b>",
          description: 'The same as days, however uses "nights" instead',
        },
      ],

      productLoop: [
        {
          start: "#PEND",
          end: "#PEND",
          description:
            "Repeats text for whatever products are specified. Product group of previous section can be nested inside of these for different results. If you would like each item on seperate line, include #PEND on seperate line",
        },
      ],
      productExamples: [
        {
          example: `
					We would like: <br>
					<b>#PEND</b>
						$\{PRODUCT.COUNT} $\{PRODUCT.NAME}
					<b>#PEND</b>`,
          result: `
					We would like: <br>
						20 Adult tickets,
						5 Child tickets
					`,
        },
        {
          example: `
					We would like: <br>
					<b>#PEND</b>
						$\{PRODUCT.COUNT} $\{PRODUCT.NAME}<br>
					<b>#PEND</b>`,
          result: `
					We would like: <br>
						20 Adult tickets,<br>
						5 Child tickets
					`,
        },
      ],
    };
  },
};
</script>
