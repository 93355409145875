<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <g-button type="primary" label="Insert Excel" class="ma-1" @onclick="dialog = true" />
    </template>

    <v-card>
      <v-card-title class="text-h5 grey lighten-2"> Copy/Paste Raw Excel Below </v-card-title>

      <v-card-text class="mt-3 black--text">
        Order of cells must reflect rows displayed to ensure they end up in appropriate slots. DO NOT ALTER ONCE PASTED.
        This can scramble parsing.
      </v-card-text>

      <v-card-text>
        <f-textarea v-model="raw" />
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <g-button label="Cancel" @onclick="dialog = false" />
        <v-btn color="primary" text @click="insertData"> Insert </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const supplierCellSlots = [
  "s.name",
  "s.address",
  "s.city",
  "s.subcity",
  "s.subjur",
  "s.zip_code",
  "s.fax_number",
  "s.business_number",
  "s.business_extension",
  "s.website",
];
// META
const hotelCellSlots = [
  "m.hotel_class",
  "m.tax",
  "m.breakfast",
  "m.car_parking",
  "m.bus_parking",
  "m.commisionable",
  "m.porterage",
  "m.foc",
];
const restaurantCellSlots = ["m.style", "m.tax", "m.inclusive", "m.foc"];
const excursionCellSlots = ["m.tax", "m.inclusive", "m.foc"];
// Contacts
const contactCellSlots = ["p.name", "p.title", "p.email", "p.phone_number", "p.cell_number"];
export default {
  props: {
    supplierType: String,
  },
  data() {
    return {
      dialog: false,
      raw: null,
    };
  },
  methods: {
    insertData() {
      // What returns + easy read stuff
      let dump = [];
      const cellSlots = [
        ...supplierCellSlots,
        ...(this.supplierType === "HOTEL"
          ? hotelCellSlots
          : this.supplierType === "RESTAURANT"
          ? restaurantCellSlots
          : this.supplierType === "EXCURSION"
          ? excursionCellSlots
          : []),
        "m.comments",
        ...contactCellSlots,
      ];
      let keyref = {
        s: "supplier",
        m: "meta",
        p: "primary_contact",
      };

      // Split the rows & pop end if empty
      let rows = this.raw.split("\n");
      if (rows[rows.length - 1] == "") rows.pop();

      // For each row in excel
      rows.forEach((row) => {
        let base = {
          supplier: {},
          primary_contact: {},
          meta: {},
        };

        // Split the columns
        let columns = row.split("\t");
        // Columns MUST correspond to cell slots
        columns.forEach((c, index) => {
          if (!cellSlots[index]) return;
          let ref = cellSlots[index].split(".");
          base[keyref[ref[0]]][ref[1]] = c;
        });

        dump.push(base);
      });

      this.$emit("insert", dump);
      this.dialog = false;
    },
  },
};
</script>
