<template>
  <v-chip small dense :color="color">{{ formatted }}</v-chip>
</template>

<script>
export default {
  props: {
    confirmed: Number,
    totalSuppliers: Number,
  },
  computed: {
    percentage() {
      if (!this.totalSuppliers) return 0;
      return this.confirmed / this.totalSuppliers;
    },
    formatted() {
      return `${Number(this.percentage * 100).toFixed(0)} %`;
    },
    color() {
      return `rgba(0, 200, 83, ${this.percentage})`;
    },
  },
};
</script>
