<template>
  <v-dialog :value="value" persistent max-width="600">
    <v-card>
      <v-card-title class="subtitle-2">Sending...</v-card-title>
      <v-card-text style="color: black">
        <BulkEmailDialogRow
          v-for="(email, index) in emailList"
          :key="email.supplier_id"
          :supplierName="email.supplier_name"
          :contacts="email.contacts"
          :promise="email.promise"
          :loading="loading"
          @send-one-mail="$emit('send-one-mail', index)"
        />
        <v-row justify="end">
          <v-btn text @click="$emit('input', false)">
            <v-icon small left>close</v-icon>
            <span>Close</span>
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import BulkEmailDialogRow from "./_BulkEmailDialogRow";
export default {
  props: {
    value: Boolean,
    emailList: Array,
    loading: Boolean,
  },
  components: {
    BulkEmailDialogRow,
  },
};
</script>
