<template>
  <v-form class="py-3" v-model="valid" @submit.prevent="submit">
    <v-text-field
      v-model="email"
      label="Email"
      prepend-icon="mail"
      :rules="[(v) => !!v || 'Invalid email']"
      required
    ></v-text-field>
    <v-text-field
      v-model="password"
      prepend-icon="lock"
      :append-icon="show1 ? 'visibility' : 'visibility_off'"
      :type="show1 ? 'text' : 'password'"
      name="password"
      label="Password"
      :rules="[(v) => !!v || 'Invalid password']"
      @click:append="show1 = !show1"
      required
    ></v-text-field>

    <v-row justify="end">
      <v-btn text x-small @click="$emit('forgot')">Forgot Password</v-btn>
    </v-row>

    <v-btn class="ma-4" color="primary" type="submit" :disabled="!valid">Login</v-btn>
  </v-form>
</template>

<script>
export default {
  props: {
    // DOM is destroyed when loading, so need persistent function
    // ($emit won't work here)
    loggedin: Function,
  },
  data() {
    return {
      valid: true,
      email: null,
      password: null,
      show1: false,
    };
  },
  methods: {
    submit() {
      let vm = this;
      this.$store
        .dispatch("login", {
          email: this.email,
          password: this.password,
        })
        .then((account) => {
          this.$router.push({ name: "home" });
          vm.loggedin(account);
        })
        .catch((err) => vm.loggedin(null, err));
    },
  },
  mounted() {
    // Pull email and password in saved cached
    // Autologin
    this.$store.dispatch("autoLogin").catch((err) => this.$root.$error(err));
  },
};
</script>

<style></style>
