<template>
  <div>
    <BookingSaveHeader class="pa-1" :admin="isAdmin" />

    <BaseForm :bookingId="bookingId" />
    <TopInfo />
    <FlightInfo :bookingId="bookingId" />
    <TourGuide />
    <MotorCoach />
    <Notes fieldName="notes" />
    <Notes fieldName="invoice_notes" />
  </div>
</template>

<script>
import { BookingSaveHeader, BaseForm, TopInfo, TourGuide, MotorCoach, Notes } from "@/modules/bookings";

import { FlightInfo } from "@/modules/bookings.flights";
export default {
  components: {
    BookingSaveHeader,
    BaseForm,
    TopInfo,
    FlightInfo,
    TourGuide,
    MotorCoach,
    Notes,
  },
  computed: {
    isAdmin() {
      return this.$store.getters["isAdmin"];
    },
    bookingId() {
      return this.$store.getters["BookingStore/booking_id"];
    },
  },
};
</script>
