import axios from "@/plugins/axios";

export default {
  updateAccount(account_id, data) {
    return axios.post(`/account/${account_id}`, data);
  },
  changeEmail(new_email, password) {
    return axios.post("/change_email", {
      new_email,
      password,
    });
  },
  changePassword(old_password, new_password) {
    return axios.post("/change_password", {
      old_password,
      new_password,
    });
  },
  switchEnvironment(account_id, mod) {
    return axios.post(`/change_env/${account_id}`, { env: mod });
  },

  /******
   * LINKED EMAILS
   * ****/
  getPersonalEmailList(account_id) {
    return axios.get(`/account_emails/accounts/${account_id}/SELF`);
  },
  addCustomEmail(account_id, source, data) {
    return axios.post("/account_emails", { account_id, source, data });
  },
  updateCustomEmail(custom_email_id, data) {
    return axios.post(`/account_emails/${custom_email_id}`, { data });
  },
  updateEmailPassword(custom_email_id, email_password) {
    return axios.post(`/account_emails/${custom_email_id}/email_password`, { email_password });
  },
  deleteCustomEmail(custom_email_id) {
    return axios.delete(`/account_emails/${custom_email_id}`);
  },
};
