<template>
  <v-col>
    <v-row justify="space-between" class="ma-0 pa-0">
      <v-card>
        <v-tabs vertical v-model="tab">
          <v-tab>
            <v-icon small left>mail</v-icon>
            <span>Email</span>
          </v-tab>
          <v-tab>
            <v-icon small left>note</v-icon>
            <span>Notes</span>
          </v-tab>

          <v-tab-item>
            <HistorySearch lockDates v-model="emailFilter" @search="searchEmails" />
          </v-tab-item>

          <v-tab-item>
            <HistorySearch v-model="noteFilter" @search="searchNotes" />
          </v-tab-item>
        </v-tabs>
      </v-card>

      <v-col cols="1">
        <add-button v-if="tab === 1" @click="$router.push({ name: 'suppliers_history_addnote' })" />
      </v-col>
    </v-row>

    <v-divider class="my-2" />

    <div v-if="view === 'SHOW'">
      <v-expansion-panels popout focusable hover flat>
        <v-expansion-panel v-for="h in historyContent" :key="h.id">
          <v-expansion-panel-header style="padding: 5px 15px">
            <HistoryCardHeader
              :type="h.type"
              :date="h.date"
              :content="h"
              :contactMap="contactMapping"
              :accountMap="accountEmailMapping"
              @edit="editNote"
              @reply="replyMail"
            />
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <HistoryCard :type="h.type" :content="h" :contactMap="contactMapping" :accountMap="accountEmailMapping" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <d-no-display v-else-if="view === 'NOVAL'" label="(No history to show)" />
    <v-progress-linear v-else-if="view === 'LOADING'" color="blue" indeterminate rounded></v-progress-linear>
  </v-col>
</template>

<script>
import format from "@/services/format";
import { EmailService } from "@/modules/email";
import { HistorySearch, HistoryCard, HistoryCardHeader, SupplierHistoryService } from "@/modules/suppliers.history";
export default {
  components: {
    HistorySearch,
    HistoryCard,
    HistoryCardHeader,
  },
  data() {
    return {
      tab: 0,
      historyList: [],

      noteFilter: {
        user: "",
        contact: "",
        dates: {
          from: "",
          to: "",
          range: true,
        },
      },

      loadingEmail: false,
      emailFilter: {
        user: "",
        contact: "",
        dates: {
          // THREE DAYS AGO
          from: format.convertDate(new Date(Date.now() - 3 * 24 * 60 * 60 * 1000)),
          to: format.convertDate(new Date()),
          range: true,
        },
      },
    };
  },
  computed: {
    supplierId() {
      return this.$store.getters["SupplierStore/id"];
    },
    contactMapping() {
      const contacts = this.$store.getters["SupplierStore/all_contacts"];
      let obj = {};
      contacts.forEach((item) => (obj[item.email.toLowerCase()] = item.name));
      return obj;
    },
    accountEmailMapping() {
      return this.$store.getters["SupplierHistoryStore/account_ref"];
    },

    // EMAIL LIST
    gotData() {
      // Hide data if querying new supplier
      // Otherwise show cache until new history is found
      const storedSupplierId = this.$store.getters["SupplierHistoryStore/savedSupplierId"];
      if (!storedSupplierId) return false;
      if (storedSupplierId && this.loadingEmail) return false;
      if (storedSupplierId === 1) return true; // Error
      if (storedSupplierId !== this.supplierId) return false;
      return true;
    },
    emailList() {
      if (!this.gotData) return [];
      return this.$store.getters["SupplierHistoryStore/emailList"];
    },

    view() {
      if (!this.gotData) return "LOADING";
      if (!this.historyContent.length) return "NOVAL";
      return "SHOW";
    },
    historyContent() {
      // EMAIL
      if (this.tab === 0) return this.emailList;
      // NOTES
      const f = this.noteFilter;
      return this.historyList
        .filter((item) => {
          // Apply filter
          if (
            f.user &&
            !SupplierHistoryService.checkItemHas(item, f.user, typeof f.user === "object", this.contactMapping)
          )
            return false;
          if (f.contact && !SupplierHistoryService.checkItemHas(item, f.contact, false, this.contactMapping))
            return false;
          if (f.dates && f.dates.from && !SupplierHistoryService.checkDate(item, f.dates)) return false;

          return true;
        })
        .sort((a, b) => {
          if (a.date > b.date) return -1;
          if (a.date < b.date) return 1;
          return 0;
        });
    },
  },
  methods: {
    editNote(contentId) {
      this.$router.push({
        name: "suppliers_history_editnote",
        params: {
          note_id: contentId,
        },
      });
    },
    replyMail(content) {
      this.$store.dispatch("openEmailReplyTab", {
        supplierId: this.supplierId,
        isclient: false,
        originalContent: content,
      });
    },

    // Search Blobs
    searchEmails(filter) {
      // Get emails
      this.emailFilter = filter;

      this.loadingEmail = true;
      this.$store
        .dispatch("SupplierHistoryStore/getSupplierEmailHistory", {
          supplierId: this.supplierId,
          query: SupplierHistoryService.createEmailQuery(
            this.emailFilter,
            this.$store.getters["SupplierStore/all_contacts"],
            this.$store.getters["SupplierHistoryStore/account_ref"]
          ),
        })
        .then((v) => {
          this.loadingEmail = false;
        })
        .catch((err) => {
          this.$root.$error(err);
        });
    },
    searchNotes(filter) {
      this.noteFilter = filter;
    },
  },
  mounted() {
    this.searchEmails(this.emailFilter);

    // Get other stuff
    Promise.all([
      this.$store.dispatch("SupplierHistoryStore/getHistory", this.supplierId),
      this.$store.dispatch("EmailStore/getAccountEmails"),
    ])
      .then((results) => {
        this.historyList = results[0];

        // Add accounts to history store so search can reference
        this.$store.dispatch("SupplierHistoryStore/storeAccounts", results[1]);
      })
      .catch((err) => this.$root.$error(err));
  },
};
</script>
