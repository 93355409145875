<template>
  <a-group icon="event">
    <v-row>
      <v-col>
        <elwrapper label="From" smlabel>
          <v-text-field :value="displayFrom" outlined hide-details dense readonly @click="dialog = true"></v-text-field>
        </elwrapper>
      </v-col>

      <v-col>
        <elwrapper label="To" smlabel>
          <v-text-field :value="displayTo" outlined hide-details dense readonly @click="dialog = true"></v-text-field>
        </elwrapper>
      </v-col>

      <SelectDateDialog
        :currentValue="value"
        :datewall="datewall"
        v-model="dialog"
        @revert="(v) => $emit('revert', v)"
        @update="(v) => $emit('update', v)"
        @copy="(v) => $emit('copy', v)"
        @duplicate="(v) => $emit('duplicate', v)"
      />
    </v-row>
  </a-group>
</template>

<script>
import format from "@/services/format";
import SelectDateDialog from "./_SelectDateDialog";
export default {
  components: {
    SelectDateDialog,
  },
  props: {
    value: Object,
    datewall: [String, Date],
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    displayFrom() {
      return this.value && this.value.from ? format.formatDate(this.value.from) : "";
    },
    displayTo() {
      return this.value && this.value.to ? format.formatDate(this.value.to) : "";
    },
  },
};
</script>

<style lang="scss"></style>
