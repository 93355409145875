import format from "@/services/format";
import _ from "lodash";
import BOOKING_ROOMS from "@/config/BOOKING_ROOMS";
import { BookingSupplierRoomService } from "@/modules/bookings.supplier_rooms";

export default {
  reduceRooms,
  getRoomingListBreakdown,
  getPaxCount,
  getPaxCountWithPaxnumDefault,
  flattenAndGetRoomingInfo,
  sortRoomList,
  getExtraRoomList,
};

function reduceRooms(roomBreakdown, extraFilter) {
  // roomBreakdown 	@Object 	Room type key => count
  // extraFilter		@Array		List of supplier rooms
  let r = [];
  BOOKING_ROOMS.forEach((item) => {
    // Append Supplier Rooms to end
    if (extraFilter.length && item.value.includes("SUPPLIER")) {
      const ref = item.value.split(".")[1];
      const adjustCount = extraFilter.filter((item) => item.type_as === ref).length;
      if (!adjustCount) return;

      r.push({
        label: item.label,
        count: adjustCount,
      });
      return;
    }

    if (!roomBreakdown[item.value]) return;

    r.push({
      label: item.label,
      count: roomBreakdown[item.value],
    });
  });
  return r;
}

function getRoomingListBreakdown({ bookingMetaData, bookingRoomingList, bookingDays, supplierRoomList }) {
  // A normalizaer function, extracts information for each
  // bookingMetaData		@Object 	Main booking's meta, where estimate rooming list stored
  // bookingRoomingList 	@Array		The actual rooming list, minus supplier rooms
  // bookingSuppliers 	@Object 	Supplier rooms are stored here

  const bookingMainBreakdown = bookingMetaData && bookingMetaData.room_num;

  const roomingListBreakdown =
    bookingRoomingList &&
    bookingRoomingList.reduce((o, item) => {
      if (item.meta && item.meta.cancelled) return o;
      if (!o[item.type]) {
        o[item.type] = 1;
      } else {
        o[item.type] += 1;
      }
      return o;
    }, {});

  const specialRoomList = getExtraRoomList(bookingDays, supplierRoomList);

  return {
    bookingMainBreakdown,
    roomingList: bookingRoomingList,
    roomingListBreakdown,
    specialRoomList,
  };
}

function getPaxCount(bookingMainBreakdown, roomingList) {
  // bookingMainBreakdown 	@Object 	Breakdown of room est for booking
  // roomingList 				@Array 		Actual rooming list
  let total = 0;

  if (roomingList && roomingList.length > 0) {
    total = roomingList.reduce((total, room) => {
      // Don't include cancelled
      if (room.meta && room.meta.cancelled) return total;
      return total + Number(room.pax);
    }, 0);
  } else if (bookingMainBreakdown) {
    BOOKING_ROOMS.forEach((item) => {
      if (bookingMainBreakdown[item.value]) {
        total += Number(item.pax) * Number(bookingMainBreakdown[item.value]);
      }
    });
  }

  return total;
}

function getPaxCountWithPaxnumDefault(bookingPaxNum, roomingList) {
  // bookingPaxNum 			@Number 	Bookings Pax
  // roomingList 				@Array 		Actual rooming list
  if (roomingList && roomingList.length > 0) {
    return roomingList.reduce((total, room) => {
      return total + Number(room.pax);
    }, 0);
  } else {
    return bookingPaxNum;
  }
}

function flattenAndGetRoomingInfo({
  confirmed,
  dateList,
  bookingMainBreakdown,
  roomingListBreakdown,
  roomingList,
  specialRoomList,
  supplierCustomRoom,
}) {
  const EST = "EST",
    ROOMING = "ROOMING",
    CUSTOM = "CUSTOM";

  let extraFilter = [],
    extraTotal = 0;

  // Strip extra rooms if not in overlapping date range
  if (specialRoomList) {
    if (Array.isArray(dateList)) {
      extraFilter = dateList.reduce((arr, date) => {
        return _.uniqWith([...arr, ...specialRoomList[date]], (a, b) => a.id === b.id);
      }, []);
    } else {
      extraFilter = specialRoomList[dateList] || [];
    }
    extraTotal = extraFilter.length;
  }

  const mainData = {
    room_breakdown: bookingMainBreakdown || {},
    room_total: _getTotalOfBreakdown(bookingMainBreakdown),
    pax_total: getPaxCount(bookingMainBreakdown, null),
  };

  const roomingData = {
    room_breakdown: roomingListBreakdown || {},
    room_total: _getTotalOfBreakdown(roomingListBreakdown),
    pax_total: getPaxCount(null, roomingList),
  };

  const customData = {
    room_breakdown: supplierCustomRoom || {},
    room_total: _getTotalOfBreakdown(supplierCustomRoom),
    pax_total: getPaxCount(supplierCustomRoom, null),
  };

  let using = EST; // Default

  if (roomingData.room_total) {
    // IF CONFIRMED (rooming list sent) -- Show Rooming List sent
    using = ROOMING;
  } else if (customData.room_total) {
    // Custom Booking Supplier Room
    using = CUSTOM;
  }

  // using: EST, SUPPLIER, ROOMING
  return {
    using,
    extraFilter,
    extraTotal,
    ...(using === EST && mainData),
    ...(using === ROOMING && roomingData),
    ...(using === CUSTOM && customData),
  };
}

function _getTotalOfBreakdown(breakdown) {
  if (!breakdown) return 0;

  return Object.values(breakdown).reduce((total, value, key) => {
    return Number(total) + Number(value);
  }, 0);
}

function sortRoomList(roomList, specialListMapping, dateList, popCancelled) {
  var group = {};
  let key, extra, request, adjustNameList;
  // -- SUPPLIER ROOMS
  let useAll = dateList === "ALL" || false;
  let filteredSpecialList = [];
  if (useAll) {
    filteredSpecialList = specialListMapping.ALL;
  } else if (typeof dateList === "string") {
    filteredSpecialList = specialListMapping[dateList] || [];
  } else if (Array.isArray(dateList)) {
    // Get suppliers in Hotel date range
    let filteredSpecialList = specialListMapping.ALL.filter((sup) => {
      // Extract suppliers in date range
      let overlap = false;
      let datesNotInRange = dateList.filter((date) => {
        let inRange = sup.roomDateRange.includes(format.convertDate(date));
        let out = !inRange;
        if (inRange) overlap = true;
        return out;
      });

      let pass = datesNotInRange.length === 0;
      if (overlap && !pass) useAll = true;
      return overlap;
    });
  }

  let supitem = {
    type: "SINGLE_DK",
    pax: 1,
  };

  key = supitem.type + supitem.pax;
  filteredSpecialList.forEach((item, index) => {
    extra = item.type_as === "TRANSPORT" ? "Bus Driver" : "Tour Guide";
    if (!group[key]) {
      group[key] = {
        type: supitem.type,
        key,
        pax: supitem.pax,
        rooms: [],
      };
    }

    adjustNameList = [
      {
        first: item.first_name,
        last: item.last_name,
        extra,
      },
    ].map((n) => {
      // Hide Bus Driver name if default (Bus Driver)
      if (item.type_as === "TRANSPORT" && n.first === "Driver") {
        return {
          first: "",
          last: "",
          extra,
        };
      }
      return n;
    });

    if (!useAll) {
      // Add Suppliers for THIS specific day
      group[key].rooms.push({
        index: index,
        request: request,
        nameList: adjustNameList,
      });
    }

    if (useAll) {
      let cleanedRoomDateRange = item.roomDateRange;
      group[key].rooms.push({
        index: index,
        // extra: extra,
        showDates: (() => {
          // All or fixed day
          if (typeof dateList === "string") return true;
          // Every date for room booked should be in hotel's dates
          // If one date is missing, return false
          return (
            dateList.filter((date) => {
              return !cleanedRoomDateRange.includes(format.convertDate(date));
            }).length > 0
          );
        })(),
        dates: cleanedRoomDateRange
          .filter((d) => {
            if (dateList === "ALL") return true;
            if (item === "ALL") return false;
            if (dateList === format.convertDate(d)) return true;
            // Only get dates in hotel date range
            return dateList.includes(format.convertDate(d));
          })
          .map((date) => format.convertDate(date))
          .sort((a, b) => {
            if (a > b) return 1;
            if (a < b) return -1;
            return 0;
          })
          .map((item) => format.formatDate(item, true)),
        nameList: adjustNameList,
      });
      return;
    }
  });

  // // ACUTAL ROOMS
  roomList.forEach((item, index) => {
    key = item.type + item.pax;
    let request = item.meta && item.meta.request;

    if (item.meta.cancelled && popCancelled) return;

    // Add group if doesn't exist
    if (!group[key]) {
      group[key] = {
        type: item.type,
        key,
        pax: item.pax,
        rooms: [],
      };
    }

    // Append current room to the list
    group[key].rooms.push({
      index: index,
      nameList: item.nameList,
      request,
      meta: item.meta || {},
    });
  });

  // Sort by pax
  const long = group["DOUBLE_QK2"];
  delete group["DOUBLE_QK2"];
  const temp = Object.values(group).sort((a, b) => {
    if (a.pax < b.pax) return -1;
    if (a.pax > b.pax) return 1;
    return 0;
  });

  return long ? [long, ...temp] : temp;
}

function getExtraRoomList(bookingDays, supplierRoomList) {
  let dateMapping = {
    ALL: BookingSupplierRoomService.getSupplierRoomFlatList({
      dayList: bookingDays,
      bookingSupplierRooms: supplierRoomList,
      list: true,
    }),
  };
  let adjustDay = bookingDays.length ? bookingDays : [];

  adjustDay.forEach((day) => {
    // HACK FIX -- suppliers are being added to a day multiple times...
    dateMapping[day.date] = _.uniq(day.supplier_rooms).map((sroomid) => {
      return (supplierRoomList || []).find((sroom) => sroom.id === sroomid);
    });
  });

  // const CONFIRMED = 0;	// Add right away (but NOT cancelled)
  // let compiledRoomData = [];

  // // Get confirmed suppliers
  // const confirmed = Object.values(bookingSuppliersObject || {})
  // 	.filter(item => {
  // 		// Fix meta type
  // 		if(typeof(item.meta) === 'string') item.meta = JSON.parse(item.meta)

  // 		// Filter
  // 		if(['TRANSPORT', 'TOUR_GUIDE'].includes(item.type_as)
  // 			&& item.meta
  // 			&& item.meta.createRoom){
  // 			return true;
  // 		}
  // 		return false;
  // 	})

  // // Group by date
  // let dateMapping = { 'ALL': [] }
  // confirmed.forEach(item => {
  // 	if(!item.meta) return;

  // 	let blob = [];
  // 	let status = item.status >= CONFIRMED;
  // 	item.meta.roomDateRange.forEach(date => {
  // 		if(item.meta.room_nameList) {
  // 			// If names added already
  // 			blob = item.meta.room_nameList.map(name => {
  // 				// Add to ALL
  // 				// Matching based on Supplier + unique name
  // 				let added = dateMapping.ALL.find(i => i.supplier_id === item.supplier_id
  // 						&& i.nameList[0].first === name.first && i.nameList[0].last === name.last)
  // 				if(!added){
  // 					dateMapping.ALL.push(_getRoom(item, status, name))
  // 				} else {
  // 					// MERGE
  // 					Object.assign(added, {
  // 						roomDateRange: [...new Set([...added.roomDateRange, ...item.meta.roomDateRange])]
  // 					})
  // 				}

  // 				// For individual day
  // 				return _getRoom(item, status, name)
  // 			})
  // 		} else {
  // 			// Use the count of supplier (eg. 2 buses)
  // 			let count = Number(item.meta.count) || 1;
  // 			blob = Array.from({length: count},
  // 					() => _getRoom(item, status, { first: null, last: null }) )

  // 			// Add to ALL
  // 			// Should be the MAX count
  // 			let existing = dateMapping.ALL.filter(i => i.supplier_id === item.supplier_id);
  // 			let currentAllCount = existing.length;
  // 			if(currentAllCount < count){
  // 				let adjustedCount = count - currentAllCount;
  // 				dateMapping.ALL.push(...Array.from({length: adjustedCount},
  // 					() => _getRoom(item, status, { first: null, last: null }) ))
  // 			} else {
  // 				// Add date to existing if they aren't added yet
  // 				existing.forEach(item => {
  // 					if(!item.roomDateRange.includes(date)) item.roomDateRange.push(date)
  // 				})
  // 			}

  // 		}

  // 		dateMapping[date] = !dateMapping[date]? blob : [...dateMapping[date], ...blob];
  // 	})
  // })

  return dateMapping;
}

// function _getRoom(item, confirmed, name){
// 	return {
// 		confirmed,
// 		type_as: item.type_as,
// 		supplier_id: item.supplier_id,
// 		booking_supplier_id: item.id,
// 		roomDateRange: [...item.meta.roomDateRange],
// 		nameList: [name]
// 	}
// }
