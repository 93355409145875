<template>
  <v-row justify="space-between" class="pb-2">
    <g-button label="Cancel" @onclick="goback" />
    <h3 class="headline white--text">{{ label }}</h3>
    <g-button type="primary" label="Create" :disabled="disabled" @onclick="create" />
  </v-row>
</template>

<script>
export default {
  props: {
    label: String,
    disabled: Boolean,
    goBackTo: Object, // route object
    oncreate: {
      required: true,
      type: Function, // Must return promise
    },
  },
  methods: {
    goback() {
      this.$router.push(this.goBackTo);
    },
    create() {
      if (this.disabled) return;

      this.$root.$loading.open(`Creating ${this.label}...`);
      this.oncreate()
        .then((v) => {
          this.$root.$loading.end();
        })
        .catch((err) => {
          this.$root.$loading.end();
          this.$root.$error(err);
        });
    },
    keysave(event) {
      // CLICK Ctrl+S
      if (!(event.which == 115 && event.ctrlKey) && !(event.which == 19)) return true;
      event.preventDefault();
      this.create();
      return false;
    },
  },
  mounted() {
    window.addEventListener("keypress", this.keysave);
  },
  beforeDestroy() {
    window.removeEventListener("keypress", this.keysave);
  },
};
</script>
