<template>
  <v-container class="pa-0 flex-nowrap" fluid fill-height style="align-items: start; max-width: 100%; overflow: auto">
    <DatabaseNavigationDrawer :dbConfig="dbConfig" :dbSource="dbSource" />

    <v-col align-self="start" class="database-table-wrapper">
      <v-row justify="space-between" class="ma-2 top-row">
        <div class="d-flex align-center">
          <g-button
            :label="showSearch ? 'hide' : 'show search'"
            :icon="showSearch ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
            @onclick="toggleSearch"
          />

          <SupplierExportButton
            :dbSource="dbSource"
            :searchQuery="origQuery"
            v-if="dbConfig === 'suppliers' && isAdmin"
          />

          <month-range
            v-if="dbConfig === 'products'"
            label="Pricing Dates"
            v-model="dateRange"
            @update="updateSeasonalPrice"
            class="ml-4"
          />

          <ProductYearDrop v-if="dbConfig === 'products'" v-model="focusYear" strict />
        </div>

        <div>
          <g-button
            v-if="dbConfig === 'suppliers'"
            @onclick="$router.push({ name: 'database_email', params: { supplier_type: dbSource } })"
            label="Email"
            icon="send"
            type="primary"
            class="mx-2"
          />
          <g-button
            v-if="dbConfig === 'clients'"
            @onclick="$router.push({ name: 'database_client_email' })"
            label="Email"
            icon="send"
            type="primary"
            class="mx-2"
          />
          <g-button
            v-if="dbConfig === 'suppliers'"
            label="Mass Add"
            icon="library_add"
            class="mx-2"
            @onclick="massAddNew"
          />
          <add-button @click="createNew" />
        </div>
      </v-row>

      <DatabaseSearch v-show="showSearch" :type="dbConfig" :mod="dbSource" @search="updateQuery" />

      <DatabaseTable
        class="table"
        :height="showSearch ? '330px' : '520px'"
        :dbConfig="dbConfig"
        :dbSource="dbSource"
        :query="origQuery"
        :focusYear="focusYear"
      />
    </v-col>
  </v-container>
</template>

<script>
import { DatabaseNavigationDrawer, DatabaseSearch, DatabaseTable } from "@/modules/database";
import { ProductYearDrop } from "@/modules/products";
import { SupplierExportButton } from "@/modules/suppliers";
export default {
  components: {
    DatabaseNavigationDrawer,
    DatabaseSearch,
    DatabaseTable,
    ProductYearDrop,
    SupplierExportButton,
  },
  data() {
    const now = new Date();

    return {
      showSearch: true,
      //dbSource: null,
      dbQuery: null,

      dateRange: {
        from_month: now.getMonth() + 1,
        from_day: now.getDate(),
      },
    };
  },
  computed: {
    dbConfig() {
      return this.$route.params.view;
    },
    dbSource() {
      return this.$route.params.sub;
    },

    origQuery() {
      const savedTab = this.$store.getters["DatabaseStore/activeTab"](this.dbConfig);
      return savedTab.data ? { ...savedTab.data } : this.dbQuery;
    },

    focusYear: {
      get() {
        return this.$store.getters["workingYear"];
      },
      set(v) {
        this.$store.dispatch("setDefaultProductYear", v);
      },
    },

    isAdmin() {
      return this.$store.getters["isAdmin"];
    },
  },
  methods: {
    updateSeasonalPrice(v) {
      this.$store.dispatch("DatabaseStore/applySeasonPriceRange", { ...this.dateRange });
    },
    massAddNew() {
      // Name of route to redirect to
      const path = {
        suppliers: "suppliers_mass_add",
        // products: 'products_new',
        // clients: 'clients_new'
      };

      this.$router.push({ name: path[this.dbConfig], query: { type: this.dbSource } });
    },
    createNew() {
      // Name of route to redirect to
      const path = {
        suppliers: "suppliers_new",
        products: "products_new",
        clients: "clients_new",
      };

      this.$router.push({ name: path[this.dbConfig], query: { type: this.dbSource } });
    },
    updateQuery(query) {
      this.dbQuery = { ...query };
      //this.adjustTable()
    },

    toggleSearch() {
      this.showSearch = !this.showSearch;
      //this.adjustTable();
    },
    // adjustTable(){
    //   this.$nextTick()
    //     .then(function (res) {
    //     let OFFSET = 30
    //     if(res.showSearch) OFFSET += 175;
    //     res.dbHeight = (res.$refs.databaseWrapper.$el.clientHeight-OFFSET)+'px';
    //   });
    //}
  },
  mounted() {
    // Clear cache for supplier, products, clients
    // PRevents looping in environment change
    const env = this.$store.getters["AccountStore/environment"];
    this.$store.dispatch("SupplierStore/setFocusSupplier", { supplier: { env } });
    this.$store.dispatch("ProductStore/setProduct", { env });
    this.$store.dispatch("ClientStore/setClient", { client: { env } });
  },
};
</script>

<style lang="scss">
.database-table-wrapper {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  .table {
    flex: 1;
  }

  .top-row {
    max-height: 25px;
  }
}

.dataview {
  height: 320px;
  overflow: auto;

  &.dataview-full {
    height: 520px;
  }
}
</style>
