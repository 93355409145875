<template>
  <div>
    <div class="caption ma-2">
      <span class="font-weight-bold">{{ displayTitle }}</span>
      <span v-if="!displayList.length" class="mx-2">- ({{ displayEmpty }})</span>
    </div>

    <v-row v-for="payment in displayList" :key="configAs + payment.id">
      <v-col cols="7">
        <span>
          <v-icon small left>event</v-icon>
          {{ payment.date }}
        </span>
        <span v-if="payment.isfull" class="green white--text pa-1">(Full)</span>
        <span v-if="payment.ispartial" class="warning white--text pa-1">(Partial)</span>
        <span v-if="payment.isdeposit" class="primary white--text pa-1">(Deposit)</span>

        <v-tooltip left v-if="payment.notes" right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small v-on="on">
              <v-icon small>note</v-icon>
            </v-btn>
          </template>
          <span>{{ payment.notes }}</span>
        </v-tooltip>
      </v-col>

      <v-col class="d-flex justify-end">
        <span>{{ payment.amount }}</span>
      </v-col>

      <v-col cols="1">
        <v-btn
          icon
          x-small
          @click="configAs === 'INVOICE_HISTORY' ? removeInvoice(payment.id) : removePayment(payment.id)"
        >
          <v-icon small>clear</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import money from "@/services/money";
import format from "@/services/format";
export default {
  props: {
    configAs: String,
  },
  data() {
    return {
      invoiceDialog: false,
      partPaidDialog: false,
    };
  },
  computed: {
    displayTitle() {
      return this.configAs === "INVOICE_HISTORY" ? "Invoice History" : "Payment History";
    },
    displayEmpty() {
      return this.configAs === "INVOICE_HISTORY" ? "No invoice set" : "No payment made";
    },
    displayList() {
      if (this.configAs === "INVOICE_HISTORY") {
        const v = this.$store.getters["FinanceReportStore/client_invoice_history"];
        if (!v) return [];
        return v.map((item) => {
          return {
            id: item.id,
            date: format.formatDate(item.sent_date),
            amount: `$ ${money.format(item.total_invoice, { precision: 2 })}`,
            isdeposit: item.invoice_type === "DEPOSIT",
            ispartial: item.invoice_type === "PARTIAL",
            isfull: item.invoice_type === "FULL",
            notes: item.notes,
          };
        });
      }

      const v = this.$store.getters["FinanceReportStore/payment_history"];
      if (!v) return [];
      return v
        .sort((a, b) => {
          if (a.paid_date < b.paid_date) return 1;
          if (a.paid_date > b.paid_date) return -1;
          return 0;
        })
        .map((item) => {
          return {
            id: item.id,
            date: format.formatDate(item.paid_date),
            amount: `$ ${money.format(item.paid_amount, { precision: 2 })}`,
            isdeposit: item.isdeposit,
          };
        });
    },
  },
  methods: {
    formatCost(v) {
      return v ? `$ ${money.format(v, { precision: 2 })}` : "$ --";
    },
    removeInvoice(invoice_id) {
      this.$root.$loading.open("Deleting invoice");
      this.$store
        .dispatch("FinanceReportStore/removeClientInvoice", invoice_id)
        .then((_) => this.$root.$loading.end())
        .catch((err) => this.$root.$error(err));
    },
    removePayment(payment_id) {
      this.$root.$loading.open("Deleting payment");
      this.$store
        .dispatch("FinanceReportStore/removeClientPayment", payment_id)
        .then((_) => this.$root.$loading.end())
        .catch((err) => this.$root.$error(err));
    },
  },
};
</script>

<style lang="scss"></style>
