<template>
  <a-card nopad>
    <div class="booking-title">Tour Top Information</div>
    <v-row class="px-1 flex-nowrap">
      <ConfirmField smlabel label="Client" type="CLIENT" :value="client" @input="(v) => updateClient(v)" />
      <v-col class="pa-0">
        <PaxField :disabled="disablePaxChange" />
        <elwrapper label="# of PAX" midtext cuslabel="min-width:60px;max-width:60px">
          <span class="caption">{{ pax_num }}</span>
        </elwrapper>
      </v-col>

      <v-col class="pa-0">
        <RoomNumberField
          v-model="room_num"
          :pax="pax_num"
          label="Est Rooms"
          style="width: 150px"
          est
          :disabled="disablePaxChange"
        />
        <RoomingListNumberHover :pax="pax_num" label="Rooming List" />
      </v-col>

      <a-field :config="config.airport_sign" widelabel v-model="airport_sign" midtext />

      <ProjectManagerField v-model="project_manager" />
    </v-row>
  </a-card>
</template>

<script>
import { BookingFields } from "@/config/fields/bookings";
import ConfirmField from "./confirmChangeField/confirmField";
import { RoomNumberField, RoomingListNumberHover, BookingRoomService } from "@/modules/bookings.rooms";
import ProjectManagerField from "./projectManagerField/ProjectManagerField";
import PaxField from "./_PaxField";
export default {
  components: {
    ConfirmField,
    RoomNumberField,
    ProjectManagerField,
    RoomingListNumberHover,
    PaxField,
  },
  data() {
    return {
      config: BookingFields,
    };
  },
  computed: {
    disablePaxChange() {
      return !!this.$store.getters["BookingRoomStore/totalRooms"];
    },
    client: {
      get() {
        var a = this.$store.getters["BookingStore/active"];
        return { id: a.client_id, name: a.client_name, invoiceType: a.client_invoice_type };
      },
      set(newValue) {
        this.$store.dispatch("BookingStore/update", {
          key: "client",
          val: newValue,
        });
      },
    },
    pax_num: {
      get() {
        const roomNum = this.get("room_num");
        const roomList = this.$store.getters["BookingRoomStore/rooms"];
        const paxNum = BookingRoomService.getPaxCount(roomNum, roomList);
        return this.get("pax_num") || paxNum;
      },
      set(newValue) {
        return this.set("pax_num", newValue);
      },
    },
    room_num: {
      get() {
        return this.get("room_num");
      },
      set(newValue) {
        return this.set("room_num", newValue);
      },
    },
    airport_sign: {
      get() {
        return this.get("airport_sign");
      },
      set(newValue) {
        return this.set("airport_sign", newValue);
      },
    },
    project_manager: {
      get() {
        return this.get("project_manager");
      },
      set(newValue) {
        return this.set("project_manager", newValue);
      },
    },

    usingRoomingList() {
      return this.$store.getters["BookingRoomStore/rooms"].length;
    },
  },
  methods: {
    get(val) {
      return this.$store.getters["BookingStore/data"][val];
    },
    set(key, val) {
      return this.$store.dispatch("BookingStore/update", { deep: true, key, val });
    },
    updateClient(v) {
      this.$root.$loading.open("Updating booking client");
      return this.$store
        .dispatch("BookingStore/updateBookingMajor", { key: "client", val: v })
        .then((v) => {
          this.$root.$success("Updated Booking Client");
          if (this.client.invoiceType === "FULL")
            this.$root.$warn(
              "Updated Booking Dates, The final invoice has already been sent to the client. Please make sure to send the invoice again with the latest information."
            );
          this.$root.$loading.end();
        })
        .catch((err) => this.$root.$error(err));
    },
    syncRoomData() {
      this.roomBreakdownData = BookingRoomService.getRoomingListBreakdown({
        bookingMetaData: this.$store.getters["BookingStore/data"],
        bookingRoomingList: this.$store.getters["BookingRoomStore/rooms"],
        bookingDays: this.$store.getters["BookingDayStore/days"],
        supplierRoomList: this.$store.getters["BookingSupplierRoomStore/supplier_rooms"],
      });
    },
  },
  mounted() {
    const paxNum = BookingRoomService.getPaxCount(
      this.$store.getters["BookingStore/data"].room_num,
      this.$store.getters["BookingRoomStore/rooms"]
    );
    if (!this.$store.getters["BookingStore/data"]["est_pax_num"])
      this.$store.dispatch("BookingStore/update", { deep: true, key: "est_pax_num", val: paxNum });

    if (!this.$store.getters["BookingStore/data"]["pax_num"])
      this.$store.dispatch("BookingStore/update", { deep: true, key: "pax_num", val: paxNum });
  },
};
</script>
