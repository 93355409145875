<template>
  <div class="caption">
    <!-- product -->
    <v-row>
      <v-col cols="3">
        <a-truncate :text="productName" :hoverText="productName" />
      </v-col>
      <v-col style="min-width: 200px; max-width: 200px"> </v-col>
      <v-col>
        <span v-if="product.perTotal">x {{ product.perTotal }}</span>
      </v-col>
      <v-col style="min-width: 90px; max-width: 90px">
        <span>x {{ product.dayCount || 0 }} {{ dayTextDisplay(product.dayCount) }}</span>
      </v-col>
      <v-col class="d-flex justify-center" style="min-width: 140px; max-width: 140px; border: 1px solid lightgrey">
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ getCompiledTotal(product) }}</span>
          </template>
          <div>
            <div>{{ displayTotalBreakdown(product) }}</div>
            <div v-if="focBreakdown" class="pl-3">{{ focBreakdown }}</div>
          </div>
        </v-tooltip>
      </v-col>
    </v-row>

    <!-- room -->
    <v-row v-for="(room, index) in product.perRoom" :key="'room' + index">
      <v-col cols="3">
        <span class="pl-3">{{ room.name }}</span>
      </v-col>
      <v-col style="min-width: 200px; max-width: 200px">
        <HotelPriceHover
          :text="getProductRate(room)"
          :taxInclusive="product.tax_inclusive"
          :tax="productTax"
          :supplierMeta="supplier.supplier_meta"
          :meta="product.product_meta"
          :original="room.original ? room.original.productRate : product.original.productRate"
        />
      </v-col>
      <v-col>
        <span v-if="room.perTotal">x {{ room.perTotal }}</span>
      </v-col>
      <v-col style="min-width: 90px; max-width: 90px">
        <span>x {{ product.dayCount || 0 }} {{ dayTextDisplay(product.dayCount) }}</span>
      </v-col>
      <v-col class="d-flex justify-center" style="min-width: 140px; max-width: 140px; border: 1px solid lightgrey">
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ getCompiledTotal(room) }}</span>
          </template>
          <div>
            <div>{{ displayTotalBreakdown(room) }}</div>
          </div>
        </v-tooltip>
      </v-col>
    </v-row>

    <!-- subroom -->
    <v-row v-for="(sub, index) in product.subpricing" :key="'sub' + index">
      <v-col cols="3">
        <span class="pl-3">+ {{ sub.displayName }}</span>
      </v-col>
      <v-col style="min-width: 200px; max-width: 200px">
        <HotelPriceHover
          :text="getProductRate(sub)"
          :taxInclusive="product.tax_inclusive"
          :tax="productTax"
          :supplierMeta="supplier.supplier_meta"
          :meta="product.product_meta"
          :original="sub.original ? sub.original.productRate : product.original.productRate"
        />
      </v-col>
      <v-col>
        <span v-if="sub.perTotal">x {{ sub.perTotal }}</span>
      </v-col>
      <v-col style="min-width: 90px; max-width: 90px">
        <span>x {{ sub.count || 0 }} {{ dayTextDisplay(sub.count) }}</span>
      </v-col>
      <v-col class="d-flex justify-center" style="min-width: 140px; max-width: 140px; border: 1px solid lightgrey">
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ getCompiledTotal(sub) }}</span>
          </template>
          <div>
            <div>{{ displayTotalBreakdown(sub) }}</div>
          </div>
        </v-tooltip>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { HotelPriceHover } from "@/modules/bookings.days";
import money from "@/services/money";

export default {
  props: {
    typeAs: String,
    productType: String,
    product: Object,
    supplier: Object,
    room: Object,
  },
  components: {
    HotelPriceHover,
  },
  computed: {
    productName() {
      return this.product.name;
    },
    productTax() {
      const tax = this.supplier.supplier_meta && this.supplier.supplier_meta.tax;
      const customTax = this.product.product_meta ? this.product.product_meta.custom_tax : 0;
      return customTax || tax || 0;
    },
    focBreakdown() {
      let total = this.product.totalCountOfItems;
      let foc = this.product.howManyFOC;
      return this.product.hasfoc ? `(${total} items - ${foc} foc)` : false;
    },
    roomTotal() {
      const productTotal = Math.floor(this.product.totalCountOfItems / this.product.dayCount);
      const roomDisplay = productTotal === 1 ? "Room" : "Rooms";
      return `${productTotal} ${roomDisplay}`;
    },
  },
  methods: {
    dayTextDisplay(count) {
      const pl = count === 1;
      return pl ? "Night" : "Nights";
    },
    getProductRate(product) {
      return money.convertFormat(
        product.productRate,
        this.supplier.supplier_env,
        this.$store.getters["AccountStore/environment"]
      );
    },
    getCompiledTotal(product) {
      if (!product.compiledTotal) return "$ --";
      return `$ ${money.format(product.compiledTotal, { precision: 2 })}`;
    },
    displayTotalBreakdown(product) {
      const total = product.totalCountOfItems || product.count;
      const foc = product.howManyFOC || 0;
      let rate = this.getProductRate(product);
      console.log(product);
      let note = "";
      if (this.$store.getters["AccountStore/environment"] != this.supplier.supplier_env) {
        note = "(Converted)";
        if (this.supplier.supplier_env === "USA") {
          const preSlash = rate.indexOf(" / ");
          rate = rate.substring(0, preSlash) + " USD" + rate.substring(preSlash);
        }
      }
      return `= ${
        product.type === "SINGLE_DOUBLE" || product.product_type === "ROOM" ? total - foc : total
      } * ${rate} ${note}`;
    },
  },
};
</script>
