<template>
  <v-col>
    <button-control-header
      :menu-items="menuItems"
      @add="$router.push({ name: 'suppliers_contacts_new' })"
      @setmode="setMode"
      :ondelete="deleteContacts"
      :onsave="onSave"
    >
      <template v-slot:withadd>
        <g-button class="mx-2" label="Import" icon="import_export" @onclick="importDialog = true" />
      </template>

      <template v-slot:default>
        <SupplierSelectDialog
          v-model="importDialog"
          select-one
          title="Copy contacts from..."
          @update="copyContactsFromSupplier"
        />
      </template>
    </button-control-header>

    <CheckboxWrapper
      v-for="(item, index) in contactList"
      :key="item.id"
      :value="item.id"
      cols="12"
      :show-check="showChecks"
      :checklist="deleteGroup"
      @toggle="toggleContact"
      :single="selectSingleOnly"
      :selected="primaryContactId"
      @select="selectContact"
    >
      <ContactCard :contact="item" :primary="primaryContactId === item.id" @goto="goto" />
    </CheckboxWrapper>

    <d-no-display v-if="contactList.length === 0" label="(No contacts to display)" />
  </v-col>
</template>

<script>
import { SupplierSelectDialog } from "@/modules/database";
import { ContactCard } from "@/modules/contacts";

export default {
  components: {
    ContactCard,
    SupplierSelectDialog,
  },
  data() {
    var vm = this;
    return {
      menuItems: [
        {
          title: "Change Primary",
          showMode: "SAVE",
          onClick: () => {
            vm.selectSingleOnly = true;
            vm.saveMode = "PRIMARY";
          },
        },
        {
          title: "Delete",
          showMode: "DELETE",
          onClick: () => (vm.selectSingleOnly = false),
        },
      ],

      importDialog: false,

      showChecks: false,
      selectSingleOnly: false,

      primaryContactId: null,
      deleteGroup: [],
    };
  },
  computed: {
    supplierId() {
      return this.$store.getters["SupplierStore/id"];
    },
    contactList() {
      const primary = this.$store.getters["SupplierStore/primary_contact"];
      const allContacts = this.$store.getters["SupplierStore/all_contacts"];
      return [...allContacts].sort((a, b) => {
        if (a.id === primary.id) return -1;
        if (b.id === primary.id) return 1;
        return 0;
      });
    },
  },
  methods: {
    goto(contact_id) {
      this.$router.push({
        name: "suppliers_contacts_edit",
        params: {
          contact_id: contact_id,
        },
      });
    },
    setMode(mode) {
      let fn = {
        DEFAULT: function (vm) {
          vm.showChecks = false;
          vm.deleteGroup = [];
        },
        ADD: function (vm) {
          vm.saveMode = mode;
        },
        DELETE: function (vm) {
          vm.showChecks = true;
        },
        SAVE: function (vm) {
          vm.showChecks = true;
        },
      };

      fn[mode](this);
    },

    selectContact({ select, value }) {
      this.primaryContactId = value;
    },
    toggleContact({ check, value }) {
      // Add to delete list group if checked
      // Remove from list group if not checked
      if (check) {
        this.deleteGroup.push(value);
      } else {
        var index = this.deleteGroup.findIndex((x) => x.id === value);
        this.deleteGroup.splice(index, 1);
      }
    },

    onSave() {
      if (this.saveMode === "PRIMARY") return this.savePrimary();
    },
    savePrimary() {
      // Save
      // This should return a promise as used to delete primary contact as well
      return this.$store
        .dispatch("SupplierContactStore/setPrimaryContact", {
          supplier_id: this.supplierId,
          contact_id: this.primaryContactId,
          old_primary: this.cachedPrimaryContactId,
        })
        .then((v) => {
          // Update supplier store
          this.$store.dispatch("SupplierStore/changePrimary", this.primaryContactId);
          this.$root.$success("Updated primary contact");
        })
        .catch((err) => this.$store.$error(err));
    },

    // IMPORT/COPY PRODUCTS
    copyContactsFromSupplier(supplierSource) {
      this.$root.$loading.open(`Copying products from ${supplierSource.name}`);
      this.$store
        .dispatch("SupplierContactStore/copyContacts", {
          from_supplier_id: supplierSource.id,
          to_supplier_id: this.$route.params.id,
        })
        .then((data) => {
          // Update contact list FIRST
          this.$store.dispatch("SupplierStore/setContactList", data.contactList);
          // Update primary Contact id next
          if (data.primaryId) this.$store.dispatch("SupplierStore/changePrimary", data.primaryId);
          this.primaryContactId = this.$store.getters["SupplierStore/primary_contact"].id;

          this.$root.$loading.end();
        })
        .catch(this.$root.$error);
    },

    // DELETE
    deletePrimary() {
      if (this.deleteGroup.includes(this.primaryContactId)) {
        this.primaryContactId = null;
        return this.savePrimary();
      }

      return new Promise((resolve, rej) => resolve());
    },
    deleteContacts() {
      return this.$store
        .dispatch("SupplierContactStore/deleteContactList", this.deleteGroup)
        .then((v) => {
          this.$store.dispatch("SupplierStore/removeContacts", this.deleteGroup);
          return this.deletePrimary();
        })
        .then((v) => {
          this.deleteGroup = [];
          this.$root.$success("Deleted contacts");
        })
        .catch((err) => this.$store.$error(err));
    },
  },
  mounted() {
    this.primaryContactId = this.$store.getters["SupplierStore/primary_contact"].id;
    this.cachedPrimaryContactId = this.primaryContactId;
  },
};
</script>
