<template>
  <div>
    <v-tooltip v-if="disabled" left>
      <template v-slot:activator="{ on }">
        <v-btn color="grey" v-on="on" icon small>
          <v-icon small>delete</v-icon>
        </v-btn>
      </template>
      <span>{{ disabledText }}</span>
    </v-tooltip>

    <v-dialog v-else v-model="dialog" persistent max-width="350">
      <template v-slot:activator="{ on }">
        <v-hover v-slot:default="{ hover }">
          <v-btn v-if="quiet && !hover" color="grey" icon small>
            <v-icon small>delete</v-icon>
          </v-btn>

          <v-btn v-else color="red lighten-1" v-on="on" small>
            <v-icon left small>delete</v-icon>
            <span v-once>{{ altLabel || "Delete" }}</span>
          </v-btn>
        </v-hover>
      </template>

      <v-card>
        <v-card-title class="subtitle-2">{{ headline }}</v-card-title>
        <v-card-text>{{ text }}</v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn text depressed small @click="dialog = false">Cancel</v-btn>
          <v-btn color="error" depressed small outlined @click="confirmDelete">Yes, Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    headline: String,
    text: String,
    quiet: Boolean,
    altLabel: String,
    ondelete: Function,

    disabled: Boolean,
    disabledText: String,
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    confirmDelete() {
      this.dialog = false;
      this.$root.$loading.open("Deleting...");
      this.ondelete()
        .then((v) => {
          this.$root.$loading.end();
        })
        .catch((err) => {
          this.$root.$loading.end();
          this.$root.$error(err);
        });
    },
  },
};
</script>
