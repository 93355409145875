<template>
  <div>
    <NoEmailAlert />
    <a-card nopad>
      <v-row class="pa-0">
        <v-col class="pa-0">
          <g-button icon="arrow_left" label="Return" @onclick="close" />
        </v-col>

        <v-col class="pa-0 d-flex justify-end" cols="2">
          <EmailSendButton
            :subject="formData.subject"
            :attachments="formData.attachments"
            :compileMailFn="compileMailFn"
            :onFinishFn="close"
          />
        </v-col>
      </v-row>
    </a-card>

    <a-card>
      <SendToForm v-model="mailGroup" :supplierId="supplierId" :clientId="clientId" :isclient="isclient" class="px-2" />

      <EmailForm v-model="formData" />
    </a-card>
  </div>
</template>

<script>
import { EmailTemplateService } from "@/modules/admin.email_template";
import { EmailForm, EmailSendButton, NoEmailAlert, SendToForm, EmailService } from "@/modules/email";
// import { remote } from 'electron'
export default {
  props: {
    value: Object,
    astab: Boolean,
    dataStamp: String,
  },
  components: {
    EmailForm,
    EmailSendButton,
    NoEmailAlert,
    SendToForm,
  },
  data() {
    return {
      clientId: null,
      supplierId: null,
      isclient: false,

      // Form
      formData: {},
      mailGroup: [],
    };
  },
  methods: {
    close() {
      if (this.astab) {
        localStorage.removeItem(this.dataStamp);
        this.$emit("close");
        return;
      }

      // console.log('TO DO')
      // var window = remote.getCurrentWindow();
      //     		window.close();
    },
    compileMailFn() {
      const message = this.formData.message;

      this.mailGroup.forEach((selectedSupplier) => {
        // Replace contact names here
        // Any more, leave to custom compile function
        selectedSupplier.message = EmailTemplateService.replaceContact(selectedSupplier.contacts.nameList, message);
        selectedSupplier.message = EmailTemplateService.replaceSupplier(selectedSupplier.message, selectedSupplier);
      });

      return Promise.resolve(this.mailGroup);
    },
  },
  mounted() {
    const signature = this.$store.getters["AccountStore/signature"];

    this.$store.dispatch("EmailStore/getWindowData", this.astab ? this.dataStamp : this.$route.query.d).then((data) => {
      this.clientId = data.clientId;
      this.supplierId = data.supplierId;
      this.isclient = data.isclient;

      this.formData = {
        subject: "Re: " + data.originalContent.subject,
        message: `
					<br>
					${EmailService.createEmailBreak(data.originalContent)}
					<br>
					${data.originalContent.html || data.originalContent.message}`,
      };
    });
  },
};
</script>
