<template>
  <div>
    <input type="file" id="listupload" class="d-none" @change="importFile" accept=".xlsx" />
    <g-button icon="cloud_download" label="Export List (Excel)" class="ml-2" @onclick="exportRooms()" />
  </div>
</template>

<script>
import writeXlsxFile from "write-excel-file";
import BOOKING_ROOMS from "@/config/BOOKING_ROOMS";
import IMPORT_ROOM_SCHEMA from "@/config/IMPORT_ROOM_SCHEMA";

export default {
  methods: {
    importFile() {
      // Doesn't do anything?
    },
    exportRooms() {
      const data = [];
      const childAdultMap = {
        CHILD: "Child",
        ADULT: "Adult",
      };
      data.push(
        Object.keys(IMPORT_ROOM_SCHEMA).map((key) => {
          return {
            value: key,
            type: String,
          };
        })
      );

      const rooms = this.$store.getters["BookingRoomStore/rooms"];
      rooms.forEach((room, index) => {
        room.nameList.sort((a, b) => {
          const nameA = `${a.first} ${a.last}`.toLowerCase();
          const nameB = `${b.first} ${b.last}`.toLowerCase();

          return nameA.localeCompare(nameB);
        });
        room.nameList.forEach((name, nameIndex) => {
          data.push([
            // room type
            {
              type: String,
              value: BOOKING_ROOMS.filter((roomType) => roomType.value == room.type)[0].print,
            },
            // room number
            {
              type: Number,
              value: index + 1,
            },
            // first name
            {
              type: String,
              value: name.first,
            },
            // last name
            {
              type: String,
              value: name.last,
            },
            // adult / child
            {
              type: String,
              value: childAdultMap[name.about && name.about.type],
            },
            // age of child
            {
              type: Number,
              value: name.about && name.about.age,
            },
            // requests
            {
              type: String,
              value: nameIndex == 0 ? room.meta && room.meta.request : null,
            },
          ]);
        });
      });
      writeXlsxFile(data, {
        fileName: "rooming-list.xlsx",
      });
    },
  },
};
</script>
