import PLACES from "@/config/fields/PLACES";
import CLIENT_EXTRA from "@/config/fields/client/extra";

export default {
  name: {
    label: "Name",
    inputType: "TEXT",
  },
  country: {
    label: "Country",
    inputType: "DROPDOWN",
    source: PLACES.COUNTRY_LIST.WORLD,
  },

  // LOCATION ADDRESS
  subjur: {
    label: "State/Pref/Prov",
    dependant: true,
    dependantText: "Please select country",
    getLabel: function (env, country) {
      if (country === "US") return "State";
      if (country === "JP") return "Prefecture";
      return "Province";
    },
    inputType: "DROPDOWN",
    noSource: " ",
    getSource: function (env, country) {
      return PLACES.SUBJUR[country] || PLACES.SUBJUR.ANY(country);
    },
  },
  city: {
    label: "City",
    inputType: "TEXT",
  },
  zip: {
    label: "Zip",
    inputType: "TEXT",
  },
  address: {
    label: "Address",
    inputType: "TEXTAREA",
  },

  // CATEGORY
  category: {
    label: "Category",
    inputType: "DROPDOWN",
    source: CLIENT_EXTRA.category,
  },

  type: {
    label: "Type",
    inputType: "DROPDOWN",
    source: CLIENT_EXTRA.type,
  },
  depositPercentage: {
    cols: 4,
    label: "Deposit Percentage",
    inputType: "NUMBER",
    sideLabel: " %",
  },
};
