<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)" persistent fullscreen>
    <v-card class="supplier-select-wrapper">
      <v-card-title>
        <DialogHeader float :type="editType" :name="supplierInfo.name" :supplierId="supplierInfo.id" />
      </v-card-title>

      <v-card-text class="dialog-content">
        <AlternateSupplierView
          :module="editType"
          :parentBookingSupplierId="supplierInfo.id"
          :dayIndex="dayIndex"
          :bookingDayYear="bookingDayYear"
          :nestedBookingSupplierIds="nestedBookingSupplierIds"
          @addnest="addnest"
          @popnest="popnest"
          @post-swap="$emit('input', false)"
          @close="$emit('input', false)"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogHeader from "./_DialogHeader";
import AlternateSupplierView from "./step4_alternate/AlternateSupplierView";
export default {
  props: {
    value: Boolean,
    supplierInfo: Object,
    editType: String,

    dayIndex: Number,
    lastDay: [String, Date],
    bookingDayYear: String,
  },
  components: {
    DialogHeader,
    AlternateSupplierView,
  },
  data() {
    return {
      nestedBookingSupplierIds: [],
    };
  },
  methods: {
    // ----------------
    // CUSTOM PRICE STUFF
    // ----------------
    addnest(bsid) {
      this.nestedBookingSupplierIds.push(bsid);
    },
    popnest(bsid) {
      this.nestedBookingSupplierIds = this.nestedBookingSupplierIds.filter((item) => item !== bsid);
    },
  },
};
</script>
