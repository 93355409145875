<template>
  <v-toolbar id="supheader" dense flat>
    <v-toolbar-title>
      <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
    </v-toolbar-title>

    <div class="flex-grow-1"></div>

    <template v-slot:extension>
      <ProductTabs />
    </template>
  </v-toolbar>
</template>

<script>
import ProductTabs from "./_ProductTabs";

export default {
  components: {
    ProductTabs,
  },
  props: {
    supplierId: String,
    supplierType: String,
    supplierName: String,

    productId: String,
    productName: String,
    productType: String,
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: "Database",
          to: { name: "database" },
        },
        {
          text: `Supplier (${this.supplierType}): ${this.supplierName}`,
          to: {
            name: "suppliers_products",
            params: { id: this.supplierId },
          },
        },
        {
          text: this.productType,
        },
        {
          text: this.productName,
        },
      ];
    },
  },
};
</script>
