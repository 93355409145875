import Suppliers from "./Suppliers";
import InformationView from "./InformationView";

import PicturesView from "./PicturesView";

import contacts from "./contacts/_routes";
import documents from "./documents/_routes";
import products from "./products/_routes";
import history from "./history/_routes";

export default [
  {
    path: "/suppliers/:id",
    component: Suppliers,
    children: [
      {
        path: "",
        name: "suppliers_info",
        component: InformationView,
        meta: {
          toolbar: "supplier",
          showProductYear: true,
          tabAccess: "DATABASE",
        },
      },
      {
        path: "pictures",
        name: "suppliers_pictures",
        component: PicturesView,
        meta: {
          toolbar: "supplier",
          showProductYear: true,
          tabAccess: "DATABASE",
        },
      },

      ...contacts,
      ...documents,
      ...products,
      ...history,
    ],
  },
];
