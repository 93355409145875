import { render, staticRenderFns } from "./_Billed.vue?vue&type=template&id=2486b9d4"
import script from "./_Billed.vue?vue&type=script&lang=js"
export * from "./_Billed.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports