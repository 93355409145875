<template>
  <v-toolbar dense flat>
    <v-toolbar-title>
      <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
    </v-toolbar-title>
  </v-toolbar>
</template>

<script>
export default {
  computed: {
    active() {
      return this.$store.getters["BookingStore/active"];
    },
    bookingId() {
      return this.active.id;
    },
    clientName() {
      return this.active.client_name;
    },
    clientId() {
      return this.active.client_id;
    },
    groupName() {
      return this.active.group_name;
    },

    breadcrumbs() {
      return [
        {
          text: "Bookings",
          to: { name: "bookings" },
        },
        {
          text: this.clientName,
          to: { name: "client_info", params: { client_id: this.clientId } },
        },
        {
          text: this.groupName + " Booking",
          to: { name: "bookings_days", params: { booking_id: this.bookingId } },
        },
        {
          text: "Email Suppliers",
        },
      ];
    },
  },
};
</script>
