<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)" persistent fullscreen>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <g-button label="Back" icon="keyboard_arrow_left" @onclick="$emit('input', false)" />

            <div class="my-5">
              <div class="black--text title">
                <span>Cancelling:</span>
              </div>
              <div class="black--text title grey lighten-2 px-2">
                >
                {{ supplierName }}
              </div>
              <v-alert dense text color="warning" class="caption mt-3">
                <v-icon x-small left color="warning">priority_high</v-icon>
                <span>Supplier has been contacted. A cancellation email is recommended.</span>
              </v-alert>
              <g-button type="black" label="Cancel without email" icon="clear" @onclick="postSend" />
            </div>
          </v-col>
          <v-col>
            <DialogEmailForm
              :bookingSupplierId="bookingSupplierId"
              :focusBookingSupplier="focusBookingSupplier"
              :supplierId="supplierId"
              :supplierName="supplierName"
              :booking="booking"
              :bookingSuppliers="bookingSuppliers"
              :flattenDays="flattenDays"
              :dayinfo="dayinfo"
              :roomBreakdownData="roomBreakdownData"
              :limitTemplate="limitTemplate"
              :mailConfig="mailConfig"
              :postSend="postSend"
              @onfinish="$emit('input', false)"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import format from "@/services/format";
import DialogEmailForm from "../_dialogEmailForm";
export default {
  props: {
    value: Boolean,

    // Booking Supplier (focused)
    bookingSupplierId: {
      required: true,
      type: String,
    },
    focusBookingSupplier: Object,

    // Supplier
    supplierId: {
      required: true,
      type: String,
    },
    supplierName: String,

    // Booking stuff
    booking: Object,
    bookingSuppliers: Object,
    flattenDays: Array,
    dayinfo: Array,
    roomBreakdownData: Object,

    mailConfig: Object,
    postSend: Function,
  },
  components: {
    DialogEmailForm,
  },
  data() {
    return {
      limitTemplate: -1,

      emailContent: {
        attachments: [],
      },
    };
  },
};
</script>
