<template>
  <div>
    <elwrapper label="Supplier">
      <v-text-field
        :value="displayName"
        outlined
        hide-details
        dense
        readonly
        :disabled="disabled"
        @click="dialog = true"
      ></v-text-field>
    </elwrapper>

    <SupplierSelectDialog v-model="dialog" @update="(v) => $emit('input', v)" />
  </div>
</template>

<script>
import SupplierSelectDialog from "./_SupplierSelectDialog";
export default {
  components: {
    SupplierSelectDialog,
  },
  props: {
    value: Object,
    disabled: Boolean,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    displayName() {
      if (!this.value || !this.value.pid) return "";
      return `${this.value.name} (${this.value.supplier_type})`;
    },
  },
};
</script>
