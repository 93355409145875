<template>
  <v-form>
    <a-text-field label="Subject" :value="value.subject" @keydown="jumpToEditor" @input="(v) => update('subject', v)" />

    <elwrapper label="Message" texttop>
      <RichTextEditor ref="editor" maxheight="300px" :value="value.message" @input="(v) => update('message', v)" />
    </elwrapper>

    <SignatureDisplayBlock />

    <Attachments :value="value.attachments" @input="(v) => update('attachments', v)" :buttons="buttons" />
  </v-form>
</template>

<script>
import Vue from "vue";
import { SignatureDisplayBlock } from "@/modules/account";
import { EmailTemplateService } from "@/modules/admin.email_template";
import { EmailService } from "@/modules/email";
import Attachments from "./_Attachments";
import api from "../api";
export default {
  name: "EmailForm",
  props: {
    value: Object,
    buttons: Array,
  },
  components: {
    SignatureDisplayBlock,
    Attachments,
  },
  methods: {
    update(key, v) {
      Vue.set(this.value, key, v);
      this.$emit("input", { ...this.value });
    },
    jumpToEditor(e) {
      if (e.key.toLowerCase() === "tab") {
        const el = this.$el.querySelector(".tiptap-vuetify-editor__content");
        el.focus();
      }
    },
  },
};
</script>
