<template>
  <div class="database-table a-card">
    <virtual-scroll-wrapper
      :headers="headers"
      :list="rows"
      emptyMessage="No Clients Found"
      :count="count"
      :colspan="colspan"
      :loading="loading"
      :height="height"
      :selectOnly="selectOnly"
    >
      <template v-slot:default="{ item, colwidth }">
        <tr
          class="database-row color-alt canclick"
          :class="{ 'primary--text grey lighten-2': item.id === selected.id }"
          style="display: flex"
          @click="onclick(item)"
        >
          <div v-if="selectOnly" v-col-adjwidth="0" style="display: inline-flex; align-items: center">
            <input type="checkbox" :checked="item.id === selected.id" />
          </div>
          <td class="caption" v-col-adjwidth="'Client Name'" style="display: inline-flex; align-items: center">
            <a-truncate :text="item.name" />
          </td>
          <td class="caption" v-col-adjwidth="'Country'" style="display: inline-flex; align-items: center">
            <a-truncate :text="item.country" />
          </td>
        </tr>
      </template>
    </virtual-scroll-wrapper>
  </div>
</template>

<script>
export default {
  props: {
    query: Object,
    selectOnly: Boolean,
    height: String,
  },
  components: {},
  data() {
    return {
      headers: [
        {
          text: "Client Name",
          value: "name",
        },
        {
          text: "Country",
          value: "country",
        },
      ],
      selected: {},
      rows: [],
      loading: true,

      page: 0,
      queryActive: false, // Avoid dbSource + query change clash

      count: 0,
    };
  },
  watch: {
    dbSource(v) {
      this.launchQueryLoop(this.query);
    },
    query(searchQuery) {
      this.launchQueryLoop(searchQuery);
    },
  },
  computed: {
    colspan() {
      return this.selectOnly ? 3 : 2;
    },
  },
  methods: {
    onclick(item) {
      if (this.selectOnly) {
        this.selected = item;
        this.$emit("select", this.selected);
        return;
      }
      this.goToPage(item);
    },
    goToPage(item) {
      this.$router.push({
        name: "client_info",
        params: { client_id: item.id },
      });
    },
    queryDatabase(searchQuery) {
      if (this.queryActive) return;

      this.loading = true;
      this.queryActive = true;

      this.$store
        .dispatch("ClientStore/getClientList", {
          query: { ...searchQuery },
          page: this.page,
        })
        .then((rows) => {
          this.queryActive = false;
          this.loading = false;

          this.rows = rows;
          this.count = rows.length;
        })
        .catch((err) => {
          this.loading = false;
          this.$root.$error(err);
        });
    },

    // DISPLAY DATA
    launchQueryLoop() {
      if (this.queryActive) return;

      this.queryActive = true;
      this.rows = [];
      this.page = 0;
      this.selected = {};
      this.loading = true;

      // Get total (doesn't need to be in sync)
      this.getDataCount(this.query);

      // Launch looping promise
      this._queryLoop(this.query)
        .then((data) => {
          this.queryActive = false;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$root.$error(err);
        });
    },
    _queryLoop(searchQuery) {
      return new Promise((resolve, reject) => {
        this._queryDatabase(this.query)
          .then((data) => {
            if (!data.rows.length) return resolve();

            this.rows.push(...data.rows);

            if (!data.continue) return resolve();

            this.page += 1;
            return this._queryLoop(this.query).then(resolve);
          })
          .catch((err) => reject(err));
      });
    },
    _queryDatabase(searchQuery) {
      return this.$store
        .dispatch("ClientStore/getClientList", {
          query: { ...searchQuery },
          page: this.page,
        })
        .then((rows) => {
          if (!rows.length) return { rows: [], continue: false };

          // MAX 50 query at a time
          // Less than 50, then don't need to query again
          return { rows, continue: rows.length === 50 };
        })
        .catch((err) => {
          this.$root.$error(err);
        });
    },

    // GET FULL SUPPLIER COUNT
    getDataCount(searchQuery) {
      this.$store
        .dispatch("ClientStore/getClientCount", {
          type: this.dbSource,
          query: searchQuery,
          page: this.page,
        })
        .then((count) => {
          this.count = count;
        })
        .catch((err) => {
          this.$root.$error(err);
        });
    },
  },
  mounted() {
    this.launchQueryLoop(this.query);
  },
};
</script>
