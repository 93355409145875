import axios from "@/plugins/axios";

export default {
  getEmailArchives(supplierId, extraQuery) {
    return axios.get(`/email_archives/${supplierId}`, {
      params: { query: extraQuery },
    });
  },

  getHistory(supplier_id) {
    return axios.get(`/suppliers/${supplier_id}/history`);
  },
  addNote(supplier_id, noteInfo) {
    return axios.post(`/suppliers/${supplier_id}/history`, { note: noteInfo });
  },

  getNote(note_id) {
    return axios.get(`/supplier_history/${note_id}`);
  },
  updateNote(note_id, noteData) {
    return axios.post(`/supplier_history/${note_id}`, { ...noteData });
  },
  deleteNote(note_id) {
    return axios.delete(`/supplier_history/${note_id}`);
  },
};
