import Vue from "vue";
import api from "./api";

const FETCHING = "FETCHING";
const UPDATE_SEARCH = "UPDATE_SEARCH";
const APPLY_SEARCH = "APPLY_SEARCH";
const APPLY_SEASON_RANGE = "APPLY_SEASON_RANGE";
const RESET_SEARCH = "RESET_SEARCH";
const SET_SEARCH_SOURCES = "SET_SEARCH_SOURCES";

const namespaced = true;

const defaultGeneral = () => {
  return [
    {
      tid: "a" + new Date().getTime(),
      value: 1,
      columns: "Active",
    },
    {
      tid: "b" + new Date().getTime(),
      type: "AND",
      value: "",
      columns: "Name",
    },
  ];
};

const state = {
  fetching: false,

  searchRecord: {
    client: {}, // Object
    location: {}, // Object
    productLocation: {},
    supplier: "", // String
    product: {}, // Object
    general: {
      // All => List
      suppliers: defaultGeneral(),
      products: defaultGeneral(),
      clients: defaultGeneral(),
    },
  },

  appliedSearch: {
    // dbconfig =>
    // tab: Which tab is "active"
    // data: search blob
    suppliers: {},
    products: {},
    clients: {},
  },

  productPriceDate: {},
  searchSources: {},
};

const mutations = {
  FETCHING(state, value) {
    state.fetching = value;
  },
  UPDATE_SEARCH(state, { tab, dbconfig, data }) {
    if (tab === "general") {
      Vue.set(state.searchRecord.general, dbconfig, data);
      return;
    }
    if (tab === "supplier") {
      Vue.set(state.searchRecord, tab, data);
      return;
    }
    Vue.set(state.searchRecord, tab, { ...data });
  },

  APPLY_SEARCH(state, { tab, dbconfig, data }) {
    Vue.set(state.appliedSearch, dbconfig, { tab, data });
  },
  APPLY_SEASON_RANGE(state, config) {
    // Replace object completely to trigger update
    Vue.set(state, "productPriceDate", { ...config });
  },

  RESET_SEARCH(state) {
    state.searchRecord = {
      client: {}, // Object
      location: {}, // Object
      productLocation: {},
      supplier: "", // String
      product: {}, // Object
      general: {
        // All => List
        suppliers: defaultGeneral(),
        products: defaultGeneral(),
        clients: defaultGeneral(),
      },
    };

    state.appliedSearch = {
      // dbconfig =>
      // tab: Which tab is "active"
      // data: search blob
      suppliers: {},
      products: {},
      clients: {},
    };
  },

  SET_SEARCH_SOURCES(state, data) {
    state.searchSources = data;
  },
};

const actions = {
  updateSearch({ commit }, { tab, dbconfig, data }) {
    commit(UPDATE_SEARCH, { tab, dbconfig, data });
  },
  applySearch({ commit }, { tab, dbconfig, data }) {
    commit(APPLY_SEARCH, { tab, dbconfig, data });
  },
  applySeasonPriceRange({ commit }, config) {
    commit(APPLY_SEASON_RANGE, config);
  },
  resetSearch({ commit }) {
    commit(RESET_SEARCH);
  },

  querySuppliers({ commit }, { type, query, page, ignoreInfinite, attachContact }) {
    commit(FETCHING, true);
    return api
      .querySuppliers(type, { query, page, ignoreInfinite, attachContact })
      .then((data) => {
        commit(FETCHING, false);
        return data.data;
      })
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  getSupplierCount({ commit }, { type, query, page }) {
    commit(FETCHING, true);
    return api
      .countSupplier(type, { query, page })
      .then((data) => {
        commit(FETCHING, false);
        return Object.values(data.data)[0];
      })
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  getSupplierNames({ commit }, { type, query }) {
    commit(FETCHING, true);
    return api
      .getSupplierNamesOnly(type, { query, page: 0 })
      .then((data) => {
        commit(FETCHING, false);
        return data.data;
      })
      .catch((err) => {
        if (err) throw err.data;
      });
  },

  queryProducts({ commit }, { type, query, page }) {
    commit(FETCHING, true);
    return api
      .queryProducts(type, { query, page })
      .then((data) => {
        commit(FETCHING, false);
        return data.data;
      })
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  getProductCount({ commit }, { type, query, page }) {
    commit(FETCHING, true);
    return api
      .countProducts(type, { query, page })
      .then((data) => {
        commit(FETCHING, false);
        return Object.values(data.data)[0];
      })
      .catch((err) => {
        if (err) throw err.data;
      });
  },

  getClientNames({ commit }, { query }) {
    commit(FETCHING, true);
    return api
      .getClientNamesOnly({ query, page: 0 })
      .then((data) => {
        commit(FETCHING, false);
        return data.data;
      })
      .catch((err) => {
        if (err) throw err.data;
      });
  },

  createSupplier({ commit }, { type, supplier, primary_contact }) {
    return api
      .createSupplier(type, supplier, primary_contact)
      .then((data) => data.data) // This should be created Supplier's id
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  createProduct({ commit }, { supplier_id, supplier_pid, product }) {
    return api
      .createProduct(supplier_id, supplier_pid, product)
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
};

const getters = {
  // Search Tab data
  clientSearch: (state) => state.searchRecord.client,
  locationSearch: (state) => state.searchRecord.location,
  productLocationSearch: (state) => state.searchRecord.productLocation,
  productSearch: (state) => state.searchRecord.product,
  supplierSearch: (state) => state.searchRecord.supplier,
  generalSearch: (state) => state.searchRecord.general,

  // Applied Search stuff
  activeTab: (state) => (db) => state.appliedSearch[db],
  productPriceDate: (state) => state.productPriceDate || {},
};

export default {
  namespaced,
  state,
  mutations,
  getters,
  actions,
};
