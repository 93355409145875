<template>
  <div class="database-table a-card">
    <virtual-scroll-wrapper
      :headers="headers"
      :list="rows"
      emptyMessage="No Products Found"
      :count="count"
      :colspan="headers.length + (selectOnly ? 1 : 0)"
      :customSort="customSort"
      :loading="loading"
      :height="height"
      :selectOnly="selectOnly"
      :allowRefresh="allowRefresh"
      @refresh="refresh"
    >
      <template v-slot:default="{ item, colwidth }">
        <tr
          class="database-row color-alt canclick"
          :class="{
            'primary--text grey lighten-2': selected ? selected[item.id] : false,
            'grey lighten-3 grey--text disable-click': disableRow(item),
          }"
          @click="onclick(item)"
        >
          <td v-if="selectOnly && selected">
            <input type="checkbox" :checked="selected[item.id]" />
          </td>
          <td v-col-adjwidth="'Product Name'">
            <ProductNameColumn
              style="max-width: 300px"
              :product="item"
              :notes="item.notes"
              :supplierMeta="item.supplier_meta"
            />
          </td>
          <td class="overline" style="width: 70px; min-width: 70px; max-width: 70px">
            {{ getGroupFit(item.group_fit) }}
          </td>
          <td class="caption" v-col-adjwidth="'Supplier'">
            <a-truncate :text="getSupplierName(item)" />
          </td>
          <td class="caption" v-col-adjwidth="'City'">
            <a-truncate :text="getCity(item)" />
          </td>
          <td v-col-adjwidth="4">
            <span class="overline">{{ getShortRateKind(item.rate_history) }}</span>
          </td>
          <td v-col-adjwidth="'Price'">
            <ProductPrice :product="item" :env="env" :focusYear="focusYear" />
          </td>
          <td v-if="dbSource === 'MEAL'" class="caption" style="max-width: 100px">
            <a-truncate :text="item.supplier_meta ? item.supplier_meta.style : ''" />
          </td>
        </tr>
      </template>
    </virtual-scroll-wrapper>
  </div>
</template>

<script>
import Vue from "vue";

import ProductNameColumn from "./subcontent/_ProductNameColumn";
import ProductPrice from "./subcontent/_ProductPrice";
import { ProductService } from "@/modules/products";

export default {
  props: {
    dbSource: String,
    query: Object,
    height: String,
    selectOnly: Boolean,
    selectInto: Boolean,
    useOrderSort: Boolean,
    allowRefresh: Boolean,

    focusYear: String,

    selected: [Array, Object],
    restrictTo: String,
    disableFn: Function,
    postFetchFn: Function,
  },
  components: {
    ProductNameColumn,
    ProductPrice,
  },
  data() {
    return {
      rows: [],
      loading: true,

      page: 0,
      queryActive: false, // Avoid dbSource + query change clash
      killswitch: false, // If user changes query will fetch is running

      count: 0,
    };
  },
  watch: {
    dbSource(v) {
      this.launchQueryLoop(this.query);
    },
    query(searchQuery) {
      this.launchQueryLoop(searchQuery);
    },
    focusYear(v) {
      this.updateProductRates();
    },
    appliedSeasonRate() {
      this.updateProductRates();
    },
  },
  computed: {
    appliedSeasonRate() {
      return this.$store.getters["DatabaseStore/productPriceDate"];
    },
    showOccupancy() {
      return true;
    },
    env() {
      return this.$store.getters["AccountStore/environment"];
    },
    headers() {
      let base = [
        {
          text: "Product Name",
          value: "name",
          width: 300,
        },
        {
          text: "",
          value: "group_fit",
          width: 70,
        },
        {
          text: "Supplier",
          value: "supplier_name",
          width: 200,
        },
        {
          text: "City",
          value: "city",
          width: 100,
        },
        {
          text: "",
          width: 50,
          value: "meta.rate_kind",
        },
        {
          text: "Price",
          value: "sort_rate",
        },
      ];

      if (this.dbSource === "MEAL") {
        base.push({
          text: "Style",
          value: "supplier_meta.style",
        });
      }

      return base;
    },
  },
  methods: {
    refresh() {
      this.launchQueryLoop(this.query);
    },
    disableRow(product) {
      if (!this.disableFn) return false;
      return this.disableFn(product);
    },

    customSort(items, sortBy, isDesc) {
      items.sort((a, b) => {
        let aval = a[sortBy],
          bval = b[sortBy];
        if (sortBy[0] === "supplier_meta.style") {
          aval = a.supplier_meta ? a.supplier_meta.style : "";
          bval = b.supplier_meta ? b.supplier_meta.style : "";

          if (!isDesc[0]) {
            return this.compareStyle(aval, bval);
          } else {
            return this.compareStyle(bval, aval);
          }
        } else {
          if (!isDesc[0]) {
            return aval < bval ? -1 : 1;
          } else {
            return bval < aval ? -1 : 1;
          }
        }
      });
      return items;
    },
    compareStyle(aval, bval) {
      if (!aval) return -1;
      if (!bval) return 1;
      return aval < bval ? -1 : 1;
    },

    onclick(item) {
      if (this.selectInto) {
        this.$emit("gointo", item);
        return;
      }

      if (this.selectOnly) {
        if (this.selected[item.id]) {
          // UNCHECK
          this.$emit("select", { remove: item.id });
        } else {
          // CHECK
          this.$emit("select", { add: item });
        }
        return;
      }
      this.goToPage(item);
    },

    getNotes(text) {
      return text.replace(/\n/gm, "<br/>");
    },
    getCity(item) {
      if (item.subcity) return `${item.city} (${item.subcity})`;
      return item.city;
    },
    getSupplierName(product) {
      if (product.supplier_meta && product.supplier_meta.en_name) {
        return `${product.supplier_meta.en_name} (${product.supplier_name})`;
      }

      return product.supplier_name;
    },

    getGroupFit(gf) {
      if (gf === "GROUP_FIT" || gf === "'GROUP_FIT'") return "GR/FIT";
      return gf;
    },

    getShortRateKind(rate_history) {
      const found = rate_history.find((item) => item.rate_year === this.focusYear);
      const rateKind = (found && found.meta && found.meta.rate_kind) || "";
      if (rateKind === "CONTRACT") return "CON";
      if (rateKind === "GENERIC") return "GEN";
      return "";
    },

    goToPage(item) {
      this.$router.push({
        name: "product_page",
        params: { id: item.id },
      });
    },

    // DISPLAY DATA
    launchQueryLoop() {
      if (!this.dbSource) return Promise.resolve();
      if (this.queryActive) {
        this.killswitch = true;
        return Promise.resolve();
      }

      this.queryActive = true;
      this.rows = [];
      this.page = 0;
      this.loading = true;

      // Get total (doesn't need to be in sync)
      this.getDataCount(this.query);

      // Launch looping promise
      //return this._queryLoop(this.query)
      return this._tempQuery(this.query)
        .then((data) => {
          if (data === 1) return; // Interrupted
          this.queryActive = false;
          this.loading = false;

          this.updateProductRates();

          if (this.postFetchFn) this.postFetchFn(this.rows);
        })
        .catch((err) => {
          this.loading = false;
          this.$root.$error(err);
        });
    },
    _tempQuery(searchQuery) {
      return this.$store
        .dispatch("DatabaseStore/queryProducts", {
          type: this.dbSource,
          query: searchQuery,
          page: this.page,
        })
        .then((rows) => {
          this.rows = rows || [];
        });
    },
    _queryLoop(searchQuery) {
      return new Promise((resolve, reject) => {
        if (this.killswitch) {
          this.killswitch = false;
          this.queryActive = false;
          this.launchQueryLoop();
          return resolve(1);
        }

        this._queryDatabase(this.query)
          .then((data) => {
            if (!data.rows.length) return resolve();

            this.rows.push(...data.rows);

            if (!data.continue) return resolve();

            this.page += 1;
            return this._queryLoop(this.query).then(resolve);
          })
          .catch((err) => reject(err));
      });
    },
    _queryDatabase(searchQuery) {
      return this.$store
        .dispatch("DatabaseStore/queryProducts", {
          type: this.dbSource,
          query: searchQuery,
          page: this.page,
        })
        .then((rows) => {
          if (!rows.length) return { rows: [], continue: false };

          // MAX 50 query at a time
          // Less than 50, then don't need to query again
          return { rows, continue: rows.length === 50 };
        })
        .catch((err) => {
          this.$root.$error(err);
        });
    },

    // GET FULL SUPPLIER COUNT
    getDataCount(searchQuery) {
      this.$store
        .dispatch("DatabaseStore/getProductCount", {
          type: this.dbSource,
          query: searchQuery,
          page: this.page,
        })
        .then((count) => {
          this.count = count;
        })
        .catch((err) => {
          this.$root.$error(err);
        });
    },

    updateProductRates() {
      // MODIFIER
      // Extracts rate based on focus year
      let focusRate, seasonalBatch, rate;
      this.rows.forEach((product) => {
        focusRate = product.rate_history.find((p) => p.rate_year === this.focusYear) || product.rate_history[0] || {};
        // For sorting purposes, only FIRST seasonal rate is used
        if (focusRate.seasonal) {
          seasonalBatch = ProductService.getSeasonalRange(focusRate.rate || [], this.appliedSeasonRate);
          rate = seasonalBatch && seasonalBatch.length ? seasonalBatch[0].rate : {};
        } else {
          rate = focusRate.rate;
        }

        if (!rate) {
          product.sort_rate = 0;
          return;
        }

        let meta = product.supplier_meta || {};
        let country = product.country;
        let calc = ProductService.fullCalc(
          {
            rate: rate,
            product_type: product.product_type,
            tax_inclusive: focusRate.tax_inclusive,
            meta: focusRate.meta,
          },
          meta,
          country,
          {}
        );

        product.sort_rate = calc.raw_value;
      });
    },
  },
  mounted() {
    this.launchQueryLoop(this.query).then(() => {
      // If sorting products by their order
      if (this.useOrderSort) {
        this.rows = this.rows.sort((first, second) => {
          return first.order - second.order;
        });
      }
    });
  },
};
</script>

<style lang="scss">
.loading-block {
  position: absolute;
  right: 30px;
  padding: 5px;
  z-index: 100;
}
</style>
