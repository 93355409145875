<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)" width="400" persistent>
    <v-card>
      <v-card-title> Archive? </v-card-title>
      <v-card-text class="black--text"> </v-card-text>

      <v-card-actions>
        <g-button @onclick="$emit('input', false)" label="Cancel" />
        <div class="flex-grow-1"></div>
        <g-button type="primary" @onclick="archive" label="Yes, Archive" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import format from "@/services/format";
export default {
  props: {
    value: Boolean,
    bookingId: String,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {},
  methods: {
    archive() {
      this.loading = true;
      this.$store
        .dispatch("FinanceReportStore/toggleArchiveReport", {
          booking_id: this.bookingId,
          archive: true,
        })
        .then((_) => {
          this.loading = false;
          this.$emit("input", false);
        })
        .catch((err) => {
          this.$root.$error("Failed to archive report");
        });
    },
  },
};
</script>
