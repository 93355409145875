<template>
  <v-col>
    <DocumentsHeader
      :uploadfn="addedDocument"
      @clear="clearHeader"
      @toggleDelete="showChecks = !showChecks"
      :confirmDelete="deleteDocuments"
    />

    <CheckboxWrapper
      v-for="(doc, i) in documentList"
      :key="doc.id"
      :value="doc.id"
      :show-check="showChecks"
      @toggle="selectFileDelete"
    >
      <a class="docdownload" :href="getLink(doc)" @click="(e) => openPdf(e, doc)">
        <a-card nopad class="d-flex hover justify-space-between">
          <div class="d-flex align-center">
            <span class="subtitle-2 pa-3">{{ doc.display_name || doc.name }}</span>
            <v-btn class="editbtn" icon small @click="(e) => editDocument(e, doc)">
              <v-icon small>edit</v-icon>
            </v-btn>
          </div>
          <div class="caption pa-3">
            <span>{{ doc.user_name }}</span>
            <span class="mx-2">|</span>
            <span>{{ formatDate(doc.uploaded_date) }}</span>
          </div>
        </a-card>
      </a>
    </CheckboxWrapper>

    <edit-document-dialog v-model="editDialog" :editData="editFocus" @update="updateDocument" />
    <d-no-display v-if="documentList.length === 0" label="(No documents to display)" />
  </v-col>
</template>

<script>
import dateFormat from "@/services/format";
import { DocumentsHeader } from "@/modules/media.documents";

export default {
  components: {
    DocumentsHeader,
  },
  data() {
    return {
      showChecks: false,
      checkedItems: [],
      documentList: [],

      // Edit Dialog
      editDialog: false,
      editFocus: {},
    };
  },
  computed: {
    bookingId() {
      return this.$store.getters["FinanceReportStore/booking_id"];
    },
    hostName() {
      return this.$store.getters["host"];
    },
  },
  methods: {
    formatDate(date) {
      return date ? dateFormat.formatDate(dateFormat.convertDate(date)) : "(unknown)";
    },
    // DELETE STUFF
    clearHeader() {
      this.showChecks = false;
      this.checkedItems = [];
    },
    selectFileDelete({ check, value }) {
      // Add to delete list group if checked
      // Remove from list group if not checked
      if (check) {
        this.checkedItems.push(value);
      } else {
        var index = this.checkedItems.findIndex((x) => x.id === value);
        this.checkedItems.splice(index, 1);
      }
    },
    deleteDocuments() {
      return this.$store
        .dispatch("FinanceDocumentStore/deleteDocumentList", this.checkedItems)
        .then((v) => {
          this.$root.$success("Deleted documents");
          var vm = this;
          this.documentList = this.documentList.filter(function (item) {
            return vm.checkedItems.indexOf(item.id) === -1;
          });
          this.clearHeader();
        })
        .catch((err) => this.$root.$error(err));
    },

    // ADD
    addedDocument(docList) {
      return Promise.all(
        docList.map((doc) => {
          return this.uploadDocument(doc);
        })
      )
        .then((docBulk) => {
          this.$root.$success(`Uploaded documents`);
          docBulk.forEach((doc) => {
            doc.user_name = this.$store.getters["AccountStore/name"];
            this.documentList.push(doc);
          });
        })
        .catch((err) => this.$root.$error(err));
    },
    uploadDocument(doc) {
      return this.$store
        .dispatch("FinanceDocumentStore/addDocument", {
          origin_id: this.bookingId,
          name: doc.name,
          file: doc.upload,
        })
        .catch((err) => this.$root.$error(err));
    },

    // VIEW
    getLink(doc) {
      return this.$store.getters["host"] + doc.src;
    },
    openPdf(event, doc) {
      if (doc.mimetype.includes("pdf")) {
        event.preventDefault();
        this.$store.dispatch("openPdf", { ...doc, link: this.getLink(doc) });
      }
    },

    // EDIT
    editDocument(event, doc) {
      event.preventDefault();
      event.stopPropagation();

      this.editDialog = true;
      this.editFocus = doc;
    },
    updateDocument(data) {
      let source = this.documentList.find((item) => item.id === data.id);
      this.editDialog = false;
      this.$root.$loading.open("Updating image");
      this.$store
        .dispatch("FinanceDocumentStore/updateDocument", {
          ...data,
          dependent_id: this.bookingId,
          old_name: source.display_name,
        })
        .then((v) => {
          source.display_name = data.display_name;
          this.$root.$loading.end();
        })
        .catch((err) => this.$root.$error(err));
    },
  },
  mounted() {
    this.$store
      .dispatch("FinanceDocumentStore/getDocumentList", this.bookingId)
      .then((list) => {
        this.documentList = list;
      })
      .catch((err) => this.$root.$error);
  },
};
</script>

<style lang="scss">
.docdownload {
  .editbtn {
    display: none;
  }

  &:hover {
    .editbtn {
      display: inline-block;
    }
  }
}
</style>
