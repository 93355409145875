<template>
  <div>
    <QuoteDayInfoBlock v-for="dindex in dayCount" :key="dindex" :dayNum="dindex" :dayCount="dayCount.length" />
  </div>
</template>

<script>
import { SuppliersMenu } from "@/config/menus";
import { QuoteDayInfoBlock } from "@/modules/quotes.days";

export default {
  components: {
    QuoteDayInfoBlock,
  },
  computed: {
    dayCount() {
      var count = this.$store.getters["QuoteDayStore/dayCount"];
      return Array.from(Array(count).keys());
    },
  },
};
</script>
