<template>
  <v-row>
    <v-col class="d-flex align-center subtitle-1">
      <div class="d-flex align-center">
        <span class="title" align-self="center">Suppliers</span>
        <span style="max-width: 40px" align-self="center">
          <v-icon left>keyboard_arrow_right</v-icon>
        </span>
        <span align-self="center"> {{ type }} </span>

        <span style="max-width: 40px" v-if="mode !== 'NO_SELECT'">
          <v-icon left>keyboard_arrow_right</v-icon>
        </span>

        <span style="max-width: 200px" v-if="mode !== 'NO_SELECT'" class="text-truncate">
          <router-link :to="goRoute" target="_blank">{{ name }}</router-link>
        </span>

        <g-button
          v-if="edit"
          :disabled="contacted"
          label="Replace"
          icon="swap_vert"
          class="mx-5"
          @onclick="$emit('replace')"
        />
      </div>
    </v-col>

    <v-col v-if="edit" class="d-flex align-center justify-end subtitle-1">
      <g-button
        label="Products"
        :type="step == 2 ? 'primary' : ''"
        class="primary--text"
        @onclick="$emit('setStep', 2)"
      />
      <g-button
        v-if="hasStepThree"
        :label="customLabel"
        :type="step == 3 ? 'primary' : ''"
        class="primary--text"
        @onclick="$emit('setStep', 3)"
      />
      <span>|</span>
      <g-button
        label="Alternate"
        :type="step == 4 ? 'secondary' : ''"
        class="secondary--text"
        @onclick="$emit('setStep', 4)"
      />
    </v-col>
    <v-col v-else class="d-flex align-center justify-end subtitle-1">
      <v-btn
        icon
        v-for="n in stepCount"
        :key="n"
        small
        :color="step > 1 ? 'primary' : ''"
        :outlined="step === n"
        :disabled="step < n || (n === 3 && !hasStepThree)"
        @click="$emit('setStep', n)"
      >
        <span>{{ n }}</span>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    type: String,
    contacted: Boolean,
    edit: Boolean,
    float: Boolean,
    name: String,
    supplierId: String,

    step: Number,
    hasStepThree: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    mode() {
      if (this.edit) return "EDIT";
      return this.name ? "PENDING" : "NO_SELECT";
    },
    stepCount() {
      return [1, 2, 3, 4];
    },
    customLabel() {
      return {
        HOTEL: "Meals",
        RESTAURANT: "Supplier Meals",
        TOUR_GUIDE: "Supplier Rooms",
        TRANSPORT: "Supplier Rooms",
        EXCURSION: "Suppliers",
      }[this.type];
    },
    goRoute() {
      return {
        name: "suppliers_info",
        params: {
          id: this.supplierId,
        },
      };
    },
  },
};
</script>

<style></style>
