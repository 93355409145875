<template>
  <div>
    <a-card nopad v-if="showEdit">
      <elwrapper :label="showEdit ? editLabel || label : label" widelabel>
        <f-password v-if="password" v-model="value" />

        <RichTextEditor v-else-if="editor" maxheight="300px" v-model="value" />

        <v-text-field v-else outlined hide-details dense v-model="value" />
      </elwrapper>

      <elwrapper label="Current Password" widelabel v-if="!nopass">
        <f-password v-model="confirmPassword" />
      </elwrapper>

      <v-row justify="space-between" class="pa-3">
        <g-button label="Cancel" @onclick="clearEdit" />

        <save-button :disabled="!canSave" :onsave="saveChanges" />
      </v-row>
    </a-card>

    <elwrapper :label="label" v-else widelabel>
      <div class="d-flex justify-space-between">
        <div>
          <span>{{ displayValue }}</span>
          <slot></slot>
        </div>

        <v-btn icon small :disabled="disabled" @click="openEdit">
          <v-icon small>edit</v-icon>
        </v-btn>
      </div>
    </elwrapper>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    editLabel: String,
    displayValue: String,
    disabled: Boolean,
    password: Boolean,
    editor: Boolean,
    nopass: Boolean,

    savefn: {
      type: Function, // Must return a promise
      required: true,
    },
  },
  data() {
    return {
      value: "",
      confirmPassword: "",
      showEdit: false,
      loading: false,
    };
  },
  computed: {
    canSave() {
      return this.value && (this.nopass || this.confirmPassword);
    },
  },
  methods: {
    openEdit() {
      this.value = this.password ? "" : this.displayValue;
      this.showEdit = true;
      this.$emit("onedit");
    },
    clearEdit() {
      this.showEdit = false;
      this.$emit("clear");
    },
    saveChanges() {
      return this.savefn({
        value: this.value,
        confirmPassword: this.confirmPassword,
      })
        .then(() => {
          this.clearEdit();
        })
        .catch((err) => this.$root.$error(err));
    },
  },
};
</script>
