<template>
  <div>
    <SupplierDatabaseTable
      v-if="dbConfig === 'suppliers'"
      :dbSource="dbSource"
      :select-only="selectOnly"
      :selectInto="selectInto"
      :selectOne="selectOne"
      :height="height"
      :query="query"
      :hideList="hideList"
      @select="(v) => $emit('select', v)"
      @gointo="(v) => $emit('gointo', v)"
    />
    <ProductsDatabaseTable
      v-else-if="dbConfig === 'products'"
      :dbSource="dbSource"
      :allowRefresh="allowRefresh"
      :select-only="selectOnly"
      :select-into="selectInto"
      :height="height"
      :query="query"
      :focusYear="focusYear"
      :useOrderSort="useOrderSort"
      :restrictTo="restrictTo"
      :disableFn="disableFn"
      :postFetchFn="postFetchFn"
      :selected="selectedList"
      @select="(v) => $emit('select', v)"
      @gointo="(v) => $emit('gointo', v)"
    />
    <ClientDatabaseTable
      v-else-if="dbConfig === 'clients'"
      :select-only="selectOnly"
      :query="query"
      :height="height"
      @select="(v) => $emit('select', v)"
    />
  </div>
</template>

<script>
import ClientDatabaseTable from "./tables/_ClientDatabaseTable";
import SupplierDatabaseTable from "./tables/_SupplierDatabaseTable";
import ProductsDatabaseTable from "./tables/_ProductsDatabaseTable";
export default {
  props: {
    dbConfig: String,
    dbSource: String,
    query: Object,
    height: String,
    selectOnly: Boolean,
    selectInto: Boolean,
    selectOne: Boolean,
    allowRefresh: Boolean,

    focusYear: String, // For product pricing

    useOrderSort: Boolean,

    disableFn: Function,
    postFetchFn: Function,

    hideList: Array, //  What items to hide in list
    selectedList: [Array, Object],
    restrictTo: String, // For product database, uses supplier id to query
  },
  components: {
    SupplierDatabaseTable,
    ProductsDatabaseTable,
    ClientDatabaseTable,
  },
};
</script>

<style lang="scss">
.database-table {
  .vue-recycle-scroller__item-view {
    display: table;
  }

  .database-row {
    width: 100%;

    &:hover {
      cursor: pointer;
    }
  }

  td {
    border-right: 1px solid lightgrey;
  }
}
</style>
