<template>
  <v-col>
    <v-row>
      <v-col cols="6">
        <CalendarView
          mode="ALL"
          :dateStart="dateStart"
          :focusDate="focusDate"
          :reminderMapping="reminderMapping"
          @onfocus="(v) => (focusDate = v)"
          @updateDates="(v) => updateReminderMapping(v.dateStart, v.dateEnd)"
        />

        <div v-if="groupedReminders.length">
          <a-card nopad v-for="booking in groupedReminders" :key="booking.booking_id">
            <v-row class="booking-title grey darken-1 white--text">
              <v-col>{{ booking.client_name }}</v-col>
              <v-col>{{ booking.group_name }}</v-col>
            </v-row>
            <ReminderItem
              v-for="reminder in booking.reminderList"
              :key="reminder.id"
              :accountList="accountList"
              :supplierData="reminder"
              @oncomplete="(v) => markComplete(reminder, v)"
              @remove="removeReminder"
              class="color-alt hover"
              mode="ALL"
            />
          </a-card>
        </div>
        <div v-else class="white--text">(No reminders on this day)</div>
      </v-col>

      <v-col>
        <UpcomingReminders
          v-if="hasOverdue"
          mode="ALL"
          title="Overdue"
          :upcomingMapping="overdueMapping"
          @onfocus="(v) => (focusDate = v.date)"
        />
        <UpcomingReminders
          mode="ALL"
          title="Next 30 Days"
          :upcomingMapping="upcomingMapping"
          @onfocus="(v) => (focusDate = v.date)"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import { CalendarView, UpcomingReminders, ReminderItem } from "@/modules/reminders";
import { SuppliersMenu } from "@/config/menus";
export default {
  components: {
    CalendarView,
    UpcomingReminders,
    ReminderItem,
  },
  data() {
    return {
      today: moment().format("YYYY-MM-DD"),
      accountList: [],
      reminderMapping: {},
      upcomingMapping: {},
      overdueMapping: {},
      dateStart: moment().startOf("month").format("YYYY-MM-DD"),
      dateEnd: moment().endOf("month").format("YYYY-MM-DD"),
      focusDate: moment().format("YYYY-MM-DD"),
    };
  },
  computed: {
    hasOverdue() {
      return Object.values(this.overdueMapping).length;
    },
    groupedReminders() {
      const list = this.reminderMapping[this.focusDate];
      if (!list) return [];

      const DBFIELDS = SuppliersMenu.MOD;
      let o = {};
      list.forEach((rem) => {
        if (!o[rem.booking_id]) {
          o[rem.booking_id] = {
            booking_id: rem.booking_id,
            client_name: rem.client_name,
            group_name: rem.group_name,
            reminderList: [],
          };
        }

        o[rem.booking_id].reminderList.push(rem);
      });

      return Object.values(o).map((s) => {
        let temp = s;
        s.reminderList = s.reminderList.sort((a, b) => {
          // Sort then by type
          const aIndex = DBFIELDS.indexOf(a.type_as);
          const bIndex = DBFIELDS.indexOf(b.type_as);
          if (aIndex < bIndex) return -1;
          if (aIndex > bIndex) return 1;
          return 0;
        });
        return s;
      });
    },
  },
  methods: {
    updateReminderMapping(dateStart, dateEnd) {
      this.$store
        .dispatch("ReminderStore/getMassReminders", {
          dateStart,
          dateEnd,
        })
        .then((mapping) => {
          this.reminderMapping = mapping;
        })
        .catch((err) => this.$root.$error(err));
    },
    markComplete(source, data) {
      Vue.set(source, "done_by", data.done_by);
      Vue.set(source, "done_date", data.done_date);
    },
    removeReminder(reminder) {
      this.$store
        .dispatch("ReminderStore/removeReminder", {
          bookingId: 1, // Doesn't matter for now
          reminderId: reminder.id,
        })
        .then((_) => {
          this.reminderMapping[reminder.date] = this.reminderMapping[reminder.date].filter((v) => v.id !== reminder.id);
          this.$root.$success("Removed reminder");
        })
        .catch((err) => this.$root.$error(err));
    },
    getNextThirtyDays() {
      this.$store
        .dispatch("ReminderStore/getMassReminders", {
          dateStart: moment().format("YYYY-MM-DD"),
          dateEnd: moment().add(30, "days").format("YYYY-MM-DD"),
        })
        .then((mapping) => {
          this.upcomingMapping = mapping;
        })
        .catch((err) => this.$root.$error(err));
    },
    getOverdueReminders() {
      this.$store
        .dispatch("ReminderStore/getOverdueReminders", {
          isAdmin: this.$store.getters["isAdmin"],
          created_by: this.$store.getters["AccountStore/id"],
          beforeDate: this.today,
        })
        .then((list) => {
          if (!list || !list.length) return;

          list.forEach((item) => {
            if (!this.overdueMapping[item.date]) Vue.set(this.overdueMapping, item.date, []);
            this.overdueMapping[item.date].push(item);
          });
        })
        .catch((err) => this.$root.$error(err));
    },
  },
  mounted() {
    this.getOverdueReminders();
    this.updateReminderMapping(this.dateStart, this.dateEnd);
    this.getNextThirtyDays();

    this.$store
      .dispatch("AdminStore/getAccountList")
      .then((list) => {
        this.accountList = list;
      })
      .catch((err) => this.$root.$error(err));
  },
};
</script>
