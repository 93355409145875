const SET_TAB = "SET_TAB";
const ADD_TAB = "ADD_TAB";
const REMOVE_TAB = "REMOVE_TAB";

const state = {
  activeTab: 0,
  tablist: [],
};

const mutations = {
  SET_TAB(state, data) {
    state.activeTab = data;
  },
  ADD_TAB(state, data) {
    state.tablist.push(data);
    state.activeTab = state.tablist.length;
  },
  REMOVE_TAB(state, index) {
    state.tablist.splice(index, 1);
    state.activeTab = index;
  },
};

const actions = {
  setFocusTab({ commit }, data) {
    commit(SET_TAB, data);
  },
  addTab({ commit }, data) {
    commit(ADD_TAB, data);
  },
  removeTab({ commit }, index) {
    commit(REMOVE_TAB, index);
  },

  openEmailReplyTab({ commit }, data) {
    const dataStamp = `message_${new Date().getTime()}`;

    localStorage.setItem(dataStamp, JSON.stringify(data));

    commit(ADD_TAB, {
      id: dataStamp,
      closeable: true,
      type: "EMAIL",
      title: "Reply",
      dataStamp,
    });
  },
  openPdf({ commit }, data) {
    window.open(data.link, "_blank");
    // commit(ADD_TAB, {
    // 	id:  `pdf_${new Date().getTime()}`,
    // 	type: 'PDF',
    // 	closeable: true,
    // 	title: data.display_name,
    // 	link: data.link
    // })
  },
};

const getters = {
  activeWindowTab: (state) => state.activeTab,
  tablist: (state) => state.tablist || [],
};

export default {
  // CORE MODULE, NO NAMESPACING
  state,
  mutations,
  actions,
  getters,
};
