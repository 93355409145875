const REGION_LIST = {
  SW: ["CA", "NV", "AZ", "UT", "CO", "NM"],
  NW: ["OR", "ID", "WY", "WA", "MT"],
  S: ["TX", "OK", "KS", "LA", "AR", "MS", "TN", "KY"],
  N: ["ND", "SD", "NE", "IA", "MN", "MO", "IL", "WI", "IN", "MI", "OH"],
  SE: ["FL", "GA", "SC", "NC", "AL"],
  NE: ["ME", "NH", "VT", "NY", "RI", "MA", "CT", "NJ", "DE", "MD", "DC", "WV", "PA", "VA"],
  O: ["AK", "HI", "PR", "VI", "BH", "CI"],
};

const DEFAULT = [
  {
    label: "Nationwide",
    value: "NAT",
  },
  {
    label: "Southwest",
    value: "SW",
  },
  {
    label: "West",
    value: "W",
  },
  {
    label: "Northwest",
    value: "NW",
  },
  {
    label: "North",
    value: "N",
  },
  {
    label: "South",
    value: "S",
  },
  {
    label: "Southeast",
    value: "SE",
  },
  {
    label: "East",
    value: "E",
  },
  {
    label: "Northeast",
    value: "NE",
  },
  {
    label: "Central",
    value: "C",
  },
  {
    label: "Other",
    value: "O",
  },
];

export default {
  REGIONS: DEFAULT.filter((item) => {
    return REGION_LIST[item.value] || item.value === "NAT";
  }),
  STATE_TO_REGION_MAP: (function () {
    let obj = {};

    Object.keys(REGION_LIST).map(function (key, index) {
      REGION_LIST[key].forEach((item) => {
        obj[item] = key;
      });
    });

    return obj;
  })(),
};
