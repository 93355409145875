<template>
  <div class="elgroup">
    <v-icon small left>{{ icon }}</v-icon>
    <span v-if="label" class="caption">{{ label }}</span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    label: String,
  },
};
</script>

<style lang="scss">
.elgroup {
  display: flex;
  border: 1px solid lightgrey;
  border-radius: 10px;
  background: white;

  > span {
    display: flex;
    align-items: center;
  }
}
</style>
