<template>
  <a-card nopad>
    <v-row align="center" justify="space-between" class="px-1 flex-nowrap">
      <a-group label="City" icon="location_city">
        <a-text-field label="Start" v-model="where_start" smlabel v-caps />
        <a-text-field label="End" v-model="where_end" smlabel v-caps />
      </a-group>

      <elwrapper label="Nights" smlabel class="mx-3">
        <f-number v-model="nights" />
      </elwrapper>

      <a-group label="Products" icon="event">
        <elwrapper label="Month" smlabel>
          <month-picker v-model="productMonth" />
        </elwrapper>
        <a-select label="Year" :items="displayYearOptions" v-model="productYear" />
      </a-group>
    </v-row>
  </a-card>
</template>

<script>
import format from "@/services/format";
let START_YEAR = 2020;
let ADVANCE = 2;
let LENGTH = new Date().getFullYear() - START_YEAR + ADVANCE;
export default {
  props: {
    bookingId: String,
  },
  components: {
    // ConfirmField
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    displayYearOptions() {
      return [...Array(LENGTH).keys()].map((i) => START_YEAR + i).reverse();
    },
    where_start: {
      get() {
        return this.get("where_start");
      },
      set(newValue) {
        return this.set("where_start", newValue);
      },
    },
    where_end: {
      get() {
        return this.get("where_end");
      },
      set(newValue) {
        return this.set("where_end", newValue);
      },
    },
    nights: {
      get() {
        return this.get("nights");
      },
      set(newValue) {
        return this.set("nights", Number(newValue));
      },
    },
    productMonth: {
      get() {
        return this.get("product_price_month");
      },
      set(newValue) {
        return this.set("product_price_month", newValue);
      },
    },
    productYear: {
      get() {
        return this.get("product_price_year");
      },
      set(newValue) {
        return this.set("product_price_year", newValue);
      },
    },
  },
  methods: {
    get(val) {
      return this.$store.getters["QuoteStore/" + val];
    },
    set(key, value) {
      return this.$store.dispatch("QuoteStore/update", { key, value });
    },

    // updateBookingDays(dateInfo){
    // 	this.$root.$loading.open('Updating booking dates')

    // 	Promise.all([
    // 		this.$store.dispatch('BookingStore/updateBookingDates', dateInfo),
    // 		this.updateFlight(dateInfo)
    // 	])
    // 	.then(v => {
    // 		// Update day range
    // 		return this.$store.dispatch('BookingDayStore/getDays', this.bookingId)
    // 	})
    // 	.then(v => {
    // 		this.$root.$loading.end();
    // 		this.$root.$success('Updating booking dates')
    // 	})
    // 	.catch(err => {
    // 		this.$root.$error(err)
    // 	})
    // },
    // revertBookingDays(){
    // 	this.$root.$loading.open('Reverting booking dates')

    // 	this.$store.dispatch('BookingStore/revertBookingDates')
    // 	.then(oldDates => {
    // 		return Promise.all([
    // 			this.updateFlight(oldDates),
    // 			this.$store.dispatch('BookingDayStore/getDays', this.bookingId)])
    // 	})
    // 	.then(v => {
    // 		this.$root.$loading.end();
    // 		this.$root.$success('Updating booking dates')
    // 	})
    // 	.catch(err => {
    // 		this.$root.$error(err)
    // 	})

    // },
    // copyBooking(dateInfo){
    // 	const copyName = this.group_name;

    // 	this.$root.$loading.open(`Copying ${this.group_name}...`)
    // 	this.$store.dispatch('BookingStore/copyBooking', {
    // 		booking_id: this.bookingId,
    // 		dates: dateInfo
    // 	}).then(nuBookingId =>  this._switchToNewBooking(nuBookingId, copyName) )
    // 	.catch(err => this.$root.$error(err));
    // },
    // duplicateBooking(dateInfo){
    // 	const copyName = this.group_name;

    // 	this.$root.$loading.open(`Copying ${this.group_name}...`)
    // 	this.$store.dispatch('BookingStore/duplicateBooking', {
    // 		booking_id: this.bookingId,
    // 		dates: dateInfo
    // 	}).then(nuBookingId =>  this._switchToNewBooking(nuBookingId, copyName) )
    // 	.catch(err => this.$root.$error(err));
    // },
    // _switchToNewBooking(nuBookingId, copyName){
    // 	// Updating booking route
    // 	this.$router.push({
    // 		name: 'bookings_view',
    // 		params: {
    // 			booking_id: nuBookingId
    // 		}
    // 	})

    // 	return this.$store.dispatch('BookingStore/getOneBooking', nuBookingId)
    // 	.then(data => {
    // 		// Insert into other stores
    // 		this.$store.dispatch('BookingFlightStore/setFlights', data.flights || {})
    // 		this.$store.dispatch('BookingRoomStore/setRooms', data.rooms || [])
    // 		this.$roomingPing.$emit('sync')
    // 		this.$store.dispatch('BookingDayStore/setDays', {
    // 			days: data.days || [],
    // 			suppliers: data.suppliers || {}
    // 		})
    // 	}).then(() => {
    // 		this.$root.$success(`Copied: ${copyName}`)
    // 		this.$root.$loading.end();
    // 	})
    // }
  },
};
</script>
