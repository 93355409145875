<template>
  <div>
    <v-toolbar dense flat>
      <v-toolbar-title>
        <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
      </v-toolbar-title>
    </v-toolbar>

    <v-container fluid class="justify-center">
      <d-header label="New Client" />
      <create-header label="Client" :goBackTo="goback" :disabled="!canCreate" :oncreate="create" />

      <v-row>
        <v-col cols="5">
          <a-card>
            <a-text-field label="Client Name" v-model="name" v-uppercase="!showTranslation" />
            <a-select label="Country" :items="countryList" v-model="country" />
          </a-card>
          <a-card>
            <a-select label="Category" :items="categoryList" v-model="category" />
          </a-card>
        </v-col>

        <v-col cols="7">
          <NewFormPrimaryAdd :country="country || 'US'" v-model="primaryContact" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PLACES from "@/config/fields/PLACES";
import CLIENT_EXTRA from "@/config/fields/client/extra";
import { NewFormPrimaryAdd } from "@/modules/contacts";
export default {
  components: {
    NewFormPrimaryAdd,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: "Database",
          to: { name: "database" },
        },
        {
          text: "Clients",
          to: { name: "database_view", params: { view: "clients", sub: "ALL" } },
        },
        {
          text: "New",
        },
      ],

      goback: { name: "database_view", params: { view: "clients", sub: "ALL" } },

      countryList: PLACES.COUNTRY_LIST.WORLD,
      categoryList: CLIENT_EXTRA.category,

      name: "",
      country: "",
      category: "Leisure",
      primaryContact: {
        name: "",
        title: "",
        phone_number: "",
        email: "",
      },
    };
  },
  computed: {
    canCreate() {
      return this.name && this.country && this.category;
    },

    showTranslation() {
      return this.$store.getters["AccountStore/environment"] !== "USA";
    },
  },
  methods: {
    create() {
      return this.$store
        .dispatch("ClientStore/addClient", {
          client: {
            name: this.name,
            country: this.country,
            category: this.category,
          },
          primary_contact: this.primaryContact,
        })
        .then((id) => {
          this.$root.$success("Created Client: " + this.name);
          this.$router.push({
            name: "client_info",
            params: {
              client_id: id,
            },
          });
        })
        .catch((err) => this.$root.$error(err));
    },
  },
};
</script>
