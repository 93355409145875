<template>
  <a-card>
    <v-row>
      <v-col class="py-0">
        <a-field
          :config="config.name"
          v-uppercase="!showTranslation"
          :value="value.name"
          @input="(v) => feedUp('name', v)"
        />
      </v-col>
      <v-col class="py-0">
        <a-field
          :config="config.title"
          v-uppercase="!showTranslation"
          :value="value.title"
          @input="(v) => feedUp('title', v)"
        />
      </v-col>
      <v-col class="py-0">
        <v-checkbox
          dense
          hide-details
          class="ma-0 pr-3"
          label="Primary Contact"
          :value="isprimary"
          @change="(v) => $emit('setPrimary', v)"
        ></v-checkbox>
      </v-col>
    </v-row>

    <v-row v-if="showTranslation">
      <v-col class="py-0">
        <a-field
          :config="config.en_label"
          v-uppercase
          :value="value.meta.en_name"
          @input="(v) => feedUpMeta('en_name', v)"
        />
      </v-col>
      <v-col class="py-0">
        <a-field
          :config="config.en_label"
          v-uppercase
          :value="value.meta.en_title"
          @input="(v) => feedUpMeta('en_title', v)"
        />
      </v-col>
      <v-col class="py-0">
        <!-- placeholder -->
      </v-col>
    </v-row>

    <v-row>
      <v-col class="py-0">
        <a-field
          :config="config.phone_number"
          :country="country"
          :value="value.phone_number"
          @input="(v) => feedUp('phone_number', v)"
        />
      </v-col>
      <v-col class="py-0">
        <a-field
          :config="config.cell_number"
          :country="country"
          :value="value.cell_number"
          @input="(v) => feedUp('cell_number', v)"
        />
      </v-col>
      <v-col class="py-0">
        <a-field :config="config.email" v-uppercase :value="value.email" @input="(v) => feedUp('email', v)" />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="py-0">
        <a-field :config="config.notes" v-bullets :value="value.notes" @input="(v) => feedUp('notes', v)" />
      </v-col>
    </v-row>
  </a-card>
</template>

<script>
import contactConfig from "../config/contactConfig";

export default {
  props: {
    value: Object,
    country: String,
    isprimary: Boolean,
  },
  data() {
    return {
      config: contactConfig,
    };
  },
  computed: {
    showTranslation() {
      return this.$store.getters["AccountStore/environment"] !== "USA";
    },
  },
  methods: {
    feedUp(key, value) {
      let copy = { ...this.value };
      this.$emit(
        "input",
        Object.assign(copy, {
          [key]: value,
        })
      );
    },
    feedUpMeta(key, value) {
      let copy = { ...this.value };
      copy.meta = Object.assign(copy.meta, { [key]: value });
      this.$emit(
        "input",
        Object.assign(copy, {
          meta: copy.meta,
        })
      );
    },
  },
};
</script>
