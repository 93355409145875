import axios from "@/plugins/axios";

export default {
  getSupplier(id) {
    return axios.get(`/suppliers/${id}`);
  },
  copySupplier(id) {
    return axios.post(`/suppliers/${id}/copy`);
  },
  updateSupplier(id, data, cache) {
    return axios.post(`/suppliers/${id}`, { data, cache });
  },
  updateSupplierType(id, type, old_type) {
    return axios.post(`/suppliers/${id}/type`, { type, old_type });
  },
  resetPid(id) {
    return axios.post(`/suppliers/${id}/pid`);
  },
  deleteSupplier(id) {
    return axios.delete(`/suppliers/${id}`);
  },

  checkSupplierInBooking(id) {
    return axios.get(`/booking_suppliers/suppliers/${id}`);
  },
};
