<template>
  <div class="black--text">
    <div class="table py-5">
      <v-row>
        <v-col style="max-width: 60px"><!-- Spacer --></v-col>
        <v-col style="max-width: 110px">Date</v-col>

        <v-col>
          {{ displayLabel }}
        </v-col>
      </v-row>

      <v-row v-for="(day, index) in formatedDayList" :key="day.rawDate">
        <v-col style="max-width: 60px">
          <span>{{ day.day }}</span>
        </v-col>
        <v-col class="black--text font-weight-bold" style="max-width: 110px">
          <span>{{ day.date }}</span>
        </v-col>

        <v-col>
          <v-row v-for="bs in day.bssuppliers" :key="day.rawDate + bs.id" class="pa-0">
            <v-col cols="2" class="pa-0">
              {{ bs.productNames }}
            </v-col>
            <v-col v-for="supplier in day.suppliers" :key="day.rawDate + supplier.bsid" class="pa-0">
              <SupplierMealBlock
                :bookingSupplierId="supplier.bsid"
                :date="day.rawDate"
                :sprod="supplier"
                :excludeMapping="bs.excludeMapping"
                @toggle="(data) => toggleBookingSupplierMeal(bs.id, data)"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <div class="ma-2">
      <v-row class="button-row" justify="space-between">
        <g-button label="Close" icon="clear" @onclick="$emit('close')" />

        <div>
          <g-button label="Back" icon="keyboard_arrow_left" @onclick="$emit('revert')" />
          <g-button type="primary" label="Next" @onclick="$emit('next')" />
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import SupplierMealBlock from "./_SupplierMealBlock";
export default {
  props: {
    mode: String,
    disabled: Boolean,
    notransport: Boolean,

    module: String,
    supplierId: String,
  },
  data() {
    return {
      loading: false,
    };
  },
  components: {
    SupplierMealBlock,
  },
  computed: {
    displayLabel() {
      const ref = {
        RESTAURANT: "Meals",
        EXCURSION: "Excursion",
      };
      return `Include ${ref[this.module]} for...`;
    },
    formatedDayList() {
      const dateList = this.$store.getters["BookingDayStore/whatDaysBySupplierId"](this.module, this.supplierId);
      const list = this.$store.getters["BookingDayStore/dayLinkList"]();

      return list
        .filter((item, index) => {
          return dateList.includes(item.rawDate);
        })
        .map((day) => {
          const tourguides = this.$store.getters["BookingDayStore/daySupplier"](day.dayIndex, "tour_guides");
          const transport = this.$store.getters["BookingDayStore/daySupplier"](day.dayIndex, "transport");

          const excludeByDate = {};
          const base = this.$store.getters["BookingDayStore/allSuppliersOnDay"](
            day.rawDate,
            this.module,
            this.supplierId
          );

          return {
            ...day,
            bssuppliers: base.map((bs) => {
              return {
                id: bs.id,
                productNames: Object.values(bs.products)
                  .map((v) => {
                    let name = bs.meta && bs.meta.custom_names && bs.meta.custom_names[v.id];
                    return name || v.name;
                  })
                  .join(", "),
                excludeMapping: bs.meta && bs.meta.exclude_suppliers,
              };
            }),
            suppliers: [..._extractTourGuides(tourguides), ..._extractTransport(transport)],
          };
        });
    },
  },
  methods: {
    toggleBookingSupplierMeal(bsid, data) {
      const updatingbs = this.$store.getters["BookingDayStore/suppliers"][bsid];
      let metaref = _.cloneDeep(updatingbs.meta || {});

      if (!metaref.exclude_suppliers) metaref.exclude_suppliers = {};
      if (!metaref.exclude_suppliers[data.date]) metaref.exclude_suppliers[data.date] = {};

      metaref.exclude_suppliers[data.date][data.bsid] = data.value;

      this.loading = true;
      this.$store
        .dispatch("BookingDayStore/updateBookingSupplier", {
          bookingSupplierId: bsid,
          bookingId: this.$store.getters["BookingStore/booking_id"],
          data: {
            content: {
              meta: metaref,
            },
          },
        })
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$root.$error(err);
        });
    },
  },
};

function _extractTourGuides(tourguides) {
  return tourguides.map((item, index) => {
    return {
      bsid: item.id,
      type_as: "TOUR_GUIDE",
      label: `Tour Guide${tourguides.length > 1 ? "s" : ""}`,
      supplier_name: item.name,
      count: 1,
    };
  });
}
function _extractTransport(transport) {
  return transport.map((item, index) => {
    const count = item.meta.count || {};
    const total = Object.values(count).reduce((total, c) => total + Number(c), 0);
    return {
      bsid: item.id,
      type_as: "TRANSPORT",
      label: `Bus Driver${transport.length > 1 ? "s" : ""}`,
      supplier_name: item.name,
      count: total || 1,
    };
  });
}
</script>
