<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)" persistent fullscreen>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <g-button label="Back" icon="keyboard_arrow_left" @onclick="$emit('input', false)" />

            <div class="my-5">
              <div class="black--text title">
                <v-icon left>notifications_active</v-icon>
                <span>Send Reminder:</span>
              </div>
              <div class="black--text title grey lighten-2 px-2">
                {{ supplierName }}
              </div>
              <v-alert dense text color="warning" class="caption mt-3">
                <v-icon x-small left color="warning">priority_high</v-icon>
                <span>Status will not be updated based on template.</span>
              </v-alert>
            </div>
          </v-col>

          <DialogEmailForm
            :bookingSupplierId="bookingSupplierId"
            :focusBookingSupplier="focusBookingSupplier"
            :supplierId="supplierId"
            :supplierName="supplierName"
            :booking="booking"
            :bookingSuppliers="bookingSuppliers"
            :flattenDays="flattenDays"
            :dayinfo="dayinfo"
            :roomBreakdownData="roomBreakdownData"
            :mailConfig="mailConfig"
            :postSend="updateReminder"
            @onfinish="$emit('input', false)"
          />
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import format from "@/services/format";
import DialogEmailForm from "../_dialogEmailForm";
export default {
  props: {
    value: Boolean,

    // Booking Supplier (focused)
    bookingSupplierId: {
      required: true,
      type: String,
    },
    focusBookingSupplier: Object,

    // Supplier
    supplierId: {
      required: true,
      type: String,
    },
    supplierName: String,

    // Booking stuff
    booking: Object,
    bookingSuppliers: Object,
    flattenDays: Array,
    dayinfo: Array,
    roomBreakdownData: Object,

    reminderData: Array,

    mailConfig: Object,
    postSend: Function,
  },
  components: {
    DialogEmailForm,
  },
  data() {
    return {
      emailContent: {
        attachments: [],
      },
    };
  },
  methods: {
    updateReminder() {
      let updatedReminderData = this.reminderData ? [...this.reminderData] : [];

      // Add reminder
      updatedReminderData.push({
        date: format.convertDate(new Date()),
      });

      return this.$store
        .dispatch("BookingDayStore/updateOneSupplierReminder", {
          bookingId: this.booking.id,
          supplierId: this.supplierId,
          bookingSupplierId: this.bookingSupplierId,
          cache: {
            reminder: this.reminderData,
          },
          data: {
            reminder: updatedReminderData,
          },
        })
        .then(() => {
          this.postSend(updatedReminderData);
        });
    },
  },
};
</script>
