<template>
  <v-menu open-on-hover bottom offset-x>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon small v-bind="attrs" v-on="on">
        <v-icon small>more_vert</v-icon>
      </v-btn>
    </template>

    <v-list dense>
      <v-list-item>
        <v-list-item-title>
          <v-btn small icon color="green" @click="insertRowAbove">
            <v-icon small>arrow_upward</v-icon>
          </v-btn>
          <v-btn small icon color="green" class="mx-3" @click="insertRowBelow">
            <v-icon small>arrow_downward</v-icon>
          </v-btn>
          <v-btn small icon color="error" @click="removeRow">
            <v-icon small>remove</v-icon>
          </v-btn>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    dayIndex: Number,
    hookId: String,
  },
  computed: {
    nights() {
      return Number(this.$store.getters["QuoteStore/nights"] || 0);
    },
  },
  methods: {
    insertRowAbove() {
      // Insert day + add a night
      this.$store.dispatch("QuoteStore/update", { key: "nights", value: this.nights + 1 });

      Promise.all([
        this.$store.dispatch("QuoteStore/updateQuote"),
        this.$store.dispatch("QuoteDayStore/insertDay", {
          quote_id: this.hookId,
          dayIndexToInsert: this.dayIndex,
        }),
      ])
        .then(() => this.$root.$success("Added day"))
        .catch((err) => this.$root.$error(err));
    },
    insertRowBelow() {
      // Insert day + add a night
      this.$store.dispatch("QuoteStore/update", { key: "nights", value: this.nights + 1 });
      Promise.all([
        this.$store.dispatch("QuoteStore/updateQuote"),
        this.$store.dispatch("QuoteDayStore/insertDay", {
          quote_id: this.hookId,
          dayIndexToInsert: this.dayIndex + 1,
        }),
      ])
        .then(() => this.$root.$success("Added day"))
        .catch((err) => this.$root.$error(err));
    },
    removeRow() {
      // Insert day + pop a night
      this.$store.dispatch("QuoteStore/update", { key: "nights", value: this.nights - 1 });
      Promise.all([
        this.$store.dispatch("QuoteStore/updateQuote"),
        this.$store.dispatch("QuoteDayStore/removeDay", {
          quote_id: this.hookId,
          dayIndexToRemove: this.dayIndex + 1,
        }),
      ])
        .then(() => this.$root.$success("Removed day"))
        .catch((err) => this.$root.$error(err));
    },
  },
};
</script>
