<template>
  <div>
    <v-btn icon small color="grey" @click="$emit('input', value - 10)">
      <v-icon small>arrow_downward</v-icon>
    </v-btn>
    <span>{{ value }}</span>
    <v-btn icon small color="grey" @click="$emit('input', value + 10)">
      <v-icon small>arrow_upward</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    value: Number,
  },
};
</script>
