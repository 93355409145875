<template>
  <div>
    <a-hover-menu>
      <template v-slot:activator>
        <v-btn small icon>
          <v-icon small>nights_stay</v-icon>
        </v-btn>
      </template>

      <v-col class="mx-0 caption">
        <span class="font-weight-bold mr-5">Rooming For</span>
        <div v-for="(room, index) in displayNameList" :key="'room' + index">{{ room }}</div>
      </v-col>
    </a-hover-menu>
  </div>
</template>

<script>
export default {
  props: {
    roomingNameList: Array,
  },
  computed: {
    displayNameList() {
      return this.roomingNameList.map((sroom) => {
        return `${sroom.last_name.toUpperCase()} ${sroom.first_name}`;
      });
    },
  },
};
</script>
