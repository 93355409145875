<template>
  <nav-button icon="mail" label="Email" @click="setEmail" />
</template>

<script>
import { EmailService } from "@/modules/email";
export default {
  computed: {
    supplierName() {
      const name = this.$store.getters["SupplierStore/name"];
      const type = this.$store.getters["SupplierStore/type"];
      return `${name} (${type})`;
    },
    contact() {
      return this.$store.getters["SupplierStore/primary_contact"];
    },
  },
  methods: {
    setEmail() {
      // Set email form content
      this.$store.dispatch("EmailStore/pendMail", {
        contact: this.contact,
        returnTo: this.$route.path,
        config: {
          title: this.supplierName,
          supplierId: this.$store.getters["SupplierStore/id"],
        },
      });
      // Switch to new page
      this.$router.push({
        name: "email",
      });
    },
  },
};
</script>
