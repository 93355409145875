<template>
  <a-card>
    <v-col>
      <v-row><h3>General</h3></v-row>
      <v-row align="center" justify="center" class="px-1 py-1 flex-nowrap">
        <ClientAutocomplete v-model="data.client" />
        <elwrapper label="Reference" smlabel>
          <a-text-field v-model="data.reference" smlabel />
        </elwrapper>
        <a-group class="px-2 py-2" label="City" icon="location_city">
          <elwrapper label="Start" smlabel>
            <v-autocomplete dense outlined hide-details :items="cities" v-model="data.cityStart"></v-autocomplete>
          </elwrapper>
          <elwrapper label="End" smlabel>
            <v-autocomplete dense outlined hide-details :items="cities" v-model="data.cityEnd"></v-autocomplete>
          </elwrapper>
          <elwrapper label="Version" smlabel>
            <a-text-field v-model="data.version" smlabel v-caps />
          </elwrapper>
        </a-group>
      </v-row>
      <v-row align="center" justify="start" class="px-1 py-1 flex-nowrap">
        <QuotePeriod v-model="data" :id="data.id" @input="(v) => onQuotePeriodInput(v)" />
        <elwrapper class="ml-2" label="Accommodation category" widelabel>
          <f-dropdown multiple :items="accommodationTypes" v-model="data.accommodations" />
        </elwrapper>
      </v-row>
      <v-row align="center" justify="start" class="px-1 py-1 flex-nowrap">
        <elwrapper label="Program's Title" widelabel>
          <a-text-field v-model="data.title" smlabel />
        </elwrapper>
      </v-row>
    </v-col>
  </a-card>
</template>

<script>
import { ClientAutocomplete } from "@/modules/clients";
import { SupplierHotelMeta } from "../../../config/fields/suppliers";
import { QuotePeriod } from "@/modules/quotes";
export default {
  components: {
    ClientAutocomplete,
    QuotePeriod,
  },
  props: {
    value: Object,
    supplierProductData: Object,
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    accommodationTypes() {
      return SupplierHotelMeta.hotel_class.source.reverse();
    },
    cities() {
      return Object.values(this.supplierProductData)
        .map((item) => item.supplier.city)
        .filter((city) => city !== null && city !== undefined)
        .map((city) => city.trim().toUpperCase())
        .filter((city, index, array) => array.indexOf(city) === index);
    },
  },
  methods: {
    onQuotePeriodInput(v) {
      const numberOfNights = Number(v.nights);
      if (numberOfNights >= 0) {
        this.tempVariableCosts = this.data.variableCosts;
        this.updateVariableCosts(numberOfNights);
      }
    },
    updateVariableCosts(nights) {
      // Update the row of data for variable costs based on the number of nights
      this.data.variableCosts = [];

      const days = nights + 1;
      for (let i = 0; i < days; i++) {
        const existingCost =
          this.tempVariableCosts && this.tempVariableCosts[i] !== undefined ? this.tempVariableCosts[i] : null;
        if (existingCost !== null && existingCost !== undefined) {
          this.data.variableCosts[i] = existingCost;
        } else {
          this.data.variableCosts.push({ index: i, items: [] });
        }
      }
    },
  },
};
</script>
