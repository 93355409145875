const SET_LOADING = "SET_LOADING";
const CLEAR_LOADING = "CLEAR_LOADING";

const state = {
  loadingPage: false,
  loadingPageMessage: "",

  bookingScrollOn: false,
  bookingAllDaysScroll: 0,
};

const mutations = {
  SET_LOADING(state, data) {
    state.loadingPage = true;
    state.loadingPageMessage = data.loadingPageMessage || "Loading";
  },
  CLEAR_LOADING(state) {
    state.loadingPage = false;
    state.loadingPageMessage = "Loading";
  },

  TOGGLE_BOOKING_SCROLL(state, value) {
    state.bookingScrollOn = value;
  },
  SET_BOOKING_SCROLL(state, value) {
    state.bookingAllDaysScroll = value;
  },
};

const actions = {
  showLoading({ commit }, data) {
    commit(SET_LOADING, data);
  },
  clearLoading({ commit }, CLEAR_LOADING) {
    commit(CLEAR_LOADING);
  },

  setBookingPageScrollPos({ commit }, val) {
    commit("SET_BOOKING_SCROLL", val);
  },
  toggleBookingScroll({ commit }, val) {
    commit("TOGGLE_BOOKING_SCROLL", val);
  },
};

const getters = {
  bookingScrollOn: (state) => state.bookingScrollOn,
  bookingAllDaysScroll: (state) => state.bookingAllDaysScroll,
};

export default {
  // CORE MODULE, NO NAMESPACING
  state,
  mutations,
  actions,
  getters,
};
