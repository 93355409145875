<template>
  <a-card nopad>
    <a-field :config="config[fieldName]" v-bullets v-model="notes" />
  </a-card>
</template>

<script>
import { BookingFields } from "@/config/fields/bookings";
export default {
  props: {
    fieldName: String,
  },
  data() {
    return {
      config: BookingFields,
    };
  },
  computed: {
    notes: {
      get() {
        return this.get(this.fieldName);
      },
      set(newValue) {
        return this.set(this.fieldName, newValue);
      },
    },
  },
  methods: {
    get(val) {
      return this.$store.getters["BookingStore/active"][val];
    },
    set(key, val) {
      return this.$store.dispatch("BookingStore/update", { key, val });
    },
  },
};
</script>
