<template>
  <v-row class="mb-3" align="center">
    <v-col cols="2" class="subtitle-2" align-self="start"> Parking </v-col>
    <v-col>
      <div v-if="free">
        <span class="mx-2 subtitle-2">FREE</span>
      </div>
      <div v-else-if="cost">
        <v-row v-if="vehicle">
          <v-col cols="1" class="subtitle-2">Vehicles</v-col>
          <v-col cols="2" style="text-align: right"> {{ vehicle.value }} x </v-col>
          <v-col cols="1">
            <f-number :value="vehicleCount" @input="setVehicleCount" />
          </v-col>
        </v-row>
        <v-row v-if="bus">
          <v-col cols="1" class="subtitle-2">Buses</v-col>
          <v-col cols="1" style="text-align: right"> {{ bus.value }} x </v-col>
          <v-col cols="1">
            <f-number :value="busCount" @input="setBusCount" />
          </v-col>
        </v-row>
      </div>
      <div v-else>(Not Offered)</div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    supplier: Object,
    bsmeta: Object,
  },
  data() {
    return {
      parking_vehicle_count: 0,
      parking_bus_count: 0,
    };
  },
  computed: {
    supplierMeta() {
      return (this.supplier && this.supplier.meta) || {};
    },
    free() {
      if (!this.supplierMeta.parking) return false;
      return this.supplierMeta.parking.value === "free";
    },
    cost() {
      if (!this.supplierMeta.parking) return false;
      return this.supplierMeta.parking.per_vehicle || this.supplierMeta.parking.per_bus;
    },

    vehicle() {
      if (!this.cost) return {};
      return this.supplierMeta.parking.per_vehicle;
    },
    bus() {
      if (!this.cost) return {};
      return this.supplierMeta.parking.per_bus;
    },

    vehicleCount() {
      if (this.parking_vehicle_count) return this.parking_vehicle_count;
      if (this.bsmeta.parking && this.bsmeta.parking.parking_vehicle_count)
        return this.bsmeta.parking.parking_vehicle_count;
      return 0;
    },
    busCount() {
      if (this.parking_bus_count) return this.parking_bus_count;
      if (this.bsmeta.parking && this.bsmeta.parking.parking_bus_count) return this.bsmeta.parking.parking_bus_count;
      return 0;
    },
  },
  methods: {
    setVehicleCount(v) {
      this.parking_vehicle_count = v;
      this.$emit("set", {
        ...this.bsmeta.parking,
        parking_vehicle_count: v,
      });
    },
    setBusCount(v) {
      this.parking_bus_count = v;
      this.$emit("set", {
        ...this.bsmeta.parking,
        parking_bus_count: v,
      });
    },
  },
};
</script>
