<template>
  <div>
    <v-btn small icon class="ml-3 white--text" @click="dialog = true">
      <v-icon>help_outline</v-icon>
    </v-btn>
    <HelperDialog v-model="dialog" />
  </div>
</template>

<script>
import HelperDialog from "./HelperDialog";
export default {
  components: {
    HelperDialog,
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
