<template>
  <div>
    <ClientHeader :name="client" :clientId="clientId" />

    <v-container fluid>
      <router-view></router-view>
    </v-container>
  </div>
</template>

<script>
import { ClientHeader } from "@/modules/clients";
export default {
  components: {
    ClientHeader,
  },
  computed: {
    client() {
      return this.$store.getters["ClientStore/cache"].name;
    },
    clientId() {
      return this.$store.getters["ClientStore/client_id"];
    },
  },
  mounted() {
    // Refreshing page
    // Wrong Environment for supplier, then redirect to database suppliers
    const cached = this.$store.state.ClientStore.cache;
    const env = this.$store.getters["AccountStore/environment"];
    if (cached && cached.env !== env) {
      this.$router.push({
        name: "database_view",
        params: { view: "clients", sub: "ALL" },
      });
      return;
    }

    var clientId = this.$route.params.client_id;

    this.$store.dispatch("ClientStore/getOneClient", clientId).catch((err) => this.$root.$error(err));
  },
};
</script>

<style lang="scss"></style>
