export default {
  US: {
    currency: "USD",
    currency_symbol: "$",
    prefix: "$",
    money: {
      currency: "USD",
      prefix: "$",
      locale: "en",
      decimal: ".",
      thousands: ",",
      precision: 2,
    },
  },
  CA: {
    currency: "CAD",
    currency_symbol: "$",
    showConvert: true,
    prefix: "$",
    money: {
      currency: "USD",
      prefix: "$",
      locale: "en-US",
      currencyDisplay: "narrowSymbol",
      decimal: ",",
      thousands: " ",
      precision: 2,
    },
  },
  JP: {
    currency: "JPY",
    currency_symbol: "¥",
    showConvert: true,
    money: {
      currency: "JPY",
      decimal: ".",
      thousands: ",",
      precision: 0,
    },
  },
};
