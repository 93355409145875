<template>
  <v-col class="px-0 py-2">
    <SeasonalPriceRow v-for="(r, index) in orderedPriceList" :key="index" class="caption" :env="env" :rate="r" />

    <div>
      <g-button label="Edit" icon="edit" class="mr-3" :disabled="disabled" @onclick="editContent" />
      <span v-show="!disabled" class="seasonal-click blue--text caption pr-3" @click="$emit('revert')"
        >Revert to Flat</span
      >
    </div>

    <SeasonlPricingDialog :env="env" :priceList="value" @update="(v) => $emit('input', v)" v-model="dialog" />
  </v-col>
</template>

<script>
import MONTHS from "@/config/MONTHS";
import SeasonalPriceRow from "./_SeasonPriceRow";
import SeasonlPricingDialog from "./_SeasonalPricingDialog";
export default {
  props: {
    env: String,
    disabled: Boolean,
    value: Array, // List of seasonal rates
  },
  components: {
    SeasonalPriceRow,
    SeasonlPricingDialog,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    orderedPriceList() {
      // Remove existing season
      return this.value;
    },
  },
  methods: {
    editContent() {
      this.dialog = true;
    },
  },
};
</script>
