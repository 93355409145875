<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)" width="450px" persistent>
    <v-card>
      <v-toolbar depressed flat>
        <span class="caption mr-1">Status for: </span>
        <span class="subtitle-2">{{ focusBookingSupplier.name }} ({{ type }})</span>

        <div class="flex-grow-1"></div>
      </v-toolbar>

      <v-card-text class="pb-0">
        <v-row class="pa-0" justify="center">
          <g-button label="Cancel" icon="cancel" type="black" class="mx-2" @onclick="cancelEmail" />
          <g-button label="Impossible" icon="cancel" type="red" class="mx-2" @onclick="setImpossible" />
          <g-button
            label="Send Reminder"
            icon="notifications_active"
            type="primary"
            class="mx-2"
            :disabled="!bookingStatus"
            @onclick="reminderDialog = true"
          />
        </v-row>

        <v-list>
          <v-list-item-group :value="focusBookingSupplier.status" color="primary">
            <v-list-item
              v-for="(bleep, index) in bleeps"
              :key="index"
              :class="{ 'status-disabled': blockChange(bleep.value) }"
              @click="nuStatus = bleep.value"
            >
              <v-icon :class="bleep.color" left>brightness_1</v-icon>
              <span>{{ bleep.label }}</span>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>

      <div v-if="nuStatus > 3" class="mx-5 pa-3 primary--text ring">
        <pcheck label="Has Confirmation Number" v-model="addConfirmNumber" />
      </div>

      <div v-if="massOption" class="mx-5 pa-3 primary--text ring">
        <pcheck label="Apply to all cases of this supplier" v-model="applyAll" />
      </div>

      <v-card-actions>
        <g-button label="Close" @onclick="$emit('input', false)" />
        <div class="flex-grow-1"></div>

        <g-button type="primary" label="Update" @onclick="updateStatus" />
      </v-card-actions>
    </v-card>

    <EnterConfirmationNumberDialog
      v-model="confirmDialog"
      :type="type"
      :count="focusBookingSupplier.meta && focusBookingSupplier.meta.count"
      :roomingName="roomNameDefault"
      :showConfirm="addConfirmNumber"
      :massOption="massOption"
      @setConfirm="(v) => updateStatus(true, v)"
    />

    <CancelEmailDialog
      v-model="cancelDialog"
      :bookingSupplierId="focusBookingSupplier.id"
      :focusBookingSupplier="focusBookingSupplier"
      :supplierName="focusBookingSupplier.name"
      :supplierId="focusBookingSupplier.supplier_id"
      :booking="booking"
      :bookingSuppliers="bookingSuppliers"
      :flattenDays="flattenDays"
      :dayinfo="dayinfo"
      :roomBreakdownData="roomBreakdownData"
      :mailConfig="mailConfig"
      :postSend="cancelEmailSent"
    />

    <ReminderEmailDialog
      v-model="reminderDialog"
      :bookingSupplierId="focusBookingSupplier.id"
      :focusBookingSupplier="focusBookingSupplier"
      :supplierId="focusBookingSupplier.supplier_id"
      :supplierName="focusBookingSupplier.name"
      :booking="booking"
      :bookingSuppliers="bookingSuppliers"
      :flattenDays="flattenDays"
      :dayinfo="dayinfo"
      :roomBreakdownData="roomBreakdownData"
      :mailConfig="mailConfig"
      :postSend="updateReminder"
      :reminderData="focusBookingSupplier.reminder"
    />
  </v-dialog>
</template>

<script>
import Vue from "vue";
import EnterConfirmationNumberDialog from "./_EnterConfirmationNumberDialog";
import CancelEmailDialog from "./cancelEmailDialog/CancelEmailDialog";
import ReminderEmailDialog from "./reminderDialog/ReminderEmailDialog";
import STATUS_COLORS from "@/config/STATUS_COLORS";
import BookingEmailService from "@/modules/bookings.email/service";
import { BookingRoomService } from "@/modules/bookings.rooms";
import { BookingDayService } from "@/modules/bookings.days";

const INQUIRY = 1;

export default {
  props: {
    mode: String, // OUT = outside booking, IN = inside booking
    value: Boolean,
    type: String,
    focusBookingSupplier: Object, // Focused booking supplier

    // Booking specific stuff
    booking: Object,
    bookingSuppliers: Object,
    flattenDays: Array,
    dayinfo: Array,
  },
  components: {
    EnterConfirmationNumberDialog,
    CancelEmailDialog,
    ReminderEmailDialog,
  },
  data() {
    return {
      nuStatus: 0,
      confirmDialog: false,
      cancelDialog: false,
      reminderDialog: false,

      applyAll: false,
      addConfirmNumber: false,
    };
  },
  watch: {
    value(v) {
      this.nuStatus = this.focusBookingSupplier.status;

      // Set confirmation number check box
      if (this.hasConfirmNumber || ["TRANSPORT", "EXCURSION"].includes(this.type)) {
        this.addConfirmNumber = true;
      }

      // Set applyAll checkbox
      if (this.focusBookingSupplier.meta) {
        this.applyAll = this.focusBookingSupplier.meta.bound;
      }
    },
  },
  computed: {
    isInside() {
      return this.mode === "IN";
    },
    bookingStatus() {
      if (this.isInside) return this.$store.getters["BookingStore/active"].status;
      return (this.booking && this.booking.status) || 1;
    },
    levels() {
      if (["HOTEL"].includes(this.type)) return 4;
      return 2;
    },
    bleeps() {
      return this.type ? STATUS_COLORS.dotColors(this.type) : [];
    },
    hasConfirmNumber() {
      return this.focusBookingSupplier.meta && this.focusBookingSupplier.meta.confirm_number;
    },

    needRoomName() {
      const ALLOWED = ["TRANSPORT", "TOUR_GUIDE"];
      if (!ALLOWED.includes(this.type)) return false;
      if (!this.applyAll) return this.focusBookingSupplier.meta && this.focusBookingSupplier.meta.createRoom;

      // Get all cases of this supplier
      const bsgroup = Object.values(this.$store.getters["BookingDayStore/suppliers"]).filter((v) => {
        return (
          ALLOWED.includes(v.type_as) &&
          this.focusBookingSupplier.supplier_id === v.supplier_id &&
          v.meta &&
          v.meta.createRoom
        );
      });
      return bsgroup.length > 0;
    },
    roomNameDefault() {
      if (this.type === "TRANSPORT") return "Driver Bus";
      if (this.type === "TOUR_GUIDE") return this.focusBookingSupplier.name;
      return "";
    },
    massOption() {
      const shared = Object.values(this.bookingSuppliers).filter(
        (item) => item.supplier_id === this.focusBookingSupplier.supplier_id
      );
      return shared.length > 1;
    },
    roomBreakdownData() {
      // If not inside booking, then the booking + rooming data MUST be passed into this dialog
      return BookingRoomService.getRoomingListBreakdown({
        bookingMetaData: this.isInside ? this.$store.getters["BookingStore/data"] : this.booking.data || {},
        bookingRoomingList: this.isInside
          ? this.$store.getters["BookingRoomStore/rooms"]
          : this.booking.rooming_data || [],
        bookingDays: this.isInside ? this.$store.getters["BookingDayStore/days"] : this.booking.all_days || [],
        supplierRoomList: this.isInside
          ? this.$store.getters["BookingSupplierRoomStore/supplier_rooms"]
          : this.booking.supplier_rooms || [],
      });
    },

    mailConfig() {
      const booking = this.isInside ? this.$store.getters["BookingStore/active"] : this.booking;
      const roomingList = this.isInside ? this.$store.getters["BookingRoomStore/rooms"] : this.booking.rooming_data;

      return {
        group_name: booking.group_name,
        pax: booking.data && booking.data.pax_num,
        //BookingRoomService.getPaxCount(this.roomBreakdownData.bookingMainBreakdown || {}, roomingList)
      };
    },
  },
  methods: {
    blockChange(status) {
      // Custom changing the status means things can still be changed
      // 1. If system email is sent, then changing DOWN is locked
      // 2. If booking is pending to be cancelled, then changing UP is locked
      if (!this.bookingStatus) {
        // Pending cancel
        if (this.focusBookingSupplier.status <= status) return true;
        return false;
      }

      if (!this.focusBookingSupplier.status_set) return false;
      // FORMAT: SYS_1
      const num = Number(this.focusBookingSupplier.status_set.split("_")[1]);

      return status < num;
    },
    updateStatus(skip, confirmInformation) {
      const vm = this;
      // setting to constant. Changes for some reason
      const nuStatus = this.nuStatus;

      if (!skip && nuStatus === 4) {
        // Show Dialog if requires extra confirmation info
        if (this.addConfirmNumber && !this.hasConfirmNumber) {
          this.confirmDialog = true;
          return;
        }
      }

      if (!this.focusBookingSupplier.meta) Vue.set(this.focusBookingSupplier, "meta", {});
      if (this.applyAll) Vue.set(this.focusBookingSupplier.meta, "bound", true);
      if (confirmInformation && confirmInformation.data) {
        Object.keys(confirmInformation.data).forEach((key) => {
          Vue.set(this.focusBookingSupplier.meta, key, confirmInformation.data[key]);
        });
      } else if (!this.addConfirmNumber) {
        // REMOVE confirmation if unchecked
        Vue.set(this.focusBookingSupplier.meta, "confirm_number", null);
      }

      this.$emit("input", false);
      this.$root.$loading.open("Updating " + this.focusBookingSupplier.name);
      return this.$store
        .dispatch("BookingDayStore/updateOneSupplierStatus", {
          bookingSupplierId: this.focusBookingSupplier.id,
          bookingSuppliers: this.bookingSuppliers,
          bookingId: this.$store.getters["BookingStore/booking_id"] || this.booking.id,
          supplierId: this.focusBookingSupplier.supplier_id,
          applyAll: this.applyAll ? this.applyAll : false,
          data: {
            status: nuStatus,
            meta: this.focusBookingSupplier.meta,
          },

          bookingPaxCount: this.mailConfig.pax,

          mode: this.mode, // OUT = in status search

          getLockedPricesFn(item) {
            // Get the date for product seasonal calculations
            const firstSupplierDay = vm.dayinfo.find((d) => {
              return d.suppliers.includes(vm.focusBookingSupplier.id);
            });

            return BookingDayService.setLockedPrices({
              item,
              status: nuStatus,
              storeGetters: vm.$store.getters,
              useDates: firstSupplierDay.date,
            });
          },
          getConfirmedRoomDataFn(item, existingMeta) {
            return BookingDayService.setConfirmedRoomData({
              item,
              status: nuStatus,
              existingMeta,
              roomBreakdownData: vm.roomBreakdownData,
              dayinfo: vm.dayinfo,
            });
          },
          getConfirmedCountFn(item, existingMeta) {
            return BookingDayService.setConfirmedCount({
              env: vm.$store.getters["AccountStore/environment"],
              item,
              status: nuStatus,
              existingMeta,
              days: vm.isInside ? vm.$store.getters["BookingDayStore/days"] : vm.booking.days,
              bookingSuppliers: vm.bookingSuppliers,
              // bookingSupplierRoomList: vm.isInside? vm.$store.getters['BookingSupplierRoomStore/supplier_rooms'] : vm.booking.supplier_rooms,
              dayinfo: vm.dayinfo,
            });
          },
        })
        .then((data) => {
          this.$root.$loading.end();
          this.$emit("update", this.mode === "OUT" ? data : nuStatus);
          this.$root.$success("Updated supplier status");
          this.$roomingPing.$emit("sync");
        })
        .catch((err) => {
          this.$root.$error(err);
        });
    },

    cancelEmail() {
      if (this.focusBookingSupplier.status <= INQUIRY || this.focusBookingSupplier.status_set) {
        // Inquiry or less, can cancel supplier
        // If no email sent to supplier, can cancel supplier
        // Can safely set status to -1
        this.nuStatus = -1;
        this.updateStatus(true);
        return;
      }

      // Supplier already contacted
      // Needs to be notified of cancelation
      this.cancelDialog = true;
    },
    cancelEmailSent() {
      // Cancel email here
      this.cancelDialog = false;
      this.$emit("input", false);
      this.nuStatus = -1;
      this.updateStatus(true);
    },

    setImpossible() {
      this.nuStatus = -2;
      this.updateStatus(true);
    },

    updateReminder(reminderCount) {
      Vue.set(this.focusBookingSupplier, "reminder", reminderCount);
      this.reminderDialog = false;
      this.$emit("input", false);
    },
  },
};
</script>

<style lang="scss">
.status-disabled {
  pointer-events: none;
  background: lightgrey;
  opacity: 0.8;
}
</style>
