import Vue from "vue";

import ADate from "./ADate";
import DateRange from "./DateRange";

import MonthPicker from "./MonthPicker";
import DayPicker from "./DayPicker";
import MonthRange from "./MonthRange";
import YearPicker from "./YearPicker";

Vue.component("a-date", ADate);
Vue.component("date-range", DateRange);

Vue.component("month-picker", MonthPicker);
Vue.component("day-picker", DayPicker);
Vue.component("month-range", MonthRange);
Vue.component("year-picker", YearPicker);
