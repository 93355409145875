<template>
  <v-row class="mb-3 top-row" align="center">
    <v-col cols="2" class="subtitle-2"> Porterage </v-col>
    <v-col align-self="center">
      <div v-if="mandatory">
        <span class="mx-2 subtitle-2">MANDATORY -</span>
        <span>{{ hasport.value }} x {{ pax }} (pax)</span>
      </div>
      <v-row v-else-if="optional">
        <pcheck label="Include optional" :value="bsmeta.porterage" @input="(v) => $emit('set', v)" />
        <span>{{ hasport.value }} x {{ pax }} (pax)</span>
      </v-row>
      <div v-else>(Not Offered)</div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    supplier: Object,
    bsmeta: Object,
    init: Boolean,
    pax: [Number, String],
  },
  data() {
    return {
      innercheck: false,
    };
  },
  computed: {
    supmeta() {
      return (
        this.supplier && (this.init ? this.supplier.supplier_meta || this.supplier.meta : this.supplier.supplier_meta)
      );
    },

    hasport() {
      return this.supmeta && this.supmeta.porterage;
    },
    mandatory() {
      return this.hasport && this.hasport.text === "mandatory";
    },
    optional() {
      return this.hasport && this.hasport.text === "optional";
    },
    free() {
      return false;
    },
  },
};
</script>
