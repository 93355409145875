<template>
  <v-col class="pa-0" style="border-bottom: 1px solid rgb(200, 200, 200)">
    <ProductClickableCol
      :mode="mode"
      :module="module"
      v-if="!bssuppliers.length"
      :selectedBsid="selectedBsid"
      :dateList="dateList"
      :highlightedRows="highlightedRows"
      :rawDate="focusDate"
      :supplierId="supplierId"
    >
      <v-row style="height: 28px">
        <!-- SPACER -->
      </v-row>
    </ProductClickableCol>

    <ProductClickableCol
      :mode="mode"
      :module="module"
      v-for="p in bssuppliers"
      :key="p.id"
      :selectedBsid="selectedBsid"
      :dateList="dateList"
      :highlightedRows="highlightedRows"
      :supplierId="supplierId"
      :rawDate="focusDate"
      :focusBsid="p.id"
      @set="(v) => $emit('set', v)"
      @mark="(v) => $emit('mark', v)"
    >
      <ProductBlock
        :bs="p"
        :date="focusDate"
        :type="module"
        @edit="(v) => $emit('edit', v)"
        @clear="(v) => $emit('clear', v)"
      />
    </ProductClickableCol>
  </v-col>
</template>

<script>
import format from "@/services/format";
import ProductBlock from "./_ProductBlock";
import { ProductService } from "@/modules/products";
import ProductClickableCol from "./_ProductClickableCol";
export default {
  props: {
    mode: String,
    module: String,
    disabled: Boolean,

    // Copy stuff
    selectedBsid: String,
    dateList: Array,
    highlightedRows: Object,

    focusDate: String,
    supplierId: String,
    nestedType: String,
  },
  components: {
    ProductBlock,
    ProductClickableCol,
  },
  data() {
    return {
      product: {},
      bookingSupplierMeta: {},
    };
  },
  computed: {
    bssuppliers() {
      const base = this.$store.getters["BookingDayStore/allSuppliersOnDay"](
        this.focusDate,
        this.module,
        this.supplierId
      );
      if (!this.nestedType) return base;

      return base.filter((s) => {
        return s.nest_type === this.nestedType;
      });
    },
  },
  methods: {
    getProductNames(products, bs) {
      return Object.values(products)
        .map((v) => {
          let name = bs.meta && bs.meta.custom_names && bs.meta.custom_names[v.id];
          return name || v.name;
        })
        .join(", ");
    },
  },
};
</script>
