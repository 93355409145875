import axios from "@/plugins/axios";

export default {
  getOptionList(refkey) {
    return axios.get(`/option_lists/${refkey}`);
  },
  syncRawOptionsList(refkey) {
    return axios.post(`/option_lists/${refkey}/raw`);
  },
  updateList(refkey, data) {
    return axios.post(`/option_lists/${refkey}`, { data });
  },
};
