<template>
  <v-col>
    <DocumentsHeader
      isimg
      :prependMenu="prependMenu"
      :uploadfn="addedImage"
      @clear="clearHeader"
      :onsave="saveDefaultImages"
      @toggleDelete="setDeleteMode"
      :confirmDelete="deleteDocuments"
    />

    <a-card>
      <h3 class="title">Selected Logo</h3>

      <ImageGroup
        v-if="defaultImages.length > 0"
        :imageList="defaultImages"
        :showChecks="showChecks"
        @toggle="selectImage"
        @edit="openEditImage"
      />
      <p v-if="!defaultImages.length">(No logo has been selected)</p>
    </a-card>

    <a-card v-if="otherImages.length > 0">
      <h3 v-if="defaultImages.length > 0" class="title">All Images</h3>

      <ImageGroup :imageList="otherImages" :showChecks="showChecks" @toggle="selectImage" @edit="openEditImage" />
    </a-card>

    <edit-image-dialog v-model="editDialog" :editData="editFocus" @update="updateImage" />
    <d-no-display v-if="imageList.length === 0" label="(No images to display)" />
  </v-col>
</template>

<script>
import { ImageGroup } from "@/modules/media.pictures";
import { DocumentsHeader } from "@/modules/media.documents";

export default {
  components: {
    ImageGroup,
    DocumentsHeader,
  },
  data() {
    let vm = this;
    return {
      prependMenu: [
        {
          title: "Select Client Logo",
          showMode: "SAVE",
          onClick: vm.setDefaultMode,
        },
      ],

      showChecks: false,
      mode: "",
      defaultImageChanges: {},
      deleteItems: [],
      showImages: false,
      focusImageIndex: "",

      // From backend
      imageList: [],

      // Manually calucalted
      defaultImages: [],
      otherImages: [],
      allImages: [],

      // Edit Dialog
      editDialog: false,
      editFocus: {},
    };
  },
  computed: {
    hostName() {
      return this.$store.getters["host"];
    },
    clientId() {
      return this.$store.getters["ClientStore/client_id"];
    },
  },
  methods: {
    // ADD
    addedImage(submissionList) {
      return Promise.all(
        submissionList.map((item) => {
          return this.uploadImagePromise(item);
        })
      )
        .then((imgBulk) => {
          this.$root.$success("Uploaded image");

          imgBulk.forEach((img) => {
            img.user_name = this.$store.getters["AccountStore/name"];
            this.imageList.push(img);
            this.otherImages.push(img);
            this.allImages.push(img);
          });
        })
        .catch((err) => this.$root.$error(err));
    },
    uploadImagePromise(submission) {
      return this.$store.dispatch("ClientImageStore/addImage", {
        ref_id: this.clientId,
        description: submission.description,
        file: submission.upload,
      });
    },

    // CLEAR
    clearHeader() {
      this.showChecks = false;
      this.defaultImageChanges = {};
      this.deleteItems = [];
    },

    // SELECT
    selectImage(selected) {
      if (this.mode === "DELETE") this.selectFileDelete(selected);
      else if (this.mode === "SELECT_DEFAULT") this.selectDefaultImage(selected);
    },

    // DEFAULT IMAGE
    sortDefaultAndOthers() {
      this.defaultImages = this.imageList.filter((item) => item.isdefault);
      this.otherImages = this.imageList.filter((item) => !item.isdefault);
      this.allImages = [...this.defaultImages, ...this.otherImages];
    },
    setDefaultMode() {
      this.mode = "SELECT_DEFAULT";
      this.showChecks = true;
    },
    selectDefaultImage({ check, value }) {
      // ONlY SELECT ONE
      if (check) {
        const vm = this;
        this.imageList.forEach((image) => {
          // Chcking item then all but selected item must be checked
          if (image.id === value) {
            image.isdefault = true;
            vm.defaultImageChanges[image.id] = image;
          } else if (image.id !== value && image.isdefault) {
            image.isdefault = false;
            vm.defaultImageChanges[image.id] = image;
          } // else don't do anything
        });
        return;
      }

      // Uncheck, don't need to clear anything
      var image = this.imageList.find((x) => x.id === value);
      image.isdefault = check;
      this.defaultImageChanges[image.id] = image;
    },
    saveDefaultImages() {
      return this.$store
        .dispatch("ClientImageStore/saveDefaultImages", this.defaultImageChanges)
        .then((v) => {
          this.$root.$success("Updated defaults");
          this.sortDefaultAndOthers();
          this.clearHeader();
        })
        .catch((err) => this.$root.$error(err));
    },

    // DELETE STUFF
    setDeleteMode() {
      this.showChecks = !this.showChecks;
      this.mode = "DELETE";
    },
    selectFileDelete({ check, value }) {
      // Add to delete list group if checked
      // Remove from list group if not checked
      if (check) {
        this.deleteItems.push(value);
      } else {
        var index = this.deleteItems.findIndex((x) => x.id === value);
        this.deleteItems.splice(index, 1);
      }
    },
    deleteDocuments() {
      return this.$store
        .dispatch("ClientImageStore/deleteImageList", this.deleteItems)
        .then((v) => {
          this.$root.$success("Deleted images");
          var vm = this;
          this.imageList = this.imageList.filter(function (item) {
            return vm.deleteItems.indexOf(item.id) === -1;
          });
          this.sortDefaultAndOthers();
          this.clearHeader();
        })
        .catch((err) => this.$root.$error(err));
    },

    // EDIT IMAGE LABEL
    openEditImage(image) {
      this.editDialog = true;
      this.editFocus = image;
    },
    updateImage(data) {
      let source = this.imageList.find((item) => item.id === data.id);
      this.editDialog = false;
      this.$root.$loading.open("Updating image");
      this.$store
        .dispatch("ClientImageStore/updateImage", {
          ...data,
          description_cache: source.description,
          dependent_id: this.clientId,
        })
        .then((v) => {
          source.description = data.description;
          this.$root.$loading.end();
        })
        .catch((err) => this.$root.$error(err));
    },
  },
  mounted() {
    this.$store
      .dispatch("ClientImageStore/getImageList", this.clientId)
      .then((list) => {
        this.imageList = list || [];
        this.sortDefaultAndOthers();
      })
      .catch((err) => this.$root.$error(err));
  },
};
</script>

<style lang="scss">
.imageview {
  &:hover {
    background: lightgray;
    cursor: pointer;
  }
}
</style>
