<template>
  <elwrapper label="Attachments" texttop>
    <div class="grey lighten-2 pa-2" v-if="value && value.length">
      <v-icon left>attach_file</v-icon>
      <v-chip
        color="primary"
        v-for="(file, index) in value"
        :key="file.lastModified"
        class="mx-1"
        @click="openAttachment(file.path)"
      >
        <span>{{ file.filename }}</span>
        <v-btn small icon @click="(e) => removeFile(e, index)">
          <v-icon small>clear</v-icon>
        </v-btn>
      </v-chip>
    </div>

    <div class="d-flex align-center">
      <v-file-input
        outlined
        dense
        hide-details
        show-size
        multiple
        prepend-icon="attach_file"
        :value="placeHolderValue"
        @change="setFile"
      />

      <v-btn v-for="(b, index) in buttons" :key="'button' + index" @click="b.onclick" small text color="primary">
        {{ b.text }}
      </v-btn>
    </div>
    <span class="subtitle-2">** Maximum file upload: 50MB</span>
  </elwrapper>
</template>

<script>
// import { ipcRenderer } from 'electron'
export default {
  props: {
    value: Array,
    buttons: Array,
  },
  data() {
    return {
      placeHolderValue: [],
    };
  },
  methods: {
    setFile(files) {
      const item = files[0];
      if (item) {
        var reader = new FileReader();
        reader.readAsDataURL(item);
        reader.onload = () => {
          let extract = {
            filename: item.name,
            contentBase64: reader.result,
          };
          // Pass up and handle
          if (!this.value) return this.$emit("input", [extract]);
          this.$emit("input", [...this.value, extract]);
          this.placeHolderValue = [];
        };
      }

      // if(!this.value) return this.$emit('input', [...extract]);
      // this.$emit('input', [...this.value, ...extract])
      //    this.placeHolderValue = [];
    },
    removeFile(e, index) {
      e.stopPropagation();
      let modifer = [...this.value];
      modifer.splice(index, 1);
      this.$emit("input", modifer);
    },
    openAttachment(filepath) {
      this.$root.$success("Opening file...");
      if (filepath.includes(".pdf")) {
        this.$store.dispatch("openPdf", {
          display_name: filepath,
          link: filepath,
        });
      } else {
        // console.log('TO DO')
        // ipcRenderer.send('open-doc', filepath)
      }
    },
  },
};
</script>
