import BookingSearchEmailView from "./BookingSearchEmailView";
import BookingEmailWrapper from "./BookingEmailWrapper";
import EmailSelectView from "./EmailSelectView";

export default [
  {
    path: "/booking_search_email",
    name: "booking_search_email",
    component: BookingSearchEmailView,
    meta: {
      tabAccess: "BOOKING",
    },
  },
  {
    path: "/booking_email/:booking_id",
    component: BookingEmailWrapper,
    children: [
      {
        path: "",
        name: "booking_email",
        component: EmailSelectView,
        meta: {
          tabAccess: "BOOKING",
        },
      },
    ],
  },
];
