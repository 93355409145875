<template>
  <div>
    <div class="d-flex align-center">
      <ProductYearDrop :yearOptions="yearOptions" :value="showYear" @input="(item) => $emit('setYearDisplay', item)" />

      <v-btn icon small @click="dialog = true">
        <v-icon small color="white">edit</v-icon>
      </v-btn>
    </div>

    <ProductYearDialog v-model="dialog" :showYear="showYear" :alreadyAdded="alreadyAdded" :addNextYear="addNextYear" />
  </div>
</template>

<script>
import ProductYearDrop from "./ProductYearDrop";
import ProductYearDialog from "./_ProductYearDialog";
export default {
  props: {
    showYear: String,
    yearOptions: Array,
    addNextYear: Function,
    missingYearRates: Boolean,
  },
  components: {
    ProductYearDrop,
    ProductYearDialog,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    alreadyAdded() {
      if (this.missingYearRates) return false;
      const found = this.yearOptions.find((year) => year === `${new Date().getFullYear() + 1}`);
      return !!found;
    },
  },
};
</script>

<style lang="scss">
.year-option {
  padding: 5px 15px;
  max-height: 30px;
  height: 30px;
  min-height: 30px;
}
</style>
