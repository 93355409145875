<template>
  <a-card nopad class="history-search">
    <v-form @submit.prevent="search">
      <v-row>
        <a-select
          label="User"
          freeopt
          smlabel
          :value="displayUser"
          @input="(v) => (inputUser = v)"
          :items="accountContacts"
        />

        <a-select
          label="Contact"
          freeopt
          smlabel
          :value="displayContact"
          @input="(v) => (inputContact = v)"
          :hint="hiddenContent"
          :items="contactData.emailList"
        />

        <date-range rangeAlways :datewallEnd="datewallEnd" :value="displayDates" @input="(v) => (inputDates = v)" />
      </v-row>
      <v-row justify="end">
        <div>
          <g-button label="Clear" @onclick="clear" />
          <search-button class="mx-5" />
        </div>
      </v-row>
    </v-form>
  </a-card>
</template>

<script>
import { EmailService } from "@/modules/email";
import format from "@/services/format";
export default {
  props: {
    value: Object,
    lockDates: Boolean,
  },
  data() {
    return {
      inputContact: "",
      inputUser: "",
      inputDates: {
        from: "",
        to: "",
        range: true,
      },

      datewallEnd: format.convertDate(new Date()),
    };
  },
  computed: {
    displayUser() {
      return this.inputUser || this.value.user || "";
    },
    displayContact() {
      return this.inputContact || this.value.contact || "";
    },
    displayDates() {
      return {
        from: this.inputDates.from || this.value.dates.from,
        to: this.inputDates.to || this.value.dates.to,
        range: true,
      };
    },

    supplierName() {
      const name = this.$store.getters["SupplierStore/name"];
      const type = this.$store.getters["SupplierStore/type"];
      return `${name} (${type})`;
    },
    accountContacts() {
      const accounts = this.$store.getters["SupplierHistoryStore/account_ref"];
      return Object.keys(accounts).map((key) => {
        return {
          label: `${accounts[key].name} -- ${key}`,
          value: accounts[key],
        };
      });
    },
    contactData() {
      const allContacts = this.$store.getters["SupplierStore/all_contacts"];
      const primary = this.$store.getters["SupplierStore/primary_contact"];
      return EmailService.compileContactDropdownData(
        allContacts.map((item) => {
          return {
            ...item,
            supplier_name: this.supplierName,
          };
        }),
        primary.id
      );
    },
    hiddenContent() {
      const hidden = this.contactData.hidden;
      return hidden ? `Hiding ${hidden} contacts (no email)` : "";
    },
  },
  methods: {
    clear() {
      this.user = "";
      this.contact = "";
      if (this.lockDates) {
        this.dates = {
          // THREE DAYS AGO
          from: format.convertDate(new Date(Date.now() - 3 * 24 * 60 * 60 * 1000)),
          to: format.convertDate(new Date()),
          range: true,
        };
      } else {
        this.dates = {
          from: "",
          to: "",
          range: true,
        };
      }

      this.$emit("search", {
        user: this.user,
        contact: this.contact,
        dates: this.dates,
      });
    },
    search() {
      this.$emit("search", {
        user: this.displayUser,
        contact: this.displayContact,
        dates: this.displayDates,
      });
    },
  },
};
</script>

<style lang="scss">
.history-search {
  display: flex;
  align-items: center;

  .date-group {
    display: flex;
    align-items: center;
  }

  .search-item {
    max-width: 200px;
  }
}
</style>
