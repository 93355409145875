import TemplateView from "./TemplateView";
import NewTemplateView from "./NewTemplateView";
import EditTemplateView from "./EditTemplateView";

export default [
  {
    path: "/email_templates",
    name: "admin_email_templates",
    component: TemplateView,
    meta: {
      tabAccess: "ADMIN",
    },
  },
  {
    path: "/email_templates_add",
    name: "admin_add_template",
    component: NewTemplateView,
    meta: {
      tabAccess: "ADMIN",
    },
  },
  {
    path: "/email_templates/:template_id",
    name: "admin_edit_template",
    component: EditTemplateView,
    meta: {
      tabAccess: "ADMIN",
    },
  },
];
