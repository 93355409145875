import Vue from "vue";

import CurrencyPerValue from "./CurrencyPerValue";
import Cyoa from "./Cyoa";
import ClearCyoaBtn from "./cyoa/_clearCyoaButton";
import NumPerNum from "./NumPerNum";
import ButtonDropdownSelect from "./ButtonDropdownSelect";

Vue.component("cur-per-val", CurrencyPerValue);
Vue.component("cyoa", Cyoa);
Vue.component("cyoa-clear", ClearCyoaBtn);
Vue.component("num-per-num", NumPerNum);
Vue.component("btndrop", ButtonDropdownSelect);
