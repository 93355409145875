import api from "./api";
import Vue from "vue";

const namespaced = true;

const SET_QUOTE = "SET_QUOTE";
const TRANSFER_QUOTE = "TRANSFER_QUOTE";
const UPDATE_QUOTE = "UPDATE_QUOTE";

const state = {
  active: _getQuote(),
  cache: _getQuote(),
};

function _getQuote() {
  return JSON.parse(localStorage.getItem("quote") || "{}");
}

const mutations = {
  SET_QUOTE(state, data) {
    state.active = data.quote || {};
    state.cache = { ...state.active };
    localStorage.setItem("quote", JSON.stringify(state.active));
  },
  TRANSFER_QUOTE(state) {
    // Transfer active quote => cache
    // Save update local storage
    localStorage.setItem("quote", JSON.stringify(state.active));
  },
  UPDATE_QUOTE(state, data) {
    Vue.set(state.active, data.key, data.value);
  },
};

const actions = {
  getQuoteList({ commit }, query) {
    return api
      .getQuoteList(query)
      .then((data) => data.data)
      .catch((err) => {
        throw err.data;
      });
  },
  getOneQuote({ commit }, quote_id) {
    return api
      .getOneQuote(quote_id)
      .then((data) => {
        commit(SET_QUOTE, data.data);
        return data.data;
      })
      .catch((err) => {
        throw err.data;
      });
  },
  update({ commit }, { key, value }) {
    commit(UPDATE_QUOTE, { key, value });
  },
  updateQuote({ commit }, data) {
    var quote_id = state.active.id;
    var update = data;

    if (!quote_id) return Promise.resolve();

    return api
      .updateQuote(quote_id, update)
      .then((v) => {
        // Update new booking
        commit(TRANSFER_QUOTE);
      })
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  updateQuoteById({ commit }, { quote_id, data }) {
    return api.updateQuote(quote_id, data).catch((err) => {
      if (err) throw err.data;
    });
  },
  createQuote({ commit }, data) {
    return api
      .createQuote(data)
      .then((data) => {
        return data.data;
      })
      .catch((err) => {
        throw err.data;
      });
  },
  convertQuoteToBooking({ commit }, quote_id) {
    return api
      .convertQuoteToBooking(quote_id)
      .then((data) => {
        return data.data;
      })
      .catch((err) => {
        throw err.data;
      });
  },
  deleteQuote({ commit }, quote_id) {
    return api
      .deleteQuote(quote_id)
      .then((data) => data.data)
      .catch((err) => {
        throw err.data;
      });
  },
  getDocumentList(v, supplier_id) {
    return api
      .getDocumentList(supplier_id)
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  addDocument(v, { origin_id, name, file }) {
    return api
      .addDocument(origin_id, { name, file })
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  updateDocument(v, { id, display_name, old_name, dependent_id }) {
    return api
      .updateDocument(id, {
        display_name,
        old_name,
        dependent_id,
      })
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
  deleteDocumentList(v, idList) {
    return api
      .deleteDocumentList(idList)
      .then((data) => data.data)
      .catch((err) => {
        if (err) throw err.data;
      });
  },
};

const getters = {
  active: (state) => state.active,
  quote_id: (state) => state.active.id,
  where_start: (state) => state.active.where_start,
  where_end: (state) => state.active.where_end,
  nights: (state) => state.active.nights,
  product_price_month: (state) => state.active.month,
  product_price_year: (state) => state.active.year,
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
