<template>
  <v-btn depressed small :block="block" @click="$emit('click')">
    <v-icon left>add</v-icon>
    <span>Add</span>
  </v-btn>
</template>

<script>
export default {
  props: {
    block: Boolean,
  },
};
</script>
