<template>
  <elwrapper :label="config.label">
    <v-row class="d-flex" align="center" justify="space-between">
      <v-col cols="4">
        <f-dropdown :items="types" :value="data.astype" @input="(v) => updateValue('astype', v)" hideClear />
      </v-col>

      <v-col cols="3">
        <f-percent v-if="data.astype === 'PERCENT'" :value="data.value" @input="(v) => updateValue('value', v)" />
        <enumber
          v-else-if="data.astype === 'COST'"
          :value="data.value"
          :country="country"
          @input="(v) => updateValue('value', v)"
        />
      </v-col>

      <v-col cols="4">
        <pcheck label="Before Tax" :value="beforeTax" @input="(v) => updateValue('beforeTax', v)" />
      </v-col>

      <v-col cols="1">
        <v-btn icon small @click="$emit('remove')">
          <v-icon small>clear</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </elwrapper>
</template>

<script>
import Vue from "vue";
export default {
  props: {
    country: String,
    config: Object,
    data: Object,
  },
  computed: {
    beforeTax() {
      return this.data.beforeTax || this.config.beforeTax;
    },
  },
  data() {
    return {
      types: [
        {
          label: "Fixed ($)",
          value: "COST",
        },
        {
          label: "Percent (%)",
          value: "PERCENT",
        },
      ],
    };
  },
  methods: {
    updateValue(key, value) {
      this.$emit("update", { key, value });
    },
  },
};
</script>
