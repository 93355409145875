<template>
  <a-card>
    <TemplateSelect :limitTemplate="limitTemplate" @input="fillTemplate" />

    <v-divider class="my-3" />

    <a-text-field label="Subject" :value="value.subject" @input="(v) => update('subject', v)" />

    <elwrapper label="Message" texttop>
      <RichTextEditor maxheight="300px" :value="value.message" @input="(v) => update('message', v)" />
    </elwrapper>

    <SignatureDisplayBlock />

    <Attachments :value="value.attachments" @input="(v) => update('attachments', v)" />

    <v-alert dense text color="warning" class="caption mt-3">
      <v-icon x-small left color="warning">priority_high</v-icon>
      <span>Note: Only Contact name will be replaced in the template.</span>
    </v-alert>

    <TemplateDialog
      v-model="dialog"
      :title="selectedTemplateTitle"
      :floatingText="floatingText"
      :subject="this.value.subject"
      @set="(text) => update('message', text)"
      @setSubject="(text) => update('subject', text)"
    />
  </a-card>
</template>

<script>
import Vue from "vue";
import { SignatureDisplayBlock } from "@/modules/account";
import TemplateDialog from "@/modules/admin.email_template/components/TemplateDialog";
import {
  TemplateSelect,
  // TemplateDialog,
  EmailTemplateService,
} from "@/modules/admin.email_template";
import { Attachments } from "@/modules/email";
export default {
  props: {
    value: Object,
    limitTemplate: Number,
  },
  components: {
    SignatureDisplayBlock,
    TemplateSelect,
    TemplateDialog,
    Attachments,
  },
  data() {
    return {
      // Template fill stuff
      dialog: false,
      selectedTemplateTitle: "",
      floatingText: "",
    };
  },
  computed: {},
  methods: {
    update(key, value) {
      Vue.set(this.value, key, value);
      this.$emit("input", this.value);
    },
    fillTemplate(found) {
      this.selectedTemplateTitle = found.name;

      this.update("subject", EmailTemplateService.supplierBasicFill(found.subject, true));
      this.update("status", found.status);
      this.update("language", found.language);

      this.floatingText = EmailTemplateService.supplierBasicFill(found.text);

      this.dialog = EmailTemplateService.hasReplaceContent(this.floatingText);
      if (!this.dialog) this.update("message", this.floatingText);
    },
  },
  mounted() {
    // Append Account Signature
    const signature = this.$store.getters["AccountStore/signature"];
    this.update("message", `<br/>` + signature);
  },
};
</script>
