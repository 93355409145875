<template>
  <v-dialog :value="value" @input="(v) => $emit('input', v)" width="400px" persistent>
    <v-card>
      <v-card-title>
        <span class="caption"></span>
      </v-card-title>

      <v-card-text class="black--text">
        <div>{{ supplierName }}</div>
        <elwrapper label="Confirmation #" widelabel>
          <v-row v-for="(val, i) in number" :key="i">
            <f-text :value="val" @input="(v) => (number[i] = v)"></f-text>
          </v-row>
        </elwrapper>
        <v-row justify="end">
          <g-button label="confirm #" icon="add" @onclick="addConfirmation" />
        </v-row>
      </v-card-text>

      <v-card-actions>
        <g-button label="Cancel" @onclick="$emit('input', false)" />
        <div class="flex-grow-1"></div>

        <g-button type="primary" label="Update" @onclick="update" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    clickedSupplier: Object,
    updateFn: Function,
  },
  data() {
    return {
      number: [null],
    };
  },
  watch: {
    value(v) {
      if (!v) return;
      this.number = (this.clickedSupplier && this.clickedSupplier.meta && this.clickedSupplier.meta.confirm_number) || [
        null,
      ];
    },
  },
  computed: {
    supplierName() {
      return this.clickedSupplier && this.clickedSupplier.name;
    },
  },
  methods: {
    addConfirmation() {
      this.number.push(null);
    },
    update() {
      const c = this.clickedSupplier;
      this.updateFn({
        bsid: c.id,
        supplier_id: c.supplier_id,
        type_as: c.type_as,
        bound: c.meta && c.meta.bound,
        metaMerge: {
          room_nameList: this.nameList,
        },
      }).then((_) => {
        this.$root.$success("Updated " + c.name);
        this.$emit("input", false);
      });
    },
  },
};
</script>
