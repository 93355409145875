<template>
  <v-btn small text depressed @click="$emit('click')">
    <v-icon small left>{{ icon }}</v-icon>
    <span v-once>{{ label }}</span>
  </v-btn>
</template>

<script>
export default {
  props: {
    icon: String,
    label: String,
  },
};
</script>
