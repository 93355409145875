<template>
  <div>
    <h3 class="title white--text">Editing: {{ parentTemplate.name }}</h3>
    <v-row justify="space-between" class="ma-3">
      <delete-button :ondelete="deleteTemplate" />

      <div class="d-flex">
        <g-button label="Return" @onclick="$router.push({ name: 'admin_email_templates' })" />
        <save-button :onsave="updateTemplate" />
        <QuestionHover />
      </div>
    </v-row>

    <a-card class="px-5">
      <div>
        <a-text-field label="Name" v-model="parentTemplate.name" />
        <elwrapper label="Suppliers">
          <f-dropdown multiple :items="supplierItems" v-model="parentTemplate.supplier_types" />
        </elwrapper>
        <EmailStatusDropdown v-model="parentTemplate.status_set" />
      </div>

      <v-divider class="mt-2" />

      <v-row>
        <v-col cols="2" class="ring grey lighten-2 subtitle-2">
          <div
            v-for="lang in optionList"
            :key="lang.value"
            class="py-1 px-3 hover"
            :class="{ white: selectedLanguage === lang.value }"
            @click="switchLanguage(lang)"
          >
            <span>{{ lang.label }}</span>
          </div>

          <v-divider />

          <div class="mt-5">
            <LanguageAddDialog :exists="exists" @add="addLanguage" />
          </div>
        </v-col>

        <v-col>
          <a-text-field label="Subject" v-model="selectedTemplate.subject" />
          <RichTextEditor v-model="selectedTemplate.text" />
        </v-col>
      </v-row>
    </a-card>
  </div>
</template>

<script>
import Vue from "vue";
import SUPMENU from "@/config/menus/suppliersMenu";
import languageList from "language-list";
import { EmailStatusDropdown, QuestionHover, LanguageAddDialog } from "@/modules/admin.email_template";

export default {
  components: {
    EmailStatusDropdown,
    QuestionHover,
    LanguageAddDialog,
  },
  data() {
    return {
      supplierItems: SUPMENU.FULL.map((item) => {
        return {
          label: item.title,
          value: item.module,
        };
      }),

      parentTemplateId: null,
      templateBlob: {},

      // Parent
      parentTemplate: {
        supplier_types: "",
      },
      parentCacheStamp: null,

      // Acticle
      selectedLanguage: "EN",
      selectedTemplate: {},

      // Language stuff
      languageDialog: false,
    };
  },
  computed: {
    exists() {
      return Object.keys(this.templateBlob);
    },
    optionList() {
      const LANGUAGES = languageList();
      return this.exists
        .map((key) => {
          return {
            value: key,
            label: LANGUAGES.getLanguageName(key.toLowerCase()),
          };
        })
        .sort((a, b) => {
          if (a.value < b.value) return -1;
          if (a.value > b.value) return 1;
          return 0;
        });
    },
  },
  methods: {
    deleteTemplate() {
      return this.$store
        .dispatch("EmailTemplateStore/deleteTemplate", this.parentTemplateId)
        .then((v) => {
          this.$root.$success("Deleted template: " + this.parentTemplate.name);
          this.$router.push({ name: "admin_email_templates" });
        })
        .catch((err) => this.$root.$error(err));
    },

    addLanguage(lang) {
      return this.$store
        .dispatch("EmailTemplateStore/createTemplate", {
          subject: "",
          parent_id: this.parentTemplateId,
          language: lang.value,
        })
        .then((temp) => {
          Vue.set(this.templateBlob, temp.language, temp);
          this.$root.$success("Added language");
        })
        .catch((err) => this.$root.$error(err));
    },

    switchLanguage(lang) {
      this.selectedLanguage = lang.value;
      this.selectedTemplate = this.templateBlob[lang.value];
    },

    // Update Template stuffs
    updateTemplate() {
      let promiseQuery = [];
      // Update parent
      if (JSON.stringify(this.parentTemplate) !== this.parentCacheStamp) {
        promiseQuery.push(this._updatParentTemplate());
      }

      // Update active template
      if (this.selectedTemplate.id !== this.parentTemplateId) {
        promiseQuery.push(this._updateChildTemplate());
      }

      return Promise.all(promiseQuery);
    },
    _updatParentTemplate() {
      return this.$store
        .dispatch("EmailTemplateStore/updateTemplate", {
          template_id: this.parentTemplateId,
          data: {
            ...this.parentTemplate,
          },
        })
        .then((v) => {
          this.$root.$success("Updated template: " + this.parentTemplate.name);
        })
        .catch((err) => this.$root.$error(err));
    },
    _updateChildTemplate() {
      const active = this.selectedTemplate;
      return this.$store
        .dispatch("EmailTemplateStore/updateTemplate", {
          template_id: active.id,
          data: {
            subject: active.subject,
            text: active.text,
          },
        })
        .then((v) => {
          this.$root.$success("Updated template: " + this.parentTemplate.name);
        })
        .catch((err) => this.$root.$error(err));
    },
  },
  mounted() {
    // Get this template
    this.parentTemplateId = this.$route.params.template_id;
    this.$store
      .dispatch("EmailTemplateStore/getOneTemplate", this.parentTemplateId)
      .then((templateList) => {
        templateList.forEach((temp) => {
          // No parent id = parent template
          if (!temp.parent_id) {
            this.parentTemplate = temp;
            if (!this.parentTemplate.supplier_types) this.parentTemplate.supplier_types = [];
            this.parentCacheStamp = JSON.stringify(temp);
            this.selectedTemplate = temp;
          }

          Vue.set(this.templateBlob, temp.language, temp);
        });
      })
      .catch((err) => this.$root.$error(err));
  },
};
</script>
